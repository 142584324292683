import { createSlice } from "@reduxjs/toolkit";
import { upload } from "../../api/common/uploadApi";

const initialState = {
  chunkSize: 1024 * 1024 * 5,
  threadsQuantity: Math.min(5, 15),
  file: null,
  fileName: null,
  aborted: false,
  uploadedSize: 0,
  progressCache: {},
  activeConnections: {},
  parts: [],
  uploadedParts: [],
  fileId: null,
  fileKey: null,
  //   onProgressFn: () => {},
  //   onErrorFn: () => {},
  onProgress: {
    sent: null,
    total: null,
    percentage: null,
  },
  onError: {},
};

export const uploaderSlice = createSlice({
  name: "uploader",
  initialState: initialState,
  reducers: {
    start() {
      //   this.initialize();
      uploaderSlice.caseReducers.initialize();
    },
    initialize(state, action) {
      console.log("initialize");

      const initializeMultipartUpload =
        upload.endpoints.initializeMultipartUpload.useMutation();

      try {
        // adding the the file extension (if present) to fileName
        let fileName = state.fileName;

        // initializing the multipart request
        const numberOfparts = Math.ceil(state.file.size / state.chunkSize);

        const initializationUploadInput = {
          name: fileName,
          parts: numberOfparts,
        };
        const initializeReponse = initializeMultipartUpload(
          initializationUploadInput
        );

        state.fileId = initializeReponse.data.upload_id;
        state.fileKey = initializeReponse.data.key;

        const newParts = initializeReponse.data.parts;
        state.parts.push(...newParts);

        uploaderSlice.caseReducers.sendNext();
      } catch (error) {
        uploaderSlice.caseReducers.complete(error);
      }
    },
    sendNext(state, action) {
      const activeConnections = Object.keys(state.activeConnections).length;

      if (activeConnections >= state.threadsQuantity) {
        return;
      }

      if (!state.parts.length) {
        if (!activeConnections) {
          uploaderSlice.caseReducers.complete();
        }

        return;
      }

      const part = state.parts.pop();
      if (state.file && part) {
        const sentSize = (part.PartNumber - 1) * state.chunkSize;
        const chunk = state.file.slice(sentSize, sentSize + state.chunkSize);

        const sendChunkStarted = () => {
          uploaderSlice.caseReducers.sendNext();
        };

        uploaderSlice.caseReducers
          .sendChunk(chunk, part, sendChunkStarted)
          .then(() => {
            uploaderSlice.caseReducers.sendNext();
          })
          .catch((error) => {
            state.parts.push(part);

            uploaderSlice.caseReducers.complete(error);
          });
      }
    },

    // terminating the multipart upload request on success or failure
    async complete(state, action) {
      const { error } = action.payload;

      if (error && !state.aborted) {
        // state.onErrorFn(error);
        return {
          ...state,
          onError: error,
        };
      }

      if (error) {
        // state.onErrorFn(error);
        return {
          ...state,
          onError: error,
        };
      }

      try {
        await uploaderSlice.caseReducers.sendCompleteRequest();
      } catch (error) {
        // state.onErrorFn(error);
        return {
          ...state,
          onError: error,
        };
      }
    },

    // finalizing the multipart upload request on success by calling
    // the finalization API
    async sendCompleteRequest(state, action) {
      // const [finalizationMultipartUpload] = useFinalizeMultipartUploadMutation();
      const finalizationMultipartUpload =
        upload.endpoints.finalizeMultipartUpload.useMutation();

      if (state.fileId && state.fileKey) {
        const finalizationMultiPartInput = {
          upload_id: state.fileId,
          key: state.fileKey,
          parts: state.uploadedParts,
        };

        // await api.request({
        //   url: "/uploads/finalizeMultipartUpload",
        //   method: "POST",
        //   data: finalizationMultiPartInput,
        // });

        await finalizationMultipartUpload(finalizationMultiPartInput);
      }
    },

    sendChunk(state, action) {
      const { chunk, part, sendChunkStarted } = action.payload;
      return new Promise((resolve, reject) => {
        uploaderSlice.caseReducers
          .upload(chunk, part, sendChunkStarted)
          .then((status) => {
            if (status !== 200) {
              reject(new Error("Failed chunk upload"));
              return;
            }

            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // calculating the current progress of the multipart upload request
    handleProgress(state, action) {
      const { part, event } = action.payload;
      if (state.file) {
        if (
          event.type === "progress" ||
          event.type === "error" ||
          event.type === "abort"
        ) {
          state.progressCache[part] = event.loaded;
        }

        if (event.type === "uploaded") {
          state.uploadedSize += state.progressCache[part] || 0;
          delete state.progressCache[part];
        }

        const inProgress = Object.keys(state.progressCache)
          .map(Number)
          .reduce((memo, id) => (memo += state.progressCache[id]), 0);

        const sent = Math.min(state.uploadedSize + inProgress, state.file.size);

        const total = state.file.size;

        const percentage = Math.round((sent / total) * 100);

        return {
          ...state,
          onProgress: {
            sent: sent,
            total: total,
            percentage: percentage,
          },
        };
        // state.onProgressFn({
        //     sent: sent,
        //     total: total,
        //     percentage: percentage,
        // });
      }
    },

    // uploading a part through its pre-signed URL
    upload(state, action) {
      const { file, part, sendChunkStarted } = action.payload;
      // uploading each part with its pre-signed URL
      return new Promise((resolve, reject) => {
        if (state.fileId && state.fileKey) {
          // - 1 because PartNumber is an index starting from 1 and not 0
          const xhr = (state.activeConnections[part.PartNumber - 1] =
            new XMLHttpRequest());

          sendChunkStarted();

          const progressListener =
            uploaderSlice.caseReducers.handleProgress.bind(
              this,
              part.PartNumber - 1
            );

          xhr.upload.addEventListener("progress", progressListener);

          xhr.addEventListener("error", progressListener);
          xhr.addEventListener("abort", progressListener);
          xhr.addEventListener("loadend", progressListener);

          xhr.open("PUT", part.signedUrl);

          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
              // retrieving the ETag parameter from the HTTP headers
              const ETag = xhr.getResponseHeader("ETag");

              if (ETag) {
                const uploadedPart = {
                  PartNumber: part.PartNumber,
                  // removing the " enclosing carachters from
                  // the raw ETag
                  ETag: ETag.replaceAll('"', ""),
                };

                state.uploadedParts.push(uploadedPart);

                resolve(xhr.status);
                delete state.activeConnections[part.PartNumber - 1];
              }
            }
          };

          xhr.onerror = (error) => {
            reject(error);
            delete state.activeConnections[part.PartNumber - 1];
          };

          xhr.onabort = () => {
            reject(new Error("Upload canceled by user"));
            delete state.activeConnections[part.PartNumber - 1];
          };

          xhr.send(file);
        }
      });
    },

    // onProgress(state, action) {
    //   const { onProgress } = action.payload;
    //   state.onProgressFn = onProgress;
    //   return;
    // },

    // onError(state, action) {
    //   const { onError } = action.payload;
    //   state.onErrorFn = onError;
    //   return;
    // },

    abort(state, action) {
      Object.keys(state.activeConnections)
        .map(Number)
        .forEach((id) => {
          state.activeConnections[id].abort();
        });

      state.aborted = true;
    },
  },
});

export const { start } = uploaderSlice.actions;

export default uploaderSlice.reducer;
