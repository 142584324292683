import { api } from "../api";

export const upload = api.injectEndpoints({
  endpoints: (builder) => ({
    initializeMultipartUpload: builder.mutation({
      query: (data) => ({
        url: "/admin/v1/initialize-multipart-upload",
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => {
        return response.data[0];
      },
    }),
    finalizeMultipartUpload: builder.mutation({
      query: (data) => {
        return {
          url: "/admin/v1/finalize-multipart-upload",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: (result, error, data) => {
        if (data.user_id) {
          return [
            { type: "User", id: data.user_id },
            { type: "Assessment", id: data.user_id },
          ];
        }

        if (data.organisation_id) {
          return [
            { type: "OrganisationSupportingDocs", id: data.organisation_id },
            { type: "OrganisationTOBs", id: data.organisation_id }
          ];
        }

        return [];
      },
    }),
  }),
});

export const {
  useInitializeMultipartUploadMutation,
  useFinalizeMultipartUploadMutation,
} = upload;
