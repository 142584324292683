import { useEffect, useState } from "react";
import { Modal, ModalBody, Spinner } from "reactstrap";
import { useParams } from "react-router-dom";
import {
  PreviewCard,
  Block,
  BlockHeadContent,
  BlockHead,
  BlockDes,
  BlockTitle,
  TextInput,
  Row,
  DatePickerInput,
  SelectField,
  Col,
  Button,
  ModalComponent,
  BlockContent,
  Icon,
  TextareaInput,
} from "../../components/Components";
import PageContainer from "../../layout/page-container/PageContainer";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { useForm, Controller } from "react-hook-form";
import Error404Modern from "../error/404-modern";
import {
  useSaveIntroductionFormMutation,
  useSubmitIntroductionMutation,
  useGetIntroductionFormQuery,
} from "../../redux/api/forms/formApi";
import LoadingPage from "../../pages/loading/LoadingPage";
import ReactFlagsSelect from "react-flags-select";
import classNames from "classnames";
import { countryFlagsMap, nioIconData, stagingHostname } from "../../constants";
import { PhoneNumberWithCountryCodeInput } from "../../components/form/TextInput";
import { toggleModal } from "../../redux/features/accountSlice";
import Logo from "../../images/Vector.png";
import { useRef } from "react";
import { toast } from "react-toastify";

const IntroductionForm = () => {
  const ref = useRef(null);
  const params = useParams();
  const accessToken = localStorage.getItem("accessToken");
  const {
    data: formData,
    isFetching,
    isSuccess,
    isError,
    error,
  } = useGetIntroductionFormQuery({
    code: params.code,
  });
  const [
    submitForm,
    {
      isLoading: isSubmitLoading,
      isError: isSubmitError,
      isSuccess: isSubmitSuccess,
      error: submitError,
    },
  ] = useSubmitIntroductionMutation();
  const [
    saveForm,
    {
      data: saveData,
      isLoading: isSaveLoading,
      isError: isSaveError,
      isSuccess: isSaveSuccess,
    },
  ] = useSaveIntroductionFormMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
    setValue,
    watch,
    trigger,
  } = useForm({
    defaultValues: {
      caregiver_country_calling_code: "SG",
      country_calling_code: "SG",
    },
  });
  const watchFields = watch(["primary_caregiver", "recent_employer"]);
  const [submitted, setSubmitted] = useState(formData?.is_completed ?? false);
  const [saveModal, setSaveModal] = useState(false);

  // When select "No caregiver", not need to require caregiver name & phone number
  // Otherwise trigger validate to show required warning text
  //TODO: Need to refactor watch trigger revalidation fields with useEffect dep
  useEffect(() => {
    if (watchFields[0]) {
      trigger([
        "caregiver_first_name",
        "caregiver_last_name",
        "caregiver_phone_number",
      ]);
    }
  }, [watchFields[0]]);
  // useEffect(() => {
  //   if (caregiverField) {
  //     trigger(["caregiver_name", "caregiver_phone_number"]);
  //   }
  // }, [caregiverField]);

  useEffect(() => {
    if (formData) {
      reset(
        {
          email: formData.email,
          first_name: formData.first_name,
          last_name: formData.last_name,
          country_calling_code: formData.country_calling_code,
          phone_number: formData.phone_number,
          date_of_birth: Date.parse(formData.date_of_birth),
          is_travel_independently: formData.is_travel_independently
            ? {
                value: formData.is_travel_independently,
                label: formData.is_travel_independently,
              }
            : null,
          primary_caregiver: formData.primary_caregiver
            ? {
                value: formData.primary_caregiver,
                label: formData.primary_caregiver,
              }
            : null,
          caregiver_first_name: formData.caregiver_first_name,
          caregiver_last_name: formData.caregiver_last_name,
          caregiver_country_calling_code:
            formData.caregiver_country_calling_code,
          caregiver_phone_number: formData.caregiver_phone_number,
          reason_to_work: formData.reason_to_work,
          interests: formData.interests,

          highest_education: formData.highest_education
            ? {
                value: formData.highest_education,
                label: formData.highest_education,
              }
            : null,
          highest_education_school: formData.highest_education_school,
          highest_education_course: formData.highest_education_course,
          time_start_highest_education: Date.parse(
            formData.time_start_highest_education
          ),
          time_end_highest_education: Date.parse(
            formData.time_end_highest_education
          ),

          recent_employer: formData.recent_employer,
          recent_job_role: formData.recent_job_role,
          recent_first_month_of_work: Date.parse(
            formData.recent_first_month_of_work
          ),
          recent_last_month_of_work: Date.parse(
            formData.recent_last_month_of_work
          ),
          reason_leaving_job: formData.reason_leaving_job,

          disability_name: formData.disability_name
            ? {
                value: formData.disability_name,
                label: formData.disability_name,
              }
            : null,
          disability_description: formData.disability_description,
          disability_year_diagnosed: Date.parse(
            formData.disability_year_diagnosed
          ),
          ongoing_therapy: formData.ongoing_therapy,
          medical_history: formData.medical_history,

          who_filled_form: formData.who_filled_form,
          more_things_sharing: formData.more_things_sharing,
          how_know_inclus: formData.how_know_inclus,

          comfort_level_technology_excel:
            formData.comfort_level_technology_excel,
          comfort_level_technology_email:
            formData.comfort_level_technology_email,
          comfort_level_technology_google_search:
            formData.comfort_level_technology_google_search,
          comfort_level_technology_powerpoint:
            formData.comfort_level_technology_powerpoint,
          comfort_level_technology_video_tools:
            formData.comfort_level_technology_video_tools,
          comfort_level_technology_word: formData.comfort_level_technology_word,
        },
        {
          keepDefaultValues: true,
        }
      );
    }
  }, [formData]);

  const processData = (data) => {
    data.date_of_birth = !data.date_of_birth
      ? null
      : new Date(data.date_of_birth).toISOString();
    data.is_travel_independently = data.is_travel_independently?.value;
    data.primary_caregiver = data.primary_caregiver?.value;

    data.highest_education = data.highest_education?.value;
    data.time_start_highest_education = !data.time_start_highest_education
      ? null
      : new Date(data.time_start_highest_education).toISOString();
    data.time_end_highest_education = !data.time_end_highest_education
      ? null
      : new Date(data.time_end_highest_education).toISOString();

    data.recent_first_month_of_work = !data.recent_first_month_of_work
      ? null
      : new Date(data.recent_first_month_of_work).toISOString();
    data.recent_last_month_of_work = !data.recent_last_month_of_work
      ? null
      : new Date(data.recent_last_month_of_work).toISOString();

    data.disability_name = data.disability_name?.value;
    data.disability_year_diagnosed = !data.disability_year_diagnosed
      ? null
      : new Date(data.disability_year_diagnosed).toISOString();

    data.reference_code = params.code;
    return data;
  };

  const onFormSubmit = (data, e) => {
    data = processData(data);
    data.type = "submit";
    submitForm(data);
  };

  useEffect(() => {
    if (isSubmitSuccess || formData?.is_submitted) {
      if (ref.current) {
        clearInterval(ref.current);
      }
    }
    if (isSubmitError && submitError) {
      toast.error(submitError.data.message);
    }
    if (isSubmitSuccess || (formData?.is_submitted && !accessToken)) {
      setSubmitted(true);
    }
    if (formData?.is_completed) {
      // setSubmitted(true);
    }
  }, [isSubmitSuccess, isSubmitError, formData]);

  const onSave = (type) => {
    let data = getValues();
    data = processData(data);
    data.submission_type = type;
    saveForm(data);
  };

  useEffect(() => {
    ref.current = setInterval(() => onSave("autosave"), 30000);

    return () => {
      if (ref.current) {
        clearInterval(ref.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isSaveSuccess) {
      if (!saveData.data) {
        setSaveModal(true);
      }
    }
  }, [isSaveSuccess, isSaveError]);

  if (process.env.NODE_ENV === "production") {
    const hostname = window.location.hostname;
    if (hostname !== stagingHostname) {
      return <Error404Modern />;
    }
  }

  if (isFetching) {
    return <LoadingPage />;
  }
  if (isError && error && (error.status === 404 || error.status === 400)) {
    return <Error404Modern />;
  }

  if (submitted) {
    return (
      <>
        <Head title="Introduction Form" />
        <PageContainer>
          <Block className="nk-block-middle nk-auth-body wide-sm">
            <PreviewCard
              className="card-bordered fs-18px"
              bodyClass="card-inner-lg"
            >
              <BlockHead>
                <BlockContent
                  className={"d-flex align-items-center flex-column"}
                >
                  <div className="w-100px mb-4">
                    {nioIconData["doc-checked"]}
                  </div>
                  <BlockTitle tag="h4">
                    Thank you for filling up the introduction form.
                  </BlockTitle>
                  <BlockDes>
                    <p className="fs-20px">
                      Your form reference code is:{" "}
                      <strong className="fw-bold">{params.code}</strong>
                    </p>
                  </BlockDes>
                </BlockContent>
              </BlockHead>
              <div>
                {/* <p>
                  A confirmation email will be sent to the email address you
                  provided.
                </p> */}
                <p>
                  Some one from Inclus should be contacting you soon. If you
                  need to contact us, please send an email with your form
                  reference code to{" "}
                  <span className="custom-link">hello@inclus.sg</span>
                </p>
              </div>
            </PreviewCard>
          </Block>
        </PageContainer>
      </>
    );
  }

  return (
    <>
      <Head title="Introduction Form" />
      <Content>
        <div className="content-page wide-sm m-auto">
          <BlockHead size="lg" wide="xs" className="mx-auto">
            <BlockHeadContent className="text-center">
              <BlockTitle tag="h2" className="fw-medium">
                Inclus introduction form
              </BlockTitle>
              <BlockDes className="fs-16px">
                <p className="lead">Please help us get to know you better.</p>
                <div className="form-des-block-note px-4 py-3">
                  <p>Before you start, please take note:</p>
                  <ul className="list">
                    <li className="my-2">
                      Your form reference code is:{" "}
                      <strong className="fs-18px fw-bold">{params.code}</strong>
                    </li>
                    <li className="my-2">
                      Please do not share your reference code with others.
                    </li>
                    <li className="my-2">
                      At any point of time, you can save the form and come back
                      at a later time to finish filling it. You will need your
                      form reference code to continue filling the form.
                    </li>
                  </ul>
                </div>
              </BlockDes>
            </BlockHeadContent>
          </BlockHead>
          <Block className="fs-16px">
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <PreviewCard className="card-bordered">
                <Row className="g-3">
                  <Col size="12">
                    <BlockTitle tag="h5">
                      Your personal &amp; contact information
                    </BlockTitle>
                    <div className="nk-divider divider mt-3 mb-1" />
                  </Col>
                  <TextInput
                    name={"email"}
                    label={"Email address"}
                    placeholder={"name@example.com"}
                    required
                    // disabled={formData?.created_by}
                    register={{
                      ...register("email", {
                        required: "This field is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",
                        },
                      }),
                    }}
                    errors={errors}
                    md="12"
                  />
                  <TextInput
                    name={"first_name"}
                    label={"First name"}
                    placeholder={"your personal name"}
                    required
                    register={{
                      ...register("first_name", {
                        required: "This field is required",
                      }),
                    }}
                    errors={errors}
                    md="12"
                  />
                  <TextInput
                    name={"last_name"}
                    label={"Last name"}
                    placeholder={"your surname"}
                    required
                    register={{
                      ...register("last_name", {
                        required: "This field is required",
                      }),
                    }}
                    errors={errors}
                    md="12"
                  />
                  <PhoneNumberWithCountryCodeInput
                    label={"Mobile number"}
                    nameCode={"country_calling_code"}
                    nameInputField={"phone_number"}
                    rules={{
                      required: "This field is required",
                    }}
                    register={register}
                    control={control}
                    errors={errors}
                    md="12"
                  />
                  <DatePickerInput
                    name={"date_of_birth"}
                    label={"Date of Birth"}
                    placeholder={"dd/mm/yyyy"}
                    dateFormat={"dd/MM/yyyy"}
                    control={control}
                    rules={{ required: "This field is required" }}
                    errors={errors}
                    md="12"
                  />
                  <SelectField
                    name={"is_travel_independently"}
                    label={"Are you able to travel independently?"}
                    control={control}
                    rules={{ required: "This field is required" }}
                    isSearchable={false}
                    options={[
                      { value: "Yes", label: "Yes" },
                      { value: "No", label: "No" },
                    ]}
                    errors={errors}
                    md="12"
                  />
                  <SelectField
                    name={"primary_caregiver"}
                    label={"Who is your primary caregiver?"}
                    control={control}
                    rules={{ required: "This field is required" }}
                    isSearchable={false}
                    options={[
                      { value: "Parent", label: "Parent" },
                      {
                        value: "Sibling",
                        label: "Sibling",
                      },
                      { value: "Spouse", label: "Spouse" },
                      {
                        value: "No primary caregiver",
                        label: "No primary caregiver",
                      },
                      { value: "Other", label: "Other" },
                    ]}
                    errors={errors}
                    md="12"
                  />
                  <TextInput
                    name={"caregiver_first_name"}
                    label={"Caregiver first name"}
                    caption={"Leave this empty if you do not have a caregiver"}
                    placeholder={"your caregiver's first name"}
                    required={
                      watchFields[0] &&
                      watchFields[0].value !== "No primary caregiver"
                    }
                    onChangeFunc={() => {
                      trigger("caregiver_first_name");
                    }}
                    register={{
                      ...register("caregiver_first_name", {
                        required:
                          watchFields[0] &&
                          watchFields[0].value !== "No primary caregiver"
                            ? "This field is required"
                            : false,
                      }),
                    }}
                    errors={errors}
                    md="12"
                  />
                  <TextInput
                    name={"caregiver_last_name"}
                    label={"Caregiver last name"}
                    caption={"Leave this empty if you do not have a caregiver"}
                    placeholder={"your caregiver's last name"}
                    required={
                      watchFields[0] &&
                      watchFields[0].value !== "No primary caregiver"
                    }
                    onChangeFunc={() => {
                      trigger("caregiver_last_name");
                    }}
                    register={{
                      ...register("caregiver_last_name", {
                        required:
                          watchFields[0] &&
                          watchFields[0].value !== "No primary caregiver"
                            ? "This field is required"
                            : false,
                      }),
                    }}
                    errors={errors}
                    md="12"
                  />
                  <PhoneNumberWithCountryCodeInput
                    label={"Caregiver mobile number"}
                    caption={"Leave this empty if you do not have a caregiver"}
                    nameCode={"caregiver_country_calling_code"}
                    nameInputField={"caregiver_phone_number"}
                    register={register}
                    onChangeFunc={() => {
                      trigger("caregiver_phone_number");
                    }}
                    rules={{
                      required:
                        watchFields[0] &&
                        watchFields[0].value !== "No primary caregiver"
                          ? "This field is required"
                          : false,
                    }}
                    control={control}
                    errors={errors}
                    md="12"
                  />
                  <TextareaInput
                    name={"reason_to_work"}
                    label={"Why do you want to work?"}
                    placeholder={"motivation and reason for working"}
                    required
                    register={{
                      ...register("reason_to_work", {
                        required: "This field is required",
                      }),
                    }}
                    errors={errors}
                    md="12"
                  />
                  <TextareaInput
                    name={"interests"}
                    label={"What are your hobbies and interests?"}
                    placeholder={"what you like to do in your free time"}
                    register={{
                      ...register("interests"),
                    }}
                    errors={errors}
                    md="12"
                  />
                </Row>
              </PreviewCard>

              <PreviewCard className="card-bordered">
                <Row className="g-3">
                  <Col size="12">
                    <BlockTitle tag="h5">
                      Your highest level of education
                    </BlockTitle>
                    <BlockDes className="text-soft">
                      <p>
                        The school that you studied at, and the course you
                        attended, for your highest level of education
                      </p>
                    </BlockDes>
                    <div className="nk-divider divider mt-3 mb-1" />
                  </Col>
                  <SelectField
                    name={"highest_education"}
                    label={"Highest level of education"}
                    control={control}
                    rules={{ required: "This field is required" }}
                    options={[
                      { value: "PSLE", label: "PSLE" },
                      {
                        value: "GCE N-Level",
                        label: "GCE N-Level",
                      },
                      {
                        value: "GCE O-Level",
                        label: "GCE O-Level",
                      },
                      {
                        value: "GCE A-Level",
                        label: "GCE A-Level",
                      },
                      {
                        value: "Nitec",
                        label: "Nitec",
                      },
                      {
                        value: "Higher Nitec",
                        label: "Higher Nitec",
                      },
                      {
                        value: "Diploma",
                        label: "Diploma",
                      },
                      {
                        value: "Bachelor's",
                        label: "Bachelor's",
                      },
                      {
                        value: "Masters",
                        label: "Masters",
                      },
                      {
                        value: "PHD",
                        label: "PHD",
                      },
                      {
                        value: "Others",
                        label: "Others",
                      },
                    ]}
                    errors={errors}
                    md="12"
                  />
                  <TextInput
                    name={"highest_education_school"}
                    label={"School"}
                    placeholder={"name of school"}
                    required
                    register={{
                      ...register("highest_education_school", {
                        required: "This field is required",
                      }),
                    }}
                    errors={errors}
                    md="12"
                  />
                  <TextInput
                    name={"highest_education_course"}
                    label={"Course"}
                    placeholder={"name of course or programme"}
                    required
                    register={{
                      ...register("highest_education_course", {
                        required: "This field is required",
                      }),
                    }}
                    errors={errors}
                    md="12"
                  />
                  <DatePickerInput
                    name={"time_start_highest_education"}
                    label={"From"}
                    showMonthYearPicker
                    dateFormat={"MM/yyyy"}
                    placeholder={"mm/yyyy"}
                    control={control}
                    rules={{ required: "This field is required" }}
                    errors={errors}
                    md="12"
                  />
                  <DatePickerInput
                    name={"time_end_highest_education"}
                    label={"To"}
                    showMonthYearPicker
                    dateFormat={"MM/yyyy"}
                    placeholder={"mm/yyyy"}
                    control={control}
                    rules={{ required: "This field is required" }}
                    errors={errors}
                    md="12"
                  />
                </Row>
              </PreviewCard>

              <PreviewCard className="card-bordered">
                <Row className="g-3">
                  <Col size="12">
                    <BlockTitle tag="h5">
                      Your most recent employment
                    </BlockTitle>
                    <BlockDes className="text-soft">
                      <p>
                        If you have not been employed before, please leave this
                        section empty
                      </p>
                    </BlockDes>
                    <div className="nk-divider divider mt-3 mb-1" />
                  </Col>
                  <TextInput
                    name={"recent_employer"}
                    label={"Employer"}
                    placeholder={"name of employer"}
                    register={{
                      ...register("recent_employer"),
                    }}
                    onBlurFunc={() => {
                      trigger([
                        "recent_job_role",
                        "recent_first_month_of_work",
                        "reason_leaving_job",
                      ]);
                    }}
                    errors={errors}
                    md="12"
                  />
                  <TextInput
                    name={"recent_job_role"}
                    label={"Your job title/role"}
                    placeholder={"e.g. admin"}
                    required={watchFields[1]}
                    onChangeFunc={() => {
                      trigger("recent_job_role");
                    }}
                    register={{
                      ...register("recent_job_role", {
                        required: watchFields[1]
                          ? "This field is required"
                          : false,
                      }),
                    }}
                    errors={errors}
                    md="12"
                  />
                  <DatePickerInput
                    name={"recent_first_month_of_work"}
                    label={"First month of work"}
                    showMonthYearPicker
                    dateFormat={"MM/yyyy"}
                    placeholder={"mm/yyyy"}
                    rules={{
                      required: watchFields[1]
                        ? "This field is required"
                        : false,
                    }}
                    onChangeFunc={() => {
                      trigger("recent_first_month_of_work");
                    }}
                    control={control}
                    errors={errors}
                    md="12"
                  />
                  <DatePickerInput
                    name={"recent_last_month_of_work"}
                    label={"Last month of work"}
                    caption={
                      "Leave this empty if you are still currently working"
                    }
                    showMonthYearPicker
                    dateFormat={"MM/yyyy"}
                    placeholder={"mm/yyyy"}
                    control={control}
                    errors={errors}
                    md="12"
                  />
                  <TextareaInput
                    name={"reason_leaving_job"}
                    label={"Reason for leaving your job"}
                    placeholder={
                      "why did you leave, or why are you leaving your job?"
                    }
                    required={watchFields[1]}
                    register={{
                      ...register("reason_leaving_job", {
                        required: watchFields[1]
                          ? "This field is required"
                          : false,
                      }),
                    }}
                    onChangeFunc={() => {
                      trigger("reason_leaving_job");
                    }}
                    errors={errors}
                    md="12"
                  />
                </Row>
              </PreviewCard>

              <PreviewCard className="card-bordered">
                <Row className="g-3">
                  <Col size="12">
                    <BlockTitle tag="h5">Your disability details</BlockTitle>
                    <div className="nk-divider divider mt-3 mb-1" />
                  </Col>
                  <SelectField
                    name={"disability_name"}
                    label={"What is your disability?"}
                    caption={
                      "Select Others if your disability is not in this list"
                    }
                    control={control}
                    rules={{ required: "This field is required" }}
                    options={[
                      {
                        value: "Attention Deficit Hyperactivity Disorder",
                        label: "Attention Deficit Hyperactivity Disorder",
                      },
                      {
                        value: "Autism Spectrum Disorder",
                        label: "Autism Spectrum Disorder",
                      },
                      { value: "Blind - full", label: "Blind - full" },
                      { value: "Blind - partial", label: "Blind - partial" },
                      { value: "Cerebral Palsy", label: "Cerebral Palsy" },
                      { value: "Deaf - full", label: "Deaf - full" },
                      { value: "Deaf - partial", label: "Deaf - partial" },
                      { value: "Dyslexia", label: "Dyslexia" },
                      {
                        value: "Mild Intellectual Disability",
                        label: "Mild Intellectual Disability",
                      },
                      { value: "Physical", label: "Physical" },
                      { value: "Others", label: "Others" },
                    ]}
                    errors={errors}
                    md="12"
                  />
                  <TextareaInput
                    name={"disability_description"}
                    label={"Please tell us more about your disability"}
                    caption={
                      "If you selected Others from the list, please indicate your disability here too"
                    }
                    placeholder={"share more details about your disability"}
                    required
                    register={{
                      ...register("disability_description", {
                        required: "This field is required",
                      }),
                    }}
                    errors={errors}
                    md="12"
                  />
                  <DatePickerInput
                    name={"disability_year_diagnosed"}
                    label={
                      "Which year were you diagnosed with your disability?"
                    }
                    showYearPicker
                    dateFormat={"yyyy"}
                    placeholder={"yyyy"}
                    maxDate={new Date()}
                    rules={{ required: "This field is required" }}
                    control={control}
                    errors={errors}
                    md="12"
                  />
                  <TextareaInput
                    name={"ongoing_therapy"}
                    label={
                      "Please share any therapy that you are attending or attended before"
                    }
                    caption={
                      "Indicate as N.A if you are not attending or have not attended any theraphy"
                    }
                    placeholder={
                      "e.g weekly physical therapy sessions at hospital"
                    }
                    required
                    register={{
                      ...register("ongoing_therapy", {
                        required: "This field is required",
                      }),
                    }}
                    errors={errors}
                    md="12"
                  />
                  <TextareaInput
                    name={"medical_history"}
                    label={"Please share your medical history"}
                    caption={
                      "Indicate as N.A. if you do not have any medical history"
                    }
                    placeholder={"e.g epilepsy"}
                    required
                    register={{
                      ...register("medical_history", {
                        required: "This field is required",
                      }),
                    }}
                    errors={errors}
                    md="12"
                  />
                </Row>
              </PreviewCard>

              <PreviewCard className="card-bordered">
                <Row className="g-3">
                  <Col size="12">
                    <BlockTitle tag="h5">
                      Comfort level with technology
                    </BlockTitle>
                    <div className="nk-divider divider mt-3 mb-1" />
                  </Col>
                </Row>
                <label className="form-label my-2">
                  How comfortable are you using these technology?{" "}
                  <span className="required-asterisk">*</span>
                </label>
                <table className="table table-borderless text-center">
                  <thead>
                    <tr>
                      <th scope="col" className="border-0"></th>
                      <th scope="col" className="border-0 w-110px">
                        Not sure
                      </th>
                      <th scope="col" className="border-0 w-110px">
                        Familiar
                      </th>
                      <th scope="col" className="border-0 w-110px">
                        Very good
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-left pl-0">Microsoft Excel</td>
                      <td>
                        <input
                          type="radio"
                          {...register("comfort_level_technology_excel")}
                          name="comfort_level_technology_excel"
                          value="Not sure"
                        />
                      </td>
                      <td>
                        <input
                          type="radio"
                          {...register("comfort_level_technology_excel")}
                          name="comfort_level_technology_excel"
                          value="Familiar"
                        />
                      </td>
                      <td>
                        <input
                          type="radio"
                          {...register("comfort_level_technology_excel")}
                          name="comfort_level_technology_excel"
                          value="Very good"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left pl-0">Microsoft Word</td>
                      <td>
                        <input
                          type="radio"
                          {...register("comfort_level_technology_word")}
                          name="comfort_level_technology_word"
                          value="Not sure"
                        />
                      </td>
                      <td>
                        <input
                          type="radio"
                          {...register("comfort_level_technology_word")}
                          name="comfort_level_technology_word"
                          value="Familiar"
                        />
                      </td>
                      <td>
                        <input
                          type="radio"
                          {...register("comfort_level_technology_word")}
                          name="comfort_level_technology_word"
                          value="Very good"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left pl-0">Microsoft Powerpoint</td>
                      <td>
                        <input
                          type="radio"
                          {...register("comfort_level_technology_powerpoint")}
                          name="comfort_level_technology_powerpoint"
                          value="Not sure"
                        />
                      </td>
                      <td>
                        <input
                          type="radio"
                          {...register("comfort_level_technology_powerpoint")}
                          name="comfort_level_technology_powerpoint"
                          value="Familiar"
                        />
                      </td>
                      <td>
                        <input
                          type="radio"
                          {...register("comfort_level_technology_powerpoint")}
                          name="comfort_level_technology_powerpoint"
                          value="Very good"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left pl-0">
                        Video Tools (Zoom, Microsoft Team, etc.)
                      </td>
                      <td>
                        <input
                          type="radio"
                          {...register("comfort_level_technology_video_tools")}
                          name="comfort_level_technology_video_tools"
                          value="Not sure"
                        />
                      </td>
                      <td>
                        <input
                          type="radio"
                          {...register("comfort_level_technology_video_tools")}
                          name="comfort_level_technology_video_tools"
                          value="Familiar"
                        />
                      </td>
                      <td>
                        <input
                          type="radio"
                          {...register("comfort_level_technology_video_tools")}
                          name="comfort_level_technology_video_tools"
                          value="Very good"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left pl-0">
                        Email (Gmail, Hotmail, etc.)
                      </td>
                      <td>
                        <input
                          type="radio"
                          {...register("comfort_level_technology_email")}
                          name="comfort_level_technology_email"
                          value="Not sure"
                        />
                      </td>
                      <td>
                        <input
                          type="radio"
                          {...register("comfort_level_technology_email")}
                          name="comfort_level_technology_email"
                          value="Familiar"
                        />
                      </td>
                      <td>
                        <input
                          type="radio"
                          {...register("comfort_level_technology_email")}
                          name="comfort_level_technology_email"
                          value="Very good"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left pl-0">Google Search</td>
                      <td>
                        <input
                          type="radio"
                          {...register(
                            "comfort_level_technology_google_search"
                          )}
                          name="comfort_level_technology_google_search"
                          value="Not sure"
                        />
                      </td>
                      <td>
                        <input
                          type="radio"
                          {...register(
                            "comfort_level_technology_google_search"
                          )}
                          name="comfort_level_technology_google_search"
                          value="Familiar"
                        />
                      </td>
                      <td>
                        <input
                          type="radio"
                          {...register(
                            "comfort_level_technology_google_search"
                          )}
                          name="comfort_level_technology_google_search"
                          value="Very good"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </PreviewCard>

              <PreviewCard className="card-bordered">
                <Row className="g-3">
                  <Col size="12">
                    <BlockTitle tag="h5">General</BlockTitle>
                    <div className="nk-divider divider mt-3 mb-1" />
                  </Col>
                  <TextareaInput
                    name={"who_filled_form"}
                    label={"Who filled this form?"}
                    caption={
                      "If someone helped you fill this form, please indicate who"
                    }
                    placeholder={"e.g. myself, caregiver, etc"}
                    required
                    register={{
                      ...register("who_filled_form", {
                        required: "This field is required",
                      }),
                    }}
                    errors={errors}
                    md="12"
                  />
                  <TextareaInput
                    name={"more_things_sharing"}
                    label={
                      "Is there anything else that you want to let us know?"
                    }
                    register={{
                      ...register("more_things_sharing"),
                    }}
                    errors={errors}
                    md="12"
                  />
                  <TextareaInput
                    name={"how_know_inclus"}
                    label={"How did you know about Inclus?"}
                    required
                    register={{
                      ...register("how_know_inclus", {
                        required: "This field is required",
                      }),
                    }}
                    errors={errors}
                    md="12"
                  />
                </Row>
              </PreviewCard>

              {!formData?.is_submitted && (
                <Row className="mt-4 mb-2 mx-0 justify-content-around">
                  {/* <Col md="6" className=""> */}
                  <Button
                    type="button"
                    size={"lg"}
                    className={"btn-alt w-min-350px"}
                    // disabled={isSubmitLoading}
                    onClick={() => {
                      onSave("save");
                    }}
                  >
                    {/* {isSubmitLoading && <Spinner type="grow" size="sm" />} */}
                    <span>SAVE AND CONTINUE ANOTHER TIME</span>
                  </Button>
                  {/* </Col> */}
                  {/* <Col md="6" className=""> */}
                  <Button
                    color="primary"
                    type="submit"
                    size={"lg"}
                    className={"w-min-200px justify-content-center"}
                    disabled={isSubmitLoading}
                  >
                    {isSubmitLoading && <Spinner type="grow" size="sm" />}
                    <span>SUBMIT</span>
                  </Button>
                  {/* </Col> */}
                </Row>
              )}

              {formData?.is_submitted && accessToken && (
                <Row className="mt-4 mb-2 justify-content-around">
                  <Button
                    type="button"
                    size={"lg"}
                    className={"btn-alt w-min-350px"}
                    // disabled={isSubmitLoading}
                    onClick={() => {}}
                  >
                    {/* {isSubmitLoading && <Spinner type="grow" size="sm" />} */}
                    <span>ACCEPT FORM</span>
                  </Button>
                </Row>
              )}
            </form>
          </Block>
        </div>
      </Content>

      <Modal
        isOpen={saveModal}
        toggle={() => setSaveModal(false)}
        // backdrop={backdrop}
        className="modal-dialog-centered"
        size="lg"
      >
        <ModalBody>
          <div className="d-flex flex-column p-2 fs-16px">
            <h4 className="title align-self-center">
              Please take note of your form reference code: {params.code}
            </h4>
            <p className="mt-3">
              An email will be sent to the email address you provided with the
              details you need to continue filling the form in the future.
            </p>
            <p>
              If you need to contact us, please send an email to{" "}
              <span className="custom-link">hello@inclus.sg</span>
            </p>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default IntroductionForm;
