const menu = [
  {
    icon: "dashboard",
    text: "Dashboard",
    link: "/admin",
    active: false,
  },
  {
    icon: "dashboard",
    text: "Chat",
    active: false,
    link: "/admin/chat",
  },
  {
    icon: "dashboard",
    text: "Organisations",
    active: false,
    subMenu: [
      {
        text: "Organisations",
        link: "/admin/organisations",
      },
    ],
  },
  {
    icon: "dashboard",
    text: "Users",
    active: false,
    subMenu: [
      {
        text: "Users",
        link: "/admin/users",
      },
      {
        text: "Candidates",
        link: "/admin/candidates",
      },
      {
        text: "Caregivers",
        link: "/admin/caregivers",
      },
    ],
  },
  {
    icon: "dashboard",
    text: "Forms",
    active: false,
    subMenu: [
      {
        text: "Introduction",
        link: "/admin/introduction-forms",
      },
      {
        text: "Assessment",
        link: "/admin/assessment-forms",
      },
      {
        text: "Inclus Web Submissions",
        subMenu: [
          {
            text: "Careers",
            link: "/admin/web-submissions/careers",
          },
          {
            text: "Contact Us",
            link: "/admin/web-submissions/contact-us",
          },
        ],
      },
    ],
  },
  {
    icon: "dashboard",
    text: "Authorization",
    active: false,
    subMenu: [
      {
        text: "Permission",
        link: "/admin/authorization/permission",
      },
      {
        text: "Role",
        link: "/admin/authorization/role",
      },
    ],
  },
  {
    icon: "dashboard",
    text: "Config",
    active: false,
    subMenu: [
      {
        text: "Form Email Sending",
        link: "/admin/config/form-email-sending",
      },
      {
        text: "Menu Data",
        link: "/admin/config/menu-data",
      },
    ],
  },
  {
    icon: "dashboard",
    text: "Dropdown Data",
    active: false,
    subMenu: [
      {
        text: "Sex",
        link: "/admin/dropdown-config/sex",
      },
      {
        text: "Religions",
        link: "/admin/dropdown-config/religions",
      },
      {
        text: "Interaction Types",
        link: "/admin/dropdown-config/interaction-types",
      },
      {
        text: "Medical Types",
        link: "/admin/dropdown-config/medical-types",
      },
      {
        text: "Races",
        link: "/admin/dropdown-config/races",
      },
      {
        text: "Education Levels",
        link: "/admin/dropdown-config/education-levels",
      },
      {
        text: "Org Contact Roles",
        link: "/admin/dropdown-config/org-contact-roles",
      },
      {
        text: "Org Supporting Doc Types",
        link: "/admin/dropdown-config/org-supporting-doc-types",
      },
      {
        text: "Opportunity Types",
        link: "/admin/dropdown-config/opportunity-types",
      },
      {
        text: "Opportunity Contract Types",
        link: "/admin/dropdown-config/opportunity-contract-types",
      },
      {
        text: "Opportunity Commitment Types",
        link: "/admin/dropdown-config/opportunity-commitment-types",
      },
      {
        text: "Observation Grading",
        link: "/admin/dropdown-config/observation-grading",
      },
    ],
  },
  {
    heading: "Mobile Apps",
  },
  // {
  //   icon: "users",
  //   text: "Account Management",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Account",
  //       link: "/admin/account",
  //     },
  //   ],
  // },
  {
    icon: "home",
    text: "Home Management",
    active: false,
    subMenu: [
      {
        text: "Quotes",
        link: "/admin/quotes",
      },
    ],
  },
  {
    icon: "task-c",
    text: "Goal Management",
    active: false,
    subMenu: [
      {
        text: "Goals",
        link: "/admin/goals",
      },
      {
        text: "Goal Frequency",
        link: "/admin/goal-frequency",
      },
      {
        text: "Goal Relationship",
        link: "/admin/goal-relationship",
      },
      {
        text: "Goal Tracking",
        link: "/admin/goal-tracking",
      },
    ],
  },
  {
    icon: "help-alt",
    text: "Support Management",
    active: false,
    subMenu: [
      {
        text: "Attendance",
        link: "/admin/attendance",
      },
      {
        text: "Support Metrics",
        subMenu: [
          {
            text: "Soft skills",
            link: "/admin/support-metrics/soft-skills",
          },
          {
            text: "Hard skills",
            link: "/admin/support-metrics/hard-skills",
          },
          {
            text: "Motor skills",
            link: "/admin/support-metrics/motor-skills",
          },
          {
            text: "Others",
            link: "/admin/support-metrics/others",
          },
        ],
      },
      {
        text: "Support Logs",
        link: "/admin/support-logs",
      },
      {
        text: "Location Tracking",
        link: "/admin/location-tracking",
      },
    ],
  },
  {
    icon: "happy",
    text: "Emotional Management",
    active: false,
    subMenu: [
      {
        text: "Emotional Logs",
        link: "/admin/emotional-logs",
      },
      {
        text: "Emotion Types",
        link: "/admin/emotion-types",
      },
    ],
  },
  {
    icon: "briefcase",
    text: "Employment Management",
    active: false,
    subMenu: [
      {
        text: "Employments",
        link: "/admin/employments",
      },
      {
        text: "Pro Schedules",
        link: "/admin/pro-schedules",
      },
    ],
  },
];
export default menu;
