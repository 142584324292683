import { Outlet, useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { stagingHostname } from "../../constants";
import IntroductionIndex from "../forms/IntroductionIndex";

const SiteCheck = () => {
  const location = useLocation();

  if (process.env.NODE_ENV === "production") {
    const hostname = window.location.hostname;
    if (hostname === stagingHostname) {
      return <IntroductionIndex />;
    }
  }

  return (
    <Navigate
      to="/admin"
      replace
      state={{ previousPathName: location.pathname }}
    />
  );
};

export default SiteCheck;
