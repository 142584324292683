import { Col } from "../Components";
import { countryFlagsMap } from "../../constants";
import { useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import classNames from "classnames";
import { Controller } from "react-hook-form";

export const TextInput = ({
  name,
  label,
  caption,
  obscure,
  errors,
  md,
  required,
  onBlurFunc,
  onChangeFunc,
  isDisabled,
  type,
  ...props
}) => {
  const { outerClassName, ...innerProps } = props;
  const outerClasses = classNames({
    "form-group-wrapper": true,
    [`${outerClassName}`]: outerClassName,
  });
  const splittedS = name.split(".");
  let errField = null;
  if (errors[splittedS[0]] && splittedS.length === 3) {
    const arrFieldName = splittedS[0];
    const index = parseInt(splittedS[1]);
    const fieldName = splittedS[2];

    errField = errors[arrFieldName][index]?.[fieldName];
  }
  let typ = type ?? "text";
  if (obscure) {
    typ = "password";
  }

  return (
    <Col md={md ?? "6"} className={outerClasses}>
      <div className="form-group">
        {label && (
          <label className="form-label">
            {label} {required && <span className="required-asterisk">*</span>}
          </label>
        )}
        {caption && <div className="form-caption">{caption}</div>}
        <div className="form-control-wrap">
          <input
            disabled={isDisabled}
            type={typ}
            className="form-control"
            name={name}
            {...innerProps.register}
            {...innerProps}
            onChange={(e) => {
              let { onChange } = { ...innerProps.register };
              onChange(e);
              if (onChangeFunc) {
                onChangeFunc();
              }
            }}
            onBlur={(e) => {
              let { onBlur } = { ...innerProps.register };
              onBlur(e);
              if (onBlurFunc) {
                onBlurFunc();
              }
            }}
            onKeyDown={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          {errors && errors[name] && (
            <span className="invalid">{errors[name].message}</span>
          )}
          {errors && errField && (
            <span className="invalid">{errField.message}</span>
          )}
        </div>
      </div>
    </Col>
  );
};

export const PhoneNumberWithCountryCodeInput = ({
  label,
  caption,
  nameCode,
  nameInputField,
  control,
  errors,
  register,
  defaultCountryCode,
  onChangeFunc,
  md,
  rules,
  ...props
}) => {
  const [select, setSelect] = useState(false);
  const { onChange, onBlur, name, ref } = register(nameInputField, {
    onBlur: (e) => setSelect(false),
    required: null,
    pattern: {
      value: /^[0-9]*$/i,
      message: "Invalid phone number",
    },
    ...rules,
  });
  return (
    <Col md={md ?? "6"} className={"form-group-wrapper"}>
      <div className="form-group">
        <label className="form-label">
          {label}{" "}
          {rules && rules.required && (
            <span className="required-asterisk">*</span>
          )}
        </label>
        {caption && <div className="form-caption">{caption}</div>}
        <div className="form-control-wrap" style={{ display: "flex" }}>
          <Controller
            name={nameCode}
            control={control}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { isTouched, isDirty, error },
              formState,
            }) => {
              return (
                <ReactFlagsSelect
                  selected={value}
                  onSelect={onChange}
                  selectButtonClassName="react-flag-select-button"
                  searchable
                  showSelectedLabel={false}
                  customLabels={countryFlagsMap}
                  className={classNames({
                    "form-control": true,
                    "react-flag-select-wrapper": true,
                    "focus-form": select,
                  })}
                />
              );
            }}
          />
          <input
            className="form-control"
            onFocus={() => {
              setSelect(true);
            }}
            placeholder="12345678"
            onChange={(e) => {
              onChange(e);
              if (onChangeFunc) {
                onChangeFunc();
              }
            }}
            onBlur={onBlur}
            name={name}
            ref={ref}
            onKeyDown={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
        </div>
        {errors && errors[nameInputField] && (
          <span className="invalid">{errors[nameInputField].message}</span>
        )}
      </div>
    </Col>
  );
};

export default TextInput;
