import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { useContext, useEffect } from "react";
import { ChatContext } from "../chat/ChatContext";
import { useLazyGetChatDataQuery } from "../../redux/api/chat/chatApi";

const ServiceLayer = () => {
  const { user } = useSelector((state) => state.auth);
  const { connectUser, setupUser } = useContext(ChatContext);
  const [getChatData] = useLazyGetChatDataQuery();

  useEffect(() => {
    if (user) {
      getChatData({ email: user.email }).then((res) => {
        if (res.isSuccess) {
          // connectUser({
          //   token: res.data.token,
          //   userID: res.data.userID,
          // });
          setupUser({
            appID: res.data.appID,
            token: res.data.token,
            userID: res.data.userID,
          });
        }
      });

      // getChatData({ email: user.email }).then((res) => {
      //   if (res.isSuccess) {
      //     openConnection({
      //       chatToken: res.data.chatToken,
      //       userID: res.data.userID,
      //     });
      //   }
      // });
      // connectUser({
      //   userID: "tangquocvinh7695",
      // })
    }
  }, [user]);

  return <Outlet />;
};

export default ServiceLayer;
