import * as flags from "react-flags-select";

export const FILE_CHUNK_SIZE = 10_000_000;

export const countryFlagsMap = {
  AF: {
    primary: "Afghanistan",
    secondary: "+93",
    flagsComponent: flags.Af,
  },
  AL: {
    primary: "Albania",
    secondary: "+355",
    flagsComponent: flags.Al,
  },
  DZ: {
    primary: "Algeria",
    secondary: "+213",
    flagsComponent: flags.Dz,
  },
  AS: {
    primary: "American Samoa",
    secondary: "+1684",
    flagsComponent: flags.As,
  },
  AD: {
    primary: "Andorra",
    secondary: "+376",
    flagsComponent: flags.Ad,
  },
  AO: {
    primary: "Angola",
    secondary: "+244",
    flagsComponent: flags.Ao,
  },
  AI: {
    primary: "Anguilla",
    secondary: "+1264",
    flagsComponent: flags.Ai,
  },
  AG: {
    primary: "Antigua and Barbuda",
    secondary: "+1268",
    flagsComponent: flags.Ag,
  },
  AR: {
    primary: "Argentina",
    secondary: "+54",
    flagsComponent: flags.Ar,
  },
  AM: {
    primary: "Armenia",
    secondary: "+374",
    flagsComponent: flags.Am,
  },
  AW: {
    primary: "Aruba",
    secondary: "+297",
    flagsComponent: flags.Aw,
  },
  AU: {
    primary: "Australia",
    secondary: "+61",
    flagsComponent: flags.Au,
  },
  AT: {
    primary: "Austria",
    secondary: "+43",
    flagsComponent: flags.At,
  },
  AZ: {
    primary: "Azerbaijan",
    secondary: "+994",
    flagsComponent: flags.Az,
  },
  BS: {
    primary: "Bahamas",
    secondary: "+1242",
    flagsComponent: flags.Bs,
  },
  BH: {
    primary: "Bahrain",
    secondary: "+973",
    flagsComponent: flags.Bh,
  },
  BD: {
    primary: "Bangladesh",
    secondary: "+880",
    flagsComponent: flags.Bd,
  },
  BB: {
    primary: "Barbados",
    secondary: "+1246",
    flagsComponent: flags.Bb,
  },
  BY: {
    primary: "Belarus",
    secondary: "+375",
    flagsComponent: flags.By,
  },
  BE: {
    primary: "Belgium",
    secondary: "+32",
    flagsComponent: flags.Be,
  },
  BZ: {
    primary: "Belize",
    secondary: "+501",
    flagsComponent: flags.Bz,
  },
  BJ: {
    primary: "Benin",
    secondary: "+229",
    flagsComponent: flags.Bj,
  },
  BM: {
    primary: "Bermuda",
    secondary: "+1441",
    flagsComponent: flags.Bm,
  },
  BT: {
    primary: "Bhutan",
    secondary: "+975",
    flagsComponent: flags.Bt,
  },
  BO: {
    primary: "Bolivia, Plurinational State of",
    secondary: "+591",
    flagsComponent: flags.Bo,
  },
  BA: {
    primary: "Bosnia and Herzegovina",
    secondary: "+387",
    flagsComponent: flags.Ba,
  },
  BW: {
    primary: "Botswana",
    secondary: "+267",
    flagsComponent: flags.Bw,
  },
  BR: {
    primary: "Brazil",
    secondary: "+55",
    flagsComponent: flags.Br,
  },
  IO: {
    primary: "British Indian Ocean Territory",
    secondary: "+246",
    flagsComponent: flags.Io,
  },
  BG: {
    primary: "Bulgaria",
    secondary: "+359",
    flagsComponent: flags.Bg,
  },
  BF: {
    primary: "Burkina Faso",
    secondary: "+226",
    flagsComponent: flags.Bf,
  },
  BI: {
    primary: "Burundi",
    secondary: "+257",
    flagsComponent: flags.Bi,
  },
  KH: {
    primary: "Cambodia",
    secondary: "+855",
    flagsComponent: flags.Kh,
  },
  CM: {
    primary: "Cameroon",
    secondary: "+237",
    flagsComponent: flags.Cm,
  },
  CA: {
    primary: "Canada",
    secondary: "+1",
    flagsComponent: flags.Ca,
  },
  CV: {
    primary: "Cape Verde",
    secondary: "+238",
    flagsComponent: flags.Cv,
  },
  KY: {
    primary: "Cayman Islands",
    secondary: "+ 345",
    flagsComponent: flags.Ky,
  },
  CF: {
    primary: "Central African Republic",
    secondary: "+236",
    flagsComponent: flags.Cf,
  },
  TD: {
    primary: "Chad",
    secondary: "+235",
    flagsComponent: flags.Td,
  },
  CL: {
    primary: "Chile",
    secondary: "+56",
    flagsComponent: flags.Cl,
  },
  CN: {
    primary: "China",
    secondary: "+86",
    flagsComponent: flags.Cn,
  },
  CO: {
    primary: "Colombia",
    secondary: "+57",
    flagsComponent: flags.Co,
  },
  KM: {
    primary: "Comoros",
    secondary: "+269",
    flagsComponent: flags.Km,
  },
  CG: {
    primary: "Congo",
    secondary: "+242",
    flagsComponent: flags.Cg,
  },
  CD: {
    primary: "Democratic Republic of the Congo",
    secondary: "+243",
    flagsComponent: flags.Cd,
  },
  CK: {
    primary: "Cook Islands",
    secondary: "+682",
    flagsComponent: flags.Ck,
  },
  CR: {
    primary: "Costa Rica",
    secondary: "+506",
    flagsComponent: flags.Cr,
  },
  CI: {
    primary: "Côte d'Ivoire",
    secondary: "+225",
    flagsComponent: flags.Ci,
  },
  HR: {
    primary: "Croatia",
    secondary: "+385",
    flagsComponent: flags.Hr,
  },
  CU: {
    primary: "Cuba",
    secondary: "+53",
    flagsComponent: flags.Cu,
  },
  CY: {
    primary: "Cyprus",
    secondary: "+357",
    flagsComponent: flags.Cy,
  },
  CZ: {
    primary: "Czech Republic",
    secondary: "+420",
    flagsComponent: flags.Cz,
  },
  DK: {
    primary: "Denmark",
    secondary: "+45",
    flagsComponent: flags.Dk,
  },
  DJ: {
    primary: "Djibouti",
    secondary: "+253",
    flagsComponent: flags.Dj,
  },
  DM: {
    primary: "Dominica",
    secondary: "+1767",
    flagsComponent: flags.Dm,
  },
  DO: {
    primary: "Dominican Republic",
    secondary: "+1849",
    flagsComponent: flags.Do,
  },
  EC: {
    primary: "Ecuador",
    secondary: "+593",
    flagsComponent: flags.Ec,
  },
  EG: {
    primary: "Egypt",
    secondary: "+20",
    flagsComponent: flags.Eg,
  },
  SV: {
    primary: "El Salvador",
    secondary: "+503",
    flagsComponent: flags.Sv,
  },
  GQ: {
    primary: "Equatorial Guinea",
    secondary: "+240",
    flagsComponent: flags.Gq,
  },
  ER: {
    primary: "Eritrea",
    secondary: "+291",
    flagsComponent: flags.Er,
  },
  EE: {
    primary: "Estonia",
    secondary: "+372",
    flagsComponent: flags.Ee,
  },
  ET: {
    primary: "Ethiopia",
    secondary: "+251",
    flagsComponent: flags.Et,
  },
  FK: {
    primary: "Falkland Islands (Malvinas)",
    secondary: "+500",
    flagsComponent: flags.Fk,
  },
  FO: {
    primary: "Faroe Islands",
    secondary: "+298",
    flagsComponent: flags.Fo,
  },
  FJ: {
    primary: "Fiji",
    secondary: "+679",
    flagsComponent: flags.Fj,
  },
  FI: {
    primary: "Finland",
    secondary: "+358",
    flagsComponent: flags.Fi,
  },
  FR: {
    primary: "France",
    secondary: "+33",
    flagsComponent: flags.Fr,
  },
  PF: {
    primary: "French Polynesia",
    secondary: "+689",
    flagsComponent: flags.Pf,
  },
  GA: {
    primary: "Gabon",
    secondary: "+241",
    flagsComponent: flags.Ga,
  },
  GM: {
    primary: "Gambia",
    secondary: "+220",
    flagsComponent: flags.Gm,
  },
  GE: {
    primary: "Georgia",
    secondary: "+995",
    flagsComponent: flags.Ge,
  },
  DE: {
    primary: "Germany",
    secondary: "+49",
    flagsComponent: flags.De,
  },
  GH: {
    primary: "Ghana",
    secondary: "+233",
    flagsComponent: flags.Gh,
  },
  GI: {
    primary: "Gibraltar",
    secondary: "+350",
    flagsComponent: flags.Gi,
  },
  GR: {
    primary: "Greece",
    secondary: "+30",
    flagsComponent: flags.Gr,
  },
  GL: {
    primary: "Greenland",
    secondary: "+299",
    flagsComponent: flags.Gl,
  },
  GD: {
    primary: "Grenada",
    secondary: "+1473",
    flagsComponent: flags.Gd,
  },
  GU: {
    primary: "Guam",
    secondary: "+1671",
    flagsComponent: flags.Gu,
  },
  GT: {
    primary: "Guatemala",
    secondary: "+502",
    flagsComponent: flags.Gt,
  },
  GG: {
    primary: "Guernsey",
    secondary: "+44",
    flagsComponent: flags.Gg,
  },
  GN: {
    primary: "Guinea",
    secondary: "+224",
    flagsComponent: flags.Gn,
  },
  GW: {
    primary: "Guinea-Bissau",
    secondary: "+245",
    flagsComponent: flags.Gw,
  },
  HT: {
    primary: "Haiti",
    secondary: "+509",
    flagsComponent: flags.Ht,
  },
  HN: {
    primary: "Honduras",
    secondary: "+504",
    flagsComponent: flags.Hn,
  },
  HK: {
    primary: "Hong Kong",
    secondary: "+852",
    flagsComponent: flags.Hk,
  },
  HU: {
    primary: "Hungary",
    secondary: "+36",
    flagsComponent: flags.Hu,
  },
  IS: {
    primary: "Iceland",
    secondary: "+354",
    flagsComponent: flags.Is,
  },
  IN: {
    primary: "India",
    secondary: "+91",
    flagsComponent: flags.In,
  },
  ID: {
    primary: "Indonesia",
    secondary: "+62",
    flagsComponent: flags.Id,
  },
  IR: {
    primary: "Iran, Islamic Republic of",
    secondary: "+98",
    flagsComponent: flags.Ir,
  },
  IQ: {
    primary: "Iraq",
    secondary: "+964",
    flagsComponent: flags.Iq,
  },
  IE: {
    primary: "Ireland",
    secondary: "+353",
    flagsComponent: flags.Ie,
  },
  IM: {
    primary: "Isle of Man",
    secondary: "+44",
    flagsComponent: flags.Im,
  },
  IL: {
    primary: "Israel",
    secondary: "+972",
    flagsComponent: flags.Il,
  },
  IT: {
    primary: "Italy",
    secondary: "+39",
    flagsComponent: flags.It,
  },
  JM: {
    primary: "Jamaica",
    secondary: "+1876",
    flagsComponent: flags.Jm,
  },
  JP: {
    primary: "Japan",
    secondary: "+81",
    flagsComponent: flags.Jp,
  },
  JE: {
    primary: "Jersey",
    secondary: "+44",
    flagsComponent: flags.Je,
  },
  JO: {
    primary: "Jordan",
    secondary: "+962",
    flagsComponent: flags.Jo,
  },
  KZ: {
    primary: "Kazakhstan",
    secondary: "+77",
    flagsComponent: flags.Kz,
  },
  KE: {
    primary: "Kenya",
    secondary: "+254",
    flagsComponent: flags.Ke,
  },
  KI: {
    primary: "Kiribati",
    secondary: "+686",
    flagsComponent: flags.Ki,
  },
  KP: {
    primary: "North Korea",
    secondary: "+850",
    flagsComponent: flags.Kp,
  },
  KR: {
    primary: "South Korea",
    secondary: "+82",
    flagsComponent: flags.Kr,
  },
  KW: {
    primary: "Kuwait",
    secondary: "+965",
    flagsComponent: flags.Kw,
  },
  KG: {
    primary: "Kyrgyzstan",
    secondary: "+996",
    flagsComponent: flags.Kg,
  },
  LA: {
    primary: "Lao People's Democratic Republic",
    secondary: "+856",
    flagsComponent: flags.La,
  },
  LV: {
    primary: "Latvia",
    secondary: "+371",
    flagsComponent: flags.Lv,
  },
  LB: {
    primary: "Lebanon",
    secondary: "+961",
    flagsComponent: flags.Lb,
  },
  LS: {
    primary: "Lesotho",
    secondary: "+266",
    flagsComponent: flags.Ls,
  },
  LR: {
    primary: "Liberia",
    secondary: "+231",
    flagsComponent: flags.Lr,
  },
  LY: {
    primary: "Libya",
    secondary: "+218",
    flagsComponent: flags.Ly,
  },
  LI: {
    primary: "Liechtenstein",
    secondary: "+423",
    flagsComponent: flags.Li,
  },
  LT: {
    primary: "Lithuania",
    secondary: "+370",
    flagsComponent: flags.Lt,
  },
  LU: {
    primary: "Luxembourg",
    secondary: "+352",
    flagsComponent: flags.Lu,
  },
  MO: {
    primary: "Macao",
    secondary: "+853",
    flagsComponent: flags.Mo,
  },
  MK: {
    primary: "Republic of Macedonia",
    secondary: "+389",
    flagsComponent: flags.Mk,
  },
  MG: {
    primary: "Madagascar",
    secondary: "+261",
    flagsComponent: flags.Mg,
  },
  MW: {
    primary: "Malawi",
    secondary: "+265",
    flagsComponent: flags.Mw,
  },
  MY: {
    primary: "Malaysia",
    secondary: "+60",
    flagsComponent: flags.My,
  },
  MV: {
    primary: "Maldives",
    secondary: "+960",
    flagsComponent: flags.Mv,
  },
  ML: {
    primary: "Mali",
    secondary: "+223",
    flagsComponent: flags.Ml,
  },
  MT: {
    primary: "Malta",
    secondary: "+356",
    flagsComponent: flags.Mt,
  },
  MH: {
    primary: "Marshall Islands",
    secondary: "+692",
    flagsComponent: flags.Mh,
  },
  MQ: {
    primary: "Martinique",
    secondary: "+596",
    flagsComponent: flags.Mq,
  },
  MR: {
    primary: "Mauritania",
    secondary: "+222",
    flagsComponent: flags.Mr,
  },
  MU: {
    primary: "Mauritius",
    secondary: "+230",
    flagsComponent: flags.Mu,
  },
  MX: {
    primary: "Mexico",
    secondary: "+52",
    flagsComponent: flags.Mx,
  },
  FM: {
    primary: "Micronesia, Federated States of",
    secondary: "+691",
    flagsComponent: flags.Fm,
  },
  MD: {
    primary: "Republic of Moldova",
    secondary: "+373",
    flagsComponent: flags.Md,
  },
  MC: {
    primary: "Monaco",
    secondary: "+377",
    flagsComponent: flags.Mc,
  },
  MN: {
    primary: "Mongolia",
    secondary: "+976",
    flagsComponent: flags.Mn,
  },
  ME: {
    primary: "Montenegro",
    secondary: "+382",
    flagsComponent: flags.Me,
  },
  MS: {
    primary: "Montserrat",
    secondary: "+1664",
    flagsComponent: flags.Ms,
  },
  MA: {
    primary: "Morocco",
    secondary: "+212",
    flagsComponent: flags.Ma,
  },
  MZ: {
    primary: "Mozambique",
    secondary: "+258",
    flagsComponent: flags.Mz,
  },
  MM: {
    primary: "Myanmar",
    secondary: "+95",
    flagsComponent: flags.Mm,
  },
  NA: {
    primary: "Namibia",
    secondary: "+264",
    flagsComponent: flags.Na,
  },
  NR: {
    primary: "Nauru",
    secondary: "+674",
    flagsComponent: flags.Nr,
  },
  NP: {
    primary: "Nepal",
    secondary: "+977",
    flagsComponent: flags.Np,
  },
  NL: {
    primary: "Netherlands",
    secondary: "+31",
    flagsComponent: flags.Nl,
  },
  NZ: {
    primary: "New Zealand",
    secondary: "+64",
    flagsComponent: flags.Nz,
  },
  NI: {
    primary: "Nicaragua",
    secondary: "+505",
    flagsComponent: flags.Ni,
  },
  NE: {
    primary: "Niger",
    secondary: "+227",
    flagsComponent: flags.Ne,
  },
  NG: {
    primary: "Nigeria",
    secondary: "+234",
    flagsComponent: flags.Ng,
  },
  NU: {
    primary: "Niue",
    secondary: "+683",
    flagsComponent: flags.Nu,
  },
  NF: {
    primary: "Norfolk Island",
    secondary: "+672",
    flagsComponent: flags.Nf,
  },
  MP: {
    primary: "Northern Mariana Islands",
    secondary: "+1670",
    flagsComponent: flags.Mp,
  },
  NO: {
    primary: "Norway",
    secondary: "+47",
    flagsComponent: flags.No,
  },
  OM: {
    primary: "Oman",
    secondary: "+968",
    flagsComponent: flags.Om,
  },
  PK: {
    primary: "Pakistan",
    secondary: "+92",
    flagsComponent: flags.Pk,
  },
  PW: {
    primary: "Palau",
    secondary: "+680",
    flagsComponent: flags.Pw,
  },
  PS: {
    primary: "Palestinian Territory",
    secondary: "+970",
    flagsComponent: flags.Ps,
  },
  PA: {
    primary: "Panama",
    secondary: "+507",
    flagsComponent: flags.Pa,
  },
  PG: {
    primary: "Papua New Guinea",
    secondary: "+675",
    flagsComponent: flags.Pg,
  },
  PY: {
    primary: "Paraguay",
    secondary: "+595",
    flagsComponent: flags.Py,
  },
  PE: {
    primary: "Peru",
    secondary: "+51",
    flagsComponent: flags.Pe,
  },
  PH: {
    primary: "Philippines",
    secondary: "+63",
    flagsComponent: flags.Ph,
  },
  PN: {
    primary: "Pitcairn",
    secondary: "+872",
    flagsComponent: flags.Pn,
  },
  PL: {
    primary: "Poland",
    secondary: "+48",
    flagsComponent: flags.Pl,
  },
  PT: {
    primary: "Portugal",
    secondary: "+351",
    flagsComponent: flags.Pt,
  },
  PR: {
    primary: "Puerto Rico",
    secondary: "+1939",
    flagsComponent: flags.Pr,
  },
  QA: {
    primary: "Qatar",
    secondary: "+974",
    flagsComponent: flags.Qa,
  },
  RO: {
    primary: "Romania",
    secondary: "+40",
    flagsComponent: flags.Ro,
  },
  RU: {
    primary: "Russia",
    secondary: "+7",
    flagsComponent: flags.Ru,
  },
  RW: {
    primary: "Rwanda",
    secondary: "+250",
    flagsComponent: flags.Rw,
  },
  KN: {
    primary: "Saint Kitts and Nevis",
    secondary: "+1869",
    flagsComponent: flags.Kn,
  },
  LC: {
    primary: "Saint Lucia",
    secondary: "+1758",
    flagsComponent: flags.Lc,
  },
  WS: {
    primary: "Samoa",
    secondary: "+685",
    flagsComponent: flags.Ws,
  },
  SM: {
    primary: "San Marino",
    secondary: "+378",
    flagsComponent: flags.Sm,
  },
  ST: {
    primary: "Sao Tome and Principe",
    secondary: "+239",
    flagsComponent: flags.St,
  },
  SA: {
    primary: "Saudi Arabia",
    secondary: "+966",
    flagsComponent: flags.Sa,
  },
  SN: {
    primary: "Senegal",
    secondary: "+221",
    flagsComponent: flags.Sn,
  },
  RS: {
    primary: "Serbia",
    secondary: "+381",
    flagsComponent: flags.Rs,
  },
  SC: {
    primary: "Seychelles",
    secondary: "+248",
    flagsComponent: flags.Sc,
  },
  SL: {
    primary: "Sierra Leone",
    secondary: "+232",
    flagsComponent: flags.Sl,
  },
  SG: {
    primary: "Singapore",
    secondary: "+65",
    flagsComponent: flags.Sg,
  },
  SK: {
    primary: "Slovakia",
    secondary: "+421",
    flagsComponent: flags.Sk,
  },
  SI: {
    primary: "Slovenia",
    secondary: "+386",
    flagsComponent: flags.Si,
  },
  SB: {
    primary: "Solomon Islands",
    secondary: "+677",
    flagsComponent: flags.Sb,
  },
  SO: {
    primary: "Somalia",
    secondary: "+252",
    flagsComponent: flags.So,
  },
  ZA: {
    primary: "South Africa",
    secondary: "+27",
    flagsComponent: flags.Za,
  },
  SS: {
    primary: "South Sudan",
    secondary: "+211",
    flagsComponent: flags.Ss,
  },
  ES: {
    primary: "Spain",
    secondary: "+34",
    flagsComponent: flags.Es,
  },
  LK: {
    primary: "Sri Lanka",
    secondary: "+94",
    flagsComponent: flags.Lk,
  },
  SD: {
    primary: "Sudan",
    secondary: "+249",
    flagsComponent: flags.Sd,
  },
  SR: {
    primary: "Suriname",
    secondary: "+597",
    flagsComponent: flags.Sr,
  },
  SZ: {
    primary: "Swaziland",
    secondary: "+268",
    flagsComponent: flags.Sz,
  },
  SE: {
    primary: "Sweden",
    secondary: "+46",
    flagsComponent: flags.Se,
  },
  CH: {
    primary: "Switzerland",
    secondary: "+41",
    flagsComponent: flags.Ch,
  },
  SY: {
    primary: "Syria",
    secondary: "+963",
    flagsComponent: flags.Sy,
  },
  TW: {
    primary: "Taiwan",
    secondary: "+886",
    flagsComponent: flags.Tw,
  },
  TJ: {
    primary: "Tajikistan",
    secondary: "+992",
    flagsComponent: flags.Tj,
  },
  TZ: {
    primary: "Tanzania",
    secondary: "+255",
    flagsComponent: flags.Tz,
  },
  TH: {
    primary: "Thailand",
    secondary: "+66",
    flagsComponent: flags.Th,
  },
  TG: {
    primary: "Togo",
    secondary: "+228",
    flagsComponent: flags.Tg,
  },
  TK: {
    primary: "Tokelau",
    secondary: "+690",
    flagsComponent: flags.Tk,
  },
  TO: {
    primary: "Tonga",
    secondary: "+676",
    flagsComponent: flags.To,
  },
  TT: {
    primary: "Trinidad and Tobago",
    secondary: "+1868",
    flagsComponent: flags.Tt,
  },
  TN: {
    primary: "Tunisia",
    secondary: "+216",
    flagsComponent: flags.Tn,
  },
  TR: {
    primary: "Turkey",
    secondary: "+90",
    flagsComponent: flags.Tr,
  },
  TM: {
    primary: "Turkmenistan",
    secondary: "+993",
    flagsComponent: flags.Tm,
  },
  TC: {
    primary: "Turks and Caicos Islands",
    secondary: "+1649",
    flagsComponent: flags.Tc,
  },
  TV: {
    primary: "Tuvalu",
    secondary: "+688",
    flagsComponent: flags.Tv,
  },
  UG: {
    primary: "Uganda",
    secondary: "+256",
    flagsComponent: flags.Ug,
  },
  UA: {
    primary: "Ukraine",
    secondary: "+380",
    flagsComponent: flags.Ua,
  },
  AE: {
    primary: "United Arab Emirates",
    secondary: "+971",
    flagsComponent: flags.Ae,
  },
  GB: {
    primary: "United Kingdom",
    secondary: "+44",
    flagsComponent: flags.Gb,
  },
  US: {
    primary: "United States",
    secondary: "+1",
    flagsComponent: flags.Us,
  },
  UY: {
    primary: "Uruguay",
    secondary: "+598",
    flagsComponent: flags.Uy,
  },
  UZ: {
    primary: "Uzbekistan",
    secondary: "+998",
    flagsComponent: flags.Uz,
  },
  VU: {
    primary: "Vanuatu",
    secondary: "+678",
    flagsComponent: flags.Vu,
  },
  VE: {
    primary: "Venezuela, Bolivarian Republic of",
    secondary: "+58",
    flagsComponent: flags.Ve,
  },
  VN: {
    primary: "Viet Nam",
    secondary: "+84",
    flagsComponent: flags.Vn,
  },
  VI: {
    primary: "Virgin Islands",
    secondary: "+1340",
    flagsComponent: flags.Vi,
  },
  YE: {
    primary: "Yemen",
    secondary: "+967",
    flagsComponent: flags.Ye,
  },
  ZM: {
    primary: "Zambia",
    secondary: "+260",
    flagsComponent: flags.Zm,
  },
  ZW: {
    primary: "Zimbabwe",
    secondary: "+263",
    flagsComponent: flags.Zw,
  },
};

export const languages = {
  aa: {
    name: "Afar",
    native: "Afar",
  },
  ab: {
    name: "Abkhazian",
    native: "Аҧсуа",
  },
  af: {
    name: "Afrikaans",
    native: "Afrikaans",
  },
  ak: {
    name: "Akan",
    native: "Akana",
  },
  am: {
    name: "Amharic",
    native: "አማርኛ",
  },
  an: {
    name: "Aragonese",
    native: "Aragonés",
  },
  ar: {
    name: "Arabic",
    native: "العربية",
    rtl: 1,
  },
  as: {
    name: "Assamese",
    native: "অসমীয়া",
  },
  av: {
    name: "Avar",
    native: "Авар",
  },
  ay: {
    name: "Aymara",
    native: "Aymar",
  },
  az: {
    name: "Azerbaijani",
    native: "Azərbaycanca / آذربايجان",
  },
  ba: {
    name: "Bashkir",
    native: "Башҡорт",
  },
  be: {
    name: "Belarusian",
    native: "Беларуская",
  },
  bg: {
    name: "Bulgarian",
    native: "Български",
  },
  bh: {
    name: "Bihari",
    native: "भोजपुरी",
  },
  bi: {
    name: "Bislama",
    native: "Bislama",
  },
  bm: {
    name: "Bambara",
    native: "Bamanankan",
  },
  bn: {
    name: "Bengali",
    native: "বাংলা",
  },
  bo: {
    name: "Tibetan",
    native: "བོད་ཡིག / Bod skad",
  },
  br: {
    name: "Breton",
    native: "Brezhoneg",
  },
  bs: {
    name: "Bosnian",
    native: "Bosanski",
  },
  ca: {
    name: "Catalan",
    native: "Català",
  },
  ce: {
    name: "Chechen",
    native: "Нохчийн",
  },
  ch: {
    name: "Chamorro",
    native: "Chamoru",
  },
  co: {
    name: "Corsican",
    native: "Corsu",
  },
  cr: {
    name: "Cree",
    native: "Nehiyaw",
  },
  cs: {
    name: "Czech",
    native: "Čeština",
  },
  cu: {
    name: "Old Church Slavonic / Old Bulgarian",
    native: "словѣньскъ / slověnĭskŭ",
  },
  cv: {
    name: "Chuvash",
    native: "Чăваш",
  },
  cy: {
    name: "Welsh",
    native: "Cymraeg",
  },
  da: {
    name: "Danish",
    native: "Dansk",
  },
  de: {
    name: "German",
    native: "Deutsch",
  },
  dv: {
    name: "Divehi",
    native: "ދިވެހިބަސް",
    rtl: 1,
  },
  dz: {
    name: "Dzongkha",
    native: "ཇོང་ཁ",
  },
  ee: {
    name: "Ewe",
    native: "Ɛʋɛ",
  },
  el: {
    name: "Greek",
    native: "Ελληνικά",
  },
  en: {
    name: "English",
    native: "English",
  },
  eo: {
    name: "Esperanto",
    native: "Esperanto",
  },
  es: {
    name: "Spanish",
    native: "Español",
  },
  et: {
    name: "Estonian",
    native: "Eesti",
  },
  eu: {
    name: "Basque",
    native: "Euskara",
  },
  fa: {
    name: "Persian",
    native: "فارسی",
    rtl: 1,
  },
  ff: {
    name: "Peul",
    native: "Fulfulde",
  },
  fi: {
    name: "Finnish",
    native: "Suomi",
  },
  fj: {
    name: "Fijian",
    native: "Na Vosa Vakaviti",
  },
  fo: {
    name: "Faroese",
    native: "Føroyskt",
  },
  fr: {
    name: "French",
    native: "Français",
  },
  fy: {
    name: "West Frisian",
    native: "Frysk",
  },
  ga: {
    name: "Irish",
    native: "Gaeilge",
  },
  gd: {
    name: "Scottish Gaelic",
    native: "Gàidhlig",
  },
  gl: {
    name: "Galician",
    native: "Galego",
  },
  gn: {
    name: "Guarani",
    native: "Avañe'ẽ",
  },
  gu: {
    name: "Gujarati",
    native: "ગુજરાતી",
  },
  gv: {
    name: "Manx",
    native: "Gaelg",
  },
  ha: {
    name: "Hausa",
    native: "هَوُسَ",
    rtl: 1,
  },
  he: {
    name: "Hebrew",
    native: "עברית",
    rtl: 1,
  },
  hi: {
    name: "Hindi",
    native: "हिन्दी",
  },
  ho: {
    name: "Hiri Motu",
    native: "Hiri Motu",
  },
  hr: {
    name: "Croatian",
    native: "Hrvatski",
  },
  ht: {
    name: "Haitian",
    native: "Krèyol ayisyen",
  },
  hu: {
    name: "Hungarian",
    native: "Magyar",
  },
  hy: {
    name: "Armenian",
    native: "Հայերեն",
  },
  hz: {
    name: "Herero",
    native: "Otsiherero",
  },
  ia: {
    name: "Interlingua",
    native: "Interlingua",
  },
  id: {
    name: "Indonesian",
    native: "Bahasa Indonesia",
  },
  ie: {
    name: "Interlingue",
    native: "Interlingue",
  },
  ig: {
    name: "Igbo",
    native: "Igbo",
  },
  ii: {
    name: "Sichuan Yi",
    native: "ꆇꉙ / 四川彝语",
  },
  ik: {
    name: "Inupiak",
    native: "Iñupiak",
  },
  io: {
    name: "Ido",
    native: "Ido",
  },
  is: {
    name: "Icelandic",
    native: "Íslenska",
  },
  it: {
    name: "Italian",
    native: "Italiano",
  },
  iu: {
    name: "Inuktitut",
    native: "ᐃᓄᒃᑎᑐᑦ",
  },
  ja: {
    name: "Japanese",
    native: "日本語",
  },
  jv: {
    name: "Javanese",
    native: "Basa Jawa",
  },
  ka: {
    name: "Georgian",
    native: "ქართული",
  },
  kg: {
    name: "Kongo",
    native: "KiKongo",
  },
  ki: {
    name: "Kikuyu",
    native: "Gĩkũyũ",
  },
  kj: {
    name: "Kuanyama",
    native: "Kuanyama",
  },
  kk: {
    name: "Kazakh",
    native: "Қазақша",
  },
  kl: {
    name: "Greenlandic",
    native: "Kalaallisut",
  },
  km: {
    name: "Cambodian",
    native: "ភាសាខ្មែរ",
  },
  kn: {
    name: "Kannada",
    native: "ಕನ್ನಡ",
  },
  ko: {
    name: "Korean",
    native: "한국어",
  },
  kr: {
    name: "Kanuri",
    native: "Kanuri",
  },
  ks: {
    name: "Kashmiri",
    native: "कश्मीरी / كشميري",
    rtl: 1,
  },
  ku: {
    name: "Kurdish",
    native: "Kurdî / كوردی",
    rtl: 1,
  },
  kv: {
    name: "Komi",
    native: "Коми",
  },
  kw: {
    name: "Cornish",
    native: "Kernewek",
  },
  ky: {
    name: "Kyrgyz",
    native: "Кыргызча",
  },
  la: {
    name: "Latin",
    native: "Latina",
  },
  lb: {
    name: "Luxembourgish",
    native: "Lëtzebuergesch",
  },
  lg: {
    name: "Ganda",
    native: "Luganda",
  },
  li: {
    name: "Limburgian",
    native: "Limburgs",
  },
  ln: {
    name: "Lingala",
    native: "Lingála",
  },
  lo: {
    name: "Laotian",
    native: "ລາວ / Pha xa lao",
  },
  lt: {
    name: "Lithuanian",
    native: "Lietuvių",
  },
  lu: {
    name: "Luba-Katanga",
    native: "Tshiluba",
  },
  lv: {
    name: "Latvian",
    native: "Latviešu",
  },
  mg: {
    name: "Malagasy",
    native: "Malagasy",
  },
  mh: {
    name: "Marshallese",
    native: "Kajin Majel / Ebon",
  },
  mi: {
    name: "Maori",
    native: "Māori",
  },
  mk: {
    name: "Macedonian",
    native: "Македонски",
  },
  ml: {
    name: "Malayalam",
    native: "മലയാളം",
  },
  mn: {
    name: "Mongolian",
    native: "Монгол",
  },
  mo: {
    name: "Moldovan",
    native: "Moldovenească",
  },
  mr: {
    name: "Marathi",
    native: "मराठी",
  },
  ms: {
    name: "Malay",
    native: "Bahasa Melayu",
  },
  mt: {
    name: "Maltese",
    native: "bil-Malti",
  },
  my: {
    name: "Burmese",
    native: "မြန်မာစာ",
  },
  na: {
    name: "Nauruan",
    native: "Dorerin Naoero",
  },
  nb: {
    name: "Norwegian Bokmål",
    native: "Norsk bokmål",
  },
  nd: {
    name: "North Ndebele",
    native: "Sindebele",
  },
  ne: {
    name: "Nepali",
    native: "नेपाली",
  },
  ng: {
    name: "Ndonga",
    native: "Oshiwambo",
  },
  nl: {
    name: "Dutch",
    native: "Nederlands",
  },
  nn: {
    name: "Norwegian Nynorsk",
    native: "Norsk nynorsk",
  },
  no: {
    name: "Norwegian",
    native: "Norsk",
  },
  nr: {
    name: "South Ndebele",
    native: "isiNdebele",
  },
  nv: {
    name: "Navajo",
    native: "Diné bizaad",
  },
  ny: {
    name: "Chichewa",
    native: "Chi-Chewa",
  },
  oc: {
    name: "Occitan",
    native: "Occitan",
  },
  oj: {
    name: "Ojibwa",
    native: "ᐊᓂᔑᓈᐯᒧᐎᓐ / Anishinaabemowin",
  },
  om: {
    name: "Oromo",
    native: "Oromoo",
  },
  or: {
    name: "Oriya",
    native: "ଓଡ଼ିଆ",
  },
  os: {
    name: "Ossetian / Ossetic",
    native: "Иронау",
  },
  pa: {
    name: "Panjabi / Punjabi",
    native: "ਪੰਜਾਬੀ / पंजाबी / پنجابي",
  },
  pi: {
    name: "Pali",
    native: "Pāli / पाऴि",
  },
  pl: {
    name: "Polish",
    native: "Polski",
  },
  ps: {
    name: "Pashto",
    native: "پښتو",
    rtl: 1,
  },
  pt: {
    name: "Portuguese",
    native: "Português",
  },
  qu: {
    name: "Quechua",
    native: "Runa Simi",
  },
  rm: {
    name: "Raeto Romance",
    native: "Rumantsch",
  },
  rn: {
    name: "Kirundi",
    native: "Kirundi",
  },
  ro: {
    name: "Romanian",
    native: "Română",
  },
  ru: {
    name: "Russian",
    native: "Русский",
  },
  rw: {
    name: "Rwandi",
    native: "Kinyarwandi",
  },
  sa: {
    name: "Sanskrit",
    native: "संस्कृतम्",
  },
  sc: {
    name: "Sardinian",
    native: "Sardu",
  },
  sd: {
    name: "Sindhi",
    native: "सिनधि",
  },
  se: {
    name: "Northern Sami",
    native: "Sámegiella",
  },
  sg: {
    name: "Sango",
    native: "Sängö",
  },
  sh: {
    name: "Serbo-Croatian",
    native: "Srpskohrvatski / Српскохрватски",
  },
  si: {
    name: "Sinhalese",
    native: "සිංහල",
  },
  sk: {
    name: "Slovak",
    native: "Slovenčina",
  },
  sl: {
    name: "Slovenian",
    native: "Slovenščina",
  },
  sm: {
    name: "Samoan",
    native: "Gagana Samoa",
  },
  sn: {
    name: "Shona",
    native: "chiShona",
  },
  so: {
    name: "Somalia",
    native: "Soomaaliga",
  },
  sq: {
    name: "Albanian",
    native: "Shqip",
  },
  sr: {
    name: "Serbian",
    native: "Српски",
  },
  ss: {
    name: "Swati",
    native: "SiSwati",
  },
  st: {
    name: "Southern Sotho",
    native: "Sesotho",
  },
  su: {
    name: "Sundanese",
    native: "Basa Sunda",
  },
  sv: {
    name: "Swedish",
    native: "Svenska",
  },
  sw: {
    name: "Swahili",
    native: "Kiswahili",
  },
  ta: {
    name: "Tamil",
    native: "தமிழ்",
  },
  te: {
    name: "Telugu",
    native: "తెలుగు",
  },
  tg: {
    name: "Tajik",
    native: "Тоҷикӣ",
  },
  th: {
    name: "Thai",
    native: "ไทย / Phasa Thai",
  },
  ti: {
    name: "Tigrinya",
    native: "ትግርኛ",
  },
  tk: {
    name: "Turkmen",
    native: "Туркмен / تركمن",
  },
  tl: {
    name: "Tagalog / Filipino",
    native: "Tagalog",
  },
  tn: {
    name: "Tswana",
    native: "Setswana",
  },
  to: {
    name: "Tonga",
    native: "Lea Faka-Tonga",
  },
  tr: {
    name: "Turkish",
    native: "Türkçe",
  },
  ts: {
    name: "Tsonga",
    native: "Xitsonga",
  },
  tt: {
    name: "Tatar",
    native: "Tatarça",
  },
  tw: {
    name: "Twi",
    native: "Twi",
  },
  ty: {
    name: "Tahitian",
    native: "Reo Mā`ohi",
  },
  ug: {
    name: "Uyghur",
    native: "Uyƣurqə / ئۇيغۇرچە",
  },
  uk: {
    name: "Ukrainian",
    native: "Українська",
  },
  ur: {
    name: "Urdu",
    native: "اردو",
    rtl: 1,
  },
  uz: {
    name: "Uzbek",
    native: "Ўзбек",
  },
  ve: {
    name: "Venda",
    native: "Tshivenḓa",
  },
  vi: {
    name: "Vietnamese",
    native: "Tiếng Việt",
  },
  vo: {
    name: "Volapük",
    native: "Volapük",
  },
  wa: {
    name: "Walloon",
    native: "Walon",
  },
  wo: {
    name: "Wolof",
    native: "Wollof",
  },
  xh: {
    name: "Xhosa",
    native: "isiXhosa",
  },
  yi: {
    name: "Yiddish",
    native: "ייִדיש",
    rtl: 1,
  },
  yo: {
    name: "Yoruba",
    native: "Yorùbá",
  },
  za: {
    name: "Zhuang",
    native: "Cuengh / Tôô / 壮语",
  },
  zh: {
    name: "Chinese",
    native: "中文",
  },
  zu: {
    name: "Zulu",
    native: "isiZulu",
  },
};

export const metricsSoftSkillsType = "soft_skills";
export const metricsHardSkillsType = "hard_skills";
export const metricsMotorSkillsType = "motor_skills";
export const metricsOtherSkillsType = "other_skills";

export const assessmentRecommendationOptions = [
  { value: "Not suitable", label: "Not suitable" },
  { value: "Training", label: "Suitable: training" },
  { value: "Enrichment", label: "Suitable: enrichment" },
  { value: "Employment", label: "Suitable: employment" },
];

export const pipelineStatusOptions = [
  { value: "completed", label: "Completed" },
  { value: "not_completed", label: "Not Completed" },
  { value: "skipped", label: "Skipped" },
];

export const logDirectionOptions = [
  { value: "Inbound", label: "Inbound" },
  { value: "Outbound", label: "Outbound" },
];

export const billableDirectionOptions = [
  { value: "Inbound", label: "Inbound" },
  { value: "Outbound", label: "Outbound" },
];

export const addressTypeOptions = [
  { value: "Primary", label: "Primary" },
  { value: "Secondary", label: "Secondary" },
];

export const orgSupportingDocStatusOptions = [
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
];

export const opportunityStatusOptions = [
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
];

export const permissionActionOptions = [
  { value: "GET", label: "GET" },
  { value: "POST", label: "POST" },
  { value: "PUT", label: "PUT" },
  { value: "DELETE", label: "DELETE" },
];

export const nioIconData = {
  "doc-checked": (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114 113.9">
      <path
        fill="#c4cefe"
        d="M83.84 108.24l-48.31-7.86a3.55 3.55 0 01-3.1-4l12.2-69.48a3.66 3.66 0 014.29-2.8l48.32 7.8a3.56 3.56 0 013.09 4l-12.2 69.52a3.66 3.66 0 01-4.29 2.82z"
      ></path>
      <path
        fill="#c4cefe"
        d="M29.73 103.29L74.66 96a3.41 3.41 0 002.84-3.94L65.4 22.95a3.5 3.5 0 00-4-2.82l-44.96 7.28a3.41 3.41 0 00-2.84 3.94l12.1 69.11a3.52 3.52 0 004.03 2.83z"
      ></path>
      <rect
        width="66"
        height="88"
        x="22"
        y="17.9"
        fill="#6576ff"
        rx="3"
        ry="3"
      ></rect>
      <rect
        width="48"
        height="10"
        x="31"
        y="85.9"
        fill="#fff"
        rx="1.5"
        ry="1.5"
      ></rect>
      <rect
        width="48"
        height="5"
        x="31"
        y="27.9"
        fill="#e3e7fe"
        rx="1"
        ry="1"
      ></rect>
      <rect
        width="23"
        height="3"
        x="31"
        y="37.9"
        fill="#c4cefe"
        rx="1"
        ry="1"
      ></rect>
      <rect
        width="20"
        height="3"
        x="59"
        y="37.9"
        fill="#c4cefe"
        rx="1"
        ry="1"
      ></rect>
      <rect
        width="23"
        height="3"
        x="31"
        y="45.9"
        fill="#c4cefe"
        rx="1"
        ry="1"
      ></rect>
      <rect
        width="20"
        height="3"
        x="59"
        y="45.9"
        fill="#c4cefe"
        rx="1"
        ry="1"
      ></rect>
      <rect
        width="48"
        height="3"
        x="31"
        y="52.9"
        fill="#e3e7fe"
        rx="1"
        ry="1"
      ></rect>
      <rect
        width="23"
        height="3"
        x="31"
        y="60.9"
        fill="#c4cefe"
        rx="1"
        ry="1"
      ></rect>
      <path
        fill="#9cabff"
        d="M94.5 113.9a.5.5 0 01-.5-.5v-1.5h-1.5a.5.5 0 010-1H94v-1.5a.5.5 0 011 0v1.5h1.5a.5.5 0 010 1H95v1.5a.5.5 0 01-.5.5zM12.5 82.9a.5.5 0 01-.5-.5v-1.5h-1.5a.5.5 0 010-1H12v-1.5a.5.5 0 011 0v1.5h1.5a.5.5 0 010 1H13v1.5a.5.5 0 01-.5.5zM3 10.9a3 3 0 113-3 3 3 0 01-3 3zm0-5a2 2 0 102 2 2 2 0 00-2-2zM109.5 68.9a4.5 4.5 0 114.5-4.5 4.51 4.51 0 01-4.5 4.5zm0-8a3.5 3.5 0 103.5 3.5 3.5 3.5 0 00-3.5-3.5z"
      ></path>
      <path
        fill="#2ec98a"
        d="M103.66 4.95A5.66 5.66 0 0099.57.9a47.45 47.45 0 00-18.09 0 5.66 5.66 0 00-4.08 4.06 47.51 47.51 0 000 18.1 5.67 5.67 0 004.08 4.07 47.57 47.57 0 009 .87 47.78 47.78 0 009.06-.87 5.66 5.66 0 004.08-4.09 47.45 47.45 0 00.04-18.09z"
      ></path>
      <path
        fill="#fff"
        d="M96.66 10.71l-1.35 1.47c-1.9 2.06-3.88 4.21-5.77 6.3a1.29 1.29 0 01-1 .42 1.27 1.27 0 01-1-.42c-1.09-1.2-2.19-2.39-3.28-3.56a1.29 1.29 0 011.88-1.76c.78.84 1.57 1.68 2.35 2.54 1.6-1.76 3.25-3.55 4.83-5.27l1.35-1.46a1.29 1.29 0 011.9 1.74z"
      ></path>
    </svg>
  ),
  "file-pdf": (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
      <path
        fill="#f26b6b"
        d="M50 61H22a6 6 0 01-6-6V22l9-11h25a6 6 0 016 6v38a6 6 0 01-6 6z"
      ></path>
      <path
        fill="#f4c9c9"
        d="M25 20.556A1.444 1.444 0 0123.556 22H16l9-11z"
      ></path>
      <path
        fill="#fff"
        d="M46.334 44.538a4.326 4.326 0 00-2.528-1.429 22.436 22.436 0 00-4.561-.383 19.356 19.356 0 01-3.425-4.772 56.508 56.508 0 001.375-6.086 2.339 2.339 0 00-.462-1.845 1.943 1.943 0 00-1.516-.753h-.001a1.685 1.685 0 00-1.39.697c-1.149 1.526-.363 5.219-.194 5.946a12.612 12.612 0 00.724 2.147 33.322 33.322 0 01-2.49 6.106 20.347 20.347 0 00-5.979 3.44 2.568 2.568 0 00-.886 1.827 1.802 1.802 0 00.634 1.306 2.061 2.061 0 001.395.531 2.244 2.244 0 001.459-.546 20.068 20.068 0 004.29-5.357 20.838 20.838 0 015.938-1.186 33.75 33.75 0 004.243 3.605 2.64 2.64 0 003.416-.236 2.08 2.08 0 00-.042-3.012zM27.62 49.623a.834.834 0 01-1.084.042.42.42 0 01-.167-.27c-.002-.066.027-.315.44-.736a18.038 18.038 0 013.762-2.368 17.26 17.26 0 01-2.95 3.332zm7.283-18.775a.343.343 0 01.315-.151.6.6 0 01.465.239.853.853 0 01.168.672c-.164.92-.424 2.38-.852 4.117l-.037-.151c-.356-1.523-.609-3.996-.059-4.726zm-1.179 12.703a34.973 34.973 0 001.52-3.767 21.248 21.248 0 002.224 3.05 21.857 21.857 0 00-3.744.717zm11.706 2.97a1.308 1.308 0 01-1.695.088 33.203 33.203 0 01-3.004-2.43 20.968 20.968 0 012.835.334 2.97 2.97 0 011.74.965c.533.633.296.87.123 1.043z"
      ></path>
    </svg>
  ),
  test: <div>test</div>,
};

export const stagingHostname = "croissance.forms.inclus.sg"; // staging hostname
