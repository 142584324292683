import { useEffect, useState } from "react";
import { Modal, ModalBody, Spinner } from "reactstrap";
import { useParams } from "react-router-dom";
import {
  PreviewCard,
  Block,
  BlockHeadContent,
  BlockHead,
  BlockDes,
  BlockTitle,
  Row,
  Col,
  Button,
  Icon,
  ModalComponent,
} from "../../components/Components";
import PageContainer from "../../layout/page-container/PageContainer";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import Error404Modern from "../error/404-modern";
import {
  useLazyGetSDQuestionsQuery,
  useSubmitSDQFormMutation,
  useGetSDQFormQuery,
  useSaveSDQFormMutation,
} from "../../redux/api/forms/formApi";
import LoadingPage from "../../pages/loading/LoadingPage";
import { useRef } from "react";
import { toast } from "react-toastify";
import SimpleBar from "simplebar-react";
import moment from "moment";

const SDQForm = () => {
  const ref = useRef(null);
  const params = useParams();
  const { data, isFetching, isSuccess, isError, error } = useGetSDQFormQuery({
    code: params.code,
  });
  const [
    getQuestions,
    {
      data: qData,
      isFetching: isGetQuestionsFetching,
      isLoading: isGetQuestionsLoading,
      isSuccess: isGetQuestionsSuccess,
      isError: isGetQuestionsError,
      error: getQuestionsError,
    },
  ] = useLazyGetSDQuestionsQuery();
  const [
    submitSDQForm,
    {
      data: submitDataCallback,
      isLoading: isSubmitLoading,
      isError: isSubmitError,
      isSuccess: isSubmitSuccess,
      error: submitError,
    },
  ] = useSubmitSDQFormMutation();
  const [
    saveForm,
    {
      data: saveData,
      isLoading: isSaveLoading,
      isError: isSaveError,
      isSuccess: isSaveSuccess,
    },
  ] = useSaveSDQFormMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = useForm({
    defaultValues: {
      sdq_answers: [],
    },
  });
  const { fields, replace, update } = useFieldArray({
    control,
    name: "sdq_answers",
  });
  const [dataResult, setDataResult] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    ref.current = setInterval(() => onSave("autosave"), 30000);

    return () => {
      if (ref.current) {
        clearInterval(ref.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isSuccess && data) {
      if (data.is_completed) {
        if (ref.current) {
          clearInterval(ref.current);
        }
        setDataResult(data);
      } else {
        getQuestions({ limit: 1000 });
      }
    }
    if (isError && error && error.status !== 404) {
      toast.error(error?.data?.message);
    }
  }, [isError, isSuccess]);

  const processData = (data) => {
    data.reference_code = params.code;
    return data;
  };

  useEffect(() => {
    if (isGetQuestionsSuccess && qData && data) {
      qData.questions.forEach((e, idx) => {
        if (data.sdq_answers) {
          let q = data.sdq_answers.find((obj) => obj.code === e.code);
          update(idx, q);
        } else {
          update(idx, { code: e.code });
        }
      });
    }
  }, [isGetQuestionsSuccess]);

  const onFormSubmit = (data, e) => {
    data = processData(data);
    data.type = "submit";
    submitSDQForm(data);
  };

  const onSave = (type) => {
    let data = getValues();
    data = processData(data);
    data.type = type;
    saveForm(data);
  };

  useEffect(() => {
    if (isSubmitSuccess && submitDataCallback) {
      if (ref.current) {
        clearInterval(ref.current);
      }
      setDataResult(submitDataCallback);
    }
    if (isSubmitError && submitError) {
      toast.error(submitError?.data?.message);
    }
  }, [isSubmitSuccess, isSubmitError]);

  const onDetailsClicked = () => {
    getQuestions({ limit: 1000 });
    setModalOpen(true);
  };

  if (isFetching) {
    return <LoadingPage />;
  }
  if (isError && error && (error.status === 404 || error.status === 400)) {
    return <Error404Modern />;
  }

  const FormContent = () => {
    if (dataResult) {
      return (
        <SimpleBar className="w-100" forceVisible="x" autoHide={false}>
          <div className="table-wrapper">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col" className="w-min-200px">
                    Date time
                  </th>
                  <th scope="col" className="w-min-200px">
                    By
                  </th>
                  <th scope="col" className="w-min-175px">
                    Total difficulties
                  </th>
                  <th scope="col" className="w-min-175px">
                    Prone to externalising
                  </th>
                  <th scope="col" className="w-min-175px">
                    Prone to internalising
                  </th>
                  <th scope="col" className="w-min-300px">
                    Details
                  </th>
                  <th scope="col" className="w-min-200px"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {moment(dataResult.last_updated_time).format(
                      "DD-MM-YYYY, h:mm:ss a"
                    )}
                  </td>
                  <td>
                    {dataResult.assessor_name
                      ? dataResult.assessor_name
                      : dataResult.assessor_email}
                  </td>
                  <td>{`${dataResult.sdq_score_result?.total_difficulties} out of 40`}</td>
                  <td>{`${dataResult.sdq_score_result?.prone_to_externalising} out of 20`}</td>
                  <td>{`${dataResult.sdq_score_result?.prone_to_internalising} out of 20`}</td>
                  <td>
                    {dataResult.sdq_score_result?.scale_scores?.map(
                      (e, idx) => {
                        return (
                          <div key={e.scale}>
                            <span>{`${e.scale}: ${e.score}, ${e.category}`}</span>
                            {idx !==
                              dataResult.sdq_score_result?.scale_scores
                                ?.length -
                                1 && <br />}
                          </div>
                        );
                      }
                    )}
                  </td>
                  <td>
                    <Icon
                      name="eye"
                      className="fs-22px"
                      onClick={onDetailsClicked}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </SimpleBar>
      );
    }
    if (isGetQuestionsFetching || isGetQuestionsError) {
      return (
        <div className="text-center py-4">
          <Spinner />
        </div>
      );
    }
    if (isGetQuestionsSuccess) {
      return (
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <div className="table-wrapper">
            <table className="table table-bordered text-center">
              <thead>
                <tr>
                  <th scope="col" style={{ width: "50px" }}>
                    No
                  </th>
                  <th scope="col" className="w-300px">
                    Question
                  </th>
                  <th scope="col" className="w-110px">
                    Not true
                  </th>
                  <th scope="col" className="w-110px">
                    Somewhat true
                  </th>
                  <th scope="col" className="w-110px">
                    Certainly true
                  </th>
                </tr>
              </thead>
              <tbody>
                {qData?.questions.map((e, idx) => {
                  return (
                    <tr key={e.id}>
                      <td>{idx + 1}</td>
                      <td className="text-left">{e.question}</td>
                      <td>
                        <input
                          type="radio"
                          {...register(`sdq_answers.${idx}.answer`)}
                          value="not_true"
                        />
                      </td>
                      <td>
                        <input
                          type="radio"
                          {...register(`sdq_answers.${idx}.answer`)}
                          value="somewhat_true"
                        />
                      </td>
                      <td>
                        <input
                          type="radio"
                          {...register(`sdq_answers.${idx}.answer`)}
                          value="certainly_true"
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <Row className="mt-4 justify-content-center">
            <Button
              color="primary"
              type="submit"
              size={"lg"}
              className={"w-min-200px"}
              disabled={isSubmitLoading}
            >
              {isSubmitLoading && (
                <Spinner type="grow" size="sm" className="mr-2" />
              )}
              <span>SUBMIT SDQ</span>
            </Button>
          </Row>
        </form>
      );
    }
  };

  return (
    <>
      <Head title="Strengths &amp; Difficulties Questionnaire" />
      <Content>
        <div className="content-page wide-md m-auto">
          <BlockHead size="lg" wide="xs" className="mx-auto">
            <BlockHeadContent className="text-center">
              <BlockTitle tag="h2" className="fw-medium">
                Strengths &amp; Difficulties Questionnaire
              </BlockTitle>
              <BlockDes className="fs-16px">
                <div className="form-des-block-note px-4 py-3">
                  <p>Before you start, please take note:</p>
                  <ul className="list">
                    <li className="my-2">
                      Your form reference code is:{" "}
                      <strong className="fs-18px fw-bold">{params.code}</strong>
                    </li>
                    <li className="my-2">
                      Please do not share your reference code with others.
                    </li>
                    <li className="my-2">
                      At any point of time, you can save the form and come back
                      at a later time to finish filling it. You will need your
                      form reference code to continue filling the form.
                    </li>
                  </ul>
                </div>
              </BlockDes>
            </BlockHeadContent>
          </BlockHead>
          <Block className="fs-16px">
            <PreviewCard className="card-bordered">
              <Row className="g-3">
                <Col size="12">
                  <FormContent />
                </Col>
              </Row>
            </PreviewCard>
          </Block>
        </div>
      </Content>

      <ModalComponent
        isOpen={isModalOpen}
        toggle={() => {
          setModalOpen(false);
        }}
        title={"Details"}
        onModalCancel={() => {
          setModalOpen(false);
        }}
      >
        {isGetQuestionsLoading && (
          <div className="text-center py-4">
            <Spinner />
          </div>
        )}
        {isGetQuestionsSuccess && (
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col" style={{ width: "70px" }}>
                  No
                </th>
                <th scope="col" className="w-300px">
                  Question
                </th>
                <th scope="col" className="w-125px">
                  Answer
                </th>
              </tr>
            </thead>
            <tbody>
              {qData?.questions.map((e, idx) => {
                const el = data?.sdq_answers?.find(
                  (obj) => obj.code === e.code
                );
                const answer = el?.answer.split("_").join(" ");
                return (
                  <tr key={e.id}>
                    <td>{idx + 1}</td>
                    <td>{e.question}</td>
                    <td className="ccap">{answer}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </ModalComponent>
    </>
  );
};

export default SDQForm;
