import { useDispatch, useSelector } from "react-redux";
import { useState, useLayoutEffect, useEffect } from "react";
import ReactDataTable from "../../components/table/ReactDataTable";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
  Row,
  Col,
  ModalComponent,
  TextInput,
  TextareaInput,
  SelectField,
} from "../../components/Components";
import {
  onChangePageState,
  onFormCancel,
  toggleModal,
} from "../../redux/features/userSlice";
import {
  useCreateUserMutation,
  useGetUsersQuery,
} from "../../redux/api/users/usersApi";
import { userColumns } from "./DataColumns";
import {
  Breadcrumb,
  BreadcrumbItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { useLazyGetOrganisationsQuery } from "../../redux/api/organisations/organisationApi";
import { useLazyGetRolesQuery } from "../../redux/api/authorization-management/authorizationApi";
import { toast } from "react-toastify";

const Users = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state.users);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    control,
    reset,
    watch,
    setValue,
  } = useForm();
  const { data, isFetching } = useGetUsersQuery({
    offset: state.offset,
    limit: state.itemsPerPage,
    total: true,
    reverse: true,
    exclude_types: "CANDIDATE, CAREGIVER",
  });
  const [
    createUser,
    {
      isLoading: isCreateLoading,
      isError: isCreateError,
      isSuccess: isCreateSuccess,
      error: createError,
    },
  ] = useCreateUserMutation();
  const orgField = watch("organisation");
  const [
    getOrganisations,
    {
      data: orgData,
      isSuccess: isOrgSuccess,
      isFetching: isOrgFetching,
      isError: isOrgError,
    },
  ] = useLazyGetOrganisationsQuery({
    offset: 0,
    limit: 0,
  });
  const [
    getRoles,
    {
      data: roleData,
      isSuccess: isRlSuccess,
      isFetching: isRlFetching,
      isError: isRlError,
    },
  ] = useLazyGetRolesQuery({
    offset: 0,
    limit: 0,
    q: {
      organisation: orgField?.value,
    },
  });
  const [tableData, setTableData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const changePageState = ({ offset = 0, itemsPerPage }) => {
    dispatch(onChangePageState({ offset, itemsPerPage: itemsPerPage }));
  };

  useLayoutEffect(() => {
    if (data) {
      setTableData(data.users);
      setTotalItems(data.total);
    }
  }, [data]);

  const onAddBtnClicked = (type) => {
    dispatch(toggleModal({ type: "add" }));
    getOrganisations(undefined, true);
    getRoles(undefined, true);
  };

  const onFormSubmit = async (data) => {
    if (data.organisation) {
      data.organisation = data.organisation.value;
    }
    data.role_id = data.role_id.value;

    createUser(data);
  };

  useEffect(() => {
    if (isCreateSuccess) {
      dispatch(toggleModal({ type: "add" }));
      reset();
    }
    if (isCreateError && createError) {
      toast.error(createError?.data?.message);
    }
  }, [isCreateSuccess, isCreateError]);

  useLayoutEffect(() => {
    if (orgField) {
      getRoles(
        {
          offset: 0,
          limit: 0,
          q: JSON.stringify({ organisation: orgField.value }),
        },
        true
      );
      if (isRlSuccess || isRlError) {
        setValue("role", "");
      }
    }
  }, [orgField]);

  const onRowClicked = (row, e) => {
    e.stopPropagation();
    const action = e.target.getAttribute("data-action");
    switch (action) {
      case "details":
        // navigate(`../${row.id}`);
        break;
      case "remove":
        //dispatch delete
        break;
      case "quick_views":
        dispatch(toggleModal({ type: "quick_views", item: row }));
        break;
      default:
    }
  };

  return (
    <>
      <Head title="Users"></Head>
      <Content>
        <Block>
          <BlockHead>
            <Breadcrumb
              tag="nav"
              listTag="div"
              className="breadcrumb-arrow fs-18px"
            >
              <BreadcrumbItem tag="a" href="../">
                Home
              </BreadcrumbItem>
              <BreadcrumbItem active>Users</BreadcrumbItem>
            </Breadcrumb>
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Users</BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <Button
                    className="toggle d-none d-md-inline-flex mr-3"
                    color="primary"
                    onClick={() => {
                      onAddBtnClicked();
                    }}
                  >
                    <Icon name="plus"></Icon>
                    <span>Create User</span>
                  </Button>
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <PreviewCard>
            <div className="table-wrapper is-scrollable">
              <ReactDataTable
                data={tableData}
                columns={userColumns}
                offset={state.offset}
                itemsPerPage={state.itemsPerPage}
                pagination
                totalItems={totalItems}
                progressPending={isFetching}
                className="nk-tb-list"
                selectableRows
                changePageState={changePageState}
                onRowClicked={onRowClicked}
                filterComponent={filterComponent}
              />
            </div>
          </PreviewCard>
        </Block>

        <ModalComponent
          isOpen={state.modal.add || state.modal.edit}
          backdrop={"static"}
          title={`${state.modal.add ? "Add" : "Edit"} User`}
          onModalCancel={() => {
            dispatch(onFormCancel());
            reset();
          }}
        >
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Row className="g-3">
              <TextInput
                name={"email"}
                label={"Email"}
                required
                register={{
                  ...register("email", {
                    required: "This field is required",
                  }),
                }}
                errors={errors}
                md="12"
              />
              <TextInput
                name={"first_name"}
                label={"First Name"}
                required
                register={{
                  ...register("first_name", {
                    required: "This field is required",
                  }),
                }}
                errors={errors}
                md="12"
              />
              <TextInput
                name={"last_name"}
                label={"Last Name"}
                required
                register={{
                  ...register("last_name", {
                    required: "This field is required",
                  }),
                }}
                errors={errors}
                md="12"
              />
              <TextInput
                name={"password"}
                label={"Password"}
                required
                register={{
                  ...register("password", {
                    required: "This field is required",
                  }),
                }}
                errors={errors}
                md="12"
              />
              <SelectField
                name={"organisation"}
                label={"Organisation"}
                control={control}
                options={
                  orgData?.organisations.map((item) => ({
                    label: item.name,
                    value: item.id,
                  })) ?? {}
                }
                isLoading={isOrgFetching}
                isDisabled={isOrgFetching}
                errors={errors}
              />
              <SelectField
                name={"role_id"}
                label={"Role"}
                control={control}
                rules={{ required: "This field is required" }}
                // placeholder={!orgField ? "Pick organisation first" : ""}
                options={roleData?.roles.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                isLoading={isRlFetching}
                isDisabled={isRlFetching}
                errors={errors}
              />

              <Col size="12">
                <Button
                  color="primary"
                  type="submit"
                  disabled={isCreateLoading}
                >
                  {isCreateLoading && <Spinner type="grow" size="sm" />}
                  <span>{state.modal.add ? "Add" : "Edit"}</span>
                </Button>
              </Col>
            </Row>
          </form>
        </ModalComponent>

        {/* <ModalComponent
          isOpen={state.modal.quick_views}
          toggle={() => dispatch(toggleModal({ type: "quick_views" }))}
          title={"Quote Details"}
          onModalCancel={() => {
            dispatch(onFormCancel());
          }}
        >
          {state.modal.item && (
            <Row className="gy-3">
              <Col lg={6}>
                <span className="sub-text">Quote Id</span>
                <span className="caption-text">{state.modal.item.id}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Quote</span>
                <span className="caption-text">{state.modal.item.quote}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Author</span>
                <span className="caption-text">{state.modal.item.author}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Created Time</span>
                <span className="caption-text">
                  {state.modal.item.created_time}
                </span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Last Updated Time</span>
                <span className="caption-text">
                  {state.modal.item.last_updated_time}
                </span>
              </Col>
            </Row>
          )}
        </ModalComponent> */}
      </Content>
    </>
  );
};

const filterComponent = () => {
  return (
    <UncontrolledDropdown>
      <DropdownToggle
        tag="a"
        className="btn btn-trigger btn-icon dropdown-toggle"
      >
        <div className="dot dot-primary"></div>
        <Icon name="filter-alt"></Icon>
      </DropdownToggle>
      <DropdownMenu
        right
        className="filter-wg dropdown-menu-xl"
        style={{ overflow: "visible" }}
      >
        <div className="dropdown-head">
          <span className="sub-title dropdown-title">Filter Candidates</span>
        </div>
        <div className="dropdown-body dropdown-body-rg">
          <Row className="gx-6 gy-3">
            <Col size="12">
              <FormGroup>
                <label className="overline-title overline-title-alt">
                  Stage
                </label>
                <div className={`form-control-select`}>
                  <Select
                    className={`react-select-container`}
                    classNamePrefix="react-select"
                    options={[
                      { value: "All", label: "All" },
                      { value: "Completed", label: "Completed" },
                      { value: "Interim", label: "Interim" },
                    ]}
                  ></Select>
                </div>
              </FormGroup>
            </Col>
          </Row>
        </div>
        <div className="dropdown-foot between">
          <a
            href="#reset"
            onClick={(ev) => {
              ev.preventDefault();
            }}
            className="clickable"
          >
            Reset Filter
          </a>
          <a
            href="#save"
            onClick={(ev) => {
              ev.preventDefault();
            }}
          >
            Save Filter
          </a>
        </div>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default Users;
