import { useDispatch, useSelector } from "react-redux";
import { useLayoutEffect, useState } from "react";
import {
  onChangePageState,
  toggleModal,
  onFormCancel,
} from "../../redux/features/emotionTypesSlice";
import { useGetEmotionTypesQuery } from "../../redux/api/emotional-management/emotionalApi";
import ReactDataTable from "../../components/table/ReactDataTable";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
  Row,
  Col,
  ModalComponent,
} from "../../components/Components";
import { emotionTypesColumns } from "./DataColumns";

const EmotionTypes = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.emotionTypes);
  const { data, isFetching, isSuccess } = useGetEmotionTypesQuery({
    offset: state.offset,
    limit: state.itemsPerPage,
    total: true,
    reverse: true,
  });
  const [tableData, setTableData] = useState(data?.emotionTypes ?? []);
  const [totalItems, setTotalItems] = useState(data?.total ?? 0);

  const changePageState = ({ offset = 0, itemsPerPage }) => {
    dispatch(onChangePageState({ offset, itemsPerPage: itemsPerPage }));
  };

  useLayoutEffect(() => {
    if (data) {
      setTableData(data.emotionTypes);
      setTotalItems(data.total);
    }
  }, [data]);

  const onAddBtnClicked = (e) => {
    dispatch(toggleModal({ type: "add" }));
  };

  const onRowClicked = (row, e) => {
    e.stopPropagation();
    const action = e.target.getAttribute("data-action");
    switch (action) {
      case "remove":
        //dispatch delete
        break;
      case "details":
        dispatch(toggleModal({ type: "details", item: row }));
        break;
    }
  };
  return (
    <>
      <Head title="Emotion Types" />
      <Content>
        <Block>
          <BlockHead>
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Emotion Types</BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <Button
                    className="toggle btn-icon d-md-none"
                    color="primary"
                    onClick={onAddBtnClicked}
                  >
                    <Icon name="plus" />
                  </Button>
                  <Button
                    className="toggle d-none d-md-inline-flex"
                    color="primary"
                    onClick={onAddBtnClicked}
                  >
                    <Icon name="plus" />
                    <span>Add Emotion</span>
                  </Button>
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <PreviewCard>
            <ReactDataTable
              data={tableData}
              columns={emotionTypesColumns}
              offset={state.offset}
              itemsPerPage={state.itemsPerPage}
              pagination
              totalItems={totalItems}
              progressPending={isFetching}
              className="nk-tb-list"
              selectableRows
              changePageState={changePageState}
              onRowClicked={onRowClicked}
            />
          </PreviewCard>
        </Block>

        <ModalComponent
          isOpen={state.modal.details}
          toggle={() => dispatch(toggleModal({ type: "details" }))}
          title={"Emotion Details"}
          onModalCancel={() => {
            dispatch(onFormCancel());
          }}
        >
          {state.modal.item && (
            <Row className="gy-3">
              <Col lg={6}>
                <span className="sub-text">Emotion Id</span>
                <span className="caption-text">{state.modal.item.id}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Type</span>
                <span className="caption-text">{state.modal.item.type}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Group</span>
                <span className="caption-text">{state.modal.item.group}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Enabled</span>
                <span className="caption-text">
                  {state.modal.item.is_enabled?.toString()}
                </span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Created Time</span>
                <span className="caption-text">
                  {state.modal.item.created_time}
                </span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Last Updated Time</span>
                <span className="caption-text">
                  {state.modal.item.last_updated_time}
                </span>
              </Col>
            </Row>
          )}
        </ModalComponent>
      </Content>
    </>
  );
};
export default EmotionTypes;
