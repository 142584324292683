import { useParams } from "react-router-dom";
import {
  PreviewCard,
  Block,
  BlockHeadContent,
  BlockHead,
  BlockDes,
  BlockTitle,
  TextInput,
  Row,
  DatePickerInput,
  SelectField,
  Col,
  Button,
  TimePickerInput,
  PhoneNumberWithCountryCodeInput,
  Icon,
  ModalComponent,
  BlockContent,
} from "../../components/Components";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { useFieldArray, useForm } from "react-hook-form";
import { FlagsSelectField } from "../../components/form/SelectField";
import SimpleBar from "simplebar-react";
import {
  useGetSexesQuery,
  useGetReligionsQuery,
} from "../../redux/api/dropdown-data/dropdownDataApi";
import { useEffect } from "react";
import { useState } from "react";
import { Spinner } from "reactstrap";
import { getLanguageOptions } from "../../utils/utils";
import LoadingPage from "../loading/LoadingPage";
import Error404Modern from "../error/404-modern";
import { toast } from "react-toastify";
import {
  useCreateSDQFormMutation,
  useGetAssessmentFormQuery,
  useSubmitAssessmentFormMutation,
  useLazyGetSDQuestionsQuery,
} from "../../redux/api/forms/formApi";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  assessmentRecommendationOptions,
  countryFlagsMap,
  nioIconData,
} from "../../constants";
import { useRef } from "react";
import PageContainer from "../../layout/page-container/PageContainer";
import isEqual from "react-fast-compare";

const defaultValues = {
  home_country_calling_code: "SG",
  country_calling_code: "SG",
  emergency_contact_country_calling_code: "SG",
  disability_details: [
    {
      name: "",
      priority: "",
      description: "",
      year_diagnosed: null,
      place_diagnosed: "",
      remarks: "",
    },
  ],
  education_details: [
    {
      level: "",
      institution: "",
      course: "",
      from: null,
      to: null,
      grades: "",
    },
  ],
  family_details: [
    {
      first_name: "",
      last_name: "",
      relationship: "",
      occupation: "",
      caregiver: "",
    },
  ],
  employment_details: [
    {
      from: null,
      to: null,
      employer: "",
      role: "",
      type: "",
      salary: "",
      reason_leaving: "",
    },
  ],
  medical_details: [
    {
      type: "",
      year_diagnosed: null,
      condition: "",
      clinic: "",
      doctor: "",
      follow_up_appointments: "",
      medications: "",
      side_effects: "",
    },
  ],
};

const IntakeAssessment = () => {
  const ref = useRef(null);
  const params = useParams();
  const {
    data: formData,
    isFetching,
    isSuccess,
    isError,
    error,
  } = useGetAssessmentFormQuery({
    code: params.code,
  });
  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    getValues,
    setFocus,
  } = useForm({
    defaultValues: defaultValues,
  });
  const [
    submitAssessment,
    {
      data: submitCallbackData,
      isLoading: isSubmitLoading,
      isSuccess: isSubmitSuccess,
      isError: isSubmitError,
      error: submitError,
    },
  ] = useSubmitAssessmentFormMutation();
  const [
    saveAssessment,
    {
      isLoading: isSaveLoading,
      isSuccess: isSveSuccess,
      isError: isSaveError,
      error: saveError,
    },
  ] = useSubmitAssessmentFormMutation();
  const authState = useSelector((state) => state.auth);
  const { errors } = formState;
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (formData) {
      //TODO: Change this

      // Pre-filled values
      reset(
        {
          ...defaultValues,
          ...formData,
          assessor_name: formData.assessor_name
            ? formData.assessor_name
            : authState.user.name,
          assessor_email: formData.assessor_email
            ? formData.assessor_email
            : authState.user.email,
          date_of_assessment: Date.parse(formData.date_of_assessment),
          time_of_assessment: Date.parse(formData.time_of_assessment),
          date_of_birth: Date.parse(formData.date_of_birth),
          sex: formData.sex
            ? { value: formData.sex, label: formData.sex }
            : null,
          religion: formData.religion
            ? { value: formData.religion, label: formData.religion }
            : null,
          ptc_card_description: formData.ptc_card_description
            ? {
                value: formData.ptc_card_description,
                label: formData.ptc_card_description,
              }
            : null,
          languages_spoken: formData.languages_spoken
            ? formData.languages_spoken.map((e) => ({ value: e, label: e }))
            : null,
          languages_written: formData.languages_written
            ? formData.languages_written.map((e) => ({ value: e, label: e }))
            : null,
          executive_functioning_tools_used:
            formData.executive_functioning_tools_used
              ? formData.executive_functioning_tools_used.map((e) => ({
                  value: e,
                  label: e,
                }))
              : null,
          assessment_recommendation: formData.assessment_recommendation
            ? formData.assessment_recommendation.map((e) => {
                let obj = assessmentRecommendationOptions.find(
                  (i) => i.value === e
                );
                return { value: e, label: obj.label };
              })
            : null,
          disability_details: formData.disability_details?.map((e) => ({
            ...e,
            name: e.name ? { value: e.name, label: e.name } : null,
            priority: e.priority
              ? { value: e.priority, label: e.priority }
              : null,
            year_diagnosed: Date.parse(e.year_diagnosed),
          })),
          education_details: formData.education_details?.map((e) => ({
            ...e,
            level: e.level ? { value: e.level, label: e.level } : null,
            from: Date.parse(e.from),
            to: Date.parse(e.to),
          })),
          family_details:
            formData.family_details?.map((e) => ({
              ...e,
              relationship: e.relationship
                ? { value: e.relationship, label: e.relationship }
                : null,
              caregiver: e.caregiver
                ? { value: e.caregiver, label: e.caregiver }
                : null,
            })) ?? defaultValues.family_details,
          employment_details: formData.employment_details?.map((e) => ({
            ...e,
            type: e.type ? { value: e.type, label: e.type } : null,
            from: Date.parse(e.from),
            to: Date.parse(e.to),
          })),
          medical_details:
            formData.medical_details?.map((e) => ({
              ...e,
              type: e.type ? { value: e.type, label: e.type } : null,
              year_diagnosed: Date.parse(e.year_diagnosed),
            })) ?? defaultValues.medical_details,
        },
        {
          keepDefaultValues: true,
        }
      );
    }
  }, [formData]);

  const processData = (data) => {
    data.date_of_assessment = data.date_of_assessment
      ? new Date(data.date_of_assessment).toISOString()
      : null;
    data.time_of_assessment = data.time_of_assessment
      ? new Date(data.time_of_assessment).toISOString()
      : null;
    data.sex = data.sex?.value;
    data.religion = data.religion?.value;
    data.nationality = data.nationality_code
      ? countryFlagsMap[data.nationality_code]?.primary
      : null;
    data.ptc_card_description = data.ptc_card_description?.value;
    data.languages_spoken = data.languages_spoken?.map((e) => {
      return e?.value;
    });
    data.languages_written = data.languages_written?.map((e) => {
      return e?.value;
    });
    data.date_of_birth = data.date_of_birth
      ? new Date(data.date_of_birth).toISOString()
      : null;
    data.executive_functioning_tools_used =
      data.executive_functioning_tools_used?.map((e) => {
        return e?.value;
      });
    data.assessment_recommendation = data.assessment_recommendation?.map(
      (e) => {
        return e?.value;
      }
    );

    data.disability_details = data.disability_details?.map((e, idx) => {
      return {
        ...data.disability_details[idx],
        name: e.name?.value,
        priority: e.priority?.value,
        year_diagnosed: e.year_diagnosed
          ? new Date(e.year_diagnosed).toISOString()
          : null,
      };
    });
    data.education_details = data.education_details?.map((e, idx) => {
      return {
        ...data.education_details[idx],
        level: e.level?.value,
        from: e.from ? new Date(e.from).toISOString() : null,
        to: e.to ? new Date(e.to).toISOString() : null,
      };
    });

    if (isEqual(data.family_details, defaultValues.family_details)) {
      data.family_details = null;
    } else {
      data.family_details = data.family_details?.map((e, idx) => {
        return {
          ...data.family_details[idx],
          caregiver: e.caregiver?.value,
          relationship: e.relationship?.value,
        };
      });
    }

    if (isEqual(data.employment_details, defaultValues.employment_details)) {
      data.employment_details = null;
    } else {
      data.employment_details = data.employment_details?.map((e, idx) => {
        return {
          ...data.employment_details[idx],
          type: e.type?.value,
          from: e.from ? new Date(e.from).toISOString() : null,
          to: e.to ? new Date(e.to).toISOString() : null,
        };
      });
    }

    if (isEqual(data.medical_details, defaultValues.medical_details)) {
      data.medical_details = null;
    } else {
      data.medical_details = data.medical_details?.map((e, idx) => {
        return {
          ...data.medical_details[idx],
          type: e.type?.value,
          year_diagnosed: e.year_diagnosed
            ? new Date(e.year_diagnosed).toISOString()
            : null,
        };
      });
    }

    data.reference_code = params.code;
    return data;
  };
  const onFormSubmit = (data) => {
    data = processData(data);
    data.type = "submit";
    submitAssessment(data);
  };

  useEffect(() => {
    if (isSubmitSuccess || formData?.is_submitted) {
      if (ref.current) {
        clearInterval(ref.current);
      }
    }
    if (isSubmitError && submitError) {
      toast.error(submitError.data.message);
    }
    if (isSubmitSuccess || formData?.is_submitted) {
      setSubmitted(true);
    }
    if (formData?.is_completed) {
      // setSubmitted(true);
    }
  }, [isSubmitSuccess, isSubmitError, formData]);

  const onSave = (type) => {
    let data = getValues();
    data = processData(data);
    data.submission_type = type;
    saveAssessment(data);
  };

  useEffect(() => {
    ref.current = setInterval(() => onSave("autosave"), 30000);

    return () => {
      if (ref.current) {
        clearInterval(ref.current);
      }
    };
  }, []);

  // if (process.env.NODE_ENV === "production") {
  //   const hostname = window.location.hostname;
  //   if (hostname !== "croissance.form.inclus.sg") {
  //     return <Error404Modern />;
  //   }
  // }

  useEffect(() => {
    if (formState?.errors?.disability_details?.root) {
      setFocus("disability_details.0.description");
    }
    if (formState?.errors?.family_details?.root) {
      setFocus("family_details.0.first_name");
    }
  }, [formState]);

  if (isFetching) {
    return <LoadingPage />;
  }
  if (isError && error && (error.status === 404 || error.status === 400)) {
    return <Error404Modern />;
  }

  if (submitted) {
    return (
      <>
        <Head title="Assessment Form" />
        <PageContainer>
          <Block className="nk-block-middle nk-auth-body wide-sm">
            <PreviewCard
              className="card-bordered fs-18px"
              bodyClass="card-inner-lg"
            >
              <BlockHead>
                <BlockContent
                  className={"d-flex align-items-center flex-column"}
                >
                  <div className="w-100px mb-4">
                    {nioIconData["doc-checked"]}
                  </div>
                  <BlockTitle tag="h4">
                    Thank you for filling up the assessment form.
                  </BlockTitle>
                  <BlockDes>
                    <p className="fs-20px">
                      Your form reference code is:{" "}
                      <strong className="fw-bold">{params.code}</strong>
                    </p>
                  </BlockDes>
                </BlockContent>
              </BlockHead>
            </PreviewCard>
          </Block>
        </PageContainer>
      </>
    );
  }

  return (
    <>
      <Head title="Intake Assessment Form" />
      <Content>
        <div className="content-page wide-md m-auto overflow-hidden">
          <BlockHead size="lg" wide="xs" className="mx-auto">
            <BlockHeadContent className="text-center">
              <BlockTitle tag="h2" className="fw-normal">
                Intake Assessment
              </BlockTitle>
              <BlockDes className="fs-16px">
                <div className="form-des-block-note px-4 py-3">
                  <p>Before you start, please take note:</p>
                  <ul className="list">
                    <li className="my-2">
                      Your form reference code is:{" "}
                      <strong className="fs-18px fw-bold">{params.code}</strong>
                    </li>
                    <li className="my-2">
                      Please do not share your reference code with others.
                    </li>
                    <li className="my-2">
                      You will need your form reference code to continue filling
                      the form.
                    </li>
                    <li className="my-2">
                      This form will auto-save your inputs every 20 seconds. At
                      any point of time, you can save the form and come back at
                      a later time to finish filling it too.
                    </li>
                  </ul>
                </div>
              </BlockDes>
            </BlockHeadContent>
          </BlockHead>
          <Block className="fs-16px">
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <AdminDetail
                control={control}
                register={register}
                errors={errors}
              />
              <General control={control} register={register} errors={errors} />
              <PersonalDetail
                control={control}
                register={register}
                errors={errors}
              />
              <DisabilityDetail
                control={control}
                register={register}
                errors={errors}
              />
              <ContactDetail
                control={control}
                register={register}
                errors={errors}
              />
              <EducationalDetail
                control={control}
                register={register}
                errors={errors}
              />
              <FamilyDetail
                control={control}
                register={register}
                errors={errors}
              />
              <EmploymentDetail
                control={control}
                register={register}
                errors={errors}
              />
              <EmploymentMotivationAndExpectations
                control={control}
                register={register}
                errors={errors}
              />
              <MedicalDetail
                control={control}
                register={register}
                errors={errors}
              />
              <Observations
                control={control}
                register={register}
                errors={errors}
              />

              <ExecutiveFunctioning
                control={control}
                register={register}
                errors={errors}
              />

              <StrengthsAndDifficultiesQuestionnaire
                formData={formData}
                code={params.code}
              />

              <AssessmentRecommendation
                control={control}
                register={register}
                errors={errors}
              />

              <Row className="mt-4 mb-2 mx-0 justify-content-around">
                <Button
                  color="primary"
                  type="submit"
                  size={"lg"}
                  className={"w-min-200px justify-content-center"}
                  disabled={isSubmitLoading}
                >
                  {isSubmitLoading && <Spinner type="grow" size="sm" />}
                  <span>SUBMIT</span>
                </Button>
                {/* </Col> */}
              </Row>
            </form>
          </Block>
        </div>
      </Content>
    </>
  );
};

const AdminDetail = ({ control, register, errors }) => {
  return (
    <PreviewCard className="card-bordered">
      <Row className="g-3">
        <Col size="12">
          <BlockTitle tag="h5">Admin details</BlockTitle>
          <div className="nk-divider divider mt-3 mb-1" />
        </Col>
        <TextInput
          name={"assessor_name"}
          label={"Assessed by"}
          required
          register={{
            ...register("assessor_name", {
              required: "This field is required",
            }),
          }}
          errors={errors}
          md="12"
        />
        <TextInput
          name={"assessor_email"}
          label={"Assessor email address"}
          placeholder={"assessor@inclus.sg"}
          required
          register={{
            ...register("assessor_email", {
              required: "This field is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address",
              },
            }),
          }}
          errors={errors}
          md="12"
        />
        <DatePickerInput
          name={"date_of_assessment"}
          label={"Date of assessment"}
          placeholder={"dd/mm/yyyy"}
          dateFormat={"dd/MM/yyyy"}
          control={control}
          rules={{ required: "This field is required" }}
          errors={errors}
          md="12"
        />
        <TimePickerInput
          name={"time_of_assessment"}
          label={"Time of assessment"}
          placeholder={"hh:mm"}
          dateFormat={"HH:mm"}
          timeFormat={"HH:mm"}
          control={control}
          rules={{ required: "This field is required" }}
          errors={errors}
          md="12"
        />
        <TextInput
          name={"referred_by"}
          label={"Referred By"}
          placeholder={"organisation or individual who referred candidate"}
          required
          register={{
            ...register("referred_by", {
              required: "This field is required",
            }),
          }}
          errors={errors}
          md="12"
        />
        <TextInput
          name={"how_know_inclus"}
          label={"How did candidate know Inclus?"}
          placeholder={"From my mum"}
          required
          register={{
            ...register("how_know_inclus", {
              required: "This field is required",
            }),
          }}
          errors={errors}
          md="12"
        />
        <TextInput
          name={"general_remarks"}
          label={"General remarks"}
          placeholder={"general"}
          required
          register={{
            ...register("general_remarks", {
              required: "This field is required",
            }),
          }}
          errors={errors}
          md="12"
        />
      </Row>
    </PreviewCard>
  );
};

const General = ({ control, register, errors }) => {
  return (
    <PreviewCard className="card-bordered">
      <Row className="g-3">
        <Col size="12">
          <BlockTitle tag="h5">General</BlockTitle>
          <div className="nk-divider divider mt-3 mb-1" />
        </Col>
        <TextInput
          name={"accompanied_by"}
          label={"Candidate accompanied by"}
          caption={
            "If candidate is accompanied by someone, please indicate who and their relationship"
          }
          placeholder={"Accompanied By"}
          required
          register={{
            ...register("accompanied_by", {
              required: "This field is required",
            }),
          }}
          errors={errors}
          md="12"
        />
        <TextInput
          name={"punctuality_notes"}
          label={"Notes on punctuality"}
          placeholder={"early, late, on time; with details"}
          required
          register={{
            ...register("punctuality_notes", {
              required: "This field is required",
            }),
          }}
          errors={errors}
          md="12"
        />
        <TextInput
          name={"is_travel_independently"}
          label={"Did the candidate travel independently?"}
          placeholder={"yes or no; with details"}
          required
          register={{
            ...register("is_travel_independently", {
              required: "This field is required",
            }),
          }}
          errors={errors}
          md="12"
        />
        <TextInput
          name={"vaccination_notes"}
          caption={"If candidate is not vaccinated, please indicate details"}
          label={"Notes on vaccination"}
          required
          register={{
            ...register("vaccination_notes", {
              required: "This field is required",
            }),
          }}
          errors={errors}
          md="12"
        />
      </Row>
    </PreviewCard>
  );
};

const PersonalDetail = ({ control, register, errors }) => {
  const {
    data: sexData,
    isFetching: isGetSexFetching,
    isSuccess: isGetSexSuccess,
    isError: isGetSexError,
    error: getSexError,
  } = useGetSexesQuery({
    limit: 1000,
  });
  const {
    data: religionData,
    isFetching: isGetReligionFetching,
    isSuccess: isGetReligionSuccess,
    isError: isGetReligionError,
    error: getReligionError,
  } = useGetReligionsQuery({
    limit: 1000,
  });
  const [sexOptions, setSexOptions] = useState([]);
  const [religionOptions, setReligionOptions] = useState([]);

  useEffect(() => {
    if (isGetSexSuccess && sexData?.sexes) {
      let data = sexData.sexes.map((e) => {
        return { value: e.sex, label: e.sex };
      });
      setSexOptions(data);
    }
  }, [isGetSexSuccess, isGetSexError]);

  useEffect(() => {
    if (isGetReligionSuccess && religionData?.religions) {
      let data = religionData.religions.map((e) => {
        return { value: e.religion, label: e.religion };
      });
      setReligionOptions(data);
    }
  }, [isGetReligionSuccess, isGetReligionError]);

  const languageOptions = getLanguageOptions();

  return (
    <PreviewCard className="card-bordered">
      <Row className="g-3">
        <Col size="12">
          <BlockTitle tag="h5">Personal details</BlockTitle>
          <div className="nk-divider divider mt-3 mb-1" />
        </Col>
        <TextInput
          name={"first_name"}
          label={"First name"}
          caption={"As per NRIC"}
          required
          register={{
            ...register("first_name", {
              required: "This field is required",
            }),
          }}
          errors={errors}
          md="12"
        />
        <TextInput
          name={"last_name"}
          label={"Last name"}
          required
          register={{
            ...register("last_name", {
              required: "This field is required",
            }),
          }}
          errors={errors}
          md="12"
        />
        <TextInput
          name={"preferred_name"}
          label={"Preferred to be called by"}
          placeholder={"preferred name or alias"}
          required
          register={{
            ...register("preferred_name", {
              required: "This field is required",
            }),
          }}
          errors={errors}
          md="12"
        />
        <TextInput
          name={"last_4_digits_nric"}
          label={"Last 4 digits of NRIC"}
          placeholder={"e.g. 123A"}
          required
          register={{
            ...register("last_4_digits_nric", {
              required: "This field is required",
            }),
          }}
          errors={errors}
          md="12"
        />
        <FlagsSelectField
          name={"nationality_code"}
          label={"Nationality"}
          control={control}
          rules={{ required: "This field is required" }}
          errors={errors}
          md="12"
        />
        <DatePickerInput
          name={"date_of_birth"}
          label={"Date of birth"}
          placeholder={"dd/mm/yyyy"}
          dateFormat={"dd/MM/yyyy"}
          control={control}
          rules={{ required: "This field is required" }}
          errors={errors}
          md="12"
        />
        <SelectField
          name={"sex"}
          label={"Sex"}
          control={control}
          rules={{ required: "This field is required" }}
          options={sexOptions}
          isSearchable={false}
          isLoading={isGetSexFetching}
          isDisabled={isGetSexFetching}
          errors={errors}
          md="12"
        />
        <SelectField
          name={"religion"}
          label={"Religion"}
          control={control}
          rules={{ required: "This field is required" }}
          options={religionOptions}
          isSearchable={false}
          isLoading={isGetReligionFetching}
          isDisabled={isGetReligionFetching}
          errors={errors}
          md="12"
        />
        <SelectField
          name={"ptc_card_description"}
          label={"Has PTC card?"}
          control={control}
          rules={{ required: "This field is required" }}
          options={[
            { value: "Yes", label: "Yes" },
            { value: "No", label: "No" },
          ]}
          errors={errors}
          md="12"
        />
        <SelectField
          name={"languages_spoken"}
          label={"Languages Spoken"}
          control={control}
          rules={{ required: "This field is required" }}
          isMulti
          options={languageOptions}
          errors={errors}
          md="12"
        />
        <SelectField
          name={"languages_written"}
          label={"Languages Written"}
          control={control}
          isMulti
          rules={{ required: "This field is required" }}
          options={languageOptions}
          errors={errors}
          md="12"
        />
      </Row>
    </PreviewCard>
  );
};

const DisabilityDetail = ({ control, register, errors }) => {
  const {
    fields: disabilityFields,
    append: disabilityAppend,
    remove: disabilityRemove,
  } = useFieldArray({
    control,
    name: "disability_details",
    rules: {
      required: "Minimum requirement is 1 row",
      minLength: {
        value: 1,
        message: "Minimum requirement is 1 row",
      },
      validate: {
        checkPrimary: (v) => {
          let sumPrim = 0;
          v.forEach((item) => {
            if (item?.priority?.value === "Primary") {
              sumPrim++;
            }
          });
          if (sumPrim >= 2) {
            return "There can only be 1 primary";
          }
          // if (sumPrim === 0) {
          //   return "Required 1 primary";
          // }
          return true;
        },
      },
    },
  });

  //FIXME: Required minium 1 row, 1 primary, not multiple primary
  return (
    <PreviewCard className="card-bordered">
      <Row className="g-3">
        <Col size="12">
          <BlockTitle tag="h5">Disability details</BlockTitle>
          <BlockDes className="text-soft">
            <p>Records of diagnosed disabilities</p>
          </BlockDes>
          <div className="nk-divider divider mt-3 mb-1" />
        </Col>
        <SimpleBar className="w-100" forceVisible="x" autoHide={false}>
          <div className="table-wrapper table-actions">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">S/N</th>
                  <th scope="col" className="w-min-300px">
                    Disability <span className="required-asterisk">*</span>
                  </th>
                  <th scope="col" className="w-min-150px">
                    Priority <span className="required-asterisk">*</span>
                  </th>
                  <th scope="col" className="w-min-250px">
                    Description
                  </th>
                  <th scope="col" className="w-min-150px">
                    Year diagnosed
                  </th>
                  <th scope="col" className="w-min-250px">
                    Place diagnosed
                  </th>
                  <th scope="col" className="w-min-200px">
                    Remarks
                  </th>
                  <th scope="col" className="w-min-150px">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {disabilityFields.map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>
                        <SelectField
                          name={`disability_details.${index}.name`}
                          control={control}
                          menuPortalTarget={document.body}
                          rules={{ required: "This field is required" }}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              maxWidth: "300px",
                            }),
                          }}
                          options={[
                            {
                              value: "Attention Deficit Hyperactivity Disorder",
                              label: "Attention Deficit Hyperactivity Disorder",
                            },
                            {
                              value: "Autism Spectrum Disorder",
                              label: "Autism Spectrum Disorder",
                            },
                            {
                              value: "Blind - full",
                              label: "Blind - full",
                            },
                            {
                              value: "Blind - partial",
                              label: "Blind - partial",
                            },
                            {
                              value: "Cerebral Palsy",
                              label: "Cerebral Palsy",
                            },
                            {
                              value: "Deaf - full",
                              label: "Deaf - full",
                            },
                            {
                              value: "Deaf - partial",
                              label: "Deaf - partial",
                            },
                            { value: "Dyslexia", label: "Dyslexia" },
                            {
                              value: "Mild Intellectual Disability",
                              label: "Mild Intellectual Disability",
                            },
                            { value: "Physical", label: "Physical" },
                            { value: "Others", label: "Others" },
                          ]}
                          errors={errors}
                          md="12"
                        />
                      </td>
                      <td>
                        <SelectField
                          name={`disability_details.${index}.priority`}
                          control={control}
                          menuPortalTarget={document.body}
                          rules={{ required: "This field is required" }}
                          isSearchable={false}
                          options={[
                            { value: "Primary", label: "Primary" },
                            { value: "Secondary", label: "Secondary" },
                          ]}
                          errors={errors}
                          md="12"
                        />
                      </td>
                      <td>
                        <TextInput
                          name={`disability_details.${index}.description`}
                          required
                          register={{
                            ...register(
                              `disability_details.${index}.description`
                            ),
                          }}
                          errors={errors}
                          md="12"
                        />
                      </td>
                      <td>
                        <div>
                          <DatePickerInput
                            name={`disability_details.${index}.year_diagnosed`}
                            showYearPicker
                            dateFormat={"yyyy"}
                            placeholder={"yyyy"}
                            maxDate={new Date()}
                            control={control}
                            errors={errors}
                            portalId={`disability_details_${index}_year_diagnosed`}
                            md="12"
                          />
                        </div>
                      </td>
                      <td>
                        <TextInput
                          name={`disability_details.${index}.place_diagnosed`}
                          required
                          register={{
                            ...register(
                              `disability_details.${index}.place_diagnosed`
                            ),
                          }}
                          errors={errors}
                          md="12"
                        />
                      </td>
                      <td>
                        <TextInput
                          name={`disability_details.${index}.remarks`}
                          required
                          register={{
                            ...register(
                              `disability_details.${index}.remarks`
                              // {
                              //   required: "This field is required",
                              // }
                            ),
                          }}
                          errors={errors}
                          md="12"
                        />
                      </td>
                      <td>
                        {index === disabilityFields.length - 1 && (
                          <Icon
                            onClick={() => {
                              disabilityAppend({
                                name: "",
                                priority: "",
                                description: "",
                                year_diagnosed: null,
                                place_diagnosed: "",
                                remarks: "",
                              });
                            }}
                            name="plus-c"
                            className="fs-22px mr-2"
                          />
                        )}
                        {((disabilityFields.length > 1 && index === 0) ||
                          index !== 0) && (
                          <Icon
                            onClick={() => {
                              disabilityRemove(index);
                            }}
                            name="trash"
                            className="fs-22px"
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </SimpleBar>

        <div>
          {errors["disability_details"]?.root && (
            <span className="table-invalid">
              {errors["disability_details"]?.root?.message}
            </span>
          )}
        </div>
      </Row>
    </PreviewCard>
  );
};

const ContactDetail = ({ control, register, errors }) => {
  return (
    <PreviewCard className="card-bordered">
      <Row className="g-3">
        <Col size="12">
          <BlockTitle tag="h5">Contact Details</BlockTitle>
          <div className="nk-divider divider mt-3 mb-1" />
        </Col>
        <TextInput
          name={"home_address"}
          label={"Home address"}
          placeholder={"place the person is staying at"}
          required
          register={{
            ...register("home_address", {
              required: "This field is required",
            }),
          }}
          errors={errors}
          md="12"
        />
        <PhoneNumberWithCountryCodeInput
          label={"Home telephone"}
          nameCode={"home_country_calling_code"}
          nameInputField={"home_phone_number"}
          register={register}
          control={control}
          errors={errors}
          md="12"
        />
        <PhoneNumberWithCountryCodeInput
          label={"Mobile number"}
          nameCode={"country_calling_code"}
          nameInputField={"phone_number"}
          rules={{
            required: "This field is required",
          }}
          register={register}
          control={control}
          errors={errors}
          md="12"
        />
        <TextInput
          name={"email"}
          label={"Email address"}
          placeholder={"name@example.com"}
          required
          register={{
            ...register("email", {
              required: "This field is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address",
              },
            }),
          }}
          errors={errors}
          md="12"
        />
        <TextInput
          name={"emergency_contact_first_name"}
          label={"Emergency contact first name"}
          required
          register={{
            ...register("emergency_contact_first_name", {
              required: "This field is required",
            }),
          }}
          errors={errors}
          md="12"
        />
        <TextInput
          name={"emergency_contact_last_name"}
          label={"Emergency contact last name"}
          required
          register={{
            ...register("emergency_contact_last_name", {
              required: "This field is required",
            }),
          }}
          errors={errors}
          md="12"
        />
        <TextInput
          name={"emergency_contact_relationship"}
          label={"Emergency contact relationship"}
          placeholder={"nature of relationship"}
          required
          register={{
            ...register("emergency_contact_relationship", {
              required: "This field is required",
            }),
          }}
          errors={errors}
          md="12"
        />
        <PhoneNumberWithCountryCodeInput
          label={"Emergency contact mobile number"}
          nameCode={"emergency_contact_country_calling_code"}
          nameInputField={"emergency_phone_number"}
          rules={{
            required: "This field is required",
          }}
          register={register}
          control={control}
          errors={errors}
          md="12"
        />
      </Row>
    </PreviewCard>
  );
};

const EducationalDetail = ({ control, register, errors }) => {
  const {
    fields: educationalFields,
    append: educationalAppend,
    remove: educationalRemove,
  } = useFieldArray({
    control,
    name: "education_details",
    rules: {
      required: "Minimum requirement is 1 row",
      minLength: {
        value: 1,
        message: "Minimum requirement is 1 row",
      },
    },
  });

  return (
    <PreviewCard className="card-bordered">
      <Row className="g-3">
        <Col size="12">
          <BlockTitle tag="h5">Educational details</BlockTitle>
          <BlockDes className="text-soft">
            <p>
              Records of education. It is not necessary to have the entire
              history
            </p>
          </BlockDes>
          <div className="nk-divider divider mt-3 mb-1" />
        </Col>
        <SimpleBar className="w-100" forceVisible="x" autoHide={false}>
          <div className="table-wrapper table-actions">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">S/N</th>
                  <th scope="col" className="w-min-300px">
                    Education level <span className="required-asterisk">*</span>
                  </th>
                  <th scope="col" className="w-min-250px">
                    Institution <span className="required-asterisk">*</span>
                  </th>
                  <th scope="col" className="w-min-250px">
                    Course
                  </th>
                  <th scope="col" className="w-min-150px">
                    From
                  </th>
                  <th scope="col" className="w-min-150px">
                    To
                  </th>
                  <th scope="col" className="w-min-200px">
                    Grades
                  </th>
                  <th scope="col" className="w-min-150px">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {educationalFields.map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>
                        <SelectField
                          name={`education_details.${index}.level`}
                          control={control}
                          menuPortalTarget={document.body}
                          rules={{ required: "This field is required" }}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              maxWidth: "300px",
                            }),
                          }}
                          options={[
                            { value: "PSLE", label: "PSLE" },
                            {
                              value: "GCE N-Level",
                              label: "GCE N-Level",
                            },
                            {
                              value: "GCE O-Level",
                              label: "GCE O-Level",
                            },
                            {
                              value: "GCE A-Level",
                              label: "GCE A-Level",
                            },
                            {
                              value: "Nitec",
                              label: "Nitec",
                            },
                            {
                              value: "Higher Nitec",
                              label: "Higher Nitec",
                            },
                            {
                              value: "Diploma",
                              label: "Diploma",
                            },
                            {
                              value: "Bachelor's",
                              label: "Bachelor's",
                            },
                            {
                              value: "Masters",
                              label: "Masters",
                            },
                            {
                              value: "PHD",
                              label: "PHD",
                            },
                            {
                              value: "Others",
                              label: "Others",
                            },
                          ]}
                          errors={errors}
                          md="12"
                        />
                      </td>
                      <td>
                        <TextInput
                          name={`education_details.${index}.institution`}
                          required
                          register={{
                            ...register(
                              `education_details.${index}.institution`,
                              { required: "This field is required" }
                            ),
                          }}
                          errors={errors}
                          md="12"
                        />
                      </td>
                      <td>
                        <TextInput
                          name={`education_details.${index}.course`}
                          register={{
                            ...register(`education_details.${index}.course`),
                          }}
                          errors={errors}
                          md="12"
                        />
                      </td>
                      <td>
                        <div>
                          <DatePickerInput
                            name={`education_details.${index}.from`}
                            showYearPicker
                            dateFormat={"yyyy"}
                            placeholder={"yyyy"}
                            maxDate={new Date()}
                            control={control}
                            errors={errors}
                            portalId={`education_details_${index}_from`}
                            md="12"
                          />
                        </div>
                      </td>
                      <td>
                        <DatePickerInput
                          name={`education_details.${index}.to`}
                          showYearPicker
                          dateFormat={"yyyy"}
                          placeholder={"yyyy"}
                          maxDate={new Date()}
                          control={control}
                          errors={errors}
                          portalId={`education_details_${index}_to`}
                          md="12"
                        />
                      </td>
                      <td>
                        <TextInput
                          name={`education_details.${index}.grades`}
                          register={{
                            ...register(`education_details.${index}.grades`),
                          }}
                          errors={errors}
                          md="12"
                        />
                      </td>
                      <td>
                        {index === educationalFields.length - 1 && (
                          <Icon
                            onClick={() => {
                              educationalAppend({
                                level: "",
                                institution: "",
                                course: "",
                                from: null,
                                to: null,
                                grades: "",
                              });
                            }}
                            name="plus-c"
                            className="fs-22px mr-2"
                          />
                        )}
                        {((educationalFields.length > 1 && index === 0) ||
                          index !== 0) && (
                          <Icon
                            onClick={() => {
                              educationalRemove(index);
                            }}
                            name="trash"
                            className="fs-22px"
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </SimpleBar>
        <div>
          {errors["education_details"]?.root && (
            <span className="table-invalid">
              {errors["education_details"]?.root?.message}
            </span>
          )}
        </div>
        <TextInput
          name={"education_details_remarks"}
          label={"Remarks on educational details"}
          placeholder={"type here..."}
          register={{
            ...register("education_details_remarks"),
          }}
          errors={errors}
          md="12"
        />
      </Row>
    </PreviewCard>
  );
};

const FamilyDetail = ({ control, register, errors }) => {
  const {
    fields: familyFields,
    append: familyAppend,
    remove: familyRemove,
  } = useFieldArray({
    control,
    name: "family_details",
    rules: {
      required: "Minimum requirement is 1 row",
      minLength: {
        value: 1,
        message: "Minimum requirement is 1 row",
      },
      validate: {
        checkPrimary: (v) => {
          let sumPrim = 0;
          v.forEach((item) => {
            if (item?.caregiver?.value === "Primary") {
              sumPrim++;
            }
          });
          if (sumPrim >= 2) {
            return "There can only be 1 primary";
          }
          return true;
        },
      },
    },
  });

  return (
    <PreviewCard className="card-bordered">
      <Row className="g-3">
        <Col size="12">
          <BlockTitle tag="h5">Family details</BlockTitle>
          <BlockDes className="text-soft">
            <p>Family members</p>
          </BlockDes>
          <div className="nk-divider divider mt-3 mb-1" />
        </Col>
        <SimpleBar className="w-100" forceVisible="x" autoHide={false}>
          <div className="table-wrapper table-actions">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">S/N</th>
                  <th scope="col" className="w-min-300px">
                    First name <span className="required-asterisk">*</span>
                  </th>
                  <th scope="col" className="w-min-250px">
                    Last name <span className="required-asterisk">*</span>
                  </th>
                  <th scope="col" className="w-min-250px">
                    Relationship <span className="required-asterisk">*</span>
                  </th>
                  <th scope="col" className="w-min-150px">
                    Occupation <span className="required-asterisk">*</span>
                  </th>
                  <th scope="col" className="w-min-150px">
                    Caregiver <span className="required-asterisk">*</span>
                  </th>
                  <th scope="col" className="w-min-150px">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {familyFields.map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>
                        <TextInput
                          name={`family_details.${index}.first_name`}
                          required
                          register={{
                            ...register(`family_details.${index}.first_name`, {
                              required: "This field is required",
                            }),
                          }}
                          errors={errors}
                          md="12"
                        />
                      </td>
                      <td>
                        <TextInput
                          name={`family_details.${index}.last_name`}
                          required
                          register={{
                            ...register(`family_details.${index}.last_name`, {
                              required: "This field is required",
                            }),
                          }}
                          errors={errors}
                          md="12"
                        />
                      </td>
                      <td>
                        <SelectField
                          name={`family_details.${index}.relationship`}
                          control={control}
                          menuPortalTarget={document.body}
                          rules={{ required: "This field is required" }}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              maxWidth: "300px",
                            }),
                          }}
                          options={[
                            { value: "Parent", label: "Parent" },
                            {
                              value: "Sibling",
                              label: "Sibling",
                            },
                            { value: "Spouse", label: "Spouse" },
                            {
                              value: "No primary caregiver",
                              label: "No primary caregiver",
                            },
                            { value: "Other", label: "Other" },
                          ]}
                          errors={errors}
                          md="12"
                        />
                      </td>
                      <td>
                        <TextInput
                          name={`family_details.${index}.occupation`}
                          required
                          register={{
                            ...register(`family_details.${index}.occupation`, {
                              required: "This field is required",
                            }),
                          }}
                          errors={errors}
                          md="12"
                        />
                      </td>
                      <td>
                        <SelectField
                          name={`family_details.${index}.caregiver`}
                          control={control}
                          menuPortalTarget={document.body}
                          rules={{ required: "This field is required" }}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              maxWidth: "300px",
                            }),
                          }}
                          options={[
                            { value: "Primary", label: "Primary" },
                            { value: "Secondary", label: "Secondary" },
                            { value: "No", label: "No" },
                          ]}
                          errors={errors}
                          md="12"
                        />
                      </td>
                      <td>
                        {index === familyFields.length - 1 && (
                          <Icon
                            onClick={() => {
                              familyAppend({
                                first_name: "",
                                last_name: "",
                                relationship: "",
                                occupation: "",
                                caregiver: "",
                              });
                            }}
                            name="plus-c"
                            className="fs-22px mr-2"
                          />
                        )}
                        {((familyFields.length > 1 && index === 0) ||
                          index !== 0) && (
                          <Icon
                            onClick={() => {
                              familyRemove(index);
                            }}
                            name="trash"
                            className="fs-22px"
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </SimpleBar>
        <div>
          {errors["family_details"]?.root && (
            <span className="table-invalid">
              {errors["family_details"]?.root?.message}
            </span>
          )}
        </div>
        <TextInput
          name={"family_details_remarks"}
          label={"Remarks on family details"}
          placeholder={"type here..."}
          register={{
            ...register("family_details_remarks"),
          }}
          errors={errors}
          md="12"
        />
      </Row>
    </PreviewCard>
  );
};

const EmploymentDetail = ({ control, register, errors }) => {
  const {
    fields: employmentFields,
    append: employmentAppend,
    remove: employmentRemove,
  } = useFieldArray({
    control,
    name: "employment_details",
  });

  return (
    <PreviewCard className="card-bordered">
      <Row className="g-3">
        <Col size="12">
          <BlockTitle tag="h5">Employment details</BlockTitle>
          <div className="nk-divider divider mt-3 mb-1" />
        </Col>
        <SimpleBar className="w-100" forceVisible="x" autoHide={false}>
          <div className="table-wrapper table-actions">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">S/N</th>
                  <th scope="col" className="w-min-250px">
                    Employer <span className="required-asterisk">*</span>
                  </th>
                  <th scope="col" className="w-min-250px">
                    Role <span className="required-asterisk">*</span>
                  </th>
                  <th scope="col" className="w-min-250px">
                    Employment type <span className="required-asterisk">*</span>
                  </th>
                  <th scope="col" className="w-min-150px">
                    From
                  </th>
                  <th scope="col" className="w-min-150px">
                    To
                  </th>
                  <th scope="col" className="w-min-150px">
                    Salary
                  </th>
                  <th scope="col" className="w-min-300px">
                    Reason for leaving
                  </th>
                  <th scope="col" className="w-min-150px">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {employmentFields.map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>
                        <TextInput
                          name={`employment_details.${index}.employer`}
                          required
                          register={{
                            ...register(
                              `employment_details.${index}.employer`,
                              {
                                required: "This field is required",
                              }
                            ),
                          }}
                          errors={errors}
                          md="12"
                        />
                      </td>
                      <td>
                        <TextInput
                          name={`employment_details.${index}.role`}
                          required
                          register={{
                            ...register(`employment_details.${index}.role`, {
                              required: "This field is required",
                            }),
                          }}
                          errors={errors}
                          md="12"
                        />
                      </td>
                      <td>
                        <SelectField
                          name={`employment_details.${index}.type`}
                          control={control}
                          menuPortalTarget={document.body}
                          rules={{ required: "This field is required" }}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              maxWidth: "300px",
                            }),
                          }}
                          options={[
                            { value: "Parent", label: "Parent" },
                            {
                              value: "Sibling",
                              label: "Sibling",
                            },
                            { value: "Spouse", label: "Spouse" },
                            {
                              value: "No primary caregiver",
                              label: "No primary caregiver",
                            },
                            { value: "Other", label: "Other" },
                          ]}
                          errors={errors}
                          md="12"
                        />
                      </td>
                      <td>
                        <DatePickerInput
                          name={`employment_details.${index}.from`}
                          showYearPicker
                          dateFormat={"yyyy"}
                          placeholder={"yyyy"}
                          maxDate={new Date()}
                          control={control}
                          errors={errors}
                          portalId={`employment_details_${index}_from`}
                          md="12"
                        />
                      </td>
                      <td>
                        <DatePickerInput
                          name={`employment_details.${index}.to`}
                          showYearPicker
                          dateFormat={"yyyy"}
                          placeholder={"yyyy"}
                          maxDate={new Date()}
                          control={control}
                          errors={errors}
                          portalId={`employment_details_${index}_to`}
                          md="12"
                        />
                      </td>
                      <td>
                        <TextInput
                          name={`employment_details.${index}.salary`}
                          register={{
                            ...register(`employment_details.${index}.salary`),
                          }}
                          errors={errors}
                          md="12"
                        />
                      </td>
                      <td>
                        <TextInput
                          name={`employment_details.${index}.reason_leaving`}
                          register={{
                            ...register(
                              `employment_details.${index}.reason_leaving`
                            ),
                          }}
                          errors={errors}
                          md="12"
                        />
                      </td>
                      <td>
                        {index === employmentFields.length - 1 && (
                          <Icon
                            onClick={() => {
                              employmentAppend({
                                from: null,
                                to: null,
                                employer: "",
                                role: "",
                                type: "",
                                salary: "",
                                reason_leaving: "",
                              });
                            }}
                            name="plus-c"
                            className="fs-22px mr-2"
                          />
                        )}
                        {((employmentFields.length > 1 && index === 0) ||
                          index !== 0) && (
                          <Icon
                            onClick={() => {
                              employmentRemove(index);
                            }}
                            name="trash"
                            className="fs-22px"
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </SimpleBar>
        <div>
          {errors["employment_details"]?.root && (
            <span className="table-invalid">
              {errors["employment_details"]?.root?.message}
            </span>
          )}
        </div>
        <TextInput
          name={"employment_details_remarks"}
          label={"Remarks on employment details"}
          placeholder={"type here..."}
          register={{
            ...register("employment_details_remarks"),
          }}
          errors={errors}
          md="12"
        />
      </Row>
    </PreviewCard>
  );
};

const EmploymentMotivationAndExpectations = ({ control, register, errors }) => {
  return (
    <PreviewCard className="card-bordered">
      <Row className="g-3">
        <Col md="12">
          <BlockTitle tag="h5">
            Employment motivation &amp; expectations
          </BlockTitle>
          <div className="nk-divider divider mt-3 mb-1" />
        </Col>
        <Col md="12">
          <label className="m-0 label-form-group">
            What are your motivations for a job?
          </label>
        </Col>
        <TextInput
          name={"reason_to_work"}
          label={"What are your reasons for wanting to find work?"}
          placeholder={"motivation for finding work"}
          required
          register={{
            ...register("reason_to_work", {
              required: "This field is required",
            }),
          }}
          errors={errors}
          outerClassName={"pr-4"}
          md="12"
        />
        <TextInput
          name={"employment_motivation_job_applied"}
          label={
            "Have you looked for a job yourself? If yes, what have you applied for?"
          }
          placeholder={"e.g. admin"}
          required
          register={{
            ...register("employment_motivation_job_applied", {
              required: "This field is required",
            }),
          }}
          errors={errors}
          outerClassName={"pl-4"}
          md="12"
        />
        <Col md="12">
          <label className="mt-4 mb-0 label-form-group">
            What are your expectations for a job?
          </label>
        </Col>
        <TextInput
          name={"employment_expectation_industry"}
          label={"Industry"}
          required
          register={{
            ...register("employment_expectation_industry", {
              required: "This field is required",
            }),
          }}
          errors={errors}
          outerClassName={"pr-4"}
          md="12"
        />
        <TextInput
          name={"employment_expectation_location"}
          label={"Location"}
          placeholder={"area or region"}
          required
          register={{
            ...register("employment_expectation_location", {
              required: "This field is required",
            }),
          }}
          errors={errors}
          outerClassName={"pl-4"}
          md="12"
        />
        <TextInput
          name={"employment_expectation_salary"}
          label={"Salary"}
          placeholder={"range"}
          required
          register={{
            ...register("employment_expectation_salary", {
              required: "This field is required",
            }),
          }}
          errors={errors}
          outerClassName={"pr-4"}
          md="12"
        />
        <TextInput
          name={"employment_expectation_timing"}
          label={"Timing"}
          placeholder={"indication"}
          required
          register={{
            ...register("employment_expectation_timing", {
              required: "This field is required",
            }),
          }}
          errors={errors}
          outerClassName={"pl-4"}
          md="12"
        />
      </Row>
    </PreviewCard>
  );
};

const MedicalDetail = ({ control, register, errors }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "medical_details",
  });

  return (
    <PreviewCard className="card-bordered">
      <Row className="g-3">
        <Col size="12">
          <BlockTitle tag="h5">Medical details</BlockTitle>
          <BlockDes className="text-soft">
            <p>Medical records &amp; history</p>
          </BlockDes>
          <div className="nk-divider divider mt-3 mb-1" />
        </Col>
        <SimpleBar className="w-100" forceVisible="x" autoHide={false}>
          <div className="table-wrapper table-actions">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">S/N</th>
                  <th scope="col" className="w-min-250px">
                    Type <span className="required-asterisk">*</span>
                  </th>
                  <th scope="col" className="w-min-250px">
                    Condition <span className="required-asterisk">*</span>
                  </th>
                  <th scope="col" className="w-min-250px">
                    Year diagnosed <span className="required-asterisk">*</span>
                  </th>
                  <th scope="col" className="w-min-150px">
                    Clinic
                  </th>
                  <th scope="col" className="w-min-150px">
                    Doctor
                  </th>
                  <th scope="col" className="w-min-150px">
                    Follow-up appointments
                  </th>
                  <th scope="col" className="w-min-300px">
                    Medications
                  </th>
                  <th scope="col" className="w-min-300px">
                    Side effects
                  </th>
                  <th scope="col" className="w-min-150px">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {fields.map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>
                        <SelectField
                          name={`medical_details.${index}.type`}
                          control={control}
                          menuPortalTarget={document.body}
                          rules={{ required: "This field is required" }}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              maxWidth: "300px",
                            }),
                          }}
                          options={[
                            { value: "Parent", label: "Parent" },
                            {
                              value: "Sibling",
                              label: "Sibling",
                            },
                            { value: "Spouse", label: "Spouse" },
                            {
                              value: "No primary caregiver",
                              label: "No primary caregiver",
                            },
                            { value: "Other", label: "Other" },
                          ]}
                          errors={errors}
                          md="12"
                        />
                      </td>
                      <td>
                        <TextInput
                          name={`medical_details.${index}.condition`}
                          required
                          register={{
                            ...register(`medical_details.${index}.condition`, {
                              required: "This field is required",
                            }),
                          }}
                          errors={errors}
                          md="12"
                        />
                      </td>
                      <td>
                        <DatePickerInput
                          name={`medical_details.${index}.year_diagnosed`}
                          showYearPicker
                          dateFormat={"yyyy"}
                          placeholder={"yyyy"}
                          rules={{ required: "This field is required" }}
                          maxDate={new Date()}
                          control={control}
                          errors={errors}
                          portalId={`medical_details_${index}_year_diagnosed`}
                          md="12"
                        />
                      </td>
                      <td>
                        <TextInput
                          name={`medical_details.${index}.clinic`}
                          register={{
                            ...register(`medical_details.${index}.clinic`),
                          }}
                          errors={errors}
                          md="12"
                        />
                      </td>
                      <td>
                        <TextInput
                          name={`medical_details.${index}.doctor`}
                          register={{
                            ...register(`medical_details.${index}.doctor`),
                          }}
                          errors={errors}
                          md="12"
                        />
                      </td>
                      <td>
                        <TextInput
                          name={`medical_details.${index}.follow_up_appointments`}
                          register={{
                            ...register(
                              `medical_details.${index}.follow_up_appointments`
                            ),
                          }}
                          errors={errors}
                          md="12"
                        />
                      </td>
                      <td>
                        <TextInput
                          name={`medical_details.${index}.medications`}
                          register={{
                            ...register(`medical_details.${index}.medications`),
                          }}
                          errors={errors}
                          md="12"
                        />
                      </td>
                      <td>
                        <TextInput
                          name={`medical_details.${index}.side_effects`}
                          register={{
                            ...register(
                              `medical_details.${index}.side_effects`
                            ),
                          }}
                          errors={errors}
                          md="12"
                        />
                      </td>
                      <td>
                        {index === fields.length - 1 && (
                          <Icon
                            onClick={() => {
                              append({
                                type: "",
                                year_diagnosed: null,
                                condition: "",
                                clinic: "",
                                doctor: "",
                                follow_up_appointments: "",
                                medications: "",
                                side_effects: "",
                              });
                            }}
                            name="plus-c"
                            className="fs-22px mr-2"
                          />
                        )}
                        {((fields.length > 1 && index === 0) ||
                          index !== 0) && (
                          <Icon
                            onClick={() => {
                              remove(index);
                            }}
                            name="trash"
                            className="fs-22px"
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </SimpleBar>
        <div>
          {errors["medical_details"]?.root && (
            <span className="table-invalid">
              {errors["medical_details"]?.root?.message}
            </span>
          )}
        </div>
        <TextInput
          name={"medical_details_remarks"}
          label={"Remarks on medical details"}
          placeholder={"type here..."}
          register={{
            ...register("medical_details_remarks"),
          }}
          errors={errors}
          md="12"
        />
      </Row>
    </PreviewCard>
  );
};

const Observations = ({ control, register, errors }) => {
  return (
    <PreviewCard className="card-bordered">
      <Row className="g-3">
        <Col size="12">
          <BlockTitle tag="h5">Observations</BlockTitle>
          <div className="nk-divider divider mt-3 mb-1" />
        </Col>
        <div className="table-wrapper table-actions">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col" className="w-55">
                  Category
                </th>
                <th scope="col">Observations</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Appearance (e.g. neat, unkempt, wore wrinkled clothes)</td>
                <td>
                  <TextInput
                    name={`observations_appearance`}
                    register={{
                      ...register(`observations_appearance`, {
                        required: "This field is required",
                      }),
                    }}
                    errors={errors}
                    md="12"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Physical Abilities (e.g. low muscle tone, weak fine/gross
                  motor skills, can lift up hands, can carry things from floor)
                </td>
                <td>
                  <TextInput
                    name={`observations_physical_abilities`}
                    register={{
                      ...register(`observations_physical_abilities`, {
                        required: "This field is required",
                      }),
                    }}
                    errors={errors}
                    md="12"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Receptive Communication (Ability to understand &amp; process
                  info)
                </td>
                <td>
                  <TextInput
                    name={`observations_receptive_communication`}
                    register={{
                      ...register(`observations_receptive_communication`, {
                        required: "This field is required",
                      }),
                    }}
                    errors={errors}
                    md="12"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Expressive Communication (Ability to communicate needs - may
                  be verbal/non-verbal)
                </td>
                <td>
                  <TextInput
                    name={`observations_expressive_communication`}
                    register={{
                      ...register(`observations_expressive_communication`, {
                        required: "This field is required",
                      }),
                    }}
                    errors={errors}
                    md="12"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Emotional Regulation (Coping skills &amp; strategies when
                  upset, angry, stressed)
                </td>
                <td>
                  <TextInput
                    name={`observations_emotional_regulation`}
                    register={{
                      ...register(`observations_emotional_regulation`, {
                        required: "This field is required",
                      }),
                    }}
                    errors={errors}
                    md="12"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Behaviour &amp; Interaction (Ability to engage in
                  conversation, eye contact, social skills, etc)
                </td>
                <td>
                  <TextInput
                    name={`observations_behaviour_and_interaction`}
                    register={{
                      ...register(`observations_behaviour_and_interaction`, {
                        required: "This field is required",
                      }),
                    }}
                    errors={errors}
                    md="12"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Row>
    </PreviewCard>
  );
};

const ExecutiveFunctioning = ({ control, register, errors }) => {
  return (
    <PreviewCard className="card-bordered">
      <Row className="g-3">
        <Col size="12">
          <BlockTitle tag="h5">Executive Functioning</BlockTitle>
          <BlockDes className="text-soft">
            <p>
              Recommended 20 minutes with an alarm set. Inform Candidate at 5
              &amp; 1-minute mark
            </p>
          </BlockDes>
          <div className="nk-divider divider mt-3 mb-1" />
        </Col>
        <SelectField
          name={"executive_functioning_tools_used"}
          label={"Tools used"}
          isSearchable={false}
          isMulti
          control={control}
          rules={{ required: "This field is required" }}
          options={[
            { value: "Yes", label: "Yes" },
            { value: "No", label: "No" },
          ]}
          errors={errors}
          md="12"
        />
        <div className="table-wrapper table-actions">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col" className="w-55">
                  Executive functioning
                </th>
                <th scope="col">Observations</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Inhibit (Recognizing the need to stop your own behaviour &amp;
                  resist impulses)
                </td>
                <td>
                  <TextInput
                    name={`executive_functioning_inhibit`}
                    register={{
                      ...register(`executive_functioning_inhibit`, {
                        required: "This field is required",
                      }),
                    }}
                    errors={errors}
                    md="12"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Shift (Easily transition &amp; change focus From interview to
                  LEGO &amp; back to interview?)
                </td>
                <td>
                  <TextInput
                    name={`executive_functioning_shift`}
                    register={{
                      ...register(`executive_functioning_shift`, {
                        required: "This field is required",
                      }),
                    }}
                    errors={errors}
                    md="12"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Emotional Control (Ability to modulate emotional response
                  Frustrated? Calm?)
                </td>
                <td>
                  <TextInput
                    name={`executive_functioning_emotional_control`}
                    register={{
                      ...register(`executive_functioning_emotional_control`, {
                        required: "This field is required",
                      }),
                    }}
                    errors={errors}
                    md="12"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Self-Monitor (Awareness of the impact of behaviour on others
                  Level of Self-Awareness?)
                </td>
                <td>
                  <TextInput
                    name={`executive_functioning_self_monitor`}
                    register={{
                      ...register(`executive_functioning_self_monitor`, {
                        required: "This field is required",
                      }),
                    }}
                    errors={errors}
                    md="12"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Initiate (Independently beginning &amp; continuing a task)
                </td>
                <td>
                  <TextInput
                    name={`executive_functioning_initiate`}
                    register={{
                      ...register(`executive_functioning_initiate`, {
                        required: "This field is required",
                      }),
                    }}
                    errors={errors}
                    md="12"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Working Memory (Holding on to multiple pieces of information)
                </td>
                <td>
                  <TextInput
                    name={`executive_functioning_working_memory`}
                    register={{
                      ...register(`executive_functioning_working_memory`, {
                        required: "This field is required",
                      }),
                    }}
                    errors={errors}
                    md="12"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Plan / Organise (Managing current &amp; future task demands)
                </td>
                <td>
                  <TextInput
                    name={`executive_functioning_plan`}
                    register={{
                      ...register(`executive_functioning_plan`, {
                        required: "This field is required",
                      }),
                    }}
                    errors={errors}
                    md="12"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Task Monitor (Keeping track &amp; identifying &amp; correcting
                  mistakes)
                </td>
                <td>
                  <TextInput
                    name={`executive_functioning_task_monitor`}
                    register={{
                      ...register(`executive_functioning_task_monitor`, {
                        required: "This field is required",
                      }),
                    }}
                    errors={errors}
                    md="12"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Organisation of Material (Easily locate &amp; maintain
                  orderliness of belongings)
                </td>
                <td>
                  <TextInput
                    name={`executive_functioning_organisation_material`}
                    register={{
                      ...register(
                        `executive_functioning_organisation_material`,
                        {
                          required: "This field is required",
                        }
                      ),
                    }}
                    errors={errors}
                    md="12"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Comments (Level of concentration, completed up to which stage?
                  Gave up? What did Candidate think of the activity?)
                </td>
                <td>
                  <TextInput
                    name={`executive_functioning_comments`}
                    register={{
                      ...register(`executive_functioning_comments`, {
                        required: "This field is required",
                      }),
                    }}
                    errors={errors}
                    md="12"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Row>
    </PreviewCard>
  );
};

const StrengthsAndDifficultiesQuestionnaire = ({ formData, code }) => {
  const [
    createSDQForm,
    {
      isLoading: isCreateLoading,
      isError: isCreateError,
      isSuccess: isCreateSuccess,
      error: createError,
    },
  ] = useCreateSDQFormMutation();
  const [
    getQuestions,
    {
      data: qData,
      isFetching: isGetQuestionsFetching,
      isLoading: isGetQuestionsLoading,
      isSuccess: isGetQuestionsSuccess,
      isError: isGetQuestionsError,
      error: getQuestionsError,
    },
  ] = useLazyGetSDQuestionsQuery();
  const [isModalOpen, setModalOpen] = useState(false);

  const onCreateSDQClick = (ev) => {
    ev.preventDefault();
    createSDQForm({
      reference_code: code,
      user_email: formData.email,
    });
  };

  const onDetailsClicked = () => {
    getQuestions({ limit: 1000 });
    setModalOpen(true);
  };

  useEffect(() => {
    if (isCreateSuccess) {
      window.open(`../sdq-form/${code}`, "_blank", "noopener,noreferrer");
    }
    if (isCreateError && createError) {
      toast.error(createError?.data?.message);
    }
  }, [isCreateSuccess, isCreateError]);

  const Content = () => {
    if (
      isCreateSuccess ||
      (formData.sdq_submission && !formData.sdq_submission.is_completed)
    ) {
      return (
        <Button
          color="primary"
          type="submit"
          size={"lg"}
          className={"w-min-200px"}
          onClick={(ev) => {
            ev.preventDefault();
            window.open(`../sdq-form/${code}`, "_blank", "noopener,noreferrer");
          }}
        >
          <span>Continue SDQ form</span>
        </Button>
      );
    }

    if (
      formData.sdq_submission &&
      formData.sdq_submission.is_completed &&
      formData.sdq_score_result
    ) {
      const dataResult = formData?.sdq_submission;
      return (
        <SimpleBar className="w-100" forceVisible="x" autoHide={false}>
          <div className="table-wrapper">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col" className="w-min-200px">
                    Date time
                  </th>
                  <th scope="col" className="w-min-200px">
                    By
                  </th>
                  <th scope="col" className="w-min-175px">
                    Total difficulties
                  </th>
                  <th scope="col" className="w-min-175px">
                    Prone to externalising
                  </th>
                  <th scope="col" className="w-min-175px">
                    Prone to internalising
                  </th>
                  <th scope="col" className="w-min-300px">
                    Details
                  </th>
                  <th scope="col" className="w-min-200px"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {moment(dataResult.last_updated_time).format(
                      "DD-MM-YYYY, h:mm:ss a"
                    )}
                  </td>
                  <td>
                    {dataResult.assessor_name
                      ? dataResult.assessor_name
                      : dataResult.assessor_email}
                  </td>
                  <td>{`${dataResult.sdq_score_result?.total_difficulties} out of 40`}</td>
                  <td>{`${dataResult.sdq_score_result?.prone_to_externalising} out of 20`}</td>
                  <td>{`${dataResult.sdq_score_result?.prone_to_internalising} out of 20`}</td>
                  <td>
                    {dataResult.sdq_score_result?.scale_scores?.map(
                      (e, idx) => {
                        return (
                          <div key={e.scale}>
                            <span>{`${e.scale}: ${e.score}, ${e.category}`}</span>
                            {idx !==
                              dataResult.sdq_score_result?.scale_scores
                                ?.length -
                                1 && <br />}
                          </div>
                        );
                      }
                    )}
                  </td>
                  <td>
                    <Icon
                      name="eye"
                      className="fs-22px"
                      onClick={onDetailsClicked}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </SimpleBar>
      );
    }

    return (
      <Button
        color="primary"
        type="submit"
        size={"lg"}
        className={"w-min-200px"}
        onClick={onCreateSDQClick}
        disabled={isCreateLoading}
      >
        {isCreateLoading && <Spinner type="grow" size="sm" />}
        <span>+ Create new SDQ form</span>
      </Button>
    );
  };

  return (
    <PreviewCard className="card-bordered">
      <Row className="g-3">
        <Col size="12">
          <BlockTitle tag="h5">
            Strengths &amp; Difficulties Questionnaire
          </BlockTitle>
          <div className="nk-divider divider mt-3 mb-1" />
        </Col>
        <Col size="12">
          <Content />
        </Col>
      </Row>

      <ModalComponent
        isOpen={isModalOpen}
        toggle={() => {
          setModalOpen(false);
        }}
        title={"Details"}
        onModalCancel={() => {
          setModalOpen(false);
        }}
      >
        {isGetQuestionsLoading && (
          <div className="text-center py-4">
            <Spinner />
          </div>
        )}
        {isGetQuestionsSuccess && (
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col" style={{ width: "70px" }}>
                  No
                </th>
                <th scope="col" className="w-300px">
                  Question
                </th>
                <th scope="col" className="w-125px">
                  Answer
                </th>
              </tr>
            </thead>
            <tbody>
              {qData?.questions.map((e, idx) => {
                const el = formData?.sdq_submission?.sdq_answers?.find(
                  (obj) => obj.code === e.code
                );
                const answer = el?.answer.split("_").join(" ");
                return (
                  <tr key={e.id}>
                    <td>{idx + 1}</td>
                    <td>{e.question}</td>
                    <td className="ccap">{answer}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </ModalComponent>
    </PreviewCard>
  );
};

const AssessmentRecommendation = ({ control, register, errors }) => {
  return (
    <PreviewCard className="card-bordered">
      <Row className="g-3">
        <Col size="12">
          <BlockTitle tag="h5">Assessment recommendation</BlockTitle>
          <div className="nk-divider divider mt-3 mb-1" />
        </Col>
        <SelectField
          name={"assessment_recommendation"}
          label={"Recommendation"}
          isMulti
          isSearchable={false}
          control={control}
          rules={{
            required: "This field is required",
            validate: {
              checkNotSuitable: (v) => {
                let isNotSuitable = false;
                v.forEach((item) => {
                  if (item.value === "Not suitable") {
                    isNotSuitable = true;
                  }
                });
                if (isNotSuitable && v.length > 1) {
                  return "Can not choose Not suitable with others";
                }
                return true;
              },
            },
          }}
          options={assessmentRecommendationOptions}
          errors={errors}
          md="12"
        />
        <TextInput
          name={"support_required"}
          label={"Support required"}
          placeholder={"type here..."}
          required
          register={{
            ...register("support_required", {
              required: "This field is required",
            }),
          }}
          errors={errors}
          md="12"
        />
        <TextInput
          name={"feedback"}
          label={"Other feedback / comments"}
          placeholder={"type here..."}
          register={{
            ...register("feedback"),
          }}
          errors={errors}
          md="12"
        />
      </Row>
    </PreviewCard>
  );
};

export default IntakeAssessment;
