import React, { useState, useContext, useEffect } from "react";
import {
  UserAvatar,
  Icon,
  LinkList,
  LinkItem,
  ModalComponent,
  TextInput,
} from "../../components/Components";
import {
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import { ChatContext } from "./ChatContext";
import { capitalize, findUpper } from "../../utils/utils";
import { useSelector } from "react-redux";
import moment from "moment";
import { set, useForm } from "react-hook-form";
import { useLazySearchUsersQuery } from "../../redux/api/users/usersApi";
import classNames from "classnames";
import { toast } from "react-toastify";

const ChatSideBar = ({ sidebar, channel }) => {
  const {
    changeNickname,
    changeTheme,
    updateChannel,
    leaveGroupChannel,
    removeOperators,
    banUser,
    addOperators,
    userID,
    inviteMembers,
  } = useContext(ChatContext);
  const [memberState, setMemberState] = useState(false);
  const [optionState, setOptionState] = useState(false);
  const [settingState, setSettingState] = useState(false);
  const [photoState, setPhotoState] = useState(false);
  const [isGroup, setIsGroup] = useState(false);
  const [isInviteMemberModalOpen, setIsInviteMemberModalOpen] = useState(false);
  const [newChatUserList, setNewChatUserList] = useState([]);
  const [isOperator, setIsOperator] = useState(false);
  const [isChangeGroupNameOpen, setIsChangeGroupNameOpen] = useState(false);
  const toggleChangeGroupName = () => setIsChangeGroupNameOpen((prevState) => !prevState);
  const [
    searchUsers,
    {
      data: searchedData,
      isUninitialized: isSearchUninitialized,
      isSuccess: isSearchSuccess,
      isFetching: isSearchFetching,
      isError: isSearchError,
    },
  ] = useLazySearchUsersQuery();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      new_chat_selected_users: [],
    },
  });
  const watchNewChatSelectedUsers = watch("new_chat_selected_users");

  useEffect(() => {
    if (channel) {
      if (channel?.memberCount > 2) {
        setIsGroup(true);
      } else {
        setIsGroup(false);
      }

      for (let i = 0; i < channel?.members?.length; i++) {
        if (channel?.members[i]?.userId === userID) {
          setIsOperator(channel?.members[i]?.role === "operator");
          break;
        }
      }
    }
  }, [channel]);

  useEffect(() => {
    if (isSearchFetching) {
      return;
    }
    if (searchedData) {
      setNewChatUserList([...searchedData]);
    } else {
      setNewChatUserList([]);
    }
  }, [searchedData, isSearchFetching, setNewChatUserList]);

  const onNewChatSearchChange = (e) => {
    let text = e.target.value;
    if (text !== "" && text.length >= 3) {
      searchUsers(
        {
          search_text: e.target.value,
          exclude_ids: channel.members?.map((member) => member?.userId),
        },
        true
      );
    }
  };

  const onNewChatRemoveSelectedItem = (item) => {
    let selectedList = getValues("new_chat_selected_users");
    selectedList = selectedList.filter(
      (user) => user.sendbird_user_id !== item.sendbird_user_id
    );
    setValue("new_chat_selected_users", [...selectedList]);
  };

  const onNewChatUserSelect = (user) => {
    let selectedList = getValues("new_chat_selected_users");

    let isExists = false;
    for (let i = 0; i < selectedList.length; i++) {
      if (selectedList[i].sendbird_user_id === user.sendbird_user_id) {
        isExists = true;
        selectedList.splice(i, 1);
        setValue("new_chat_selected_users", [...selectedList]);
        break;
      }
    }
    if (!isExists) {
      setValue("new_chat_selected_users", [...selectedList, user]);
    }
  };

  const onInviteMemberModalCancel = () => {
    setIsInviteMemberModalOpen(false);
  };

  const onFormInviteMemberSubmit = (data) => {
    if (
      !data.new_chat_selected_users ||
      data.new_chat_selected_users.length === 0
    ) {
      toast.error("Please select at least one user");
      return;
    }

    inviteMembers({
      ids: data.new_chat_selected_users?.map((user) => user.sendbird_user_id),
    });
    setIsInviteMemberModalOpen(false);
    reset();
  };

  const onBanUserClicked = (member) => {
    banUser({ userID: member?.userId });
  };

  const onMakeOperatorClicked = (member) => {
    addOperators({ ids: [member?.userId] });
  };

  const onUnregisterOperatorClicked = (member) => {
    removeOperators({ ids: [member?.userId] });
  };

  const onLeaveGroupClicked = () => {
    leaveGroupChannel();
  };

  return (
    <div>
      <SimpleBar className={`nk-chat-profile ${sidebar ? "visible" : ""}`}>
        <div className="user-card user-card-s2 my-4">
          {isGroup ? (
            <div className="user-avatar-group">
              {channel?.members.slice(0, 3).map((member, index) => {
                return (
                  <UserAvatar
                    // theme={user.theme}
                    text={findUpper(member.nickname)}
                    // image={user.image}
                    size="md"
                    className="chat-media"
                    key={index}
                  ></UserAvatar>
                );
              })}
              {/* {chat.memberCount > 3 && (
              <UserAvatar
                theme="bg-light"
                text={chat.user.length - 3}
                size="md"
                className="chat-media"
              ></UserAvatar>
            )} */}
            </div>
          ) : (
            <UserAvatar
              // theme={chat.theme}
              // image={chat.image}
              size="md"
              text={findUpper(channel.name)}
            ></UserAvatar>
          )}
          <div className="user-info">
            <h5>{channel.name}</h5>
          </div>
          <UncontrolledDropdown className="user-card-menu">
            <DropdownToggle
              tag="a"
              className="btn btn-icon btn-sm btn-trigger dropdown-toggle"
            >
              <Icon name="more-h"></Icon>
            </DropdownToggle>
            <DropdownMenu right>
              {/* <LinkList opt className="no-bdr">
                <LinkItem link={"/user-details-regular/1"} icon="eye">
                  View Profile
                </LinkItem>
                <LinkItem link={""} tag="a" icon="na">
                  Block From System
                </LinkItem>
              </LinkList> */}
              <ul className="link-list-opt no-bdr">
                {!isGroup && (
                  <li>
                    <DropdownItem
                      tag="a"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                      href="#view-profile"
                    >
                      <Icon name="eye-fill"></Icon>
                      View profile
                    </DropdownItem>
                  </li>
                )}
                {!isGroup && (
                  <li>
                    <DropdownItem
                      tag="a"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                      href="#block"
                      className="text-danger"
                    >
                      <Icon name="na"></Icon>
                      Block
                    </DropdownItem>
                  </li>
                )}
                {isGroup && (
                  <li>
                    <DropdownItem
                      tag="a"
                      onClick={(e) => {
                        e.preventDefault();
                        onLeaveGroupClicked();
                      }}
                      href="#block"
                      className="text-danger"
                    >
                      <Icon name="signout"></Icon>
                      Leave group
                    </DropdownItem>
                  </li>
                )}
              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <div className="chat-profile">
          <div className="chat-profile-group">
            <a
              href="#options"
              onClick={(ev) => {
                ev.preventDefault();
                setOptionState(!optionState);
              }}
              className={`chat-profile-head`}
              id="chat-options"
            >
              <h6 className="title overline-title">Options</h6>
              <span className="indicator-icon">
                <Icon name={`chevron-${optionState ? "up" : "down"}`}></Icon>
              </span>
            </a>
            <div
              className={`chat-profile-body collapse ${
                optionState ? "" : "show"
              }`}
              id="chat-options"
            >
              <div className="chat-profile-body-inner">
                <ul className="chat-profile-options">
                  <li>
                    <Dropdown isOpen={isChangeGroupNameOpen} toggle={toggleChangeGroupName}>
                      <DropdownToggle
                        tag="a"
                        href="#"
                        color="transparent"
                        className="chat-option-link"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                      >
                        <Icon className="icon icon-circle bg-light ni ni-edit-alt"></Icon>
                        <span className="lead-text">
                          {isGroup ? "Group Name" : "Nickname"}
                        </span>
                      </DropdownToggle>
                      <DropdownMenu>
                        <ul className="link-tidy no-bdr p-3">
                          <input
                            className="form-control"
                            type="text"
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                toggleChangeGroupName();
                                updateChannel({ name: e.target.value });
                              }
                            }}
                          />
                        </ul>
                      </DropdownMenu>
                    </Dropdown>

                    {/* <UncontrolledDropdown>
                      <DropdownToggle
                        tag="a"
                        href="#"
                        color="transparent"
                        className="chat-option-link"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                      >
                        <Icon className="icon icon-circle bg-light ni ni-edit-alt"></Icon>
                        <span className="lead-text">
                          {isGroup ? "Group Name" : "Nickname"}
                        </span>
                      </DropdownToggle>
                      <DropdownMenu>
                        <ul className="link-tidy no-bdr p-3">
                          <div>
                          <input
                            className="form-control"
                            type="text"
                            // onKeyDown={(e) => {
                            //   e.code === "Enter" && console.log("enter");
                            // }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                console.log("enter");
                                e.preventDefault();
                                e.target.blur();
                              }
                            }}
                            onChange={(e) => {
                              // changeNickname(chat.id, e.target.value)
                            }}
                          />
                          <Icon name="check-round-fill" className="text-success" />
                          </div>
                        </ul>
                      </DropdownMenu>
                    </UncontrolledDropdown> */}
                  </li>
                  <li>
                    <UncontrolledDropdown>
                      <DropdownToggle
                        tag="a"
                        color="transparent"
                        className="chat-option-link"
                        href="#dropdown"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                      >
                        <Icon
                          name="circle-fill"
                          // className={`icon-circle bg-light text-${chat.chatTheme}`}
                          className={`icon-circle bg-light`}
                        ></Icon>
                        <span className="lead-text">Change Theme</span>
                      </DropdownToggle>
                      <DropdownMenu>
                        <ul className="link-list-plain li-col3x text-center">
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#dropdown"
                              className="bg-blue"
                              onClick={(ev) => {
                                ev.preventDefault();
                                // changeTheme(chat.id, "blue");
                              }}
                              style={{ height: "25px" }}
                            ></DropdownItem>
                          </li>
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#dropdown"
                              onClick={(ev) => {
                                ev.preventDefault();
                                // changeTheme(chat.id, "purple");
                              }}
                              className="bg-purple"
                              style={{ height: "25px" }}
                            ></DropdownItem>
                          </li>
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#dropdown"
                              onClick={(ev) => {
                                ev.preventDefault();
                                // changeTheme(chat.id, "warning");
                              }}
                              className="bg-warning"
                              style={{ height: "25px" }}
                            ></DropdownItem>
                          </li>
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#dropdown"
                              onClick={(ev) => {
                                ev.preventDefault();
                                // changeTheme(chat.id, "success");
                              }}
                              className="bg-success"
                              style={{ height: "25px" }}
                            ></DropdownItem>
                          </li>
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#dropdown"
                              onClick={(ev) => {
                                ev.preventDefault();
                                // changeTheme(chat.id, "danger");
                              }}
                              className="bg-danger"
                              style={{ height: "25px" }}
                            ></DropdownItem>
                          </li>
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#dropdown"
                              onClick={(ev) => {
                                ev.preventDefault();
                                // changeTheme(chat.id, "info");
                              }}
                              className="bg-info"
                              style={{ height: "25px" }}
                            ></DropdownItem>
                          </li>
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#dropdown"
                              onClick={(ev) => {
                                ev.preventDefault();
                                // changeTheme(chat.id, "pink");
                              }}
                              className="bg-pink"
                              style={{ height: "25px" }}
                            ></DropdownItem>
                          </li>
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#dropdown"
                              onClick={(ev) => {
                                ev.preventDefault();
                                // changeTheme(chat.id, "orange");
                              }}
                              className="bg-orange"
                              style={{ height: "25px" }}
                            ></DropdownItem>
                          </li>
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#dropdown"
                              onClick={(ev) => {
                                ev.preventDefault();
                                // changeTheme(chat.id, "secondary");
                              }}
                              className="bg-secondary"
                              style={{ height: "25px" }}
                            ></DropdownItem>
                          </li>
                        </ul>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* .chat-profile-group */}
          {isGroup && (
            <div className="chat-profile-group">
              <a
                href="#member"
                onClick={(ev) => {
                  ev.preventDefault();
                  setMemberState(!memberState);
                }}
                className="chat-profile-head"
              >
                <h6 className="title overline-title">{`Members (${channel.memberCount})`}</h6>
                <span className="indicator-icon">
                  <Icon name={`chevron-${memberState ? "up" : "down"}`}></Icon>
                </span>
              </a>
              <div
                className={`chat-profile-body collapse ${
                  memberState ? "" : "show"
                }`}
                id="chat-photos"
              >
                <div className="chat-profile-body-inner">
                  <ul className="chat-members">
                    {/* <li>
                    <UncontrolledDropdown>
                      <DropdownToggle
                        tag="a"
                        color="transparent"
                        className="chat-members-link add-opt"
                        href="#dropdown"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                      >
                        <Icon
                          name="plus"
                          className="icon-circle bg-light"
                        ></Icon>
                        <span className="sub-text">Add Member</span>
                      </DropdownToggle>
                      <DropdownMenu>
                        <ul className="link-list-plain">
                          {chat.participants?.map((participant, key) => {
                            let user = chatUsers[participant];
                            let userName = `${user?.first_name ?? ""} ${user?.last_name ?? ""}`;
                            return (
                              <li key={key}>
                                <DropdownItem
                                  tag="a"
                                  href="#dropdown"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    addUserToChat(chat.id, user);
                                  }}
                                >
                                  <UserAvatar
                                    text={findUpper(userName)}
                                    theme={user?.theme}
                                    className="xs"
                                    image={null}
                                  />
                                  <div className="user-name">{userName}</div>
                                </DropdownItem>
                              </li>
                            );
                          })}
                        </ul>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </li> */}
                    <li>
                      <div
                        className="chat-members-link add-opt"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={(ev) => {
                          ev.preventDefault();
                          setIsInviteMemberModalOpen(true);
                        }}
                      >
                        <Icon
                          name="plus"
                          className="icon-circle bg-light"
                        ></Icon>
                        <span className="sub-text">Add Member</span>
                      </div>
                    </li>

                    <li>
                      {channel.members?.map((member, idx) => {
                        return (
                          <div className="user-card" key={idx}>
                            <a
                              href="#card"
                              onClick={(ev) => ev.preventDefault()}
                            >
                              <UserAvatar
                                size="sm"
                                // theme={user?.theme}
                                // image={user?.image}
                                text={findUpper(member?.nickname)}
                              ></UserAvatar>
                              <div className="user-name">{`${member?.nickname}${
                                member?.userId === userID ? " (You)" : ""
                              }`}</div>
                              <div className="user-role">
                                {channel.creator?.userId === member?.userId
                                  ? "Group creator"
                                  : capitalize(member.role)}
                              </div>
                            </a>
                            {member?.userId !== userID && (
                              <div className="user-actions">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    tag="a"
                                    className="btn btn-icon btn-sm btn-trigger dropdown-toggle"
                                  >
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu right>
                                    <ul className="link-list-opt no-bdr">
                                      <li>
                                        <DropdownItem
                                          tag="a"
                                          onClick={(e) => {
                                            e.preventDefault();
                                          }}
                                          href="#view-profile"
                                        >
                                          <Icon name="eye-fill"></Icon>
                                          View profile
                                        </DropdownItem>
                                      </li>
                                      {isOperator &&
                                        member?.role !== "operator" && (
                                          <li>
                                            <DropdownItem
                                              tag="a"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                onMakeOperatorClicked(member);
                                              }}
                                              href="#make-operator"
                                            >
                                              <Icon name="account-setting-fill"></Icon>
                                              Make operator
                                            </DropdownItem>
                                          </li>
                                        )}
                                      {isOperator &&
                                        member?.role === "operator" &&
                                        channel.creator?.userId !==
                                          member?.userId && (
                                          <li>
                                            <DropdownItem
                                              tag="a"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                onUnregisterOperatorClicked(
                                                  member
                                                );
                                              }}
                                              href="#unregister-operator"
                                            >
                                              <Icon name="user-remove-fill"></Icon>
                                              Remove as operator
                                            </DropdownItem>
                                          </li>
                                        )}
                                      <li>
                                        <DropdownItem
                                          tag="a"
                                          onClick={(e) => {
                                            e.preventDefault();
                                          }}
                                          href="#message"
                                        >
                                          <Icon name="chat-circle-fill"></Icon>
                                          Message
                                        </DropdownItem>
                                      </li>
                                      <li>
                                        <DropdownItem
                                          tag="a"
                                          onClick={(e) => {
                                            e.preventDefault();
                                          }}
                                          href="#block"
                                          className="text-danger"
                                        >
                                          <Icon name="na"></Icon>
                                          Block
                                        </DropdownItem>
                                      </li>
                                      {isOperator &&
                                        channel.creator?.userId !==
                                          member?.userId && (
                                          <li>
                                            <DropdownItem
                                              tag="a"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                onBanUserClicked(member);
                                              }}
                                              href="#remove-from-group"
                                              className="text-danger"
                                            >
                                              <Icon name="user-cross-fill"></Icon>
                                              Remove from group
                                            </DropdownItem>
                                          </li>
                                        )}
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
          <div className="chat-profile-group">
            <a
              href="#settings"
              onClick={(ev) => {
                ev.preventDefault();
                setSettingState(!settingState);
              }}
              className="chat-profile-head"
            >
              <h6 className="title overline-title">Settings</h6>
              <span className="indicator-icon">
                <Icon name={`chevron-${settingState ? "up" : "down"}`}></Icon>
              </span>
            </a>
            <div
              className={`chat-profile-body collapse ${
                settingState ? "" : "show"
              }`}
              id="chat-settings"
            >
              <div className="chat-profile-body-inner">
                <ul className="chat-profile-settings">
                  <li>
                    <div className="custom-control custom-control-sm custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customSwitch2"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customSwitch2"
                      >
                        Notifications
                      </label>
                    </div>
                  </li>
                  <li>
                    <a
                      className="chat-option-link"
                      href="#ignore"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <Icon className="icon icon-circle bg-light ni ni-bell-off-fill"></Icon>
                      <div>
                        <span className="lead-text">Ignore Messages</span>
                        <span className="sub-text">
                          You won’t be notified when message you.
                        </span>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a
                      className="chat-option-link"
                      href="#feedback"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <Icon className="icon icon-circle bg-light ni ni-alert-fill"></Icon>
                      <div>
                        <span className="lead-text">Something Wrong</span>
                        <span className="sub-text">
                          Give feedback and report conversion.
                        </span>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* .chat-profile-group */}
          <div className="chat-profile-group">
            <a
              href="#shared"
              onClick={(ev) => {
                ev.preventDefault();
                setPhotoState(!photoState);
              }}
              className="chat-profile-head"
            >
              <h6 className="title overline-title">Shared Photos</h6>
              <span className="indicator-icon">
                <Icon name={`chevron-${photoState ? "up" : "down"}`}></Icon>
              </span>
            </a>
            <div
              className={`chat-profile-body collapse ${
                photoState ? "" : "show"
              }`}
              id="chat-photos"
            >
              <div className="chat-profile-body-inner">
                <ul className="chat-profile-media">
                  <li>
                    <a
                      href="#images"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      {/* <img src={SlideA} alt="" /> */}
                    </a>
                  </li>
                  <li>
                    <a
                      href="#images"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      {/* <img src={SlideB} alt="" /> */}
                    </a>
                  </li>
                  <li>
                    <a
                      href="#images"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      {/* <img src={SlideC} alt="" /> */}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* .chat-profile-group */}
        </div>
      </SimpleBar>

      <ModalComponent
        isOpen={isInviteMemberModalOpen}
        toggle={onInviteMemberModalCancel}
        title={"Invite members"}
        backdrop={"static"}
        onModalCancel={onInviteMemberModalCancel}
      >
        <form onSubmit={handleSubmit(onFormInviteMemberSubmit)}>
          <div className="nk-chat-aside-search px-0 mb-3">
            <div className="form-group">
              <div className="form-control-wrap">
                <div className="form-icon form-icon-left">
                  <Icon name="search"></Icon>
                </div>
                <Input
                  {...register("new_chat_search")}
                  type="text"
                  className="form-round"
                  id={`new-chat-search-input`}
                  placeholder="Search by email/name (min 3 characters)"
                  onChange={(e) => onNewChatSearchChange(e)}
                />
              </div>
            </div>
          </div>
          {watchNewChatSelectedUsers.length > 0 && (
            <div className="mb-2">
              {watchNewChatSelectedUsers.map((item, index) => {
                return (
                  <div
                    className={classNames({
                      "tag-item": true,
                      "ml-2": index !== 0,
                    })}
                    style={{
                      // backgroundColor: "rgb(218, 216, 216)",
                      backgroundColor: "#e4d8ff",
                      display: "inline-flex",
                      padding: ".25rem .35rem .25rem .7rem",
                      borderRadius: "20px",
                      alignItems: "center",
                    }}
                    key={`tag-selected-user-${item?.sendbird_user_id}`}
                  >
                    <span className="text">{item?.sendbird_nickname}</span>
                    <Icon
                      name="cross-circle-fill"
                      style={{
                        fontSize: "20px",
                        position: "relative",
                        top: "-2px",
                        color: "#854fff",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        onNewChatRemoveSelectedItem(item);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          )}
          {isSearchFetching ? (
            <Row className="justify-content-center">
              <Spinner size="lg" color="primary" />
            </Row>
          ) : (
            <BuildNewChatUserList
              newChatuserList={newChatUserList}
              onNewChatUserSelect={onNewChatUserSelect}
              watchNewChatSelectedUsers={watchNewChatSelectedUsers}
            />
          )}

          <Row className="mx-0 mt-3 justify-content-end">
            <Button
              color="primary"
              type="submit"
              // disabled={isAddChatContactLoading}
            >
              {/* {isAddChatContactLoading && <Spinner type="grow" size="sm" />} */}
              <span>Invite member(s)</span>
            </Button>
          </Row>
        </form>
      </ModalComponent>

      {/* <ModalComponent
          isOpen={isConfirmModalOpen}
          toggle={() => setIsConfirmModalOpen(false)}
          backdrop={"static"}
          title={`Are you sure you want to ${confirmText}?`}
          onModalCancel={() => {
            setIsConfirmModalOpen(false);
          }}
          disableCloseBtn={true}
          size={"xs"}
        >
          <Row className="gy-3">
            <Col size="12">
              <Button
                color="primary"
                type="submit"
                onClick={() => {
                  
                }}
              >
                <span>Yes</span>
              </Button>
              <Button
                className="ml-3"
                color="light"
                onClick={() => {
                  setIsConfirmModalOpen(false);
                }}
              >
                <span>No</span>
              </Button>
            </Col>
          </Row>
      </ModalComponent> */}
    </div>
  );
};
export default ChatSideBar;

const BuildNewChatUserList = ({
  watchNewChatSelectedUsers,
  newChatuserList,
  onNewChatUserSelect,
}) => {
  return (
    <div>
      {newChatuserList?.length > 0 && <div className="mb-3">Suggested</div>}
      <ul>
        <Row className="g-3">
          {newChatuserList?.map((item, index) => {
            return (
              <Col md="6" key={`user-${index}`}>
                <li>
                  <div
                    className="custom-control custom-control-sm custom-checkbox custom-control-pro"
                    style={{
                      display: "block",
                    }}
                  >
                    <div
                      style={{
                        display: "block",
                        padding: "0.6875rem 1.125rem",
                        lineHeight: "1.25rem",
                        borderRadius: "4px",
                        fontSize: "13px",
                        backgroundColor: "#fff",
                        border: "1px solid #e5e9f2",
                        cursor: "pointer",
                        transition:
                          "color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                      }}
                      onClick={() => {
                        onNewChatUserSelect(item);
                      }}
                    >
                      <span className="user-card justify-content-between">
                        <div className="d-flex align-items-center">
                          <span className="user-avatar">
                            <UserAvatar
                              text={findUpper(item?.sendbird_nickname)}
                              className="chat-media"
                            />
                          </span>
                          <span className="user-info">
                            <span className="lead-text">
                              {item?.sendbird_nickname}
                            </span>
                            {/* <span className="sub-text">Senior Developer</span> */}
                          </span>
                        </div>
                        {watchNewChatSelectedUsers?.find(
                          (user) =>
                            user.sendbird_user_id === item.sendbird_user_id
                        ) ? (
                          <Icon
                            name="check"
                            style={{
                              fontSize: "20px",
                            }}
                          ></Icon>
                        ) : (
                          <Icon
                            name="plus"
                            style={{
                              fontSize: "20px",
                            }}
                          ></Icon>
                        )}
                      </span>
                    </div>
                  </div>
                </li>
              </Col>
            );
            // return item.contacts?.map((contact, index2) => (

            // ));
          })}
        </Row>
      </ul>
      {newChatuserList?.length === 0 && (
        <div className="d-flex justify-content-center my-3">No user found</div>
      )}
    </div>
  );
};
