import {
  Col,
  Icon,
  Row,
  TooltipComponent,
  UserAvatar,
} from "../../components/Components";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import React from "react";
import { capitalize } from "../../utils/utils";

export const candidateColumns = [
  {
    name: "User",
    selector: (row) => `${row.user.first_name} ${row.user.last_name}`,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px" },
    minWidth: "250px",
    sortable: true,
    cell: (row) => (
      <Link
        to={row.user.id}
        rel="noopener noreferrer"
        // className="nk-menu-link"
        state={{ test: "asdasd" }}
      >
        <div
          className="user-card mt-2 mb-2"
          data-tag="allowRowEvents"
          data-action="details"
        >
          <UserAvatar
            theme={row.user.avatarBg}
            icon="user-alt"
            // text={findUpper(row.name)}
            image={row.user.image}
          ></UserAvatar>
          <div className="user-info">
            <span className="fs-11px">id: {row.user.id}</span>
            <span className="tb-lead">{`${row.user.first_name} ${row.user.last_name}`}</span>
            <span>{row.user?.preferred_name}</span>
          </div>
        </div>
      </Link>
    ),
  },
  {
    name: "Services with Inclus",
    // selector: (row) => row.role_name,
    //FIXME:
    cell: (row) => (
      <Row>
        <Col size="10" className="pr-0">
          <div>
            {["Training", "Enrichment", "Employment", "Job Support"].map(
              (item) => (
                <p key={row.user.id + "_activities_" + item} className="my-1">
                  {item}
                </p>
              )
            )}
          </div>
        </Col>
        <Col size="2" className="pr-1 pl-2 align-self-center">
          <TooltipComponent
            icon="info"
            direction="top"
            id={"services_last_updated_" + row.user.id}
            text={`Updated by asdasd at Dec 18, 2019 01:02 am`}
            iconClass={"fs-13px"}
          />
        </Col>
      </Row>
    ),
    minWidth: "200px",
    hide: "md",
  },
  {
    name: "Assessment recommendation",
    minWidth: "200px",
    hide: "md",
    cell: (row) => (
      <Row>
        <Col size="9" className="pr-0">
          {row.assessment?.assessment_recommendation?.map((item, idx) => (
            <p
              key={row.user.id + "_assessment_recommendation_" + idx}
              className="m-0"
            >
              {item !== "Not suitable" ? `Suitable: ${item}` : item}
            </p>
          ))}
        </Col>
        {row.assessment ? (
          <Col size="3" className="pr-1 pl-2 align-self-center">
            <TooltipComponent
              icon="info"
              direction="top"
              id={"assessment_last_updated_" + row.user.id}
              text={`Updated by asdasd at ${moment(
                row?.assessment?.last_updated_time
              ).format("MMM DD, YYYY h:mm a")}`}
              iconClass={"fs-13px"}
            />
          </Col>
        ) : null}
      </Row>
    ),
  },
  {
    name: "Pipelines",
    // selector: (row) => row.role_name,
    //FIXME:
    cell: (row) => {
      return (
        <React.Fragment>
          {row?.pipelines?.map((item, idx) => {
            const filteredArray = item?.stages?.filter(
              (obj) => obj.status == "completed"
            );
            const lastFilteredItem = filteredArray.slice(-1)[0];

            return (
              <Row key={`candidates-${row.user.id}-pipelines-${idx}`}>
                <Col size="10" className="pr-0">
                  <span>
                    <strong>{`${capitalize(item?.type)}:`}</strong>{" "}
                    {lastFilteredItem?.name}
                  </span>
                </Col>
                <Col size="2" className="pr-1 pl-2 align-self-center">
                  <TooltipComponent
                    icon="info"
                    direction="top"
                    id={"pipelines_last_updated_" + row.id}
                    text={`Updated by asdasd at Dec 18, 2019 01:02 am`}
                    iconClass={"fs-13px"}
                  />
                </Col>
              </Row>
            );
          })}
        </React.Fragment>
      );
    },
    minWidth: "200px",
    hide: "md",
  },
  {
    name: "Status",
    // selector: (row) => row.role_name,
    //FIXME:
    cell: (row) => (
      <Row className="flex-nowrap">
        <Col size="10" className="pr-0">
          <span>Status</span>
        </Col>
        <Col size="2" className="pl-2 align-self-center">
          <TooltipComponent
            icon="info"
            direction="top"
            id={"status_last_updated_" + row.id}
            text={`Updated by asdasd at Dec 18, 2019 01:02 am`}
            iconClass={"fs-13px"}
          />
        </Col>
      </Row>
    ),
    minWidth: "120px",
    hide: "md",
  },
  {
    name: "Created",
    selector: (row) => row.user?.created_time,
    sortable: true,
    minWidth: "120px",
    cell: (row) => (
      <span>
        {moment(row.user?.created_time).format("DD-MM-YYYY, hh:mm:ss a")}
      </span>
    ),
    hide: "md",
  },
  {
    name: "Updated",
    selector: (row) => row.user?.last_updated_time,
    sortable: true,
    minWidth: "120px",
    cell: (row) => (
      <span>
        {moment(row.user?.last_updated_time).format("DD-MM-YYYY, hh:mm:ss a")}
      </span>
    ),
    hide: "md",
  },
  {
    name: (
      <UncontrolledDropdown>
        <DropdownToggle
          tag="a"
          className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
        >
          <Icon name="more-h"></Icon>
        </DropdownToggle>
        <DropdownMenu right>
          <ul className="link-list-opt no-bdr">
            {/* <li>
              <DropdownItem
                tag="a"
                href="#markasdone"
                onClick={(ev) => {
                  ev.preventDefault();
                  // selectorMarkAsDelivered();
                }}
              >
                <Icon name="truck"></Icon>
                <span>Mark As Delivered</span>
              </DropdownItem>
            </li> */}
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                  // selectorDeleteOrder();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Candidates</span>
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li className="nk-tb-action-hidden">
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-trigger btn-icon"
              id={"quick_view_" + row.id}
              icon="eye"
              direction="top"
              text="Quick View"
              data-tag="allowRowEvents"
              data-action="quick_views"
            />
          </li>
          <li className="nk-tb-action-hidden">
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-trigger btn-icon"
              id={"detail_" + row.id}
              icon="focus"
              direction="top"
              text="View Details"
              data-tag="allowRowEvents"
              data-action="details"
            />
          </li>
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#markasdone"
                      data-tag="allowRowEvents"
                      data-action="quick_view"
                    >
                      <Icon
                        name="eye"
                        data-tag="allowRowEvents"
                        data-action="quick_view"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="quick_view">
                        Quick View
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#markasdone"
                      data-tag="allowRowEvents"
                      data-action="details"
                    >
                      <Icon
                        name="focus"
                        data-tag="allowRowEvents"
                        data-action="details"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="details">
                        View Details
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  },
];

export const caregiverColumns = [
  {
    name: "User",
    selector: (row) => `${row.first_name} ${row.last_name}`,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px" },
    minWidth: "250px",
    sortable: true,
    cell: (row) => (
      <Link
        to={row.id}
        rel="noopener noreferrer"
        // className="nk-menu-link"
        state={{ test: "asdasd" }}
      >
        <div
          className="user-card mt-2 mb-2"
          data-tag="allowRowEvents"
          data-action="details"
        >
          <UserAvatar
            theme={row.avatarBg}
            icon="user-alt"
            // text={findUpper(row.name)}
            image={row.image}
          ></UserAvatar>
          <div className="user-info">
            <span className="fs-11px">id: {row.id}</span>
            <span className="tb-lead">{`${row.first_name} ${row.last_name}`}</span>
            <span>{row.preferred_name}</span>
          </div>
        </div>
      </Link>
    ),
  },
  {
    name: "Created",
    selector: (row) => row.created_time,
    sortable: true,
    minWidth: "120px",
    cell: (row) => (
      <span>{moment(row.created_time).format("D-MM-YYYY, h:mm:ss a")}</span>
    ),
    hide: "md",
  },
  {
    name: "Updated",
    selector: (row) => row.last_updated_time,
    sortable: true,
    minWidth: "120px",
    cell: (row) => (
      <span>
        {moment(row.last_updated_time).format("D-MM-YYYY, h:mm:ss a")}
      </span>
    ),
    hide: "md",
  },
  {
    name: (
      <UncontrolledDropdown>
        <DropdownToggle
          tag="a"
          className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
        >
          <Icon name="more-h"></Icon>
        </DropdownToggle>
        <DropdownMenu right>
          <ul className="link-list-opt no-bdr">
            {/* <li>
              <DropdownItem
                tag="a"
                href="#markasdone"
                onClick={(ev) => {
                  ev.preventDefault();
                  // selectorMarkAsDelivered();
                }}
              >
                <Icon name="truck"></Icon>
                <span>Mark As Delivered</span>
              </DropdownItem>
            </li> */}
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                  // selectorDeleteOrder();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Candidates</span>
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li className="nk-tb-action-hidden">
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-trigger btn-icon"
              id={"quick_view_" + row.id}
              icon="eye"
              direction="top"
              text="Quick View"
              data-tag="allowRowEvents"
              data-action="quick_views"
            />
          </li>
          <li className="nk-tb-action-hidden">
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-trigger btn-icon"
              id={"detail_" + row.id}
              icon="focus"
              direction="top"
              text="View Details"
              data-tag="allowRowEvents"
              data-action="details"
            />
          </li>
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#markasdone"
                      data-tag="allowRowEvents"
                      data-action="quick_view"
                    >
                      <Icon
                        name="eye"
                        data-tag="allowRowEvents"
                        data-action="quick_view"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="quick_view">
                        Quick View
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#markasdone"
                      data-tag="allowRowEvents"
                      data-action="details"
                    >
                      <Icon
                        name="focus"
                        data-tag="allowRowEvents"
                        data-action="details"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="details">
                        View Details
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  },
];

export const userColumns = [
  {
    name: "User",
    selector: (row) => `${row.first_name} ${row.last_name}`,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px" },
    minWidth: "250px",
    sortable: true,
    cell: (row) => (
      <div
        className="user-card mt-2 mb-2"
        data-tag="allowRowEvents"
        data-action="details"
      >
        <UserAvatar
          theme={row.avatarBg}
          icon="user-alt"
          // text={findUpper(row.name)}
          image={row.image}
        ></UserAvatar>
        <div className="user-info">
          <span className="fs-11px">id: {row.id}</span>
          <span className="tb-lead">{`${row.first_name} ${row.last_name}`}</span>
          <span>{row?.preferred_name}</span>
        </div>
      </div>
    ),
  },
  {
    name: "Created",
    selector: (row) => row?.created_time,
    sortable: true,
    minWidth: "120px",
    cell: (row) => (
      <span>{moment(row?.created_time).format("DD-MM-YYYY, hh:mm:ss a")}</span>
    ),
    hide: "md",
  },
  {
    name: "Updated",
    selector: (row) => row?.last_updated_time,
    sortable: true,
    minWidth: "120px",
    cell: (row) => (
      <span>
        {moment(row?.last_updated_time).format("DD-MM-YYYY, hh:mm:ss a")}
      </span>
    ),
    hide: "md",
  },
  {
    name: (
      <UncontrolledDropdown>
        <DropdownToggle
          tag="a"
          className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
        >
          <Icon name="more-h"></Icon>
        </DropdownToggle>
        <DropdownMenu right>
          <ul className="link-list-opt no-bdr">
            {/* <li>
              <DropdownItem
                tag="a"
                href="#markasdone"
                onClick={(ev) => {
                  ev.preventDefault();
                  // selectorMarkAsDelivered();
                }}
              >
                <Icon name="truck"></Icon>
                <span>Mark As Delivered</span>
              </DropdownItem>
            </li> */}
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                  // selectorDeleteOrder();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Candidates</span>
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li className="nk-tb-action-hidden">
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-trigger btn-icon"
              id={"quick_view_" + row.id}
              icon="eye"
              direction="top"
              text="Quick View"
              data-tag="allowRowEvents"
              data-action="quick_views"
            />
          </li>
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#markasdone"
                      data-tag="allowRowEvents"
                      data-action="quick_view"
                    >
                      <Icon
                        name="eye"
                        data-tag="allowRowEvents"
                        data-action="quick_view"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="quick_view">
                        Quick View
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  },
];
