import { getBoundingClientObj } from "../../utils/utils";
import { createPortal } from "react-dom";

const MenuPortal = ({ controlElement, isOpen, portalId, ...props }) => {
  if (!isOpen || !controlElement || !portalId) {
    return null;
  }

  const rect = getBoundingClientObj(controlElement);
  const scrollDistance = window.pageYOffset;
  const offset = rect["bottom"] + scrollDistance;

  const menuWrapper = (
    <div
      id={portalId}
      className="date-picker-portal-menu"
      style={{
        position: "absolute",
        left: rect.left,
        top: offset,
        width: rect.width,
        zIndex: 1,
      }}
    >
      {props.children}
    </div>
  );

  return createPortal(menuWrapper, document.body);
};

export default MenuPortal;
