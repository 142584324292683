import { api } from "../api";

export const formManagementApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCareersSubmissions: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/careers-submissions",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          submissions: response.data,
          total: response.total,
        };
      },
      providesTags: ["Careers-Submissions"],
    }),
    getContactUsSubmissions: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/contact-us-submissions",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          submissions: response.data,
          total: response.total,
        };
      },
      providesTags: ["Contact-Us-Submissions"],
    }),
    getIntroductionForms: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/introduction-forms",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          forms: response.data,
          total: response.total,
        };
      },
      providesTags: ["Introduction-Forms"],
    }),
    getAssessmentForms: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/assessment-forms",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          forms: response.data,
          total: response.total,
        };
      },
      providesTags: ["Assessment-Forms"],
    }),
    createAssessmentForm: builder.mutation({
      query: (data) => ({
        url: "admin/v1/assessment-form",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Assessment-Forms"],
    }),
  }),
});

export const {
  useGetIntroductionFormsQuery,
  useGetAssessmentFormsQuery,
  useCreateAssessmentFormMutation,
  useGetCareersSubmissionsQuery,
  useGetContactUsSubmissionsQuery,
} = formManagementApi;
