import { api } from "../api";
import { onPresenceStatusChange, updateChatState } from "../../features/chat/chatSlice";

export const chat = api.injectEndpoints({
  endpoints: (builder) => ({
    getToken: builder.query({
      query: (params) => {
        return {
          url: "chat/v1/token/account",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          token: response.data[0],
        };
      },
    }),
    getChatData: builder.query({
      query: (params) => {
        return {
          url: "chat/v1/data",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          ...response.data[0],
        };
      },
      async onQueryStarted(token, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const response = await queryFulfilled;

          // let conversations = {};
          // let users = {};
          // response.data.channels?.forEach((channel) => {
          //   channel.members?.forEach((member) => {
          //     if (!users[member.user_id]) {
          //       users[member.user_id] = member;
          //     }
          //   });

          //   conversations[channel.channel_url] = {
          //     id: channel.id,
          //     channel_url: channel.channel_url,
          //     name: channel.name,
          //     cover_url: channel.cover_url,
          //     last_message: channel.last_message,
          //     unread_message_count: channel.unread_message_count,
          //     unread_mention_count: channel.unread_mention_count,
          //     member_count: channel.member_count,
          //     joined_member_count: channel.joined_member_count,
          //     members: channel.members,
          //   };
          // });

          // // Arrange conversations
          
          // // response.data.conversations?.forEach((conversation) => {
          // //   let participants = [response.data.userID];
          // //   conversation.users?.forEach((user) => {
          // //     if (!participants.find(id => id === user.agora_chat_user_id)) {
          // //       participants.push(user.agora_chat_user_id);
          // //     }
          // //     if (!users[user.agora_chat_user_id]) {
          // //       users[user.agora_chat_user_id] = user;
          // //     }
          // //   });

          // //   conversations[conversation.id] = {
          // //     id: conversation.id,
          // //     groupName: conversation.groupName,
          // //     participants: participants,
          // //     unreadNum: conversation.unread_num,
          // //     lastMessage: conversation.lastMessage,
          // //     convo: [],
          // //   };
          // // });

          // // // onSuccess
          // dispatch(
          //   updateChatState({
          //     // contactIds: response.data.contactIds ?? [],
          //     // contacts: response.data.contacts ?? [],
          //     userID: response.data.userID,
          //     chatToken: response.data.token,
          //     // presenceStatus: response.data.statusDetails ?? [],
          //     conversations: conversations,
          //     users: users,
          //   })
          // );

          // // // Arrange presence status
          // // if (response.data.statusDetails) {
          // //   dispatch(onPresenceStatusChange({ data: response.data.statusDetails }));
          // // }
          
        } catch (err) { }
      },
      //   providesTags: ["ChatInfo"],
    }),
    createGroupChannel: builder.mutation({
      query: (params) => {
        return {
          url: "chat/v1/group-channels",
          method: "POST",
          body: params,
        };
      },
      transformResponse: (response) => {
        return {
          ...response.data[0],
        };
      },
    }),
    addContact: builder.mutation({
      query: (params) => {
        return {
          url: "chat/v1/contact",
          method: "POST",
          body: params,
        };
      },
      transformResponse: (response) => {
        return {
          ...response.data[0],
        };
      },
    }),
  }),
});

export const {
  useGetTokenQuery,
  useLazyGetTokenQuery,
  useGetChatDataQuery,
  useLazyGetChatDataQuery,
  useAddContactMutation,
  useCreateGroupChannelMutation,
} = chat;
