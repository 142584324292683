import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import Icon from "../icon/Icon";

const TooltipComponent = ({
  iconClass,
  icon,
  id,
  direction,
  text,
  containerClassName,
  ...props
}) => {
  const { "data-tag": dataTag, "data-action": dataAction } = props;

  return (
    <React.Fragment>
      {props.tag ? (
        <props.tag
          className={containerClassName}
          id={id}
          data-tag={dataTag}
          data-action={dataAction}
        >
          {" "}
          <Icon
            className={`${iconClass ? iconClass : ""}`}
            name={icon}
            data-tag={dataTag}
            data-action={dataAction}
          ></Icon>
        </props.tag>
      ) : (
        <Icon
          className={`${iconClass ? iconClass : ""}`}
          name={icon}
          id={id}
          data-tag={dataTag}
          data-action={dataAction}
        ></Icon>
      )}
      <UncontrolledTooltip autohide={false} placement={direction} target={id}>
        {text}
      </UncontrolledTooltip>
    </React.Fragment>
  );
};
export default TooltipComponent;
