import React, { useState, createContext, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import SendbirdChat, { ConnectionHandler, ChannelType } from "@sendbird/chat";
import {
  OpenChannelModule,
  OpenChannelCreateParams,
} from "@sendbird/chat/openChannel";
import {
  GroupChannelModule,
  MessageFilter,
  MessageCollectionInitPolicy,
  GroupChannelListOrder,
  GroupChannelFilter,
  GroupChannelHandler,
} from "@sendbird/chat/groupChannel";
import { ReplyType } from "@sendbird/chat/message";
import { useCreateGroupChannelMutation } from "../../redux/api/chat/chatApi";
import { updateChatState } from "../../redux/features/chat/chatSlice";
let sb;

export const ChatContext = createContext();
export const ChatContextProvider = ({ ...props }) => {
  const dispatch = useDispatch();
  //   const appKey = process.env.REACT_APP_CHAT_APP_KEY
  const [chat, setChat] = useState([]);

  const [state, updateState] = useState({
    applicationUsers: [],
    groupChannelMembers: [],
    currentlyJoinedChannel: null,
    isOpenThread: false,
    threadParentsMessage: {},
    threadMessages: [],
    messages: [],
    pinnedMessages: [],
    channels: [],
    messageInputValue: "",
    userID: "",
    userNickname: "",
    channelNameUpdateValue: "",
    settingUpUser: true,
    file: null,
    messageToUpdate: null,
    messageCollection: null,
    loading: false,
    error: false,
    isLoadingMessages: false,
    contacts: [],
  });
  const [
    createGroupChannel,
    { data: createGroupChannelData, isSuccess, isError },
  ] = useCreateGroupChannelMutation();

  // const channelsRef = useRef();
  // useEffect(() => {
  //   channelsRef.current = channels;
  // }, [channels]);

  const stateRef = useRef();
  stateRef.current = state;

  const channelRef = useRef();

  // TODO: Add a timer to refresh the channels every 30 seconds
  // To check status connection of each member in channels (online/offline)
  // useEffect(() => {
  //   const interval = setInterval(async () => {
  //     stateRef.current.channels.forEach((channel) => {
  //       channel.refresh();
  //     });
  //   }, 30000);

  //   return () => clearInterval(interval);
  // }, []);

  const connectionHandler = new ConnectionHandler({
    onConnected: (userId) => {},
    onDisconnected: () => {},
    onReconnectStarted: () => {},
    onReconnectSucceeded: () => {},
    onReconnectFailed: () => {},
  });

  const messageCollectionEventHandlers = {
    onMessagesAdded: (context, channel, messages) => {
      console.log("onMessagesAdded", messages);

      const updatedMessages = [...stateRef.current.messages, ...messages];
      updateState({ ...stateRef.current, messages: updatedMessages });
    },
    onMessagesUpdated: (context, channel, messages) => {
      console.log("onMessagesUpdated", messages);

      const updatedMessages = [...stateRef.current.messages];
      for (let i in messages) {
        const incomingMessage = messages[i];

        // For newly added messages, the messageId will be 0.
        const indexOfExisting = stateRef.current.messages.findIndex(
          (message) => {
            return (
              (incomingMessage.reqId &&
                message.reqId &&
                incomingMessage.reqId === message.reqId) ||
              incomingMessage.messageId === message.messageId
            );
          }
        );

        if (indexOfExisting !== -1) {
          updatedMessages[indexOfExisting] = incomingMessage;
        } else if (!incomingMessage.reqId) {
          updatedMessages.push(incomingMessage);
        }
      }
      updateState({ ...stateRef.current, messages: updatedMessages });
    },
    onMessagesDeleted: (context, channel, messageIds) => {
      console.log("onMessagesDeleted", messageIds);
      const updateMessages = stateRef.current.messages.filter((message) => {
        return !messageIds.includes(message.messageId);
      });
      updateState({ ...stateRef.current, messages: updateMessages });
    },
    onChannelUpdated: (context, channel) => {},
    onChannelDeleted: (context, channelUrl) => {},
    onHugeGapDetected: () => {},
  };

  const groupChannelCollectionHandlers = {
    onChannelsAdded: (context, newChannels) => {
      console.log("onChannelsAdded", newChannels);
      const updatedChannels = [...newChannels, ...stateRef.current.channels];
      updateState({ ...stateRef.current, channels: updatedChannels });
    },
    onChannelsDeleted: (context, channelUrls) => {
      console.log("onChannelsDeleted", channelUrls);

      const updatedChannels = stateRef.current.channels.filter(
        (channel) => channelUrls.indexOf(channel.url) === -1
      );

      updateState({
        ...stateRef.current,
        channels: updatedChannels,
        currentlyJoinedChannel: null,
        messages: [],
      });
    },
    onChannelsUpdated: (context, channels) => {
      console.log("onChannelsUpdated", channels);

      let updatedChannels = [...stateRef.current.channels];
      let currentlyJoinedChannel = stateRef.current.currentlyJoinedChannel;
      channels.forEach((channel) => {
        const index = updatedChannels.findIndex(
          (currentChannel) => currentChannel.url === channel.url
        );
        if (index !== -1) {
          updatedChannels[index] = channel;
          if (currentlyJoinedChannel && currentlyJoinedChannel.url === channel.url) {
            currentlyJoinedChannel = channel;
          }
          // if (!channel.lastMessage) {
          //   updatedChannels.splice(index, 1);
          // }
        }
      });
      updateState({ ...stateRef.current, channels: updatedChannels, currentlyJoinedChannel: currentlyJoinedChannel });
    },
  };

  const groupChannelHandlers = new GroupChannelHandler({
    onUnreadMemberStatusUpdated: (channel) => {
      console.log("onUnreadMemberStatusUpdated", channel);

      // const updatedChannels = [...stateRef.current.channels];
      // updateState({ ...stateRef.current, channels: updatedChannels });
    },
    onPinnedMessageUpdated: (channel) => {
      console.log("onPinnedMessageUpdated", channel);

      let updatedPinnedMessages = [...stateRef.current.pinnedMessages];
      if (stateRef.current.currentlyJoinedChannel?.url === channel.url) {
        updatedPinnedMessages = updatedPinnedMessages.filter((message) => {
          return channel.pinnedMessageIds.includes(message.messageId);
        });
      }

      updateState({
        ...stateRef.current,
        pinnedMessages: updatedPinnedMessages,
      });
    },
  });

  const createOneToOneChat = async ({ ids, user, ...props }) => {
    try {
      const params = {
        user_ids: [state.userID, ...ids],
        name: user.sendbird_nickname,
        inviter_id: state.userID,
        is_distinct: true,
        is_public: false,
      };
      const response = await createGroupChannel(params).unwrap();
      // updateState({ ...state, currentlyJoinedChannel: response, loading: false });
      handleJoinChannel({ channelURL: response.channel_url });
    } catch (err) {
      console.log(err);
    }
  };

  const createChatGroup = async ({ groupname, users, ...props }) => {
    try {
      let ids = users.map((user) => user.sendbird_user_id);
      const params = {
        user_ids: [state.userID, ...ids],
        name: groupname,
        inviter_id: state.userID,
        is_distinct: false,
        is_public: false,
        operator_ids: [state.userID],
      };
      const response = await createGroupChannel(params).unwrap();
      handleJoinChannel({ channelURL: response.channel_url });
    } catch (err) {
      console.log(err);
    }
  };

  const handleJoinChannel = async ({ channelURL }) => {
    try {
      if (state.messageCollection && state.messageCollection.dispose) {
        state.messageCollection?.dispose();
      }

      if (state.currentlyJoinedChannel?.url === channelURL) {
        return null;
      }

      const { channels } = stateRef.current;
      var channel = channels.find((channel) => channel.url === channelURL);
      if (!channel) {
        channel = await sb.groupChannel.getChannel(channelURL);
      }

      updateState({
        ...stateRef.current,
        currentlyJoinedChannel: channel,
        messages: [],
        isLoadingMessages: true,
        pinnedMessages: [],
      });

      const onCacheResult = (err, messages) => {
        console.log("onCacheResult", messages);

        // updateState({
        //   ...stateRef.current,
        //   currentlyJoinedChannel: channel,
        //   messages: messages.reverse(),
        //   loading: false,
        // });
      };

      const onApiResult = (err, messages) => {
        console.log("onApiResult", messages);

        updateState({
          ...stateRef.current,
          currentlyJoinedChannel: channel,
          messages: messages.reverse(),
          isLoadingMessages: false,
        });
      };
      const collection = loadMessages(
        channel,
        messageCollectionEventHandlers,
        onCacheResult,
        onApiResult
      );

      updateState({
        ...stateRef.current,
        messageCollection: collection,
      });

      channel.markAsRead();
    } catch (err) {
      console.log(err);
    }
  };

  const setupUser = async ({ appID, userID, token }) => {
    // const { userNameInputValue, userIdInputValue } = state;
    try {
      const sendbirdChat = SendbirdChat.init({
        appId: appID,
        localCacheEnabled: true,
        modules: [new GroupChannelModule()],
      });
      sendbirdChat.addConnectionHandler(uuidv4(), connectionHandler);

      await sendbirdChat.connect(userID, token);
      await sendbirdChat.setChannelInvitationPreference(true);

      // const userUpdateParams = {};
      // userUpdateParams.nickname = userNameInputValue;
      // userUpdateParams.userId = userIdInputValue;
      // await sendbirdChat.updateCurrentUserInfo(userUpdateParams);

      sb = sendbirdChat;

      sb.groupChannel.addGroupChannelHandler(uuidv4(), groupChannelHandlers);
      // updateState({ ...state, loading: true });
      const [channels, error] = await loadChannels(
        groupChannelCollectionHandlers
      );
      if (error) {
        // return onError(error);
        console.log(error);
      }

      // Mark all channels as delivered
      channels.forEach((channel) => {
        channel.markAsDelivered();
      });

      updateState({
        ...state,
        channels: channels,
        loading: false,
        settingUpUser: false,
        userID: userID,
      });
    } catch (err) {
      toast.error(err.message);
    }
    // dispatch(updateChatState(handleDataBeforeDispatch({ channels: channels })));
  };

  const disconnect = async () => {
    try {
      console.log(sb);

      await sb.disconnect();
    } catch (err) {
      console.log(err);
    }
  };

  const loadChannels = async (groupChannelCollectionHandlers) => {
    const groupChannelFilter = new GroupChannelFilter();
    groupChannelFilter.includeEmpty = true;

    const collection = sb.groupChannel.createGroupChannelCollection({
      filter: groupChannelFilter,
      order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
    });

    collection.setGroupChannelCollectionHandler(groupChannelCollectionHandlers);

    const channels = await collection.loadMore();
    return [channels, null];
  };

  const loadMessages = (
    channel,
    messageCollectionEventHandlers,
    onCacheResult,
    onApiResult
  ) => {
    const messageFilter = new MessageFilter({
      customTypesFilter:
        channel.memberCount > 2 ? ["", "SENDBIRD:AUTO_EVENT_MESSAGE"] : [],
      replyType: ReplyType.ALL,
    });

    const collection = channel.createMessageCollection({
      filter: messageFilter,
      startingPoint: Date.now(),
      prevResultLimit: 100,
      nextResultLimit: 100,
    });

    collection.setMessageCollectionHandler(messageCollectionEventHandlers);
    collection
      .initialize(MessageCollectionInitPolicy.CACHE_AND_REPLACE_BY_API)
      .onCacheResult(onCacheResult)
      .onApiResult(onApiResult);
    return collection;
  };

  const banUser = async ({ userID }) => {
    try {
      await state.currentlyJoinedChannel.banUserWithUserId(userID, 5);
    } catch (err) {
      console.log(err);
    }
  };

  const addOperators = async ({ ids }) => {
    try {
      await state.currentlyJoinedChannel.addOperators(ids);
    } catch (err) {
      console.log(err);
    }
  };

  const removeOperators = async ({ ids }) => {
    try {
      await state.currentlyJoinedChannel.removeOperators(ids);
    } catch (err) {
      console.log(err);
    }
  };

  const inviteMembers = async ({ ids }) => {
    try {
      await state.currentlyJoinedChannel.inviteWithUserIds(ids);
    } catch (err) {
      console.log(err);
    }
  };

  const leaveGroupChannel = async () => {
    try {
      await state.currentlyJoinedChannel.leave(true);
    } catch (err) {
      console.log(err);
    }
  };

  const updateChannel = async ({ name }) => {
    try {
      const params = {
        name: name,
      };
      await state.currentlyJoinedChannel.updateChannel(params);
    } catch (err) {
      console.log(err);
    }
  };

  const sendMessage = async ({ message, parentMessageId, files = [], ...props }) => {
    console.log(files)

    var resp;
    if (files.length === 1) {
      resp = stateRef.current.currentlyJoinedChannel.sendFileMessage({
        file: files[0],
        fileName: files[0].name,
        mimeType: files[0].type,
        fileSize: files[0].size,
        thumbnailSizes: [
          {maxWidth: 200, maxHeight: 200},
          {maxWidth: 400, maxHeight: 400},
        ],
      })
    }

    if (files.length > 1) {
      resp = stateRef.current.currentlyJoinedChannel.sendMultipleFilesMessage({
        fileInfoList: files.map((file) => {
          return {
            file: file,
            fileSize: file.size,
            fileName: file.name,
            mimeType: file.type,
            thumbnailSizes: [
              {maxWidth: 200, maxHeight: 200},
              {maxWidth: 400, maxHeight: 400},
            ],
          };
        }),
      })
    }

    if (files.length === 0) {
      resp = stateRef.current.currentlyJoinedChannel.sendUserMessage({
        message: message,
      });
    }

    resp
      .onSucceeded((message) => {
        // A text message with detailed configuration is successfully sent to the channel.
        // By using userMessage.messageId, userMessage.message, userMessage.customType, and so on,
        // you can access the result object from the Sendbird server to check your UserMessageCreateParams configuration.
        // The current user can receive messages from other users through the onMessageReceived() method of an event handler.
        // const messageId = userMessage.messageId;
        console.log("onSucceeded", message);
      })
      .onFailed((error, message) => {
        // The message failed to be sent to the channel.
        // Try to resend the message.
        console.log("onFailed", error);
      });
  };

  const updateMessage = async ({
    messageToUpdate,
    message,
    type,
    ...props
  }) => {
    try {
      if (type === "MESG") {
        const param = {
          message: message,
        };

        state.currentlyJoinedChannel.updateUserMessage(
          messageToUpdate.messageId,
          param
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  // const forwardMessage = async ({ messageToForward, targetChannelUrl, targetUser }) => {
  //   try {
  //     // let targetChannel = state.channels?.find((channel) => {
  //     //   return channel.url === targetChannelUrl;
  //     // });

  //     let idxTargetChannel = state.channels.findIndex((channel) => {
  //       return channel.url === targetChannelUrl;
  //     });
  //     let targetChannel = state.channels[idxTargetChannel];
  //     if (idxTargetChannel === -1) {
  //       // Create a new channel, if the target channel does not exist.
  //       const params = {
  //         user_ids: [state.userID, targetUser?.sendbird_user_id],
  //         name: targetUser?.sendbird_nickname,
  //         inviter_id: state.userID,
  //         is_distinct: true,
  //         is_public: false,
  //       };
  //       const response = await createGroupChannel(params).unwrap();
  //       targetChannel = await sb.groupChannel.getChannel(response.channel_url);
  //     }
      
  //     const resp = state.currentlyJoinedChannel.copyMessage(targetChannel, messageToForward);
  //     resp
  //       .onSucceeded((message) => {
  //         console.log("onSucceeded Forward", message);

  //         // let updatedChannels = [...stateRef.current.channels];
  //         // let updatedChannel = updatedChannels[idxTargetChannel];
  //         // updatedChannel.lastMessage = message;
          
  //         // updateChatState({ ...state, channels: updatedChannels });
  //       })
  //       .onFailed((error, message) => {

  //       });
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const resetHistoryGroupChannel = async ({ channel }) => {
    try {
      // await sb.groupChannel.resetMyHistory(channelURL);
      await channel.resetMyHistory();

      updateState({
        ...stateRef.current,
        messages: [],
        currentlyJoinedChannel: null,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const deleteMessage = async ({ messageID }) => {
    try {
      let messageToDelete = state.messages?.find(
        (message) => message.messageId === messageID
      );
      await state.currentlyJoinedChannel?.deleteMessage(messageToDelete);
    } catch (err) {
      console.log(err);
    }
  };

  const pinMessage = async (messageID) => {
    try {
      await state.currentlyJoinedChannel?.pinMessage(messageID);
    } catch (err) {
      console.log(err);
    }
  };

  const unpinMessage = async (messageID) => {
    try {
      await state.currentlyJoinedChannel?.unpinMessage(messageID);
    } catch (err) {
      console.log(err);
    }
  };

  const listPinnedMessages = async () => {
    try {
      const query = state.currentlyJoinedChannel?.createPinnedMessageListQuery({
        limit: 50,
        includeMetaArray: true,
        includeReactions: true,
        includeParentMessageInfo: true,
        includeThreadInfo: true,
        includePollDetails: true,
      });

      const resp = await query.next();
      const pinnedMessages = resp.map((item) => {
        return item.message;
      });
      updateState({ ...state, pinnedMessages: pinnedMessages.reverse() });
    } catch (err) {
      console.log(err);
    }
  };

  const openThread = async (parentsMessage) => {
    const { currentlyJoinedChannel } = state;
    const { params, threadedMessages } = await getParamsForThreading(
      parentsMessage,
      currentlyJoinedChannel
    );
    const message = await sb.message.getMessage(params);

    updateState({
      ...state,
      isOpenThread: true,
      threadParentsMessage: message,
      threadMessages: threadedMessages,
    });
  };

  return (
    <ChatContext.Provider
      value={{
        disconnect: disconnect,
        setupUser: setupUser,
        sendMessage: sendMessage,
        handleJoinChannel: handleJoinChannel,
        createOneToOneChat: createOneToOneChat,
        createChatGroup: createChatGroup,
        resetHistoryGroupChannel: resetHistoryGroupChannel,
        deleteMessage: deleteMessage,
        updateMessage: updateMessage,
        listPinnedMessages: listPinnedMessages,
        pinMessage: pinMessage,
        unpinMessage: unpinMessage,
        banUser: banUser,
        addOperators: addOperators,
        removeOperators: removeOperators,
        inviteMembers: inviteMembers,
        leaveGroupChannel: leaveGroupChannel,
        updateChannel: updateChannel,
        openThread: openThread,
        userID: state.userID,
        chatState: [state, updateState],
      }}
    >
      {props.children}
    </ChatContext.Provider>
  );
};

const getParamsForThreading = async (
  parentsMessage,
  currentlyJoinedChannel
) => {
  const params = {
    messageId: parentsMessage.messageId,
    channelType: ChannelType.GROUP, // Acceptable values are open and group.
    channelUrl: currentlyJoinedChannel.url,
  };

  const paramsThreadedMessageListParams = {
    prevResultSize: 10,
    nextResultSize: 10,
    isInclusive: true,
    reverse: false,
    includeParentMessageInfo: false,
  };

  try {
    const { threadedMessages } =
      await parentsMessage.getThreadedMessagesByTimestamp(
        30,
        paramsThreadedMessageListParams
      );

    return { params: params, threadedMessages: threadedMessages };
  } catch (e) {
    console.log("Error:", e);
  }
};
