import { useDispatch, useSelector } from "react-redux";
import { useLayoutEffect, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  onChangePageState,
  toggleModal,
  onFormCancel,
} from "../../redux/features/adminIntroductionFormSlice";
import { useGetIntroductionFormsQuery } from "../../redux/api/form-management/formManagementApi";
import ReactDataTable from "../../components/table/ReactDataTable";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { Breadcrumb, BreadcrumbItem, Spinner } from "reactstrap";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  Row,
  Col,
  ModalComponent,
} from "../../components/Components";
import { introductionFormsColumns } from "./DataColumns";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Introduction = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state.introductionForms);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm();
  const [tableData, setTableData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const { data, isFetching, isSuccess } = useGetIntroductionFormsQuery({
    offset: state.offset,
    limit: state.itemsPerPage,
    total: true,
    reverse: true,
  });

  const changePageState = ({ offset = 0, itemsPerPage }) => {
    dispatch(onChangePageState({ offset, itemsPerPage: itemsPerPage }));
  };

  useLayoutEffect(() => {
    if (data) {
      setTableData(data.forms);
      setTotalItems(data.total);
    }
  }, [data]);

  const onRowClicked = (row, e) => {
    e.stopPropagation();
    const action = e.target.getAttribute("data-action");
    switch (action) {
      case "remove":
        //dispatch delete
        break;
      case "details":
        // dispatch(toggleModal({ type: "details", item: row }));
        navigate(`../${row.reference_code}`);
        break;
    }
  };
  return (
    <>
      <Head title="Introduction" />
      <Content>
        <Block>
          <BlockHead>
            <Breadcrumb
              tag="nav"
              listTag="div"
              className="breadcrumb-arrow fs-18px"
            >
              <BreadcrumbItem tag="a" href="../">
                Home
              </BreadcrumbItem>
              <BreadcrumbItem active>Introduction Form</BreadcrumbItem>
            </Breadcrumb>
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Introduction</BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <PreviewCard>
            <ReactDataTable
              data={tableData}
              columns={introductionFormsColumns}
              offset={state.offset}
              itemsPerPage={state.itemsPerPage}
              pagination
              totalItems={totalItems}
              progressPending={isFetching}
              className="nk-tb-list"
              selectableRows
              changePageState={changePageState}
              onRowClicked={onRowClicked}
            />
          </PreviewCard>
        </Block>

        <ModalComponent
          isOpen={state.modal.details}
          toggle={() => dispatch(toggleModal({ type: "details" }))}
          title={"Introduction Form Details"}
          onModalCancel={() => {
            dispatch(onFormCancel());
          }}
        >
          {state.modal.item && (
            <Row className="gy-3">
              <Col lg={6}>
                <span className="sub-text">Employment Id</span>
                <span className="caption-text">{state.modal.item.id}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Email</span>
                <span className="caption-text">{state.modal.item.email}</span>
              </Col>

              <Col lg={6}>
                <span className="sub-text">Workplace</span>
                <span className="caption-text">
                  {state.modal.item.workplace_name}
                </span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Created Time</span>
                <span className="caption-text">
                  {state.modal.item.created_time}
                </span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Last Updated Time</span>
                <span className="caption-text">
                  {state.modal.item.last_updated_time}
                </span>
              </Col>
            </Row>
          )}
        </ModalComponent>
      </Content>
    </>
  );
};
export default Introduction;
