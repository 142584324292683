import { Outlet, useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { auth } from "../redux/api/authApi";
import { useDispatch, useSelector } from "react-redux";
import LoadingPage from "../pages/loading/LoadingPage";
import { ChatContextProvider } from "../pages/chat/ChatContext";

const AuthLayer = () => {
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  const accessToken = localStorage.getItem("accessToken");
  const {
    data: userS,
    isSuccess,
    isFetching,
    isError,
  } = auth.endpoints.getMe.useQuery(accessToken, {
    skip: user || !accessToken,
  });

  if (isFetching) {
    return <LoadingPage />;
  } else if (isError || !accessToken) {
    if (isError) {
      localStorage.removeItem("accessToken");
    }
    return (
      <Navigate
        to="/admin/login"
        replace
        state={{ previousPathName: location.pathname }}
      />
    );
  }

  //   console.log(accessToken);
  //   if (!accessToken) {
  //     console.log("yesss");
  //     return <Navigate to="/login" />;
  //   }
  //   if (!isUninitialized && !user) {
  //     return <Navigate to="/login" replace />;
  //   }
  //   if (!isUninitialized && !accessToken) {
  //     console.log("yeahhhhh");
  //     return <Navigate to="/login" replace />;
  //   }

  //   useEffect(() => {
  //     let token = localStorage.getItem("accessToken");
  //     setAccessToken(token);
  //   }, [user]);

  return (
    <ChatContextProvider>
      <Outlet />
    </ChatContextProvider>
  );
};

export default AuthLayer;
