import { api } from "../api";

export const formApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getIntroductionForm: builder.query({
      query: (code) => {
        return {
          url: "form/v1/introduction",
          params: code,
        };
      },
      transformResponse: (response) => {
        return response.data[0];
      },
    }),
    createIntroductionForm: builder.mutation({
      query: () => ({
        url: "form/v1/introduction/create",
        method: "POST",
      }),
      transformResponse: (response, meta) => {
        return {
          ...response.data[0],
          user_email: meta.response.headers.get("x-user-email"),
        };
      },
    }),
    saveIntroductionForm: builder.mutation({
      query: (data) => ({
        url: "form/v1/introduction",
        method: "POST",
        body: data,
      }),
    }),
    submitIntroduction: builder.mutation({
      query: (data) => ({
        url: "form/v1/introduction",
        method: "POST",
        body: data,
      }),
    }),
    getAssessmentForm: builder.query({
      query: (code) => {
        return {
          url: "form/v1/assessment",
          params: code,
        };
      },
      transformResponse: (response) => {
        return response.data[0];
      },
    }),
    submitAssessmentForm: builder.mutation({
      query: (data) => ({
        url: "form/v1/assessment",
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => {
        return response.data[0];
      },
    }),
    saveAssessmentForm: builder.mutation({
      query: (data) => ({
        url: "form/v1/assessment",
        method: "POST",
        body: data,
      }),
    }),
    getSDQForm: builder.query({
      query: (code) => {
        return {
          url: "form/v1/sdq",
          params: code,
        };
      },
      transformResponse: (response) => {
        return response.data[0];
      },
    }),
    getSDQuestions: builder.query({
      query: (params) => {
        return {
          url: "form/v1/sdq-list",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          questions: response.data,
          total: response.total,
        };
      },
    }),
    createSDQForm: builder.mutation({
      query: (data) => ({
        url: "form/v1/sdq",
        method: "POST",
        body: data,
      }),
    }),
    submitSDQForm: builder.mutation({
      query: (data) => ({
        url: "form/v1/sdq-submit",
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => {
        return response.data[0];
      },
    }),
    saveSDQForm: builder.mutation({
      query: (data) => ({
        url: "form/v1/sdq-submit",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useCreateIntroductionFormMutation,
  useSaveIntroductionFormMutation,
  useGetIntroductionFormQuery,
  useGetAssessmentFormQuery,
  useSubmitAssessmentFormMutation,
  useSaveAssessmentFormMutation,
  useGetSDQFormQuery,
  useGetSDQuestionsQuery,
  useLazyGetSDQuestionsQuery,
  useCreateSDQFormMutation,
  useSubmitSDQFormMutation,
  useSaveSDQFormMutation,
  useLazyGetIntroductionFormQuery,
  useSubmitIntroductionMutation,
} = formApi;
