import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import { Block } from "../../components/Components";
import { Spinner } from "reactstrap";

const LoadingPage = () => {
  return (
    <>
      <Head title="Loading" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body wide-xs">
          <div className="text-center">
            <Spinner />
          </div>
        </Block>
      </PageContainer>
    </>
  );
};

export default LoadingPage;
