import { api } from "../api";

export const dropdownDataApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSexes: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/dropdown-config/sexes",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          sexes: response.data,
          total: response.total,
        };
      },
      providesTags: ["Sexes"],
    }),
    createSex: builder.mutation({
      query: (data) => ({
        url: "admin/v1/dropdown-config/sex",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Sexes"],
    }),
    updateSex: builder.mutation({
      query: ({ params, data }) => {
        return {
          url: "admin/v1/dropdown-config/sex",
          method: "PUT",
          params: params,
          body: data,
        };
      },
      invalidatesTags: ["Sexes"],
    }),
    deleteSex: builder.mutation({
      query: (params) => ({
        url: "admin/v1/dropdown-config/sex",
        method: "DELETE",
        params: params,
      }),
      invalidatesTags: ["Sexes"],
    }),
    getReligions: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/dropdown-config/religions",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          religions: response.data,
          total: response.total,
        };
      },
      providesTags: ["Religions"],
    }),
    createReligion: builder.mutation({
      query: (data) => ({
        url: "admin/v1/dropdown-config/religion",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Religions"],
    }),
    updateReligion: builder.mutation({
      query: ({ params, data }) => {
        return {
          url: "admin/v1/dropdown-config/religion",
          method: "PUT",
          params: params,
          body: data,
        };
      },
      invalidatesTags: ["Religions"],
    }),
    deleteReligion: builder.mutation({
      query: (params) => ({
        url: "admin/v1/dropdown-config/religion",
        method: "DELETE",
        params: params,
      }),
      invalidatesTags: ["Religions"],
    }),
    getInteractionTypes: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/dropdown-config/interaction-types",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          interaction_types: response.data,
          total: response.total,
        };
      },
      providesTags: ["InteractionTypes"],
    }),
    createInteractionType: builder.mutation({
      query: (data) => ({
        url: "admin/v1/dropdown-config/interaction-type",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["InteractionTypes"],
    }),
    updateInteractionType: builder.mutation({
      query: ({ params, data }) => {
        return {
          url: "admin/v1/dropdown-config/interaction-type",
          method: "PUT",
          params: params,
          body: data,
        };
      },
      invalidatesTags: ["InteractionTypes"],
    }),
    deleteInteractionType: builder.mutation({
      query: (params) => ({
        url: "admin/v1/dropdown-config/interaction-type",
        method: "DELETE",
        params: params,
      }),
      invalidatesTags: ["InteractionTypes"],
    }),
    getMedicalTypes: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/dropdown-config/medical-types",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          medical_types: response.data,
          total: response.total,
        };
      },
      providesTags: ["MedicalTypes"],
    }),
    createMedicalType: builder.mutation({
      query: (data) => ({
        url: "admin/v1/dropdown-config/medical-type",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["MedicalTypes"],
    }),
    updateMedicalType: builder.mutation({
      query: ({ params, data }) => {
        return {
          url: "admin/v1/dropdown-config/medical-type",
          method: "PUT",
          params: params,
          body: data,
        };
      },
      invalidatesTags: ["MedicalTypes"],
    }),
    deleteMedicalType: builder.mutation({
      query: (params) => ({
        url: "admin/v1/dropdown-config/medical-type",
        method: "DELETE",
        params: params,
      }),
      invalidatesTags: ["MedicalTypes"],
    }),
    getRaces: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/dropdown-config/races",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          races: response.data,
          total: response.total,
        };
      },
      providesTags: ["Races"],
    }),
    createRace: builder.mutation({
      query: (data) => ({
        url: "admin/v1/dropdown-config/race",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Races"],
    }),
    updateRace: builder.mutation({
      query: ({ params, data }) => {
        return {
          url: "admin/v1/dropdown-config/race",
          method: "PUT",
          params: params,
          body: data,
        };
      },
      invalidatesTags: ["Races"],
    }),
    deleteRace: builder.mutation({
      query: (params) => ({
        url: "admin/v1/dropdown-config/race",
        method: "DELETE",
        params: params,
      }),
      invalidatesTags: ["Races"],
    }),
    getEducationLevels: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/dropdown-config/education-levels",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          education_levels: response.data,
          total: response.total,
        };
      },
      providesTags: ["EducationLevels"],
    }),
    createEducationLevel: builder.mutation({
      query: (data) => ({
        url: "admin/v1/dropdown-config/education-level",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["EducationLevels"],
    }),
    updateEducationLevel: builder.mutation({
      query: ({ params, data }) => {
        return {
          url: "admin/v1/dropdown-config/education-level",
          method: "PUT",
          params: params,
          body: data,
        };
      },
      invalidatesTags: ["EducationLevels"],
    }),
    deleteEducationLevel: builder.mutation({
      query: (params) => ({
        url: "admin/v1/dropdown-config/education-level",
        method: "DELETE",
        params: params,
      }),
      invalidatesTags: ["EducationLevels"],
    }),
    getOrgContactRoles: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/dropdown-config/org-contact-roles",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          roles: response.data,
          total: response.total,
        };
      },
      providesTags: ["OrgContactRoles"],
    }),
    createOrgContactRole: builder.mutation({
      query: (data) => ({
        url: "admin/v1/dropdown-config/org-contact-role",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["OrgContactRoles"],
    }),
    updateOrgContactRole: builder.mutation({
      query: ({ params, data }) => {
        return {
          url: "admin/v1/dropdown-config/org-contact-role",
          method: "PUT",
          params: params,
          body: data,
        };
      },
      invalidatesTags: ["OrgContactRoles"],
    }),
    deleteOrgContactRole: builder.mutation({
      query: (params) => ({
        url: "admin/v1/dropdown-config/org-contact-role",
        method: "DELETE",
        params: params,
      }),
      invalidatesTags: ["OrgContactRoles"],
    }),
    getOrgSupportingDocTypes: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/dropdown-config/org-supporting-doc-types",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          types: response.data,
          total: response.total,
        };
      },
      providesTags: ["OrgSupportingDocTypes"],
    }),
    createOrgSupportingDocType: builder.mutation({
      query: (data) => ({
        url: "admin/v1/dropdown-config/org-supporting-doc-type",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["OrgSupportingDocTypes"],
    }),
    updateOrgSupportingDocType: builder.mutation({
      query: ({ params, data }) => {
        return {
          url: "admin/v1/dropdown-config/org-supporting-doc-type",
          method: "PUT",
          params: params,
          body: data,
        };
      },
      invalidatesTags: ["OrgSupportingDocTypes"],
    }),
    deleteOrgSupportingDocType: builder.mutation({
      query: (params) => ({
        url: "admin/v1/dropdown-config/org-supporting-doc-type",
        method: "DELETE",
        params: params,
      }),
      invalidatesTags: ["OrgSupportingDocTypes"],
    }),
    getOpportunityTypes: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/dropdown-config/opportunity-types",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          types: response.data,
          total: response.total,
        };
      },
      providesTags: ["OpportunityTypes"],
    }),
    createOpportunityType: builder.mutation({
      query: (data) => ({
        url: "admin/v1/dropdown-config/opportunity-type",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["OpportunityTypes"],
    }),
    updateOpportunityType: builder.mutation({
      query: ({ params, data }) => {
        return {
          url: "admin/v1/dropdown-config/opportunity-type",
          method: "PUT",
          params: params,
          body: data,
        };
      },
      invalidatesTags: ["OpportunityTypes"],
    }),
    deleteOpportunityType: builder.mutation({
      query: (params) => ({
        url: "admin/v1/dropdown-config/opportunity-type",
        method: "DELETE",
        params: params,
      }),
      invalidatesTags: ["OpportunityTypes"],
    }),
    getOpportunityContractTypes: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/dropdown-config/opportunity-contract-types",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          types: response.data,
          total: response.total,
        };
      },
      providesTags: ["OpportunityContractTypes"],
    }),
    createOpportunityContractType: builder.mutation({
      query: (data) => ({
        url: "admin/v1/dropdown-config/opportunity-contract-type",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["OpportunityContractTypes"],
    }),
    updateOpportunityContractType: builder.mutation({
      query: ({ params, data }) => {
        return {
          url: "admin/v1/dropdown-config/opportunity-contract-type",
          method: "PUT",
          params: params,
          body: data,
        };
      },
      invalidatesTags: ["OpportunityContractTypes"],
    }),
    deleteOpportunityContractType: builder.mutation({
      query: (params) => ({
        url: "admin/v1/dropdown-config/opportunity-contract-type",
        method: "DELETE",
        params: params,
      }),
      invalidatesTags: ["OpportunityContractTypes"],
    }),
    getOpportunityCommitments: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/dropdown-config/opportunity-commitment-types",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          commitments: response.data,
          total: response.total,
        };
      },
      providesTags: ["OpportunityCommitments"],
    }),
    createOpportunityCommitment: builder.mutation({
      query: (data) => ({
        url: "admin/v1/dropdown-config/opportunity-commitment-type",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["OpportunityCommitments"],
    }),
    updateOpportunityCommitment: builder.mutation({
      query: ({ params, data }) => {
        return {
          url: "admin/v1/dropdown-config/opportunity-commitment-type",
          method: "PUT",
          params: params,
          body: data,
        };
      },
      invalidatesTags: ["OpportunityCommitments"],
    }),
    deleteOpportunityCommitment: builder.mutation({
      query: (params) => ({
        url: "admin/v1/dropdown-config/opportunity-commitment-type",
        method: "DELETE",
        params: params,
      }),
      invalidatesTags: ["OpportunityCommitments"],
    }),
    getObservationGradings: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/dropdown-config/observation-gradings",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          observationGradings: response.data,
          total: response.total,
        };
      },
      providesTags: ["ObservationGradings"],
    }),
    createObservationGrading: builder.mutation({
      query: (data) => ({
        url: "admin/v1/dropdown-config/observation-gradings",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["ObservationGradings"],
    }),
    updateObservationGrading: builder.mutation({
      query: ({ params, data }) => {
        return {
          url: "admin/v1/dropdown-config/observation-gradings",
          method: "PUT",
          params: params,
          body: data,
        };
      },
      invalidatesTags: ["ObservationGradings"],
    }),
    deleteObservationGrading: builder.mutation({
      query: (params) => ({
        url: "admin/v1/dropdown-config/observation-gradings",
        method: "DELETE",
        params: params,
      }),
      invalidatesTags: ["ObservationGradings"],
    }),
  }),
});

export const {
  useGetSexesQuery,
  useLazyGetReligionsQuery,
  useCreateSexMutation,
  useUpdateSexMutation,
  useDeleteSexMutation,
  useGetReligionsQuery,
  useLazyGetSexesQuery,
  useCreateReligionMutation,
  useUpdateReligionMutation,
  useDeleteReligionMutation,
  useGetInteractionTypesQuery,
  useLazyGetInteractionTypesQuery,
  useCreateInteractionTypeMutation,
  useUpdateInteractionTypeMutation,
  useDeleteInteractionTypeMutation,
  useGetMedicalTypesQuery,
  useLazyGetMedicalTypesQuery,
  useCreateMedicalTypeMutation,
  useUpdateMedicalTypeMutation,
  useDeleteMedicalTypeMutation,
  useGetRacesQuery,
  useLazyGetRacesQuery,
  useCreateRaceMutation,
  useUpdateRaceMutation,
  useDeleteRaceMutation,
  useGetEducationLevelsQuery,
  useLazyGetEducationLevelsQuery,
  useCreateEducationLevelMutation,
  useUpdateEducationLevelMutation,
  useDeleteEducationLevelMutation,
  useGetOrgContactRolesQuery,
  useLazyGetOrgContactRolesQuery,
  useCreateOrgContactRoleMutation,
  useUpdateOrgContactRoleMutation,
  useDeleteOrgContactRoleMutation,
  useGetOrgSupportingDocTypesQuery,
  useLazyGetOrgSupportingDocTypesQuery,
  useCreateOrgSupportingDocTypeMutation,
  useUpdateOrgSupportingDocTypeMutation,
  useDeleteOrgSupportingDocTypeMutation,
  useGetOpportunityTypesQuery,
  useLazyGetOpportunityTypesQuery,
  useCreateOpportunityTypeMutation,
  useUpdateOpportunityTypeMutation,
  useDeleteOpportunityTypeMutation,
  useGetOpportunityContractTypesQuery,
  useLazyGetOpportunityContractTypesQuery,
  useCreateOpportunityContractTypeMutation,
  useUpdateOpportunityContractTypeMutation,
  useDeleteOpportunityContractTypeMutation,
  useGetOpportunityCommitmentsQuery,
  useLazyGetOpportunityCommitmentsQuery,
  useCreateOpportunityCommitmentMutation,
  useUpdateOpportunityCommitmentMutation,
  useDeleteOpportunityCommitmentMutation,
  useGetObservationGradingsQuery,
  useLazyGetObservationGradingsQuery,
  useCreateObservationGradingMutation,
  useUpdateObservationGradingMutation,
  useDeleteObservationGradingMutation,
} = dropdownDataApi;
