import { Col } from "../Components";
import classNames from "classnames";
import { FormGroup } from "reactstrap";

export const CheckboxInput = ({
  name,
  label,
  caption,
  obscure,
  errors,
  md,
  required,
  defaultChecked,
  isDisabled,
  isLabelOnLeft,
  onBlurFunc,
  onChangeFunc,
  options,
  ...props
}) => {
  const { outerClassName, ...innerProps } = props;
  const outerClasses = classNames({
    "form-group-wrapper": true,
    [`${outerClassName}`]: outerClassName,
  });
  const splittedS = name.split(".");
  let errField = null;
  if (errors[splittedS[0]] && splittedS.length === 3) {
    const arrFieldName = splittedS[0];
    const index = parseInt(splittedS[1]);
    const fieldName = splittedS[2];

    errField = errors[arrFieldName][index]?.[fieldName];
  }

  if (options) {
    return (
      <Col md={md ?? "6"} className={outerClasses}>
        <FormGroup className="form-group"> 
            <label className="form-label">{label}</label> 
            <ul className="custom-control-group g-3 align-center"> 
              {options?.map((opt, idx) => {
                return (
                  <li key={`${name}-option-${opt.value}`}> 
                    <div className="custom-control custom-control-sm custom-checkbox"> 
                    <input type="checkbox"
                        className="custom-control-input form-control"
                        id={`${name}-${opt.value}`}
                        value={opt.value}
                        {...innerProps.register} /> 
                    <label className="custom-control-label" htmlFor={`${name}-${opt.value}`}> 
                        {opt.label}
                    </label> 
                    </div> 
                  </li> 
                )
              })}
            </ul> 
        </FormGroup>
      </Col>
    )
  }

  return (
    <Col md={md ?? "6"} className={outerClasses}>
      <div
        className={classNames("custom-control", "custom-checkbox", {
          "custom-checkbox-left-label": isLabelOnLeft ?? true,
        })}
      >
        <input
          disabled={isDisabled}
          name={name}
          {...innerProps.register}
          {...innerProps}
          type="checkbox"
          className="custom-control-input form-control"
          id={`checkbox-field-${name}`}
          defaultChecked={defaultChecked ?? false}
          onChange={(e) => {
            let { onChange } = { ...innerProps.register };
            onChange(e);
            if (onChangeFunc) {
              onChangeFunc();
            }
          }}
          onBlur={(e) => {
            let { onBlur } = { ...innerProps.register };
            onBlur(e);
            if (onBlurFunc) {
              onBlurFunc();
            }
          }}
        />
        <label
          className={classNames("custom-control-label", "form-label", {
            "custom-label-left": isLabelOnLeft ?? true,
          })}
          htmlFor={`checkbox-field-${name}`}
        >
          {label} {required && <span className="required-asterisk">*</span>}
        </label>
        {errors && errors[name] && (
          <span className="invalid">{errors[name].message}</span>
        )}
        {errors && errField && (
          <span className="invalid">{errField.message}</span>
        )}
      </div>
    </Col>
  );
};
