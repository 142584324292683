import { Icon, TooltipComponent } from "../../components/Components";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import { formatDate } from "../../utils/utils";
import React from "react";

export const permissionColumns = [
  {
    name: "Name",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px" },
    cell: (row) => <span>{row.name}</span>,
    sortable: true,
  },
  {
    name: "Description",
    selector: (row) => row.description,
    cell: (row) => <span>{row.description}</span>,
    sortable: true,
    hide: "md",
  },{
    name: "Resource",
    selector: (row) => row.resource,
    cell: (row) => <span>{row.resource}</span>,
    sortable: true,
    hide: "md",
  },{
    name: "Action",
    selector: (row) => row.action,
    cell: (row) => <div className="my-1">
      {row.action?.map((e, id) => {
      return <p key={`${row.id}-action-${e}`} className="my-1">{e}</p>
    })}
    </div>,
    sortable: true,
    hide: "md",
  },
  {
    name: "Created",
    selector: (row) => row.created_time,
    sortable: true,
    cell: (row) => <span>{formatDate(row.created_time)}</span>,
    hide: "md",
  },
  {
    name: "Updated",
    selector: (row) => row.last_updated_time,
    sortable: true,
    cell: (row) => <span>{formatDate(row.last_updated_time)}</span>,
    hide: "md",
  },
  {
    name: (
      <UncontrolledDropdown>
        <DropdownToggle
          tag="a"
          className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
        >
          <Icon name="more-h"></Icon>
        </DropdownToggle>
        <DropdownMenu right>
          <ul className="link-list-opt no-bdr">
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Selected</span>
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      data-tag="allowRowEvents"
                      data-action="edit"
                    >
                      <Icon
                        name="edit"
                        data-tag="allowRowEvents"
                        data-action="edit"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="edit">
                        Edit
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  },
];

export const roleColumns = [
  {
    name: "Name",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px" },
    cell: (row) => <span>{row.name}</span>,
    sortable: true,
  },
  {
    name: "Description",
    selector: (row) => row.description,
    cell: (row) => <span>{row.description}</span>,
    sortable: true,
    hide: "md",
  },
  {
    name: "Created",
    selector: (row) => row.created_time,
    sortable: true,
    cell: (row) => <span>{formatDate(row.created_time)}</span>,
    hide: "md",
  },
  {
    name: "Updated",
    selector: (row) => row.last_updated_time,
    sortable: true,
    cell: (row) => <span>{formatDate(row.last_updated_time)}</span>,
    hide: "md",
  },
  {
    name: (
      <UncontrolledDropdown>
        <DropdownToggle
          tag="a"
          className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
        >
          <Icon name="more-h"></Icon>
        </DropdownToggle>
        <DropdownMenu right>
          <ul className="link-list-opt no-bdr">
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Selected</span>
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li className="nk-tb-action-hidden">
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-trigger btn-icon"
              id={"view" + row.id}
              icon="eye"
              direction="top"
              text="View Details"
              data-tag="allowRowEvents"
              data-action="details"
            />
          </li>
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#markasdone"
                      data-tag="allowRowEvents"
                      data-action="details"
                    >
                      <Icon
                        name="eye"
                        data-tag="allowRowEvents"
                        data-action="details"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="details">
                        View Details
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      data-tag="allowRowEvents"
                      data-action="edit"
                    >
                      <Icon
                        name="edit"
                        data-tag="allowRowEvents"
                        data-action="edit"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="edit">
                        Edit
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  },
];
