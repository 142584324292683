import { useDispatch, useSelector } from "react-redux";
import { useState, useLayoutEffect, useEffect } from "react";
import ReactDataTable from "../../components/table/ReactDataTable";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
  Row,
  Col,
} from "../../components/Components";
import {
  onChangePageState,
  toggleModal,
} from "../../redux/features/organisationSlice";
import { useGetOrganisationsQuery } from "../../redux/api/organisations/organisationApi";
import { organisationColumns } from "./DataColumns";
import {
  Breadcrumb,
  BreadcrumbItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledDropdown,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

const Organisations = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state.organisations);
  const { data, isFetching } = useGetOrganisationsQuery({
    offset: state.offset,
    limit: state.itemsPerPage,
    total: true,
    reverse: true,
  });
  const [tableData, setTableData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const changePageState = ({ offset = 0, itemsPerPage }) => {
    dispatch(onChangePageState({ offset, itemsPerPage: itemsPerPage }));
  };

  useLayoutEffect(() => {
    if (data) {
      setTableData(data.organisations);
      setTotalItems(data.total);
    }
  }, [data]);

  const onAddBtnClicked = () => {
    navigate("../create");
  };

  const onRowClicked = (row, e) => {
    e.stopPropagation();
    const action = e.target.getAttribute("data-action");
    switch (action) {
      case "details":
        navigate(`../${row.id}`);
        break;
      case "remove":
        //dispatch delete
        break;
      case "quick_views":
        dispatch(toggleModal({ type: "quick_views", item: row }));
        break;
      default:
    }
  };

  return (
    <>
      <Head title="Organisations"></Head>
      <Content>
        <Block>
          <BlockHead>
            <Breadcrumb
              tag="nav"
              listTag="div"
              className="breadcrumb-arrow fs-18px"
            >
              <BreadcrumbItem tag="a" href="../">
                Home
              </BreadcrumbItem>
              <BreadcrumbItem active>Organisations</BreadcrumbItem>
            </Breadcrumb>
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Organisations</BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <Button
                    className="toggle d-none d-md-inline-flex mr-3"
                    color="primary"
                    onClick={() => {
                      onAddBtnClicked();
                    }}
                  >
                    <Icon name="plus"></Icon>
                    <span>Create Organisation</span>
                  </Button>
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <PreviewCard>
            <div className="table-wrapper is-scrollable">
              <ReactDataTable
                data={tableData}
                columns={organisationColumns}
                offset={state.offset}
                itemsPerPage={state.itemsPerPage}
                pagination
                totalItems={totalItems}
                progressPending={isFetching}
                className="nk-tb-list"
                selectableRows
                changePageState={changePageState}
                onRowClicked={onRowClicked}
                filterComponent={filterComponent}
              />
            </div>
          </PreviewCard>
        </Block>
      </Content>
    </>
  );
};

const filterComponent = () => {
  return (
    <UncontrolledDropdown>
      <DropdownToggle
        tag="a"
        className="btn btn-trigger btn-icon dropdown-toggle"
      >
        <div className="dot dot-primary"></div>
        <Icon name="filter-alt"></Icon>
      </DropdownToggle>
      <DropdownMenu
        right
        className="filter-wg dropdown-menu-xl"
        style={{ overflow: "visible" }}
      >
        <div className="dropdown-head">
          <span className="sub-title dropdown-title">Filter Organisations</span>
        </div>
        <div className="dropdown-body dropdown-body-rg">
          <Row className="gx-6 gy-3">
            <Col size="12">
              <FormGroup>
                <label className="overline-title overline-title-alt">
                  Stage
                </label>
                <div className={`form-control-select`}>
                  <Select
                    className={`react-select-container`}
                    classNamePrefix="react-select"
                    options={[
                      { value: "All", label: "All" },
                      { value: "Completed", label: "Completed" },
                      { value: "Interim", label: "Interim" },
                    ]}
                  ></Select>
                </div>
              </FormGroup>
            </Col>
          </Row>
        </div>
        <div className="dropdown-foot between">
          <a
            href="#reset"
            onClick={(ev) => {
              ev.preventDefault();
            }}
            className="clickable"
          >
            Reset Filter
          </a>
          <a
            href="#save"
            onClick={(ev) => {
              ev.preventDefault();
            }}
          >
            Save Filter
          </a>
        </div>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default Organisations;
