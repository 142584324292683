import React, { useContext, useEffect, useState } from "react";
import {
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import { Icon, UserAvatar } from "../../components/Components";
import { ChatContext } from "./ChatContext";
import { findUpper } from "../../utils/utils";
import moment from "moment";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { set } from "react-hook-form";

export const MeChat2 = ({
  item,
  onDeleteMessageClicked,
  setMessageToUpdate,
  setMessageToReply,
  pinMessage,
  unpinMessage,
  isPinnedMessage,
  isOpenPinnedView,
}) => {
  return (
    <div
      className="chat is-me"
      style={{
        marginLeft: "5rem",
      }}
    >
      <div className="chat-content">
        <div className="chat-bubbles">
          {/* {item.chat.map((chatItem, idx) => {
            return (
              <div className="chat-bubble" key={idx}>
                {chatItem.deleted === "deleted" ? (
                  <div className="chat-msg border bg-white text-muted">
                    Message has been deleted
                  </div>
                ) : (
                  <React.Fragment>
                    <div
                      className={`chat-msg bg-${chat.chatTheme}`}
                      style={{
                        display: "flex",
                        padding: "0.7rem 1rem 1rem 1rem",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "0.8rem",
                          maxWidth: "10rem",
                        }}
                      >
                        {chatItem.type === "txt" && chatItem.msg}
                        {chatItem.type === "img" && (
                          <img src={`${chatItem.url}`} alt="" />
                        )}
                      </div>
                      <div
                        style={{
                          position: "relative",
                          fontSize: "0.75rem",
                          top: "0.7rem",
                          right: "-0.5rem",
                          alignSelf: "flex-end",
                          opacity: "0.8",
                        }}
                      >
                        {moment(chatItem.time).format("hh:mm A")}
                      </div>
                    </div>
                    <ul className="chat-msg-more">
                      <li className="d-none d-sm-block">
                        <a
                          href="#delete"
                          onClick={(ev) => {
                            ev.preventDefault();
                            // onRemoveMessage(idx, item.id);
                            recallMessage({
                              mid: chatItem.id,
                              chatType: chatItem.chatType,
                              to: chatItem.to,
                              channel: chat.id,
                            })
                          }}
                          className="btn btn-icon btn-sm btn-trigger"
                        >
                          <Icon name="trash-fill"></Icon>
                        </a>
                      </li>
                    </ul>
                  </React.Fragment>
                )}
              </div>
            );
          })} */}

          <div className="chat-bubble">
            {item.deleted === "deleted" ? (
              <div className="chat-msg border bg-white text-muted">
                Message has been deleted
              </div>
            ) : (
              <React.Fragment>
                <div
                  // className={`chat-msg bg-${chat.chatTheme}`}
                  className={`chat-msg`}
                  style={{
                    display: "flex",
                    padding:
                      item.updatedAt !== 0
                        ? "0.5rem 1rem"
                        : "0.7rem 1rem 1rem 1rem",
                    alignItems: "stretch",
                  }}
                >
                  <div
                    style={{
                      // marginRight: "0.5rem",
                      // maxWidth: "10rem",
                      alignSelf: "center",
                    }}
                  >
                    <MessageContent item={item}/>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      minWidth: "3rem",
                      justifyContent:
                        item.updatedAt !== 0 ? "space-between" : "flex-end",
                    }}
                  >
                    {item.updatedAt !== 0 && (
                      <span
                        style={{
                          opacity: "0.8",
                          fontSize: "0.75rem",
                          position: "relative",
                          top: "-0.2rem",
                          right: "-1.4rem",
                        }}
                      >
                        edited
                      </span>
                    )}
                    <div
                      style={{
                        position: "relative",
                        fontSize: "0.75rem",
                        top: item.updatedAt !== 0 ? "0.1rem" : "0.7rem",
                        right: "-0.5rem",
                        alignSelf: "flex-end",
                        opacity: "0.8",
                        textWrap: "nowrap",
                      }}
                    >
                      {moment(item.createdAt).format("hh:mm A")}
                    </div>
                  </div>
                </div>
                <ul className="chat-msg-more">
                  <li className="d-none d-sm-block">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        tag="a"
                        className="btn btn-icon btn-sm btn-trigger dropdown-toggle mr-n1"
                      >
                        <Icon name="more-v"></Icon>
                      </DropdownToggle>
                      <DropdownMenu right className="dropdown-menu-sm">
                        <ul className="link-list-opt no-bdr">
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#reply"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setMessageToReply(item);
                              }}
                            >
                              <Icon name="reply-fill"></Icon>
                              <span>Reply</span>
                            </DropdownItem>
                          </li>
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#edit"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setMessageToUpdate(item);
                              }}
                            >
                              <Icon name="pen2"></Icon>
                              <span>Edit</span>
                            </DropdownItem>
                          </li>
                          {!isPinnedMessage && (
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#pin"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  pinMessage(item.messageId);
                                }}
                              >
                                <Icon name="bookmark-fill"></Icon>
                                <span>Pin</span>
                              </DropdownItem>
                            </li>
                          )}
                          {isPinnedMessage && (
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#unpin"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  unpinMessage(item.messageId);
                                }}
                              >
                                <Icon name="bookmark-fill"></Icon>
                                <span>Unpin</span>
                              </DropdownItem>
                            </li>
                          )}
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#forward"
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              <Icon
                                name="reply-fill"
                                style={{
                                  transform: "scaleX(-1)",
                                  position: "relative",
                                  right: "0.5rem",
                                }}
                              ></Icon>
                              <span>Forward</span>
                            </DropdownItem>
                          </li>
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#delete"
                              onClick={(ev) => {
                                ev.preventDefault();
                                onDeleteMessageClicked(item.messageId);
                              }}
                            >
                              <Icon className="text-danger" name="trash"></Icon>
                              <span className="text-danger">Delete</span>
                            </DropdownItem>
                          </li>
                        </ul>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </li>
                  <li className="d-none d-sm-block">
                    <a
                      href="#reaction"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                      className="btn btn-icon btn-sm btn-trigger"
                    >
                      <Icon name="happy-fill"></Icon>
                    </a>
                  </li>
                </ul>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const YouChat2 = ({
  item,
  setMessageToReply,
  pinMessage,
  unpinMessage,
  isPinnedMessage,
  isOpenPinnedView,
  setForwardMessage,
}) => {
  return (
    <div
      className="chat is-you"
      style={{
        marginRight: "5rem",
      }}
    >
      <div className="chat-avatar">
        <UserAvatar
          // image={chat.image}
          // theme={chat.theme}
          text={findUpper(item.sender?.nickname)}
        ></UserAvatar>
      </div>
      <div className="chat-content">
        <div className="chat-bubbles">
          {/* {item.chat.map((chatItem, idx) => {
            return (
              <div className="chat-bubble" key={idx}>
                <div
                  className="chat-msg"
                  style={{
                    display: "flex",
                    padding: "0.7rem 0.2rem 1rem 1rem",
                  }}
                >
                  <div
                    style={{
                      marginRight: "0.8rem",
                      maxWidth: "10rem",
                    }}
                  >
                    {chatItem.type === "txt" && chatItem.msg}
                    {chatItem.type === "img" && (
                      <img src={`${chatItem.url}`} alt="" />
                    )}
                  </div>
                  <div
                    style={{
                      position: "relative",
                      fontSize: "0.75rem",
                      minWidth: "4rem",
                      right: "-0.4rem",
                      bottom: "-0.6rem",
                      alignSelf: "flex-end",
                      color: "#8094ae",
                    }}
                  >
                    {moment(chatItem.time).format("hh:mm A")}
                  </div>
                </div>
              </div>
            );
          })} */}
          <div className="chat-bubble">
            <div
              className="chat-msg"
              // style={{
              //   display: "flex",
              //   padding: "0.7rem 0.2rem 1rem 1rem",
              // }}
              style={{
                display: "flex",
                padding:
                  item.updatedAt !== 0
                    ? "0.5rem 1rem"
                    : "0.7rem 1rem 1rem 1rem",
                alignItems: "stretch",
              }}
            >
              <div
                style={{
                  // marginRight: "0.8rem",
                  // maxWidth: "10rem",
                  alignSelf: "center",
                }}
              >
                {item.messageType === "user" && item.message}
                {item.messageType === "file" && (
                  <img src={`${item.url}`} alt="" />
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  minWidth: "3rem",
                  justifyContent:
                    item.updatedAt !== 0 ? "space-between" : "flex-end",
                }}
              >
                {item.updatedAt !== 0 && (
                  <span
                    style={{
                      color: "#8094ae",
                      fontSize: "0.75rem",
                      position: "relative",
                      top: "-0.2rem",
                      right: "-1.4rem",
                    }}
                  >
                    edited
                  </span>
                )}
                <div
                  style={{
                    position: "relative",
                    fontSize: "0.75rem",
                    top: item.updatedAt !== 0 ? "0.1rem" : "0.7rem",
                    right: "-0.5rem",
                    alignSelf: "flex-end",
                    color: "#8094ae",
                    textWrap: "nowrap",
                  }}
                >
                  {moment(item.createdAt).format("hh:mm A")}
                </div>
              </div>
            </div>
            <ul className="chat-msg-more">
              <li className="d-none d-sm-block">
                <a
                  href="#reaction"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                  className="btn btn-icon btn-sm btn-trigger"
                >
                  <Icon name="happy-fill"></Icon>
                </a>
              </li>
              <li className="d-none d-sm-block">
                <UncontrolledDropdown>
                  <DropdownToggle
                    tag="a"
                    className="btn btn-icon btn-sm btn-trigger dropdown-toggle mr-n1"
                  >
                    <Icon name="more-v"></Icon>
                  </DropdownToggle>
                  <DropdownMenu right className="dropdown-menu-sm">
                    <ul className="link-list-opt no-bdr">
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#reply"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setMessageToReply(item);
                          }}
                        >
                          <Icon name="reply-fill"></Icon>
                          <span>Reply</span>
                        </DropdownItem>
                      </li>
                      {!isPinnedMessage && (
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#pin"
                            onClick={(ev) => {
                              ev.preventDefault();
                              pinMessage(item.messageId);
                            }}
                          >
                            <Icon name="bookmark-fill"></Icon>
                            <span>Pin</span>
                          </DropdownItem>
                        </li>
                      )}
                      {isPinnedMessage && (
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#unpin"
                            onClick={(ev) => {
                              ev.preventDefault();
                              unpinMessage(item.messageId);
                            }}
                          >
                            <Icon name="bookmark-fill"></Icon>
                            <span>Unpin</span>
                          </DropdownItem>
                        </li>
                      )}
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#forward"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setForwardMessage(item);
                          }}
                        >
                          <Icon
                            name="reply-fill"
                            style={{
                              transform: "scaleX(-1)",
                              position: "relative",
                              right: "0.5rem",
                            }}
                          ></Icon>
                          <span>Forward</span>
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#report"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          <Icon name="alert-fill"></Icon>
                          <span>Report</span>
                        </DropdownItem>
                      </li>
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export const MetaChat = ({ item }) => {
  return (
    <div className="chat-sap">
      <div className="chat-sap-meta">
        <span>{item}</span>
      </div>
    </div>
  );
};

const MessageContent = ({ item }) => {
  if (item.messageType === "file") {
    if (item.type?.startsWith("image")) {
      return <img src={`${item.thumbnails?.length > 0 ? item.thumbnails[0].url : item.url}`} alt="" />;
    }

  }

  return (<span>{item.message}</span>)
};

export const ChatItemHeader = ({ item, userID }) => {
  const [name, setName] = useState("");
  const [text, setText] = useState("");
  const [messageStatus, setMessageStatus] = useState("");

  useEffect(() => {
    if (item.memberCount > 2) {
      setName(item.name);
    } else {
      let usr = item.members?.find((member) => member.userId !== userID);
      setName(usr?.nickname);
    }

    let msg = "";
    if (item.lastMessage?.sender?.userId === userID) {
      msg += "You: ";
    }
    if (item.lastMessage?.messageType === "user") {
      msg += `${item.lastMessage?.message}`;
    }
    setText(msg);

    if (item.unreadMessageCount > 0) {
      setMessageStatus("unread");
    } else if (item.lastMessage?.sender?.userId === userID) {
      // Check if message is delivered or not
      if (item.memberCount === 2) {
        if (item.getUndeliveredMemberCount(item.lastMessage) > 0) {
          setMessageStatus("sent");
        } else {
          setMessageStatus("delivered");
        }
      }
    }
  }, [item.lastMessage]);

  return (
    <Link
      to={`${process.env.PUBLIC_URL}/admin/chat`}
      className={`chat-link ${item.unreadMessageCount > 0 ? "is-unread" : ""}`}
    >
      {item.memberCount > 2 ? (
        <div className="chat-media user-avatar user-avatar-multiple">
          {/* {item.user.slice(0, 2).map((user, idx) => {
            return (
              <UserAvatar
                key={idx}
                theme={user.theme}
                text={user.name}
                image={user.image}
                className="chat-media"
              ></UserAvatar>
            );
          })} */}
          <span className={"status dot dot-lg dot-success"}></span>
        </div>
      ) : (
        <UserAvatar
          theme={item.theme}
          text={findUpper(name)}
          image={item.image}
          className="chat-media"
        >
          <span
            className={`status dot dot-lg dot-${
              item.active === true ? "success" : "gray"
            }`}
          ></span>
        </UserAvatar>
      )}
      <div className="chat-info">
        <div className="chat-from">
          <div className="name">{name}</div>
          <span className="time">
            {moment(item.lastMessage?.createdAt).format("h:mm A")}
          </span>
        </div>
        <div className="chat-context">
          <div className="text">{text}</div>
          <div
            className={`status ${
              messageStatus === "unread" ? "unread" : "delivered"
            }`}
          >
            {messageStatus !== "unread" && (
              <Icon
                name={`${
                  messageStatus === "delivered"
                    ? "check-circle-fill"
                    : messageStatus === "sent"
                    ? "check-circle"
                    : ""
                }`}
              ></Icon>
            )}
            {messageStatus === "unread" && (
              <em className="icon ni ni-bullet-fill"></em>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

export const ChatItem = ({ userID, item, chatItemClick }) => {
  const { resetHistoryGroupChannel, propAction } = useContext(ChatContext);
  const [text, setText] = useState("");
  const [messageStatus, setMessageStatus] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [date, setDate] = useState("");

  // const checkBeforeDelete = (id) => {
  //   deleteConvo(id);
  //   if (selectedId === id) {
  //     setSelectedId(null);
  //   }
  // };

  useEffect(() => {
    let msg = "";
    if (item.lastMessage?.messageType === "user") {
      if (item.lastMessage?.sender?.userId === userID) {
        msg += "You: ";
      } else if (item.memberCount > 2) {
        msg += `${item.lastMessage?.sender?.nickname}: `;
      }
      msg += `${item.lastMessage?.message}`;
    }
    setText(msg);

    if (item.lastMessage?.createdAt) {
      setDate(
        moment(item.lastMessage?.createdAt).local().date() ===
          moment().local().date()
          ? moment(item.lastMessage?.createdAt).format("h:mm A")
          : moment(item.lastMessage?.createdAt).format("D MMM")
      );
    }

    if (item.unreadMessageCount > 0) {
      setMessageStatus("unread");
    } else if (item.lastMessage?.sender?.userId === userID) {
      // Check if message is delivered or not
      if (item.memberCount === 2) {
        if (item.getUndeliveredMemberCount(item.lastMessage) > 0) {
          setMessageStatus("sent");
        } else {
          setMessageStatus("delivered");
        }
      }
    } else {
      setMessageStatus("");
    }
  }, [item, item.lastMessage, item.unreadMessageCount, userID]);

  // useEffect(() => {
  //   let members = item.members?.filter((member) => member.userId !== userID);
  //   let isActive = false;
  //   for (let i = 0; i < members.length; i++) {
  //     if (members[i].connectionStatus === "online") {
  //       isActive = true;
  //       break;
  //     }
  //   }
  //   setIsActive(isActive);
  // }, [item.members, userID]);

  return (
    <li
      className={`chat-item ${item.unreadMessageCount > 0 ? "is-unread" : ""}`}
    >
      <a
        className="chat-link"
        href="#chat-link"
        onClick={(ev) => {
          ev.preventDefault();
          chatItemClick({
            channelURL: item.url,
            // id: item.id,
            // chatType: isGroup ? "groupChat" : "singleChat",
          });
        }}
      >
        {item.memberCount > 2 ? (
          <div
            className={classNames("chat-media user-avatar", {
              "user-avatar-multiple": true,
            })}
          >
            {/* {item.members?.length === 1 && (
              <UserAvatar
                className="chat-media"
                icon={`users-fill`}
                style={{ fontSize: "large" }}
              />
            )} */}
            {item.members?.slice(0, 2).map((member, idx) => {
              return (
                <UserAvatar
                  key={`avatar-group-aside-${idx}`}
                  // theme={user.theme}
                  text={findUpper(member.nickname)}
                  // image={user.image}
                  className="chat-media"
                ></UserAvatar>
              );
            })}
            {/* <span
              className={`status dot dot-lg dot-${
                isActive === true ? "success" : "gray"
              }`}
            ></span> */}
          </div>
        ) : (
          <UserAvatar
            theme={item.theme}
            text={findUpper(item.name)}
            image={item.image}
            className="chat-media"
          >
            {/* <span
              className={`status dot dot-lg dot-${
                // item.active === true ? "success" : "gray"
                isActive === true ? "success" : "gray"
              }`}
            ></span> */}
          </UserAvatar>
        )}
        <div className="chat-info">
          <div className="chat-from">
            <div
              className="name"
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                flex: "1",
                marginRight: "1rem",
                textWrap: "nowrap",
              }}
            >
              {item.name}
            </div>
            <span className="time">{date}</span>
          </div>
          <div className="chat-context">
            <div className="text">{text}</div>
            <div
              className={`status ${
                messageStatus === "unread" ? "unread" : "delivered"
              }`}
            >
              {messageStatus !== "unread" && (
                <Icon
                  name={`${
                    messageStatus === "delivered"
                      ? "check-circle-fill"
                      : messageStatus === "sent"
                      ? "check-circle"
                      : ""
                  }`}
                ></Icon>
              )}
              {messageStatus === "unread" && (
                <em className="icon ni ni-bullet-fill"></em>
              )}
            </div>
          </div>
        </div>
      </a>
      <div className="chat-actions">
        <UncontrolledDropdown>
          <DropdownToggle
            tag="a"
            className="btn btn-icon btn-sm btn-trigger dropdown-toggle"
          >
            <Icon name="more-h"></Icon>
          </DropdownToggle>
          <DropdownMenu right>
            <ul className="link-list-opt no-bdr">
              {/* <li onClick={() => checkBeforeDelete(item.id)}> */}
              {/* <li onClick={() => propAction(item.id, "unread")}>
                <DropdownItem
                  tag="a"
                  href="#unread"
                  className={item.unreadMessageCount > 0 ? "disabled" : ""}
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  Mark as Unread
                </DropdownItem>
              </li> */}
              <li onClick={() => propAction(item.id, "archive")}>
                <DropdownItem
                  tag="a"
                  href="#archive"
                  className={item.archive ? "disabled" : ""}
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  <Icon name="archive-fill"></Icon>
                  Archive chat
                </DropdownItem>
              </li>
              <li>
                <DropdownItem
                  tag="a"
                  href="#delete"
                  onClick={(ev) => {
                    ev.preventDefault();
                    resetHistoryGroupChannel({
                      channel: item,
                    });
                  }}
                  className="text-danger"
                >
                  <Icon name="trash-fill"></Icon>
                  Delete chat
                </DropdownItem>
              </li>
            </ul>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </li>
  );
};

export const ContactItem = ({ item, setTab }) => {
  const { chatItemClick: chatItemClickContext } = useContext(ChatContext);
  return (
    <ul className="contacts-list">
      <li>
        <h6 className="title overline-title-alt">
          {item.contacts.length > 0 && item.title}
        </h6>
      </li>
      {item.contacts.map((contact, idx) => {
        let name = `${contact.first_name ?? ""} ${contact.last_name ?? ""}`;
        let nickname = contact.agora_chat_nickname;

        return (
          <li
            key={idx}
            onClick={() => {
              setTab("Chats");
              chatItemClickContext({
                selectedID: contact.agora_chat_user_id,
                chatType: "singleChat",
              });
            }}
          >
            <div className="user-card">
              <a href="#card" onClick={(ev) => ev.preventDefault()}>
                <UserAvatar
                  text={findUpper(`${nickname ? nickname : name}`)}
                  theme={contact.theme}
                  image={contact.image}
                ></UserAvatar>
                <div className="user-name">{nickname ? nickname : name}</div>
              </a>
              <div className="user-actions">
                <Link to={`${window.location.pathname}`}>Start Chat</Link>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
