import moment from "moment";
import isEqual from "react-fast-compare";
import { countryFlagsMap, languages } from "../constants";

export const getBoundingClientObj = (element) => {
  const rect = element.getBoundingClientRect();
  return {
    bottom: rect.bottom,
    height: rect.height,
    left: rect.left,
    right: rect.right,
    top: rect.top,
    width: rect.width,
  };
};

export const formatDate = (date) => {
  if (moment(date).isValid && date != null) {
    return moment(date).format("DD-MM-YYYY, hh:mm:ss a");
  }
  return "";
};

export const formatDuration = (duration) => {
  let s = "";
  let seconds = Math.round(duration);
  let hours = Math.floor(seconds / 3600);
  if (hours > 0) {
    s += hours;
    if (hours === 1) {
      s += " hour ";
    } else {
      s += " hours ";
    }
  }

  let minutes = Math.floor((seconds - hours * 3600) / 60);
  if (minutes > 0) {
    s += minutes;
    if (minutes === 1) {
      s += " minute ";
    } else {
      s += " minutes ";
    }
  }
  seconds = seconds - hours * 3600 - minutes * 60;
  if (seconds > 0) {
    s += seconds;
    if (seconds === 1) {
      s += " second";
    } else {
      s += " seconds";
    }
  }

  return s;
};

export const getLanguageOptions = () => {
  return Object.values(languages).map((e) => ({
    value: e.name,
    label: e.name,
  }));
};

export const capitalize = (s) => {
  return s ? s[0].toUpperCase() + s.slice(1) : "";
};

export const convertUserData = (data, defaultValues, params) => {
  data.date_of_assessment = data.date_of_assessment
    ? new Date(data.date_of_assessment).toISOString()
    : null;
  data.time_of_assessment = data.time_of_assessment
    ? new Date(data.time_of_assessment).toISOString()
    : null;
  data.sex = data.sex?.value;
  data.religion = data.religion?.value;
  data.nationality = data.nationality_code
    ? countryFlagsMap[data.nationality_code]?.primary
    : null;
  data.ptc_card_description = data.ptc_card_description?.value;
  data.languages_spoken = data.languages_spoken?.map((e) => {
    return e?.value;
  });
  data.languages_written = data.languages_written?.map((e) => {
    return e?.value;
  });
  data.date_of_birth = data.date_of_birth
    ? new Date(data.date_of_birth).toISOString()
    : null;
  data.executive_functioning_tools_used =
    data.executive_functioning_tools_used?.map((e) => {
      return e?.value;
    });
  data.assessment_recommendation = data.assessment_recommendation?.map((e) => {
    return e?.value;
  });

  data.disability_details = data.disability_details?.map((e, idx) => {
    return {
      ...data.disability_details[idx],
      name: e.name?.value,
      priority: e.priority?.value,
      year_diagnosed: e.year_diagnosed
        ? new Date(e.year_diagnosed).toISOString()
        : null,
    };
  });
  data.education_details = data.education_details?.map((e, idx) => {
    return {
      ...data.education_details[idx],
      level: e.level?.value,
      from: e.from ? new Date(e.from).toISOString() : null,
      to: e.to ? new Date(e.to).toISOString() : null,
    };
  });

  if (isEqual(data.family_details, defaultValues.family_details)) {
    data.family_details = null;
  } else {
    data.family_details = data.family_details?.map((e, idx) => {
      return {
        ...data.family_details[idx],
        caregiver: e.caregiver?.value,
        relationship: e.relationship?.value,
      };
    });
  }

  if (isEqual(data.employment_details, defaultValues.employment_details)) {
    data.employment_details = null;
  } else {
    data.employment_details = data.employment_details?.map((e, idx) => {
      return {
        ...data.employment_details[idx],
        type: e.type?.value,
        from: e.from ? new Date(e.from).toISOString() : null,
        to: e.to ? new Date(e.to).toISOString() : null,
      };
    });
  }

  if (isEqual(data.medical_details, defaultValues.medical_details)) {
    data.medical_details = null;
  } else {
    data.medical_details = data.medical_details?.map((e, idx) => {
      return {
        ...data.medical_details[idx],
        type: e.type?.value,
        year_diagnosed: e.year_diagnosed
          ? new Date(e.year_diagnosed).toISOString()
          : null,
      };
    });
  }

  if (params) {
    data.reference_code = params.code;
  }

  return data;
};

export const convertOrganisationData = (data, defaultValues, params) => {
  data.addresses = data.addresses?.map((e, idx) => {
    return {
      ...data.addresses[idx],
      type: e.type?.value,
    };
  });

  return data;
};

//Function to validate and return errors for a form
export const checkForm = (formData) => {
  let errorState = {};
  Object.keys(formData).forEach((item) => {
    if (formData[item] === null || formData[item] === "") {
      errorState[item] = "This field is required";
    }
  });
  return errorState;
};

//Function that returns the first or first two letters from a name
export const findUpper = (string) => {
  string.split(" ");

  let extractedString = [];
  let splitted = string.split(" ");
  for (let i = 0; i < splitted.length; i++) {
    extractedString.push(splitted[i].charAt(0).toUpperCase());
  }

  // for (var i = 0; i < string.length; i++) {
  //   if (
  //     string.charAt(i) === string.charAt(i).toUpperCase() &&
  //     string.charAt(i) !== " "
  //   ) {
  //     extractedString.push(string.charAt(i));
  //   }
  // }
  if (extractedString.length > 1) {
    return extractedString[0] + extractedString[1];
  } else {
    return extractedString[0];
  }
};

//Function that calculates the from current date
export const setDeadline = (days) => {
  let todayDate = new Date();
  var newDate = new Date(todayDate);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

// Function to set deadline for projects
export const setDeadlineDays = (deadline) => {
  var currentDate = new Date();
  var difference = deadline.getTime() - currentDate.getTime();
  var days = Math.ceil(difference / (1000 * 3600 * 24));
  return days;
};

//Date formatter function
export const dateFormatterAlt = (date, reverse) => {
  let d = date.getDate();
  let m = date.getMonth();
  let y = date.getFullYear();
  reverse ? (date = m + "-" + d + "-" + y) : (date = y + "-" + d + "-" + m);
  return date;
};

//Date formatter function
export const dateFormatter = (date, reverse) => {
  var dateformat = date.split("-");
  //var date = dateformat[1]+"-"+dateformat[2]+"-"+dateformat[0];
  reverse
    ? (date = dateformat[2] + "-" + dateformat[0] + "-" + dateformat[1])
    : (date = dateformat[1] + "-" + dateformat[2] + "-" + dateformat[0]);
  return date;
};

//Month Names
export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

//todays Date
export const todaysDate = new Date();

// Function to structure date ex : Jun 4, 2011;
export const getDateStructured = (date) => {
  let d = date.getDate();
  let m = date.getMonth();
  let y = date.getFullYear();
  let final = monthNames[m] + " " + d + ", " + y;
  return final;
};

// Function to structure date ex: YYYY-MM-DD
export const setDateForPicker = (rdate) => {
  let d = rdate.getDate();
  d < 10 && (d = "0" + d);
  let m = rdate.getMonth() + 1;
  m < 10 && (m = "0" + m);
  let y = rdate.getFullYear();
  rdate = y + "-" + m + "-" + d;

  return rdate;
};

//current Time
export const currentTime = () => {
  var hours = todaysDate.getHours();
  var minutes = todaysDate.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

//Percentage calculation
export const calcPercentage = (str1, str2) => {
  let result = Number(str2) / Number(str1);
  result = result * 100;
  return Math.floor(result);
};

//shortens a long string
export const truncate = (str, n) => {
  return str.length > n
    ? str.substr(0, n - 1) + " " + truncate(str.substr(n - 1, str.length), n)
    : str;
};

// returns upload url
export const getUploadParams = () => {
  return { url: "https://httpbin.org/post" };
};

// Converts KB to MB
export const bytesToMegaBytes = (bytes) => {
  let result = bytes / (1024 * 1024);
  return result.toFixed(2);
};

export const bulkActionOptions = [
  { value: "suspend", label: "Suspend User" },
  { value: "delete", label: "Delete User" },
];

export const arrangeConvo = ({ convo, id, senderID, message }) => {
  if (convo.length > 0) {
    // Check if message same day
    let lastConvo = convo[convo.length - 1];
    let lastChatItem = lastConvo.chat[lastConvo.chat.length - 1];
    //   let diff = moment(lastChatItem.time).diff(message.time, "day");

    if (moment(lastChatItem.time).date() !== moment(message.time).date()) {
      // If different day, push to convo
      convo.push(
        ...[
          {
            meta: {
              metaID: "",
              metaText: moment(message.time).format("DD MMM, YYYY"),
            },
          },
          {
            chat: [message],
            me: message.from === senderID,
          },
        ]
      );
    } else {
      // Check if is same user
      if (
        lastChatItem.from === message.from ||
        (lastChatItem.to === message.to && lastChatItem.chatType !== "groupChat")
      ) {
        lastConvo.chat.push(message);
      } else {
        convo.push({
          chat: [message],
          me: message.from === senderID,
        });
      }
    }
  } else {
    convo.push(...[
      {
        meta: {
          metaID: "",
          metaText: moment(message.time).format("DD MMM, YYYY"),
        },
      },
      {
        chat: [message],
        me: message.from === senderID
      },
    ])
  }

  return convo;
}