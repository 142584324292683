import { Col, MenuPortal } from "../Components";
import { Icon } from "../Components";
import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import { useRef, useState } from "react";

const DatePickerInput = ({
  name,
  label,
  caption,
  errors,
  showYearPicker,
  showMonthYearPicker,
  dateFormat,
  maxDate,
  placeholder,
  onChangeFunc,
  portalId,
  md,
  ...props
}) => {
  const dRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const splittedS = name.split(".");
  let errField = null;
  if (errors[splittedS[0]] && splittedS.length === 3) {
    const arrFieldName = splittedS[0];
    const index = parseInt(splittedS[1]);
    const fieldName = splittedS[2];

    errField = errors[arrFieldName][index]?.[fieldName];
  }

  return (
    <Col md={md ?? "6"} className="form-group-wrapper">
      <div className="form-group">
        {label && (
          <label className="form-label" htmlFor={name}>
            {label}{" "}
            {props.rules && props.rules.required && (
              <span className="required-asterisk">*</span>
            )}
          </label>
        )}
        {caption && <div className="form-caption">{caption}</div>}
        <div className="form-control-wrap" ref={dRef}>
          <MenuPortal
            isOpen={isOpen}
            controlElement={dRef.current}
            portalId={portalId}
          />
          <div className="form-icon form-icon-left">
            <Icon name="calendar" />
          </div>
          <Controller
            name={name}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { isTouched, isDirty, error },
              formState,
            }) => {
              return (
                <DatePicker
                  className="form-control"
                  placeholderText={placeholder}
                  onChange={(e) => {
                    onChange(e);
                    if (onChangeFunc) {
                      onChangeFunc();
                    }
                  }}
                  disabled={props.isDisabled}
                  selected={value}
                  showYearPicker={showYearPicker}
                  showMonthYearPicker={showMonthYearPicker}
                  dateFormat={dateFormat}
                  maxDate={maxDate}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  open={isOpen}
                  onFocus={() => setIsOpen(true)}
                  onInputClick={() => setIsOpen(true)}
                  onBlur={() => setIsOpen(false)}
                  onClickOutside={() => setIsOpen(false)}
                  onSelect={() => setIsOpen(false)}
                  portalId={portalId}
                />
              );
            }}
            {...props}
          />
          {errors && errors[name] && (
            <span className="invalid">{errors[name].message}</span>
          )}
          {errors && errField && (
            <span className="invalid">{errField.message}</span>
          )}
        </div>
      </div>
    </Col>
  );
};

export default DatePickerInput;
