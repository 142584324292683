import classNames from "classnames";
import Icon from "../icon/Icon";

const UserAvatar = ({
  className,
  size,
  theme,
  icon,
  text,
  image,
  imageAlt,
  style,
  ...props
}) => {
  const classes = classNames({
    "user-avatar": true,
    [`${className}`]: className,
    [`user-avatar-${size}`]: size,
    [`bg-${theme}`]: theme,
  });
  return (
    <div className={classes} style={style}>
      {icon ? <Icon name={icon} /> : null}
      {image && <img src={image} alt={imageAlt} />}
      {text && !image && <span>{text}</span>}
      {props.children}
    </div>
  );
};

export default UserAvatar;
