import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  offset: 0,
  itemsPerPage: 10,
  modal: {
    add: false,
    details: false,
    item: null,
  },
  searchText: "",
};

export const emotionTypesSlice = createSlice({
  name: "emotionTypes",
  initialState: initialState,
  reducers: {
    onChangeSearchText(state, action) {
      state.searchText = action.payload;
    },
    onChangePageState(state, action) {
      const { offset, itemsPerPage } = action.payload;
      return {
        ...state,
        offset,
        itemsPerPage,
      };
    },
    onFormCancel(state, action) {
      return {
        ...state,
        modal: initialState.modal,
      };
    },
    toggleModal(state, action) {
      const { type, item } = action.payload;
      state.modal[type] = !state.modal[type];
      state.modal.item = item;
    },
  },
});

export const {
  onChangePageState,
  onFormCancel,
  toggleModal,
  onChangeSearchText,
} = emotionTypesSlice.actions;

export default emotionTypesSlice.reducer;
