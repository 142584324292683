import { useDispatch, useSelector } from "react-redux";
import React, { useState, useLayoutEffect, useEffect } from "react";
import ReactDataTable from "../../components/table/ReactDataTable";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
  Row,
  Col,
  ModalComponent,
  TextInput,
  TextareaInput,
  CheckboxInput,
  SelectField,
} from "../../components/Components";
import {
  onChangePageState,
  onSelectedRowsChange,
  onFormCancel,
  toggleModal,
} from "../../redux/features/authorization/roleSlice";
import {
  useGetRolesQuery,
  useCreateRoleMutation,
  useDeleteRoleMutation,
  useUpdateRoleMutation,
  useLazyGetPermissionsQuery,
  useGetPermissionsQuery,
} from "../../redux/api/authorization-management/authorizationApi";
import { useLazyGetOrganisationsQuery } from "../../redux/api/organisations/organisationApi";
import { roleColumns } from "./DataColumns";
import { FormGroup, Modal, ModalBody, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useGetMenuDataQuery } from "../../redux/api/config/configDataApi";

const Role = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.role);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    control,
    reset,
  } = useForm({
    defaultValues: {
      permissions: [],
      menu_data_ids: [],
    },
  });
  const { data, isFetching, error, isLoading, isError } = useGetRolesQuery({
    offset: state.offset,
    limit: state.itemsPerPage,
    total: true,
    reverse: true,
    q: JSON.stringify({ is_get_all: true }),
  });
  const {
    data: permissionData,
    isFetching: isGetPermissionFetching,
    isSuccess: isGetPermissionSuccess,
    isError: isGetPermissionError,
    error: getPermissionError,
  } = useGetPermissionsQuery({ limit: 1000 });
  const {
    data: menuData,
    isFetching: isGetMenuDataFetching,
    isSuccess: isGetMenuDataSuccess,
    isError: isGetMenuDataError,
    error: getMenuDataError,
  } = useGetMenuDataQuery({ limit: 1000 });
  const [
    getOrganisations,
    {
      data: orgData,
      isSuccess: isOrgSuccess,
      isFetching: isOrgFetching,
      isError: isOrgError,
    },
  ] = useLazyGetOrganisationsQuery({
    offset: 0,
    limit: 0,
  });
  const [
    createRole,
    {
      isLoading: isCreateLoading,
      isError: isCreateError,
      isSuccess: isCreateSuccess,
      error: createError,
    },
  ] = useCreateRoleMutation();
  const [
    updateRole,
    {
      isLoading: isUpdateLoading,
      isError: isUpdateError,
      isSuccess: isUpdateSuccess,
      error: updateError,
    },
  ] = useUpdateRoleMutation();
  const [
    deleteRole,
    {
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
      isError: isDeleteError,
      error: deleteError,
    },
  ] = useDeleteRoleMutation();
  const [tableData, setTableData] = useState(data?.roles ?? []);
  const [totalItems, setTotalItems] = useState(data?.total ?? 0);
  const [permissionOptions, setPermissionOptions] = useState([]);
  const [menuDataOptions, setMenuDataOptions] = useState([]);

  const changePageState = ({ offset = 0, itemsPerPage }) => {
    dispatch(onChangePageState({ offset, itemsPerPage: itemsPerPage }));
  };

  useLayoutEffect(() => {
    if (data) {
      setTableData(data.roles);
      setTotalItems(data.total);
    }
    if (isError && error) {
      if (error?.status === 404) {
        setTableData([]);
        setTotalItems(0);
      } else {
        toast.error(error?.data?.message);
      }
    }
  }, [data, isError]);

  const onFormSubmit = async (data) => {
    if (data.organisations != null) {
      data.organisation = data.organisation.value;
    }

    if (state.modal.add) {
      createRole(data);
    } else if (state.modal.edit) {
      updateRole({ params: { id: state.modal.item.id }, data: data });
    }
  };

  useEffect(() => {
    if (isCreateSuccess) {
      dispatch(toggleModal({ type: "add" }));
      reset();
    }
    if (isCreateError && createError) {
      toast.error(createError?.data?.message);
    }
  }, [isCreateSuccess, isCreateError]);

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(toggleModal({ type: "edit" }));
      reset();
    }
    if (isUpdateError && updateError) {
      toast.error(updateError?.data?.message);
    }
  }, [isUpdateError, isUpdateSuccess]);

  useEffect(() => {
    if (isGetPermissionSuccess && permissionData?.permissions) {
      let data = permissionData.permissions.map((e) => {
        return { value: e.id, label: e.name };
      });
      setPermissionOptions(data);
    }
  }, [isGetPermissionSuccess, isGetPermissionError]);

  useEffect(() => {
    if (isGetMenuDataSuccess && menuData?.data) {
      let data = menuData.data.map((e) => {
        return { value: e.id, label: e.menu_name };
      });
      setMenuDataOptions(data);
    }
  }, [isGetMenuDataSuccess, isGetMenuDataError]);

  const onAddBtnClicked = (e) => {
    dispatch(toggleModal({ type: "add" }));
    getOrganisations(undefined, true);
  };

  const handleSelectedRowsChange = ({ selectedRows }) => {
    dispatch(onSelectedRowsChange(selectedRows));
  };

  const onRowClicked = (row, e) => {
    e.stopPropagation();
    const action = e.target.getAttribute("data-action");
    switch (action) {
      case "remove":
        //dispatch delete
        deleteRole({ id: row.id });
        break;
      case "details":
        dispatch(toggleModal({ type: "details", item: row }));
        break;
      case "edit":
        dispatch(toggleModal({ type: "edit", item: row }));
        reset(
          {
            ...row,
            permissions: row?.permission_list?.map((permission, idx) => {
              return permission?.id;
            }),
          },
          {
            keepDefaultValues: true,
          }
        );
        break;
    }
  };

  return (
    <>
      <Head title="Roles"></Head>
      <Content>
        <Block>
          <BlockHead>
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Roles</BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <Button
                    className="toggle btn-icon d-md-none"
                    color="primary"
                    onClick={onAddBtnClicked}
                  >
                    <Icon name="plus"></Icon>
                  </Button>
                  <Button
                    className="toggle d-none d-md-inline-flex"
                    color="primary"
                    onClick={onAddBtnClicked}
                  >
                    <Icon name="plus"></Icon>
                    <span>Add Role</span>
                  </Button>
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <PreviewCard>
            <ReactDataTable
              data={tableData}
              columns={roleColumns}
              offset={state.offset}
              itemsPerPage={state.itemsPerPage}
              pagination
              totalItems={totalItems}
              progressPending={isFetching}
              className="nk-tb-list"
              selectableRows
              onSelectedRowsChange={handleSelectedRowsChange}
              changePageState={changePageState}
              onRowClicked={onRowClicked}
            />
          </PreviewCard>
        </Block>

        <ModalComponent
          isOpen={state.modal.details}
          toggle={() => dispatch(toggleModal({ type: "details" }))}
          title={"Details"}
          onModalCancel={() => {
            dispatch(onFormCancel());
          }}
        >
          {state.modal.item && (
            <Row className="gy-3">
              <Col lg={6}>
                <span className="sub-text">Role ID</span>
                <span className="caption-text">{state.modal.item.id}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Role Name</span>
                <span className="caption-text">{state.modal.item.name}</span>
              </Col>
              <Col lg={12}>
                <span className="sub-text">Description</span>
                <span className="caption-text">
                  {state.modal.item.description}
                </span>
              </Col>
              <Col lg={12}>
                <span className="sub-text">Permissions</span>
                <Row>
                  {state.modal.item.permission_list?.map((e, idx) => {
                    return (
                      <Col lg={6} key={e.id}>
                        <span className="caption-text">{e.name}</span>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Created Time</span>
                <span className="caption-text">
                  {state.modal.item.created_time}
                </span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Last Updated Time</span>
                <span className="caption-text">
                  {state.modal.item.last_updated_time}
                </span>
              </Col>
            </Row>
          )}
        </ModalComponent>

        <ModalComponent
          isOpen={state.modal.add || state.modal.edit}
          backdrop={"static"}
          title={`${state.modal.add ? "Add" : "Edit"} Role`}
          onModalCancel={() => {
            dispatch(onFormCancel());
            reset();
          }}
        >
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Row className="g-3">
              <TextInput
                name={"name"}
                label={"Name"}
                required
                register={{
                  ...register("name", {
                    required: "This field is required",
                  }),
                }}
                errors={errors}
              />
              <SelectField
                name={"organisation"}
                label={"Organisation"}
                control={control}
                options={
                  orgData?.organisations.map((item) => ({
                    label: item.name,
                    value: item.id,
                  })) ?? {}
                }
                isLoading={isOrgFetching}
                isDisabled={isOrgFetching}
                errors={errors}
              />
              <TextareaInput
                name={"description"}
                label={"Description"}
                register={{
                  ...register("description", {}),
                }}
                errors={errors}
                md="12"
              />
              <CheckboxInput
                name={"menu_data_ids"}
                label={"Menu"}
                register={{
                  ...register("menu_data_ids"),
                }}
                errors={errors}
                options={menuDataOptions}
                isLabelOnLeft={false}
                md="12"
              />
              <CheckboxInput
                name={"permissions"}
                label={"Permissions"}
                register={{
                  ...register("permissions"),
                }}
                errors={errors}
                options={permissionOptions}
                isLabelOnLeft={false}
                md="12"
              />
              <Col size="12">
                <Button
                  color="primary"
                  type="submit"
                  disabled={isCreateLoading || isUpdateLoading}
                >
                  {(isCreateLoading || isUpdateLoading) && (
                    <Spinner type="grow" size="sm" />
                  )}
                  <span>{state.modal.add ? "Add" : "Edit"}</span>
                </Button>
              </Col>
            </Row>
          </form>
        </ModalComponent>
      </Content>
    </>
  );
};

export default Role;
