import React, { useEffect } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Spinner } from "reactstrap";
import { Block } from "../../components/Components";
import PageContainer from "../../layout/page-container/PageContainer";
import { useGetFileQuery } from "../../redux/api/media/mediaApi";

const MediaView = () => {
  let [searchParams] = useSearchParams();
  let navigate = useNavigate();
  const { data, isLoading, isSuccess, isError, error } = useGetFileQuery({
    key: searchParams.get("key"),
  });

  useEffect(() => {
    console.log(error);
    if (isSuccess) {
      window.location.replace(data);
      //   navigate(`../${data}`, { replace: true });
    }
  }, [isSuccess, isError]);

  return (
    <React.Fragment>
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body wide-xs">
          <div className="text-center">
            <Spinner />
          </div>
        </Block>
      </PageContainer>
    </React.Fragment>
  );
};

export default MediaView;
