import { useDispatch, useSelector } from "react-redux";
import { useState, useLayoutEffect, useEffect } from "react";
import ReactDataTable from "../../components/table/ReactDataTable";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
  Row,
  Col,
  ModalComponent,
  TextInput,
} from "../../components/Components";
import {
  onChangePageState,
  onSelectedRowsChange,
  onFormCancel,
  toggleModal,
} from "../../redux/features/dropdown-data/opportunityContractTypeSlice";
import {
  useGetOpportunityContractTypesQuery,
  useCreateOpportunityContractTypeMutation,
  useUpdateOpportunityContractTypeMutation,
  useDeleteOpportunityContractTypeMutation,
} from "../../redux/api/dropdown-data/dropdownDataApi";
import { opportunityContractTypeColumns } from "./DataColumns";
import { Modal, ModalBody, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const OpportunityContractTypes = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.opportunityContractType);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    control,
    reset,
  } = useForm({
    defaultValues: {
      type: "",
    },
  });
  const { data, isFetching, isSuccess, isError, error } =
    useGetOpportunityContractTypesQuery({
      offset: state.offset,
      limit: state.itemsPerPage,
      total: true,
      reverse: true,
    });
  const [
    createType,
    {
      isLoading: isCreateLoading,
      isError: isCreateError,
      isSuccess: isCreateSuccess,
      error: createError,
    },
  ] = useCreateOpportunityContractTypeMutation();
  const [
    updateType,
    {
      isLoading: isUpdateLoading,
      isError: isUpdateError,
      isSuccess: isUpdateSuccess,
      error: updateError,
    },
  ] = useUpdateOpportunityContractTypeMutation();
  const [
    deleteType,
    {
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
      isError: isDeleteError,
      error: deleteError,
    },
  ] = useDeleteOpportunityContractTypeMutation();
  const [tableData, setTableData] = useState(data?.types ?? []);
  const [totalItems, setTotalItems] = useState(data?.total ?? 0);

  const changePageState = ({ offset = 0, itemsPerPage }) => {
    dispatch(onChangePageState({ offset, itemsPerPage: itemsPerPage }));
  };

  useLayoutEffect(() => {
    if (data) {
      setTableData(data.types);
      setTotalItems(data.total);
    }
    if (isError && error) {
      if (error?.status === 404) {
        setTableData([]);
        setTotalItems(0);
      } else {
        toast.error(error?.data?.message);
      }
    }
  }, [data, isError]);

  const onFormSubmit = async (data) => {
    if (state.modal.add) {
      createType(data);
    } else if (state.modal.edit) {
      updateType({
        params: { id: state.modal.item.id },
        data: data,
      });
    }
  };

  useEffect(() => {
    if (isCreateSuccess) {
      dispatch(toggleModal({ type: "add" }));
      reset();
    }
    if (isCreateError && createError) {
      toast.error(createError?.data?.message);
    }
  }, [isCreateSuccess, isCreateError]);

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(toggleModal({ type: "edit" }));
      reset();
    }
    if (isUpdateError && updateError) {
      toast.error(updateError?.data?.message);
    }
  }, [isUpdateError, isUpdateSuccess]);

  const onAddBtnClicked = (e) => {
    dispatch(toggleModal({ type: "add" }));
  };

  const handleSelectedRowsChange = ({ selectedRows }) => {
    dispatch(onSelectedRowsChange(selectedRows));
  };

  const onRowClicked = (row, e) => {
    e.stopPropagation();
    const action = e.target.getAttribute("data-action");
    switch (action) {
      case "remove":
        //dispatch delete
        deleteType({ id: row.id });
        break;
      case "edit":
        dispatch(toggleModal({ type: "edit", item: row }));
        reset(
          {
            type: row.type,
          },
          {
            keepDefaultValues: true,
          }
        );
        break;
    }
  };

  return (
    <>
      <Head title="Opportunity Contract Types"></Head>
      <Content>
        <Block>
          <BlockHead>
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Opportunity Contract Types</BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <Button
                    className="toggle btn-icon d-md-none"
                    color="primary"
                    onClick={onAddBtnClicked}
                  >
                    <Icon name="plus"></Icon>
                  </Button>
                  <Button
                    className="toggle d-none d-md-inline-flex"
                    color="primary"
                    onClick={onAddBtnClicked}
                  >
                    <Icon name="plus"></Icon>
                    <span>Add Option</span>
                  </Button>
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <PreviewCard>
            <ReactDataTable
              data={tableData}
              columns={opportunityContractTypeColumns}
              offset={state.offset}
              itemsPerPage={state.itemsPerPage}
              pagination
              totalItems={totalItems}
              progressPending={isFetching}
              className="nk-tb-list"
              selectableRows
              onSelectedRowsChange={handleSelectedRowsChange}
              changePageState={changePageState}
              onRowClicked={onRowClicked}
            />
          </PreviewCard>
        </Block>

        <ModalComponent
          isOpen={state.modal.details}
          toggle={() => dispatch(toggleModal({ type: "details" }))}
          title={"Quote Details"}
          onModalCancel={() => {
            dispatch(onFormCancel());
          }}
        >
          {state.modal.item && (
            <Row className="gy-3">
              <Col lg={6}>
                <span className="sub-text">Quote Id</span>
                <span className="caption-text">{state.modal.item.id}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Quote</span>
                <span className="caption-text">{state.modal.item.quote}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Author</span>
                <span className="caption-text">{state.modal.item.author}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Created Time</span>
                <span className="caption-text">
                  {state.modal.item.created_time}
                </span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Last Updated Time</span>
                <span className="caption-text">
                  {state.modal.item.last_updated_time}
                </span>
              </Col>
            </Row>
          )}
        </ModalComponent>

        <ModalComponent
          isOpen={state.modal.add || state.modal.edit}
          backdrop={"static"}
          title={`${state.modal.add ? "Add" : "Edit"} Option`}
          onModalCancel={() => {
            dispatch(onFormCancel());
            reset();
          }}
        >
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Row className="g-3">
              <TextInput
                name={"type"}
                label={"Type"}
                register={{
                  ...register("type", {
                    required: "This field is required",
                  }),
                }}
                errors={errors}
                md="12"
              />
              <Col size="12">
                <Button
                  color="primary"
                  type="submit"
                  disabled={isCreateLoading || isUpdateLoading}
                >
                  {(isCreateLoading || isUpdateLoading) && (
                    <Spinner type="grow" size="sm" />
                  )}
                  <span>{state.modal.add ? "Add" : "Edit"}</span>
                </Button>
              </Col>
            </Row>
          </form>
        </ModalComponent>
      </Content>
    </>
  );
};

export default OpportunityContractTypes;
