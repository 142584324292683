import React, { useEffect, useLayoutEffect, useState } from "react";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockHead,
  BlockContent,
  BlockTitle,
  BlockDes,
  PreviewCard,
  Button,
  Icon,
} from "../../components/Components";
// import Logo from "../../images/logo.png";
// import LogoDark from "../../images/logo-dark.png";
// import Logo from "../../images/Vector.png";
import Logo from "../../images/logo-inclus.png";
import AuthFooter from "./AuthFooter";
import { Form, FormGroup, Spinner, Alert } from "reactstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useLoginMutation } from "../../redux/api/authApi";

const LoginPage = () => {
  const location = useLocation();
  const [passState, setPassState] = useState(false);
  const [errorVal, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login, { isLoading }] = useLoginMutation();
  const accessToken = localStorage.getItem("accessToken");

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (accessToken) {
      navigate("/", { replace: true });
    }
  }, []);

  const onSubmit = async (data) => {
    try {
      const response = await login(data).unwrap();
      localStorage.setItem("accessToken", response.data[0].token);
      if (location.state && location.state.previousPathName) {
        var user = response.data[0].user;
        if (user.role_name !== "ADMIN") {
          if (
            !user.menu_data_list.find(
              (menu) => menu.menu_url === location.state.previousPathName
            )
          ) {
            navigate("/", { replace: true });
            return;
          }
        }

        navigate(location.state.previousPathName, { replace: true });
      } else {
        navigate("/", { replace: true });
      }
    } catch (err) {
      if (err.data) {
        setError(err.data.message);
      } else {
        setError(JSON.stringify(err));
      }
    }
  };

  return (
    <React.Fragment>
      <Head title="Login" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={`${window.location.pathname}`} className="logo-link">
              <img
                className="logo-dark logo-img logo-img-xl"
                src={Logo}
                alt="logo"
              />
              {/* <img
                className="logo-dark logo-img logo-img-lg"
                src={LogoDark}
                alt="logo-dark"
              /> */}
            </Link>
          </div>

          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">Sign-In</BlockTitle>
                <BlockDes>
                  <p>Access Inclus using your email and password.</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            {errorVal && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  <Icon name="alert-circle" /> {errorVal}
                </Alert>
              </div>
            )}
            <Form className="is-alter" onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Email
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="default-01"
                    tabIndex={"1"}
                    placeholder="Enter your email address"
                    className="form-control-lg form-control"
                    {...register("email", {
                      required: "This field is required",
                    })}
                  />
                  {errors && errors.email && (
                    <span className="invalid">{errors.email.message}</span>
                  )}
                </div>
              </FormGroup>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="password">
                    Password
                  </label>
                  <Link
                    className="link link-primary link-sm"
                    to={`${process.env.PUBLIC_URL}/auth-reset`}
                  >
                    Forgot Password?
                  </Link>
                </div>
                <div className="form-control-wrap">
                  <a
                    href="#password"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setPassState(!passState);
                    }}
                    className={`form-icon lg form-icon-right passcode-switch ${
                      passState ? "is-hidden" : "is-shown"
                    }`}
                  >
                    <Icon name="eye" className="passcode-icon icon-show"></Icon>
                    <Icon
                      name="eye-off"
                      className="passcode-icon icon-hide"
                    ></Icon>
                  </a>
                  <input
                    type={passState ? "text" : "password"}
                    id="password"
                    tabIndex={"2"}
                    {...register("password", {
                      required: "This field is required",
                    })}
                    placeholder="Enter your password"
                    className={`form-control-lg form-control ${
                      passState ? "is-hidden" : "is-shown"
                    }`}
                  />
                  {errors && errors.password && (
                    <span className="invalid">{errors.password.message}</span>
                  )}
                </div>
              </FormGroup>
              <FormGroup>
                <Button
                  size="lg"
                  className="btn-block"
                  type="submit"
                  color="primary"
                  disabled={isLoading || !isValid}
                >
                  {isLoading ? <Spinner size="sm" color="light" /> : "Sign in"}
                </Button>
              </FormGroup>
            </Form>
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};

export default LoginPage;
