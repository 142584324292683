import { Col, MenuPortal } from "../Components";
import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import { useRef, useState } from "react";

export const TimePickerGroupInput = ({
  nameStart,
  nameEnd,
  label,
  errors,
  required,
  md,
  ...props
}) => {
  const { control, ...innerProps } = props;
  return (
    <Col md={md ?? "6"}>
      <div className="form-group" style={label ? null : { display: "flex" }}>
        <label className="form-label">
          {label} {required && <span className="required-asterisk">*</span>}
        </label>
        <div
          className="form-control-wrap time-range-wrapper"
          style={{ flexBasis: "100%" }}
        >
          <Controller
            name={nameStart}
            rules={required ? { required: "These field are required" } : null}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { isTouched, isDirty, error },
              formState,
            }) => (
              <DatePicker
                className="form-control"
                onChange={onChange}
                selected={value}
                {...innerProps}
              />
            )}
            control={control}
            // {...props}
          />
          <div className="mx-1">-</div>
          <Controller
            name={nameEnd}
            rules={required ? { required: "These field are required" } : null}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { isTouched, isDirty, error },
              formState,
            }) => (
              <DatePicker
                className="form-control"
                onChange={onChange}
                selected={value}
                {...innerProps}
              />
            )}
            control={control}
          />
        </div>
        {errors && errors[nameStart] && (
          <span className="invalid">{errors[nameStart].message}</span>
        )}
        {errors &&
          errors[nameEnd] &&
          (!errors[nameStart] ||
            errors[nameStart]?.message != errors[nameEnd]?.message) && (
            <span className="invalid">{errors[nameEnd].message}</span>
          )}
      </div>
    </Col>
  );
};

export const TimePickerInput = ({
  name,
  label,
  errors,
  required,
  md,
  placeholder,
  dateFormat,
  timeFormat,
  portalId,
  ...props
}) => {
  const dRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Col md={md ?? "6"}>
      <div className="form-group">
        {label && (
          <label className="form-label" htmlFor={label}>
            {label}{" "}
            {props.rules && props.rules.required && (
              <span className="required-asterisk">*</span>
            )}
          </label>
        )}
        <div className="form-control-wrap time-range-wrapper" ref={dRef}>
          <MenuPortal
            isOpen={isOpen}
            controlElement={dRef.current}
            portalId={portalId}
          />
          <Controller
            name={name}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { isTouched, isDirty, error },
              formState,
            }) => (
              <DatePicker
                className="form-control"
                onChange={onChange}
                selected={value}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeFormat={timeFormat}
                timeCaption="Times"
                dateFormat={dateFormat ?? "h:mm aa"}
                placeholderText={placeholder}
                open={isOpen}
                onFocus={() => setIsOpen(true)}
                onInputClick={() => setIsOpen(true)}
                onBlur={() => setIsOpen(false)}
                onClickOutside={() => setIsOpen(false)}
                onSelect={() => setIsOpen(false)}
                portalId={portalId}
              />
            )}
            {...props}
          />
        </div>
        {errors && errors[name] && (
          <span className="invalid">{errors[name].message}</span>
        )}
      </div>
    </Col>
  );
};
