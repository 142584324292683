import { api } from "../api";

export const mediaApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getFile: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/files",
          params: params,
        };
      },
    }),
  }),
});

export const { useGetFileQuery } = mediaApi;
