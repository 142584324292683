import React, { useState } from "react";
import { Icon, Button } from "../Components";
import { set } from "react-hook-form";

const NSComponent = ({
  name,
  register,
  max,
  min,
  step,
  outline,
  color,
  defaultVal,
  setValue,
  ...props
}) => {
  const [val, setVal] = useState(defaultVal);
  const addVal = (n) => {
    if (val !== max) {
      if (step) {
        n = step;
      }
      setVal(val + n);
      setValue(name, val + n);
    }
  };
  const reduceVal = (n) => {
    if (val > 0 && val !== min) {
      if (step) {
        n = step;
      }
      setVal(val - n);
      setValue(name, val - n);
    }
  };
  return (
    <div className="form-control-wrap number-spinner-wrap">
      {" "}
      <Button
        outline={outline ? true : false}
        color={color}
        disabled={val === min ? true : false}
        className="btn-icon number-spinner-btn number-minus"
        onClick={(ev) => {
          ev.preventDefault();
          reduceVal(1);
        }}
      >
        <Icon name="minus"></Icon>
      </Button>{" "}
      <input
        name={name}
        {...register}
        type="number"
        className="form-control number-spinner"
        value={val}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            e.target.blur();
          }
        }}
        onChange={(e) => {
          let { onChange } = { ...register };
          onChange(e);
          setVal(e.target.value);
          setValue(name, e.target.value);
        }}
        max={max}
        min={min}
      />{" "}
      <Button
        outline={outline ? true : false}
        color={color}
        disabled={val === max ? true : false}
        className="btn-icon number-spinner-btn number-plus"
        onClick={(ev) => {
          ev.preventDefault();
          addVal(1);
        }}
      >
        <Icon name="plus"></Icon>
      </Button>{" "}
    </div>
  );
};

export default NSComponent;
