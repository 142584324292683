import React, { useEffect } from "react";
import Icon from "../icon/Icon";
import {
  Pagination,
  PaginationLink,
  PaginationItem,
  Row,
  Col,
} from "reactstrap";

const DataTablePagination = ({
  itemPerPage,
  totalItems,
  paginate,
  currentPage,
  onChangeRowsPerPage,
  customItemPerPage,
  setRowsPerPage,
  setCurrentPage,
  changePageState,
}) => {
  useEffect(() => {});

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemPerPage); i++) {
    pageNumbers.push(i);
  }

  const nextPage = () => {
    paginate(currentPage + 1);
    setCurrentPage(currentPage + 1);
    changePageState({
      offset: currentPage * itemPerPage,
      itemsPerPage: itemPerPage,
    });
  };

  const prevPage = () => {
    paginate(currentPage - 1);
    setCurrentPage(currentPage - 1);
    changePageState({
      offset: (currentPage - 2) * itemPerPage,
      itemsPerPage: itemPerPage,
    });
  };

  useEffect(() => {
    onChangeRowsPerPage(customItemPerPage);
    // setRowsPerPage(customItemPerPage);
  }, [customItemPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const onPageClick = (item) => {
    paginate(item);
    setCurrentPage(item);
    changePageState({
      offset: (item - 1) * itemPerPage,
      itemsPerPage: itemPerPage,
    });
  };

  let firstItemIndex = (currentPage - 1) * itemPerPage + 1;
  let lastItemIndex = currentPage * itemPerPage;
  if (lastItemIndex > totalItems) {
    lastItemIndex = totalItems;
  }

  const PaginationItems = () => {
    const items = [];
    let pageCheck = false;

    pageNumbers.forEach((item, index) => {
      const paginationItem = (
        <PaginationItem
          className={`d-none d-sm-block ${
            currentPage === item ? "active" : ""
          }`}
          key={item}
        >
          <PaginationLink
            tag="a"
            href="#"
            onClick={(ev) => {
              ev.preventDefault();
              onPageClick(item);
            }}
          >
            {item}
          </PaginationLink>
        </PaginationItem>
      );
      if (
        index === 0 ||
        index === pageNumbers.length - 1 ||
        (currentPage - 2 <= item && item <= currentPage + 2)
      ) {
        items.push(paginationItem);
        pageCheck = false;
        return;
      }

      if (!pageCheck) {
        pageCheck = true;
        items.push(
          <PaginationItem className={"d-none d-sm-block"} key={item}>
            <PaginationLink
              tag="a"
              href="#"
              onClick={(ev) => {
                ev.preventDefault();
              }}
            >
              ...
            </PaginationLink>
          </PaginationItem>
        );
        return;
      }
    });

    return items;
  };

  return (
    <Row className="align-items-center">
      <Col className="col-7" sm="12" md="9">
        <Pagination aria-label="Page navigation example">
          <PaginationItem disabled={currentPage - 1 === 0 ? true : false}>
            <PaginationLink
              className="page-link-prev"
              onClick={(ev) => {
                ev.preventDefault();
                prevPage();
              }}
              href="#prev"
            >
              <Icon name="chevrons-left" />
              <span>Prev</span>
            </PaginationLink>
          </PaginationItem>
          <PaginationItems />
          {/* {pageNumbers.map((item, index) => {
            return (
              <PaginationItem
                className={`d-none d-sm-block ${
                  currentPage === item ? "active" : ""
                }`}
                key={item}
              >
                <PaginationLink
                  tag="a"
                  href="#pageitem"
                  onClick={(ev) => {
                    ev.preventDefault();
                    onPageClick(item);
                  }}
                >
                  {item}
                </PaginationLink>
              </PaginationItem>
            );
          })} */}

          <PaginationItem
            disabled={pageNumbers[pageNumbers.length - 1] === currentPage}
          >
            <PaginationLink
              className="page-link-next"
              onClick={(ev) => {
                ev.preventDefault();
                nextPage();
              }}
              href="#next"
            >
              <span>Next</span>
              <Icon name="chevrons-right" />
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      </Col>
      <Col sm="12" md="3" className="col-5 text-left text-md-right">
        <div
          className="dataTables_info"
          id="DataTables_Table_2_info"
          role="status"
          aria-live="polite"
        >
          {firstItemIndex} - {lastItemIndex} of {totalItems}
        </div>
      </Col>
    </Row>
  );
};

export default DataTablePagination;
