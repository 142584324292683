import { api } from "../api";

export const supportApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAttendances: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/attendance",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          attendances: response.data,
          total: response.total,
        };
      },
      providesTags: ["Attendances"],
    }),
    createAttendances: builder.mutation({
      query: (data) => ({
        url: "admin/v1/attendance",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Attendances"],
    }),
    updateAttendances: builder.mutation({
      query: ({ params, data }) => {
        return {
          url: "admin/v1/attendance",
          method: "PUT",
          params: params,
          body: data,
        };
      },
      invalidatesTags: ["Attendances"],
    }),
    deleteAttendances: builder.mutation({
      query: (data) => ({
        url: "admin/v1/attendance",
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["Attendances"],
    }),
    getSupportMetrics: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/support-metrics",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          metrics: response.data,
          total: response.total,
        };
      },
      providesTags: (result, error, params) => [
        { type: "Support-Metrics", id: params.type },
      ],
    }),
    createSupportMetrics: builder.mutation({
      query: (data) => ({
        url: "admin/v1/support-metrics",
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, error, data) => [
        { type: "Support-Metrics", id: data.type },
      ],
    }),
    updateSupportMetrics: builder.mutation({
      query: ({ params, data }) => {
        return {
          url: "admin/v1/support-metrics",
          method: "PUT",
          params: params,
          body: data,
        };
      },
      invalidatesTags: (result, error, data) => [
        { type: "Support-Metrics", id: data.type },
      ],
    }),
    deleteSupportMetrics: builder.mutation({
      query: (params) => ({
        url: "admin/v1/support-metrics",
        method: "DELETE",
        params: params,
      }),
      invalidatesTags: (result, error, params) => [
        { type: "Support-Metrics", id: params.type },
      ],
    }),
    getObservationGrading: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/observation-grading",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          observationGrading: response.data,
          total: response.total,
        };
      },
      providesTags: ["ObservationGrading"],
    }),
    getSupportLogs: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/support-log",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          supportLogs: response.data,
          total: response.total,
        };
      },
      providesTags: ["Support-Logs"],
    }),
    createSupportLogs: builder.mutation({
      query: (data) => ({
        url: "admin/v1/support-log",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Support-Logs"],
    }),
    updateSupportLogs: builder.mutation({
      query: ({ params, data }) => {
        return {
          url: "admin/v1/support-log",
          method: "PUT",
          params: params,
          body: data,
        };
      },
      invalidatesTags: ["Support-Logs"],
    }),
    deleteSupportLogs: builder.mutation({
      query: (data) => ({
        url: "admin/v1/support-log",
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["Support-Logs"],
    }),
    getLocationTrackings: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/location",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          locationTrackings: response.data,
          total: response.total,
        };
      },
      providesTags: ["Location-Trackings"],
    }),
  }),
});

export const {
  useGetAttendancesQuery,
  useCreateAttendancesMutation,
  useDeleteAttendancesMutation,
  useUpdateAttendancesMutation,
  useGetSupportMetricsQuery,
  useLazyGetSupportMetricsQuery,
  useCreateSupportMetricsMutation,
  useUpdateSupportMetricsMutation,
  useDeleteSupportMetricsMutation,
  useGetSupportLogsQuery,
  useCreateSupportLogsMutation,
  useUpdateSupportLogsMutation,
  useDeleteSupportLogsMutation,
  useGetLocationTrackingsQuery,
  useGetObservationGradingQuery,
  useLazyGetObservationGradingQuery,
} = supportApi;
