import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  offset: 0,
  itemsPerPage: 10,
  modal: {
    add: false,
    details: false,
    delete: false,
    item: null,
  },
  selectedRows: null,
};

export const orgSupportingDocTypeSlice = createSlice({
  name: "orgSupportingDocType",
  initialState: initialState,
  reducers: {
    onChangePageState(state, action) {
      const { offset, itemsPerPage } = action.payload;
      return {
        ...state,
        offset,
        itemsPerPage,
      };
    },
    onSelectedRowsChange(state, action) {
      const selectedRows = action.payload;
      return { ...state, selectedRows: selectedRows };
    },
    onFormCancel(state, action) {
      return {
        ...state,
        modal: initialState.modal,
      };
    },
    toggleModal(state, action) {
      const { type, item } = action.payload;
      state.modal[type] = !state.modal[type];
      state.modal.item = item;
    },
  },
});

export const {
  onChangePageState,
  onFormCancel,
  toggleModal,
  onSelectedRowsChange,
} = orgSupportingDocTypeSlice.actions;

export default orgSupportingDocTypeSlice.reducer;
