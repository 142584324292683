import { useDispatch, useSelector } from "react-redux";
import { useLayoutEffect, useState } from "react";
import {
  onChangePageState,
  toggleModal,
  onFormCancel,
} from "../../redux/features/locationTrackingSlice";
import { useGetLocationTrackingsQuery } from "../../redux/api/support-management/supportApi";
import ReactDataTable from "../../components/table/ReactDataTable";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  Row,
  Col,
  ModalComponent,
} from "../../components/Components";
import { locationTrackingColumns, supportLogsColumns } from "./DataColumns";
import moment from "moment";

const LocationTracking = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.locationTrackings);
  const { data, isFetching, isSuccess } = useGetLocationTrackingsQuery({
    offset: state.offset,
    limit: state.itemsPerPage,
    total: true,
    reverse: true,
  });
  const [tableData, setTableData] = useState(data?.locationTrackings ?? []);
  const [totalItems, setTotalItems] = useState(data?.total ?? 0);

  const changePageState = ({ offset = 0, itemsPerPage }) => {
    dispatch(onChangePageState({ offset, itemsPerPage: itemsPerPage }));
  };

  useLayoutEffect(() => {
    if (data) {
      setTableData(data.locationTrackings);
      setTotalItems(data.total);
    }
  }, [data]);

  const onAddBtnClicked = (e) => {
    dispatch(toggleModal({ type: "add" }));
  };

  const onRowClicked = (row, e) => {
    e.stopPropagation();
    const action = e.target.getAttribute("data-action");
    switch (action) {
      case "remove":
        //dispatch delete
        break;
      case "details":
        dispatch(toggleModal({ type: "details", item: row }));
        break;
      case "open-map":
        window.open(`https://maps.google.com?q=${row.coordinates?.latitude},${row.coordinates?.longitude}`, '_blank', 'noopener,noreferrer');
        break;
    }
  };
  return (
    <>
      <Head title="Location Tracking" />
      <Content>
        <Block>
          <BlockHead>
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Location Tracking</BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <PreviewCard>
            <ReactDataTable
              data={tableData}
              columns={locationTrackingColumns}
              offset={state.offset}
              itemsPerPage={state.itemsPerPage}
              pagination
              totalItems={totalItems}
              progressPending={isFetching}
              className="nk-tb-list"
              selectableRows
              changePageState={changePageState}
              onRowClicked={onRowClicked}
            />
          </PreviewCard>
        </Block>

        <ModalComponent
          isOpen={state.modal.details}
          toggle={() => dispatch(toggleModal({ type: "details" }))}
          title={"Location Tracking Details"}
          onModalCancel={() => {
            dispatch(onFormCancel());
          }}
        >
          {state.modal.item && (
            <Row className="gy-3">
              <Col lg={6}>
                <span className="sub-text">UUID</span>
                <span className="caption-text">{state.modal.item.uuid}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Email</span>
                <span className="caption-text">{state.modal.item.email}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Latitude</span>
                <span className="caption-text">
                  {state.modal.item.coordinates?.latitude}
                </span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Longitude</span>
                <span className="caption-text">
                  {state.modal.item.coordinates?.longitude}
                </span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Time</span>
                <span className="caption-text">
                  {moment(state.modal.item.timestamp).format("DD-MM-YYYY hh:mm:ss a")}
                </span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Activity</span>
                <span className="caption-text">
                  {`${state.modal.item.activity?.type} (${state.modal.item.activity?.confidence}%)`}
                </span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Accuracy</span>
                <span className="caption-text">
                  {`${state.modal.item.coordinates?.accuracy} (meters)`}
                </span>
              </Col>
            </Row>
          )}
        </ModalComponent>
      </Content>
    </>
  );
};
export default LocationTracking;
