import React, { useState, useEffect } from "react";
import classNames from "classnames";
import Head from "./head/Head";
import Sidebar from "./sidebar/Sidebar";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import Pages from "../route/Index";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import Error404Modern from "../pages/error/404-modern";
import { useSelector } from "react-redux";

const Layout = () => {
  // const location = useLocation();
  // const { user } = useSelector((state) => state.auth);
  const [visibility, setVisibility] = useState(false);
  const [themeState] = useState({
    header: "white",
    sidebar: "white",
    skin: "light",
  });

  useEffect(() => {
    document.body.className = `nk-body bg-lighter npc-default has-sidebar no-touch nk-nio-theme ${
      themeState.skin === "dark" ? "dark-mode" : ""
    }`;
  }, [themeState.skin]);

  const toggleSidebar = (e) => {
    e.preventDefault();
    if (visibility === false) {
      setVisibility(true);
    } else {
      setVisibility(false);
    }
  };

  const sidebarClass = classNames({
    // "nk-sidebar-mobile": mobileView,
    // "nk-sidebar-active": visibility && mobileView,
    "nk-sidebar-active": visibility,
  });

  // if (!user) {
  //   return <Navigate to="/login" replace />;
  // }

  // if (location.state && location.state.is404) {
  //   return <Error404Modern />;
  // }

  return (
    <React.Fragment>
      <Head title="Loading" />
      <div className="nk-app-root">
        <div className="nk-main">
          <Sidebar
            sidebarToggle={toggleSidebar}
            fixed
            className={sidebarClass}
            theme={themeState.sidebar}
          />
          <div className="nk-wrap">
            <Header fixed theme={themeState.header} />
            {/* <Pages /> */}
            <Outlet />
            <Footer />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
