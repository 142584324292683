import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useContext } from "react";
import Head from "../../layout/head/Head";
import ContentAlt from "../../layout/content/ContentAlt";
import {
  Button,
  Col,
  Icon,
  ModalComponent,
  Row,
  TextInput,
  UserAvatar,
} from "../../components/Components";
import { ChatContext } from "./ChatContext";
import { ChatBody2 } from "./ChatBody";
import { useForm } from "react-hook-form";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import { ChannelAsideBody, ChatAsideBody2 } from "./ChatAsideBody";
import AppContact from "./contact/Contact";
import {
  onCreateOneToOneChat,
  updateChatState,
} from "../../redux/features/chat/chatSlice";
import { useAddContactMutation } from "../../redux/api/chat/chatApi";
import { findUpper } from "../../utils/utils";
import { useLazySearchUsersQuery } from "../../redux/api/users/usersApi";
import classNames from "classnames";
import { toast } from "react-toastify";
import moment from "moment";

const Chat = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.users);
  const [mainTab, setMainTab] = useState("Chats");
  const [selectedId, setSelectedId] = useState(null);
  const [filterTab, setFilterTab] = useState("messages");
  const [filteredChatList, setFilteredChatList] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [favState, setFavState] = useState(false);
  const [favFilter, setFavFilter] = useState([]);
  const [favFilterText, setFavFilterText] = useState("");
  const [mobileView, setMobileView] = useState(false);
  const [newOption, setNewOption] = useState(null);
  const [isShowGroupNameInput, setIsShowGroupNameInput] = useState(false);
  const [newChatUserList, setNewChatUserList] = useState([]);
  const [
    searchUsers,
    {
      data: searchedData,
      isUninitialized: isSearchUninitialized,
      isSuccess: isSearchSuccess,
      isFetching: isSearchFetching,
      isError: isSearchError,
    },
  ] = useLazySearchUsersQuery();
  const {
    register,
    handleSubmit: handleNewChatSubmit,
    formState: { errors },
    getValues,
    setValue,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      new_chat_selected_users: [],
    },
  });
  const watchNewChatSelectedUsers = watch("new_chat_selected_users");
  const {
    register: registerContactForm,
    handleSubmit: handleSubmitContactForm,
    reset: resetContactForm,
    formState: { errors: errorsContactForm },
  } = useForm();
  //   const { chatState, fav } = useContext(ChatContext);
  const {
    chatState,
    handleJoinChannel,
    createChatGroup,
    createOneToOneChat,
    userID,
  } = useContext(ChatContext);
  const [
    addChatContact,
    {
      data: addChatContactData,
      isLoading: isAddChatContactLoading,
      isError: isAddChatContactError,
      isSuccess: isAddChatContactSuccess,
      error: addChatContactError,
    },
  ] = useAddContactMutation();

  const [chat, setChat] = chatState;
  //   const [chat, setChat] = chatState;
  //   const [favData] = fav;

  // useEffect(() => {
  //   if (convList) {
  //     if (filterText !== "") {
  //       const filteredObject = convList.filter((item) => {
  //         return item.name.toLowerCase().includes(filterText.toLowerCase());
  //       });
  //       setFilteredChatList([...filteredObject]);
  //     } else {
  //       setFilteredChatList([...convList]);
  //     }
  //   }
  // }, [filterText, setFilteredChatList, convList]);

  useEffect(() => {
    if (chat.channels?.length > 0) {
      let channels = chat.channels?.filter((channel) => {
        if (channel.memberCount > 2) {
          return true;
        }
        return channel.lastMessage
      });

      if (filterText !== "") {
        //TODO: filter by name/group name
      } else {
        setFilteredChatList(channels);
      }
    }
  }, [chat.channels]);

  useEffect(() => {
    if (isAddChatContactSuccess) {
      dispatch(
        updateChatState({
          contacts: addChatContactData.contacts,
          contactIds: addChatContactData.contactIds,
        })
      );
      setNewOption(null);
      reset();
    }
  }, [isAddChatContactSuccess, reset]);

  // Filtering favourite users by search
  //   useEffect(() => {
  //     if (favFilterText !== "") {
  //       const filteredObject = favData.filter((item) => {
  //         return (
  //           item.name.toLowerCase().includes(favFilterText.toLowerCase()) &&
  //           item.fav === false
  //         );
  //       });
  //       setFavFilter([...filteredObject]);
  //     } else {
  //       setFavFilter([]);
  //     }
  //   }, [favFilterText, favData]);

  useEffect(() => {
    if (isSearchFetching) {
      return;
    }
    if (searchedData) {
      setNewChatUserList([...searchedData]);
    } else {
      setNewChatUserList([]);
    }
  }, [searchedData, isSearchFetching, setNewChatUserList]);

  const onNewChatCancel = () => {
    setNewOption(null);
    setNewChatUserList([]);
    setIsShowGroupNameInput(false);
    reset();
  };

  const onNewChatRemoveSelectedItem = (item) => {
    let selectedList = getValues("new_chat_selected_users");
    selectedList = selectedList.filter(
      (user) => user.sendbird_user_id !== item.sendbird_user_id
    );
    setValue("new_chat_selected_users", [...selectedList]);
  };

  const onInputChange = (e) => {
    setFilterText(e.target.value);
  };

  const onNewOptionClick = (ev) => {
    ev.preventDefault();
    if (mainTab === "Chats") {
      // if (contacts) {
      //   let lst = [];
      //   for (let i = 0; i < contacts.length; i++) {
      //     lst.push(...contacts[i].contacts);
      //   }
      //   setNewChatUserList([...lst]);
      // }
    }
    setNewOption(mainTab);
  };

  const onNewChatSearchChange = (e) => {
    //TODO: search user from api
    let text = e.target.value;
    if (text !== "" && text.length >= 3) {
      searchUsers({ search_text: e.target.value }, true);
    }
  };

  const onNewChatUserSelect = (user) => {
    let selectedList = getValues("new_chat_selected_users");

    let isExists = false;
    for (let i = 0; i < selectedList.length; i++) {
      if (selectedList[i].sendbird_user_id === user.sendbird_user_id) {
        isExists = true;
        selectedList.splice(i, 1);
        setValue("new_chat_selected_users", [...selectedList]);
        break;
      }
    }
    if (!isExists) {
      setValue("new_chat_selected_users", [...selectedList, user]);
    }

    // if (isSelected) {
    //   console.log("user_id", user_id);
    //   setValue("new_chat_selected_users", [
    //     ...getValues("new_chat_selected_users"),
    //     user_id,
    //   ]);
    // } else {
    //   setValue(
    //     "new_chat_selected_users",
    //     getValues("new_chat_selected_users").filter((item) => item !== user_id)
    //   );
    // }
  };

  useEffect(() => {
    if (watchNewChatSelectedUsers.length > 1) {
      setIsShowGroupNameInput(true);
    } else {
      setIsShowGroupNameInput(false);
    }
  }, [watchNewChatSelectedUsers]);

  const favInputSearchChange = (e) => {
    setFavFilterText(e.target.value);
  };

  const onFilterClick = (prop) => {
    setFilterTab(prop);
  };

  const chatItemClick = ({ channelURL }) => {
    if (window.innerWidth < 860) {
      setMobileView(true);
    }

    handleJoinChannel({
      channelURL: channelURL,
    });
  };

  const onFormNewChatSubmit = (data) => {
    // chatItemClickContext({ selectedID: data.user_id });
    // setNewOption(null);
    // reset();
    if (
      !data.new_chat_selected_users ||
      data.new_chat_selected_users.length === 0
    ) {
      toast.error("Please select at least one user");
      return;
    }

    if (data.new_chat_selected_users?.length > 1) {
      //TODO: create group chat
      let groupname = data.group_name;
      let members = [];
      members.push(...data.new_chat_selected_users);

      createChatGroup({
        groupname: groupname,
        users: members,
      });
    } else {
      createOneToOneChat({
        // recipientID: data.new_chat_selected_users[0]?.sendbird_user_id,
        user: data.new_chat_selected_users[0],
        ids: [data.new_chat_selected_users[0]?.sendbird_user_id],
      })
    }
    setNewOption(null);
    reset();
  };

  const onFormNewContactSubmit = (data) => {
    addChatContact({
      ownerEmail: state.user.email,
      friendEmail: data.email,
    });
  };

  return (
    <>
      <Head title="Users"></Head>
      <ContentAlt>
        <div className="nk-chat">
          <div className={`nk-chat-aside ${mobileView ? "has-aside" : ""}`}>
            <div className="nk-chat-aside-head">
              <div className="nk-chat-aside-user">
                <UncontrolledDropdown>
                  <DropdownToggle
                    tag="a"
                    className="dropdown-toggle dropdown-indicator"
                  >
                    <div className="title">{mainTab}</div>
                  </DropdownToggle>
                  <DropdownMenu>
                    <ul className="link-list-opt no-bdr">
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#contact"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setMainTab(
                              mainTab === "Chats" ? "Contact" : "Chats"
                            );
                          }}
                        >
                          <span>
                            {mainTab === "Chats" ? "Contact" : "Chats"}
                          </span>
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#contact"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setMainTab(
                              mainTab === "Chats"
                                ? "Channel"
                                : mainTab === "Channel"
                                  ? "Contact"
                                  : "Channel"
                            );
                          }}
                        >
                          <span>
                            {mainTab === "Chats"
                              ? "Channel"
                              : mainTab === "Channel"
                                ? "Contact"
                                : "Channel"}
                          </span>
                        </DropdownItem>
                      </li>
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              <ul className="nk-chat-aside-tools g-2">
                <React.Fragment>
                  <li>
                    <UncontrolledDropdown>
                      <DropdownToggle
                        tag="a"
                        className="btn btn-round btn-icon btn-light dropdown-toggle"
                      >
                        <Icon name="setting-alt-fill"></Icon>
                      </DropdownToggle>
                      <DropdownMenu right>
                        <ul className="link-list-opt no-bdr">
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#dropdown"
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              <span>Settings</span>
                            </DropdownItem>
                          </li>
                          <li className="divider"></li>
                          <li
                            onClick={() => onFilterClick("messages")}
                            className={filterTab === "messages" ? "active" : ""}
                          >
                            <DropdownItem
                              tag="a"
                              href="#dropdown"
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              <span>Messages</span>
                            </DropdownItem>
                          </li>
                          <li
                            onClick={() => onFilterClick("archive")}
                            className={filterTab === "archive" ? "active" : ""}
                          >
                            <DropdownItem
                              tag="a"
                              href="#dropdown"
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              <span>Archives Chats</span>
                            </DropdownItem>
                          </li>
                          <li
                            onClick={() => onFilterClick("unread")}
                            className={filterTab === "unread" ? "active" : ""}
                          >
                            <DropdownItem
                              tag="a"
                              href="#dropdown"
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              <span>Unread Chats</span>
                            </DropdownItem>
                          </li>
                          <li
                            onClick={() => onFilterClick("group")}
                            className={filterTab === "group" ? "active" : ""}
                          >
                            <DropdownItem
                              tag="a"
                              href="#dropdown"
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              <span>Group Chats</span>
                            </DropdownItem>
                          </li>
                        </ul>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </li>
                  <li>
                    <Button color="light" className="btn-round btn-icon">
                      <Icon
                        name="edit-alt-fill"
                        onClick={onNewOptionClick}
                      ></Icon>
                    </Button>
                  </li>
                </React.Fragment>
              </ul>
            </div>
            {mainTab === "Chats" ? (
              <ChatAsideBody2
                userID={userID}
                onInputChange={onInputChange}
                filteredChatList={filteredChatList}
                favState={favState}
                favFilter={favFilter}
                setFavFilter={setFavFilter}
                setFavState={setFavState}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                favInputSearchChange={favInputSearchChange}
                favFilterText={favFilterText}
                chatItemClick={chatItemClick}
                filterTab={filterTab}
                isSettingUpUser={chat.settingUpUser}
              />
            ) : mainTab === "Channel" ? (
              <ChannelAsideBody
                filteredChatList={filteredChatList}
                onInputChange={onInputChange}
                setSelectedId={setSelectedId}
                setMobileView={setMobileView}
                selectedId={selectedId}
                chatItemClick={chatItemClick}
                filterTab={filterTab}
              />
            ) : (
              <AppContact setTab={setMainTab} setSelectedId={setSelectedId} />
            )}
          </div>
          {chat.currentlyJoinedChannel ? (
            <ChatBody2
              // id={selectedId}
              // setSelectedId={setSelectedId}
              setMobileView={setMobileView}
              mobileView={mobileView}
            />
          ) : (
            <div className="nk-chat-body">
              <div className="nk-chat-blank">
                <div className="nk-chat-blank-icon">
                  <Icon
                    name="chat"
                    className="icon-circle icon-circle-xxl bg-white"
                  ></Icon>
                </div>
                <div className="nk-chat-blank-btn">
                  <Button
                    color="primary"
                    disabled={mainTab === "Contact"}
                    onClick={() => setMainTab("Contact")}
                  // onClick={() => setUpChatPresence()}
                  >
                    Start Chat
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </ContentAlt>

      <ModalComponent
        isOpen={newOption === "Chats"}
        toggle={onNewChatCancel}
        title={"New Chat"}
        backdrop={"static"}
        onModalCancel={onNewChatCancel}
      >
        <form onSubmit={handleNewChatSubmit(onFormNewChatSubmit)}>
          {isShowGroupNameInput && (
            <TextInput
              md={"12"}
              name={"group_name"}
              label={"Group name (Optional)"}
              register={{
                ...register("group_name", {}),
              }}
              errors={errors}
              outerClassName={"px-0 mb-4"}
            />
          )}
          <div className="nk-chat-aside-search px-0 mb-3">
            <div className="form-group">
              <div className="form-control-wrap">
                <div className="form-icon form-icon-left">
                  <Icon name="search"></Icon>
                </div>
                <Input
                  {...register("new_chat_search")}
                  type="text"
                  className="form-round"
                  id={`new-chat-search-input`}
                  placeholder="Search by email/name (min 3 characters)"
                  onChange={(e) => onNewChatSearchChange(e)}
                />
              </div>
            </div>
          </div>
          {watchNewChatSelectedUsers.length > 0 && (
            <div className="mb-2">
              {watchNewChatSelectedUsers.map((item, index) => {
                return (
                  <div
                    className={classNames({
                      "tag-item": true,
                      "ml-2": index !== 0,
                    })}
                    style={{
                      // backgroundColor: "rgb(218, 216, 216)",
                      backgroundColor: "#e4d8ff",
                      display: "inline-flex",
                      padding: ".25rem .35rem .25rem .7rem",
                      borderRadius: "20px",
                      alignItems: "center",
                    }}
                    key={`tag-selected-user-${item?.sendbird_user_id}`}
                  >
                    <span className="text">{item?.sendbird_nickname}</span>
                    <Icon
                      name="cross-circle-fill"
                      style={{
                        fontSize: "20px",
                        position: "relative",
                        top: "-2px",
                        color: "#854fff",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        onNewChatRemoveSelectedItem(item);
                      }}
                    />
                    {/* <span
                      className="close"
                      style={{
                        position: "relative",
                        top: "1.5px",
                        height: "20px",
                        width: "20px",
                        backgroundColor: "rgb(48, 48, 48)",
                        color: "#fff",
                        borderRadius: "50%",
                        display: "inline-flex",
                        justifyContent: "center",
                        // alignItems: "center",
                        marginLeft: ".5em",
                        fontSize: "18px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        onNewChatRemoveSelectedItem(item);
                      }}
                    >
                      &times;
                    </span> */}
                  </div>
                );
              })}
            </div>
          )}
          {isSearchFetching ? (
            <Row className="justify-content-center">
              <Spinner size="lg" color="primary" />
            </Row>
          ) : (
            <BuildNewChatUserList
              newChatuserList={newChatUserList}
              onNewChatUserSelect={onNewChatUserSelect}
              watchNewChatSelectedUsers={watchNewChatSelectedUsers}
            />
          )}

          <Row className="mx-0 mt-3 justify-content-end">
            <Button
              color="primary"
              type="submit"
            // disabled={isAddChatContactLoading}
            >
              {/* {isAddChatContactLoading && <Spinner type="grow" size="sm" />} */}
              <span>Create new chat</span>
            </Button>
          </Row>
        </form>
      </ModalComponent>

      <ModalComponent
        isOpen={newOption === "Contact"}
        toggle={() => setNewOption(null)}
        title={"New Contact"}
        onModalCancel={() => {
          setNewOption(null);
        }}
      >
        <form onSubmit={handleSubmitContactForm(onFormNewContactSubmit)}>
          <Row className="g-3">
            <TextInput
              name={"email"}
              label={"Email"}
              register={{
                ...registerContactForm("email", {
                  required: "This field is required",
                }),
              }}
              errors={errorsContactForm}
            />

            <Col size="12">
              <Button
                color="primary"
                type="submit"
                disabled={isAddChatContactLoading}
              >
                {isAddChatContactLoading && <Spinner type="grow" size="sm" />}
                <span>Add</span>
              </Button>
            </Col>
          </Row>
        </form>
      </ModalComponent>
    </>
  );
};

const BuildNewChatUserList = ({
  watchNewChatSelectedUsers,
  newChatuserList,
  onNewChatUserSelect,
}) => {
  return (
    <div>
      {newChatuserList?.length > 0 && <div className="mb-3">Suggested</div>}
      <ul>
        <Row className="g-3">
          {newChatuserList?.map((item, index) => {
            return (
              <Col md="6" key={`user-${index}`}>
                <li>
                  <div
                    className="custom-control custom-control-sm custom-checkbox custom-control-pro"
                    style={{
                      display: "block",
                    }}
                  >
                    <div
                      style={{
                        display: "block",
                        padding: "0.6875rem 1.125rem",
                        lineHeight: "1.25rem",
                        borderRadius: "4px",
                        fontSize: "13px",
                        backgroundColor: "#fff",
                        border: "1px solid #e5e9f2",
                        cursor: "pointer",
                        transition:
                          "color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                      }}
                      onClick={() => {
                        onNewChatUserSelect(item);
                      }}
                    >
                      <span className="user-card justify-content-between">
                        <div className="d-flex align-items-center">
                          <span className="user-avatar">
                            <UserAvatar
                              text={findUpper(item?.sendbird_nickname)}
                              className="chat-media"
                            />
                          </span>
                          <span className="user-info">
                            <span className="lead-text">{item?.sendbird_nickname}</span>
                            {/* <span className="sub-text">Senior Developer</span> */}
                          </span>
                        </div>
                        {watchNewChatSelectedUsers?.find(
                          (user) =>
                            user.sendbird_user_id === item.sendbird_user_id
                        ) ? (
                          <Icon
                            name="check"
                            style={{
                              fontSize: "20px",
                            }}
                          ></Icon>
                        ) : (
                          <Icon
                            name="plus"
                            style={{
                              fontSize: "20px",
                            }}
                          ></Icon>
                        )}
                      </span>
                    </div>
                  </div>
                </li>
              </Col>
            );
            // return item.contacts?.map((contact, index2) => (

            // ));
          })}
        </Row>
      </ul>
      {newChatuserList?.length === 0 && (
        <div className="d-flex justify-content-center my-3">No user found</div>
      )}
    </div>
  );
};

export default Chat;
