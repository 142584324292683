import { api } from "../api";

export const homeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getQuotes: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/quotes",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          quotes: response.data,
          total: response.total,
        };
      },
      providesTags: ["Quotes"],
    }),
    createQuote: builder.mutation({
      query: (data) => ({
        url: "admin/v1/quotes",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Quotes"],
    }),
    updateQuotes: builder.mutation({
      query: ({ params, data }) => {
        return {
          url: "admin/v1/quotes",
          method: "PUT",
          params: params,
          body: data,
        };
      },
      invalidatesTags: ["Quotes"],
    }),
    deleteQuotes: builder.mutation({
      query: (data) => ({
        url: "admin/v1/quotes",
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["Quotes"],
    }),
  }),
});

export const {
  useGetQuotesQuery,
  useCreateQuoteMutation,
  useUpdateQuotesMutation,
  useDeleteQuotesMutation,
} = homeApi;
