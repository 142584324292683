import { Col } from "../Components";
import TextareaAutosize from "react-textarea-autosize";

const TextareaInput = ({
  name,
  label,
  placeholder,
  caption,
  obscure,
  errors,
  onChangeFunc,
  md,
  ...props
}) => {
  return (
    <Col md={md ?? "6"} className={"form-group-wrapper"}>
      <div className="form-group">
        {label && (
          <label className="form-label">
            {label}{" "}
            {props.required && <span className="required-asterisk">*</span>}
          </label>
        )}
        {caption && <div className="form-caption">{caption}</div>}
        <div className="form-control-wrap">
          <TextareaAutosize
            className="no-resize form-control"
            placeholder={placeholder}
            name={name}
            {...props.register}
            minRows={props.minRows ?? 4}
            onChange={(e) => {
              let { onChange } = { ...props.register };
              onChange(e);
              if (onChangeFunc) {
                onChangeFunc();
              }
            }}
          />
          {errors && errors[name] && (
            <span className="invalid">{errors[name].message}</span>
          )}
        </div>
      </div>
    </Col>
  );
};

export default TextareaInput;

{
  /* <FormGroup>
                    <Label htmlFor="default-textarea" className="form-label">
                      Textarea
                    </Label>
                    <div className="form-control-wrap">
                      <textarea
                        className="no-resize form-control"
                        type="textarea"
                        id="default-textarea"
                        defaultValue="Large text area content"
                      />
                    </div>
                  </FormGroup> */
}
