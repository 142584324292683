import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import {
  Icon,
  Button,
  Block,
  BlockDes,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  TextareaInput,
  Col,
  Row,
  SelectField,
  ModalComponent,
  TextInput,
  DatePickerInput,
  PhoneNumberWithCountryCodeInput,
  CheckboxInput,
} from "../../components/Components";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  Progress,
  UncontrolledTooltip,
} from "reactstrap";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {
  useGetUserLogQuery,
  useLazyGetUserLogQuery,
  useGetUserQuery,
  useLazyGetPipelineConfigQuery,
  useUpdatePipelineStageMutation,
  useAddUserLogMutation,
  useEditUserLogMutation,
  useRemoveUserLogMutation,
  useGetUserPipelinesQuery,
  useGetUserAssessmentQuery,
  useEditUserMutation,
} from "../../redux/api/users/usersApi";
import React, {
  useEffect,
  useState,
  createContext,
  useRef,
  useContext,
  useCallback,
} from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Spinner } from "reactstrap";
import { toast } from "react-toastify";
import classNames from "classnames";
import DataTable from "react-data-table-component";
import { useInView } from "react-intersection-observer";
import {
  countryFlagsMap,
  logDirectionOptions,
  nioIconData,
  pipelineStatusOptions,
} from "../../constants";
import moment from "moment";
import SimpleBar from "simplebar-react";
import {
  capitalize,
  convertUserData,
  formatDuration,
  getLanguageOptions,
} from "../../utils/utils";
import { useDropzone } from "react-dropzone";
import {
  useFinalizeMultipartUploadMutation,
  useInitializeMultipartUploadMutation,
} from "../../redux/api/common/uploadApi";
import { Uploader } from "../../utils/httpUtility";
import { useDispatch } from "react-redux";
import { start } from "../../redux/features/upload/uploaderSlice";
import { FlagsSelectField } from "../../components/form/SelectField";
import {
  useLazyGetInteractionTypesQuery,
  useLazyGetReligionsQuery,
  useLazyGetSexesQuery,
} from "../../redux/api/dropdown-data/dropdownDataApi";

// const defaultThemes = {
//   default: {
//     text: {
//       primary: "rgba(0, 0, 0, 0.87)",
//       secondary: "rgba(0, 0, 0, 0.54)",
//       disabled: "rgba(0, 0, 0, 0.38)",
//     },
//     background: {
//       default: "#FFFFFF",
//     },
//     context: {
//       background: "#e3f2fd",
//       text: "rgba(0, 0, 0, 0.87)",
//     },
//     divider: {
//       default: "rgba(0,0,0,.12)",
//     },
//     button: {
//       default: "rgba(0,0,0,.54)",
//       focus: "rgba(0,0,0,.12)",
//       hover: "rgba(0,0,0,.12)",
//       disabled: "rgba(0, 0, 0, .18)",
//     },
//     sortFocus: {
//       default: "rgba(0, 0, 0, .54)",
//     },
//     selected: {
//       default: "#e3f2fd",
//       text: "rgba(0, 0, 0, 0.87)",
//     },
//     highlightOnHover: {
//       default: "#EEEEEE",
//       text: "rgba(0, 0, 0, 0.87)",
//     },
//     striped: {
//       default: "#FAFAFA",
//       text: "rgba(0, 0, 0, 0.87)",
//     },
//   },
//   dark: {
//     text: {
//       primary: "#FFFFFF",
//       secondary: "rgba(255, 255, 255, 0.7)",
//       disabled: "rgba(0,0,0,.12)",
//     },
//     background: {
//       default: "#424242",
//     },
//     context: {
//       background: "#E91E63",
//       text: "#FFFFFF",
//     },
//     divider: {
//       default: "rgba(81, 81, 81, 1)",
//     },
//     button: {
//       default: "#FFFFFF",
//       focus: "rgba(255, 255, 255, .54)",
//       hover: "rgba(255, 255, 255, .12)",
//       disabled: "rgba(255, 255, 255, .18)",
//     },
//     sortFocus: {
//       default: "rgba(255, 255, 255, .54)",
//     },
//     selected: {
//       default: "rgba(0, 0, 0, .7)",
//       text: "#FFFFFF",
//     },
//     highlightOnHover: {
//       default: "rgba(0, 0, 0, .7)",
//       text: "#FFFFFF",
//     },
//     striped: {
//       default: "rgba(0, 0, 0, .87)",
//       text: "#FFFFFF",
//     },
//   },
// };

// const customStyles = {
//   header: {
//     style: {
//       minHeight: "56px",
//     },
//   },
//   headRow: {
//     style: {
//       borderTopStyle: "solid",
//       borderTopWidth: "1px",
//       borderTopColor: defaultThemes.default.divider.default,
//     },
//   },
//   headCells: {
//     style: {
//       "&:not(:last-of-type)": {
//         borderRightStyle: "solid",
//         borderRightWidth: "1px",
//         borderRightColor: defaultThemes.default.divider.default,
//       },
//     },
//   },
//   cells: {
//     style: {
//       "&:not(:last-of-type)": {
//         borderRightStyle: "solid",
//         borderRightWidth: "1px",
//         borderRightColor: defaultThemes.default.divider.default,
//       },
//     },
//   },
// };

// const ExpandedComponent = ({ data }) => (
//   <div>
//     <Row className="mx-3 align-items-center">
//       <svg
//         className="w-80px"
//         xmlns="http://www.w3.org/2000/svg"
//         viewBox="0 0 72 72"
//       >
//         <path
//           fill="#f26b6b"
//           d="M50 61H22a6 6 0 01-6-6V22l9-11h25a6 6 0 016 6v38a6 6 0 01-6 6z"
//         ></path>
//         <path
//           fill="#f4c9c9"
//           d="M25 20.556A1.444 1.444 0 0123.556 22H16l9-11z"
//         ></path>
//         <path
//           fill="#fff"
//           d="M46.334 44.538a4.326 4.326 0 00-2.528-1.429 22.436 22.436 0 00-4.561-.383 19.356 19.356 0 01-3.425-4.772 56.508 56.508 0 001.375-6.086 2.339 2.339 0 00-.462-1.845 1.943 1.943 0 00-1.516-.753h-.001a1.685 1.685 0 00-1.39.697c-1.149 1.526-.363 5.219-.194 5.946a12.612 12.612 0 00.724 2.147 33.322 33.322 0 01-2.49 6.106 20.347 20.347 0 00-5.979 3.44 2.568 2.568 0 00-.886 1.827 1.802 1.802 0 00.634 1.306 2.061 2.061 0 001.395.531 2.244 2.244 0 001.459-.546 20.068 20.068 0 004.29-5.357 20.838 20.838 0 015.938-1.186 33.75 33.75 0 004.243 3.605 2.64 2.64 0 003.416-.236 2.08 2.08 0 00-.042-3.012zM27.62 49.623a.834.834 0 01-1.084.042.42.42 0 01-.167-.27c-.002-.066.027-.315.44-.736a18.038 18.038 0 013.762-2.368 17.26 17.26 0 01-2.95 3.332zm7.283-18.775a.343.343 0 01.315-.151.6.6 0 01.465.239.853.853 0 01.168.672c-.164.92-.424 2.38-.852 4.117l-.037-.151c-.356-1.523-.609-3.996-.059-4.726zm-1.179 12.703a34.973 34.973 0 001.52-3.767 21.248 21.248 0 002.224 3.05 21.857 21.857 0 00-3.744.717zm11.706 2.97a1.308 1.308 0 01-1.695.088 33.203 33.203 0 01-3.004-2.43 20.968 20.968 0 012.835.334 2.97 2.97 0 011.74.965c.533.633.296.87.123 1.043z"
//         ></path>
//       </svg>
//       <span className="mr-4">KKH disability report.pdf</span>
//       <span>Uploaded: 2022-08-31 15:47</span>
//     </Row>
//     <Row className="mx-3 align-items-center">
//       <svg
//         className="w-80px"
//         xmlns="http://www.w3.org/2000/svg"
//         viewBox="0 0 72 72"
//       >
//         <path
//           fill="#f26b6b"
//           d="M50 61H22a6 6 0 01-6-6V22l9-11h25a6 6 0 016 6v38a6 6 0 01-6 6z"
//         ></path>
//         <path
//           fill="#f4c9c9"
//           d="M25 20.556A1.444 1.444 0 0123.556 22H16l9-11z"
//         ></path>
//         <path
//           fill="#fff"
//           d="M46.334 44.538a4.326 4.326 0 00-2.528-1.429 22.436 22.436 0 00-4.561-.383 19.356 19.356 0 01-3.425-4.772 56.508 56.508 0 001.375-6.086 2.339 2.339 0 00-.462-1.845 1.943 1.943 0 00-1.516-.753h-.001a1.685 1.685 0 00-1.39.697c-1.149 1.526-.363 5.219-.194 5.946a12.612 12.612 0 00.724 2.147 33.322 33.322 0 01-2.49 6.106 20.347 20.347 0 00-5.979 3.44 2.568 2.568 0 00-.886 1.827 1.802 1.802 0 00.634 1.306 2.061 2.061 0 001.395.531 2.244 2.244 0 001.459-.546 20.068 20.068 0 004.29-5.357 20.838 20.838 0 015.938-1.186 33.75 33.75 0 004.243 3.605 2.64 2.64 0 003.416-.236 2.08 2.08 0 00-.042-3.012zM27.62 49.623a.834.834 0 01-1.084.042.42.42 0 01-.167-.27c-.002-.066.027-.315.44-.736a18.038 18.038 0 013.762-2.368 17.26 17.26 0 01-2.95 3.332zm7.283-18.775a.343.343 0 01.315-.151.6.6 0 01.465.239.853.853 0 01.168.672c-.164.92-.424 2.38-.852 4.117l-.037-.151c-.356-1.523-.609-3.996-.059-4.726zm-1.179 12.703a34.973 34.973 0 001.52-3.767 21.248 21.248 0 002.224 3.05 21.857 21.857 0 00-3.744.717zm11.706 2.97a1.308 1.308 0 01-1.695.088 33.203 33.203 0 01-3.004-2.43 20.968 20.968 0 012.835.334 2.97 2.97 0 011.74.965c.533.633.296.87.123 1.043z"
//         ></path>
//       </svg>
//       <span className="mr-4">KKH disability report.pdf</span>
//       <span>Uploaded: 2022-08-31 15:47</span>
//     </Row>
//   </div>
// );

const DataContext = createContext();

const DataContextProvider = ({
  user,
  logs,
  pipelines,
  assessment,
  ...props
}) => {
  if (!user) {
    return (
      <Spinner
        className="mt-3 mb-3"
        style={{ display: "block", margin: "auto" }}
      />
    );
  }
  return (
    <DataContext.Provider
      value={{
        user: user,
        logs: logs,
        pipelines: pipelines,
        assessment: assessment,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};

const UserDetails = () => {
  let params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { data: user, isFetching } = useGetUserQuery({ id: params.id });
  const { data: assessment, isFetching: isGetAssessmentFetching } =
    useGetUserAssessmentQuery({ id: params.id });
  const [
    getUserLogs,
    {
      data: logs,
      isLoading: isLogsLoading,
      isSuccess: isLogsSuccess,
      isError: isLogsError,
    },
  ] = useLazyGetUserLogQuery();
  const { data: pipelines, isFetching: isGetPipelinesFetching } =
    useGetUserPipelinesQuery({ id: params.id });
  const [activeSection, setTab] = useState("personal");
  const [displayTabHeader, setDisplayTabHeader] = useState(false);
  const sectionRef = useRef([]);
  const {
    ref: navRef,
    inView,
    entry,
  } = useInView({
    rootMargin: "-65px 0px 0px 0px",
    onChange: (inView, entry) => {
      if (inView) {
        setDisplayTabHeader(false);
      } else {
        setDisplayTabHeader(true);
      }
    },
  });
  const [isUploadModalOpen, setUploadModal] = useState(false);
  const [uploadCategory, setUploadCategory] = useState(null);

  useEffect(() => {
    getUserLogs({ id: params.id });
  }, [getUserLogs, params.id]);

  // useEffect(() => {
  //   console.log("test");
  //   if (isLogsSuccess) {
  //     setLogs(dataLogs);
  //   }
  // }, [dataLogs, isLogsSuccess, isLogsError, isLogsLoading]);

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // const handleScroll = () => {
  //   const position = window.pageYOffset;
  //   // if (position > 220) {
  //   //   setDisplayTabHeader(true);
  //   // } else {
  //   //   setDisplayTabHeader(false);
  //   // }

  //   if (sectionRef.current[1].getBoundingClientRect().top >= window.innerHeight / 2) {
  //     //setTab("personal")
  //   }
  //   if (sectionRef.current[1].getBoundingClientRect().top < window.innerHeight / 2) {
  //     //setTab("caregiver")
  //   }
  //   if (sectionRef.current[2].getBoundingClientRect().top < window.innerHeight / 2) {
  //     //setTab("family")
  //   }
  //   if (sectionRef.current[3].getBoundingClientRect().top < window.innerHeight / 2) {
  //     //setTab("disability")
  //   }
  //   if (sectionRef.current[4].getBoundingClientRect().top < window.innerHeight / 2) {
  //     //setTab("medical")
  //   }
  //   if (sectionRef.current[5].getBoundingClientRect().top < window.innerHeight / 2) {
  //     //setTab("education")
  //   }
  //   if (sectionRef.current[6].getBoundingClientRect().top < window.innerHeight / 2) {
  //     //setTab("assessment")
  //   }
  //   if (sectionRef.current[7].getBoundingClientRect().top < window.innerHeight / 2) {
  //     //setTab("training")
  //   }
  //   if (sectionRef.current[8].getBoundingClientRect().top < window.innerHeight / 2) {
  //     //setTab("employment")
  //   }
  //   if (sectionRef.current[9].getBoundingClientRect().top < window.innerHeight / 2) {
  //     //setTab("pipelines")
  //   }
  //   if (sectionRef.current[10].getBoundingClientRect().top < window.innerHeight / 2) {
  //     //setTab("log")
  //   }
  // };

  return (
    <>
      <Head title="Candidate"></Head>
      <Content>
        <BlockHead size="sm">
          <Breadcrumb
            tag="nav"
            listTag="div"
            className="breadcrumb-arrow fs-18px"
          >
            <BreadcrumbItem tag="a" href="../">
              Home
            </BreadcrumbItem>
            <BreadcrumbItem tag="a" href="./">
              Candidates
            </BreadcrumbItem>
            <BreadcrumbItem
              active
            >{`${user?.first_name} ${user?.last_name}`}</BreadcrumbItem>
          </Breadcrumb>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Users /{" "}
                <strong className="text-primary small">{`${user?.first_name} ${user?.last_name}`}</strong>
              </BlockTitle>
              <BlockDes className="text-soft">
                Status:{" "}
                <span className="candidate-status text-base">CUSTOMER</span>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <Button
                color="light"
                outline
                className="bg-white d-none d-sm-inline-flex"
                onClick={() => {
                  navigate("../");
                }}
              >
                <Icon name="arrow-left"></Icon>
                <span>Back</span>
              </Button>
              <a
                href="#back"
                onClick={(ev) => {
                  ev.preventDefault();
                  navigate("../");
                }}
                className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
              >
                <Icon name="arrow-left"></Icon>
              </a>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <DataContextProvider
          user={user}
          logs={logs}
          pipelines={pipelines}
          assessment={assessment}
        >
          <Block>
            <Card className="card-bordered">
              {/* <div className="card-aside-wrap" id="user-detail-block"> */}
              <ul
                ref={navRef}
                className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card"
              >
                <li className="nav-item">
                  <a
                    // className={`nav-link ${activeSection === "personal" ? "active" : ""
                    //   }`}
                    className="nav-link"
                    href="#personal"
                    onClick={(ev) => {
                      ev.preventDefault();
                      //setTab("personal");
                      window.scrollTo({
                        top:
                          window.pageYOffset +
                          sectionRef.current[0].getBoundingClientRect().top -
                          window.outerHeight / 3,
                      });
                    }}
                  >
                    <Icon name="user-circle"></Icon>
                    <span>Personal</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#caregiver"
                    onClick={(ev) => {
                      ev.preventDefault();
                      //setTab("caregiver");
                      window.scrollTo({
                        top:
                          window.pageYOffset +
                          sectionRef.current[1].getBoundingClientRect().top -
                          window.outerHeight / 3,
                      });
                    }}
                  >
                    <Icon name="file-text"></Icon>
                    <span>Caregiver</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#family"
                    onClick={(ev) => {
                      ev.preventDefault();
                      //setTab("family");
                      window.scrollTo({
                        top:
                          window.pageYOffset +
                          sectionRef.current[2].getBoundingClientRect().top -
                          window.outerHeight / 3,
                      });
                    }}
                  >
                    <Icon name="file-text"></Icon>
                    <span>Family</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#disability"
                    onClick={(ev) => {
                      ev.preventDefault();
                      //setTab("disability");
                      window.scrollTo({
                        top:
                          window.pageYOffset +
                          sectionRef.current[3].getBoundingClientRect().top -
                          window.outerHeight / 3,
                      });
                    }}
                  >
                    <Icon name="repeat"></Icon>
                    <span>Disability</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#medical"
                    onClick={(ev) => {
                      ev.preventDefault();
                      //setTab("medical");
                      window.scrollTo({
                        top:
                          window.pageYOffset +
                          sectionRef.current[4].getBoundingClientRect().top -
                          window.outerHeight / 3,
                      });
                    }}
                  >
                    <Icon name="bell"></Icon>
                    <span>Medical</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#education"
                    onClick={(ev) => {
                      ev.preventDefault();
                      //setTab("education");
                      window.scrollTo({
                        top:
                          window.pageYOffset +
                          sectionRef.current[5].getBoundingClientRect().top -
                          window.outerHeight / 3,
                      });
                    }}
                  >
                    <Icon name="bell"></Icon>
                    <span>Education</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#assessment"
                    onClick={(ev) => {
                      ev.preventDefault();
                      //setTab("assessment");
                      window.scrollTo({
                        top:
                          window.pageYOffset +
                          sectionRef.current[6].getBoundingClientRect().top -
                          window.outerHeight / 3,
                      });
                    }}
                  >
                    <Icon name="activity"></Icon>
                    <span>Assessment</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#training"
                    onClick={(ev) => {
                      ev.preventDefault();
                      //setTab("training");
                      window.scrollTo({
                        top:
                          window.pageYOffset +
                          sectionRef.current[7].getBoundingClientRect().top -
                          window.outerHeight / 3,
                      });
                    }}
                  >
                    <Icon name="activity"></Icon>
                    <span>Training</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#employment"
                    onClick={(ev) => {
                      ev.preventDefault();
                      //setTab("employment");
                      window.scrollTo({
                        top:
                          window.pageYOffset +
                          sectionRef.current[8].getBoundingClientRect().top -
                          window.outerHeight / 3,
                      });
                    }}
                  >
                    <Icon name="activity"></Icon>
                    <span>Employment</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#pipelines"
                    onClick={(ev) => {
                      ev.preventDefault();
                      //setTab("pipelines");
                      window.scrollTo({
                        top:
                          window.pageYOffset +
                          sectionRef.current[9].getBoundingClientRect().top -
                          window.outerHeight / 3,
                      });
                    }}
                  >
                    <Icon name="activity"></Icon>
                    <span>Work</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#log"
                    onClick={(ev) => {
                      ev.preventDefault();
                      //setTab("log");
                      window.scrollTo({
                        top:
                          window.pageYOffset +
                          sectionRef.current[10].getBoundingClientRect().top -
                          window.outerHeight / 3,
                      });
                    }}
                  >
                    <Icon name="activity"></Icon>
                    <span>Log</span>
                  </a>
                </li>
              </ul>

              <div className="card-content">
                <div className="card-inner active">
                  <PersonalSection
                    sectionRef={sectionRef}
                    setTab={setTab}
                    setUploadModal={setUploadModal}
                    setUploadCategory={setUploadCategory}
                  />
                </div>
              </div>
              {/* </div> */}
            </Card>

            <Sections
              sectionRef={sectionRef}
              setTab={setTab}
              setUploadCategory={setUploadCategory}
              setUploadModal={setUploadModal}
            />
            <CommonModals
              isUploadModalOpen={isUploadModalOpen}
              setUploadModal={setUploadModal}
              uploadCategory={uploadCategory}
            />
          </Block>
        </DataContextProvider>
      </Content>
      <div
        className={classNames({
          "d-none": !displayTabHeader,
          "container-fluid": true,
          "tab-header-fixed": true,
        })}
      >
        <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
          <li className="nav-item">
            <a
              className="nav-link"
              href="#personal"
              onClick={(ev) => {
                ev.preventDefault();
                //setTab("personal");
                window.scrollTo({
                  top:
                    window.pageYOffset +
                    sectionRef.current[0].getBoundingClientRect().top -
                    window.outerHeight / 2,
                });
              }}
            >
              <Icon name="user-circle"></Icon>
              <span>Personal</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#caregiver"
              onClick={(ev) => {
                ev.preventDefault();
                //setTab("caregiver");
                window.scrollTo({
                  top:
                    window.pageYOffset +
                    sectionRef.current[1].getBoundingClientRect().top -
                    window.outerHeight / 3,
                });
              }}
            >
              <Icon name="file-text"></Icon>
              <span>Caregiver</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              // className={`nav-link ${activeSection === "family" ? "active" : ""
              //   }`}
              href="#family"
              onClick={(ev) => {
                ev.preventDefault();
                //setTab("family");
                window.scrollTo({
                  top:
                    window.pageYOffset +
                    sectionRef.current[2].getBoundingClientRect().top -
                    window.outerHeight / 3,
                });
              }}
            >
              <Icon name="file-text"></Icon>
              <span>Family</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#disability"
              onClick={(ev) => {
                ev.preventDefault();
                //setTab("disability");
                // sectionRef.current[3].scrollIntoView({block: "center", inline: "nearest"});
                window.scrollTo({
                  top:
                    window.pageYOffset +
                    sectionRef.current[3].getBoundingClientRect().top -
                    window.outerHeight / 3,
                });
              }}
            >
              <Icon name="repeat"></Icon>
              <span>Disability</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#medical"
              onClick={(ev) => {
                ev.preventDefault();
                //setTab("medical");
                window.scrollTo({
                  top:
                    window.pageYOffset +
                    sectionRef.current[4].getBoundingClientRect().top -
                    window.outerHeight / 3,
                });
              }}
            >
              <Icon name="bell"></Icon>
              <span>Medical</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#education"
              onClick={(ev) => {
                ev.preventDefault();
                //setTab("education");
                window.scrollTo({
                  top:
                    window.pageYOffset +
                    sectionRef.current[5].getBoundingClientRect().top -
                    window.outerHeight / 3,
                });
              }}
            >
              <Icon name="bell"></Icon>
              <span>Education</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#assessment"
              onClick={(ev) => {
                ev.preventDefault();
                //setTab("assessment");
                window.scrollTo({
                  top:
                    window.pageYOffset +
                    sectionRef.current[6].getBoundingClientRect().top -
                    window.outerHeight / 3,
                });
              }}
            >
              <Icon name="activity"></Icon>
              <span>Assessment</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#training"
              onClick={(ev) => {
                ev.preventDefault();
                //setTab("training");
                window.scrollTo({
                  top:
                    window.pageYOffset +
                    sectionRef.current[7].getBoundingClientRect().top -
                    window.outerHeight / 3,
                });
              }}
            >
              <Icon name="activity"></Icon>
              <span>Training</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#employment"
              onClick={(ev) => {
                ev.preventDefault();
                //setTab("employment");
                window.scrollTo({
                  top:
                    window.pageYOffset +
                    sectionRef.current[8].getBoundingClientRect().top -
                    window.outerHeight / 3,
                });
              }}
            >
              <Icon name="activity"></Icon>
              <span>Employment</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#pipelines"
              onClick={(ev) => {
                ev.preventDefault();
                //setTab("pipelines");
                window.scrollTo({
                  top:
                    window.pageYOffset +
                    sectionRef.current[9].getBoundingClientRect().top -
                    window.outerHeight / 3,
                });
              }}
            >
              <Icon name="activity"></Icon>
              <span>Work</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#log"
              onClick={(ev) => {
                ev.preventDefault();
                //setTab("log");
                window.scrollTo({
                  top:
                    window.pageYOffset +
                    sectionRef.current[10].getBoundingClientRect().top -
                    window.outerHeight / 3,
                });
              }}
            >
              <Icon name="activity"></Icon>
              <span>Log</span>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default UserDetails;

const Sections = ({
  sectionRef,
  setTab,
  setUploadCategory,
  setUploadModal,
}) => {
  return (
    <React.Fragment>
      <Card className="card-bordered">
        <div className="card-content">
          <div className="card-inner active">
            <CaregiverSection sectionRef={sectionRef} setTab={setTab} />
          </div>
        </div>
      </Card>

      <Card className="card-bordered">
        <div className="card-content">
          <div className="card-inner active">
            <FamilySection sectionRef={sectionRef} setTab={setTab} />
          </div>
        </div>
      </Card>

      <Card className="card-bordered">
        <div className="card-content">
          <div className="card-inner active">
            <DisabilitySection
              sectionRef={sectionRef}
              setTab={setTab}
              setUploadCategory={setUploadCategory}
              setUploadModal={setUploadModal}
            />
          </div>
        </div>
      </Card>

      <Card className="card-bordered">
        <div className="card-content">
          <div className="card-inner active">
            <MedicalSection
              sectionRef={sectionRef}
              setTab={setTab}
              setUploadCategory={setUploadCategory}
              setUploadModal={setUploadModal}
            />
          </div>
        </div>
      </Card>

      <Card className="card-bordered">
        <div className="card-content">
          <div className="card-inner active">
            <EducationSection
              sectionRef={sectionRef}
              setTab={setTab}
              setUploadCategory={setUploadCategory}
              setUploadModal={setUploadModal}
            />
          </div>
        </div>
      </Card>

      <Card className="card-bordered">
        <div className="card-content">
          <div className="card-inner active">
            <AssessmentSection sectionRef={sectionRef} setTab={setTab} />
          </div>
        </div>
      </Card>

      <Card className="card-bordered">
        <div className="card-content">
          <div className="card-inner active">
            <TrainingSection sectionRef={sectionRef} setTab={setTab} />
          </div>
        </div>
      </Card>

      <Card className="card-bordered">
        <div className="card-content">
          <div className="card-inner active">
            <EmploymentSection sectionRef={sectionRef} setTab={setTab} />
          </div>
        </div>
      </Card>

      <Card className="card-bordered">
        <div className="card-content">
          <div className="card-inner active">
            <WorkSection sectionRef={sectionRef} setTab={setTab} />
          </div>
        </div>
      </Card>

      <Card className="card-bordered">
        <div className="card-content">
          <div className="card-inner active">
            <LogSection sectionRef={sectionRef} setTab={setTab} />
          </div>
        </div>
      </Card>
    </React.Fragment>
  );
};

const CommonModals = ({
  isUploadModalOpen,
  setUploadModal,
  uploadCategory,
}) => {
  return (
    <React.Fragment>
      <UploadModal
        isUploadModalOpen={isUploadModalOpen}
        setUploadModal={setUploadModal}
        uploadCategory={uploadCategory}
      />
    </React.Fragment>
  );
};

const UploadModal = ({ isUploadModalOpen, setUploadModal, uploadCategory }) => {
  const { user } = useContext(DataContext);
  const [files, setFiles] = useState([]);
  const [percentage, setPercentage] = useState(null);
  const [uploader, setUploader] = useState(0);
  const [isUploading, setUploading] = useState(false);
  const [
    initializeMultipartUpload,
    {
      data: initializeMultipartUploadData,
      isLoading: isInitializeMultipartUploadLoading,
      isError: isInitializeMultipartUploadError,
      isSuccess: isInitializeMultipartUploadSuccess,
      error: initializeMultipartUploadError,
    },
  ] = useInitializeMultipartUploadMutation();
  const [
    finalizationMultipartUpload,
    {
      isLoading: isFinalizeMultipartUploadLoading,
      isSuccess: isFinalizeMultipartUploadSuccess,
      isError: isFinalizeMultipartUploadError,
      error: finalizeMultipartUploadError,
    },
  ] = useFinalizeMultipartUploadMutation();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          console.log(err);

          if (err.code === "file-too-large") {
            toast.error("File too large");
          }

          if (err.code === "file-invalid-type") {
          }
        });
      });
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm();

  const onUploadSubmit = () => {
    if (files) {
      const uploader = new Uploader({
        userId: user.id,
        fileName: files[0].name,
        file: files[0],
        fileType: files[0].type,
        uploadCategory: uploadCategory,
        initializeMultipartUpload: initializeMultipartUpload,
        finalizationMultipartUpload: finalizationMultipartUpload,
      });
      setUploader(uploader);

      uploader
        .onProgress(({ percentage: newPercentage }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            setPercentage(newPercentage);
          }
        })
        .onError((error) => {
          setUploading(false);
          setFiles([]);
          setPercentage(0);
          toast.error(error);
        });

      uploader.start();
      setUploading(true);
    }
  };

  useEffect(() => {
    if (
      isFinalizeMultipartUploadSuccess ||
      isFinalizeMultipartUploadError ||
      isInitializeMultipartUploadError
    ) {
      setUploading(false);
      setFiles([]);
      setPercentage(0);

      if (isFinalizeMultipartUploadError) {
        toast.error(finalizeMultipartUploadError?.data?.message);
      }
      if (isInitializeMultipartUploadError) {
        toast.error(initializeMultipartUploadError?.data?.message);
      }

      if (isFinalizeMultipartUploadSuccess) {
        setUploadModal(false);
        toast.success("File uploaded successfully");
      }
    }
  }, [
    isFinalizeMultipartUploadSuccess,
    isFinalizeMultipartUploadError,
    isInitializeMultipartUploadError,
  ]);

  return (
    <React.Fragment>
      <ModalComponent
        isOpen={isUploadModalOpen}
        title={"Upload File"}
        onModalCancel={() => {
          setUploadModal(false);
        }}
      >
        <form onSubmit={handleSubmit(onUploadSubmit)}>
          <Row className="gy-3">
            <Col size="12">
              <section>
                <div
                  {...getRootProps()}
                  className="dropzone upload-zone dz-clickable h-min-100px"
                >
                  <input {...getInputProps()} />
                  {files.length === 0 && (
                    <div className="dz-message">
                      <span className="dz-message-text">
                        Drag and drop file
                      </span>
                      <span className="dz-message-or">or</span>
                      <Button color="primary">SELECT</Button>
                    </div>
                  )}
                  {files.map((file) => (
                    <React.Fragment key={`file-name-${file.name}`}>
                      <div>{file.name}</div>
                      <Progress
                        className="progress-lg mt-2"
                        animated
                        value={percentage}
                      >
                        {`${percentage}%`}
                      </Progress>
                    </React.Fragment>
                  ))}
                  {/* {files.map((file) => (
                    <div
                      key={file.name}
                      className="dz-preview dz-processing dz-image-preview dz-error dz-complete"
                    >
                      <div className="dz-image">
                        <img src={file.preview} alt="preview" />
                      </div>
                    </div>
                  ))} */}
                </div>
              </section>

              <div className="d-flex justify-content-end mt-3">
                <Button
                  className="mr-2"
                  color="light"
                  type="button"
                  onClick={() => {
                    setUploadModal(false);
                  }}
                >
                  Cancel
                </Button>
                <Button color="primary" type="submit" disabled={isUploading}>
                  {isUploading && <Spinner type="grow" size="sm" />}
                  <span>Submit</span>
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </ModalComponent>
    </React.Fragment>
  );
};

const PersonalSection = ({
  sectionRef,
  setTab,
  setUploadModal,
  setUploadCategory,
}) => {
  const { user } = useContext(DataContext);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    control,
    reset,
  } = useForm();
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [
    editUser,
    {
      isLoading: isEditUserLoading,
      isSuccess: isEditUserSuccess,
      isError: isEditUserError,
      error: editUserError,
    },
  ] = useEditUserMutation();
  const [
    getSexOptions,
    {
      data: sexData,
      isFetching: isGetSexFetching,
      isSuccess: isGetSexSuccess,
      isError: isGetSexError,
      error: getSexError,
    },
  ] = useLazyGetSexesQuery({
    limit: 1000,
  });
  const [
    getReligionOptions,
    {
      data: religionData,
      isFetching: isGetReligionFetching,
      isSuccess: isGetReligionSuccess,
      isError: isGetReligionError,
      error: getReligionError,
    },
  ] = useLazyGetReligionsQuery({
    limit: 1000,
  });
  const [sexOptions, setSexOptions] = useState([]);
  const [religionOptions, setReligionOptions] = useState([]);
  const [editData, setEditData] = useState(user);

  useEffect(() => {
    if (isGetSexSuccess && sexData?.sexes) {
      let data = sexData.sexes.map((e) => {
        return { value: e.sex, label: e.sex };
      });
      setSexOptions(data);
    }
  }, [isGetSexSuccess, isGetSexError]);

  useEffect(() => {
    if (isGetReligionSuccess && religionData?.religions) {
      let data = religionData.religions.map((e) => {
        return { value: e.religion, label: e.religion };
      });
      setReligionOptions(data);
    }
  }, [isGetReligionSuccess, isGetReligionError]);

  useEffect(() => {
    if (isEditUserSuccess) {
      toast.success("Edit successfully!");
      setEditModalOpen(false);
    }
    if (isEditUserError && editUserError) {
      toast.error(editUserError?.data?.message);
    }
  }, [isEditUserSuccess, isEditUserError]);

  const defaultValues = {
    home_country_calling_code: "SG",
    country_calling_code: "SG",
  };
  const onEditBtnClicked = () => {
    getSexOptions(undefined, true);
    getReligionOptions(undefined, true);
    reset(
      {
        ...defaultValues,
        first_name: user?.first_name,
        last_name: user?.last_name,
        preferred_name: user?.preferred_name,
        last_4_digits_nric: user?.last_4_digits_nric,
        nationality_code: user?.nationality_code,
        date_of_birth: Date.parse(user.date_of_birth),
        sex: user.sex ? { value: user.sex, label: user.sex } : null,
        phone_number: user?.phone_number,
        country_calling_code: user?.country_calling_code,
        home_country_calling_code: user?.home_country_calling_code,
        home_phone_number: user?.home_phone_number,
        religion: user.religion
          ? { value: user.religion, label: user.religion }
          : null,
        ptc_card_description: user.ptc_card_description
          ? {
              value: user.ptc_card_description,
              label: user.ptc_card_description,
            }
          : null,
        // languages_spoken: user.languages_spoken
        //   ? user.languages_spoken.map((e) => ({ value: e, label: e }))
        //   : null,
        // languages_written: user.languages_written
        //   ? user.languages_written.map((e) => ({ value: e, label: e }))
        //   : null,
      },
      {
        keepDefaultValues: true,
      }
    );
    setEditModalOpen(true);
  };

  const onEditModalCancel = () => {
    setEditModalOpen(false);
  };

  const onFormSubmit = (data) => {
    data = convertUserData(data, defaultValues);
    data.id = user.id;
    editUser(data);
  };

  // const {
  //   ref: inViewRef,
  //   inView,
  //   entry,
  // } = useInView({
  //   threshold: 0.2,
  //   onChange: (inView, entry) => {
  //     // console.log('PersonalSection: ' + inView)
  //     if (inView) {
  //       //setTab("personal");
  //     }
  //   },
  // });

  // const setRefs = useCallback((node) => {
  //   sectionRef.current[0] = node;
  //   inViewRef(node);
  // });

  // const handleDropChange = (acceptedFiles) => {
  //   setFiles(
  //     acceptedFiles.map((file) =>
  //       Object.assign(file, {
  //         preview: URL.createObjectURL(file),
  //       })
  //     )
  //   );
  // };

  return (
    <div ref={(ref) => (sectionRef.current[0] = ref)}>
      <Block>
        <BlockHead>
          {/* <BlockBetween className="mb-1"> */}
          <BlockTitle tag="h5">
            Personal Information{" "}
            <Button
              color="primary"
              outline
              size="xs"
              className="d-none d-sm-inline-flex ml-3"
              onClick={onEditBtnClicked}
            >
              <Icon name="edit-alt-fill"></Icon>
              <span>Edit</span>
            </Button>
          </BlockTitle>

          {/* <Block>
              <Button
                className="d-none d-sm-inline-flex ml-3 toggle btn-icon btn-trigger"
                id={`edit-personal-info-btn`}
                onClick={onEditBtnClicked}
              >
                <Icon name="edit-alt-fill"></Icon>
              </Button>
              <UncontrolledTooltip
                placement="top"
                target={`edit-personal-info-btn`}
              >
                Edit
              </UncontrolledTooltip>
            </Block> */}
          {/* </BlockBetween> */}
          <p>
            Basic info, like name and address, that you use on Inclus Platform.
          </p>
        </BlockHead>
        <div className="profile-ud-list">
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">First Name</span>
              <span className="profile-ud-value">{user?.first_name}</span>
            </div>
          </div>
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Date of Birth</span>
              <span className="profile-ud-value">
                {user?.date_of_birth
                  ? moment(user?.date_of_birth).format("DD/MM/YYYY")
                  : null}
              </span>
            </div>
          </div>
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Last Name</span>
              <span className="profile-ud-value">{user?.last_name}</span>
            </div>
          </div>
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Nationality</span>
              <span className="profile-ud-value">{user?.nationality}</span>
            </div>
          </div>
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">
                Preferred to be called as
              </span>
              <span className="profile-ud-value">{user?.preferred_name}</span>
            </div>
          </div>
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Race</span>
              <span className="profile-ud-value">{user?.race}</span>
            </div>
          </div>
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Sex</span>
              <span className="profile-ud-value">{user?.sex}</span>
            </div>
          </div>
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Religion</span>
              <span className="profile-ud-value">{user?.religion}</span>
            </div>
          </div>
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Last 4 digits of NRIC</span>
              <span className="profile-ud-value">
                {user?.last_4_digits_nric}
              </span>
            </div>
          </div>
        </div>
      </Block>

      <Block>
        <BlockHead className="nk-block-head-line">
          <BlockTitle tag="h6" className="overline-title text-base">
            Personal \ Contact Info
          </BlockTitle>
        </BlockHead>
        <div className="profile-ud-list">
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Mobile number</span>
              <span className="profile-ud-value">
                {user?.country_calling_code && user?.phone_number
                  ? countryFlagsMap[user?.country_calling_code]?.secondary +
                    user?.phone_number
                  : ""}
              </span>
            </div>
          </div>
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Email address</span>
              <span className="profile-ud-value">{user?.email}</span>
            </div>
          </div>
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Home number</span>
              <span className="profile-ud-value">
                {user?.home_country_calling_code && user?.home_phone_number
                  ? countryFlagsMap[user?.home_country_calling_code]
                      ?.secondary + user?.home_phone_number
                  : ""}
              </span>
            </div>
          </div>
        </div>
      </Block>

      <Block>
        <BlockHead className="nk-block-head-line">
          <BlockTitle tag="h6" className="overline-title text-base">
            Personal \ Administrative
          </BlockTitle>
        </BlockHead>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col" className="w-min-150px">
                Item
              </th>
              <th scope="col" className="w-min-250px">
                Description
              </th>
              <th scope="col" className="w-min-250px">
                Documents
              </th>
              <th scope="col" className="w-min-100px"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>PTC card</td>
              <td>{user?.ptc_card_description}</td>
              <td>
                {user?.ptc_card_urls?.map((e, idx) => {
                  // const extension = e.split(".").pop();
                  return (
                    <Row
                      key={`ptc-card-file-${idx}`}
                      className="align-items-center my-2"
                    >
                      <Col className="mx-2">{nioIconData["file-pdf"]}</Col>
                      <Col>
                        <a
                          href={`${process.env.PUBLIC_URL}/files/?key=${e?.url}`}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <span>{e?.name}</span>
                        </a>
                      </Col>
                    </Row>
                  );
                })}
              </td>
              <td>
                <React.Fragment>
                  <Button
                    className="btn-icon"
                    id={`upload-btn-ptc-card`}
                    color="light"
                    size="xs"
                    onClick={() => {
                      setUploadCategory("ptc_card");
                      setUploadModal(true);
                    }}
                  >
                    <Icon name="upload" />
                  </Button>
                  <UncontrolledTooltip
                    placement="top"
                    target={`upload-btn-ptc-card`}
                  >
                    Upload
                  </UncontrolledTooltip>
                </React.Fragment>
              </td>
            </tr>
            <tr>
              <td>Vaccination</td>
              <td>{user?.vaccination_notes}</td>
              <td>
                {user?.vaccination_urls?.map((e, idx) => (
                  <Row
                    key={`vaccination-file-${idx}`}
                    className="align-items-center my-2"
                  >
                    <Col className="mx-2">{nioIconData["file-pdf"]}</Col>
                    <Col>
                      <a
                        href={`${process.env.PUBLIC_URL}/files/?key=${e?.url}`}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <span>{e?.name}</span>
                      </a>
                    </Col>
                  </Row>
                ))}
              </td>
              <td>
                <React.Fragment>
                  <Button
                    className="btn-icon"
                    id={`upload-btn-vaccination`}
                    color="light"
                    size="xs"
                    onClick={() => {
                      setUploadCategory("vaccination");
                      setUploadModal(true);
                    }}
                  >
                    <Icon name="upload" />
                  </Button>
                  <UncontrolledTooltip
                    placement="top"
                    target={`upload-btn-vaccination`}
                  >
                    Upload
                  </UncontrolledTooltip>
                </React.Fragment>
              </td>
            </tr>
          </tbody>
        </table>
      </Block>

      <Block>
        <BlockHead className="nk-block-head-line">
          <BlockTitle tag="h6" className="overline-title text-base">
            Personal \ Others
          </BlockTitle>
        </BlockHead>
        <div className="profile-ud-list">
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">
                Can travel independently?
              </span>
              <span className="profile-ud-value">
                {user?.is_travel_independently}
              </span>
            </div>
          </div>
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Status</span>
              <span className="profile-ud-value"></span>
            </div>
          </div>
        </div>
      </Block>

      <ModalComponent
        isOpen={isEditModalOpen}
        backdrop={"static"}
        title={"Edit Personal Information"}
        onModalCancel={() => {
          setEditModalOpen(false);
        }}
      >
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Row className="gy-3">
            <TextInput
              name={"first_name"}
              label={"First name"}
              required
              register={{
                ...register("first_name", {
                  required: "This field is required",
                }),
              }}
              errors={errors}
              md="12"
            />
            <TextInput
              name={"last_name"}
              label={"Last name"}
              required
              register={{
                ...register("last_name", {
                  required: "This field is required",
                }),
              }}
              errors={errors}
              md="12"
            />
            <TextInput
              name={"preferred_name"}
              label={"Preferred to be called by"}
              placeholder={"preferred name or alias"}
              required
              register={{
                ...register("preferred_name", {
                  required: "This field is required",
                }),
              }}
              errors={errors}
              md="12"
            />
            <TextInput
              name={"last_4_digits_nric"}
              label={"Last 4 digits of NRIC"}
              placeholder={"e.g. 123A"}
              required
              register={{
                ...register("last_4_digits_nric", {
                  required: "This field is required",
                }),
              }}
              errors={errors}
              md="12"
            />
            <FlagsSelectField
              name={"nationality_code"}
              label={"Nationality"}
              control={control}
              rules={{ required: "This field is required" }}
              errors={errors}
              md="12"
            />
            <DatePickerInput
              name={"date_of_birth"}
              label={"Date of birth"}
              placeholder={"dd/mm/yyyy"}
              dateFormat={"dd/MM/yyyy"}
              control={control}
              rules={{ required: "This field is required" }}
              errors={errors}
              md="12"
            />
            <SelectField
              name={"sex"}
              label={"Sex"}
              control={control}
              rules={{ required: "This field is required" }}
              options={sexOptions}
              isSearchable={false}
              isLoading={isGetSexFetching}
              isDisabled={isGetSexFetching}
              errors={errors}
              md="12"
            />
            <SelectField
              name={"religion"}
              label={"Religion"}
              control={control}
              rules={{ required: "This field is required" }}
              options={religionOptions}
              isSearchable={false}
              isLoading={isGetReligionFetching}
              isDisabled={isGetReligionFetching}
              errors={errors}
              md="12"
            />
            <SelectField
              name={"ptc_card_description"}
              label={"Has PTC card?"}
              control={control}
              rules={{ required: "This field is required" }}
              options={[
                { value: "Yes", label: "Yes" },
                { value: "No", label: "No" },
              ]}
              errors={errors}
              md="12"
            />
            <PhoneNumberWithCountryCodeInput
              label={"Home telephone"}
              nameCode={"home_country_calling_code"}
              nameInputField={"home_phone_number"}
              register={register}
              control={control}
              errors={errors}
              md="12"
            />
            <PhoneNumberWithCountryCodeInput
              label={"Mobile number"}
              nameCode={"country_calling_code"}
              nameInputField={"phone_number"}
              rules={{
                required: "This field is required",
              }}
              register={register}
              control={control}
              errors={errors}
              md="12"
            />
            <Col size="12" className="d-flex justify-content-end">
              <Button
                className="mr-2"
                color="light"
                type="button"
                onClick={onEditModalCancel}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={isEditUserLoading}
              >
                {isEditUserLoading && <Spinner type="grow" size="sm" />}
                <span>Submit</span>
              </Button>
            </Col>
          </Row>
        </form>
      </ModalComponent>
    </div>
  );
};

const DisabilityMedicalTab = ({ isActive }) => {
  return (
    <div className={`${isActive ? "" : "d-none"}`}>
      <ul className="timeline-steps">
        <li className="timeline-step">
          <div className={`timeline-status bg-primary`}></div>
          <div className="timeline-content">
            <strong>PWD contacted Inclus</strong>
            <p>xczcxvcv</p>
          </div>
        </li>
        <li className="timeline-step">
          <div className={`timeline-status bg-primary is-outline`}></div>
          <div className="timeline-content">
            <p>PWD contacted Inclus</p>
            <p>xczcxvcv</p>
          </div>
        </li>
        <li className="timeline-step">
          <div className={`timeline-status bg-primary is-outline`}></div>
          <div className="timeline-content">
            <span>asdasddasasdasdcvxxcvvxcvxcv</span>
            <p>xczcxvcv</p>
          </div>
        </li>
        <li className="timeline-step">
          <div className={`timeline-status bg-primary is-outline`}></div>
          <div className="timeline-content">
            <p>asdasddasasdasdcvxxcvvxcvxcv</p>
            <p>xczcxvcv</p>
          </div>
        </li>
        <li className="timeline-step">
          <div className={`timeline-status bg-primary is-outline`}></div>
          <div className="timeline-content">
            <p>asdasddasasdasdcvxxcvvxcvxcv</p>
            <p>xczcxvcv</p>
          </div>
        </li>
        <li className="timeline-step">
          <div className={`timeline-status bg-primary is-outline`}></div>
          <div className="timeline-content">
            <p>asdasddasasdasdcvxxcvvxcvxcv</p>
            <p>xczcxvcv</p>
          </div>
        </li>
        <li className="timeline-step">
          <div className={`timeline-status bg-primary is-outline`}></div>
          <div className="timeline-content">
            <p>asdasddasasdasdcvxxcvvxcvxcv</p>
            <p>xczcxvcv</p>
          </div>
        </li>
        <li className="timeline-step">
          <div className={`timeline-status bg-primary is-outline`}></div>
          <div className="timeline-content">
            <p>asdasddasasdasdcvxxcvvxcvxcv</p>
            <p>xczcxvcv</p>
          </div>
        </li>
        <li className="timeline-step">
          <div className={`timeline-status bg-primary is-outline`}></div>
          <div className="timeline-content">
            <p>asdasddasasdasdcvxxcvvxcvxcv</p>
            <p>xczcxvcv</p>
          </div>
        </li>
        <li className="timeline-step">
          <div className={`timeline-status bg-primary is-outline`}></div>
          <div className="timeline-content">
            <p>asdasddasasdasdcvxxcvvxcvxcv</p>
            <p>xczcxvcv</p>
          </div>
        </li>
        <li className="timeline-step">
          <div className={`timeline-status bg-primary is-outline`}></div>
          <div className="timeline-content">
            <p>asdasddasasdasdcvxxcvvxcvxcv</p>
            <p>xczcxvcv</p>
          </div>
        </li>
        <li className="timeline-step">
          <div className={`timeline-status bg-primary is-outline`}></div>
          <div className="timeline-content">
            <p>asdasddasasdasdcvxxcvvxcvxcv</p>
            <p>xczcxvcv</p>
          </div>
        </li>
        <li className="timeline-step">
          <div className={`timeline-status bg-primary is-outline`}></div>
          <div className="timeline-content">
            <p>asdasddasasdasdcvxxcvvxcvxcv</p>
            <p>xczcxvcv</p>
          </div>
        </li>
        <li className="timeline-step">
          <div className={`timeline-status bg-primary is-outline`}></div>
          <div className="timeline-content">
            <p>asdasddasasdasdcvxxcvvxcvxcv</p>
            <p>xczcxvcv</p>
          </div>
        </li>
      </ul>
    </div>
  );
};

const CaregiverSection = ({ sectionRef, setTab }) => {
  const { user } = useContext(DataContext);
  // const {
  //   ref: inViewRef,
  //   inView,
  //   entry,
  // } = useInView({
  //   threshold: 0.5,
  //   onChange: (inView, entry) => {
  //     // console.log('CaregiverSection: ' + inView)
  //     if (inView) {
  //       //setTab("caregiver");
  //     }
  //   },
  // });

  // const setRefs = useCallback((node) => {
  //   sectionRef.current[1] = node;
  //   inViewRef(node);
  // });
  let caregiver = {};
  // assessment?.family_details?.forEach((e) => {
  //   if (e.caregiver === "Primary") {
  //     caregiver = e;
  //   }
  // });
  caregiver = user?.caregiver_data;

  if (caregiver != null && Object.keys(caregiver).length === 0) {
    caregiver.first_name = user?.emergency_contact_first_name;
    caregiver.last_name = user?.emergency_contact_last_name;
    caregiver.relationship_to_candidate = user?.emergency_contact_relationship;
  }

  return (
    <div ref={(ref) => (sectionRef.current[1] = ref)}>
      <Block>
        <BlockHead>
          <BlockBetween>
            <BlockTitle tag="h5">Caregiver</BlockTitle>
            <Link
              to={`../../caregivers/${user?.caregiver_id}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              <span className="caption-text">{`View more >`}</span>
            </Link>
          </BlockBetween>
        </BlockHead>
        <div className="profile-ud-list">
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">First Name</span>
              <span className="profile-ud-value">{caregiver?.first_name}</span>
            </div>
          </div>
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Last Name</span>
              <span className="profile-ud-value">{caregiver?.last_name}</span>
            </div>
          </div>
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">
                Relationship to candidate
              </span>
              <span className="profile-ud-value">
                {caregiver?.relationship_to_candidate}
              </span>
            </div>
          </div>
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Occupation</span>
              <span className="profile-ud-value">{caregiver?.occupation}</span>
            </div>
          </div>
        </div>
      </Block>

      <Block>
        <BlockHead className="nk-block-head-line">
          <BlockTitle tag="h6" className="overline-title text-base">
            Caregiver \ Contact Info
          </BlockTitle>
        </BlockHead>
        <div className="profile-ud-list">
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Mobile number</span>
              <span className="profile-ud-value">
                {caregiver?.country_calling_code
                  ? countryFlagsMap[caregiver?.country_calling_code]
                      ?.secondary + caregiver?.phone_number
                  : ""}
              </span>
            </div>
          </div>
          {/* <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Email address</span>
              <span className="profile-ud-value">{user?.email}</span>
            </div>
          </div> */}
        </div>
      </Block>
    </div>
  );
};

const FamilySection = ({ sectionRef, setTab }) => {
  const { user } = useContext(DataContext);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    control,
    reset,
  } = useForm();
  const {
    fields: familyFields,
    append: familyAppend,
    remove: familyRemove,
  } = useFieldArray({
    control,
    name: "family_details",
    rules: {
      required: "Minimum requirement is 1 row",
      minLength: {
        value: 1,
        message: "Minimum requirement is 1 row",
      },
      validate: {
        checkPrimary: (v) => {
          let sumPrim = 0;
          v.forEach((item) => {
            if (item?.caregiver?.value === "Primary") {
              sumPrim++;
            }
          });
          if (sumPrim >= 2) {
            return "There can only be 1 primary";
          }
          return true;
        },
      },
    },
  });
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [
    editUser,
    {
      isLoading: isEditUserLoading,
      isSuccess: isEditUserSuccess,
      isError: isEditUserError,
      error: editUserError,
    },
  ] = useEditUserMutation();
  const defaultValues = {
    family_details: [
      {
        first_name: "",
        last_name: "",
        relationship: "",
        occupation: "",
        caregiver: "",
      },
    ],
  };

  // const {
  //   ref: inViewRef,
  //   inView,
  //   entry,
  // } = useInView({
  //   threshold: 0.5,
  //   onChange: (inView, entry) => {
  //     // console.log('FamilySection: ' + inView)
  //     if (inView) {
  //       //setTab("family");
  //     }
  //   },
  // });

  // const setRefs = useCallback((node) => {
  //   sectionRef.current[2] = node;
  //   inViewRef(node);
  // });

  // (ref) => {
  //   sectionRef.current[2] = ref
  //   inViewRef(ref)
  // }

  useEffect(() => {
    if (isEditUserSuccess) {
      toast.success("Edit successfully!");
      setEditModalOpen(false);
    }
    if (isEditUserError && editUserError) {
      toast.error(editUserError?.data?.message);
    }
  }, [isEditUserSuccess, isEditUserError]);

  const onEditBtnClicked = () => {
    reset({
      family_details:
        user.family_details?.map((e) => ({
          ...e,
          relationship: e.relationship
            ? { value: e.relationship, label: e.relationship }
            : null,
          caregiver: e.caregiver
            ? { value: e.caregiver, label: e.caregiver }
            : null,
        })) ?? defaultValues.family_details,
      family_details_remarks: user.family_details_remarks,
    });
    setEditModalOpen(true);
  };

  const onFormSubmit = (data) => {
    data = convertUserData(data, defaultValues);

    data.id = user.id;
    editUser(data);
  };

  const onEditModalCancel = () => {
    setEditModalOpen(false);
  };

  return (
    <div ref={(ref) => (sectionRef.current[2] = ref)}>
      <Block>
        <BlockHead>
          <BlockTitle tag="h5">
            Family{" "}
            <Button
              color="primary"
              outline
              size="xs"
              className="d-none d-sm-inline-flex ml-3"
              onClick={onEditBtnClicked}
            >
              <Icon name="edit-alt-fill"></Icon>
              <span>Edit</span>
            </Button>
          </BlockTitle>
        </BlockHead>
        <Block className="overflow-auto">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col" className="w-min-60px">
                  S/N
                </th>
                <th scope="col" className="w-min-150px">
                  First name
                </th>
                <th scope="col" className="w-min-150px">
                  Last name
                </th>
                <th scope="col" className="w-min-150px">
                  Relationship
                </th>
                <th scope="col" className="w-min-200px">
                  Occupation
                </th>
                <th scope="col" className="w-min-150px">
                  Caregiver
                </th>
                <th scope="col" className="w-min-250px">
                  Remarks
                </th>
              </tr>
            </thead>
            <tbody>
              {user?.family_details?.map((e, idx) => {
                return (
                  <tr key={`${e?.first_name}-${e?.last_name}`}>
                    <td>{idx + 1}</td>
                    <td>{e?.first_name}</td>
                    <td>{e?.last_name}</td>
                    <td>{e?.relationship}</td>
                    <td>{e?.occupation}</td>
                    <td>{e?.caregiver}</td>
                    <td>{e?.remarks}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Row className="mt-4 fs-16px">
            <Col md="3">
              <span>General remarks on Family details</span>
            </Col>
            <Col md="9">
              <span>{user?.family_details_remarks}</span>
            </Col>
          </Row>
        </Block>
      </Block>

      <ModalComponent
        id={"test-family-detail"}
        isOpen={isEditModalOpen}
        backdrop={"static"}
        title={"Edit Family Detail"}
        onModalCancel={() => {
          setEditModalOpen(false);
        }}
      >
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Row className="gy-3">
            <SimpleBar className="w-100" forceVisible="x" autoHide={false}>
              <div className="table-wrapper table-actions">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">S/N</th>
                      <th scope="col" className="w-min-300px">
                        First name <span className="required-asterisk">*</span>
                      </th>
                      <th scope="col" className="w-min-250px">
                        Last name <span className="required-asterisk">*</span>
                      </th>
                      <th scope="col" className="w-min-250px">
                        Relationship{" "}
                        <span className="required-asterisk">*</span>
                      </th>
                      <th scope="col" className="w-min-150px">
                        Occupation <span className="required-asterisk">*</span>
                      </th>
                      <th scope="col" className="w-min-150px">
                        Caregiver <span className="required-asterisk">*</span>
                      </th>
                      <th scope="col" className="w-min-150px">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {familyFields.map((item, index) => {
                      return (
                        <tr key={item.id}>
                          <td>{index + 1}</td>
                          <td>
                            <TextInput
                              name={`family_details.${index}.first_name`}
                              required
                              register={{
                                ...register(
                                  `family_details.${index}.first_name`,
                                  {
                                    required: "This field is required",
                                  }
                                ),
                              }}
                              errors={errors}
                              md="12"
                            />
                          </td>
                          <td>
                            <TextInput
                              name={`family_details.${index}.last_name`}
                              required
                              register={{
                                ...register(
                                  `family_details.${index}.last_name`,
                                  {
                                    required: "This field is required",
                                  }
                                ),
                              }}
                              errors={errors}
                              md="12"
                            />
                          </td>
                          <td>
                            <SelectField
                              name={`family_details.${index}.relationship`}
                              control={control}
                              menuPortalTarget={document.body}
                              rules={{ required: "This field is required" }}
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  maxWidth: "300px",
                                }),
                              }}
                              options={[
                                { value: "Parent", label: "Parent" },
                                {
                                  value: "Sibling",
                                  label: "Sibling",
                                },
                                { value: "Spouse", label: "Spouse" },
                                {
                                  value: "No primary caregiver",
                                  label: "No primary caregiver",
                                },
                                { value: "Other", label: "Other" },
                              ]}
                              errors={errors}
                              md="12"
                            />
                          </td>
                          <td>
                            <TextInput
                              name={`family_details.${index}.occupation`}
                              required
                              register={{
                                ...register(
                                  `family_details.${index}.occupation`,
                                  {
                                    required: "This field is required",
                                  }
                                ),
                              }}
                              errors={errors}
                              md="12"
                            />
                          </td>
                          <td>
                            <SelectField
                              name={`family_details.${index}.caregiver`}
                              control={control}
                              menuPortalTarget={document.body}
                              rules={{ required: "This field is required" }}
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  maxWidth: "300px",
                                }),
                              }}
                              options={[
                                { value: "Primary", label: "Primary" },
                                { value: "Secondary", label: "Secondary" },
                                { value: "No", label: "No" },
                              ]}
                              errors={errors}
                              md="12"
                            />
                          </td>
                          <td>
                            {index === familyFields.length - 1 && (
                              <Icon
                                onClick={() => {
                                  familyAppend({
                                    first_name: "",
                                    last_name: "",
                                    relationship: "",
                                    occupation: "",
                                    caregiver: "",
                                  });
                                }}
                                name="plus-c"
                                className="fs-22px mr-2"
                              />
                            )}
                            {((familyFields.length > 1 && index === 0) ||
                              index !== 0) && (
                              <Icon
                                onClick={() => {
                                  familyRemove(index);
                                }}
                                name="trash"
                                className="fs-22px"
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </SimpleBar>
            <div>
              {errors["family_details"]?.root && (
                <span className="table-invalid">
                  {errors["family_details"]?.root?.message}
                </span>
              )}
            </div>
            <TextInput
              name={"family_details_remarks"}
              label={"Remarks on family details"}
              placeholder={"type here..."}
              register={{
                ...register("family_details_remarks"),
              }}
              errors={errors}
              md="12"
            />

            <Col size="12" className="d-flex justify-content-end">
              <Button
                className="mr-2"
                color="light"
                type="button"
                onClick={onEditModalCancel}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={isEditUserLoading}
              >
                {isEditUserLoading && <Spinner type="grow" size="sm" />}
                <span>Submit</span>
              </Button>
            </Col>
          </Row>
        </form>
      </ModalComponent>
    </div>
  );
};

const DisabilitySection = ({
  sectionRef,
  setTab,
  setUploadCategory,
  setUploadModal,
}) => {
  const { user } = useContext(DataContext);
  // const {
  //   ref: inViewRef,
  //   inView,
  //   entry,
  // } = useInView({
  //   threshold: 0.2,
  //   onChange: (inView, entry) => {
  //     if (inView) {
  //       //setTab("disability");
  //     }
  //   },
  // });

  // const setRefs = useCallback((node) => {
  //   sectionRef.current[3] = node;
  //   inViewRef(node);
  // });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    control,
    reset,
  } = useForm();
  const {
    fields: disabilityFields,
    append: disabilityAppend,
    remove: disabilityRemove,
  } = useFieldArray({
    control,
    name: "disability_details",
    rules: {
      required: "Minimum requirement is 1 row",
      minLength: {
        value: 1,
        message: "Minimum requirement is 1 row",
      },
      validate: {
        checkPrimary: (v) => {
          let sumPrim = 0;
          v.forEach((item) => {
            if (item?.priority?.value === "Primary") {
              sumPrim++;
            }
          });
          if (sumPrim >= 2) {
            return "There can only be 1 primary";
          }
          // if (sumPrim === 0) {
          //   return "Required 1 primary";
          // }
          return true;
        },
      },
    },
  });
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [
    editUser,
    {
      isLoading: isEditUserLoading,
      isSuccess: isEditUserSuccess,
      isError: isEditUserError,
      error: editUserError,
    },
  ] = useEditUserMutation();
  const defaultValues = {
    disability_details: [
      {
        name: "",
        priority: "",
        description: "",
        year_diagnosed: null,
        place_diagnosed: "",
        remarks: "",
        document_urls: {},
      },
    ],
  };

  useEffect(() => {
    if (isEditUserSuccess) {
      toast.success("Edit successfully!");
      setEditModalOpen(false);
    }
    if (isEditUserError && editUserError) {
      toast.error(editUserError?.data?.message);
    }
  }, [isEditUserSuccess, isEditUserError]);

  const onEditBtnClicked = () => {
    reset({
      disability_details: user.disability_details?.map((e) => ({
        ...e,
        name: e.name ? { value: e.name, label: e.name } : null,
        priority: e.priority ? { value: e.priority, label: e.priority } : null,
        year_diagnosed: Date.parse(e.year_diagnosed),
      })),
    });
    setEditModalOpen(true);
  };

  const onFormSubmit = (data) => {
    data = convertUserData(data, defaultValues);

    data.id = user.id;
    editUser(data);
  };

  const onEditModalCancel = () => {
    setEditModalOpen(false);
  };

  return (
    <div ref={(ref) => (sectionRef.current[3] = ref)}>
      <Block>
        <BlockHead>
          <BlockTitle tag="h5">
            Disability
            <Button
              color="primary"
              outline
              size="xs"
              className="d-none d-sm-inline-flex ml-3"
              onClick={onEditBtnClicked}
            >
              <Icon name="edit-alt-fill"></Icon>
              <span>Edit</span>
            </Button>
          </BlockTitle>
        </BlockHead>
        <Block className="overflow-auto">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col" className="w-min-60px">
                  S/N
                </th>
                <th scope="col" className="w-min-250px">
                  Name
                </th>
                <th scope="col" className="w-min-100px">
                  Priority
                </th>
                <th scope="col" className="w-min-200px">
                  Description
                </th>
                <th scope="col" className="w-min-150px">
                  Year diagnosed
                </th>
                <th scope="col" className="w-min-150px">
                  Place diagnosed
                </th>
                <th scope="col" className="w-min-200px">
                  Remarks
                </th>
                <th scope="col" className="w-min-400px">
                  Documents
                </th>
                <th scope="col" className="w-min-150px"></th>
              </tr>
            </thead>
            <tbody>
              {user?.disability_details?.map((e, idx) => {
                let key = `disability_details_${idx}`;
                return (
                  <tr key={key}>
                    <td>{idx + 1}</td>
                    <td>{e?.name}</td>
                    <td>{e?.priority}</td>
                    <td>{e?.description}</td>
                    <td>{moment(e?.year_diagnosed).format("YYYY")}</td>
                    <td>{e?.place_diagnosed}</td>
                    <td>{e?.remarks}</td>
                    <td>
                      {e?.document_urls?.map((doc, docIdx) => (
                        <Row
                          key={`${key}_doc_${docIdx}`}
                          className="align-items-center my-2"
                        >
                          <Col>{nioIconData["file-pdf"]}</Col>
                          <Col md="6">
                            <a
                              href={`${process.env.PUBLIC_URL}/files/?key=${doc?.url}`}
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              <span>{doc?.name}</span>
                            </a>
                          </Col>
                          <Col md="4">
                            <span>{`Uploaded: ${moment(
                              doc?.uploaded_time
                            ).format("YYYY-MM-DD hh:mm")}`}</span>
                          </Col>
                        </Row>
                      ))}
                    </td>
                    <td>
                      <React.Fragment>
                        <Button
                          className="btn-icon"
                          id={`upload-btn-${key}`}
                          color="light"
                          size="xs"
                          onClick={() => {
                            setUploadCategory(`disability_details_${idx}`);
                            setUploadModal(true);
                          }}
                        >
                          <Icon name="upload" />
                        </Button>
                        <UncontrolledTooltip
                          placement="top"
                          flip={false}
                          target={`upload-btn-${key}`}
                        >
                          Upload
                        </UncontrolledTooltip>
                      </React.Fragment>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Block>
        {/* <DataTable
          data={[
            {
              index: 1,
              name: "Autism Spectrum Disorder",
              priority: "Primary",
              year_diagnosed: "2008",
              place_diagnosed: "KKH",
            },
            {
              index: 2,
              name: "Autism Spectrum Disorder",
              priority: "Primary",
              year_diagnosed: "2008",
              place_diagnosed: "KKH",
            },
            {
              index: 3,
              name: "Autism Spectrum Disorder",
              priority: "Primary",
              year_diagnosed: "2008",
              place_diagnosed: "KKH",
            },
          ]}
          customStyles={customStyles}
          dense
          expandableRows
          expandableRowsComponent={ExpandedComponent}
          columns={[
            {
              name: "Name",
              selector: (row) => row.name,
              cell: (row) => <span className="py-2">{row.name}</span>,
              // sortable: true,
            },
            {
              name: "Priority",
              selector: (row) => row.priority,
              // cell: (row) => <span>{row.priority}</span>,
            },
            {
              name: "Description",
              selector: (row) => row.description,
              // cell: (row) => <span>{row.priority}</span>,
            },
            {
              name: "Year Diagnosed",
              selector: (row) => row.year_diagnosed,
              // cell: (row) => <span>{row.year_diagnosed}</span>,
            },
            {
              name: "Place Diagnosed",
              selector: (row) => row.place_diagnosed,
              // cell: (row) => <span>{row.year_diagnosed}</span>,
            },
            {
              name: "Remarks",
              selector: (row) => row.remarks,
              // cell: (row) => <span>{row.year_diagnosed}</span>,
            },
          ]}
        ></DataTable> */}
        {/* <Row className="mt-4 fs-16px">
          <Col md="3">
            <span>General remarks on Disability details</span>
          </Col>
          <Col md="9">
            <span>N.A</span>
          </Col>
        </Row> */}
      </Block>

      <Block>
        <BlockHead className="nk-block-head-line">
          <BlockTitle tag="h6" className="overline-title text-base">
            Disability \ General documents
            <Button
              color="primary"
              outline
              size="xs"
              className="d-none d-sm-inline-flex ml-3"
              onClick={() => {
                setUploadCategory("disability_general_document");
                setUploadModal(true);
              }}
            >
              <Icon name="plus"></Icon>
              <span>Upload document</span>
            </Button>
          </BlockTitle>
        </BlockHead>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col" className="w-min-60px">
                S/N
              </th>
              <th scope="col" className="w-min-150px">
                Document name
              </th>
              <th scope="col" className="w-min-150px">
                Document
              </th>
              <th scope="col" className="w-min-150px">
                Uploaded
              </th>
            </tr>
          </thead>
          <tbody>
            {user?.disability_general_documents?.map((doc, docIdx) => (
              <tr key={`disability_general_doc_${docIdx}`}>
                <td>{docIdx + 1}</td>
                <td>{doc?.name}</td>
                <td>
                  <Row className="align-items-center my-2">
                    <Col className="mx-2">{nioIconData["file-pdf"]}</Col>
                    <Col>
                      <a
                        href={`${process.env.PUBLIC_URL}/files/?key=${doc?.url}`}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <span>{doc?.name}</span>
                      </a>
                    </Col>
                  </Row>
                </td>
                <td>{moment(doc?.uploaded_time).format("YYYY-MM-DD hh:mm")}</td>
              </tr>
            ))}
            {(!user?.disability_general_documents ||
              user?.disability_general_documents?.length === 0) && (
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
            )}
          </tbody>
        </table>
      </Block>

      <ModalComponent
        isOpen={isEditModalOpen}
        backdrop={"static"}
        title={"Edit Disability Detail"}
        onModalCancel={() => {
          setEditModalOpen(false);
        }}
      >
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Row className="gy-3">
            <SimpleBar className="w-100" forceVisible="x" autoHide={false}>
              <div className="table-wrapper table-actions">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">S/N</th>
                      <th scope="col" className="w-min-300px">
                        Disability <span className="required-asterisk">*</span>
                      </th>
                      <th scope="col" className="w-min-150px">
                        Priority <span className="required-asterisk">*</span>
                      </th>
                      <th scope="col" className="w-min-250px">
                        Description
                      </th>
                      <th scope="col" className="w-min-150px">
                        Year diagnosed
                      </th>
                      <th scope="col" className="w-min-250px">
                        Place diagnosed
                      </th>
                      <th scope="col" className="w-min-200px">
                        Remarks
                      </th>
                      <th scope="col" className="w-min-150px">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {disabilityFields.map((item, index) => {
                      return (
                        <tr key={item.id}>
                          <td>{index + 1}</td>
                          <td>
                            <SelectField
                              name={`disability_details.${index}.name`}
                              control={control}
                              menuPortalTarget={document.body}
                              rules={{ required: "This field is required" }}
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  maxWidth: "300px",
                                }),
                              }}
                              options={[
                                {
                                  value:
                                    "Attention Deficit Hyperactivity Disorder",
                                  label:
                                    "Attention Deficit Hyperactivity Disorder",
                                },
                                {
                                  value: "Autism Spectrum Disorder",
                                  label: "Autism Spectrum Disorder",
                                },
                                {
                                  value: "Blind - full",
                                  label: "Blind - full",
                                },
                                {
                                  value: "Blind - partial",
                                  label: "Blind - partial",
                                },
                                {
                                  value: "Cerebral Palsy",
                                  label: "Cerebral Palsy",
                                },
                                {
                                  value: "Deaf - full",
                                  label: "Deaf - full",
                                },
                                {
                                  value: "Deaf - partial",
                                  label: "Deaf - partial",
                                },
                                { value: "Dyslexia", label: "Dyslexia" },
                                {
                                  value: "Mild Intellectual Disability",
                                  label: "Mild Intellectual Disability",
                                },
                                { value: "Physical", label: "Physical" },
                                { value: "Others", label: "Others" },
                              ]}
                              errors={errors}
                              md="12"
                            />
                          </td>
                          <td>
                            <SelectField
                              name={`disability_details.${index}.priority`}
                              control={control}
                              menuPortalTarget={document.body}
                              rules={{ required: "This field is required" }}
                              isSearchable={false}
                              options={[
                                { value: "Primary", label: "Primary" },
                                { value: "Secondary", label: "Secondary" },
                              ]}
                              errors={errors}
                              md="12"
                            />
                          </td>
                          <td>
                            <TextInput
                              name={`disability_details.${index}.description`}
                              required
                              register={{
                                ...register(
                                  `disability_details.${index}.description`
                                ),
                              }}
                              errors={errors}
                              md="12"
                            />
                          </td>
                          <td>
                            <div>
                              <DatePickerInput
                                name={`disability_details.${index}.year_diagnosed`}
                                showYearPicker
                                dateFormat={"yyyy"}
                                placeholder={"yyyy"}
                                maxDate={new Date()}
                                control={control}
                                errors={errors}
                                portalId={"test"}
                                md="12"
                              />
                            </div>
                          </td>
                          <td>
                            <TextInput
                              name={`disability_details.${index}.place_diagnosed`}
                              required
                              register={{
                                ...register(
                                  `disability_details.${index}.place_diagnosed`
                                ),
                              }}
                              errors={errors}
                              md="12"
                            />
                          </td>
                          <td>
                            <TextInput
                              name={`disability_details.${index}.remarks`}
                              required
                              register={{
                                ...register(
                                  `disability_details.${index}.remarks`
                                  // {
                                  //   required: "This field is required",
                                  // }
                                ),
                              }}
                              errors={errors}
                              md="12"
                            />
                          </td>
                          <td>
                            {index === disabilityFields.length - 1 && (
                              <Icon
                                onClick={() => {
                                  disabilityAppend({
                                    name: "",
                                    priority: "",
                                    description: "",
                                    year_diagnosed: null,
                                    place_diagnosed: "",
                                    remarks: "",
                                  });
                                }}
                                name="plus-c"
                                className="fs-22px mr-2"
                              />
                            )}
                            {((disabilityFields.length > 1 && index === 0) ||
                              index !== 0) && (
                              <Icon
                                onClick={() => {
                                  disabilityRemove(index);
                                }}
                                name="trash"
                                className="fs-22px"
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </SimpleBar>

            <div>
              {errors["disability_details"]?.root && (
                <span className="table-invalid">
                  {errors["disability_details"]?.root?.message}
                </span>
              )}
            </div>

            <Col size="12" className="d-flex justify-content-end">
              <Button
                className="mr-2"
                color="light"
                type="button"
                onClick={onEditModalCancel}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={isEditUserLoading}
              >
                {isEditUserLoading && <Spinner type="grow" size="sm" />}
                <span>Submit</span>
              </Button>
            </Col>
          </Row>
        </form>
      </ModalComponent>
    </div>
  );
};

const MedicalSection = ({
  sectionRef,
  setTab,
  setUploadCategory,
  setUploadModal,
}) => {
  const { user } = useContext(DataContext);
  // const {
  //   ref: inViewRef,
  //   inView,
  //   entry,
  // } = useInView({
  //   threshold: 0.2,
  //   onChange: (inView, entry) => {
  //     if (inView) {
  //       //setTab("medical");
  //     }
  //   },
  // });

  // const setRefs = useCallback((node) => {
  //   sectionRef.current[4] = node;
  //   inViewRef(node);
  // });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    control,
    reset,
  } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "medical_details",
  });
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [
    editUser,
    {
      isLoading: isEditUserLoading,
      isSuccess: isEditUserSuccess,
      isError: isEditUserError,
      error: editUserError,
    },
  ] = useEditUserMutation();
  const defaultValues = {
    medical_details: [
      {
        type: "",
        year_diagnosed: null,
        condition: "",
        clinic: "",
        doctor: "",
        follow_up_appointments: "",
        medications: "",
        side_effects: "",
        document_urls: {},
      },
    ],
  };

  useEffect(() => {
    if (isEditUserSuccess) {
      toast.success("Edit successfully!");
      setEditModalOpen(false);
    }
    if (isEditUserError && editUserError) {
      toast.error(editUserError?.data?.message);
    }
  }, [isEditUserSuccess, isEditUserError]);

  const onEditBtnClicked = () => {
    reset({
      medical_details:
        user.medical_details?.map((e) => ({
          ...e,
          type: e.type ? { value: e.type, label: e.type } : null,
          year_diagnosed: Date.parse(e.year_diagnosed),
        })) ?? defaultValues.medical_details,
      medical_details_remarks: user.medical_details_remarks,
    });
    setEditModalOpen(true);
  };

  const onFormSubmit = (data) => {
    data = convertUserData(data, defaultValues);
    data.id = user.id;

    editUser(data);
  };

  const onEditModalCancel = () => {
    setEditModalOpen(false);
  };

  return (
    <div ref={(ref) => (sectionRef.current[4] = ref)}>
      <Block>
        <BlockHead>
          <BlockTitle tag="h5">
            Medical
            <Button
              color="primary"
              outline
              size="xs"
              className="d-none d-sm-inline-flex ml-3"
              onClick={onEditBtnClicked}
            >
              <Icon name="edit-alt-fill"></Icon>
              <span>Edit</span>
            </Button>
          </BlockTitle>
        </BlockHead>
        <Block className="overflow-auto">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col" className="w-min-60px">
                  S/N
                </th>
                <th scope="col" className="w-min-80px">
                  Type
                </th>
                <th scope="col" className="w-min-150px">
                  Condition
                </th>
                <th scope="col" className="w-min-150px">
                  Year diagnosed
                </th>
                <th scope="col" className="w-min-250px">
                  Clinic
                </th>
                <th scope="col" className="w-min-200px">
                  Doctor
                </th>
                <th scope="col" className="w-min-250px">
                  Follow-up appointments
                </th>
                <th scope="col" className="w-min-200px">
                  Medications
                </th>
                <th scope="col" className="w-min-200px">
                  Side effects
                </th>
                <th scope="col" className="w-min-400px">
                  Documents
                </th>
                <th scope="col" className="w-min-150px"></th>
              </tr>
            </thead>
            <tbody>
              {user?.medical_details?.map((e, idx) => {
                let key = `medical_details_${idx}`;
                return (
                  <tr key={key}>
                    <td>{idx + 1}</td>
                    <td>{e?.type}</td>
                    <td>{e?.condition}</td>
                    <td>{moment(e?.year_diagnosed).format("YYYY")}</td>
                    <td>{e?.clinic}</td>
                    <td>{e?.doctor}</td>
                    <td>{e?.follow_up_appointments}</td>
                    <td>{e?.medications}</td>
                    <td>{e?.side_effects}</td>
                    <td>
                      {e?.document_urls?.map((doc, docIdx) => (
                        <Row
                          key={`${key}_doc_${docIdx}`}
                          className="align-items-center my-2"
                        >
                          <Col>{nioIconData["file-pdf"]}</Col>
                          <Col md="6">
                            <a
                              href={`${process.env.PUBLIC_URL}/files/?key=${doc?.url}`}
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              <span>{doc?.name}</span>
                            </a>
                          </Col>
                          <Col md="4">
                            <span>{`Uploaded: ${moment(
                              doc?.uploaded_time
                            ).format("YYYY-MM-DD hh:mm")}`}</span>
                          </Col>
                        </Row>
                      ))}
                    </td>
                    <td>
                      <React.Fragment>
                        <Button
                          className="btn-icon"
                          id={`upload-btn-${key}`}
                          color="light"
                          size="xs"
                          onClick={() => {
                            setUploadCategory(`medical_details_${idx}`);
                            setUploadModal(true);
                          }}
                        >
                          <Icon name="upload" />
                        </Button>
                        {/* <UncontrolledTooltip
                          placement="top"
                          target={`upload-btn-${key}`}
                        >
                          Upload
                        </UncontrolledTooltip> */}
                      </React.Fragment>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Row className="mt-4 fs-16px">
            <Col md="3">
              <span>General remarks on medical details</span>
            </Col>
            <Col md="9">
              <span>{user?.medical_details_remarks}</span>
            </Col>
          </Row>
        </Block>
      </Block>

      <Block>
        <BlockHead className="nk-block-head-line">
          <BlockTitle tag="h6" className="overline-title text-base">
            Medical \ General documents
            <Button
              color="primary"
              outline
              size="xs"
              className="d-none d-sm-inline-flex ml-3"
              onClick={() => {
                setUploadCategory("medical_general_document");
                setUploadModal(true);
              }}
            >
              <Icon name="plus"></Icon>
              <span>Upload document</span>
            </Button>
          </BlockTitle>
        </BlockHead>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col" className="w-min-60px">
                S/N
              </th>
              <th scope="col" className="w-min-150px">
                Document name
              </th>
              <th scope="col" className="w-min-150px">
                Document
              </th>
              <th scope="col" className="w-min-150px">
                Uploaded
              </th>
            </tr>
          </thead>
          <tbody>
            {user?.medical_general_documents?.map((doc, docIdx) => (
              <tr key={`medical_general_doc_${docIdx}`}>
                <td>{docIdx + 1}</td>
                <td>{doc?.name}</td>
                <td>
                  <Row className="align-items-center my-2">
                    <Col className="mx-2">{nioIconData["file-pdf"]}</Col>
                    <Col>
                      <a
                        href={`${process.env.PUBLIC_URL}/files/?key=${doc?.url}`}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <span>{doc?.name}</span>
                      </a>
                    </Col>
                  </Row>
                </td>
                <td>{moment(doc?.uploaded_time).format("YYYY-MM-DD hh:mm")}</td>
              </tr>
            ))}
            {(!user?.medical_general_documents ||
              user?.medical_general_documents?.length === 0) && (
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
            )}
          </tbody>
        </table>
      </Block>

      <ModalComponent
        isOpen={isEditModalOpen}
        backdrop={"static"}
        title={"Edit Medical Detail"}
        onModalCancel={() => {
          setEditModalOpen(false);
        }}
      >
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Row className="gy-3">
            <SimpleBar className="w-100" forceVisible="x" autoHide={false}>
              <div className="table-wrapper table-actions">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">S/N</th>
                      <th scope="col" className="w-min-250px">
                        Type <span className="required-asterisk">*</span>
                      </th>
                      <th scope="col" className="w-min-250px">
                        Condition <span className="required-asterisk">*</span>
                      </th>
                      <th scope="col" className="w-min-250px">
                        Year diagnosed{" "}
                        <span className="required-asterisk">*</span>
                      </th>
                      <th scope="col" className="w-min-150px">
                        Clinic
                      </th>
                      <th scope="col" className="w-min-150px">
                        Doctor
                      </th>
                      <th scope="col" className="w-min-150px">
                        Follow-up appointments
                      </th>
                      <th scope="col" className="w-min-300px">
                        Medications
                      </th>
                      <th scope="col" className="w-min-300px">
                        Side effects
                      </th>
                      <th scope="col" className="w-min-150px">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {fields.map((item, index) => {
                      return (
                        <tr key={item.id}>
                          <td>{index + 1}</td>
                          <td>
                            <SelectField
                              name={`medical_details.${index}.type`}
                              control={control}
                              menuPortalTarget={document.body}
                              rules={{ required: "This field is required" }}
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  maxWidth: "300px",
                                }),
                              }}
                              options={[
                                { value: "Parent", label: "Parent" },
                                {
                                  value: "Sibling",
                                  label: "Sibling",
                                },
                                { value: "Spouse", label: "Spouse" },
                                {
                                  value: "No primary caregiver",
                                  label: "No primary caregiver",
                                },
                                { value: "Other", label: "Other" },
                              ]}
                              errors={errors}
                              md="12"
                            />
                          </td>
                          <td>
                            <TextInput
                              name={`medical_details.${index}.condition`}
                              required
                              register={{
                                ...register(
                                  `medical_details.${index}.condition`,
                                  {
                                    required: "This field is required",
                                  }
                                ),
                              }}
                              errors={errors}
                              md="12"
                            />
                          </td>
                          <td>
                            <DatePickerInput
                              name={`medical_details.${index}.year_diagnosed`}
                              showYearPicker
                              dateFormat={"yyyy"}
                              placeholder={"yyyy"}
                              rules={{ required: "This field is required" }}
                              maxDate={new Date()}
                              control={control}
                              errors={errors}
                              portalId={`medical_details_${index}_year_diagnosed`}
                              md="12"
                            />
                          </td>
                          <td>
                            <TextInput
                              name={`medical_details.${index}.clinic`}
                              register={{
                                ...register(`medical_details.${index}.clinic`),
                              }}
                              errors={errors}
                              md="12"
                            />
                          </td>
                          <td>
                            <TextInput
                              name={`medical_details.${index}.doctor`}
                              register={{
                                ...register(`medical_details.${index}.doctor`),
                              }}
                              errors={errors}
                              md="12"
                            />
                          </td>
                          <td>
                            <TextInput
                              name={`medical_details.${index}.follow_up_appointments`}
                              register={{
                                ...register(
                                  `medical_details.${index}.follow_up_appointments`
                                ),
                              }}
                              errors={errors}
                              md="12"
                            />
                          </td>
                          <td>
                            <TextInput
                              name={`medical_details.${index}.medications`}
                              register={{
                                ...register(
                                  `medical_details.${index}.medications`
                                ),
                              }}
                              errors={errors}
                              md="12"
                            />
                          </td>
                          <td>
                            <TextInput
                              name={`medical_details.${index}.side_effects`}
                              register={{
                                ...register(
                                  `medical_details.${index}.side_effects`
                                ),
                              }}
                              errors={errors}
                              md="12"
                            />
                          </td>
                          <td>
                            {index === fields.length - 1 && (
                              <Icon
                                onClick={() => {
                                  append({
                                    type: "",
                                    year_diagnosed: null,
                                    condition: "",
                                    clinic: "",
                                    doctor: "",
                                    follow_up_appointments: "",
                                    medications: "",
                                    side_effects: "",
                                  });
                                }}
                                name="plus-c"
                                className="fs-22px mr-2"
                              />
                            )}
                            {((fields.length > 1 && index === 0) ||
                              index !== 0) && (
                              <Icon
                                onClick={() => {
                                  remove(index);
                                }}
                                name="trash"
                                className="fs-22px"
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </SimpleBar>
            <div>
              {errors["medical_details"]?.root && (
                <span className="table-invalid">
                  {errors["medical_details"]?.root?.message}
                </span>
              )}
            </div>
            <TextInput
              name={"medical_details_remarks"}
              label={"Remarks on medical details"}
              placeholder={"type here..."}
              register={{
                ...register("medical_details_remarks"),
              }}
              errors={errors}
              md="12"
            />

            <Col size="12" className="d-flex justify-content-end">
              <Button
                className="mr-2"
                color="light"
                type="button"
                onClick={onEditModalCancel}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={isEditUserLoading}
              >
                {isEditUserLoading && <Spinner type="grow" size="sm" />}
                <span>Submit</span>
              </Button>
            </Col>
          </Row>
        </form>
      </ModalComponent>
    </div>
  );
};

const EducationSection = ({
  sectionRef,
  setTab,
  setUploadCategory,
  setUploadModal,
}) => {
  const { user } = useContext(DataContext);
  // const {
  //   ref: inViewRef,
  //   inView,
  //   entry,
  // } = useInView({
  //   threshold: 0.2,
  //   onChange: (inView, entry) => {
  //     if (inView) {
  //       //setTab("education");
  //     }
  //   },
  // });

  // const setRefs = useCallback((node) => {
  //   sectionRef.current[5] = node;
  //   inViewRef(node);
  // });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    control,
    reset,
  } = useForm();
  const {
    fields: educationalFields,
    append: educationalAppend,
    remove: educationalRemove,
  } = useFieldArray({
    control,
    name: "education_details",
    rules: {
      required: "Minimum requirement is 1 row",
      minLength: {
        value: 1,
        message: "Minimum requirement is 1 row",
      },
    },
  });
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [
    editUser,
    {
      isLoading: isEditUserLoading,
      isSuccess: isEditUserSuccess,
      isError: isEditUserError,
      error: editUserError,
    },
  ] = useEditUserMutation();
  const defaultValues = {
    education_details: [
      {
        level: "",
        institution: "",
        course: "",
        from: null,
        to: null,
        grades: "",
        document_urls: {},
      },
    ],
  };

  useEffect(() => {
    if (isEditUserSuccess) {
      toast.success("Edit successfully!");
      setEditModalOpen(false);
    }
    if (isEditUserError && editUserError) {
      toast.error(editUserError?.data?.message);
    }
  }, [isEditUserSuccess, isEditUserError]);

  const onEditBtnClicked = () => {
    reset({
      education_details: user.education_details?.map((e) => ({
        ...e,
        level: e.level ? { value: e.level, label: e.level } : null,
        from: Date.parse(e.from),
        to: Date.parse(e.to),
      })),
      education_details_remarks: user.education_details_remarks,
    });
    setEditModalOpen(true);
  };

  const onFormSubmit = (data) => {
    data = convertUserData(data, defaultValues);
    data.id = user.id;

    editUser(data);
  };

  const onEditModalCancel = () => {
    setEditModalOpen(false);
  };

  return (
    <div ref={(ref) => (sectionRef.current[5] = ref)}>
      <Block>
        <BlockHead>
          <BlockTitle tag="h5">
            Education
            <Button
              color="primary"
              outline
              size="xs"
              className="d-none d-sm-inline-flex ml-3"
              onClick={onEditBtnClicked}
            >
              <Icon name="edit-alt-fill"></Icon>
              <span>Edit</span>
            </Button>
          </BlockTitle>
        </BlockHead>
        <Block className="overflow-auto">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col" className="w-min-60px">
                  S/N
                </th>
                <th scope="col" className="w-min-250px">
                  Education level
                </th>
                <th scope="col" className="w-min-250px">
                  Institution
                </th>
                <th scope="col" className="w-min-200px">
                  Course
                </th>
                <th scope="col" className="w-min-150px">
                  From
                </th>
                <th scope="col" className="w-min-150px">
                  To
                </th>
                <th scope="col" className="w-min-200px">
                  Grades
                </th>
                <th scope="col" className="w-min-400px">
                  Documents
                </th>
                <th scope="col" className="w-min-150px"></th>
              </tr>
            </thead>
            <tbody>
              {user?.education_details?.map((e, idx) => {
                let key = `education_details_${idx}`;
                return (
                  <tr key={key}>
                    <td>{idx + 1}</td>
                    <td>{e?.level}</td>
                    <td>{e?.institution}</td>
                    <td>{e?.course}</td>
                    <td>
                      {e?.from ? moment(e?.from).format("MMM YYYY") : null}
                    </td>
                    <td>{e?.to ? moment(e?.to).format("MMM YYYY") : null}</td>
                    <td>{e?.grades}</td>
                    <td>
                      {e?.document_urls?.map((doc, docIdx) => (
                        <Row
                          key={`${key}_doc_${docIdx}`}
                          className="align-items-center my-2"
                        >
                          <Col>{nioIconData["file-pdf"]}</Col>
                          <Col md="6">
                            <a
                              href={`${process.env.PUBLIC_URL}/files/?key=${doc?.url}`}
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              <span>{doc?.name}</span>
                            </a>
                          </Col>
                          <Col md="4">
                            <span>{`Uploaded: ${moment(
                              doc?.uploaded_time
                            ).format("YYYY-MM-DD hh:mm")}`}</span>
                          </Col>
                        </Row>
                      ))}
                    </td>
                    <td>
                      <React.Fragment>
                        <Button
                          className="btn-icon"
                          id={`upload-btn-${key}`}
                          color="light"
                          size="xs"
                          onClick={() => {
                            setUploadCategory(`education_details_${idx}`);
                            setUploadModal(true);
                          }}
                        >
                          <Icon name="upload" />
                        </Button>
                        {/* <UncontrolledTooltip
                          placement="top"
                          target={`upload-btn-${key}`}
                        >
                          Upload
                        </UncontrolledTooltip> */}
                      </React.Fragment>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Row className="mt-4 fs-16px">
            <Col md="3">
              <span>General remarks on Education details</span>
            </Col>
            <Col md="9">
              <span>{user?.education_details_remarks}</span>
            </Col>
          </Row>
        </Block>
      </Block>

      <Block>
        <BlockHead className="nk-block-head-line">
          <BlockTitle tag="h6" className="overline-title text-base">
            Education \ General documents
            <Button
              color="primary"
              outline
              size="xs"
              className="d-none d-sm-inline-flex ml-3"
              onClick={() => {
                setUploadCategory("education_general_document");
                setUploadModal(true);
              }}
            >
              <Icon name="plus"></Icon>
              <span>Upload document</span>
            </Button>
          </BlockTitle>
        </BlockHead>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col" className="w-min-60px">
                S/N
              </th>
              <th scope="col" className="w-min-150px">
                Document name
              </th>
              <th scope="col" className="w-min-150px">
                Document
              </th>
              <th scope="col" className="w-min-150px">
                Uploaded
              </th>
            </tr>
          </thead>
          <tbody>
            {user?.education_general_documents?.map((doc, docIdx) => (
              <tr key={`education_general_doc_${docIdx}`}>
                <td>{docIdx + 1}</td>
                <td>{doc?.name}</td>
                <td>
                  <Row className="align-items-center my-2">
                    <Col className="mx-2">{nioIconData["file-pdf"]}</Col>
                    <Col>
                      <a
                        href={`${process.env.PUBLIC_URL}/files/?key=${doc?.url}`}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <span>{doc?.name}</span>
                      </a>
                    </Col>
                  </Row>
                </td>
                <td>{moment(doc?.uploaded_time).format("YYYY-MM-DD hh:mm")}</td>
              </tr>
            ))}
            {(!user?.education_general_documents ||
              user?.education_general_documents?.length === 0) && (
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
            )}
          </tbody>
        </table>
      </Block>

      <ModalComponent
        isOpen={isEditModalOpen}
        backdrop={"static"}
        title={"Edit Education Detail"}
        onModalCancel={() => {
          setEditModalOpen(false);
        }}
      >
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Row className="gy-3">
            <SimpleBar className="w-100" forceVisible="x" autoHide={false}>
              <div className="table-wrapper table-actions">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">S/N</th>
                      <th scope="col" className="w-min-300px">
                        Education level{" "}
                        <span className="required-asterisk">*</span>
                      </th>
                      <th scope="col" className="w-min-250px">
                        Institution <span className="required-asterisk">*</span>
                      </th>
                      <th scope="col" className="w-min-250px">
                        Course
                      </th>
                      <th scope="col" className="w-min-150px">
                        From
                      </th>
                      <th scope="col" className="w-min-150px">
                        To
                      </th>
                      <th scope="col" className="w-min-200px">
                        Grades
                      </th>
                      <th scope="col" className="w-min-150px">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {educationalFields.map((item, index) => {
                      return (
                        <tr key={item.id}>
                          <td>{index + 1}</td>
                          <td>
                            <SelectField
                              name={`education_details.${index}.level`}
                              control={control}
                              menuPortalTarget={document.body}
                              rules={{ required: "This field is required" }}
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  maxWidth: "300px",
                                }),
                              }}
                              options={[
                                { value: "PSLE", label: "PSLE" },
                                {
                                  value: "GCE N-Level",
                                  label: "GCE N-Level",
                                },
                                {
                                  value: "GCE O-Level",
                                  label: "GCE O-Level",
                                },
                                {
                                  value: "GCE A-Level",
                                  label: "GCE A-Level",
                                },
                                {
                                  value: "Nitec",
                                  label: "Nitec",
                                },
                                {
                                  value: "Higher Nitec",
                                  label: "Higher Nitec",
                                },
                                {
                                  value: "Diploma",
                                  label: "Diploma",
                                },
                                {
                                  value: "Bachelor's",
                                  label: "Bachelor's",
                                },
                                {
                                  value: "Masters",
                                  label: "Masters",
                                },
                                {
                                  value: "PHD",
                                  label: "PHD",
                                },
                                {
                                  value: "Others",
                                  label: "Others",
                                },
                              ]}
                              errors={errors}
                              md="12"
                            />
                          </td>
                          <td>
                            <TextInput
                              name={`education_details.${index}.institution`}
                              required
                              register={{
                                ...register(
                                  `education_details.${index}.institution`,
                                  { required: "This field is required" }
                                ),
                              }}
                              errors={errors}
                              md="12"
                            />
                          </td>
                          <td>
                            <TextInput
                              name={`education_details.${index}.course`}
                              register={{
                                ...register(
                                  `education_details.${index}.course`
                                ),
                              }}
                              errors={errors}
                              md="12"
                            />
                          </td>
                          <td>
                            <div>
                              <DatePickerInput
                                name={`education_details.${index}.from`}
                                showYearPicker
                                dateFormat={"yyyy"}
                                placeholder={"yyyy"}
                                maxDate={new Date()}
                                control={control}
                                errors={errors}
                                portalId={`education_details_${index}_from`}
                                md="12"
                              />
                            </div>
                          </td>
                          <td>
                            <DatePickerInput
                              name={`education_details.${index}.to`}
                              showYearPicker
                              dateFormat={"yyyy"}
                              placeholder={"yyyy"}
                              maxDate={new Date()}
                              control={control}
                              errors={errors}
                              portalId={`education_details_${index}_to`}
                              md="12"
                            />
                          </td>
                          <td>
                            <TextInput
                              name={`education_details.${index}.grades`}
                              register={{
                                ...register(
                                  `education_details.${index}.grades`
                                ),
                              }}
                              errors={errors}
                              md="12"
                            />
                          </td>
                          <td>
                            {index === educationalFields.length - 1 && (
                              <Icon
                                onClick={() => {
                                  educationalAppend({
                                    level: "",
                                    institution: "",
                                    course: "",
                                    from: null,
                                    to: null,
                                    grades: "",
                                  });
                                }}
                                name="plus-c"
                                className="fs-22px mr-2"
                              />
                            )}
                            {((educationalFields.length > 1 && index === 0) ||
                              index !== 0) && (
                              <Icon
                                onClick={() => {
                                  educationalRemove(index);
                                }}
                                name="trash"
                                className="fs-22px"
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </SimpleBar>
            <div>
              {errors["education_details"]?.root && (
                <span className="table-invalid">
                  {errors["education_details"]?.root?.message}
                </span>
              )}
            </div>
            <TextInput
              name={"education_details_remarks"}
              label={"Remarks on educational details"}
              placeholder={"type here..."}
              register={{
                ...register("education_details_remarks"),
              }}
              errors={errors}
              md="12"
            />

            <Col size="12" className="d-flex justify-content-end">
              <Button
                className="mr-2"
                color="light"
                type="button"
                onClick={onEditModalCancel}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={isEditUserLoading}
              >
                {isEditUserLoading && <Spinner type="grow" size="sm" />}
                <span>Submit</span>
              </Button>
            </Col>
          </Row>
        </form>
      </ModalComponent>
    </div>
  );
};

const AssessmentSection = ({ sectionRef, setTab }) => {
  const navigate = useNavigate();
  const { user, logs, pipelines, assessment } = useContext(DataContext);
  const [isStageModalOpen, setStageModal] = useState(false);
  const [isLogModalOpen, setLogModal] = useState(false);
  const [isEditLogModalOpen, setEditLogModal] = useState(false);
  const [isRemoveLogModalOpen, setRemoveLogModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const defaultValues = {
    follow_up_required: false,
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    control,
    reset,
  } = useForm();
  const {
    register: registerLogForm,
    handleSubmit: handleLogFormSubmit,
    formState: { errors: errorsLogForm },
    control: controlLogForm,
    reset: resetLogForm,
  } = useForm({ defaultValues: defaultValues });
  const {
    register: registerEditLogForm,
    handleSubmit: handleEditLogFormSubmit,
    formState: { errors: errorsEditLogForm },
    control: controlEditLogForm,
    reset: resetEditLogForm,
  } = useForm();

  const [
    getPipelineConfig,
    {
      data: pipelineConfig,
      isFetching,
      isSuccess: isGetConfigSuccess,
      isError: isGetConfigError,
      error,
    },
  ] = useLazyGetPipelineConfigQuery();
  const [
    updatePipeline,
    {
      data: updatedPipeline,
      isLoading: isUpdatePipelineLoading,
      isError: isUpdatePipelineError,
      isSuccess: isUpdatePipelineSuccess,
      error: updatePipelineError,
    },
  ] = useUpdatePipelineStageMutation();
  const [
    addUserLog,
    {
      isLoading: isAddUserLogLoading,
      isError: isAddUserLogError,
      isSuccess: isAddUserLogSuccess,
      error: addUserLogError,
    },
  ] = useAddUserLogMutation();
  const [
    editUserLog,
    {
      isLoading: isEditUserLogLoading,
      isError: isEditUserLogError,
      isSuccess: isEditUserLogSuccess,
      error: editUserLogError,
    },
  ] = useEditUserLogMutation();
  const [
    removeUserLog,
    {
      isLoading: isRemoveUserLogLoading,
      isError: isRemoveUserLogError,
      isSuccess: isRemoveUserLogSuccess,
      error: removeUserLogError,
    },
  ] = useRemoveUserLogMutation();
  const [
    getInteractionTypesOptions,
    {
      data: interactionTypeData,
      isFetching: isGetInteractionTypesFetching,
      isSuccess: isGetInteractionTypesSuccess,
      isError: isGetInteractionTypesError,
      error: getInteractionTypesError,
    },
  ] = useLazyGetInteractionTypesQuery();
  const [interactionTypeOptions, setInteractionTypeOptions] = useState([]);
  const [stageOptions, setStageOptions] = useState([]);

  const onFormSubmit = (data) => {
    updatePipeline({
      stage: {
        name: data.stage.value,
        status: data.status.value,
      },
      type: "onboarding",
      user_id: user?.id,
    });
  };

  const onLogFormSubmit = (data) => {
    data.direction = data.direction.value;
    data.interaction_type = data?.interaction_type?.value;
    addUserLog({
      ...data,
      type: "onboarding",
      user_id: user?.id,
    });
  };

  const onEditLogFormSubmit = (data) => {
    data.direction = data.direction.value;
    data.interaction_type = data?.interaction_type?.value;
    editUserLog({
      ...data,
      type: "onboarding",
      user_id: user?.id,
    });
  };

  const onAddLogClicked = () => {
    getInteractionTypesOptions(undefined, true);
    resetLogForm();
    setLogModal(true);
  };

  const onEditLogClicked = (item) => {
    getInteractionTypesOptions(undefined, true);
    resetEditLogForm(
      {
        ...item,
        direction: {
          value: item.direction,
          label: capitalize(item.direction),
        },
      },
      {
        keepDefaultValues: true,
      }
    );
    setEditLogModal(true);
  };

  useEffect(() => {
    if (
      isGetInteractionTypesSuccess &&
      interactionTypeData?.interaction_types
    ) {
      let data = interactionTypeData.interaction_types.map((e) => {
        return { value: e.id, label: e.type };
      });
      setInteractionTypeOptions(data);
    }
    if (isGetInteractionTypesError && getInteractionTypesError) {
      toast.error(getInteractionTypesError?.data?.message);
    }
  }, [isGetInteractionTypesSuccess, isGetInteractionTypesError]);

  useEffect(() => {
    if (isStageModalOpen) {
      getPipelineConfig(
        {
          type: "onboarding",
          limit: 1000,
        },
        true
      );
    }
  }, [getPipelineConfig, isStageModalOpen]);

  useEffect(() => {
    if (isGetConfigSuccess && pipelineConfig?.stages) {
      let data = pipelineConfig?.stages?.map((e) => {
        return { value: e.name, label: e.name };
      });
      setStageOptions(data);
    }
  }, [pipelineConfig?.stages, isGetConfigSuccess, isGetConfigError]);

  useEffect(() => {
    if (isUpdatePipelineSuccess) {
      setStageModal(false);
    }
  }, [isUpdatePipelineSuccess, isUpdatePipelineError]);

  useEffect(() => {
    if (isAddUserLogSuccess) {
      toast.success("Add log successfully!");
      setLogModal(false);
    }
    if (isAddUserLogError && addUserLogError) {
      toast.error(addUserLogError?.data?.message);
    }
  }, [isAddUserLogSuccess, isAddUserLogError]);

  useEffect(() => {
    if (isEditUserLogSuccess) {
      toast.success("Edit log successfully!");
      setEditLogModal(false);
    }
    if (isEditUserLogError && editUserLogError) {
      toast.error(editUserLogError?.data?.message);
    }
  }, [isEditUserLogSuccess, isEditUserLogError]);

  useEffect(() => {
    if (isRemoveUserLogSuccess) {
      toast.success("Remove log successfully!");
      setRemoveLogModal(false);
    }
    if (isRemoveUserLogError && removeUserLogError) {
      toast.error(removeUserLogError?.data?.message);
    }
  }, [isRemoveUserLogSuccess, isRemoveUserLogError]);
  // const {
  //   ref: inViewRef,
  //   inView,
  //   entry,
  // } = useInView({
  //   threshold: 0.2,
  //   onChange: (inView, entry) => {
  //     if (inView) {
  //       //setTab("assessment");
  //     }
  //   },
  // });

  // const setRefs = useCallback((node) => {
  //   sectionRef.current[6] = node;
  //   inViewRef(node);
  // });

  return (
    <div ref={(ref) => (sectionRef.current[6] = ref)}>
      <Block>
        <BlockBetween className="mb-2">
          <BlockHead>
            <BlockTitle tag="h5">Assessment</BlockTitle>
          </BlockHead>
          <BlockHead>
            <Button
              color="primary"
              outline
              size="xs"
              className="d-none d-sm-inline-flex ml-3"
              onClick={() => {
                reset();
                setStageModal(true);
              }}
            >
              <Icon name="plus"></Icon>
              <span>Add/Edit Stage</span>
            </Button>
          </BlockHead>
        </BlockBetween>
        <Block className="overflow-auto">
          <Row>
            <Col
              md="2"
              className="d-flex flex-column text-center justify-content-center pr-0"
            >
              <p className="m-0">{pipelines?.assessment_pipeline?.code}</p>
              <p>
                {user?.first_name} {user?.last_name}
              </p>
              {/* <div style={{ textAlign: "center" }}></div> */}
            </Col>
            <Col md="10" className="p-0">
              <ul className="timeline-steps">
                {pipelines?.assessment_pipeline?.stages?.map((e, idx) => {
                  return (
                    <li
                      className="timeline-content"
                      style={{ textAlign: "center" }}
                      key={`timeline-steps-${idx}`}
                    >
                      <span>{e.name}</span>
                    </li>
                  );
                })}
              </ul>
              <ul className="timeline-steps">
                {pipelines?.assessment_pipeline?.stages?.map((e, idx) => {
                  const idxTp =
                    pipelines?.assessment_pipeline?.stages?.findIndex(
                      (e) => e.status === "not_completed"
                    );
                  return (
                    <li
                      key={`onboarding-pipeline-${idx}`}
                      className={classNames({
                        "timeline-step": true,
                        completed: e.is_completed,
                        skipped: e.status === "skipped",
                        "last-active-stage": idx === idxTp - 1,
                      })}
                    >
                      <div
                        className={classNames({
                          "timeline-status": true,
                          "bg-primary": e.status === "completed",
                          // completed: e.is_completed,
                        })}
                      ></div>
                      <div
                        className="timeline-content"
                        style={{ textAlign: "center" }}
                      >
                        {e.status === "completed" ? (
                          <>
                            <strong>{moment(e.time).fromNow()}</strong>
                            <p>{moment(e.time).format("DD MMM YYYY")}</p>
                          </>
                        ) : null}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </Col>
          </Row>
        </Block>
      </Block>

      <Block>
        <BlockBetween className="mb-1">
          <BlockHead className="nk-block-head-line">
            <BlockTitle tag="h6" className="overline-title text-base">
              Assessment \ Log
            </BlockTitle>
          </BlockHead>
          <BlockHead className="nk-block-head-line">
            <Button
              color="primary"
              outline
              size="xs"
              className="d-none d-sm-inline-flex ml-3"
              onClick={onAddLogClicked}
            >
              <Icon name="plus"></Icon>
              <span>Add log</span>
            </Button>
          </BlockHead>
        </BlockBetween>
        <Block className="overflow-auto">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col" className="w-min-150px">
                  Date
                </th>
                <th scope="col" className="w-min-100px">
                  Time
                </th>
                <th scope="col" className="w-min-150px">
                  Duration
                </th>
                <th scope="col" className="w-min-150px">
                  Direction
                </th>
                <th scope="col" className="w-min-150px">
                  Interaction Type
                </th>
                <th scope="col" className="w-min-150px">
                  Purpose
                </th>
                <th scope="col" className="w-min-150px">
                  Purpose ID
                </th>
                <th scope="col" className="w-min-150px">
                  Pipeline
                </th>
                <th scope="col" className="w-min-150px">
                  By who
                </th>
                <th scope="col" className="w-min-150px">
                  With who
                </th>
                <th scope="col" className="w-min-150px">
                  Follow up required
                </th>
                <th scope="col" className="w-min-250px">
                  Notes
                </th>
                <th scope="col" className="w-min-100px"></th>
              </tr>
            </thead>
            <tbody>
              {logs?.assessment_logs?.map((e, idx) => {
                return (
                  <tr key={`assessment-log-${e.id}`}>
                    <td>{moment(e.time).format("DD MMM YYYY")}</td>
                    <td>{moment(e.time).format("LT")}</td>
                    <td>{formatDuration(e?.duration)}</td>
                    <td>{e.direction}</td>
                    <td>{e.interaction_type}</td>
                    <td>{e.purpose}</td>
                    <td></td>
                    <td></td>
                    <td>{e.created_by}</td>
                    <td>{e.with}</td>
                    <td>{e.follow_up_required ? "Yes" : "No"}</td>
                    <td className="white-space-pre-line">{e.notes}</td>
                    <td>
                      {e.is_editable ? (
                        <React.Fragment>
                          <Button
                            className="btn-icon"
                            id={`assessment-log-edit-btn-${e.id}`}
                            color="light"
                            size="xs"
                            onClick={() => {
                              onEditLogClicked(e);
                            }}
                          >
                            <Icon name="edit" />
                          </Button>
                          <UncontrolledTooltip
                            placement="top"
                            target={`assessment-log-edit-btn-${e.id}`}
                          >
                            Edit
                          </UncontrolledTooltip>
                          <Button
                            className="btn-icon ml-2"
                            id={`assessment-log-remove-btn-${e.id}`}
                            color="light"
                            size="xs"
                            onClick={() => {
                              setSelectedItem(e);
                              setRemoveLogModal(true);
                            }}
                          >
                            <Icon name="trash" />
                          </Button>
                          <UncontrolledTooltip
                            placement="top"
                            target={`assessment-log-remove-btn-${e.id}`}
                          >
                            Remove
                          </UncontrolledTooltip>
                        </React.Fragment>
                      ) : null}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Block>
      </Block>

      <Block>
        <BlockHead className="nk-block-head-line">
          <BlockTitle tag="h6" className="overline-title text-base">
            Assessment \ Outcome
          </BlockTitle>
        </BlockHead>
        <Block className="overflow-auto">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col" className="w-min-120px">
                  Reference code
                </th>
                <th scope="col" className="w-min-120px">
                  Date
                </th>
                <th scope="col" className="w-min-100px">
                  Time
                </th>
                <th scope="col" className="w-min-120px">
                  Assessed by
                </th>
                <th scope="col" className="w-min-175px">
                  Punctuality
                </th>
                <th scope="col" className="w-min-150px">
                  Accompanied by
                </th>
                <th scope="col" className="w-min-175px">
                  Recommendations
                </th>
                <th scope="col" className="w-min-150px">
                  Assessment status
                </th>
                <th scope="col" className="w-min-300px">
                  Feedback &amp; comments
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Link
                    to={`/forms/assessment/${assessment?.reference_code}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {assessment?.reference_code}
                  </Link>
                </td>
                <td>
                  {assessment?.date_of_assessment
                    ? moment(assessment?.date_of_assessment)?.format(
                        "DD MMM YYYY"
                      )
                    : ""}
                </td>
                <td>
                  {assessment?.time_of_assessment
                    ? moment(assessment?.time_of_assessment)?.format("hh:mm A")
                    : ""}
                </td>
                <td>{assessment?.assessor_name}</td>
                <td>{assessment?.punctuality_notes}</td>
                <td>{assessment?.accompanied_by}</td>
                <td>
                  {assessment?.assessment_recommendation?.map((e, idx) => {
                    if (e !== "Not suitable") {
                      return (
                        <div key={`assessment-outcome-recommendation-${idx}`}>
                          <span>{`Suitable for ${e.toLowerCase()}`}</span>
                          <br />
                        </div>
                      );
                    }
                    return (
                      <span key={`assessment-outcome-recommendation-${idx}`}>
                        {e}
                      </span>
                    );
                  })}
                </td>
                <td>{assessment?.status ?? "Not created"}</td>
                <td>{assessment?.feedback}</td>
              </tr>
            </tbody>
          </table>
        </Block>
        {/* <Row className="mt-4 fs-16px">
          <Col md="3">
            <span>Can travel independently?</span>
          </Col>
          <Col md="9">
            <span>{assessment?.is_travel_independently}</span>
          </Col>
        </Row>
        <Row className="mt-4 fs-16px">
          <Col md="3">
            <span>Feedback &amp; comments</span>
          </Col>
          <Col md="9">
            <span>
              Will need intense support at the start. Should be able to
              transition towards independence with a proper structured plan
            </span>
          </Col>
        </Row> */}
      </Block>

      <ModalComponent
        isOpen={isStageModalOpen}
        backdrop={"static"}
        title={"Add/Edit Stage"}
        onModalCancel={() => {
          setStageModal(false);
        }}
      >
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Row className="gy-3">
            <SelectField
              name={"stage"}
              label={"Stage"}
              control={control}
              rules={{ required: "This field is required" }}
              isSearchable={false}
              options={stageOptions}
              errors={errors}
              md="12"
            />
            <SelectField
              name={"status"}
              label={"Status"}
              control={control}
              rules={{ required: "This field is required" }}
              isSearchable={false}
              options={pipelineStatusOptions}
              errors={errors}
              md="12"
            />
            <Col size="12">
              <Button
                color="primary"
                type="submit"
                disabled={isUpdatePipelineLoading}
              >
                {isUpdatePipelineLoading && <Spinner type="grow" size="sm" />}
                <span>Submit</span>
              </Button>
            </Col>
          </Row>
        </form>
      </ModalComponent>

      <ModalComponent
        isOpen={isLogModalOpen}
        backdrop={"static"}
        title={"Add Log"}
        onModalCancel={() => {
          setLogModal(false);
        }}
      >
        <form onSubmit={handleLogFormSubmit(onLogFormSubmit)}>
          <Row className="gy-3">
            <SelectField
              name={"direction"}
              label={"Direction"}
              control={controlLogForm}
              rules={{ required: "This field is required" }}
              isSearchable={false}
              options={logDirectionOptions}
              errors={errorsLogForm}
              md="12"
            />
            <SelectField
              name={"interaction_type"}
              label={"Interaction Type"}
              control={controlLogForm}
              // rules={{ required: "This field is required" }}
              options={interactionTypeOptions}
              isSearchable={false}
              isLoading={isGetInteractionTypesFetching}
              isDisabled={isGetInteractionTypesFetching}
              errors={errorsLogForm}
              md="12"
            />
            <TextInput
              name={"purpose"}
              label={"Purpose"}
              register={{
                ...registerLogForm("purpose", {}),
              }}
              errors={errorsLogForm}
              md="12"
            />
            <TextareaInput
              name={"notes"}
              label={"Notes"}
              required
              register={{
                ...registerLogForm("notes", {
                  required: "This field is required",
                }),
              }}
              errors={errorsLogForm}
              md="12"
            />
            <CheckboxInput
              name={"follow_up_required"}
              label={"Follow up required"}
              register={{
                ...registerLogForm("follow_up_required"),
              }}
              errors={errorsLogForm}
              md="12"
            />
            <Col size="12">
              <Button
                color="primary"
                type="submit"
                disabled={isAddUserLogLoading}
              >
                {isAddUserLogLoading && <Spinner type="grow" size="sm" />}
                <span>Submit</span>
              </Button>
            </Col>
          </Row>
        </form>
      </ModalComponent>

      <ModalComponent
        isOpen={isEditLogModalOpen}
        backdrop={"static"}
        title={"Edit Log"}
        onModalCancel={() => {
          setEditLogModal(false);
        }}
      >
        <form onSubmit={handleEditLogFormSubmit(onEditLogFormSubmit)}>
          <Row className="gy-3">
            <SelectField
              name={"direction"}
              label={"Direction"}
              control={controlEditLogForm}
              rules={{ required: "This field is required" }}
              isSearchable={false}
              options={logDirectionOptions}
              errors={errorsEditLogForm}
              md="12"
            />
            <TextInput
              name={"interaction_type"}
              label={"Interaction Type"}
              register={{
                ...registerEditLogForm("interaction_type", {}),
              }}
              errors={errorsEditLogForm}
              md="12"
            />
            <TextInput
              name={"purpose"}
              label={"Purpose"}
              register={{
                ...registerEditLogForm("purpose", {}),
              }}
              errors={errorsEditLogForm}
              md="12"
            />
            <TextareaInput
              name={"notes"}
              label={"Notes"}
              required
              register={{
                ...registerEditLogForm("notes", {
                  required: "This field is required",
                }),
              }}
              errors={errorsEditLogForm}
              md="12"
            />
            <CheckboxInput
              name={"follow_up_required"}
              label={"Follow up required"}
              register={{
                ...registerEditLogForm("follow_up_required"),
              }}
              errors={errorsEditLogForm}
              md="12"
            />
            <Col size="12">
              <Button
                color="primary"
                type="submit"
                disabled={isEditUserLogLoading}
              >
                {isEditUserLogLoading && <Spinner type="grow" size="sm" />}
                <span>Submit</span>
              </Button>
            </Col>
          </Row>
        </form>
      </ModalComponent>

      <ModalComponent
        isOpen={isRemoveLogModalOpen}
        title={"Are you sure you want to remove this log?"}
        disableCloseBtn={true}
        toggle={() => {
          setRemoveLogModal(false);
        }}
        onModalCancel={() => {
          setRemoveLogModal(false);
        }}
      >
        <Row className="gy-3">
          <Col size="12">
            <Button
              color="primary"
              type="submit"
              disabled={isRemoveUserLogLoading}
              onClick={() => {
                removeUserLog(selectedItem);
              }}
            >
              {isRemoveUserLogLoading && <Spinner type="grow" size="sm" />}
              <span>Yes</span>
            </Button>
            <Button
              className="ml-3"
              color="light"
              onClick={() => {
                setRemoveLogModal(false);
              }}
            >
              <span>No</span>
            </Button>
          </Col>
        </Row>
      </ModalComponent>
    </div>
  );
};

const TrainingSection = ({ sectionRef, setTab }) => {
  // const {
  //   ref: inViewRef,
  //   inView,
  //   entry,
  // } = useInView({
  //   threshold: 0.2,
  //   onChange: (inView, entry) => {
  //     if (inView) {
  //       //setTab("training");
  //     }
  //   },
  // });

  const setRefs = useCallback((node) => {
    sectionRef.current[7] = node;
    // inViewRef(node);
  });

  return (
    <div ref={setRefs}>
      <Block className="overflow-auto">
        <BlockHead>
          <BlockTitle tag="h5">Training</BlockTitle>
        </BlockHead>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col" className="w-min-125px">
                From
              </th>
              <th scope="col" className="w-min-125px">
                To
              </th>
              <th scope="col" className="w-min-250px">
                Name
              </th>
              <th scope="col" className="w-min-200px">
                Traning provider
              </th>
              <th scope="col" className="w-min-200px">
                Venue
              </th>
              <th scope="col" className="w-min-400px">
                Documents
              </th>
              <th scope="col" className="w-min-150px"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            {/* <tr>
              <td>01 Jul 2022</td>
              <td>01 Jul 2022</td>
              <td>ITE internship preparation workshop</td>
              <td>Inclus</td>
              <td>Online</td>
              <td>
                <Row className="justify-content-around align-items-center my-2">
                  <Col md="2">{nioIconData["file-pdf"]}</Col>
                  <Col md="6">
                    <span>KKH disability report.pdf</span>
                  </Col>
                  <Col md="4">
                    <span>Uploaded: 2022-08-31 15:47</span>
                  </Col>
                </Row>
                <Row className="justify-content-around align-items-center">
                  <Col md="2">{nioIconData["file-pdf"]}</Col>
                  <Col md="6">
                    <span>KKH disability report.pdf </span>
                  </Col>
                  <Col md="4">
                    <span>Uploaded: 2022-08-31 15:47</span>
                  </Col>
                </Row>
              </td>
            </tr>
            <tr>
              <td>01 Jul 2022</td>
              <td>01 Jul 2022</td>
              <td>Digital marketing 101</td>
              <td>Inclus</td>
              <td>Temasek Polytechnic</td>
              <td>
                <Row className="justify-content-around align-items-center my-2">
                  <Col md="2">{nioIconData["file-pdf"]}</Col>
                  <Col md="6">
                    <span>KKH disability report.pdf</span>
                  </Col>
                  <Col md="4">
                    <span>Uploaded: 2022-08-31 15:47</span>
                  </Col>
                </Row>
                <Row className="justify-content-around align-items-center">
                  <Col md="2">{nioIconData["file-pdf"]}</Col>
                  <Col md="6">
                    <span>KKH disability report.pdf </span>
                  </Col>
                  <Col md="4">
                    <span>Uploaded: 2022-08-31 15:47</span>
                  </Col>
                </Row>
              </td>
            </tr> */}
          </tbody>
        </table>
      </Block>

      <Block>
        <BlockHead className="nk-block-head-line">
          <BlockTitle tag="h6" className="overline-title text-base">
            Training \ General documents
          </BlockTitle>
        </BlockHead>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col" className="w-min-60px">
                S/N
              </th>
              <th scope="col" className="w-min-150px">
                Document name
              </th>
              <th scope="col" className="w-min-150px">
                Document
              </th>
              <th scope="col" className="w-min-150px">
                Uploaded
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>
      </Block>
    </div>
  );
};

const EmploymentSection = ({ sectionRef, setTab }) => {
  const { user } = useContext(DataContext);
  // const {
  //   ref: inViewRef,
  //   inView,
  //   entry,
  // } = useInView({
  //   threshold: 0.2,
  //   onChange: (inView, entry) => {
  //     if (inView) {
  //       //setTab("employment");
  //     }
  //   },
  // });

  // const setRefs = useCallback((node) => {
  //   sectionRef.current[8] = node;
  //   inViewRef(node);
  // });

  return (
    <div ref={(ref) => (sectionRef.current[8] = ref)}>
      {/* <Block className="overflow-auto">
        <BlockHead>
          <BlockTitle tag="h5">Employment</BlockTitle>
        </BlockHead>
        <BlockHead className="nk-block-head-line">
          <BlockTitle tag="h6" className="overline-title text-base">
            Employment \ Employment records
          </BlockTitle>
        </BlockHead>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col" className="w-min-300px">
                Workplace
              </th>
              <th scope="col" className="w-min-300px">
                Working hours
              </th>
              <th scope="col" className="w-min-300px">
                Lunch time
              </th>
              <th scope="col" className="w-min-300px">
                Job scope
              </th>
              <th scope="col" className="w-min-300px">
                Job tasks
              </th>
            </tr>
          </thead>
          <tbody>
            {user?.employment_details?.map((e, idx) => {
              return (
                <tr key={`employment_details-${idx}`}>
                  <td>{moment(e?.from).format("MMM YYYY")}</td>
                  <td>{moment(e?.to).format("MMM YYYY")}</td>
                  <td>{e?.employer}</td>
                  <td></td>
                  <td>{e?.type}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Block> */}

      <Block className="overflow-auto">
        <BlockHead>
          <BlockTitle tag="h5">Employment</BlockTitle>
        </BlockHead>
        <BlockHead className="nk-block-head-line">
          <BlockTitle tag="h6" className="overline-title text-base">
            Employment \ Attendance
          </BlockTitle>
        </BlockHead>
        {user?.employment_data?.attendance ? (
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col" className="w-min-150px">
                  Check in
                </th>
                <th scope="col" className="w-min-150px">
                  Check out
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {/* {moment(
                    user?.employment_data?.attendance?.created_time
                  ).format("DD-MM-YYYY, hh:mm:ss a")}
                  &nbsp;
                  {user?.employment_data?.attendance?.is_check_in_far && (
                    <span className="text-danger">(Far)</span>
                  )} */}
                  {(() => {
                    if (user?.employment_data?.attendance?.is_checked_in) {
                      return (
                        <span>
                          {moment(
                            user?.employment_data?.attendance?.created_time
                          ).format("DD-MM-YYYY, hh:mm:ss a")}{" "}
                          {user?.employment_data?.attendance
                            ?.is_check_in_far && (
                            <span className="text-danger">(Far)</span>
                          )}
                        </span>
                      );
                    }
                    return "Not checked in";
                  })()}
                </td>
                <td>
                  {(() => {
                    if (user?.employment_data?.attendance?.is_checked_out) {
                      return (
                        <span>
                          {moment(
                            user?.employment_data?.attendance?.last_updated_time
                          ).format("DD-MM-YYYY, hh:mm:ss a")}
                          {user?.employment_data?.attendance
                            ?.is_check_out_far && (
                            <span className="text-danger">(Far)</span>
                          )}
                        </span>
                      );

                      // return (
                      // {user?.employment_data?.attendance?.is_check_out_far && (
                      //   <span className="text-danger">(Far)</span>
                      // )};
                      // );
                    }
                    return "Not checked out";
                  })()}
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <div className="d-flex justify-content-center no-data-box">
            <div className="p-2">No data</div>
          </div>
        )}
      </Block>

      <Block className="overflow-auto">
        <BlockHead className="nk-block-head-line">
          <BlockTitle tag="h6" className="overline-title text-base">
            Employment \ Support Logs
          </BlockTitle>
        </BlockHead>
        {user?.employment_data?.support_log ? (
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col" className="w-min-100px">
                  By
                </th>
                <th scope="col" className="w-min-150px">
                  Location
                </th>
                <th scope="col" className="w-min-150px">
                  Work type
                </th>
                <th scope="col" className="w-min-200px">
                  Start at
                </th>
                <th scope="col" className="w-min-200px">
                  End at
                </th>
                <th scope="col" className="w-min-300px">
                  Metrics
                </th>
                <th scope="col" className="w-min-250px">
                  Observation Grading
                </th>
                <th scope="col" className="w-min-300px">
                  Observations
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{user?.employment_data?.support_log?.email}</td>
                <td>{user?.employment_data?.support_log?.location}</td>
                <td>{user?.employment_data?.support_log?.work_type}</td>
                <td>
                  {moment(user?.employment_data?.support_log?.start_at).format(
                    "DD-MM-YYYY, hh:mm:ss a"
                  )}
                </td>
                <td>
                  {moment(user?.employment_data?.support_log?.end_at).format(
                    "DD-MM-YYYY, hh:mm:ss a"
                  )}
                </td>
                <td>
                  {user?.employment_data?.support_log?.metrics?.map((item) => {
                    return (
                      <div>
                        <span>{item?.name}</span> - <span>{item?.value}</span>
                      </div>
                    );
                  })}
                </td>
                <td>
                  {user?.employment_data?.support_log?.observation_grading}
                </td>
                <td>
                  {user?.employment_data?.support_log?.observations?.map(
                    (item) => {
                      return (
                        <div>
                          <span>
                            Time:{" "}
                            {moment(item?.time).format(
                              "DD-MM-YYYY, hh:mm:ss a"
                            )}
                          </span>
                          <br />
                          <span>Description: {item?.description}</span>
                        </div>
                      );
                    }
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <div className="d-flex justify-content-center no-data-box">
            <div className="p-2">No data</div>
          </div>
        )}
      </Block>
    </div>
  );
};

const WorkSection = ({ sectionRef, setTab }) => {
  // const {
  //   ref: inViewRef,
  //   inView,
  //   entry,
  // } = useInView({
  //   threshold: 0.2,
  //   onChange: (inView, entry) => {
  //     if (inView) {
  //       //setTab("pipelines");
  //     }
  //   },
  // });

  // const setRefs = useCallback((node) => {
  //   sectionRef.current[9] = node;
  //   inViewRef(node);
  // });

  return (
    <div ref={(ref) => (sectionRef.current[9] = ref)}>
      <Block>
        <BlockHead>
          <BlockTitle tag="h5">Work</BlockTitle>
        </BlockHead>
      </Block>

      <Block className="overflow-auto">
        <BlockHead className="nk-block-head-line">
          <BlockTitle tag="h6" className="overline-title text-base">
            Work \ Active Pipelines
          </BlockTitle>
        </BlockHead>
      </Block>

      <Block className="overflow-auto">
        <BlockHead className="nk-block-head-line">
          <BlockTitle tag="h6" className="overline-title text-base">
            Work \ Log \ Employment
          </BlockTitle>
        </BlockHead>
      </Block>

      <Block className="overflow-auto">
        <BlockHead className="nk-block-head-line">
          <BlockTitle tag="h6" className="overline-title text-base">
            Work \ Log \ Internship
          </BlockTitle>
        </BlockHead>
      </Block>

      <Block className="overflow-auto">
        <BlockHead className="nk-block-head-line">
          <BlockTitle tag="h6" className="overline-title text-base">
            Work \ Records
          </BlockTitle>
        </BlockHead>
      </Block>
    </div>
  );
};

const LogSection = ({ sectionRef, setTab }) => {
  const { user, logs } = useContext(DataContext);
  const defaultValues = {
    follow_up_required: false,
  };
  const {
    register: registerLogForm,
    handleSubmit: handleLogFormSubmit,
    formState: { errors: errorsLogForm },
    control: controlLogForm,
    reset: resetLogForm,
  } = useForm({ defaultValues: defaultValues });
  const {
    register: registerEditLogForm,
    handleSubmit: handleEditLogFormSubmit,
    formState: { errors: errorsEditLogForm },
    control: controlEditLogForm,
    reset: resetEditLogForm,
  } = useForm();
  const [
    getInteractionTypesOptions,
    {
      data: interactionTypeData,
      isFetching: isGetInteractionTypesFetching,
      isSuccess: isGetInteractionTypesSuccess,
      isError: isGetInteractionTypesError,
      error: getInteractionTypesError,
    },
  ] = useLazyGetInteractionTypesQuery();
  const [
    addUserLog,
    {
      isLoading: isAddUserLogLoading,
      isError: isAddUserLogError,
      isSuccess: isAddUserLogSuccess,
      error: addUserLogError,
    },
  ] = useAddUserLogMutation();
  const [
    editUserLog,
    {
      isLoading: isEditUserLogLoading,
      isError: isEditUserLogError,
      isSuccess: isEditUserLogSuccess,
      error: editUserLogError,
    },
  ] = useEditUserLogMutation();
  const [
    removeUserLog,
    {
      isLoading: isRemoveUserLogLoading,
      isError: isRemoveUserLogError,
      isSuccess: isRemoveUserLogSuccess,
      error: removeUserLogError,
    },
  ] = useRemoveUserLogMutation();
  const [interactionTypeOptions, setInteractionTypeOptions] = useState([]);
  const [isLogModalOpen, setLogModal] = useState(false);
  const [isEditLogModalOpen, setEditLogModal] = useState(false);
  const [isRemoveLogModalOpen, setRemoveLogModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  // const {
  //   ref: inViewRef,
  //   inView,
  //   entry,
  // } = useInView({
  //   threshold: 0.2,
  //   onChange: (inView, entry) => {
  //     if (inView) {
  //       //setTab("log");
  //     }
  //   },
  // });

  // const setRefs = useCallback((node) => {
  //   sectionRef.current[10] = node;
  //   inViewRef(node);
  // });

  const onLogFormSubmit = (data) => {
    data.direction = data.direction.value;
    data.interaction_type = data?.interaction_type?.value;
    addUserLog({
      ...data,
      user_id: user?.id,
    });
  };

  const onEditLogFormSubmit = (data) => {
    data.direction = data.direction.value;
    data.interaction_type = data?.interaction_type?.value;
    editUserLog({
      ...data,
      user_id: user?.id,
    });
  };

  const onAddLogClicked = (item) => {
    getInteractionTypesOptions(undefined, true);
    resetLogForm();
    setLogModal(true);
  };

  const onEditLogClicked = (item) => {
    getInteractionTypesOptions(undefined, true);
    resetEditLogForm(
      {
        ...item,
        direction: {
          value: item.direction,
          label: capitalize(item.direction),
        },
      },
      {
        keepDefaultValues: true,
      }
    );
    setEditLogModal(true);
  };

  useEffect(() => {
    if (
      isGetInteractionTypesSuccess &&
      interactionTypeData?.interaction_types
    ) {
      let data = interactionTypeData.interaction_types.map((e) => {
        return { value: e.id, label: e.type };
      });
      setInteractionTypeOptions(data);
    }
    if (isGetInteractionTypesError && getInteractionTypesError) {
      toast.error(getInteractionTypesError?.data?.message);
    }
  }, [isGetInteractionTypesSuccess, isGetInteractionTypesError]);

  useEffect(() => {
    if (isAddUserLogSuccess) {
      toast.success("Add log successfully!");
      setLogModal(false);
    }
    if (isAddUserLogError && addUserLogError) {
      toast.error(addUserLogError?.data?.message);
    }
  }, [isAddUserLogSuccess, isAddUserLogError]);

  useEffect(() => {
    if (isEditUserLogSuccess) {
      toast.success("Edit log successfully!");
      setEditLogModal(false);
    }
    if (isEditUserLogError && editUserLogError) {
      toast.error(editUserLogError?.data?.message);
    }
  }, [isEditUserLogSuccess, isEditUserLogError]);

  useEffect(() => {
    if (isRemoveUserLogSuccess) {
      toast.success("Remove log successfully!");
      setRemoveLogModal(false);
    }
    if (isRemoveUserLogError && removeUserLogError) {
      toast.error(removeUserLogError?.data?.message);
    }
  }, [isRemoveUserLogSuccess, isRemoveUserLogError]);

  return (
    <div ref={(ref) => (sectionRef.current[10] = ref)}>
      <Block>
        <BlockBetween>
          <BlockHead>
            <BlockTitle tag="h5">Log</BlockTitle>
          </BlockHead>
          <BlockHead>
            <Button
              color="primary"
              outline
              size="xs"
              className="d-none d-sm-inline-flex ml-3"
              onClick={onAddLogClicked}
            >
              <Icon name="plus"></Icon>
              <span>Add log</span>
            </Button>
          </BlockHead>
        </BlockBetween>
        <BlockHead className="nk-block-head-line">
          <BlockTitle tag="h6" className="overline-title text-base">
            Log \ Interaction &amp; Communication
          </BlockTitle>
        </BlockHead>
        <Block className="overflow-auto">
          <table className="table table-bordered mt-4">
            <thead>
              <tr>
                <th scope="col" className="w-min-150px">
                  Date
                </th>
                <th scope="col" className="w-min-100px">
                  Time
                </th>
                <th scope="col" className="w-min-200px">
                  Duration
                </th>
                <th scope="col" className="w-min-200px">
                  Direction
                </th>
                <th scope="col" className="w-min-200px">
                  Interaction type
                </th>
                <th scope="col" className="w-min-200px">
                  Purpose
                </th>
                <th scope="col" className="w-min-150px">
                  Purpose ID
                </th>
                <th scope="col" className="w-min-250px">
                  Pipeline
                </th>
                <th scope="col" className="w-min-250px">
                  By who
                </th>
                <th scope="col" className="w-min-250px">
                  With who
                </th>
                <th scope="col" className="w-min-100px">
                  Follow up required
                </th>
                <th scope="col" className="w-min-250px">
                  Notes
                </th>
                <th scope="col" className="w-min-120px"></th>
              </tr>
            </thead>
            <tbody>
              {logs?.all?.map((e, idx) => {
                return (
                  <tr key={`all-log-${e.id}`}>
                    <td>{moment(e.time).format("DD MMM YYYY")}</td>
                    <td>{moment(e.time).format("LT")}</td>
                    <td>{formatDuration(e?.duration)}</td>
                    <td>{e.direction}</td>
                    <td>{e.interaction_type}</td>
                    <td>{e.purpose}</td>
                    <td></td>
                    <td></td>
                    <td>{e.created_by}</td>
                    <td>{e.with}</td>
                    <td>{e.follow_up_required ? "Yes" : "No"}</td>
                    <td className="white-space-pre-line">{e.notes}</td>
                    <td>
                      {e.is_editable ? (
                        <React.Fragment>
                          <Button
                            className="btn-icon"
                            id={`all-log-edit-btn-${e.id}`}
                            color="light"
                            size="xs"
                            onClick={() => {
                              onEditLogClicked(e);
                            }}
                          >
                            <Icon name="edit" />
                          </Button>
                          <UncontrolledTooltip
                            placement="top"
                            target={`all-log-edit-btn-${e.id}`}
                          >
                            Edit
                          </UncontrolledTooltip>
                          <Button
                            className="btn-icon ml-2"
                            id={`all-log-remove-btn-${e.id}`}
                            color="light"
                            size="xs"
                            onClick={() => {
                              setSelectedItem(e);
                              setRemoveLogModal(true);
                            }}
                          >
                            <Icon name="trash" />
                          </Button>
                          <UncontrolledTooltip
                            placement="top"
                            target={`all-log-remove-btn-${e.id}`}
                          >
                            Remove
                          </UncontrolledTooltip>
                        </React.Fragment>
                      ) : null}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Block>
      </Block>

      <ModalComponent
        isOpen={isLogModalOpen}
        backdrop={"static"}
        title={"Add Log"}
        onModalCancel={() => {
          setLogModal(false);
        }}
      >
        <form onSubmit={handleLogFormSubmit(onLogFormSubmit)}>
          <Row className="gy-3">
            <SelectField
              name={"direction"}
              label={"Direction"}
              control={controlLogForm}
              rules={{ required: "This field is required" }}
              isSearchable={false}
              options={logDirectionOptions}
              errors={errorsLogForm}
              md="12"
            />
            <SelectField
              name={"interaction_type"}
              label={"Interaction Type"}
              control={controlLogForm}
              // rules={{ required: "This field is required" }}
              options={interactionTypeOptions}
              isSearchable={false}
              isLoading={isGetInteractionTypesFetching}
              isDisabled={isGetInteractionTypesFetching}
              errors={errorsLogForm}
              md="12"
            />
            <TextInput
              name={"purpose"}
              label={"Purpose"}
              register={{
                ...registerLogForm("purpose", {}),
              }}
              errors={errorsLogForm}
              md="12"
            />
            <TextareaInput
              name={"notes"}
              label={"Notes"}
              required
              register={{
                ...registerLogForm("notes", {
                  required: "This field is required",
                }),
              }}
              errors={errorsLogForm}
              md="12"
            />
            <CheckboxInput
              name={"follow_up_required"}
              label={"Follow up required"}
              register={{
                ...registerLogForm("follow_up_required"),
              }}
              errors={errorsLogForm}
              md="12"
            />
            <Col size="12">
              <Button
                color="primary"
                type="submit"
                disabled={isAddUserLogLoading}
              >
                {isAddUserLogLoading && <Spinner type="grow" size="sm" />}
                <span>Submit</span>
              </Button>
            </Col>
          </Row>
        </form>
      </ModalComponent>

      <ModalComponent
        isOpen={isEditLogModalOpen}
        backdrop={"static"}
        title={"Edit Log"}
        onModalCancel={() => {
          setEditLogModal(false);
        }}
      >
        <form onSubmit={handleEditLogFormSubmit(onEditLogFormSubmit)}>
          <Row className="gy-3">
            <SelectField
              name={"direction"}
              label={"Direction"}
              control={controlEditLogForm}
              rules={{ required: "This field is required" }}
              isSearchable={false}
              options={logDirectionOptions}
              errors={errorsEditLogForm}
              md="12"
            />
            <TextInput
              name={"interaction_type"}
              label={"Interaction Type"}
              register={{
                ...registerEditLogForm("interaction_type", {}),
              }}
              errors={errorsEditLogForm}
              md="12"
            />
            <TextInput
              name={"purpose"}
              label={"Purpose"}
              register={{
                ...registerEditLogForm("purpose", {}),
              }}
              errors={errorsEditLogForm}
              md="12"
            />
            <TextareaInput
              name={"notes"}
              label={"Notes"}
              required
              register={{
                ...registerEditLogForm("notes", {
                  required: "This field is required",
                }),
              }}
              errors={errorsEditLogForm}
              md="12"
            />
            <CheckboxInput
              name={"follow_up_required"}
              label={"Follow up required"}
              register={{
                ...registerEditLogForm("follow_up_required"),
              }}
              errors={errorsEditLogForm}
              md="12"
            />
            <Col size="12">
              <Button
                color="primary"
                type="submit"
                disabled={isEditUserLogLoading}
              >
                {isEditUserLogLoading && <Spinner type="grow" size="sm" />}
                <span>Submit</span>
              </Button>
            </Col>
          </Row>
        </form>
      </ModalComponent>

      <ModalComponent
        isOpen={isRemoveLogModalOpen}
        title={"Are you sure you want to remove this log?"}
        disableCloseBtn={true}
        toggle={() => {
          setRemoveLogModal(false);
        }}
        onModalCancel={() => {
          setRemoveLogModal(false);
        }}
      >
        <Row className="gy-3">
          <Col size="12">
            <Button
              color="primary"
              type="submit"
              disabled={isRemoveUserLogLoading}
              onClick={() => {
                removeUserLog(selectedItem);
              }}
            >
              {isRemoveUserLogLoading && <Spinner type="grow" size="sm" />}
              <span>Yes</span>
            </Button>
            <Button
              className="ml-3"
              color="light"
              onClick={() => {
                setRemoveLogModal(false);
              }}
            >
              <span>No</span>
            </Button>
          </Col>
        </Row>
      </ModalComponent>
    </div>
  );
};
