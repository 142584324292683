import "./App.css";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import LoginPage from "./pages/auth/Login";
import Layout from "./layout/Index";
import Error404Modern from "./pages/error/404-modern";
import Test from "./pages/test/Test";
import {
  Account,
  Attendance,
  EmotionalLogs,
  EmotionTypes,
  Goals,
  GoalFrequency,
  LocationTracking,
  Quotes,
  SupportLogs,
  SupportMetrics,
  Employments,
  Workplaces,
  ProSchedules,
  Candidates,
  Caregivers,
  UserDetails,
  CaregiverDetails,
  Introduction,
  IntroductionFormDetails,
  CandidatesCreation,
  Sex,
  Religions,
  Assessment,
  SDQForm,
  FormEmailSending,
  CareersSubmissions,
  ContactUsSubmissions,
  MediaView,
  InteractionTypes,
  MedicalTypes,
  Races,
  EducationLevels,
  Organisations,
  OrganisationCreation,
  OrganisationDetails,
  OrgContactRoles,
  OrgSupportingDocTypes,
  OpportunityTypes,
  OpportunityContractTypes,
  OpportunityCommitmentTypes,
  SiteCheck,
  Permission,
  Role,
  SoftSkills,
  HardSkills,
  MotorSkills,
  OtherSkills,
  ObservationGrading,
  Users,
  MenuData,
  Chat,
  ServiceLayer,
} from "./pages/Pages";
import { AuthLayer } from "./components/Components";
import { ToastContainer } from "react-toastify";
import IntroductionForm from "./pages/forms/IntroductionForm";
import IntakeAssessment from "./pages/forms/IntakeAssessment";
import Calendar from "./pages/calendar/Calendar";
import IntroductionIndex from "./pages/forms/IntroductionIndex";
import {
  ChatContextProvider,
  ChatTestContextProvider,
} from "./pages/chat/ChatContext";

function App() {
  return (
    <>
      <Routes>
        {/* <Route path="/" element={<Navigate to="admin" replace />} /> */}
        <Route path="/" element={<SiteCheck />} />
        <Route path="admin">
          <Route element={<AuthLayer />}>
            <Route element={<ServiceLayer />}>
              <Route path="" element={<Layout />}>
                <Route path="chat">
                  <Route index element={<Chat />} />
                </Route>

                <Route path="users">
                  <Route index element={<Users />} />
                </Route>

                <Route path="candidates">
                  <Route index element={<Candidates />} />
                  <Route path=":id" element={<UserDetails />} />
                  <Route path="create" element={<CandidatesCreation />} />
                </Route>

                <Route path="caregivers">
                  <Route index element={<Caregivers />} />
                  <Route path=":id" element={<CaregiverDetails />} />
                </Route>

                <Route path="organisations">
                  <Route index element={<Organisations />} />
                  <Route path=":id" element={<OrganisationDetails />} />
                  <Route path="create" element={<OrganisationCreation />} />
                </Route>

                {/* <Route path="account" element={<Account />} /> */}
                <Route path="quotes" element={<Quotes />} />
                <Route path="goals" element={<Goals />} />
                <Route path="goal-frequency" element={<GoalFrequency />} />

                <Route path="attendance" element={<Attendance />} />
                <Route path="support-metrics">
                  <Route path="soft-skills" element={<SoftSkills />} />
                  <Route path="hard-skills" element={<HardSkills />} />
                  <Route path="motor-skills" element={<MotorSkills />} />
                  <Route path="others" element={<OtherSkills />} />
                </Route>
                <Route path="support-logs" element={<SupportLogs />} />
                <Route
                  path="location-tracking"
                  element={<LocationTracking />}
                />

                <Route path="emotional-logs" element={<EmotionalLogs />} />
                <Route path="emotion-types" element={<EmotionTypes />} />

                <Route path="employments" element={<Employments />} />
                <Route path="pro-schedules" element={<ProSchedules />} />

                <Route path="config">
                  <Route
                    path="form-email-sending"
                    element={<FormEmailSending />}
                  />
                  <Route path="menu-data" element={<MenuData />} />
                </Route>

                <Route path="authorization">
                  <Route path="permission" element={<Permission />} />
                  <Route path="role" element={<Role />} />
                </Route>
                <Route path="dropdown-config">
                  <Route path="sex" element={<Sex />} />
                  <Route path="religions" element={<Religions />} />
                  <Route
                    path="interaction-types"
                    element={<InteractionTypes />}
                  />
                  <Route path="medical-types" element={<MedicalTypes />} />
                  <Route path="races" element={<Races />} />
                  <Route
                    path="education-levels"
                    element={<EducationLevels />}
                  />
                  <Route
                    path="org-contact-roles"
                    element={<OrgContactRoles />}
                  />
                  <Route
                    path="org-supporting-doc-types"
                    element={<OrgSupportingDocTypes />}
                  />
                  <Route
                    path="opportunity-types"
                    element={<OpportunityTypes />}
                  />
                  <Route
                    path="opportunity-contract-types"
                    element={<OpportunityContractTypes />}
                  />
                  <Route
                    path="opportunity-commitment-types"
                    element={<OpportunityCommitmentTypes />}
                  />
                  <Route
                    path="observation-grading"
                    element={<ObservationGrading />}
                  />
                </Route>

                <Route
                  path="web-submissions/careers"
                  element={<CareersSubmissions />}
                />
                <Route
                  path="web-submissions/contact-us"
                  element={<ContactUsSubmissions />}
                />
                <Route path="assessment-forms">
                  <Route index element={<Assessment />} />
                  {/* <Route path=":code" element={<IntroductionFormDetails />} /> */}
                </Route>
                <Route path="introduction-forms">
                  <Route index element={<Introduction />} />
                  <Route path=":code" element={<IntroductionFormDetails />} />
                </Route>
              </Route>
            </Route>
          </Route>
          <Route path="login" element={<LoginPage />} />
        </Route>

        <Route element={<AuthLayer />}>
          <Route path="forms">
            <Route path="assessment">
              <Route path=":code" element={<IntakeAssessment />} />
            </Route>
            <Route path="sdq-form">
              <Route path=":code" element={<SDQForm />} />
            </Route>
          </Route>

          <Route path="files" element={<MediaView />}></Route>
        </Route>

        <Route path="introduction">
          <Route index element={<IntroductionIndex />} />
          <Route path=":code" element={<IntroductionForm />} />
        </Route>

        <Route path="calendar" element={<Calendar />} />

        <Route path="test" element={<Test />} />

        {/* <Route path="/login" element={<LoginPage />} /> */}

        <Route path="*" element={<Error404Modern />} />
      </Routes>
      <ToastContainer autoClose={5000} />
    </>
  );
}

export default App;
