import { useDispatch, useSelector } from "react-redux";
import { useState, useLayoutEffect, useEffect } from "react";
import ReactDataTable from "../../components/table/ReactDataTable";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
  Col,
  ModalComponent,
  Row,
  TextInput,
  TextareaInput,
  SelectField,
} from "../../components/Components";
import {
  onChangePageState,
  toggleModal,
  onFormCancel,
  onChangeSearchText,
} from "../../redux/features/goalSlice";
import {
  useGetGoalsQuery,
  useCreateGoalMutation,
  useUpdateGoalMutation,
  useDeleteGoalMutation,
  useLazyGetGoalFrequenciesQuery,
} from "../../redux/api/goal-management/goalApi";
import { goalColumns } from "./DataColumns";
import moment from "moment";
import { useForm } from "react-hook-form";
import { Spinner } from "reactstrap";
import { toast } from "react-toastify";

const Goals = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.goal);
  const { data, isFetching, isSuccess } = useGetGoalsQuery({
    offset: state.offset,
    limit: state.itemsPerPage,
    total: true,
    reverse: true,
    search_text: state.searchText,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: {},
  });
  const [
    createGoal,
    {
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess,
      isError: isCreateError,
      error: createError,
    },
  ] = useCreateGoalMutation();
  const [
    updateGoal,
    {
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useUpdateGoalMutation();
  const [
    deleteGoal,
    {
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
      isError: isDeleteError,
      error: deleteError,
    },
  ] = useDeleteGoalMutation();
  const [
    getFrequencies,
    {
      data: freqData,
      isSuccess: isFreqSuccess,
      isFetching: isFreqFetching,
      isError: isFreqError,
    },
  ] = useLazyGetGoalFrequenciesQuery({
    offset: 0,
    limit: 0,
  });
  const [tableData, setTableData] = useState(data?.goals ?? []);
  const [totalItems, setTotalItems] = useState(data?.total ?? 0);

  const changePageState = ({ offset = 0, itemsPerPage }) => {
    dispatch(onChangePageState({ offset, itemsPerPage: itemsPerPage }));
  };

  useLayoutEffect(() => {
    if (data) {
      setTableData(data.goals);
      setTotalItems(data.total);
    }
  }, [data]);

  const processData = (rawData) => {
    var data = { ...rawData };
    data.frequency_id = data.frequency_id.value;

    return data;
  };

  const onFormSubmit = (data) => {
    var newData = processData(data);
    if (state.modal.add) {
      createGoal(newData);
    } else if (state.modal.edit) {
      updateGoal({
        params: { id: state.modal.item.id },
        data: newData,
      });
    }
  };

  useEffect(() => {
    if (isCreateSuccess) {
      dispatch(toggleModal({ type: "add" }));
      reset();
    }
    if (isCreateError && createError) {
      toast.error(createError?.data?.message);
    }
  }, [isCreateSuccess, isCreateError]);

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(toggleModal({ type: "edit" }));
      reset();
    }
    if (isUpdateError && updateError) {
      toast.error(updateError?.data?.message);
    }
  }, [isUpdateError, isUpdateSuccess]);

  useEffect(() => {
    if (isDeleteError && deleteError) {
      toast.error(deleteError?.data?.message);
    }
  }, [isDeleteError]);

  const onAddBtnClicked = (e) => {
    dispatch(toggleModal({ type: "add" }));
    getFrequencies(undefined, true);
    reset();
  };

  const onRowClicked = (row, e) => {
    e.stopPropagation();
    const action = e.target.getAttribute("data-action");
    switch (action) {
      case "remove":
        //dispatch delete
        deleteGoal({ id: row.id });
        break;
      case "details":
        dispatch(toggleModal({ type: "details", item: row }));
        break;
      case "edit":
        getFrequencies(undefined, true);
        dispatch(toggleModal({ type: "edit", item: row }));
        reset({
          ...row,
          frequency_id: {
            value: row?.frequency_id,
            label: row?.frequency,
          },
        });

        break;
    }
  };

  return (
    <>
      <Head title="Goals"></Head>
      <Content>
        <Block>
          <BlockHead>
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Goals</BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <Button
                    className="toggle btn-icon d-md-none"
                    color="primary"
                    onClick={onAddBtnClicked}
                  >
                    <Icon name="plus" />
                  </Button>
                  <Button
                    className="toggle d-none d-md-inline-flex"
                    color="primary"
                    onClick={onAddBtnClicked}
                  >
                    <Icon name="plus" />
                    <span>Add Goal</span>
                  </Button>
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <PreviewCard>
            <div className="table-wrapper is-scrollable">
              <ReactDataTable
                data={tableData}
                columns={goalColumns}
                offset={state.offset}
                itemsPerPage={state.itemsPerPage}
                pagination
                totalItems={totalItems}
                progressPending={isFetching}
                className="nk-tb-list"
                selectableRows
                changePageState={changePageState}
                onRowClicked={onRowClicked}
                placeholderSearchText={"Search by name (minimum 3 characters)"}
                searchFunc={(searchText) => {
                  if (searchText.length >= 3 || searchText.length === 0) {
                    dispatch(onChangeSearchText(searchText));
                  }
                }}
              />
            </div>
          </PreviewCard>
        </Block>

        <ModalComponent
          isOpen={state.modal.details}
          toggle={() => dispatch(toggleModal({ type: "details" }))}
          title={"Goal Details"}
          onModalCancel={() => {
            dispatch(onFormCancel());
          }}
        >
          {state.modal.item && (
            <Row className="gy-3">
              <Col lg={6}>
                <span className="sub-text">Goal Id</span>
                <span className="caption-text">{state.modal.item.id}</span>
              </Col>
              <Col lg={12}>
                <span className="sub-text">Name</span>
                <span className="caption-text">{state.modal.item.name}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Frequency</span>
                <span className="caption-text">
                  {state.modal.item.frequency}
                </span>
              </Col>
              <Col lg={12}>
                <span className="sub-text">Description</span>
                <span className="caption-text">
                  {state.modal.item.description}
                </span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Created Time</span>
                <span className="caption-text">
                  {moment(state.modal.item.created_time).format(
                    "DD-MM-YYYY, hh:mm:ss a"
                  )}
                </span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Last Updated Time</span>
                <span className="caption-text">
                  {moment(state.modal.item.last_updated_time).format(
                    "DD-MM-YYYY, hh:mm:ss a"
                  )}
                </span>
              </Col>
            </Row>
          )}
        </ModalComponent>

        <ModalComponent
          isOpen={state.modal.add || state.modal.edit}
          title={`${state.modal.add ? "Add" : "Edit"} Goal`}
          onModalCancel={() => {
            dispatch(onFormCancel());
            reset();
          }}
          toggle={() => {
            dispatch(onFormCancel());
            reset();
          }}
          backdrop={"static"}
        >
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Row className="g-3">
              <TextInput
                name={"name"}
                label={"Name"}
                required
                register={{
                  ...register("name", {
                    required: "This field is required",
                  }),
                }}
                errors={errors}
                md={"12"}
              />
              <TextareaInput
                name={"description"}
                label={"Description"}
                required
                register={{
                  ...register("description", {}),
                }}
                errors={errors}
                md={"12"}
              />
              <SelectField
                name={"frequency_id"}
                label={"Frequency"}
                control={control}
                rules={{ required: "This field is required" }}
                options={
                  freqData?.goalFrequencies.map((item) => ({
                    label: item.frequency,
                    value: item.id,
                  })) ?? {}
                }
                isLoading={isFreqFetching}
                isDisabled={isFreqFetching}
                errors={errors}
              />
              <Col size="12">
                <Button
                  color="primary"
                  type="submit"
                  disabled={isCreateLoading || isUpdateLoading}
                >
                  {(isCreateLoading || isUpdateLoading) && (
                    <Spinner type="grow" size="sm" />
                  )}
                  <span>{state.modal.add ? "Add" : "Edit"}</span>
                </Button>
              </Col>
            </Row>
          </form>
        </ModalComponent>
      </Content>
    </>
  );
};

export default Goals;
