import ReactFlagsSelect from "react-flags-select";
import Select from "react-select";

const RSelect = ({
  field,
  onChangeFunc,
  placeholder,
  isFlagsSelect,
  ...props
}) => {
  return (
    <div
      className={`form-control-select ${props.isLoading ? "is-loading" : ""}`}
    >
      {!isFlagsSelect && (
        <Select
          className={`react-select-container ${
            props.className ? props.className : ""
          }`}
          classNamePrefix="react-select"
          {...field}
          onBlur={(e) => {
            if (onChangeFunc) {
              onChangeFunc(e);
            }
            field.onBlur(e);
          }}
          placeholder={placeholder ?? "select..."}
          {...props}
          onKeyDown={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
        />
      )}
      {isFlagsSelect && (
        <div className="react-select-container">
          <ReactFlagsSelect
            searchable
            className={`react-select-flag-container ${
              props.className ? props.className : ""
            }`}
            selected={field.value}
            onSelect={field.onChange}
            // showSelectedLabel={false}
            // customLabels={countryFlagsMap}
          />
        </div>
      )}
    </div>
  );
};

export default RSelect;
