import React, { useEffect, useState, useContext, useRef } from "react";
import EmojiPicker from "emoji-picker-react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Modal,
  ModalBody,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import { UserAvatar, Icon, Button, ModalComponent } from "../../components/Components";
import { findUpper, truncate } from "../../utils/utils";
import { ChatContext } from "./ChatContext";
import classNames from "classnames";
import ChatSideBar from "./ChatSidebar";
import { MetaChat, MeChat2, YouChat2 } from "./ChatPartials";
import moment from "moment";

export const ChatBody2 = ({ id, mobileView, setMobileView }) => {
  const {
    sendMessage,
    chatState,
    userID,
    deleteMessage,
    updateMessage,
    pinMessage,
    unpinMessage,
    listPinnedMessages,
  } = useContext(ChatContext);
  const [chat, setChat] = chatState;
  const [Uchat, setUchat] = useState({});
  const [sidebar, setsidebar] = useState(false);
  const [isAudioCallOpen, setIsAudioCallOpen] = useState(false);
  const [messageToUpdate, setMessageToUpdate] = useState(null);
  const [messageToReply, setMessageToReply] = useState(null);
  const [isOpenPinnedView, setIsOpenPinnedView] = useState(false);
  const [forwardMessage, setForwardMessage] = useState(null);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      const scrollHeight = messagesEndRef.current.scrollHeight;
      const height = messagesEndRef.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      messagesEndRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  const resizeFunc = () => {
    if (window.innerWidth > 1550) {
      setsidebar(true);
    } else {
      setsidebar(false);
    }
  };

  useEffect(() => {
    if (chat.messages) {
      //TODO: Prevent scroll to bottom after edit messages
      scrollToBottom();
    }
  }, [chat.messages]);

  useEffect(() => {
    window.addEventListener("resize", resizeFunc);
    resizeFunc();
    return () => {
      window.removeEventListener("resize", resizeFunc);
    };
  }, []);

  useEffect(() => {
    if (isOpenPinnedView) {
      listPinnedMessages();
    }
  }, [isOpenPinnedView]);

  const toggleMenu = () => {
    setsidebar(!sidebar);
  };

  const onAudioCallClick = (e) => {
    e.preventDefault();
    setIsAudioCallOpen(true);
  };

  const onVideoCallClick = (e) => {
    e.preventDefault();
  };

  const onDeleteMessageClicked = (id) => {
    deleteMessage({
      messageID: id,
    });
  };

  const chatBodyClass = classNames({
    "nk-chat-body": true,
    "show-chat": mobileView,
    "profile-shown": sidebar && window.innerWidth > 1550,
  });

  return (
    <React.Fragment>
      {/* {Object.keys(Uchat).length > 0 && ( */}
      {Object.keys(chat.currentlyJoinedChannel).length > 0 && (
        <div className={chatBodyClass}>
          {isOpenPinnedView ? (
            <PinnedViewHeader
              chat={chat}
              setIsOpenPinnedView={setIsOpenPinnedView}
            />
          ) : (
            <ChatHeader
              channel={chat.currentlyJoinedChannel}
              userID={userID}
              setMobileView={setMobileView}
              onAudioCallClick={onAudioCallClick}
              onVideoCallClick={onVideoCallClick}
              toggleMenu={toggleMenu}
              setIsOpenPinnedView={setIsOpenPinnedView}
            />
          )}
          <ChatView
            chat={chat}
            // channel={chat.currentlyJoinedChannel}
            // messages={chat.messages}
            userID={userID}
            messagesEndRef={messagesEndRef}
            onDeleteMessageClicked={onDeleteMessageClicked}
            setMessageToUpdate={setMessageToUpdate}
            setMessageToReply={setMessageToReply}
            pinMessage={pinMessage}
            unpinMessage={unpinMessage}
            isOpenPinnedView={isOpenPinnedView}
            setForwardMessage={setForwardMessage}
          />
          {!isOpenPinnedView && (
            <ChatEditor
              channel={chat.currentlyJoinedChannel}
              sendMessage={sendMessage}
              updateMessage={updateMessage}
              sidebar={sidebar}
              messageToUpdate={messageToUpdate}
              setMessageToUpdate={setMessageToUpdate}
              messageToReply={messageToReply}
              setMessageToReply={setMessageToReply}
            />
          )}
          <ChatSideBar
            sidebar={sidebar}
            channel={chat.currentlyJoinedChannel}
          />
          {window.innerWidth < 1550 && sidebar && (
            <div
              onClick={() => toggleMenu()}
              className="nk-chat-profile-overlay"
            />
          )}
        </div>
      )}

      <ModalComponent
        isOpen={forwardMessage !== null}
        toggle={() => setForwardMessage(null)}
        size={"lg"}
        title={"Forward"}
        backdrop={"static"}
        onModalCancel={() => setForwardMessage(null)}
      >
        <div className="nk-chat-aside-search px-0 mb-3">
            <div className="form-group">
              <div className="form-control-wrap">
                <div className="form-icon form-icon-left">
                  <Icon name="search"></Icon>
                </div>
                <Input
                  // {...register("new_chat_search")}
                  type="text"
                  className="form-round"
                  id={`new-chat-search-input`}
                  placeholder="Search by email/name (min 3 characters)"
                  // onChange={(e) => onNewChatSearchChange(e)}
                />
              </div>
            </div>
          </div>
          <div className="nk-divider divider md" />
          <div>
            <span>Recent</span>
            <div></div>
          </div>
      </ModalComponent>

      <Modal
        isOpen={isAudioCallOpen}
        toggle={() => setIsAudioCallOpen(!isAudioCallOpen)}
        className="modal-dialog-centered"
        size={"lg"}
      >
        <ModalBody>
          <div
            style={{
              display: "flex",
              minHeight: "300px",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div
              className="user-avatar-group user-avatar-lg"
              style={{
                background: "#9d72ff",
                borderRadius: "50%",
                alignSelf: "center",
                marginBottom: "15px",
              }}
            ></div>
            <h4 className="title text-center">
              {Uchat.user?.agora_chat_nickname
                ? Uchat.user?.agora_chat_nickname
                : `${Uchat.user?.first_name} ${Uchat.user?.last_name}`}
            </h4>
            <div className="mt-4">
              <ul
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <li>
                  <a
                    href="#test"
                    className="icon-btn btn-light button-effect mute"
                    style={{
                      display: "inline-flex",
                      overflow: "hidden",
                      position: "relative",
                      height: "50px",
                      width: "50px",
                      borderRadius: "100%",
                      fontWeight: "800",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "0!important",
                    }}
                  >
                    <em className="icon ni ni-mic"></em>
                  </a>
                </li>
                <li style={{ marginLeft: "30px" }}>
                  <a
                    href="#test"
                    className="icon-btn btn-light button-effect mute"
                    style={{
                      display: "inline-flex",
                      overflow: "hidden",
                      position: "relative",
                      height: "50px",
                      width: "50px",
                      borderRadius: "100%",
                      fontWeight: "800",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "0!important",
                    }}
                  >
                    <em className="icon ni ni-vol"></em>
                  </a>
                </li>
                <li style={{ marginLeft: "30px" }}>
                  <a
                    href="#test"
                    className="icon-btn btn-light button-effect mute"
                    style={{
                      display: "inline-flex",
                      overflow: "hidden",
                      position: "relative",
                      height: "65px",
                      width: "65px",
                      borderRadius: "100%",
                      fontWeight: "800",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "0!important",
                      boxShadow:
                        "0 0 0 0 rgba(255,78,43,0),0 .05em .1em rgba(0,0,0,.2)",
                      backgroundColor: "#ff4e2b",
                    }}
                    onClick={() => {
                      setIsAudioCallOpen(!isAudioCallOpen);
                    }}
                  >
                    <em className="icon ni ni-call"></em>
                  </a>
                </li>
                <li style={{ marginLeft: "30px" }}>
                  <a
                    href="#test"
                    className="icon-btn btn-light button-effect mute"
                    style={{
                      display: "inline-flex",
                      overflow: "hidden",
                      position: "relative",
                      height: "50px",
                      width: "50px",
                      borderRadius: "100%",
                      fontWeight: "800",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "0!important",
                    }}
                  >
                    <em className="icon ni ni-user-add"></em>
                  </a>
                </li>
                <li style={{ marginLeft: "30px" }}>
                  <a
                    href="#test"
                    className="icon-btn btn-light button-effect mute"
                    style={{
                      display: "inline-flex",
                      overflow: "hidden",
                      position: "relative",
                      height: "50px",
                      width: "50px",
                      borderRadius: "100%",
                      fontWeight: "800",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "0!important",
                    }}
                  >
                    <em className="icon ni ni-pause"></em>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const ChatView = ({
  chat,
  // channel,
  // messages,
  userID,
  messagesEndRef,
  onDeleteMessageClicked,
  setMessageToUpdate,
  setMessageToReply,
  pinMessage,
  unpinMessage,
  isOpenPinnedView,
  setForwardMessage,
}) => {
  return (
    <SimpleBar
      className="nk-chat-panel"
      scrollableNodeProps={{ ref: messagesEndRef }}
    >
      {!isOpenPinnedView &&
        chat.messages?.map((item, idx) => {
          let isPinnedMessage =
            chat.currentlyJoinedChannel?.pinnedMessageIds?.includes(
              item.messageId
            );

          let components = [];
          if (item.messageType === "admin") {
            return (
              <MetaChat
                key={item.channelUrl + "-" + item?.messageId}
                item={item.message}
              />
            );
          }

          if (
            moment(item.createdAt).date() !==
              moment(chat.messages[idx - 1]?.createdAt).date() ||
            (idx === 0 && moment(item.createdAt).date() !== moment().date())
          ) {
            components.push(
              <MetaChat
                key={
                  item.channelUrl + moment(item.createdAt).format("-DDMMMYYYY")
                }
                item={moment(item.createdAt).format("DD MMM, YYYY")}
              />
            );
          }
          if (item.sender?.userId === userID) {
            components.push(
              <MeChat2
                key={item?.messageId}
                item={item}
                chat={chat}
                onDeleteMessageClicked={onDeleteMessageClicked}
                setMessageToUpdate={setMessageToUpdate}
                setMessageToReply={setMessageToReply}
                pinMessage={pinMessage}
                unpinMessage={unpinMessage}
                isPinnedMessage={isPinnedMessage}
              />
            );
            return components;
          }

          components.push(
            <YouChat2
              key={item?.messageId}
              item={item}
              chat={chat}
              setMessageToReply={setMessageToReply}
              pinMessage={pinMessage}
              unpinMessage={unpinMessage}
              isPinnedMessage={isPinnedMessage}
              setForwardMessage={setForwardMessage}
            />
          );
          return components;
        })}
      {isOpenPinnedView &&
        chat?.pinnedMessages?.map((item, idx) => {
          if (item.sender?.userId === userID) {
            return (
              <MeChat2
                key={item?.messageId}
                item={item}
                onDeleteMessageClicked={onDeleteMessageClicked}
                setMessageToUpdate={setMessageToUpdate}
                setMessageToReply={setMessageToReply}
                pinMessage={pinMessage}
                unpinMessage={unpinMessage}
                isPinnedMessage={true}
              />
            );
          }

          return (
            <YouChat2
              key={item?.messageId}
              item={item}
              setMessageToReply={setMessageToReply}
              pinMessage={pinMessage}
              unpinMessage={unpinMessage}
              isPinnedMessage={true}
            />
          );
        })}
    </SimpleBar>
  );
};

const ChatEditor = ({
  channel,
  sendMessage,
  updateMessage,
  sidebar,
  messageToUpdate,
  setMessageToUpdate,
  messageToReply,
  setMessageToReply,
}) => {
  const [chatOptions, setChatOptions] = useState(false);
  const [isEmojiBoxOpen, setIsEmojiBoxOpen] = useState(false);
  const [inputText, setInputText] = useState("");
  const [replyText, setReplyText] = useState("");
  const [updateText, setUpdateText] = useState("");

  const [messageInput, setMessageInput] = useState("");
  const inputFilesRef = useRef(null);

  useEffect(() => {
    if (channel) {
      setInputText("");
      setReplyText("");
      setUpdateText("");
      setMessageInput("");
    }
  }, [channel]);

  useEffect(() => {
    if (messageToUpdate) {
      setUpdateText(messageToUpdate.message);
      setMessageInput(messageToUpdate.message);
    }
  }, [messageToUpdate]);

  useEffect(() => {
    if (messageToReply && !messageToUpdate) {
      setReplyText("");
      setMessageInput("");
    }
  }, [messageToReply]);

  const onSubmit = (e) => {
    e.preventDefault();
    let text = truncate(messageInput, 74);
    if (text === "") {
      return;
    }

    if (messageToUpdate) {
      // Update message
      updateMessage({
        messageToUpdate: messageToUpdate,
        message: updateText,
        type: "MESG",
      });
      setMessageToUpdate(null);
      setUpdateText("");

      if (messageToReply) {
        setMessageInput(replyText);
      } else {
        setMessageInput(inputText);
      }
      return;
    }

    if (messageToReply && !messageToUpdate) {
      // Reply message
      sendMessage({
        message: replyText,
        parentMessageId: messageToReply.messageId,
      });

      setMessageToReply(null);
      setReplyText("");
      setMessageInput(inputText);
      return;
    }

    // Send new message
    sendMessage({
      message: text,
    });

    setInputText("");
    setMessageInput("");
  };

  const onChatOptions = () => {
    setChatOptions(!chatOptions);
  };

  const onInputChange = (e) => {
    if (!messageToUpdate && !messageToReply) {
      setInputText(e.target.value);
    }
    if (messageToUpdate) {
      setUpdateText(e.target.value);
    }
    if (messageToReply && !messageToUpdate) {
      setReplyText(e.target.value);
    }
    setMessageInput(e.target.value);
  };

  const onEmojiClick = React.useCallback(
    (emojiData, event) => {
      setInputText(inputText + emojiData.emoji);
    },
    [inputText]
  );

  const onCancelUpdate = (e) => {
    setMessageToUpdate(null);
    setUpdateText("");

    if (messageToReply) {
      setMessageInput(replyText);
    } else {
      setMessageInput(inputText);
    }
  };

  const onCancelReply = (e) => {
    setMessageToReply(null);
    setReplyText("");
    setMessageInput(inputText);
  };

  const onFilesSelect = (e) => {
    e.preventDefault();

    for (let i = 0; i < e.target.files.length; i++) {
      console.log(e.target.files[i])
    }

    sendMessage({
      files: [...e.target.files],
    })
    // let file = e.target.files[0];
    // let file = AC.utils.getFileUrl(inputImgFile.current);
    // var allowType = {
    //   jpeg: true,
    //   jpg: true,
    //   gif: true,
    //   png: true,
    //   bmp: true,
    // };
    // if (file.filetype.toLowerCase() in allowType) {
    //   sendMsg({
    //     file: file,
    //     ext: {
    //       file_length: file.data.size,
    //     },
    //     to: Uchat.id,
    //     from: userID,
    //     time: +moment(),
    //     type: "img",
    //     chatType: Uchat.groupName ? "groupChat" : "singleChat",
    //   });
    // } else {
    //   toast.error("Invalid file type. Please select an image file.");
    // }

    e.target.value = null;
  };

  return (
    <div>
      {(messageToUpdate || messageToReply) && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0.8rem 1.25rem",
            backgroundColor: "#f5f6faa6",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
              cursor: "pointer",
            }}
          >
            <Icon
              name={messageToUpdate ? "pen2" : "reply-fill"}
              className="fs-18px"
            ></Icon>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                marginLeft: "0.7rem",
                marginRight: "2rem",
              }}
            >
              <span
                className="fs-12px"
                style={{
                  color: "#854fff",
                }}
              >{`${messageToUpdate ? "Edit" : "Reply"} message`}</span>
              <span
                style={{
                  textWrap: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {messageToUpdate
                  ? messageToUpdate.message
                  : messageToReply.message}
              </span>
            </div>
          </div>
          <div onClick={messageToUpdate ? onCancelUpdate : onCancelReply}>
            <Icon
              name="cross-circle"
              className="fs-22px"
              style={{
                cursor: "pointer",
              }}
            ></Icon>
          </div>
        </div>
      )}
      <div className="nk-chat-editor">
        <div className="nk-chat-editor-upload  ml-n1">
          <Button
            size="sm"
            className={`btn-icon btn-trigger text-primary toggle-opt ${
              chatOptions ? "active" : ""
            }`}
            onClick={() => onChatOptions()}
          >
            <Icon name="plus-circle-fill"></Icon>
          </Button>
          <div
            className={`chat-upload-option ${chatOptions ? "expanded" : ""}`}
          >
            <ul className="">
              <li>
                <a
                  href="#img"
                  onClick={(ev) => {
                    inputFilesRef.current.click();
                  }}
                >
                  <Icon name="img-fill"></Icon>
                  <input
                    type="file"
                    ref={inputFilesRef}
                    id={`-img-input`}
                    onChange={onFilesSelect}
                    hidden
                    multiple
                  />
                </a>
              </li>
              <li>
                <a
                  href="#camera"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  <Icon name="camera-fill"></Icon>
                </a>
              </li>
              <li>
                <a
                  href="#mic"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  <Icon name="mic"></Icon>
                </a>
              </li>
              <li>
                <a
                  href="#grid"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  <Icon name="grid-sq"></Icon>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="nk-chat-editor-form">
          <div className="form-control-wrap">
            <textarea
              className="form-control form-control-simple no-resize"
              rows="1"
              id="default-textarea"
              onChange={(e) => onInputChange(e)}
              value={messageInput}
              placeholder="Type your message..."
              // onKeyDown={(e) => {
              //   e.code === "Enter" && onTextSubmit(e);
              // }}
            ></textarea>
          </div>
        </div>
        <ul className="nk-chat-editor-tools g-2">
          {isEmojiBoxOpen && (
            <div
              style={{
                position: "absolute",
                bottom: "60px",
                right: window.innerWidth > 1550 && sidebar ? "450px" : "50px",
                zIndex: "10",
              }}
            >
              <EmojiPicker onEmojiClick={onEmojiClick} />
            </div>
          )}
          <li>
            <Button
              size="sm"
              className="btn-icon btn-trigger text-primary"
              onClick={() => {
                setIsEmojiBoxOpen(!isEmojiBoxOpen);
              }}
            >
              <Icon name="happyf-fill"></Icon>
            </Button>
          </li>
          <li>
            <Button
              color="primary"
              onClick={(e) => onSubmit(e)}
              className="btn-round btn-icon"
            >
              <Icon name="send-alt"></Icon>
            </Button>
          </li>
        </ul>
      </div>
    </div>
  );
};

const ChatHeader = ({
  channel,
  userID,
  setMobileView,
  onAudioCallClick,
  onVideoCallClick,
  toggleMenu,
  setIsOpenPinnedView,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [lastTimeActive, setLastTimeActive] = useState(null);
  const [isGroup, setIsGroup] = useState(false);
  const [lastPinnedMessage, setLastPinnedMessage] = useState(null);

  useEffect(() => {
    if (channel?.memberCount > 2) {
      setIsGroup(true);
    } else {
      setIsGroup(false);
    }
  }, [channel]);

  // useEffect(() => {
  //   let members = channel.members?.filter((member) => member.userId !== userID);
  //   let isActive = false;
  //   for (let i = 0; i < members.length; i++) {
  //     if (members[i].connectionStatus === "online") {
  //       isActive = true;
  //       break;
  //     }
  //   }
  //   setIsActive(isActive);
  // }, [channel.members, userID]);

  useEffect(() => {
    if (channel?.pinnedMessageIds?.length > 0) {
      setLastPinnedMessage(channel?.lastPinnedMessage);
    } else {
      setLastPinnedMessage(null);
    }
  }, [channel?.pinnedMessageIds]);

  return (
    <div>
      <div className="nk-chat-head">
        <ul
          className="nk-chat-head-info"
          style={{
            overflow: "hidden",
          }}
        >
          <li
            className="nk-chat-body-close"
            onClick={() => setMobileView(false)}
          >
            <a
              href="#hide-chat"
              onClick={(ev) => {
                ev.preventDefault();
              }}
              className="btn btn-icon btn-trigger nk-chat-hide ml-n1"
            >
              <Icon name="arrow-left"></Icon>
            </a>
          </li>
          <li
            className="nk-chat-head-user"
            style={{
              overflow: "hidden",
            }}
          >
            <div className="user-card">
              {channel?.memberCount > 2 ? (
                <div
                  className={classNames("chat-media user-avatar", {
                    "user-avatar-multiple": true,
                  })}
                >
                  {channel?.memberCount === 1 && (
                    <UserAvatar className="chat-media" icon={`users-fill`} />
                  )}
                  {channel?.members?.map((member, index) => {
                    return (
                      <UserAvatar
                        theme={member?.theme}
                        text={findUpper(member?.nickname)}
                        image={member?.image}
                        className="chat-media"
                        key={index}
                      ></UserAvatar>
                    );
                  })}
                  {/* {isActive ? (
                    <span className="status dot dot-lg dot-success"></span>
                  ) : (
                    <span className="status dot dot-lg dot-gray"></span>
                  )} */}
                </div>
              ) : (
                <UserAvatar
                  // image={Uchat.image}
                  // theme={Uchat.theme}
                  text={findUpper(channel?.name)}
                >
                  {/* {isActive ? (
                    <span className="status dot dot-lg dot-success"></span>
                  ) : (
                    <span className="status dot dot-lg dot-gray"></span>
                  )} */}
                </UserAvatar>
              )}
              <div
                className="user-info"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <div
                  className="lead-text"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {channel?.name}
                </div>
                <div className="sub-text">
                  <span className="d-none d-sm-inline">
                    {isActive && "Active Now"}
                    {!isActive &&
                      lastTimeActive &&
                      `Active ${moment.unix(lastTimeActive).fromNow()}`}
                  </span>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <ul className="nk-chat-head-tools">
          <li>
            <a
              href="#call"
              onClick={onAudioCallClick}
              className="btn btn-icon btn-trigger text-primary"
            >
              <Icon name="call-fill"></Icon>
            </a>
          </li>
          <li>
            <a
              href="#video"
              onClick={onVideoCallClick}
              className="btn btn-icon btn-trigger text-primary"
            >
              <Icon name="video-fill"></Icon>
            </a>
          </li>
          <li className="d-none d-sm-block">
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="dropdown-toggle btn btn-icon btn-trigger text-primary"
              >
                <Icon name="setting-fill"></Icon>
              </DropdownToggle>
              <DropdownMenu right className="dropdown-menu">
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      className="dropdown-item"
                      // disabled={Uchat.archive}
                      href="#dropdown"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <Icon name="archive"></Icon>
                      <span>Make as Archive</span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      className="dropdown-item"
                      href="#dropdown"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <Icon name="cross-c"></Icon>
                      <span>Remove Conversion</span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
          <li className="mr-n1 mr-md-n2">
            <a
              href="#alert"
              onClick={(e) => {
                e.preventDefault();
                toggleMenu();
              }}
              className="btn btn-icon btn-trigger text-primary chat-profile-toggle"
            >
              <Icon name="alert-circle-fill"></Icon>
            </a>
          </li>
        </ul>
      </div>
      {lastPinnedMessage && (
        <div className="nk-chat-head py-1">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
              marginRight: "1.5rem",
            }}
          >
            <Icon
              name="chat-circle"
              style={{
                fontSize: "1.5rem",
                marginRight: "0.7rem",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textWrap: "nowrap",
                justifyContent: "center",
                fontSize: "0.8rem",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  color: "#854fff",
                }}
              >
                Pinned message
              </div>
              <div
                style={{
                  fontSize: "0.9rem",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {lastPinnedMessage?.message}
              </div>
            </div>
          </div>
          <div>
            <Icon
              name="list"
              style={{
                fontSize: "1.5rem",
                cursor: "pointer",
              }}
              onClick={() => setIsOpenPinnedView(true)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const ThreadHeader = ({ thread, chat }) => {
  return (
    <div className="nk-chat-head">
      <ul
        className="nk-chat-head-info"
        style={{
          overflow: "hidden",
        }}
      >
        <li>
          <a
            href="#hide-chat"
            onClick={(ev) => {
              ev.preventDefault();
            }}
            className="btn btn-icon btn-trigger nk-chat-hide ml-n1"
          >
            <Icon name="arrow-left"></Icon>
          </a>
        </li>
        <li
          className="nk-chat-head-user"
          style={{
            overflow: "hidden",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              fontSize: "0.9rem",
              marginLeft: "0.3rem",
              textWrap: "nowrap",
            }}
          >
            <div>Thread</div>
            <div className="mt-1">
              <Icon
                name="chat-circle"
                style={{
                  width: "100%",
                  marginRight: "0.4rem",
                }}
              />
              <span>{chat.currentlyJoinedChannel?.name}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

const PinnedViewHeader = ({ pinnedMessages, setIsOpenPinnedView }) => {
  return (
    <div className="nk-chat-head">
      <ul className="nk-chat-head-info">
        <li>
          <a
            href="#hide-chat"
            onClick={(ev) => {
              ev.preventDefault();
              setIsOpenPinnedView(false);
            }}
            className="btn btn-icon btn-trigger nk-chat-hide ml-n1"
          >
            <Icon name="arrow-left"></Icon>
          </a>
        </li>
        <li
          className="nk-chat-head-user"
          style={{
            fontSize: "1rem",
          }}
        >
          <span>Pinned messages</span>
        </li>
      </ul>
    </div>
  );
};
