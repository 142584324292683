import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  offset: 0,
  itemsPerPage: 10,
};

export const goalFrequencySlice = createSlice({
  name: "goalFrequency",
  initialState: initialState,
  reducers: {
    onChangePageState(state, action) {
      const { offset, itemsPerPage } = action.payload;
      return {
        ...state,
        offset,
        itemsPerPage,
      };
    },
  },
});

export const { onChangePageState } = goalFrequencySlice.actions;

export default goalFrequencySlice.reducer;
