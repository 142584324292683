import { api } from "../api";

export const account = api.injectEndpoints({
  endpoints: (builder) => ({
    getAccounts: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/account-management",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          accounts: response.data,
          total: response.total,
        };
      },
      providesTags: ["Account"],
    }),
    createAccount: builder.mutation({
      query: (data) => ({
        url: "admin/v1/account-management",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Account"],
    }),
  }),
});

export const { useGetAccountsQuery, useCreateAccountMutation } = account;
