import { Col, Icon, Row, TooltipComponent } from "../../components/Components";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";

export const organisationColumns = [
  {
    name: "Organisation",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px" },
    minWidth: "250px",
    sortable: true,
    cell: (row) => (
      <Link
        to={row.id}
        rel="noopener noreferrer"
        // className="nk-menu-link"
        state={{ test: "asdasd" }}
      >
        <div
          className="user-card mt-2 mb-2"
          data-tag="allowRowEvents"
          data-action="details"
        >
          <div className="user-info">
            <span className="fs-11px">id: {row.id}</span>
            <span className="tb-lead">{row.name}</span>
          </div>
        </div>
      </Link>
    ),
  },
  {
    name: "Description",
    selector: (row) => row.description,
    cell: (row) => <span>{row.description}</span>,
    minWidth: "200px",
    hide: "md",
  },
  {
    //FIXME:
    name: "Relationship",
    minWidth: "200px",
    hide: "md",
    cell: (row) => (
      <Row>
        <Col className="pr-0 py-3">
          <p>
            Contracts: <br />
            TOB signed <br />
            MOU signed
          </p>

          <p>
            Internship: <br />
            5 placed <br />3 active
          </p>

          <p>
            Employment: <br />
            8 placed <br />7 active
          </p>
        </Col>
      </Row>
    ),
  },
  {
    //FIXME:
    name: "Address",
    cell: (row) => (
      <Row>
        <Col className="pr-0">
          <p>Main:</p>
          <p>Others:</p>
        </Col>
      </Row>
    ),
    minWidth: "200px",
    hide: "md",
  },
  {
    name: "Website",
    selector: (row) => row.website,
    cell: (row) => <span>{row.website}</span>,
    minWidth: "120px",
    hide: "md",
  },
  {
    name: "Status",
    selector: (row) => row.status,
    cell: (row) => <span>{row.status}</span>,
    minWidth: "120px",
    hide: "md",
  },
  {
    name: "Created",
    selector: (row) => row?.created_time,
    sortable: true,
    minWidth: "120px",
    cell: (row) => (
      <span>{moment(row?.created_time).format("DD-MM-YYYY, hh:mm:ss a")}</span>
    ),
    hide: "md",
  },
  {
    name: "Updated",
    selector: (row) => row?.last_updated_time,
    sortable: true,
    minWidth: "120px",
    cell: (row) => (
      <span>
        {moment(row?.last_updated_time).format("DD-MM-YYYY, hh:mm:ss a")}
      </span>
    ),
    hide: "md",
  },
  {
    name: (
      <UncontrolledDropdown>
        <DropdownToggle
          tag="a"
          className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
        >
          <Icon name="more-h"></Icon>
        </DropdownToggle>
        <DropdownMenu right>
          <ul className="link-list-opt no-bdr">
            {/* <li>
              <DropdownItem
                tag="a"
                href="#markasdone"
                onClick={(ev) => {
                  ev.preventDefault();
                  // selectorMarkAsDelivered();
                }}
              >
                <Icon name="truck"></Icon>
                <span>Mark As Delivered</span>
              </DropdownItem>
            </li> */}
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                  // selectorDeleteOrder();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Candidates</span>
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li className="nk-tb-action-hidden">
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-trigger btn-icon"
              id={"detail_" + row.id}
              icon="focus"
              direction="top"
              text="View Details"
              data-tag="allowRowEvents"
              data-action="details"
            />
          </li>
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#markasdone"
                      data-tag="allowRowEvents"
                      data-action="details"
                    >
                      <Icon
                        name="focus"
                        data-tag="allowRowEvents"
                        data-action="details"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="details">
                        View Details
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  },
];
