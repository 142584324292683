import { useDispatch, useSelector } from "react-redux";
import { useEffect, useLayoutEffect, useState } from "react";
import {
  onChangePageState,
  toggleModal,
  onFormCancel,
  onChangeSearchText,
  onChangeClearSelectedRows,
  onSelectedRowsChange,
} from "../../redux/features/supportLogsSlice";
import {
  useGetSupportLogsQuery,
  useCreateSupportLogsMutation,
  useUpdateSupportLogsMutation,
  useDeleteSupportLogsMutation,
  useLazyGetObservationGradingQuery,
  useLazyGetSupportMetricsQuery,
  useCreateSupportMetricsMutation,
} from "../../redux/api/support-management/supportApi";
import ReactDataTable from "../../components/table/ReactDataTable";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import SimpleBar from "simplebar-react";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
  Row,
  Col,
  ModalComponent,
  TextInput,
  SelectField,
  TextareaInput,
  TimePickerGroupInput,
  TimePickerInput,
  NSComponent,
} from "../../components/Components";
import {
  locationOptions,
  observationGradingOptions,
  supportLogsColumns,
  workTypeOptions,
} from "./DataColumns";
import { Spinner } from "reactstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { AsyncSelectField } from "../../components/form/SelectField";
import {
  metricsHardSkillsType,
  metricsMotorSkillsType,
  metricsOtherSkillsType,
  metricsSoftSkillsType,
} from "../../constants";
import moment from "moment";

const SupportLogs = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.supportLogs);
  const { data, isFetching, isSuccess } = useGetSupportLogsQuery({
    offset: state.offset,
    limit: state.itemsPerPage,
    total: true,
    reverse: true,
    search_text: state.searchText,
  });
  const defaultValues = {
    observations: [
      {
        time: null,
      },
    ],
    metrics: [],
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    control,
    reset,
    setValue,
    getValues,
  } = useForm({
    defaultValues: defaultValues,
  });
  const {
    fields: observationFields,
    append: observationsAppend,
    remove: observationsRemove,
  } = useFieldArray({
    control,
    name: "observations",
  });
  const {
    fields: metricsFields,
    append: metricsAppend,
    remove: metricsRemove,
  } = useFieldArray({
    control,
    name: "metrics",
  });
  const [
    createSupportLog,
    {
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess,
      isError: isCreateError,
      error: createError,
    },
  ] = useCreateSupportLogsMutation();
  const [
    updateSupportLog,
    {
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useUpdateSupportLogsMutation();
  const [
    deleteSupportLog,
    {
      data: deleteData,
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
      isError: isDeleteError,
      error: deleteError,
    },
  ] = useDeleteSupportLogsMutation();
  const [
    getObservationGrading,
    {
      data: observationGradingData,
      isSuccess: isObservationGradingSuccess,
      isFetching: isObservationGradingFetching,
      isError: isObservationGradingError,
    },
  ] = useLazyGetObservationGradingQuery({
    offset: 0,
    limit: 0,
  });
  const [
    createMetric,
    {
      isLoading: isCreateMetricLoading,
      isError: isCreateMetricError,
      isSuccess: isCreateMetricSuccess,
      error: createMetricError,
    },
  ] = useCreateSupportMetricsMutation();
  const [
    getMetrics,
    {
      data: metricData,
      isSuccess: isMetricSuccess,
      isFetching: isMetricFetching,
      isError: isMetricError,
    },
  ] = useLazyGetSupportMetricsQuery({
    offset: 0,
    limit: 0,
  });
  const { user } = useSelector((state) => state.auth);
  const [tableData, setTableData] = useState(data?.supportLogs ?? []);
  const [totalItems, setTotalItems] = useState(data?.total ?? 0);
  const [isOpen, setIsOpen] = useState(false);

  const changePageState = ({ offset = 0, itemsPerPage }) => {
    dispatch(onChangePageState({ offset, itemsPerPage: itemsPerPage }));
  };

  useLayoutEffect(() => {
    if (data) {
      setTableData(data.supportLogs);
      setTotalItems(data.total);
    }
  }, [data]);

  const onAddBtnClicked = (e) => {
    dispatch(toggleModal({ type: "add" }));
    getObservationGrading(undefined, true);
    reset(defaultValues);
  };

  const onRowClicked = (row, e) => {
    e.stopPropagation();
    const action = e.target.getAttribute("data-action");
    switch (action) {
      case "remove":
        deleteSupportLog({
          ids: [row.id],
        });
        break;
      case "details":
        dispatch(toggleModal({ type: "details", item: row }));
        break;
      case "edit":
        getObservationGrading(undefined, true);
        dispatch(toggleModal({ type: "edit", item: row }));

        reset({
          ...row,
          start_at: Date.parse(row.start_at),
          end_at: Date.parse(row.end_at),
          location: row.location
            ? { value: row.location, label: row.location }
            : null,
          observation_grading: row.observation_grading
            ? { value: row.observation_grading, label: row.observation_grading }
            : null,
          work_type: row.work_type
            ? { value: row.work_type, label: row.work_type }
            : null,
          observations: row.observations?.map((e) => ({
            ...e,
            time: Date.parse(e.time),
          })),
          metrics: row.metrics?.map((e) => {
            return {
              ...e,
              value: e.value.toString(),
            };
          }),
        });
        break;
    }
  };

  const processData = (rawData) => {
    var data = { ...rawData };

    data.location = data.location.value;
    data.observation_grading = data.observation_grading.value;
    data.start_at = data.start_at
      ? new Date(data.start_at).toISOString()
      : null;
    data.end_at = data.end_at ? new Date(data.end_at).toISOString() : null;
    data.work_type = data.work_type.value;
    data.email = user.email;
    data.metrics = data.metrics.map((item) => ({
      ...item,
      value: parseInt(item.value),
    }));
    data.observations = data.observations.map((item) => ({
      ...item,
      time: item.time ? new Date(item.time).toISOString() : null,
    }));

    return data;
  };

  const onFormSubmit = async (data) => {
    var newData = processData(data);
    if (state.modal.add) {
      createSupportLog(newData);
    } else if (state.modal.edit) {
      updateSupportLog({
        params: { id: state.modal.item.id },
        data: newData,
      });
    }
  };

  const onAsyncSelectChanged = (data, type) => {
    metricsAppend({
      id: data.value,
      name: data.label,
      value: 0,
      type: type,
    });
  };

  const noOptionsMessage = (type, data) => {
    if (data.inputValue.length >= 3) {
      return (
        <div>
          No results found. If you want to add a new one,{" "}
          <span
            style={{ cursor: "pointer", color: "#9d72ff" }}
            onClick={async () => {
              let resp = await createMetric({
                name: data.inputValue,
                type: type,
              }).unwrap();

              if (resp.status === "OK" && resp.data) {
                if (resp.data.length > 0) {
                  metricsAppend({
                    id: resp.data[0].id,
                    name: resp.data[0].name,
                    value: 0,
                    type: type,
                  });
                }
              }
            }}
          >
            click here
          </span>
        </div>
      );
    }
    return "Minimum 3 characters required";
  };

  const loadSkillsOptions = async (type, inputValue, callback) => {
    if (inputValue.length < 3) return;
    let response = await getMetrics(
      { offset: 0, limit: 0, search_text: inputValue, type: type },
      true
    ).unwrap();

    if (response.metrics) {
      const options = response.metrics.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      callback(options);
    }
  };

  useEffect(() => {
    if (isCreateSuccess) {
      dispatch(toggleModal({ type: "add" }));
      reset();
    }
    if (isCreateError && createError) {
      toast.error(createError?.data?.message);
    }
  }, [isCreateSuccess, isCreateError]);

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(toggleModal({ type: "edit" }));
      reset();
    }
    if (isUpdateError && updateError) {
      toast.error(updateError?.data?.message);
    }
  }, [isUpdateError, isUpdateSuccess]);

  useEffect(() => {
    if (isDeleteSuccess) {
      if (state.modal.removeItems) {
        dispatch(toggleModal({ type: "removeItems" }));
        dispatch(onChangeClearSelectedRows(!state.clearSelectedRows));
      } else {
        if (deleteData && deleteData.data) {
          let newSelectedRows = state.selectedRows.filter(
            (row) => row.id !== deleteData.data[0]
          );
          dispatch(onSelectedRowsChange(newSelectedRows));
        }
      }
    }
    if (isDeleteError && deleteError) {
      toast.error(deleteError?.data?.message);
    }
  }, [isDeleteSuccess, isDeleteError]);

  const handleSelectedRowsChange = ({ selectedRows }) => {
    dispatch(onSelectedRowsChange(selectedRows));
  };

  const handleApplySelectedRows = (row) => {
    return (
      state.selectedRows?.filter((selectedRow) => selectedRow.id === row.id)
        .length > 0
    );
  };

  return (
    <>
      <Head title="Support Logs" />
      <Content>
        <Block>
          <BlockHead>
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Support Logs</BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <Button
                    className="toggle btn-icon d-md-none"
                    color="primary"
                    onClick={onAddBtnClicked}
                  >
                    <Icon name="plus" />
                  </Button>
                  <Button
                    className="toggle d-none d-md-inline-flex"
                    color="primary"
                    onClick={onAddBtnClicked}
                  >
                    <Icon name="plus" />
                    <span>Add Support Log</span>
                  </Button>
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <PreviewCard>
            <ReactDataTable
              data={tableData}
              columns={supportLogsColumns}
              offset={state.offset}
              itemsPerPage={state.itemsPerPage}
              pagination
              totalItems={totalItems}
              progressPending={isFetching}
              className="nk-tb-list"
              selectableRows
              changePageState={changePageState}
              onRowClicked={onRowClicked}
              placeholderSearchText={"Search by for email"}
              searchFunc={(searchText) => {
                dispatch(onChangeSearchText(searchText));
              }}
              onSelectedRowsChange={handleSelectedRowsChange}
              clearSelectedRows={state.clearSelectedRows}
              selectableRowSelected={handleApplySelectedRows}
            />
          </PreviewCard>
        </Block>

        <ModalComponent
          isOpen={state.modal.details}
          toggle={() => dispatch(toggleModal({ type: "details" }))}
          title={"Support Log Details"}
          onModalCancel={() => {
            dispatch(onFormCancel());
          }}
        >
          {state.modal.item && (
            <Row className="gy-3">
              <Col lg={6}>
                <span className="sub-text">Email</span>
                <span className="caption-text">{state.modal.item.email}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">For Email</span>
                <span className="caption-text">
                  {state.modal.item.for_email}
                </span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Work Type</span>
                <span className="caption-text">
                  {state.modal.item.work_type}
                </span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Location</span>
                <span className="caption-text">
                  {state.modal.item.location}
                </span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Support timing from</span>
                <span className="caption-text">
                  {moment(state.modal.item.start_at).format(
                    "DD-MM-YYYY, hh:mm:ss a"
                  )}
                </span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Support timing to</span>
                <span className="caption-text">
                  {moment(state.modal.item.end_at).format(
                    "DD-MM-YYYY, hh:mm:ss a"
                  )}
                </span>
              </Col>
              <Col lg={12}>
                <span className="sub-text">Intervation</span>
                <span className="caption-text">
                  {state.modal.item.intervation}
                </span>
              </Col>
              <Col lg={12}>
                <span className="sub-text">Metrics Assessment</span>
                <span className="caption-text">
                  Soft skills: <br />
                  {state.modal.item.metrics?.map((item) => {
                    if (item.type === metricsSoftSkillsType) {
                      return `${item.name} - ${item.value} `;
                    }
                  })}
                </span>
                <div className="nk-divider divider mt-3 mb-2" />
                <span className="caption-text">
                  Hard skills: <br />
                  {state.modal.item.metrics?.map((item) => {
                    if (item.type === metricsHardSkillsType) {
                      return `${item.name} - ${item.value} `;
                    }
                  })}
                </span>
                <div className="nk-divider divider mt-3 mb-2" />
                <span className="caption-text">
                  Motor skills: <br />
                  {state.modal.item.metrics?.map((item) => {
                    if (item.type === metricsMotorSkillsType) {
                      return `${item.name} - ${item.value} `;
                    }
                  })}
                </span>
                <div className="nk-divider divider mt-3 mb-2" />
                <span className="caption-text">
                  Other skills: <br />
                  {state.modal.item.metrics?.map((item) => {
                    if (item.type === metricsOtherSkillsType) {
                      return `${item.name} - ${item.value} `;
                    }
                  })}
                </span>
                <div className="nk-divider divider mt-3 mb-2" />
              </Col>
              <Col lg={12}>
                <span className="sub-text">Observation Grading</span>
                <span className="caption-text">
                  {state.modal.item.observation_grading}
                </span>
              </Col>
              <Col lg={12}>
                <span className="sub-text">Observations</span>
                {state.modal.item.observations.map((item, index) => {
                  return (
                    <div key={`details-observation-${index}`} className="mt-2">
                      <span className="caption-text">
                        {moment(item.time).format("DD-MM-YYYY, hh:mm:ss a")}
                        <br />
                        {item.description}
                      </span>
                      <div className="nk-divider divider mt-3 mb-2" />
                    </div>
                  );
                })}
              </Col>
              <Col lg={6}>
                <span className="sub-text">Created Time</span>
                <span className="caption-text">
                  {moment(state.modal.item.created_time).format(
                    "DD-MM-YYYY, hh:mm:ss a"
                  )}
                </span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Last Updated Time</span>
                <span className="caption-text">
                  {moment(state.modal.item.last_updated_time).format(
                    "DD-MM-YYYY, hh:mm:ss a"
                  )}
                </span>
              </Col>
            </Row>
          )}
        </ModalComponent>
      </Content>

      <ModalComponent
        isOpen={state.modal.add || state.modal.edit}
        title={`${state.modal.add ? "Add" : "Edit"} Support Log`}
        onModalCancel={() => {
          dispatch(onFormCancel());
          reset();
        }}
        toggle={() => {
          dispatch(onFormCancel());
          reset();
        }}
        backdrop={"static"}
      >
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Row className="g-3">
            <TextInput
              name={"for_email"}
              label={"For whom"}
              required
              register={{
                ...register("for_email", {
                  required: "This field is required",
                }),
              }}
              errors={errors}
            />
            <SelectField
              name={"work_type"}
              label={"Work Type"}
              control={control}
              rules={{ required: "This field is required" }}
              options={workTypeOptions}
              errors={errors}
            />
            <SelectField
              name={"location"}
              label={"Location"}
              control={control}
              rules={{ required: "This field is required" }}
              options={locationOptions}
              errors={errors}
            />
            <TimePickerGroupInput
              nameStart={"start_at"}
              nameEnd={"end_at"}
              label={"Support Timing"}
              control={control}
              errors={errors}
              required
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Times"
              dateFormat="h:mm aa"
              md={"12"}
            />
            <TextareaInput
              name={"intervention"}
              label={"Intervention"}
              register={{
                ...register("intervention", {}),
              }}
              errors={errors}
              md="12"
            />
            <Col size="12">
              <label className="form-label">Metric Assessment</label>
              <Col size="0">
                <label>Soft skills</label>
                <AsyncSelectField
                  onChangeFunc={(newValue) => {
                    onAsyncSelectChanged(newValue, metricsSoftSkillsType);
                  }}
                  loadOptions={async (inputValue, callback) => {
                    await loadSkillsOptions(
                      metricsSoftSkillsType,
                      inputValue,
                      callback
                    );
                  }}
                  noOptionsMessage={(data) =>
                    noOptionsMessage(metricsSoftSkillsType, data)
                  }
                  isLoading={isCreateMetricLoading}
                />
                {metricsFields.map((item, index) => {
                  if (item.type === metricsSoftSkillsType) {
                    return (
                      <div
                        className="mt-3"
                        key={item.id}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <label className="mr-3 mb-0">{item.name}</label>
                        <NSComponent
                          name={`metrics.${index}.value`}
                          register={{
                            ...register(`metrics.${index}.value`, {}),
                          }}
                          defaultVal={
                            getValues(`metrics.${index}.value`)
                              ? parseInt(getValues(`metrics.${index}.value`))
                              : 0
                          }
                          min={0}
                          max={10}
                          color="light"
                          outline
                          setValue={setValue}
                        />
                        <Icon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            metricsRemove(index);
                          }}
                          name="trash"
                          className="fs-22px ml-2"
                        />
                      </div>
                    );
                  }
                })}
              </Col>
              <div className="nk-divider divider mt-3 mb-2" />
              <Col size="0">
                <label>Hard skills</label>
                <AsyncSelectField
                  onChangeFunc={(newValue) => {
                    onAsyncSelectChanged(newValue, metricsHardSkillsType);
                  }}
                  loadOptions={async (inputValue, callback) => {
                    await loadSkillsOptions(
                      metricsHardSkillsType,
                      inputValue,
                      callback
                    );
                  }}
                  noOptionsMessage={(data) =>
                    noOptionsMessage(metricsHardSkillsType, data)
                  }
                  isLoading={isCreateMetricLoading}
                />
                {metricsFields.map((item, index) => {
                  if (item.type === metricsHardSkillsType) {
                    return (
                      <div
                        className="mt-3"
                        key={item.id}
                        style={{ display: "flex", alignItems: "baseline" }}
                      >
                        <label className="mr-3">{item.name}</label>
                        <NSComponent
                          name={`metrics.${index}.value`}
                          register={{
                            ...register(`metrics.${index}.value`, {}),
                          }}
                          defaultVal={
                            getValues(`metrics.${index}.value`)
                              ? parseInt(getValues(`metrics.${index}.value`))
                              : 0
                          }
                          min={0}
                          max={10}
                          color="light"
                          outline
                          setValue={setValue}
                        />
                        <Icon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            metricsRemove(index);
                          }}
                          name="trash"
                          className="fs-22px ml-2"
                        />
                      </div>
                    );
                  }
                })}
              </Col>
              <div className="nk-divider divider mt-3 mb-2" />
              <Col size="0">
                <label>Motor skills</label>
                <AsyncSelectField
                  onChangeFunc={(newValue) => {
                    onAsyncSelectChanged(newValue, metricsMotorSkillsType);
                  }}
                  loadOptions={async (inputValue, callback) => {
                    await loadSkillsOptions(
                      metricsMotorSkillsType,
                      inputValue,
                      callback
                    );
                  }}
                  noOptionsMessage={(data) =>
                    noOptionsMessage(metricsMotorSkillsType, data)
                  }
                  isLoading={isCreateMetricLoading}
                />
                {metricsFields.map((item, index) => {
                  if (item.type === metricsMotorSkillsType) {
                    return (
                      <div
                        className="mt-3"
                        key={item.id}
                        style={{ display: "flex", alignItems: "baseline" }}
                      >
                        <label className="mr-3">{item.name}</label>
                        <NSComponent
                          name={`metrics.${index}.value`}
                          register={{
                            ...register(`metrics.${index}.value`, {}),
                          }}
                          defaultVal={
                            getValues(`metrics.${index}.value`)
                              ? parseInt(getValues(`metrics.${index}.value`))
                              : 0
                          }
                          min={0}
                          max={10}
                          color="light"
                          outline
                          setValue={setValue}
                        />
                        <Icon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            metricsRemove(index);
                          }}
                          name="trash"
                          className="fs-22px ml-2"
                        />
                      </div>
                    );
                  }
                })}
              </Col>
              <div className="nk-divider divider mt-3 mb-2" />
              <Col size="0">
                <label>Other skills</label>
                <AsyncSelectField
                  onChangeFunc={(newValue) => {
                    onAsyncSelectChanged(newValue, metricsOtherSkillsType);
                  }}
                  loadOptions={async (inputValue, callback) => {
                    await loadSkillsOptions(
                      metricsOtherSkillsType,
                      inputValue,
                      callback
                    );
                  }}
                  noOptionsMessage={(data) =>
                    noOptionsMessage(metricsOtherSkillsType, data)
                  }
                  isLoading={isCreateMetricLoading}
                />
                {metricsFields.map((item, index) => {
                  if (item.type === metricsOtherSkillsType) {
                    return (
                      <div
                        className="mt-3"
                        key={item.id}
                        style={{ display: "flex", alignItems: "baseline" }}
                      >
                        <label className="mr-3">{item.name}</label>
                        <NSComponent
                          name={`metrics.${index}.value`}
                          register={{
                            ...register(`metrics.${index}.value`, {}),
                          }}
                          defaultVal={
                            getValues(`metrics.${index}.value`)
                              ? parseInt(getValues(`metrics.${index}.value`))
                              : 0
                          }
                          min={0}
                          max={10}
                          color="light"
                          outline
                          setValue={setValue}
                        />
                        <Icon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            metricsRemove(index);
                          }}
                          name="trash"
                          className="fs-22px ml-2"
                        />
                      </div>
                    );
                  }
                })}
              </Col>
              <div className="nk-divider divider mt-3 mb-2" />
            </Col>
            <SelectField
              name={"observation_grading"}
              label={"Observation Grading"}
              control={control}
              rules={{ required: "This field is required" }}
              options={
                observationGradingData?.observationGrading.map((item) => ({
                  label: item.observation_grading,
                  value: item.observation_grading,
                })) ?? {}
              }
              isLoading={isObservationGradingFetching}
              isDisabled={isObservationGradingFetching}
              errors={errors}
            />
            <Col size="12">
              <label className="form-label">
                Observations <span className="required-asterisk">*</span>
              </label>
              <SimpleBar className="w-100" forceVisible="x" autoHide={false}>
                <div className="table-wrapper table-actions">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">S/N</th>
                        <th scope="col" className="w-min-150px">
                          Timing <span className="required-asterisk">*</span>
                        </th>
                        <th scope="col" className="w-min-350px">
                          Observation{" "}
                          <span className="required-asterisk">*</span>
                        </th>
                        <th scope="col" className="w-min-150px">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {observationFields.map((item, index) => {
                        return (
                          <tr key={`observation-${index}`}>
                            <td>{index + 1}</td>
                            <td>
                              <TimePickerInput
                                name={`observations.${index}.time`}
                                placeholder={"hh:mm"}
                                dateFormat={"HH:mm"}
                                timeFormat={"HH:mm"}
                                control={control}
                                rules={{ required: "This field is required" }}
                                errors={errors}
                                portalId={`observations_${index}_time`}
                                md="0"
                              />
                            </td>
                            <td>
                              <TextareaInput
                                name={`observations.${index}.description`}
                                register={{
                                  ...register(
                                    `observations.${index}.description`,
                                    {
                                      required: "This field is required",
                                    }
                                  ),
                                }}
                                errors={errors}
                                md="12"
                              />
                            </td>
                            <td>
                              {index === observationFields.length - 1 && (
                                <Icon
                                  onClick={() => {
                                    observationsAppend({
                                      name: "",
                                      priority: "",
                                      description: "",
                                      year_diagnosed: null,
                                      place_diagnosed: "",
                                      remarks: "",
                                    });
                                  }}
                                  name="plus-c"
                                  className="fs-22px mr-2"
                                />
                              )}
                              {((observationFields.length > 1 && index === 0) ||
                                index !== 0) && (
                                <Icon
                                  onClick={() => {
                                    observationsRemove(index);
                                  }}
                                  name="trash"
                                  className="fs-22px"
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </SimpleBar>
            </Col>

            <Col size="12">
              <Button
                color="primary"
                type="submit"
                disabled={isCreateLoading || isUpdateLoading}
              >
                {(isCreateLoading || isUpdateLoading) && (
                  <Spinner type="grow" size="sm" />
                )}
                <span>{state.modal.add ? "Add" : "Edit"}</span>
              </Button>
            </Col>
          </Row>
        </form>
      </ModalComponent>

      <ModalComponent
        isOpen={isOpen}
        title={`Add Soft Skills`}
        onModalCancel={() => {
          setIsOpen(false);
        }}
        toggle={() => {
          setIsOpen(false);
        }}
      >
        Testst
      </ModalComponent>

      <ModalComponent
        isOpen={state.modal.removeItems}
        backdrop={"static"}
        title={"Are you sure you want to remove selected items?"}
        onModalCancel={() => {
          dispatch(toggleModal({ type: "removeItems" }));
        }}
        disableCloseBtn={true}
        size={"xs"}
      >
        <Row className="gy-3">
          <Col size="12">
            <Button
              color="primary"
              type="submit"
              disabled={isDeleteLoading}
              onClick={() => {
                deleteSupportLog({
                  ids: state.selectedRows.map((row) => row.id),
                });
              }}
            >
              {isDeleteLoading && <Spinner type="grow" size="sm" />}
              <span>Yes</span>
            </Button>
            <Button
              className="ml-3"
              color="light"
              onClick={() => {
                dispatch(toggleModal({ type: "removeItems" }));
              }}
            >
              <span>No</span>
            </Button>
          </Col>
        </Row>
      </ModalComponent>
    </>
  );
};
export default SupportLogs;
