import { api } from "../api";

export const organisationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrganisations: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/organisations",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          organisations: response.data,
          total: response.total,
        };
      },
      providesTags: ["Organisations"],
    }),
    getOrganisation: builder.query({
      query: (data) => {
        return {
          url: "admin/v1/organisation",
          params: data,
        };
      },
      transformResponse: (response) => {
        return response.data[0];
      },
      providesTags: (result, error, data) => [
        { type: "Organisation", id: data.id },
      ],
    }),
    editOrganisation: builder.mutation({
      query: (data) => ({
        url: "admin/v1/organisation",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, data) => [
        { type: "Organisation", id: data.id },
      ],
    }),
    createOrganisation: builder.mutation({
      query: (data) => ({
        url: "admin/v1/organisation",
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => {
        return response.data[0];
      },
      invalidatesTags: ["Organisations"],
    }),
    getContacts: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/organisation/contact",
          params: params,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, data) => [
        { type: "OrganisationContacts", id: data.id },
      ],
    }),
    createContact: builder.mutation({
      query: (data) => ({
        url: "admin/v1/organisation/contact",
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => {
        return response.data[0];
      },
      invalidatesTags: (result, error, data) => [
        { type: "OrganisationContacts", id: data.organisations_id },
      ],
    }),
    updateContact: builder.mutation({
      query: (data) => {
        return {
          url: "admin/v1/organisation/contact",
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (result, error, data) => [
        { type: "OrganisationContacts", id: data.organisations_id },
      ],
    }),
    deleteContact: builder.mutation({
      query: (params) => ({
        url: "admin/v1/organisation/contact",
        method: "DELETE",
        params: params,
      }),
      invalidatesTags: (result, error, data) => [
        { type: "OrganisationContacts", id: data.organisations_id },
      ],
    }),
    getOrganisationLog: builder.query({
      query: (data) => {
        return {
          url: "admin/v1/organisation/log",
          params: data,
        };
      },
      transformResponse: (response) => {
        return response.data[0];
      },
      providesTags: (result, error, data) => [
        { type: "OrganisationLogs", id: data.id },
      ],
    }),
    addOrganisationLog: builder.mutation({
      query: (data) => ({
        url: "admin/v1/organisation/log",
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, error, data) => {
        let invalidatesArray = [
          { type: "OrganisationLogs", id: data.organisation_id },
        ];
        if (data?.is_start_pipeline) {
          invalidatesArray.push({
            type: "OrganisationTOBs",
            id: data.organisation_id,
          });
        }
        return invalidatesArray;
      },
    }),
    editOrganisationLog: builder.mutation({
      query: (data) => ({
        url: "admin/v1/organisation/log",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, data) => [
        { type: "OrganisationLogs", id: data.organisation_id },
      ],
    }),
    removeOrganisationLog: builder.mutation({
      query: (data) => ({
        url: "admin/v1/organisation/log",
        method: "DELETE",
        params: data,
      }),
      invalidatesTags: (result, error, data) => [
        { type: "OrganisationLogs", id: data.organisation_id },
      ],
    }),
    getSupportingDocs: builder.query({
      query: (data) => {
        return {
          url: "admin/v1/organisation/supporting-doc",
          params: data,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, data) => [
        { type: "OrganisationSupportingDocs", id: data.id },
      ],
    }),
    addSupportingDoc: builder.mutation({
      query: (data) => ({
        url: "admin/v1/organisation/supporting-doc",
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, error, data) => [
        { type: "OrganisationSupportingDocs", id: data.organisation_id },
      ],
    }),
    editSupportingDoc: builder.mutation({
      query: (data) => ({
        url: "admin/v1/organisation/supporting-doc",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, data) => [
        { type: "OrganisationSupportingDocs", id: data.organisation_id },
      ],
    }),
    removeSupportingDoc: builder.mutation({
      query: (data) => ({
        url: "admin/v1/organisation/supporting-doc",
        method: "DELETE",
        params: data,
      }),
      invalidatesTags: (result, error, data) => [
        { type: "OrganisationSupportingDocs", id: data.organisation_id },
      ],
    }),
    getOrganisationOpportunity: builder.query({
      query: (data) => {
        return {
          url: "admin/v1/organisation/opportunity",
          params: data,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, data) => [
        { type: "OrganisationOpportunities", id: data.id },
      ],
    }),
    addOrganisationOpportunity: builder.mutation({
      query: (data) => ({
        url: "admin/v1/organisation/opportunity",
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, error, data) => [
        { type: "OrganisationOpportunities", id: data.organisation_id },
      ],
    }),
    editOrganisationOpportunity: builder.mutation({
      query: (data) => ({
        url: "admin/v1/organisation/opportunity",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, data) => [
        { type: "OrganisationOpportunities", id: data.organisation_id },
      ],
    }),
    removeOrganisationOpportunity: builder.mutation({
      query: (data) => ({
        url: "admin/v1/organisation/opportunity",
        method: "DELETE",
        params: data,
      }),
      invalidatesTags: (result, error, data) => [
        { type: "OrganisationOpportunities", id: data.organisation_id },
      ],
    }),
    getOrganisationTOB: builder.query({
      query: (data) => {
        return {
          url: "admin/v1/organisation/tob",
          params: data,
        };
      },
      transformResponse: (response) => {
        return response.data[0];
      },
      providesTags: (result, error, data) => [
        { type: "OrganisationTOBs", id: data.id },
      ],
    }),
    addOrganisationTOB: builder.mutation({
      query: (data) => ({
        url: "admin/v1/organisation/tob",
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, error, data) => [
        { type: "OrganisationTOBs", id: data.organisation_id },
      ],
    }),
    editOrganisationTOB: builder.mutation({
      query: (data) => ({
        url: "admin/v1/organisation/tob",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, data) => [
        { type: "OrganisationTOBs", id: data.organisation_id },
      ],
    }),
    removeOrganisationTOB: builder.mutation({
      query: (data) => ({
        url: "admin/v1/organisation/tob",
        method: "DELETE",
        params: data,
      }),
      invalidatesTags: (result, error, data) => [
        { type: "OrganisationTOBs", id: data.organisation_id },
      ],
    }),
    updatePipelineStage: builder.mutation({
      query: (data) => ({
        url: "admin/v1/organisation/pipeline",
        method: "PUT",
        body: data,
      }),
      transformResponse: (response) => {
        return response.data[0];
      },
      invalidatesTags: (result, error, data) => [
        { type: "OrganisationTOBs", id: data.organisation_id },
        { type: "OrganisationLogs", id: data.organisation_id },
      ],
    }),
    getOrganisationBillable: builder.query({
      query: (data) => {
        return {
          url: "admin/v1/organisation/billable",
          params: data,
        };
      },
      transformResponse: (response) => {
        return response.data[0];
      },
      providesTags: (result, error, data) => [
        { type: "OrganisationBillables", id: data.id },
      ],
    }),
    addOrganisationBillable: builder.mutation({
      query: (data) => ({
        url: "admin/v1/organisation/billable",
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, error, data) => [
        { type: "OrganisationBillables", id: data.organisation_id },
      ],
    }),
    editOrganisationBillable: builder.mutation({
      query: (data) => ({
        url: "admin/v1/organisation/billable",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, data) => [
        { type: "OrganisationBillables", id: data.organisation_id },
      ],
    }),
    removeOrganisationBillable: builder.mutation({
      query: (data) => ({
        url: "admin/v1/organisation/billable",
        method: "DELETE",
        params: data,
      }),
      invalidatesTags: (result, error, data) => [
        { type: "OrganisationBillables", id: data.organisation_id },
      ],
    }),
    addOrganisationBillableRecord: builder.mutation({
      query: (data) => ({
        url: "admin/v1/organisation/billable-record",
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, error, data) => [
        { type: "OrganisationBillables", id: data.organisation_id },
      ],
    }),
    editOrganisationBillableRecord: builder.mutation({
      query: (data) => ({
        url: "admin/v1/organisation/billable-record",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, data) => [
        { type: "OrganisationBillables", id: data.organisation_id },
      ],
    }),
    removeOrganisationBillableRecord: builder.mutation({
      query: (data) => ({
        url: "admin/v1/organisation/billable-record",
        method: "DELETE",
        params: data,
      }),
      invalidatesTags: (result, error, data) => [
        { type: "OrganisationBillables", id: data.organisation_id },
      ],
    }),
  }),
});

export const {
  useGetOrganisationsQuery,
  useLazyGetOrganisationsQuery,
  useGetOrganisationQuery,
  useLazyGetOrganisationQuery,
  useEditOrganisationMutation,
  useCreateOrganisationMutation,
  useGetContactsQuery,
  useCreateContactMutation,
  useUpdateContactMutation,
  useDeleteContactMutation,
  useGetOrganisationLogQuery,
  useLazyGetOrganisationLogQuery,
  useAddOrganisationLogMutation,
  useEditOrganisationLogMutation,
  useRemoveOrganisationLogMutation,
  useGetSupportingDocsQuery,
  useLazyGetSupportingDocsQuery,
  useAddSupportingDocMutation,
  useEditSupportingDocMutation,
  useRemoveSupportingDocMutation,
  useGetOrganisationOpportunityQuery,
  useLazyGetOrganisationOpportunityQuery,
  useAddOrganisationOpportunityMutation,
  useEditOrganisationOpportunityMutation,
  useRemoveOrganisationOpportunityMutation,
  useGetOrganisationTOBQuery,
  useLazyGetOrganisationTOBQuery,
  useEditOrganisationTOBMutation,
  useRemoveOrganisationTOBMutation,
  useAddOrganisationTOBMutation,
  useUpdatePipelineStageMutation,
  useGetOrganisationBillableQuery,
  useLazyGetOrganisationBillableQuery,
  useAddOrganisationBillableMutation,
  useEditOrganisationBillableMutation,
  useRemoveOrganisationBillableMutation,
  useAddOrganisationBillableRecordMutation,
  useEditOrganisationBillableRecordMutation,
  useRemoveOrganisationBillableRecordMutation,
} = organisationsApi;
