import {
  Icon,
  TooltipComponent,
  BatchMenuComponent,
} from "../../components/Components";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { toggleModal } from "../../redux/features/quotesSlice";
import { toast } from "react-toastify";

export const quotesColumns = [
  {
    name: "Quote",
    selector: (row) => row.quote,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px" },
    cell: (row) => <span>{row.quote}</span>,
    sortable: true,
  },
  {
    name: "Author",
    selector: (row) => row.author,
    minWidth: "140px",
    cell: (row) => <span>{row.author}</span>,
    sortable: true,
    hide: 480,
  },
  {
    name: "Created",
    selector: (row) => row.created_time,
    minWidth: "120px",
    sortable: true,
    cell: (row) => (
      <span>{moment(row.created_time).format("DD-MM-YYYY, hh:mm:ss a")}</span>
    ),
    hide: "md",
  },
  {
    name: "Updated",
    selector: (row) => row.last_updated_time,
    minWidth: "120px",
    sortable: true,
    cell: (row) => (
      <span>
        {moment(row.last_updated_time).format("DD-MM-YYYY, hh:mm:ss a")}
      </span>
    ),
    hide: "md",
  },
  {
    name: (
      <BatchMenuComponent
        getState={() => useSelector((state) => state.quotes)}
        onRemoveItemsClicked={(state, dispatch) => {
          if (state.selectedRows && state.selectedRows.length > 0) {
            dispatch(toggleModal({ type: "removeItems" }));
          } else {
            toast.error("There are no selected items to remove.");
          }
        }}
      />
    ),
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li className="nk-tb-action-hidden">
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-trigger btn-icon"
              id={"view" + row.id}
              icon="eye"
              direction="top"
              text="View Details"
              data-tag="allowRowEvents"
              data-action="details"
            />
          </li>
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#details"
                      data-tag="allowRowEvents"
                      data-action="details"
                    >
                      <Icon
                        name="eye"
                        data-tag="allowRowEvents"
                        data-action="details"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="details">
                        View Details
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      data-tag="allowRowEvents"
                      data-action="edit"
                    >
                      <Icon
                        name="edit"
                        data-tag="allowRowEvents"
                        data-action="edit"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="edit">
                        Edit
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  },
];
