import React from "react";
import ReactDataTable from "../../components/table/ReactDataTable";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { userData, dataTableColumns2 } from "./DataMockup";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
} from "../../components/Components";
import { useEffect } from "react";
// import { useTestQuery } from "../../redux/api/test";

/*
 * Create form to request access token from Google's OAuth 2.0 server.
 */
function oauthSignIn() {
  // Google's OAuth 2.0 endpoint for requesting an access token
  var oauth2Endpoint = "https://accounts.google.com/o/oauth2/v2/auth";

  // Create <form> element to submit parameters to OAuth 2.0 endpoint.
  var form = document.createElement("form");
  form.setAttribute("method", "GET"); // Send as a GET request.
  form.setAttribute("action", oauth2Endpoint);

  // Parameters to pass to OAuth 2.0 endpoint.
  var params = {
    client_id:
      "436448898828-gmelmq8rrhbckvvlnjn4sg0gi29pbomn.apps.googleusercontent.com",
    redirect_uri: "http://localhost:3000",
    response_type: "token",
    scope: "https://www.googleapis.com/auth/drive.metadata.readonly",
    include_granted_scopes: "true",
    state: "pass-through value",
  };

  // Add form parameters as hidden input values.
  for (var p in params) {
    var input = document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", p);
    input.setAttribute("value", params[p]);
    form.appendChild(input);
  }

  // Add form to page and submit it to open the OAuth 2.0 endpoint.
  document.body.appendChild(form);
  form.submit();
}

const Test = () => {
  // const { data, isFetching, isSuccess } = useTestQuery();

  // useEffect(() => {
  //   oauthSignIn();
  // }, []);

  return (
    <>
      <Head title="Test"></Head>
      <Content>
        <Button onClick={oauthSignIn}>asd</Button>
      </Content>
    </>
  );
};

export default Test;
