import { useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewAltCard,
  PreviewCard,
  Row,
  Col,
  ModalComponent,
  TextInput,
  SelectField,
  TextareaInput,
} from "../../components/Components";
import CalenderApp from "./calender/Calender";
import { useForm } from "react-hook-form";
import { eventOptions, events } from "./calender/CalenderData";

// Function to structure date ex: YYYY-MM-DD
export const setDateForPicker = (rdate) => {
  let d = rdate.getDate();
  d < 10 && (d = "0" + d);
  let m = rdate.getMonth() + 1;
  m < 10 && (m = "0" + m);
  let y = rdate.getFullYear();
  rdate = y + "-" + m + "-" + d;

  return rdate;
};

const Calendar = () => {
  const [modal, setModal] = useState(false);
  const [mockEvents, updateEvent] = useState(events);
  const [dates, setDates] = useState({
    startDate: new Date(),
    startTime: new Date(),
    endTime: new Date(),
    endDate: new Date(),
  });
  const [theme, settheme] = useState("");
  const toggle = () => {
    setModal(!modal);
  };
  const { errors, register, handleSubmit } = useForm();

  const handleFormSubmit = (formData) => {
    let newEvent = {
      id: "default-event-id-" + Math.floor(Math.random() * 9999999),
      title: formData.title,
      start: setDateForPicker(dates.startDate),
      end: setDateForPicker(dates.endDate),
      description: formData.description,
      className: theme.value,
      type: theme,
    };
    updateEvent([...mockEvents, newEvent]);
    settheme({
      value: "fc-event-primary",
      label: "Company",
    });
    toggle();
  };

  const editEvent = (formData) => {
    let newEvents = mockEvents;
    const index = newEvents.findIndex((item) => item.id === formData.id);
    events[index] = formData;
    updateEvent([...events]);
  };

  const deleteEvent = (id) => {
    let filteredEvents = mockEvents.filter((item) => item.id !== id);
    updateEvent(filteredEvents);
  };
  return (
    <>
      <Head title="Calender" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Calendar</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <Button color="primary" onClick={toggle}>
                <Icon name="plus" />
                <span>Add Event</span>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewAltCard>
            <CalenderApp
              events={mockEvents}
              onDelete={deleteEvent}
              onEdit={editEvent}
            />
          </PreviewAltCard>
        </Block>
      </Content>
    </>
  );
};

export default Calendar;
