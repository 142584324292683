import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import {
  Icon,
  Button,
  Block,
  BlockDes,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  TextareaInput,
  Col,
  Row,
  SelectField,
  PreviewCard,
  TextInput,
  PhoneNumberWithCountryCodeInput,
  DatePickerInput,
  BlockContent,
} from "../../components/Components";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Spinner,
  Breadcrumb,
  BreadcrumbItem,
  Tooltip,
  UncontrolledTooltip,
} from "reactstrap";
import { useCreateCandidateMutation } from "../../redux/api/users/usersApi";
import { toast } from "react-toastify";
import { countryFlagsMap, nioIconData } from "../../constants";

const CandidatesCreation = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [createCandidate, result] = useCreateCandidateMutation();

  useEffect(() => {
    if (!state) {
      navigate("/admin/candidates", { replace: true });
    }
  }, []);

  useEffect(() => {
    if (result.isSuccess) {
    }
    if (result.isError) {
      if (result.error?.data?.message) {
        toast.error(result.error.data.message);
      }
    }
  }, [result.isSuccess, result.isError]);

  return (
    <>
      <Head title={`Create ${state?.type} profile`}></Head>
      <Content>
        <BlockHead size="sm">
          <Breadcrumb
            tag="nav"
            listTag="div"
            className="breadcrumb-arrow fs-18px"
          >
            <BreadcrumbItem tag="a" href="../">
              Home
            </BreadcrumbItem>
            <BreadcrumbItem tag="a" href="./">
              Candidates
            </BreadcrumbItem>
            <BreadcrumbItem active>Create {state?.type} profile</BreadcrumbItem>
          </Breadcrumb>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Create {state?.type} profile
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <Button
                color="light"
                outline
                className="bg-white d-none d-sm-inline-flex"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <Icon name="arrow-left"></Icon>
                <span>Back</span>
              </Button>
              <a
                href="#back"
                onClick={(ev) => {
                  ev.preventDefault();
                  navigate(-1);
                }}
                className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
              >
                <Icon name="arrow-left"></Icon>
              </a>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          {!result.isSuccess &&
            (state?.type === "interim" ? (
              <InterimForm createCandidate={createCandidate} result={result} />
            ) : (
              <CandidateForm
                createCandidate={createCandidate}
                result={result}
              />
            ))}
          {result.isSuccess && (
            <SuccessForm type={state?.type} data={result.data} />
          )}
        </Block>
      </Content>
    </>
  );
};

const SuccessForm = ({ type, data }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <PreviewCard className="card-bordered">
      <BlockHead>
        <BlockContent className={"d-flex align-items-center flex-column"}>
          <div className="w-100px mb-4">{nioIconData["doc-checked"]}</div>
          <BlockTitle tag="h4">
            Candidate {type === "interim" ? "interim" : ""} profile created.
          </BlockTitle>
        </BlockContent>
      </BlockHead>
      <div className="fs-18px mt-2">
        <p>
          <strong className="fw-bold">First Name:</strong> {data?.first_name}
        </p>
        <p>
          <strong className="fw-bold">Last Name:</strong> {data?.last_name}
        </p>
        <p>
          <strong className="fw-bold">Email:</strong> {data?.email}
        </p>
        <p>
          <strong className="fw-bold">Mobile Number:</strong>{" "}
          {data?.country_calling_code
            ? countryFlagsMap[data?.country_calling_code]?.secondary +
              data?.phone_number
            : data?.phone_number}
        </p>
        <p>
          <strong className="fw-bold">Introduction Form URL:</strong>{" "}
          <Link
            to={`${window.location.hostname}/introduction/${data?.reference_code}`}
          >
            {data?.reference_code
              ? `${window.location.hostname}/introduction/${data?.reference_code}`
              : ""}
          </Link>
          <Button
            className="btn-icon ml-2"
            id={`copy-btn-form-url`}
            color="light"
            size="xs"
            onClick={() => {
              navigator.clipboard.writeText(
                `${window.location.hostname}/introduction/${data?.reference_code}`
              );
              setTooltipOpen(true);
              setTimeout(() => {
                setTooltipOpen(false);
              }, 2000);
            }}
          >
            <Icon name="copy" />
          </Button>
          <Tooltip
            isOpen={tooltipOpen}
            placement="top"
            fade={true}
            // toggle={toggleTooltip}
            target={`copy-btn-form-url`}
          >
            Copied
          </Tooltip>
        </p>
      </div>
    </PreviewCard>
  );
};

const InterimForm = ({ createCandidate, result }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: {
      country_calling_code: "SG",
    },
  });
  const { isLoading } = result;

  const onFormSubmit = (data) => {
    createCandidate(data);
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <PreviewCard className="card-bordered">
        <Row className="g-3">
          <Col size="12">
            <BlockTitle tag="h5">
              Your personal &amp; contact information
            </BlockTitle>
            <div className="nk-divider divider mt-3 mb-1" />
          </Col>
          <TextInput
            name={"email"}
            label={"Email address"}
            placeholder={"name@example.com"}
            required
            register={{
              ...register("email", {
                required: "This field is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              }),
            }}
            errors={errors}
            md="12"
          />
          <TextInput
            name={"first_name"}
            label={"First name"}
            placeholder={"your personal name"}
            required
            register={{
              ...register("first_name", {
                required: "This field is required",
              }),
            }}
            errors={errors}
            // defaultValue={user?.first_name}
            md="12"
          />
          <TextInput
            name={"last_name"}
            label={"Last name"}
            placeholder={"your surname"}
            required
            register={{
              ...register("last_name", {
                required: "This field is required",
              }),
            }}
            errors={errors}
            // defaultValue={user?.last_name}
            md="12"
          />
          <PhoneNumberWithCountryCodeInput
            label={"Mobile number"}
            nameCode={"country_calling_code"}
            nameInputField={"phone_number"}
            rules={{
              required: "This field is required",
            }}
            register={register}
            control={control}
            errors={errors}
            md="12"
          />
        </Row>
      </PreviewCard>
      <Row className=" mt-4 mb-2 mx-0 justify-content-around">
        <Button
          color="primary"
          type="submit"
          size={"lg"}
          className={"w-min-300px justify-content-center"}
          disabled={isLoading}
        >
          {isLoading && <Spinner type="grow" size="sm" className="mr-1" />}
          <span>CREATE</span>
        </Button>
      </Row>
    </form>
  );
};

const CandidateForm = ({ createCandidate, result }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
    watch,
    trigger,
  } = useForm({
    reValidateMode: "onChange",
    defaultValues: {
      caregiver_country_calling_code: "SG",
      country_calling_code: "SG",
    },
  });
  const { isLoading } = result;
  const watchFields = watch(["primary_caregiver", "recent_employer"]);

  //TODO: Need to refactor watch trigger revalidation fields with useEffect dep
  useEffect(() => {
    if (watchFields[0]) {
      trigger([
        "caregiver_first_name",
        "caregiver_last_name",
        "caregiver_phone_number",
      ]);
    }
  }, [watchFields[0]]);
  //   useEffect(() => {
  //     if (watchFields[1] !== undefined) {
  //       trigger([
  //         "recent_job_role",
  //         "recent_first_month_of_work",
  //         "reason_leaving_job",
  //       ]);
  //     }
  //   }, [watchFields[1]]);

  const processData = (data) => {
    data.is_travel_independently = data.is_travel_independently?.value;
    data.primary_caregiver = data.primary_caregiver?.value;

    data.highest_education = data.highest_education?.value;
    data.time_start_highest_education = !data.time_start_highest_education
      ? null
      : new Date(data.time_start_highest_education).toISOString();
    data.time_end_highest_education = !data.time_end_highest_education
      ? null
      : new Date(data.time_end_highest_education).toISOString();

    data.recent_first_month_of_work = !data.recent_first_month_of_work
      ? null
      : new Date(data.recent_first_month_of_work).toISOString();
    data.recent_last_month_of_work = !data.recent_last_month_of_work
      ? null
      : new Date(data.recent_last_month_of_work).toISOString();

    data.disability_name = data.disability_name?.value;
    data.disability_year_diagnosed = !data.disability_year_diagnosed
      ? null
      : new Date(data.disability_year_diagnosed).toISOString();

    return data;
  };

  const onFormSubmit = (data) => {
    data = processData(data);
    createCandidate(data);
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <PreviewCard className="card-bordered">
        <Row className="g-3">
          <Col size="12">
            <BlockTitle tag="h5">
              Your personal &amp; contact information
            </BlockTitle>
            <div className="nk-divider divider mt-3 mb-1" />
          </Col>
          <TextInput
            name={"email"}
            label={"Email address"}
            placeholder={"name@example.com"}
            required
            register={{
              ...register("email", {
                required: "This field is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              }),
            }}
            errors={errors}
            md="12"
          />
          <TextInput
            name={"first_name"}
            label={"First name"}
            placeholder={"your personal name"}
            required
            register={{
              ...register("first_name", {
                required: "This field is required",
              }),
            }}
            errors={errors}
            // defaultValue={user?.first_name}
            md="12"
          />
          <TextInput
            name={"last_name"}
            label={"Last name"}
            placeholder={"your surname"}
            required
            register={{
              ...register("last_name", {
                required: "This field is required",
              }),
            }}
            errors={errors}
            // defaultValue={user?.last_name}
            md="12"
          />
          <PhoneNumberWithCountryCodeInput
            label={"Mobile number"}
            nameCode={"country_calling_code"}
            nameInputField={"phone_number"}
            rules={{
              required: "This field is required",
            }}
            register={register}
            control={control}
            errors={errors}
            md="12"
          />
          <SelectField
            name={"is_travel_independently"}
            label={"Are you able to travel independently?"}
            control={control}
            rules={{ required: "This field is required" }}
            isSearchable={false}
            options={[
              { value: "Yes", label: "Yes" },
              { value: "No", label: "No" },
            ]}
            errors={errors}
            md="12"
          />
          <SelectField
            name={"primary_caregiver"}
            label={"Who is your primary caregiver?"}
            control={control}
            rules={{ required: "This field is required" }}
            isSearchable={false}
            options={[
              { value: "Parent", label: "Parent" },
              {
                value: "Sibling",
                label: "Sibling",
              },
              { value: "Spouse", label: "Spouse" },
              {
                value: "No primary caregiver",
                label: "No primary caregiver",
              },
              { value: "Other", label: "Other" },
            ]}
            // onChangeFunc={(cData) => {
            //   console.log(cData);
            //   if (cData) {
            //     trigger(["caregiver_name", "caregiver_phone_number"]);
            //   }
            // }}
            errors={errors}
            md="12"
          />
          <TextInput
            name={"caregiver_first_name"}
            label={"Caregiver first name"}
            caption={"Leave this empty if you do not have a caregiver"}
            placeholder={"your caregiver's first name"}
            required={
              watchFields[0] &&
              watchFields[0].valueOf !== "No primary caregiver"
            }
            onChangeFunc={() => {
              trigger("caregiver_first_name");
            }}
            register={{
              ...register("caregiver_first_name", {
                required:
                  watchFields[0] &&
                  watchFields[0].valueOf !== "No primary caregiver"
                    ? "This field is required"
                    : false,
              }),
            }}
            errors={errors}
            md="12"
          />
          <TextInput
            name={"caregiver_last_name"}
            label={"Caregiver last name"}
            caption={"Leave this empty if you do not have a caregiver"}
            placeholder={"your caregiver's last name"}
            required={
              watchFields[0] &&
              watchFields[0].valueOf !== "No primary caregiver"
            }
            onChangeFunc={() => {
              trigger("caregiver_last_name");
            }}
            register={{
              ...register("caregiver_last_name", {
                required:
                  watchFields[0] &&
                  watchFields[0].valueOf !== "No primary caregiver"
                    ? "This field is required"
                    : false,
              }),
            }}
            errors={errors}
            md="12"
          />
          <PhoneNumberWithCountryCodeInput
            label={"Caregiver mobile number"}
            caption={"Leave this empty if you do not have a caregiver"}
            nameCode={"caregiver_country_calling_code"}
            nameInputField={"caregiver_phone_number"}
            register={register}
            onChangeFunc={() => {
              trigger("caregiver_phone_number");
            }}
            rules={{
              required:
                watchFields[0] &&
                watchFields[0].valueOf !== "No primary caregiver"
                  ? "This field is required"
                  : false,
            }}
            control={control}
            errors={errors}
            md="12"
          />
          <TextareaInput
            name={"reason_to_work"}
            label={"Why do you want to work?"}
            placeholder={"motivation and reason for working"}
            required
            register={{
              ...register("reason_to_work", {
                required: "This field is required",
              }),
            }}
            errors={errors}
            md="12"
          />
          <TextareaInput
            name={"interests"}
            label={"What are your hobbies and interests?"}
            placeholder={"what you like to do in your free time"}
            register={{
              ...register("interests"),
            }}
            errors={errors}
            md="12"
          />
        </Row>
      </PreviewCard>

      <PreviewCard className="card-bordered">
        <Row className="g-3">
          <Col size="12">
            <BlockTitle tag="h5">Your highest level of education</BlockTitle>
            <BlockDes className="text-soft">
              <p>
                The school that you studied at, and the course you attended, for
                your highest level of education
              </p>
            </BlockDes>
            <div className="nk-divider divider mt-3 mb-1" />
          </Col>
          <SelectField
            name={"highest_education"}
            label={"Highest level of education"}
            control={control}
            rules={{ required: "This field is required" }}
            options={[
              { value: "PSLE", label: "PSLE" },
              {
                value: "GCE N-Level",
                label: "GCE N-Level",
              },
              {
                value: "GCE O-Level",
                label: "GCE O-Level",
              },
              {
                value: "GCE A-Level",
                label: "GCE A-Level",
              },
              {
                value: "Nitec",
                label: "Nitec",
              },
              {
                value: "Higher Nitec",
                label: "Higher Nitec",
              },
              {
                value: "Diploma",
                label: "Diploma",
              },
              {
                value: "Bachelor's",
                label: "Bachelor's",
              },
              {
                value: "Masters",
                label: "Masters",
              },
              {
                value: "PHD",
                label: "PHD",
              },
              {
                value: "Others",
                label: "Others",
              },
            ]}
            errors={errors}
            md="12"
          />
          <TextInput
            name={"highest_education_school"}
            label={"School"}
            placeholder={"name of school"}
            required
            register={{
              ...register("highest_education_school", {
                required: "This field is required",
              }),
            }}
            errors={errors}
            md="12"
          />
          <TextInput
            name={"highest_education_course"}
            label={"Course"}
            placeholder={"name of course or programme"}
            required
            register={{
              ...register("highest_education_course", {
                required: "This field is required",
              }),
            }}
            errors={errors}
            md="12"
          />
          <DatePickerInput
            name={"time_start_highest_education"}
            label={"From"}
            showMonthYearPicker
            dateFormat={"MM/yyyy"}
            placeholder={"mm/yyyy"}
            control={control}
            rules={{ required: "This field is required" }}
            errors={errors}
            md="12"
          />
          <DatePickerInput
            name={"time_end_highest_education"}
            label={"To"}
            showMonthYearPicker
            dateFormat={"MM/yyyy"}
            placeholder={"mm/yyyy"}
            control={control}
            rules={{ required: "This field is required" }}
            errors={errors}
            md="12"
          />
        </Row>
      </PreviewCard>

      <PreviewCard className="card-bordered">
        <Row className="g-3">
          <Col size="12">
            <BlockTitle tag="h5">Your most recent employment</BlockTitle>
            <BlockDes className="text-soft">
              <p>
                If you have not been employed before, please leave this section
                empty
              </p>
            </BlockDes>
            <div className="nk-divider divider mt-3 mb-1" />
          </Col>
          <TextInput
            name={"recent_employer"}
            label={"Employer"}
            placeholder={"name of employer"}
            register={{
              ...register("recent_employer"),
            }}
            onBlurFunc={() => {
              trigger([
                "recent_job_role",
                "recent_first_month_of_work",
                "reason_leaving_job",
              ]);
            }}
            errors={errors}
            md="12"
          />
          <TextInput
            name={"recent_job_role"}
            label={"Your job title/role"}
            placeholder={"e.g. admin"}
            required={watchFields[1]}
            onChangeFunc={() => {
              trigger("recent_job_role");
            }}
            register={{
              ...register("recent_job_role", {
                required: watchFields[1] ? "This field is required" : false,
              }),
            }}
            errors={errors}
            md="12"
          />
          <DatePickerInput
            name={"recent_first_month_of_work"}
            label={"First month of work"}
            showMonthYearPicker
            dateFormat={"MM/yyyy"}
            placeholder={"mm/yyyy"}
            rules={{
              required: watchFields[1] ? "This field is required" : false,
            }}
            onChangeFunc={() => {
              trigger("recent_first_month_of_work");
            }}
            control={control}
            errors={errors}
            md="12"
          />
          <DatePickerInput
            name={"recent_last_month_of_work"}
            label={"Last month of work"}
            caption={"Leave this empty if you are still currently working"}
            showMonthYearPicker
            dateFormat={"MM/yyyy"}
            placeholder={"mm/yyyy"}
            control={control}
            errors={errors}
            md="12"
          />
          <TextareaInput
            name={"reason_leaving_job"}
            label={"Reason for leaving your job"}
            placeholder={"why did you leave, or why are you leaving your job?"}
            required={watchFields[1]}
            register={{
              ...register("reason_leaving_job", {
                required: watchFields[1] ? "This field is required" : false,
              }),
            }}
            onChangeFunc={() => {
              trigger("reason_leaving_job");
            }}
            errors={errors}
            md="12"
          />
        </Row>
      </PreviewCard>

      <PreviewCard className="card-bordered">
        <Row className="g-3">
          <Col size="12">
            <BlockTitle tag="h5">Your disability details</BlockTitle>
            <div className="nk-divider divider mt-3 mb-1" />
          </Col>
          <SelectField
            name={"disability_name"}
            label={"What is your disability?"}
            caption={"Select Others if your disability is not in this list"}
            control={control}
            rules={{ required: "This field is required" }}
            options={[
              {
                value: "Attention Deficit Hyperactivity Disorder",
                label: "Attention Deficit Hyperactivity Disorder",
              },
              {
                value: "Autism Spectrum Disorder",
                label: "Autism Spectrum Disorder",
              },
              { value: "Blind - full", label: "Blind - full" },
              { value: "Blind - partial", label: "Blind - partial" },
              { value: "Cerebral Palsy", label: "Cerebral Palsy" },
              { value: "Deaf - full", label: "Deaf - full" },
              { value: "Deaf - partial", label: "Deaf - partial" },
              { value: "Dyslexia", label: "Dyslexia" },
              {
                value: "Mild Intellectual Disability",
                label: "Mild Intellectual Disability",
              },
              { value: "Physical", label: "Physical" },
              { value: "Others", label: "Others" },
            ]}
            errors={errors}
            md="12"
          />
          <TextareaInput
            name={"disability_description"}
            label={"Please tell us more about your disability"}
            caption={
              "If you selected Others from the list, please indicate your disability here too"
            }
            placeholder={"share more details about your disability"}
            required
            register={{
              ...register("disability_description", {
                required: "This field is required",
              }),
            }}
            errors={errors}
            md="12"
          />
          <DatePickerInput
            name={"disability_year_diagnosed"}
            label={"Which year were you diagnosed with your disability?"}
            showYearPicker
            dateFormat={"yyyy"}
            placeholder={"yyyy"}
            maxDate={new Date()}
            rules={{ required: "This field is required" }}
            control={control}
            errors={errors}
            md="12"
          />
          <TextareaInput
            name={"ongoing_therapy"}
            label={"Please share any ongoing therapy that you are attending"}
            caption={"Indicate as N.A. if you are not attending any therapy"}
            placeholder={"e.g weekly physical therapy sessions at hospital"}
            required
            register={{
              ...register("ongoing_therapy", {
                required: "This field is required",
              }),
            }}
            errors={errors}
            md="12"
          />
          <TextareaInput
            name={"medical_history"}
            label={"Please share your medical history"}
            caption={"Indicate as N.A. if you do not have any medical history"}
            placeholder={"e.g epilepsy"}
            required
            register={{
              ...register("medical_history", {
                required: "This field is required",
              }),
            }}
            errors={errors}
            md="12"
          />
        </Row>
      </PreviewCard>

      <PreviewCard className="card-bordered">
        <Row className="g-3">
          <Col size="12">
            <BlockTitle tag="h5">General</BlockTitle>
            <div className="nk-divider divider mt-3 mb-1" />
          </Col>
          <TextareaInput
            name={"who_filled_form"}
            label={"Who filled this form?"}
            caption={"If some helped you fill this form, please indicate who"}
            placeholder={"e.g. myself, caregiver, etc"}
            required
            register={{
              ...register("who_filled_form", {
                required: "This field is required",
              }),
            }}
            errors={errors}
            md="12"
          />
          <TextareaInput
            name={"more_things_sharing"}
            label={"Is there anything else that you want to let us know?"}
            register={{
              ...register("more_things_sharing"),
            }}
            errors={errors}
            md="12"
          />
          <TextareaInput
            name={"how_know_inclus"}
            label={"How did you know about Inclus?"}
            required
            register={{
              ...register("how_know_inclus", {
                required: "This field is required",
              }),
            }}
            errors={errors}
            md="12"
          />
        </Row>
      </PreviewCard>
      <Row className=" mt-4 mb-2 mx-0 justify-content-around">
        <Button
          color="primary"
          type="submit"
          size={"lg"}
          className={"w-min-300px"}
          disabled={isLoading}
        >
          {isLoading && <Spinner type="grow" size="sm" />}
          <span>CREATE</span>
        </Button>
      </Row>
    </form>
  );
};

export default CandidatesCreation;
