import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import {
  Icon,
  Button,
  Block,
  BlockDes,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  TextareaInput,
  Col,
  Row,
  SelectField,
  ModalComponent,
  TextInput,
  DatePickerInput,
  PhoneNumberWithCountryCodeInput,
} from "../../components/Components";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  Progress,
  UncontrolledTooltip,
} from "reactstrap";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {
  useGetUserQuery,
  useEditUserMutation,
} from "../../redux/api/users/usersApi";
import React, {
  useEffect,
  useState,
  createContext,
  useRef,
  useContext,
  useCallback,
} from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Spinner } from "reactstrap";
import { toast } from "react-toastify";
import { countryFlagsMap, nioIconData } from "../../constants";
import moment from "moment";
import SimpleBar from "simplebar-react";
import {
  capitalize,
  convertUserData,
  getLanguageOptions,
} from "../../utils/utils";
import { useDropzone } from "react-dropzone";
import {
  useFinalizeMultipartUploadMutation,
  useInitializeMultipartUploadMutation,
} from "../../redux/api/common/uploadApi";
import { Uploader } from "../../utils/httpUtility";
import { start } from "../../redux/features/upload/uploaderSlice";
import { FlagsSelectField } from "../../components/form/SelectField";
import {
  useLazyGetReligionsQuery,
  useLazyGetSexesQuery,
} from "../../redux/api/dropdown-data/dropdownDataApi";

const DataContext = createContext();

const DataContextProvider = ({ user, ...props }) => {
  if (!user) {
    return (
      <Spinner
        className="mt-3 mb-3"
        style={{ display: "block", margin: "auto" }}
      />
    );
  }
  return (
    <DataContext.Provider
      value={{
        user: user,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};

const CaregiverDetails = () => {
  let params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { data: user, isFetching } = useGetUserQuery({ id: params.id });
  const [isUploadModalOpen, setUploadModal] = useState(false);
  const [uploadCategory, setUploadCategory] = useState(null);

  return (
    <>
      <Head title="Caregiver"></Head>
      <Content>
        <BlockHead size="sm">
          <Breadcrumb
            tag="nav"
            listTag="div"
            className="breadcrumb-arrow fs-18px"
          >
            <BreadcrumbItem tag="a" href="../">
              Home
            </BreadcrumbItem>
            <BreadcrumbItem tag="a" href="./">
              Caregiver
            </BreadcrumbItem>
            <BreadcrumbItem
              active
            >{`${user?.first_name} ${user?.last_name}`}</BreadcrumbItem>
          </Breadcrumb>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Users /{" "}
                <strong className="text-primary small">{`${user?.first_name} ${user?.last_name}`}</strong>
              </BlockTitle>
              <BlockDes className="text-soft">
                Status:{" "}
                <span className="candidate-status text-base">CUSTOMER</span>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <Button
                color="light"
                outline
                className="bg-white d-none d-sm-inline-flex"
                onClick={() => {
                  navigate("../");
                }}
              >
                <Icon name="arrow-left"></Icon>
                <span>Back</span>
              </Button>
              <a
                href="#back"
                onClick={(ev) => {
                  ev.preventDefault();
                  navigate(-1);
                }}
                className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
              >
                <Icon name="arrow-left"></Icon>
              </a>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <DataContextProvider user={user}>
          <Block>
            <Card className="card-bordered">
              <div className="card-content">
                <div className="card-inner active">
                  <PersonalSection
                    setUploadModal={setUploadModal}
                    setUploadCategory={setUploadCategory}
                  />
                </div>
              </div>
            </Card>

            <CommonModals
              isUploadModalOpen={isUploadModalOpen}
              setUploadModal={setUploadModal}
              uploadCategory={uploadCategory}
            />
          </Block>
        </DataContextProvider>
      </Content>
    </>
  );
};

export default CaregiverDetails;

const CommonModals = ({
  isUploadModalOpen,
  setUploadModal,
  uploadCategory,
}) => {
  return (
    <React.Fragment>
      <UploadModal
        isUploadModalOpen={isUploadModalOpen}
        setUploadModal={setUploadModal}
        uploadCategory={uploadCategory}
      />
    </React.Fragment>
  );
};

const UploadModal = ({ isUploadModalOpen, setUploadModal, uploadCategory }) => {
  const { user } = useContext(DataContext);
  const [files, setFiles] = useState([]);
  const [percentage, setPercentage] = useState(null);
  const [uploader, setUploader] = useState(0);
  const [isUploading, setUploading] = useState(false);
  const [isUploadSuccessOpen, setUploadSuccessModal] = useState(false);
  const [
    initializeMultipartUpload,
    {
      data: initializeMultipartUploadData,
      isLoading: isInitializeMultipartUploadLoading,
      isError: isInitializeMultipartUploadError,
      isSuccess: isInitializeMultipartUploadSuccess,
      error: initializeMultipartUploadError,
    },
  ] = useInitializeMultipartUploadMutation();
  const [
    finalizationMultipartUpload,
    {
      isLoading: isFinalizeMultipartUploadLoading,
      isSuccess: isFinalizeMultipartUploadSuccess,
      isError: isFinalizeMultipartUploadError,
      error: finalizeMultipartUploadError,
    },
  ] = useFinalizeMultipartUploadMutation();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          console.log(err);

          if (err.code === "file-too-large") {
            toast.error("File too large");
          }

          if (err.code === "file-invalid-type") {
          }
        });
      });
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm();

  const onUploadSubmit = () => {
    if (files) {
      const uploader = new Uploader({
        userId: user.id,
        fileName: files[0].name,
        file: files[0],
        fileType: files[0].type,
        uploadCategory: uploadCategory,
        initializeMultipartUpload: initializeMultipartUpload,
        finalizationMultipartUpload: finalizationMultipartUpload,
      });
      setUploader(uploader);

      uploader
        .onProgress(({ percentage: newPercentage }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            setPercentage(newPercentage);
          }
        })
        .onError((error) => {
          setUploading(false);
          setFiles([]);
          setPercentage(0);
          toast.error(error);
        });

      uploader.start();
      setUploading(true);
    }
  };

  useEffect(() => {
    if (
      isFinalizeMultipartUploadSuccess ||
      isFinalizeMultipartUploadError ||
      isInitializeMultipartUploadError
    ) {
      setUploading(false);
      setFiles([]);
      setPercentage(0);

      if (isFinalizeMultipartUploadError) {
        toast.error(finalizeMultipartUploadError?.data?.message);
      }
      if (isInitializeMultipartUploadError) {
        toast.error(initializeMultipartUploadError?.data?.message);
      }

      if (isFinalizeMultipartUploadSuccess) {
        setUploadModal(false);
        setUploadSuccessModal(true);
      }
    }
  }, [
    isFinalizeMultipartUploadSuccess,
    isFinalizeMultipartUploadError,
    isInitializeMultipartUploadError,
  ]);

  return (
    <React.Fragment>
      <ModalComponent
        isOpen={isUploadModalOpen}
        title={"Upload File"}
        onModalCancel={() => {
          setUploadModal(false);
        }}
      >
        <form onSubmit={handleSubmit(onUploadSubmit)}>
          <Row className="gy-3">
            <Col size="12">
              <section>
                <div
                  {...getRootProps()}
                  className="dropzone upload-zone dz-clickable h-min-100px"
                >
                  <input {...getInputProps()} />
                  {files.length === 0 && (
                    <div className="dz-message">
                      <span className="dz-message-text">
                        Drag and drop file
                      </span>
                      <span className="dz-message-or">or</span>
                      <Button color="primary">SELECT</Button>
                    </div>
                  )}
                  {files.map((file) => (
                    <React.Fragment key={`file-name-${file.name}`}>
                      <div>{file.name}</div>
                      <Progress
                        className="progress-lg mt-2"
                        animated
                        value={percentage}
                      >
                        {`${percentage}%`}
                      </Progress>
                    </React.Fragment>
                  ))}
                  {/* {files.map((file) => (
                    <div
                      key={file.name}
                      className="dz-preview dz-processing dz-image-preview dz-error dz-complete"
                    >
                      <div className="dz-image">
                        <img src={file.preview} alt="preview" />
                      </div>
                    </div>
                  ))} */}
                </div>
              </section>

              <div className="d-flex justify-content-end mt-3">
                <Button
                  className="mr-2"
                  color="light"
                  type="button"
                  onClick={() => {
                    setUploadModal(false);
                  }}
                >
                  Cancel
                </Button>
                <Button color="primary" type="submit" disabled={isUploading}>
                  {isUploading && <Spinner type="grow" size="sm" />}
                  <span>Submit</span>
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </ModalComponent>

      <ModalComponent
        isOpen={isUploadSuccessOpen}
        disableCloseBtn={true}
        onModalCancel={() => {
          setUploadSuccessModal(false);
        }}
      >
        <Row className="gy-3">
          <Col size="12" className="d-flex align-items-center flex-column">
            <div className="w-100px mb-4">{nioIconData["doc-checked"]}</div>
            <BlockTitle tag="h4">File(s) uploaded successfully!</BlockTitle>
            <Button
              className="mt-2"
              color="primary"
              onClick={() => {
                setUploadSuccessModal(false);
              }}
            >
              <span className="px-2">OK</span>
            </Button>
          </Col>
        </Row>
      </ModalComponent>
    </React.Fragment>
  );
};

const PersonalSection = ({
  sectionRef,
  setTab,
  setUploadModal,
  setUploadCategory,
}) => {
  const { user } = useContext(DataContext);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    control,
    reset,
  } = useForm();
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [
    editUser,
    {
      isLoading: isEditUserLoading,
      isSuccess: isEditUserSuccess,
      isError: isEditUserError,
      error: editUserError,
    },
  ] = useEditUserMutation();
  const [
    getSexOptions,
    {
      data: sexData,
      isFetching: isGetSexFetching,
      isSuccess: isGetSexSuccess,
      isError: isGetSexError,
      error: getSexError,
    },
  ] = useLazyGetSexesQuery({
    limit: 1000,
  });
  const [
    getReligionOptions,
    {
      data: religionData,
      isFetching: isGetReligionFetching,
      isSuccess: isGetReligionSuccess,
      isError: isGetReligionError,
      error: getReligionError,
    },
  ] = useLazyGetReligionsQuery({
    limit: 1000,
  });
  const [sexOptions, setSexOptions] = useState([]);
  const [religionOptions, setReligionOptions] = useState([]);
  const [editData, setEditData] = useState(user);

  useEffect(() => {
    if (isGetSexSuccess && sexData?.sexes) {
      let data = sexData.sexes.map((e) => {
        return { value: e.sex, label: e.sex };
      });
      setSexOptions(data);
    }
  }, [isGetSexSuccess, isGetSexError]);

  useEffect(() => {
    if (isGetReligionSuccess && religionData?.religions) {
      let data = religionData.religions.map((e) => {
        return { value: e.religion, label: e.religion };
      });
      setReligionOptions(data);
    }
  }, [isGetReligionSuccess, isGetReligionError]);

  useEffect(() => {
    if (isEditUserSuccess) {
      toast.success("Edit successfully!");
      setEditModalOpen(false);
    }
    if (isEditUserError && editUserError) {
      toast.error(editUserError?.data?.message);
    }
  }, [isEditUserSuccess, isEditUserError]);

  const defaultValues = {
    home_country_calling_code: "SG",
    country_calling_code: "SG",
  };
  const onEditBtnClicked = () => {
    getSexOptions(undefined, true);
    getReligionOptions(undefined, true);
    reset(
      {
        ...defaultValues,
        first_name: user?.first_name,
        last_name: user?.last_name,
        preferred_name: user?.preferred_name,
        last_4_digits_nric: user?.last_4_digits_nric,
        nationality_code: user?.nationality_code,
        date_of_birth: user.date_of_birth
          ? Date.parse(user.date_of_birth)
          : null,
        sex: user.sex ? { value: user.sex, label: user.sex } : null,
        phone_number: user?.phone_number,
        country_calling_code: user?.country_calling_code
          ? user?.country_calling_code
          : defaultValues.country_calling_code,
        home_country_calling_code: user?.home_country_calling_code
          ? user?.home_country_calling_code
          : defaultValues.home_country_calling_code,
        home_phone_number: user?.home_phone_number,
        religion: user.religion
          ? { value: user.religion, label: user.religion }
          : null,
        // ptc_card_description: user.ptc_card_description
        //   ? {
        //       value: user.ptc_card_description,
        //       label: user.ptc_card_description,
        //     }
        //   : null,
        // languages_spoken: user.languages_spoken
        //   ? user.languages_spoken.map((e) => ({ value: e, label: e }))
        //   : null,
        // languages_written: user.languages_written
        //   ? user.languages_written.map((e) => ({ value: e, label: e }))
        //   : null,
      },
      {
        keepDefaultValues: true,
      }
    );
    setEditModalOpen(true);
  };

  const onEditModalCancel = () => {
    setEditModalOpen(false);
  };

  const onFormSubmit = (data) => {
    data = convertUserData(data, defaultValues);
    data.id = user.id;
    editUser(data);
  };

  return (
    <div>
      <Block>
        <BlockHead>
          {/* <BlockBetween className="mb-1"> */}
          <BlockTitle tag="h5">
            Personal Information{" "}
            <Button
              color="primary"
              outline
              size="xs"
              className="d-none d-sm-inline-flex ml-3"
              onClick={onEditBtnClicked}
            >
              <Icon name="edit-alt-fill"></Icon>
              <span>Edit</span>
            </Button>
          </BlockTitle>

          {/* <Block>
              <Button
                className="d-none d-sm-inline-flex ml-3 toggle btn-icon btn-trigger"
                id={`edit-personal-info-btn`}
                onClick={onEditBtnClicked}
              >
                <Icon name="edit-alt-fill"></Icon>
              </Button>
              <UncontrolledTooltip
                placement="top"
                target={`edit-personal-info-btn`}
              >
                Edit
              </UncontrolledTooltip>
            </Block> */}
          {/* </BlockBetween> */}
          <p>
            Basic info, like name and address, that you use on Inclus Platform.
          </p>
        </BlockHead>
        <div className="profile-ud-list">
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">First Name</span>
              <span className="profile-ud-value">{user?.first_name}</span>
            </div>
          </div>
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Date of Birth</span>
              <span className="profile-ud-value">
                {user?.date_of_birth
                  ? moment(user?.date_of_birth).format("DD/MM/YYYY")
                  : null}
              </span>
            </div>
          </div>
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Last Name</span>
              <span className="profile-ud-value">{user?.last_name}</span>
            </div>
          </div>
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Nationality</span>
              <span className="profile-ud-value">{user?.nationality}</span>
            </div>
          </div>
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">
                Preferred to be called as
              </span>
              <span className="profile-ud-value">{user?.preferred_name}</span>
            </div>
          </div>
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Race</span>
              <span className="profile-ud-value">{user?.race}</span>
            </div>
          </div>
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Sex</span>
              <span className="profile-ud-value">{user?.sex}</span>
            </div>
          </div>
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Religion</span>
              <span className="profile-ud-value">{user?.religion}</span>
            </div>
          </div>
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Last 4 digits of NRIC</span>
              <span className="profile-ud-value">
                {user?.last_4_digits_nric}
              </span>
            </div>
          </div>
        </div>
      </Block>

      <Block>
        <BlockHead className="nk-block-head-line">
          <BlockTitle tag="h6" className="overline-title text-base">
            Personal \ Contact Info
          </BlockTitle>
        </BlockHead>
        <div className="profile-ud-list">
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Mobile number</span>
              <span className="profile-ud-value">
                {countryFlagsMap[user?.country_calling_code]?.secondary +
                  user?.phone_number}
              </span>
            </div>
          </div>
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Email address</span>
              <span className="profile-ud-value">{user?.email}</span>
            </div>
          </div>
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Home number</span>
              <span className="profile-ud-value">
                {user?.home_country_calling_code && user?.home_phone_number
                  ? countryFlagsMap[user?.home_country_calling_code]
                      ?.secondary + user?.home_phone_number
                  : ""}
              </span>
            </div>
          </div>
        </div>
      </Block>

      <ModalComponent
        isOpen={isEditModalOpen}
        backdrop={"static"}
        title={"Edit Personal Information"}
        onModalCancel={() => {
          setEditModalOpen(false);
        }}
      >
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Row className="gy-3">
            <TextInput
              name={"first_name"}
              label={"First name"}
              required
              register={{
                ...register("first_name", {
                  required: "This field is required",
                }),
              }}
              errors={errors}
              md="12"
            />
            <TextInput
              name={"last_name"}
              label={"Last name"}
              required
              register={{
                ...register("last_name", {
                  required: "This field is required",
                }),
              }}
              errors={errors}
              md="12"
            />
            <TextInput
              name={"preferred_name"}
              label={"Preferred to be called by"}
              placeholder={"preferred name or alias"}
              required
              register={{
                ...register("preferred_name", {
                  required: "This field is required",
                }),
              }}
              errors={errors}
              md="12"
            />
            <TextInput
              name={"last_4_digits_nric"}
              label={"Last 4 digits of NRIC"}
              placeholder={"e.g. 123A"}
              required
              register={{
                ...register("last_4_digits_nric", {
                  required: "This field is required",
                }),
              }}
              errors={errors}
              md="12"
            />
            <FlagsSelectField
              name={"nationality_code"}
              label={"Nationality"}
              control={control}
              rules={{ required: "This field is required" }}
              errors={errors}
              md="12"
            />
            <DatePickerInput
              name={"date_of_birth"}
              label={"Date of birth"}
              placeholder={"dd/mm/yyyy"}
              dateFormat={"dd/MM/yyyy"}
              control={control}
              rules={{ required: "This field is required" }}
              errors={errors}
              md="12"
            />
            <SelectField
              name={"sex"}
              label={"Sex"}
              control={control}
              rules={{ required: "This field is required" }}
              options={sexOptions}
              isSearchable={false}
              isLoading={isGetSexFetching}
              isDisabled={isGetSexFetching}
              errors={errors}
              md="12"
            />
            <SelectField
              name={"religion"}
              label={"Religion"}
              control={control}
              rules={{ required: "This field is required" }}
              options={religionOptions}
              isSearchable={false}
              isLoading={isGetReligionFetching}
              isDisabled={isGetReligionFetching}
              errors={errors}
              md="12"
            />
            <PhoneNumberWithCountryCodeInput
              label={"Home telephone"}
              nameCode={"home_country_calling_code"}
              nameInputField={"home_phone_number"}
              register={register}
              control={control}
              errors={errors}
              md="12"
            />
            <PhoneNumberWithCountryCodeInput
              label={"Mobile number"}
              nameCode={"country_calling_code"}
              nameInputField={"phone_number"}
              rules={{
                required: "This field is required",
              }}
              register={register}
              control={control}
              errors={errors}
              md="12"
            />
            <Col size="12" className="d-flex justify-content-end">
              <Button
                className="mr-2"
                color="light"
                type="button"
                onClick={onEditModalCancel}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={isEditUserLoading}
              >
                {isEditUserLoading && <Spinner type="grow" size="sm" />}
                <span>Submit</span>
              </Button>
            </Col>
          </Row>
        </form>
      </ModalComponent>
    </div>
  );
};
