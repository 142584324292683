import React, { useContext, useEffect, useState } from "react";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { ChatItemHeader } from "../../../../pages/chat/ChatPartials";
import { chatData } from "../../../../pages/chat/ChatData";
import { Icon } from "../../../../components/Components";
import { ChatContext } from "../../../../pages/chat/ChatContext";

const ChatDropdown = () => {
  const { chatState, userID } = useContext(ChatContext);
  const [chat, setChat] = chatState;
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    let isActive = false;
    chat.channels?.slice(0, 5)?.forEach((channel) => {
      if (channel.unreadMessageCount > 0) {
        isActive = true;
      }
    });
    setIsActive(isActive);
  }, [chat.channels]);

  return (
    <UncontrolledDropdown>
      <DropdownToggle
        tag="a"
        href="#dropdown"
        onClick={(ev) => ev.preventDefault()}
        className="nk-quick-nav-icon"
      >
        <div className={`icon-status icon-status-${isActive ? "info" : "na"}`}>
          <Icon name="chat-circle"></Icon>
        </div>
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-xl">
        <div className="dropdown-head">
          <span className="sub-title nk-dropdown-title">Recent Chats</span>
          {/* <Link to={`${process.env.PUBLIC_URL}/user-profile-setting`}>
            Setting
          </Link> */}
          Setting
        </div>
        <div className="dropdown-body">
          <ul className="chat-list">
            {/* {chatData.map((item, i) => {
              return (
                item.convo.length > 0 &&
                !item.group && <ChatItemHeader key={i} item={item} />
              );
            })} */}
            {chat.channels?.slice(0, 5).map((item, i) => {
              return <ChatItemHeader key={i} item={item} userID={userID} />
            })}
          </ul>
        </div>
        <div className="dropdown-foot center">
          <Link to={`${process.env.PUBLIC_URL}/admin/chat`}>View All</Link>
        </div>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

// export default withRouter(ChatDropdown);

export default ChatDropdown;
