import { createSlice } from "@reduxjs/toolkit";
import { arrangeConvo } from "../../../utils/utils";
import moment from "moment";

const initialState = {
  chatToken: "",
  expireTime: 0,
  userID: "",
  convList: [],
  contacts: [],
  contactIds: [],
  usersList: [],
  presenceStatus: [],
  isConnected: false,

  conversations: {},
  users: {},
  currentConversation: "",
  currentUser: "",

  channels: [],
  messages: [],
};

export const chatSlice = createSlice({
  name: "chat",
  initialState: () => {
    var appId = "";

    return {
      ...initialState,
      appId,
    };
  },
  reducers: {
    updateChatState(state, action) {
      const data = action.payload;
      return {
        ...state,
        ...data,
      };
    },
    onSubmitMessage(state, action) {
      const message = action.payload;

      let currentConv = state.conversations[message.to];
      currentConv.convo = arrangeConvo({
        convo: currentConv.convo,
        message: message,
        senderID: state.userID,
      });
      currentConv.lastMessage = message;

      // Sort convList by last message
      // So that the latest message will be on top
      // state.convList.sort((a, b) => {
      //   if (a.lastMessage && b.lastMessage) {
      //     return (
      //       moment(b.lastMessage.time).unix() -
      //       moment(a.lastMessage.time).unix()
      //     );
      //   }
      //   return 0;
      // });
    },
    onCreateOneToOneChat(state, action) {
      const { senderID, recipientID, user } = action.payload;
      state.currentConversation = recipientID;
      state.conversations[recipientID] = {
        id: recipientID,
        participants: [senderID, recipientID],
        convo: [],
      };
      if (!state.users[recipientID]) {
        state.users[recipientID] = user;
      }
    },
    onCreateChatGroup(state, action) {
      const { groupid, users, groupname } = action.payload;
      state.currentConversation = groupid;
      let participants = [];
      for (let i = 0; i < users.length; i++) {
        participants.push(users[i].agora_chat_user_id);
        if (!state.users[users[i].agora_chat_user_id]) {
          state.users[users[i].agora_chat_user_id] = users[i];
        }
      }
      state.conversations[groupid] = {
        id: groupid,
        groupName: groupname,
        participants: participants,
        convo: [],
      };
    },
    recallMessage(state, action) {
      const { mid, to, channel } = action.payload;

      let conv = state.conversations[channel];
      if (conv.groupName) {
        //TODO: Recall message in group
      } else {
        for (let i = 0; i < conv.convo?.length; i++) {
          if (conv.convo[i].me) {
            for (let j = 0; j < conv.convo[i].chat?.length; j++) {
              if (conv.convo[i].chat[j].id === mid) {

                conv.convo[i].chat.splice(j, 1);
                if (conv.convo[i].chat.length === 0) {
                  conv.convo.splice(i, 1);
                  if (conv.convo[conv.convo.length - 1].meta) {
                    conv.convo.splice(-1);
                  }
                }
                return;
              }
            }
          }
        }
      }
    },
    deleteConversation(state, action) {
      const { id } = action.payload;
      delete state.conversations[id];

      if (state.currentConversation === id) {
        state.currentConversation = "";
      }
    },
    onChatItemClick(state, action) {
      const { channelURL, messages } = action.payload;

      state.currentConversation = channelURL;
      // state.conversations[id].unreadNum = 0;
      // messages?.forEach((message) => {
      //   state.conversations[id].convo = arrangeConvo({
      //     convo: state.conversations[id].convo,
      //     message: message,
      //     senderID: state.userID,
      //   });
      // });

    },
    onTextMessage(state, action) {
      let { message } = action.payload;

      switch (message.chatType) {
        case "groupchat":
        case "groupChat":
          if (!state.conversations[message.to]) {
            //TODO: Add new conversation to conversations 
          } else {
            state.conversations[message.to].lastMessage = message;
            if (state.currentConversation !== message.to) {
              state.conversations[message.to].unreadNum += 1;
            } else {
              state.conversations[message.to].convo = arrangeConvo({
                convo: state.conversations[message.to].convo,
                message: message,
                senderID: state.userID,
              });
            }
          }
          break;
        case "chat":
        case "singleChat":
          if (!state.conversations[message.from]) {
            //TODO: Add new conversation to conversations
            state.conversations[message.from] = {
              id: message.from,
              participants: [message.from, message.to],
              unreadNum: 1,
              convo: [],
              lastMessage: message,
            };
          } else {
            state.conversations[message.from].lastMessage = message;
            if (state.currentConversation !== message.from) {
              state.conversations[message.from].unreadNum += 1;
            } else {
              state.conversations[message.from].convo = arrangeConvo({
                convo: state.conversations[message.from].convo,
                message: message,
                senderID: state.userID,
              });
            }
          }
          break;
      }
    },
    onReceivedMessage(state, action) { },
    onDeliveredMessage(state, action) { },
    onPresenceStatusChange(state, action) {
      const { data } = action.payload;
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          if (state.users[data[i].userId]) {
            state.users[data[i].userId].presenceStatus = data[i];
          }
        }
      }
    },
  },
});

export const {
  updateChatState,
  onSubmitMessage,
  onChatItemClick,
  onTextMessage,
  onReceivedMessage,
  onDeliveredMessage,
  onPresenceStatusChange,
  onCreateChatGroup,
  onCreateOneToOneChat,
  deleteConversation,
  recallMessage,
} = chatSlice.actions;

export default chatSlice.reducer;

const arrangeConvList = ({ messages, userID }) => {
  let convo = [];
  let convItem = {
    chat: [],
  };

  for (let i = 0; i < messages?.length; i++) {
    let message = messages[i];
    if (convItem.chat.length > 0) {
      // Check if message same day
      let lastChatItem = convItem.chat[convItem.chat.length - 1];
      //   let diff = moment(lastChatItem.time).diff(message.time, "day");

      if (moment(lastChatItem.time).date() !== moment(message.time).date()) {
        //   if (diff !== 0) {
        // If different day, push to convo
        convo.push(
          ...[
            convItem,
            {
              meta: {
                metaID: "",
                metaText: moment(message.time).format("DD MMM, YYYY"),
              },
            },
          ]
        );
        convItem = {
          chat: [message],
        };
        if (message.to === userID) {
          convItem.me = true;
        }
      } else {
        // Check if is same user
        if (
          lastChatItem.from === message.from ||
          lastChatItem.to === message.to
        ) {
          convItem.chat.push(message);
        } else {
          convo.push(convItem);
          convItem = {
            chat: [message],
          };
          if (message.to === userID) {
            convItem.me = true;
          }
        }
      }
    } else {
      convo.push({
        meta: {
          metaID: "",
          metaText: moment(message.time).format("DD MMM, YYYY"),
        },
      });
      if (message.to === userID) {
        convItem.me = true;
      }
      convItem.chat.push(message);
    }

    if (i === messages.length - 1) {
      convo.push(convItem);
    }
  }

  return convo;
};
