import { api } from "../api";

export const supportApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEmotionalLogs: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/emotion-log",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          emotionalLogs: response.data,
          total: response.total,
        };
      },
      providesTags: ["Emotional-Logs"],
    }),
    createEmotionalLogs: builder.mutation({
      query: (data) => ({
        url: "admin/v1/emotion-log",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Emotional-Logs"],
    }),
    updateEmotionalLogs: builder.mutation({
      query: ({ params, data }) => {
        return {
          url: "admin/v1/emotion-log",
          method: "PUT",
          params: params,
          body: data,
        };
      },
      invalidatesTags: ["Emotional-Logs"],
    }),
    deleteEmotionalLogs: builder.mutation({
      query: (params) => ({
        url: "admin/v1/emotion-log",
        method: "DELETE",
        params: params,
      }),
      invalidatesTags: ["Emotional-Logs"],
    }),
    getEmotionTypes: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/emotion",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          emotionTypes: response.data,
          total: response.total,
        };
      },
      providesTags: ["Emotional-Types"],
    }),
  }),
});

export const {
  useGetEmotionTypesQuery,
  useGetEmotionalLogsQuery,
  useCreateEmotionalLogsMutation,
  useUpdateEmotionalLogsMutation,
  useDeleteEmotionalLogsMutation,
  useLazyGetEmotionTypesQuery,
} = supportApi;
