import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  offset: 0,
  itemsPerPage: 10,
  modal: {
    add: false,
    details: false,
    item: null,
  },
};

export const contactUsSlice = createSlice({
  name: "contactUsSubmissions",
  initialState: initialState,
  reducers: {
    onChangePageState(state, action) {
      const { offset, itemsPerPage } = action.payload;
      return {
        ...state,
        offset,
        itemsPerPage,
      };
    },
    onFormCancel(state, action) {
      return {
        ...state,
        modal: initialState.modal,
      };
    },
    toggleModal(state, action) {
      const { type, item } = action.payload;
      state.modal[type] = !state.modal[type];
      state.modal.item = item;
    },
  },
});

export const { onChangePageState, onFormCancel, toggleModal } =
  contactUsSlice.actions;

export default contactUsSlice.reducer;
