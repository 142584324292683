import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import Select, { components } from "react-select";
import {
  Icon,
  Button,
  Block,
  BlockDes,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  TextareaInput,
  Col,
  Row,
  SelectField,
  PreviewCard,
  TextInput,
  PhoneNumberWithCountryCodeInput,
  DatePickerInput,
  BlockContent,
  FlagComponent,
} from "../../components/Components";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Spinner, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { useCreateOrganisationMutation } from "../../redux/api/organisations/organisationApi";
import { addressTypeOptions, nioIconData } from "../../constants";
import { toast } from "react-toastify";
import SimpleBar from "simplebar-react";
import { FlagsSelectField } from "../../components/form/SelectField";
import ReactFlagsSelect, { Vn } from "react-flags-select";
import classNames from "classnames";
import { createPortal } from "react-dom";
import { useRef } from "react";
import { forwardRef } from "react";
import { countryFlagsMap } from "../../constants";

const OrganisationCreation = () => {
  const navigate = useNavigate();
  const [createOrganisation, result] = useCreateOrganisationMutation();

  useEffect(() => {
    if (result.isSuccess) {
    }
    if (result.isError) {
      if (result.error?.data?.message) {
        toast.error(result.error.data.message);
      }
    }
  }, [result.isSuccess, result.isError]);

  return (
    <>
      <Head title={`Create organisation`}></Head>
      <Content>
        <BlockHead size="sm">
          <Breadcrumb
            tag="nav"
            listTag="div"
            className="breadcrumb-arrow fs-18px"
          >
            <BreadcrumbItem tag="a" href="../">
              Home
            </BreadcrumbItem>
            <BreadcrumbItem tag="a" href="./">
              Organisations
            </BreadcrumbItem>
            <BreadcrumbItem active>Create organisation</BreadcrumbItem>
          </Breadcrumb>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Create organisation
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <Button
                color="light"
                outline
                className="bg-white d-none d-sm-inline-flex"
                onClick={() => {
                  navigate("../");
                }}
              >
                <Icon name="arrow-left"></Icon>
                <span>Back</span>
              </Button>
              <a
                href="#back"
                onClick={(ev) => {
                  ev.preventDefault();
                  navigate("../");
                }}
                className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
              >
                <Icon name="arrow-left"></Icon>
              </a>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          {!result.isSuccess && (
            <OrganisationForm
              createOrganisation={createOrganisation}
              result={result}
            />
          )}
          {result.isSuccess && <SuccessForm data={result.data} />}
        </Block>
      </Content>
    </>
  );
};

const SuccessForm = ({ data }) => {
  return (
    <PreviewCard className="card-bordered">
      <BlockHead>
        <BlockContent className={"d-flex align-items-center flex-column"}>
          <div className="w-100px mb-4">{nioIconData["doc-checked"]}</div>
          <BlockTitle tag="h4">Organisation created.</BlockTitle>
        </BlockContent>
      </BlockHead>
      <div className="fs-18px mt-2">
        <p>
          Name: <strong className="fw-bold">{data?.name}</strong>
        </p>
        <p>
          Description: <strong className="fw-bold">{data?.description}</strong>
        </p>
        <p>
          Website: <strong className="fw-bold">{data?.website}</strong>
        </p>
        <p>
          <span>Addresses:</span>
          <br />
          {data?.addresses?.map((address, index) => {
            return (
              <div key={`address-input-${index}`}>
                <strong>{`${address?.name} - ${address?.address} - ${address?.type}`}</strong>
                <br />
              </div>
            );
          })}
        </p>
      </div>
    </PreviewCard>
  );
};

// const InterimForm = ({ createCandidate, result }) => {
//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//     control,
//     reset,
//   } = useForm({
//     defaultValues: {
//       country_calling_code: "SG",
//     },
//   });
//   const { isLoading } = result;

//   const onFormSubmit = (data) => {
//     createCandidate(data);
//   };

//   return (
//     <form onSubmit={handleSubmit(onFormSubmit)}>
//       <PreviewCard className="card-bordered">
//         <Row className="g-3">
//           <Col size="12">
//             <BlockTitle tag="h5">
//               Your personal &amp; contact information
//             </BlockTitle>
//             <div className="nk-divider divider mt-3 mb-1" />
//           </Col>
//           <TextInput
//             name={"email"}
//             label={"Email address"}
//             placeholder={"name@example.com"}
//             required
//             register={{
//               ...register("email", {
//                 required: "This field is required",
//                 pattern: {
//                   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
//                   message: "Invalid email address",
//                 },
//               }),
//             }}
//             errors={errors}
//             md="12"
//           />
//           <TextInput
//             name={"first_name"}
//             label={"First name"}
//             placeholder={"your personal name"}
//             required
//             register={{
//               ...register("first_name", {
//                 required: "This field is required",
//               }),
//             }}
//             errors={errors}
//             // defaultValue={user?.first_name}
//             md="12"
//           />
//           <TextInput
//             name={"last_name"}
//             label={"Last name"}
//             placeholder={"your surname"}
//             required
//             register={{
//               ...register("last_name", {
//                 required: "This field is required",
//               }),
//             }}
//             errors={errors}
//             // defaultValue={user?.last_name}
//             md="12"
//           />
//           <PhoneNumberWithCountryCodeInput
//             label={"Mobile number"}
//             nameCode={"country_calling_code"}
//             nameInputField={"phone_number"}
//             rules={{
//               required: "This field is required",
//             }}
//             register={register}
//             control={control}
//             errors={errors}
//             md="12"
//           />
//         </Row>
//       </PreviewCard>
//       <Row className=" mt-4 mb-2 mx-0 justify-content-around">
//         <Button
//           color="primary"
//           type="submit"
//           size={"lg"}
//           className={"w-min-300px justify-content-center"}
//           disabled={isLoading}
//         >
//           {isLoading && <Spinner type="grow" size="sm" className="mr-1" />}
//           <span>CREATE</span>
//         </Button>
//       </Row>
//     </form>
//   );
// };

const OrganisationForm = ({ createOrganisation, result }) => {
  const IconOption = (props) => (
    <components.Option {...props}>
      <FlagComponent flag={props.data.value} />
      {/* <img
        src={require('./' + props.data.icon)}
        style={{ width: 36 }}
        alt={props.data.label}
      /> */}{" "}
      {props.data.label}
    </components.Option>
  );

  // const elementRef = useRef();
  // const testRef = useRef();

  // useEffect(() => {
  //   elementRef.current.addEventListener("click", handleOnClick);
  // }, [elementRef]);

  // const MyTestPortal = () => {
  //   return createPortal(<div>TTESTSET</div>, elementRef.current);
  // };

  // const handleOnClick = () => {
  //   setTimeout(() => {
  //     //   // createPortal(document.querySelector("#test"), document.body);
  //     elementRef = document.querySelector("#test ul");
  //   }, 200);
  // };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
    watch,
    trigger,
  } = useForm({
    reValidateMode: "onChange",
    defaultValues: {
      addresses: [
        {
          name: "",
          address: "",
          type: "",
        },
      ],
      // contacts: [
      //   {
      //     first_name: "",
      //     last_name: "",
      //     work_email: "",
      //     work_mobile_number: "",
      //     work_mobile_country_calling_code: "VN",
      //   },
      // ],
    },
  });
  const {
    fields: addressFields,
    append: addressAppend,
    remove: addressRemove,
  } = useFieldArray({
    control,
    name: "addresses",
    rules: {
      required: "Minimum requirement is 1 row",
      minLength: {
        value: 1,
        message: "Minimum requirement is 1 row",
      },
    },
  });
  const {
    fields: contactFields,
    append: contactAppend,
    remove: contactRemove,
  } = useFieldArray({
    control,
    name: "contacts",
    // rules: {
    //   required: "Minimum requirement is 1 row",
    //   minLength: {
    //     value: 1,
    //     message: "Minimum requirement is 1 row",
    //   },
    // },
  });
  const { isLoading } = result;

  const processData = (data) => {
    data.addresses = data.addresses?.map((e, idx) => {
      return {
        ...data.addresses[idx],
        type: e.type?.value,
        longitude: parseFloat(e.longitude),
        latitude: parseFloat(e.latitude),
      };
    });
    return data;
  };

  const onFormSubmit = (data) => {
    data = processData(data);
    createOrganisation(data);
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <PreviewCard className="card-bordered">
        <BlockHead>
          <BlockTitle tag="h5">Basic</BlockTitle>
        </BlockHead>

        <Row className="g-3">
          <TextInput
            name={"name"}
            label={"Organisation name"}
            required
            register={{
              ...register("name", {
                required: "This field is required",
              }),
            }}
            errors={errors}
            md="12"
          />
          <TextareaInput
            name={"description"}
            label={"Description"}
            required
            register={{
              ...register("description", {
                required: "This field is required",
              }),
            }}
            errors={errors}
            md="12"
          />
          <TextInput
            name={"website"}
            label={"Website"}
            placeholder={"https://www.example.com"}
            register={{
              ...register("website"),
            }}
            errors={errors}
            md="12"
          />
          <Col md="12">
            <label className="form-label">
              <p>Addresses</p>
            </label>
            <SimpleBar className="w-100" forceVisible="x" autoHide={false}>
              <div className="table-wrapper table-actions">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" className="w-min-300px">
                        Name <span className="required-asterisk">*</span>
                      </th>
                      <th scope="col" className="w-min-300px">
                        Address <span className="required-asterisk">*</span>
                      </th>
                      <th scope="col" className="w-min-200px">
                        Latitude <span className="required-asterisk">*</span>
                      </th>
                      <th scope="col" className="w-min-200px">
                        Longitude <span className="required-asterisk">*</span>
                      </th>
                      <th scope="col" className="w-min-250px">
                        Type <span className="required-asterisk">*</span>
                      </th>
                      <th scope="col" className="w-min-100px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {addressFields.map((item, index) => {
                      return (
                        <tr key={item.id}>
                          <td>
                            <TextInput
                              name={`addresses.${index}.name`}
                              required
                              register={{
                                ...register(`addresses.${index}.name`, {
                                  required: "This field is required",
                                }),
                              }}
                              errors={errors}
                              md="12"
                            />
                          </td>
                          <td>
                            <TextInput
                              name={`addresses.${index}.address`}
                              required
                              register={{
                                ...register(`addresses.${index}.address`, {
                                  required: "This field is required",
                                }),
                              }}
                              errors={errors}
                              md="12"
                            />
                          </td>
                          <td>
                            <TextInput
                              name={`addresses.${index}.latitude`}
                              required
                              register={{
                                ...register(`addresses.${index}.latitude`, {
                                  required: "This field is required",
                                }),
                              }}
                              errors={errors}
                              md="12"
                            />
                          </td>
                          <td>
                            <TextInput
                              name={`addresses.${index}.longitude`}
                              required
                              register={{
                                ...register(`addresses.${index}.longitude`, {
                                  required: "This field is required",
                                }),
                              }}
                              errors={errors}
                              md="12"
                            />
                          </td>
                          <td>
                            <SelectField
                              name={`addresses.${index}.type`}
                              control={control}
                              menuPortalTarget={document.body}
                              rules={{ required: "This field is required" }}
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  maxWidth: "300px",
                                }),
                              }}
                              options={addressTypeOptions}
                              errors={errors}
                              md="12"
                            />
                          </td>
                          <td>
                            {index === addressFields.length - 1 && (
                              <Icon
                                onClick={() => {
                                  addressAppend({
                                    name: "",
                                    address: "",
                                    type: "Primary",
                                  });
                                }}
                                name="plus-c"
                                className="fs-22px mr-2"
                              />
                            )}
                            {((addressFields.length > 1 && index === 0) ||
                              index !== 0) && (
                              <Icon
                                onClick={() => {
                                  addressRemove(index);
                                }}
                                name="trash"
                                className="fs-22px"
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </SimpleBar>
          </Col>
          <TextInput
            name={"note"}
            label={"Note"}
            register={{
              ...register("note"),
            }}
            errors={errors}
            md="12"
          />
        </Row>
      </PreviewCard>

      <PreviewCard className="card-bordered">
        <BlockHead>
          <BlockTitle tag="h5">
            Contact
            <Button
              color="primary"
              outline
              size="xs"
              className="d-none d-sm-inline-flex ml-3"
              onClick={() => {
                contactAppend({
                  first_name: "",
                  last_name: "",
                  work_email: "",
                  work_mobile_number: "",
                  work_mobile_country_calling_code: "SG",
                });
              }}
            >
              <Icon name="plus"></Icon>
              <span>Add Contact</span>
            </Button>
          </BlockTitle>
        </BlockHead>

        {contactFields.length > 0 && (
          <Row className="g-3">
            <Col md="12">
              <label className="form-label">
                <p>Organisation Contact</p>
              </label>
              <SimpleBar className="w-100" forceVisible="x" autoHide={false}>
                <div className="table-wrapper table-actions">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col" className="w-min-300px">
                          First Name{" "}
                          <span className="required-asterisk">*</span>
                        </th>
                        <th scope="col" className="w-min-300px">
                          Last Name <span className="required-asterisk">*</span>
                        </th>
                        <th scope="col" className="w-min-300px">
                          Work Email
                        </th>
                        <th scope="col" className="w-min-300px">
                          Work Mobile Number
                        </th>
                        <th scope="col" className="w-min-100px"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {contactFields.map((item, index) => {
                        return (
                          <tr key={item.id}>
                            <td>
                              <TextInput
                                name={`contacts.${index}.first_name`}
                                required
                                register={{
                                  ...register(`contacts.${index}.first_name`, {
                                    required: "This field is required",
                                  }),
                                }}
                                errors={errors}
                                md="12"
                              />
                            </td>
                            <td>
                              <TextInput
                                name={`contacts.${index}.last_name`}
                                required
                                register={{
                                  ...register(`contacts.${index}.last_name`, {
                                    required: "This field is required",
                                  }),
                                }}
                                errors={errors}
                                md="12"
                              />
                            </td>
                            <td>
                              <TextInput
                                name={`contacts.${index}.work_email`}
                                register={{
                                  ...register(
                                    `contacts.${index}.work_email`,
                                    {}
                                  ),
                                }}
                                errors={errors}
                                md="12"
                              />
                            </td>
                            <td>
                              <TextInput
                                name={`contacts.${index}.work_mobile_number`}
                                register={{
                                  ...register(
                                    `contacts.${index}.work_mobile_number`,
                                    {}
                                  ),
                                }}
                                errors={errors}
                                md="12"
                              />
                              {/* <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <Select
                                id="test-select"
                                menuPortalTarget={document.body}
                                isSearchable={true}
                                isClearable={true}
                                onChange={(e) => {
                                  console.log(e);
                                }}
                                components={{ Option: IconOption }}
                                getOptionLabel={(e) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <FlagComponent flag={e.value} />
                                    <span style={{ marginLeft: 5 }}>
                                      {e.label}
                                      test
                                    </span>
                                  </div>
                                )}
                                options={Object.entries(countryFlagsMap).map(
                                  (item) => {
                                    return {
                                      value: item[0],
                                      label: item[1].primary,
                                    };
                                  }
                                )}
                              />
                              <input />
                            </div> */}
                            </td>
                            <td>
                              {index === contactFields.length - 1 && (
                                <Icon
                                  onClick={() => {
                                    contactAppend({
                                      first_name: "",
                                      last_name: "",
                                      work_email: "",
                                      work_mobile_country_calling_code: "SG",
                                      work_mobile_number: "",
                                    });
                                  }}
                                  name="plus-c"
                                  className="fs-22px mr-2"
                                />
                              )}
                              {((contactFields.length > 1 && index === 0) ||
                                index !== 0) && (
                                <Icon
                                  onClick={() => {
                                    contactRemove(index);
                                  }}
                                  name="trash"
                                  className="fs-22px"
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </SimpleBar>
            </Col>
          </Row>
        )}
      </PreviewCard>

      <Row className=" mt-4 mb-2 mx-0 justify-content-around">
        <Button
          color="primary"
          type="submit"
          size={"lg"}
          className="w-min-300px justify-content-center"
          disabled={isLoading}
        >
          {isLoading && <Spinner type="grow" size="sm" />}
          <span className="ml-2">CREATE</span>
        </Button>
      </Row>
    </form>
  );
};

const TestWrap = forwardRef((props, ref) => {
  return (
    <div id="test-wrap" ref={ref}>
      <ReactFlagsSelect
        // selected={value}
        // onSelect={onChange}
        id="test"
        rfsKey="app-lang"
        selectButtonClassName="react-flag-select-button"
        searchable
        showSelectedLabel={false}
        // customLabels={countryFlagsMap}
        className={classNames({
          "form-control": true,
          "react-flag-select-wrapper": true,
          // "focus-form": select,
        })}
      />
    </div>
  );
});

export default OrganisationCreation;
