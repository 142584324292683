import { useDispatch, useSelector } from "react-redux";
import { useState, useLayoutEffect, useEffect } from "react";
import ReactDataTable from "../../components/table/ReactDataTable";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
  Row,
  Col,
  ModalComponent,
  TextInput,
} from "../../components/Components";
import {
  onChangePageState,
  onFormCancel,
  toggleModal,
} from "../../redux/features/web-form-submissions/contactUsSlice";
import { contactUsSubmissionsColumns } from "./DataColumns";
import {
  Breadcrumb,
  BreadcrumbItem,
  Modal,
  ModalBody,
  Spinner,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useGetContactUsSubmissionsQuery } from "../../redux/api/form-management/formManagementApi";

const ContactUsSubmissions = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.contactUsSubmissions);
  const { data, isFetching } = useGetContactUsSubmissionsQuery({
    offset: state.offset,
    limit: state.itemsPerPage,
    total: true,
    reverse: true,
  });
  const [tableData, setTableData] = useState(data?.submissions ?? []);
  const [totalItems, setTotalItems] = useState(data?.total ?? 0);

  const changePageState = ({ offset = 0, itemsPerPage }) => {
    dispatch(onChangePageState({ offset, itemsPerPage: itemsPerPage }));
  };

  useLayoutEffect(() => {
    if (data) {
      setTableData(data.submissions);
      setTotalItems(data.total);
    }
  }, [data]);

  const onRowClicked = (row, e) => {
    e.stopPropagation();
    const action = e.target.getAttribute("data-action");
    switch (action) {
      case "details":
        dispatch(toggleModal({ type: "details", item: row }));
        break;
    }
  };

  return (
    <>
      <Head title="Contact Us Submissions"></Head>
      <Content>
        <Block>
          <BlockHead>
            <Breadcrumb
              tag="nav"
              listTag="div"
              className="breadcrumb-arrow fs-18px"
            >
              <BreadcrumbItem tag="a" href="../">
                Home
              </BreadcrumbItem>
              <BreadcrumbItem active>Contact Us Submissions</BreadcrumbItem>
            </Breadcrumb>
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Contact Us Submissions</BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <PreviewCard>
            <div className="table-wrapper is-scrollable">
              <ReactDataTable
                data={tableData}
                columns={contactUsSubmissionsColumns}
                offset={state.offset}
                itemsPerPage={state.itemsPerPage}
                pagination
                totalItems={totalItems}
                progressPending={isFetching}
                className="nk-tb-list"
                selectableRows
                changePageState={changePageState}
                onRowClicked={onRowClicked}
              />
            </div>
          </PreviewCard>
        </Block>

        <ModalComponent
          isOpen={state.modal.details}
          toggle={() => dispatch(toggleModal({ type: "details" }))}
          title={"Submission Details"}
          onModalCancel={() => {
            dispatch(onFormCancel());
          }}
        >
          {state.modal.item && (
            <Row className="gy-3">
              {/* <Col lg={6}>
                <span className="sub-text">Quote Id</span>
                <span className="caption-text">{state.modal.item.id}</span>
              </Col> */}
              <Col lg={6}>
                <span className="sub-text">Name</span>
                <span className="caption-text">{state.modal.item.name}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Email</span>
                <span className="caption-text">{state.modal.item.email}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Number</span>
                <span className="caption-text">{state.modal.item.number}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">I am a</span>
                <span className="caption-text">{state.modal.item.iam}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Type of enquiry</span>
                <span className="caption-text">
                  {state.modal.item.enquiry_type}
                </span>
              </Col>
              <Col lg={12}>
                <span className="sub-text">Message</span>
                <span className="caption-text">{state.modal.item.message}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Created Time</span>
                <span className="caption-text">
                  {state.modal.item.created_time}
                </span>
              </Col>
            </Row>
          )}
        </ModalComponent>
      </Content>
    </>
  );
};

export default ContactUsSubmissions;
