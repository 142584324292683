import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import {
  Icon,
  Button,
  Block,
  BlockDes,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  TextareaInput,
  Col,
  Row,
  SelectField,
  ModalComponent,
  TextInput,
  DatePickerInput,
  PhoneNumberWithCountryCodeInput,
  CheckboxInput,
} from "../../components/Components";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  Progress,
  UncontrolledTooltip,
} from "reactstrap";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {
  useAddOrganisationBillableMutation,
  useAddOrganisationBillableRecordMutation,
  useAddOrganisationLogMutation,
  useAddOrganisationOpportunityMutation,
  useAddOrganisationTOBMutation,
  useAddSupportingDocMutation,
  useCreateContactMutation,
  useDeleteContactMutation,
  useEditOrganisationBillableMutation,
  useEditOrganisationBillableRecordMutation,
  useEditOrganisationLogMutation,
  useEditOrganisationMutation,
  useEditOrganisationTOBMutation,
  useEditSupportingDocMutation,
  useGetContactsQuery,
  useGetOrganisationBillableQuery,
  useGetOrganisationLogQuery,
  useGetOrganisationOpportunityQuery,
  useGetOrganisationQuery,
  useGetOrganisationTOBQuery,
  useGetSupportingDocsQuery,
  useRemoveOrganisationBillableMutation,
  useRemoveOrganisationBillableRecordMutation,
  useRemoveOrganisationLogMutation,
  useRemoveSupportingDocMutation,
  useUpdateContactMutation,
  useUpdatePipelineStageMutation,
} from "../../redux/api/organisations/organisationApi";
import React, {
  useEffect,
  useState,
  createContext,
  useRef,
  useContext,
  useCallback,
} from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Spinner } from "reactstrap";
import { toast } from "react-toastify";
import classNames from "classnames";
import DataTable from "react-data-table-component";
import { useInView } from "react-intersection-observer";
import {
  addressTypeOptions,
  billableDirectionOptions,
  countryFlagsMap,
  logDirectionOptions,
  nioIconData,
  opportunityStatusOptions,
  orgSupportingDocStatusOptions,
  pipelineStatusOptions,
} from "../../constants";
import moment from "moment";
import SimpleBar from "simplebar-react";
import {
  capitalize,
  convertOrganisationData,
  formatDuration,
} from "../../utils/utils";
import { useDropzone } from "react-dropzone";
import {
  useFinalizeMultipartUploadMutation,
  useInitializeMultipartUploadMutation,
} from "../../redux/api/common/uploadApi";
import { Uploader } from "../../utils/httpUtility";
import { useDispatch } from "react-redux";
import { start } from "../../redux/features/upload/uploaderSlice";
import {
  useLazyGetInteractionTypesQuery,
  useLazyGetOpportunityCommitmentsQuery,
  useLazyGetOpportunityContractTypesQuery,
  useLazyGetOpportunityTypesQuery,
  useLazyGetOrgContactRolesQuery,
  useLazyGetOrgSupportingDocTypesQuery,
} from "../../redux/api/dropdown-data/dropdownDataApi";
import { useLazyGetPipelineConfigQuery } from "../../redux/api/users/usersApi";

const DataContext = createContext();

const DataContextProvider = ({
  organisation,
  contacts,
  logs,
  supportingDocs,
  opportunities,
  tobs,
  billables,
  ...props
}) => {
  if (!organisation) {
    return (
      <Spinner
        className="mt-3 mb-3"
        style={{ display: "block", margin: "auto" }}
      />
    );
  }
  return (
    <DataContext.Provider
      value={{
        organisation: organisation,
        logs: logs,
        contacts: contacts,
        supportingDocs: supportingDocs,
        opportunities: opportunities,
        tobs: tobs,
        billables: billables,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};

const OrganisationDetails = () => {
  let params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { data: organisation, isFetching } = useGetOrganisationQuery({
    id: params.id,
  });
  const { data: contacts, isFetching: isGetContactsFetching } =
    useGetContactsQuery({ id: params.id });
  const { data: logs, isFetching: isGetLogsFetching } =
    useGetOrganisationLogQuery({ id: params.id });
  const { data: supportingDocs, isFetching: isGetOrgSupportingDocFetching } =
    useGetSupportingDocsQuery({ id: params.id });
  const { data: opportunities, isFetching: isGetOpportunityFetching } =
    useGetOrganisationOpportunityQuery({ id: params.id });
  const { data: tobs, isFetching: isGetTOBFetching } =
    useGetOrganisationTOBQuery({ id: params.id });
  const { data: billables, isFetching: isGetBillableFetching } =
    useGetOrganisationBillableQuery({ id: params.id });
  const [activeSection, setTab] = useState("basic");
  const [displayTabHeader, setDisplayTabHeader] = useState(false);
  const sectionRef = useRef([]);
  const {
    ref: navRef,
    inView,
    entry,
  } = useInView({
    rootMargin: "-65px 0px 0px 0px",
    onChange: (inView, entry) => {
      if (inView) {
        setDisplayTabHeader(false);
      } else {
        setDisplayTabHeader(true);
      }
    },
  });
  const [isUploadModalOpen, setUploadModal] = useState(false);
  const [uploadCategory, setUploadCategory] = useState(null);

  return (
    <>
      <Head title="Organisation"></Head>
      <Content>
        <BlockHead size="sm">
          <Breadcrumb
            tag="nav"
            listTag="div"
            className="breadcrumb-arrow fs-18px"
          >
            <BreadcrumbItem tag="a" href="../">
              Home
            </BreadcrumbItem>
            <BreadcrumbItem tag="a" href="./">
              Organisations
            </BreadcrumbItem>
            <BreadcrumbItem active>{organisation?.name}</BreadcrumbItem>
          </Breadcrumb>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Organisations /{" "}
                <strong className="text-primary small">
                  {organisation?.name}
                </strong>
              </BlockTitle>
              <BlockDes className="text-soft">
                Status:{" "}
                <span className="candidate-status text-base">CUSTOMER</span>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <Button
                color="light"
                outline
                className="bg-white d-none d-sm-inline-flex"
                onClick={() => {
                  navigate("../");
                }}
              >
                <Icon name="arrow-left"></Icon>
                <span>Back</span>
              </Button>
              <a
                href="#back"
                onClick={(ev) => {
                  ev.preventDefault();
                  navigate("../");
                }}
                className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
              >
                <Icon name="arrow-left"></Icon>
              </a>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <DataContextProvider
          organisation={organisation}
          logs={logs}
          contacts={contacts}
          supportingDocs={supportingDocs}
          opportunities={opportunities}
          tobs={tobs}
          billables={billables}
        >
          <Block>
            <Card className="card-bordered">
              <ul
                ref={navRef}
                className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card"
              >
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#basic"
                    onClick={(ev) => {
                      ev.preventDefault();
                      window.scrollTo({
                        top:
                          window.pageYOffset +
                          sectionRef.current[0].getBoundingClientRect().top -
                          window.outerHeight / 3,
                      });
                    }}
                  >
                    <Icon name="user-circle"></Icon>
                    <span>Basic</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#org-contacts"
                    onClick={(ev) => {
                      ev.preventDefault();
                      //setTab("caregiver");
                      window.scrollTo({
                        top:
                          window.pageYOffset +
                          sectionRef.current[1].getBoundingClientRect().top -
                          window.outerHeight / 3,
                      });
                    }}
                  >
                    <Icon name="file-text"></Icon>
                    <span>Org Contacts</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#tob"
                    onClick={(ev) => {
                      ev.preventDefault();
                      //setTab("family");
                      window.scrollTo({
                        top:
                          window.pageYOffset +
                          sectionRef.current[2].getBoundingClientRect().top -
                          window.outerHeight / 3,
                      });
                    }}
                  >
                    <Icon name="file-text"></Icon>
                    <span>TOB</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#opportunities"
                    onClick={(ev) => {
                      ev.preventDefault();
                      //setTab("disability");
                      window.scrollTo({
                        top:
                          window.pageYOffset +
                          sectionRef.current[3].getBoundingClientRect().top -
                          window.outerHeight / 3,
                      });
                    }}
                  >
                    <Icon name="repeat"></Icon>
                    <span>Opportunities</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#billables"
                    onClick={(ev) => {
                      ev.preventDefault();
                      //setTab("medical");
                      window.scrollTo({
                        top:
                          window.pageYOffset +
                          sectionRef.current[4].getBoundingClientRect().top -
                          window.outerHeight / 3,
                      });
                    }}
                  >
                    <Icon name="bell"></Icon>
                    <span>Billables</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#supporting-docs"
                    onClick={(ev) => {
                      ev.preventDefault();
                      //setTab("education");
                      window.scrollTo({
                        top:
                          window.pageYOffset +
                          sectionRef.current[5].getBoundingClientRect().top -
                          window.outerHeight / 3,
                      });
                    }}
                  >
                    <Icon name="bell"></Icon>
                    <span>Supporting Documents</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#log"
                    onClick={(ev) => {
                      ev.preventDefault();
                      //setTab("assessment");
                      window.scrollTo({
                        top:
                          window.pageYOffset +
                          sectionRef.current[6].getBoundingClientRect().top -
                          window.outerHeight / 3,
                      });
                    }}
                  >
                    <Icon name="activity"></Icon>
                    <span>Log</span>
                  </a>
                </li>
              </ul>

              <div className="card-content">
                <div className="card-inner active">
                  <BasicSection
                    sectionRef={sectionRef}
                    setTab={setTab}
                    setUploadModal={setUploadModal}
                    setUploadCategory={setUploadCategory}
                  />
                </div>
              </div>
            </Card>

            <Sections
              sectionRef={sectionRef}
              setTab={setTab}
              setUploadCategory={setUploadCategory}
              setUploadModal={setUploadModal}
            />
            <CommonModals
              isUploadModalOpen={isUploadModalOpen}
              setUploadModal={setUploadModal}
              uploadCategory={uploadCategory}
            />
          </Block>
        </DataContextProvider>
      </Content>
      <div
        className={classNames({
          "d-none": !displayTabHeader,
          "container-fluid": true,
          "tab-header-fixed": true,
        })}
      >
        <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
          <li className="nav-item">
            <a
              className="nav-link"
              href="#basic"
              onClick={(ev) => {
                ev.preventDefault();
                //setTab("personal");
                window.scrollTo({
                  top:
                    window.pageYOffset +
                    sectionRef.current[0].getBoundingClientRect().top -
                    window.outerHeight / 2,
                });
              }}
            >
              <Icon name="user-circle"></Icon>
              <span>Basic</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#org-contacts"
              onClick={(ev) => {
                ev.preventDefault();
                //setTab("caregiver");
                window.scrollTo({
                  top:
                    window.pageYOffset +
                    sectionRef.current[1].getBoundingClientRect().top -
                    window.outerHeight / 3,
                });
              }}
            >
              <Icon name="file-text"></Icon>
              <span>Org Contacts</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              // className={`nav-link ${activeSection === "family" ? "active" : ""
              //   }`}
              href="#tob"
              onClick={(ev) => {
                ev.preventDefault();
                //setTab("family");
                window.scrollTo({
                  top:
                    window.pageYOffset +
                    sectionRef.current[2].getBoundingClientRect().top -
                    window.outerHeight / 3,
                });
              }}
            >
              <Icon name="file-text"></Icon>
              <span>TOB</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#oppotunities"
              onClick={(ev) => {
                ev.preventDefault();
                //setTab("disability");
                // sectionRef.current[3].scrollIntoView({block: "center", inline: "nearest"});
                window.scrollTo({
                  top:
                    window.pageYOffset +
                    sectionRef.current[3].getBoundingClientRect().top -
                    window.outerHeight / 3,
                });
              }}
            >
              <Icon name="repeat"></Icon>
              <span>Oppotunities</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#billables"
              onClick={(ev) => {
                ev.preventDefault();
                //setTab("medical");
                window.scrollTo({
                  top:
                    window.pageYOffset +
                    sectionRef.current[4].getBoundingClientRect().top -
                    window.outerHeight / 3,
                });
              }}
            >
              <Icon name="bell"></Icon>
              <span>Billables</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#supporting-docs"
              onClick={(ev) => {
                ev.preventDefault();
                //setTab("education");
                window.scrollTo({
                  top:
                    window.pageYOffset +
                    sectionRef.current[5].getBoundingClientRect().top -
                    window.outerHeight / 3,
                });
              }}
            >
              <Icon name="bell"></Icon>
              <span>Supporting Documents</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#log"
              onClick={(ev) => {
                ev.preventDefault();
                //setTab("assessment");
                window.scrollTo({
                  top:
                    window.pageYOffset +
                    sectionRef.current[6].getBoundingClientRect().top -
                    window.outerHeight / 3,
                });
              }}
            >
              <Icon name="activity"></Icon>
              <span>Log</span>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default OrganisationDetails;

const Sections = ({
  sectionRef,
  setTab,
  setUploadCategory,
  setUploadModal,
}) => {
  return (
    <React.Fragment>
      <Card className="card-bordered">
        <div className="card-content">
          <div className="card-inner active">
            <OrgContactsSection sectionRef={sectionRef} setTab={setTab} />
          </div>
        </div>
      </Card>

      <Card className="card-bordered">
        <div className="card-content">
          <div className="card-inner active">
            <TOBSection
              sectionRef={sectionRef}
              setTab={setTab}
              setUploadCategory={setUploadCategory}
              setUploadModal={setUploadModal}
            />
          </div>
        </div>
      </Card>

      <Card className="card-bordered">
        <div className="card-content">
          <div className="card-inner active">
            <OpportunitiesSection
              sectionRef={sectionRef}
              setTab={setTab}
              setUploadCategory={setUploadCategory}
              setUploadModal={setUploadModal}
            />
          </div>
        </div>
      </Card>

      <Card className="card-bordered">
        <div className="card-content">
          <div className="card-inner active">
            <BillablesSection
              sectionRef={sectionRef}
              setTab={setTab}
              setUploadCategory={setUploadCategory}
              setUploadModal={setUploadModal}
            />
          </div>
        </div>
      </Card>

      <Card className="card-bordered">
        <div className="card-content">
          <div className="card-inner active">
            <SupportingDocsSection
              sectionRef={sectionRef}
              setTab={setTab}
              setUploadCategory={setUploadCategory}
              setUploadModal={setUploadModal}
            />
          </div>
        </div>
      </Card>

      <Card className="card-bordered">
        <div className="card-content">
          <div className="card-inner active">
            <LogSection sectionRef={sectionRef} setTab={setTab} />
          </div>
        </div>
      </Card>
    </React.Fragment>
  );
};

const CommonModals = ({
  isUploadModalOpen,
  setUploadModal,
  uploadCategory,
}) => {
  return (
    <React.Fragment>
      <UploadModal
        isUploadModalOpen={isUploadModalOpen}
        setUploadModal={setUploadModal}
        uploadCategory={uploadCategory}
      />
    </React.Fragment>
  );
};

const UploadModal = ({ isUploadModalOpen, setUploadModal, uploadCategory }) => {
  const { organisation } = useContext(DataContext);
  const [files, setFiles] = useState([]);
  const [percentage, setPercentage] = useState(null);
  const [uploader, setUploader] = useState(0);
  const [isUploading, setUploading] = useState(false);
  const [
    initializeMultipartUpload,
    {
      data: initializeMultipartUploadData,
      isLoading: isInitializeMultipartUploadLoading,
      isError: isInitializeMultipartUploadError,
      isSuccess: isInitializeMultipartUploadSuccess,
      error: initializeMultipartUploadError,
    },
  ] = useInitializeMultipartUploadMutation();
  const [
    finalizationMultipartUpload,
    {
      isLoading: isFinalizeMultipartUploadLoading,
      isSuccess: isFinalizeMultipartUploadSuccess,
      isError: isFinalizeMultipartUploadError,
      error: finalizeMultipartUploadError,
    },
  ] = useFinalizeMultipartUploadMutation();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          console.log(err);

          if (err.code === "file-too-large") {
            toast.error("File too large");
          }

          if (err.code === "file-invalid-type") {
          }
        });
      });
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm();

  const onUploadSubmit = () => {
    if (files) {
      const uploader = new Uploader({
        organisationId: organisation?.id,
        fileName: files[0].name,
        file: files[0],
        fileType: files[0].type,
        uploadCategory: uploadCategory,
        initializeMultipartUpload: initializeMultipartUpload,
        finalizationMultipartUpload: finalizationMultipartUpload,
      });
      setUploader(uploader);

      uploader
        .onProgress(({ percentage: newPercentage }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            setPercentage(newPercentage);
          }
        })
        .onError((error) => {
          setUploading(false);
          setFiles([]);
          setPercentage(0);
          toast.error(error);
        });

      uploader.start();
      setUploading(true);
    }
  };

  useEffect(() => {
    if (
      isFinalizeMultipartUploadSuccess ||
      isFinalizeMultipartUploadError ||
      isInitializeMultipartUploadError
    ) {
      setUploading(false);
      setFiles([]);
      setPercentage(0);

      if (isFinalizeMultipartUploadError) {
        toast.error(finalizeMultipartUploadError?.data?.message);
      }
      if (isInitializeMultipartUploadError) {
        toast.error(initializeMultipartUploadError?.data?.message);
      }

      if (isFinalizeMultipartUploadSuccess) {
        setUploadModal(false);
        toast.success("File uploaded successfully");
      }
    }
  }, [
    isFinalizeMultipartUploadSuccess,
    isFinalizeMultipartUploadError,
    isInitializeMultipartUploadError,
  ]);

  return (
    <React.Fragment>
      <ModalComponent
        isOpen={isUploadModalOpen}
        title={"Upload File"}
        onModalCancel={() => {
          setUploadModal(false);
        }}
      >
        <form onSubmit={handleSubmit(onUploadSubmit)}>
          <Row className="gy-3">
            <Col size="12">
              <section>
                <div
                  {...getRootProps()}
                  className="dropzone upload-zone dz-clickable h-min-100px"
                >
                  <input {...getInputProps()} />
                  {files.length === 0 && (
                    <div className="dz-message">
                      <span className="dz-message-text">
                        Drag and drop file
                      </span>
                      <span className="dz-message-or">or</span>
                      <Button color="primary">SELECT</Button>
                    </div>
                  )}
                  {files.map((file) => (
                    <React.Fragment key={`file-name-${file.name}`}>
                      <div>{file.name}</div>
                      <Progress
                        className="progress-lg mt-2"
                        animated
                        value={percentage}
                      >
                        {`${percentage}%`}
                      </Progress>
                    </React.Fragment>
                  ))}
                  {/* {files.map((file) => (
                    <div
                      key={file.name}
                      className="dz-preview dz-processing dz-image-preview dz-error dz-complete"
                    >
                      <div className="dz-image">
                        <img src={file.preview} alt="preview" />
                      </div>
                    </div>
                  ))} */}
                </div>
              </section>

              <div className="d-flex justify-content-end mt-3">
                <Button
                  className="mr-2"
                  color="light"
                  type="button"
                  onClick={() => {
                    setUploadModal(false);
                  }}
                >
                  Cancel
                </Button>
                <Button color="primary" type="submit" disabled={isUploading}>
                  {isUploading && <Spinner type="grow" size="sm" />}
                  <span>Submit</span>
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </ModalComponent>
    </React.Fragment>
  );
};

const BasicSection = ({
  sectionRef,
  setTab,
  setUploadModal,
  setUploadCategory,
}) => {
  const { organisation } = useContext(DataContext);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    control,
    reset,
  } = useForm();
  const {
    fields: addressFields,
    append: addressAppend,
    remove: addressRemove,
  } = useFieldArray({
    control,
    name: "addresses",
    rules: {
      required: "Minimum requirement is 1 row",
      minLength: {
        value: 1,
        message: "Minimum requirement is 1 row",
      },
    },
  });
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [
    editOrganisation,
    {
      isLoading: isEditOrganisationLoading,
      isSuccess: isEditOrganisationSuccess,
      isError: isEditOrganisationError,
      error: editOrganisationError,
    },
  ] = useEditOrganisationMutation();

  const defaultValues = {};
  const onEditBtnClicked = () => {
    reset(
      {
        ...defaultValues,
        name: organisation?.name,
        description: organisation?.description,
        website: organisation?.website,
        addresses:
          organisation.addresses?.map((e) => ({
            ...e,
            type: e.type ? { value: e.type, label: e.type } : null,
          })) ?? defaultValues.addresses,
      },
      {
        keepDefaultValues: true,
      }
    );
    setEditModalOpen(true);
  };

  const onEditModalCancel = () => {
    setEditModalOpen(false);
  };

  const onFormSubmit = (data) => {
    data = convertOrganisationData(data, defaultValues);
    data.id = organisation.id;

    console.log(data);
    editOrganisation(data);
  };

  useEffect(() => {
    if (isEditOrganisationSuccess) {
      toast.success("Edit successfully!");
      setEditModalOpen(false);
    }
    if (isEditOrganisationError && editOrganisationError) {
      toast.error(editOrganisationError?.data?.message);
    }
  }, [isEditOrganisationSuccess, isEditOrganisationError]);

  return (
    <div ref={(ref) => (sectionRef.current[0] = ref)}>
      <Block>
        <BlockHead>
          {/* <BlockBetween className="mb-1"> */}
          <BlockTitle tag="h5">
            Basic{" "}
            <Button
              color="primary"
              outline
              size="xs"
              className="d-none d-sm-inline-flex ml-3"
              onClick={onEditBtnClicked}
            >
              <Icon name="edit-alt-fill"></Icon>
              <span>Edit</span>
            </Button>
          </BlockTitle>
        </BlockHead>
        <div className="profile-ud-list">
          <div className="field-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Organisation name</span>
              <span className="field-value">{organisation?.name}</span>
            </div>
          </div>
          <div className="field-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Description</span>
              <span className="field-value white-space-pre-line">
                {organisation?.description}
              </span>
            </div>
          </div>
          <div className="field-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Website</span>
              <span className="field-value">{organisation?.website}</span>
            </div>
          </div>
          <div className="field-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Status</span>
              <span className="field-value">{organisation?.status}</span>
            </div>
          </div>
          <div className="field-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Note</span>
              <span className="field-value">{organisation?.note}</span>
            </div>
          </div>
        </div>
      </Block>

      <Block>
        <BlockHead className="nk-block-head-line">
          <BlockTitle tag="h6" className="overline-title text-base">
            Basic \ Addresses
          </BlockTitle>
        </BlockHead>
        <SimpleBar className="w-100" forceVisible="x" autoHide={false}>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col" className="w-min-80px">
                  S/N
                </th>
                <th scope="col" className="w-min-250px">
                  Name
                </th>
                <th scope="col" className="w-min-300px">
                  Address
                </th>
                <th scope="col" className="w-min-150px">
                  Latitude
                </th>
                <th scope="col" className="w-min-150px">
                  Longitude
                </th>
                <th scope="col" className="w-min-150px">
                  Type
                </th>
                <th scope="col" className="w-min-150px">
                  Created
                </th>
                <th scope="col" className="w-min-150px">
                  Updated
                </th>
              </tr>
            </thead>
            <tbody>
              {organisation?.addresses?.map((e, idx) => {
                return (
                  <tr key={`organisation-address-${e.id}-${idx}`}>
                    <td>{idx + 1}</td>
                    <td>{e?.name}</td>
                    <td>{e?.address}</td>
                    <td>{e?.latitude}</td>
                    <td>{e?.longitude}</td>
                    <td>{e?.type}</td>
                    <td>
                      {moment(e?.created_time).format("DD MMM YYYY, hh:mm a")}
                    </td>
                    <td>
                      {moment(e?.last_updated_time).format(
                        "DD MMM YYYY, hh:mm a"
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </SimpleBar>
      </Block>

      <ModalComponent
        isOpen={isEditModalOpen}
        backdrop={"static"}
        title={"Edit Basic Information"}
        onModalCancel={() => {
          setEditModalOpen(false);
        }}
      >
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Row className="gy-3">
            <TextInput
              name={"name"}
              label={"Organisation name"}
              required
              register={{
                ...register("name", {
                  required: "This field is required",
                }),
              }}
              errors={errors}
              md="12"
            />
            <TextareaInput
              name={"description"}
              label={"Description"}
              required
              register={{
                ...register("description", {
                  required: "This field is required",
                }),
              }}
              errors={errors}
              md="12"
            />
            <TextInput
              name={"website"}
              label={"Website"}
              placeholder={"https://www.example.com"}
              required
              register={{
                ...register("website", {
                  required: "This field is required",
                }),
              }}
              errors={errors}
              md="12"
            />
            <Col md="12">
              <label className="form-label">
                <p>Addresses</p>
              </label>
              <SimpleBar className="w-100" forceVisible="x" autoHide={false}>
                <div className="table-wrapper table-actions">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col" className="w-min-300px">
                          Name <span className="required-asterisk">*</span>
                        </th>
                        <th scope="col" className="w-min-300px">
                          Address <span className="required-asterisk">*</span>
                        </th>
                        <th scope="col" className="w-min-250px">
                          Type <span className="required-asterisk">*</span>
                        </th>
                        <th scope="col" className="w-min-100px"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {addressFields.map((item, index) => {
                        return (
                          <tr key={item.id}>
                            <td>
                              <TextInput
                                name={`addresses.${index}.name`}
                                required
                                register={{
                                  ...register(`addresses.${index}.name`, {
                                    required: "This field is required",
                                  }),
                                }}
                                errors={errors}
                                md="12"
                              />
                            </td>
                            <td>
                              <TextInput
                                name={`addresses.${index}.address`}
                                required
                                register={{
                                  ...register(`addresses.${index}.address`, {
                                    required: "This field is required",
                                  }),
                                }}
                                errors={errors}
                                md="12"
                              />
                            </td>
                            <td>
                              <SelectField
                                name={`addresses.${index}.type`}
                                control={control}
                                menuPortalTarget={document.body}
                                rules={{ required: "This field is required" }}
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    maxWidth: "300px",
                                  }),
                                }}
                                options={addressTypeOptions}
                                errors={errors}
                                md="12"
                              />
                            </td>
                            <td>
                              {index === addressFields.length - 1 && (
                                <Icon
                                  onClick={() => {
                                    addressAppend({
                                      name: "",
                                      address: "",
                                      type: "Primary",
                                    });
                                  }}
                                  name="plus-c"
                                  className="fs-22px mr-2"
                                />
                              )}
                              {((addressFields.length > 1 && index === 0) ||
                                index !== 0) && (
                                <Icon
                                  onClick={() => {
                                    addressRemove(index);
                                  }}
                                  name="trash"
                                  className="fs-22px"
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </SimpleBar>
            </Col>
            <Col size="12" className="d-flex justify-content-end">
              <Button
                className="mr-2"
                color="light"
                type="button"
                onClick={onEditModalCancel}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={isEditOrganisationLoading}
              >
                {isEditOrganisationLoading && <Spinner type="grow" size="sm" />}
                <span>Submit</span>
              </Button>
            </Col>
          </Row>
        </form>
      </ModalComponent>
    </div>
  );
};

const OrgContactsSection = ({ sectionRef, setTab }) => {
  const { organisation, contacts } = useContext(DataContext);
  const [isAddContactModalOpen, setAddContactModalOpen] = useState(false);
  const [isEditContactModalOpen, setEditContactModal] = useState(false);
  const [isRemoveContactModalOpen, setRemoveContactModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const defaultValues = {
    work_mobile_country_calling_code: "SG",
    personal_country_calling_code: "SG",
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    control,
    reset,
  } = useForm({ defaultValues: defaultValues });
  const {
    register: registerEditLogForm,
    handleSubmit: handleEditLogFormSubmit,
    formState: { errors: errorsEditLogForm },
    control: controlEditLogForm,
    reset: resetEditLogForm,
  } = useForm();

  const [
    createContact,
    {
      isLoading: isCreateContactLoading,
      isSuccess: isCreateContactSuccess,
      isError: isCreateContactError,
      error: createContactError,
    },
  ] = useCreateContactMutation();
  const [
    updateContact,
    {
      isLoading: isUpdateContactLoading,
      isSuccess: isUpdateContactSuccess,
      isError: isUpdateContactError,
      error: updateContactError,
    },
  ] = useUpdateContactMutation();
  const [
    removeContact,
    {
      isLoading: isRemoveContactLoading,
      isSuccess: isRemoveContactSuccess,
      isError: isRemoveContactError,
      error: removeContactError,
    },
  ] = useDeleteContactMutation();
  const [
    getOrgContactRoles,
    {
      data: orgContactRolesData,
      isFetching: isGetOrgContactRolesFetching,
      isSuccess: isGetOrgContactRolesSuccess,
      isError: isGetOrgContactRolesError,
      error: getOrgContactRolesError,
    },
  ] = useLazyGetOrgContactRolesQuery({
    limit: 1000,
  });
  const [orgContactRolesOptions, setOrgContactRolesOptions] = useState([]);

  const onAddBtnClicked = () => {
    getOrgContactRoles(undefined, true);
    setAddContactModalOpen(true);
  };

  const onEditBtnClicked = (contact) => {
    getOrgContactRoles(undefined, true);
    setEditContactModal(true);

    resetEditLogForm({
      ...contact,
      org_role_id: {
        value: contact.org_role_id,
        label: contact.org_role_name,
      },
    });
  };

  const onRemoveBtnClicked = (contact) => {
    setSelectedItem(contact);
    setRemoveContactModal(true);
  };

  const processData = (data) => {
    data.org_role_id = data.org_role_id?.value;
    data.organisation_id = organisation.id;
    return data;
  };

  const onFormSubmit = (data) => {
    data = processData(data);
    createContact(data);
  };

  const onEditFormSubmit = (data) => {
    data = processData(data);
    updateContact(data);
  };

  const onAddOrgContactModalCancel = () => {
    setAddContactModalOpen(false);
  };

  const onEditOrgContactModalCancel = () => {
    setEditContactModal(false);
  };

  useEffect(() => {
    if (isGetOrgContactRolesSuccess && orgContactRolesData?.roles) {
      let data = orgContactRolesData.roles.map((role) => {
        return { value: role.id, label: role.name };
      });
      setOrgContactRolesOptions(data);
    }
    if (isGetOrgContactRolesError && getOrgContactRolesError) {
      toast.error(getOrgContactRolesError?.data?.message);
    }
  }, [isGetOrgContactRolesSuccess, isGetOrgContactRolesError]);
  //   const [
  //     editUser,
  //     {
  //       isLoading: isEditUserLoading,
  //       isSuccess: isEditUserSuccess,
  //       isError: isEditUserError,
  //       error: editUserError,
  //     },
  //   ] = useEditOrganisationMutation();
  //   const defaultValues = {
  //     family_details: [
  //       {
  //         first_name: "",
  //         last_name: "",
  //         relationship: "",
  //         occupation: "",
  //         caregiver: "",
  //       },
  //     ],
  //   };

  useEffect(() => {
    if (isCreateContactSuccess) {
      toast.success("Create contact successfully!");
      setAddContactModalOpen(false);
    }
    if (isCreateContactError && createContactError) {
      toast.error(createContactError?.data?.message);
    }
  }, [isCreateContactSuccess, isCreateContactError]);

  useEffect(() => {
    if (isUpdateContactSuccess) {
      toast.success("Edit contact successfully!");
      setEditContactModal(false);
    }
    if (isUpdateContactError && updateContactError) {
      toast.error(updateContactError?.data?.message);
    }
  }, [isUpdateContactSuccess, isUpdateContactError]);

  useEffect(() => {
    if (isRemoveContactSuccess) {
      toast.success("Remove contact successfully!");
      setRemoveContactModal(false);
    }
    if (isRemoveContactError && removeContactError) {
      toast.error(removeContactError?.data?.message);
    }
  }, [isRemoveContactSuccess, isRemoveContactError]);

  return (
    <div ref={(ref) => (sectionRef.current[1] = ref)}>
      <Block>
        <BlockHead>
          <BlockTitle tag="h5">
            Org Contacts{" "}
            <Button
              color="primary"
              outline
              size="xs"
              className="d-none d-sm-inline-flex ml-3"
              onClick={onAddBtnClicked}
            >
              <Icon name="plus"></Icon>
              <span>Add Contact</span>
            </Button>
          </BlockTitle>
        </BlockHead>
        {contacts?.length > 0 ? (
          <SimpleBar className="w-100" forceVisible="x" autoHide={false}>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col" className="w-min-80px">
                    S/N
                  </th>
                  <th scope="col" className="w-min-150px">
                    First name
                  </th>
                  <th scope="col" className="w-min-150px">
                    Last name
                  </th>
                  <th scope="col" className="w-min-250px">
                    Role
                  </th>
                  <th scope="col" className="w-min-250px">
                    Emails
                  </th>
                  <th scope="col" className="w-min-250px">
                    Contact numbers
                  </th>
                  <th scope="col" className="w-min-250px">
                    Notes
                  </th>
                  <th scope="col" className="w-min-250px">
                    Documents
                  </th>
                  <th scope="col" className="w-min-150px">
                    Created
                  </th>
                  <th scope="col" className="w-min-150px">
                    Updated
                  </th>
                  <th scope="col" className="w-min-150px"></th>
                </tr>
              </thead>
              <tbody>
                {contacts?.map((contact, idx) => {
                  return (
                    <tr key={`org-contacts-${contact.id}`}>
                      <td>{idx + 1}</td>
                      <td>{contact?.first_name}</td>
                      <td>{contact?.last_name}</td>
                      <td>{contact?.org_role_name}</td>
                      <td>
                        {contact?.work_email && (
                          <p>
                            <span>Work:</span>
                            <br />
                            <span>{contact?.work_email}</span>
                          </p>
                        )}
                        {contact?.personal_email && (
                          <p>
                            <span>Personal:</span>
                            <br />
                            <span>{contact?.personal_email}</span>
                          </p>
                        )}
                      </td>
                      <td>
                        {contact?.work_mobile_number && (
                          <p>
                            <span>Work mobile:</span>
                            <br />
                            <span>
                              {countryFlagsMap[
                                contact?.work_mobile_country_calling_code
                              ]?.secondary + contact?.work_mobile_number}
                            </span>
                          </p>
                        )}
                        {contact?.work_office_number && (
                          <p>
                            <span>Work office:</span>
                            <br />
                            <span>{contact?.work_office_number}</span>
                          </p>
                        )}
                        {contact?.personal_number && (
                          <p>
                            <span>Personal:</span>
                            <br />
                            <span>
                              {countryFlagsMap[
                                contact?.personal_country_calling_code
                              ]?.secondary + contact?.personal_number}
                            </span>
                          </p>
                        )}
                      </td>
                      <td className="white-space-pre-line">{contact?.notes}</td>
                      <td>
                        {contact?.document_urls?.map((doc_url, index) => {
                          return (
                            <Row
                              key={`org-contacts-doc-url-${contact.id}-${index}`}
                              className="align-items-center my-2"
                            >
                              <Col className="mx-2">
                                {nioIconData["file-pdf"]}
                              </Col>
                              <Col>
                                <a
                                  href={`${process.env.PUBLIC_URL}/files/?key=${doc_url?.url}`}
                                  target="_blank"
                                  rel="noreferrer noopener"
                                >
                                  <span>{doc_url?.name}</span>
                                </a>
                              </Col>
                            </Row>
                          );
                        })}
                      </td>
                      <td>
                        {moment(contact?.created_time).format(
                          "DD MMM YYYY, hh:mm a"
                        )}
                      </td>
                      <td>
                        {moment(contact?.last_updated_time).format(
                          "DD MMM YYYY, hh:mm a"
                        )}
                      </td>
                      <td>
                        <React.Fragment>
                          <Button
                            className="btn-icon"
                            id={`upload-btn-doc-contact-${contact.id}`}
                            color="light"
                            size="xs"
                            onClick={() => {
                              //   setUploadCategory("");
                              //   setUploadModal(true);
                            }}
                          >
                            <Icon name="upload" />
                          </Button>
                          <UncontrolledTooltip
                            placement="top"
                            flip={false}
                            target={`upload-btn-doc-contact-${contact.id}`}
                          >
                            Upload Document
                          </UncontrolledTooltip>

                          <Button
                            className="btn-icon ml-2"
                            id={`edit-btn-doc-contact-${contact.id}`}
                            color="light"
                            size="xs"
                            onClick={() => {
                              onEditBtnClicked(contact);
                            }}
                          >
                            <Icon name="edit-alt" />
                          </Button>
                          <UncontrolledTooltip
                            flip={false}
                            placement="top"
                            target={`edit-btn-doc-contact-${contact.id}`}
                          >
                            Edit
                          </UncontrolledTooltip>

                          <Button
                            className="btn-icon ml-2"
                            id={`remove-btn-doc-contact-${contact.id}`}
                            color="light"
                            size="xs"
                            onClick={() => {
                              onRemoveBtnClicked(contact);
                            }}
                          >
                            <Icon name="trash" />
                          </Button>
                          <UncontrolledTooltip
                            flip={false}
                            placement="top"
                            target={`remove-btn-doc-contact-${contact.id}`}
                          >
                            Remove
                          </UncontrolledTooltip>
                        </React.Fragment>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </SimpleBar>
        ) : (
          <div className="d-flex justify-content-center no-data-box">
            <div className="p-2">No data</div>
          </div>
        )}
      </Block>

      <ModalComponent
        isOpen={isAddContactModalOpen}
        backdrop={"static"}
        title={"Add Contact"}
        onModalCancel={() => {
          setAddContactModalOpen(false);
        }}
      >
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Row className="gy-3">
            <TextInput
              name={"first_name"}
              label={"First name"}
              required
              register={{
                ...register("first_name", {
                  required: "This field is required",
                }),
              }}
              errors={errors}
              md="12"
            />
            <TextInput
              name={"last_name"}
              label={"Last name"}
              required
              register={{
                ...register("last_name", {
                  required: "This field is required",
                }),
              }}
              errors={errors}
              md="12"
            />
            <SelectField
              name={"org_role_id"}
              label={"Role"}
              control={control}
              rules={{ required: "This field is required" }}
              options={orgContactRolesOptions}
              isSearchable={true}
              isLoading={isGetOrgContactRolesFetching}
              isDisabled={isGetOrgContactRolesFetching}
              errors={errors}
              md="12"
            />
            <TextInput
              name={"work_email"}
              label={"Work email"}
              register={{
                ...register("work_email", {}),
              }}
              errors={errors}
              md="12"
            />
            <TextInput
              name={"personal_email"}
              label={"Personal email"}
              register={{
                ...register("personal_email", {}),
              }}
              errors={errors}
              md="12"
            />
            <PhoneNumberWithCountryCodeInput
              label={"Work Mobile number"}
              nameCode={"work_mobile_country_calling_code"}
              nameInputField={"work_mobile_number"}
              register={register}
              control={control}
              errors={errors}
              md="12"
            />
            <TextInput
              name={"work_office_number"}
              label={"Work office number"}
              register={{
                ...register("work_office_number"),
              }}
              errors={errors}
              md="12"
            />
            <PhoneNumberWithCountryCodeInput
              label={"Personal number"}
              nameCode={"personal_country_calling_code"}
              nameInputField={"personal_number"}
              register={register}
              control={control}
              errors={errors}
              md="12"
            />
            <TextareaInput
              name={"notes"}
              label={"Notes"}
              register={{
                ...register("notes"),
              }}
              errors={errors}
              md="12"
            />
            <Col size="12" className="d-flex justify-content-end">
              <Button
                className="mr-2"
                color="light"
                type="button"
                onClick={onAddOrgContactModalCancel}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={isCreateContactLoading}
              >
                {isCreateContactLoading && <Spinner type="grow" size="sm" />}
                <span>Submit</span>
              </Button>
            </Col>
          </Row>
        </form>
      </ModalComponent>

      <ModalComponent
        isOpen={isEditContactModalOpen}
        backdrop={"static"}
        title={"Edit Contact"}
        onModalCancel={() => {
          setEditContactModal(false);
        }}
      >
        <form onSubmit={handleEditLogFormSubmit(onEditFormSubmit)}>
          <Row className="gy-3">
            <TextInput
              name={"first_name"}
              label={"First name"}
              required
              register={{
                ...registerEditLogForm("first_name", {
                  required: "This field is required",
                }),
              }}
              errors={errorsEditLogForm}
              md="12"
            />
            <TextInput
              name={"last_name"}
              label={"Last name"}
              required
              register={{
                ...registerEditLogForm("last_name", {
                  required: "This field is required",
                }),
              }}
              errors={errorsEditLogForm}
              md="12"
            />
            <SelectField
              name={"org_role_id"}
              label={"Role"}
              control={controlEditLogForm}
              rules={{ required: "This field is required" }}
              options={orgContactRolesOptions}
              isSearchable={true}
              isLoading={isGetOrgContactRolesFetching}
              isDisabled={isGetOrgContactRolesFetching}
              errors={errorsEditLogForm}
              md="12"
            />
            <TextInput
              name={"work_email"}
              label={"Work email"}
              register={{
                ...registerEditLogForm("work_email", {}),
              }}
              errors={errorsEditLogForm}
              md="12"
            />
            <TextInput
              name={"personal_email"}
              label={"Personal email"}
              register={{
                ...registerEditLogForm("personal_email", {}),
              }}
              errors={errorsEditLogForm}
              md="12"
            />
            <PhoneNumberWithCountryCodeInput
              label={"Work Mobile number"}
              nameCode={"work_mobile_country_calling_code"}
              nameInputField={"work_mobile_number"}
              register={registerEditLogForm}
              control={controlEditLogForm}
              errors={errorsEditLogForm}
              md="12"
            />
            <TextInput
              name={"work_office_number"}
              label={"Work office number"}
              register={{
                ...registerEditLogForm("work_office_number"),
              }}
              errors={errorsEditLogForm}
              md="12"
            />
            <PhoneNumberWithCountryCodeInput
              label={"Personal number"}
              nameCode={"personal_country_calling_code"}
              nameInputField={"personal_number"}
              register={registerEditLogForm}
              control={controlEditLogForm}
              errors={errorsEditLogForm}
              md="12"
            />
            <TextareaInput
              name={"notes"}
              label={"Notes"}
              register={{
                ...registerEditLogForm("notes"),
              }}
              errors={errors}
              md="12"
            />
            <Col size="12" className="d-flex justify-content-end">
              <Button
                className="mr-2"
                color="light"
                type="button"
                onClick={onEditOrgContactModalCancel}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={isUpdateContactLoading}
              >
                {isUpdateContactLoading && <Spinner type="grow" size="sm" />}
                <span>Submit</span>
              </Button>
            </Col>
          </Row>
        </form>
      </ModalComponent>

      <ModalComponent
        isOpen={isRemoveContactModalOpen}
        title={"Are you sure you want to remove this contact?"}
        disableCloseBtn={true}
        toggle={() => {
          setRemoveContactModal(false);
        }}
        onModalCancel={() => {
          setRemoveContactModal(false);
        }}
      >
        <Row className="gy-3">
          <Col size="12">
            <Button
              color="primary"
              type="submit"
              disabled={isRemoveContactLoading}
              onClick={() => {
                removeContact(selectedItem);
              }}
            >
              {isRemoveContactLoading && <Spinner type="grow" size="sm" />}
              <span>Yes</span>
            </Button>
            <Button
              className="ml-3"
              color="light"
              onClick={() => {
                setRemoveContactModal(false);
              }}
            >
              <span>No</span>
            </Button>
          </Col>
        </Row>
      </ModalComponent>
    </div>
  );
};

const TOBSection = ({
  sectionRef,
  setTab,
  setUploadCategory,
  setUploadModal,
}) => {
  const { organisation, logs, tobs } = useContext(DataContext);
  const defaultValues = {};
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    control,
    reset,
  } = useForm();
  const {
    register: registerEditPipelineForm,
    handleSubmit: handleEditPipelineFormSubmit,
    formState: {
      errors: editPipelineFormErrors,
      isSubmitSuccessful: isEditPipelineFormSubmitSuccessful,
    },
    control: controlEditPipelineForm,
    reset: resetEditPipelineForm,
  } = useForm();
  const {
    register: registerLogForm,
    handleSubmit: handleLogFormSubmit,
    formState: { errors: errorsLogForm },
    control: controlLogForm,
    reset: resetLogForm,
  } = useForm({ defaultValues: defaultValues });
  const [
    addTOBRecord,
    {
      isLoading: isAddTOBLoading,
      isSuccess: isAddTOBSuccess,
      isError: isAddTOBError,
      error: addTOBError,
    },
  ] = useAddOrganisationTOBMutation();
  const [
    getInteractionTypesOptions,
    {
      data: interactionTypeData,
      isFetching: isGetInteractionTypesFetching,
      isSuccess: isGetInteractionTypesSuccess,
      isError: isGetInteractionTypesError,
      error: getInteractionTypesError,
    },
  ] = useLazyGetInteractionTypesQuery();
  const [
    addOrganisationLog,
    {
      isLoading: isAddOrganisationLogLoading,
      isError: isAddOrganisationLogError,
      isSuccess: isAddOrganisationLogSuccess,
      error: addOrganisationLogError,
    },
  ] = useAddOrganisationLogMutation();
  const [
    editOrganisationLog,
    {
      isLoading: isEditOrganisationLogLoading,
      isError: isEditOrganisationLogError,
      isSuccess: isEditOrganisationLogSuccess,
      error: editOrganisationLogError,
    },
  ] = useEditOrganisationLogMutation();
  const [
    editTOBRecord,
    {
      isLoading: isEditTOBRecordLoading,
      isError: isEditTOBRecordError,
      isSuccess: isEditTOBRecordSuccess,
      error: editTOBRecordError,
    },
  ] = useEditOrganisationTOBMutation();
  const [
    removeOrganisationLog,
    {
      isLoading: isRemoveOrganisationLogLoading,
      isError: isRemoveOrganisationLogError,
      isSuccess: isRemoveOrganisationLogSuccess,
      error: removeOrganisationLogError,
    },
  ] = useRemoveOrganisationLogMutation();
  const [
    updateOrganisationPipelineStage,
    {
      isLoading: isEditOrganisationPipelineLoading,
      isError: isEditOrganisationPipelineError,
      isSuccess: isEditOrganisationPipelineSuccess,
      error: editOrganisationPipelineError,
    },
  ] = useUpdatePipelineStageMutation();
  const [
    getPipelineConfig,
    {
      data: pipelineConfig,
      isFetching,
      isSuccess: isGetConfigSuccess,
      isError: isGetConfigError,
      error,
    },
  ] = useLazyGetPipelineConfigQuery();
  const [interactionTypeOptions, setInteractionTypeOptions] = useState([]);
  const [isAddTOBRecordModalOpen, setAddTOBRecordModalOpen] = useState(false);
  const [isLogModalOpen, setLogModal] = useState(false);
  const [isEditLogModalOpen, setEditLogModal] = useState(false);
  const [isEditPipelineModalOpen, setEditPipelineModalOpen] = useState(false);
  const [isRemoveLogModalOpen, setRemoveLogModal] = useState(false);
  const [isEditTOBModalOpen, setEditTOBModalOpen] = useState(false);
  const [stageOptions, setStageOptions] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});

  const onAddTOBRecordBtnClicked = () => {
    reset();
    setAddTOBRecordModalOpen(true);
  };
  const onAddTOBLogBtnClicked = () => {
    getInteractionTypesOptions(undefined, true);
    resetLogForm();
    setLogModal(true);
  };

  const onEditLogBtnClicked = (item) => {
    getInteractionTypesOptions(undefined, true);
    resetLogForm(
      {
        ...item,
        direction: {
          value: item.direction,
          label: capitalize(item.direction),
        },
        interaction_type: {
          value: item.interaction_type,
          label: item.interaction_type_name,
        },
      },
      {
        keepDefaultValues: true,
      }
    );
    setEditLogModal(true);
  };

  const processData = (data) => {
    data.code = data?.code?.value;
    data.organisation_id = organisation?.id;
    data.date_started = data.date_started
      ? new Date(data.date_started).toISOString()
      : null;

    data.date_end = data.date_end
      ? new Date(data.date_end).toISOString()
      : null;

    return data;
  };

  const onAddTOBRecordSubmit = (data) => {
    data = processData(data);

    addTOBRecord(data);
  };

  const onAddTOBRecordModalCancel = () => {
    setAddTOBRecordModalOpen(false);
  };
  const onEditTOBModalCancel = () => {
    setEditTOBModalOpen(false);
  };

  const onEditPipelineClicked = () => {
    getPipelineConfig({ type: "tob" }, true);
    resetEditPipelineForm();
    setEditPipelineModalOpen(true);
  };

  const onEditPipelineSubmit = (data) => {
    updateOrganisationPipelineStage({
      code: data.code.value,
      stage: {
        name: data.stage.value,
        status: data.status.value,
      },
      type: "tob",
      organisation_id: organisation?.id,
    });
  };

  const onEditTOBBtnClicked = (item) => {
    reset(
      {
        ...item,
        date_started: Date.parse(item?.date_started),
        date_end: Date.parse(item?.date_end),
      },
      {
        keepDefaultValues: true,
      }
    );
    setEditTOBModalOpen(true);
  };

  const onEditTOBFormSubmit = (data) => {
    data = processData(data);
    editTOBRecord(data);
  };

  const onLogFormSubmit = (data) => {
    data.direction = data?.direction?.value;
    data.interaction_type = data?.interaction_type?.value;
    addOrganisationLog({
      ...data,
      type: "tob",
      organisation_id: organisation?.id,
    });
  };

  const onEditLogFormSubmit = (data) => {
    data.direction = data?.direction?.value;
    data.interaction_type = data?.interaction_type?.value;
    editOrganisationLog({
      ...data,
      organisation_id: organisation?.id,
    });
  };

  useEffect(() => {
    if (isAddTOBSuccess) {
      toast.success("Add TOB successfully!");
      setAddTOBRecordModalOpen(false);
    }
    if (isAddTOBError && addTOBError) {
      toast.error(addTOBError?.data?.message);
    }
  }, [isAddTOBSuccess, isAddTOBError]);

  useEffect(() => {
    if (isEditTOBRecordSuccess) {
      toast.success("Edit TOB record successfully!");
      setEditTOBModalOpen(false);
    }
    if (isEditTOBRecordError && editTOBRecordError) {
      toast.error(editTOBRecordError?.data?.message);
    }
  }, [isEditTOBRecordSuccess, isEditTOBRecordError]);

  useEffect(() => {
    if (isGetConfigSuccess && pipelineConfig?.stages) {
      let data = pipelineConfig?.stages?.map((e) => {
        return { value: e.name, label: e.name };
      });
      setStageOptions(data);
    }
  }, [pipelineConfig?.stages, isGetConfigSuccess, isGetConfigError]);

  useEffect(() => {
    if (
      isGetInteractionTypesSuccess &&
      interactionTypeData?.interaction_types
    ) {
      let data = interactionTypeData.interaction_types.map((e) => {
        return { value: e.id, label: e.type };
      });
      setInteractionTypeOptions(data);
    }
    if (isGetInteractionTypesError && getInteractionTypesError) {
      toast.error(getInteractionTypesError?.data?.message);
    }
  }, [isGetInteractionTypesSuccess, isGetInteractionTypesError]);

  useEffect(() => {
    if (isAddOrganisationLogSuccess) {
      toast.success("Add log successfully!");
      setLogModal(false);
    }
    if (isAddOrganisationLogError && addOrganisationLogError) {
      toast.error(addOrganisationLogError?.data?.message);
    }
  }, [isAddOrganisationLogSuccess, isAddOrganisationLogError]);

  useEffect(() => {
    if (isEditOrganisationLogSuccess) {
      toast.success("Edit log successfully!");
      setEditLogModal(false);
    }
    if (isEditOrganisationLogError && editOrganisationLogError) {
      toast.error(editOrganisationLogError?.data?.message);
    }
  }, [isEditOrganisationLogSuccess, isEditOrganisationLogError]);

  useEffect(() => {
    if (isRemoveOrganisationLogSuccess) {
      toast.success("Remove log successfully!");
      setRemoveLogModal(false);
    }
    if (isRemoveOrganisationLogError && removeOrganisationLogError) {
      toast.error(removeOrganisationLogError?.data?.message);
    }
  }, [isRemoveOrganisationLogSuccess, isRemoveOrganisationLogError]);

  useEffect(() => {
    if (isEditOrganisationPipelineSuccess) {
      toast.success("Edit pipeline stage successfully!");
      setEditPipelineModalOpen(false);
    }
    if (isEditOrganisationPipelineError && editOrganisationPipelineError) {
      toast.error(editOrganisationPipelineError?.data?.message);
    }
  }, [isEditOrganisationPipelineSuccess, isEditOrganisationPipelineError]);

  return (
    <div ref={(ref) => (sectionRef.current[2] = ref)}>
      <Block>
        <BlockHead>
          <BlockTitle tag="h5">TOB</BlockTitle>
        </BlockHead>
        <BlockHead className="nk-block-head-line">
          <BlockTitle tag="h6" className="overline-title text-base">
            TOB \ Active Pipelines
            {tobs?.pipelines?.length > 0 && (
              <Button
                color="primary"
                outline
                size="xs"
                className="d-none d-sm-inline-flex ml-3"
                onClick={onEditPipelineClicked}
              >
                <Icon name="edit-alt-fill"></Icon>
                <span>Edit a pipeline</span>
              </Button>
            )}
          </BlockTitle>
        </BlockHead>
        <Block className="overflow-auto">
          <Block>
            {tobs?.pipelines?.map((pipeline, idx) => {
              let key = `org-tob-pipeline-${pipeline?.id}`;
              return (
                <Row
                  key={key}
                  className={classNames({
                    "mt-3": idx > 0,
                  })}
                >
                  <Col
                    md="2"
                    className="d-flex flex-column text-center justify-content-center pr-0"
                  >
                    <p className="m-0">{pipeline?.code}</p>
                    <p>{/* {user?.first_name} {user?.last_name} */}</p>
                  </Col>
                  <Col md="10" className="p-0">
                    <ul className="timeline-steps">
                      {pipeline?.stages?.map((stage, stageIdx) => {
                        return (
                          <li
                            className="timeline-content"
                            style={{ textAlign: "center" }}
                            key={`${key}-stage-name-${stageIdx}`}
                          >
                            <span>{stage.name}</span>
                          </li>
                        );
                      })}
                    </ul>
                    <ul className="timeline-steps">
                      {pipeline?.stages?.map((stage, stageIdx) => {
                        const idxTp = pipeline?.stages?.findIndex(
                          (e) => e.status === "not_completed"
                        );
                        return (
                          <li
                            key={`${key}-stage-status-${stageIdx}`}
                            className={classNames({
                              "timeline-step": true,
                              completed: stage.is_completed,
                              skipped: stage.status === "skipped",
                              "last-active-stage": idx === idxTp - 1,
                            })}
                          >
                            <div
                              className={classNames({
                                "timeline-status": true,
                                "bg-primary": stage.status === "completed",
                                // completed: e.is_completed,
                              })}
                            ></div>
                            <div
                              className="timeline-content"
                              style={{ textAlign: "center" }}
                            >
                              {stage.status === "completed" ? (
                                <>
                                  <strong>
                                    {moment(stage.time).fromNow()}
                                  </strong>
                                  <p>
                                    {moment(stage.time).format("DD MMM YYYY")}
                                  </p>
                                </>
                              ) : null}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </Col>
                </Row>
              );
            })}
          </Block>
          <Block>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col" className="w-min-120px">
                    Date
                  </th>
                  <th scope="col" className="w-min-120px">
                    Time
                  </th>
                  <th scope="col" className="w-min-150px">
                    Pipeline Code
                  </th>
                  <th scope="col" className="w-min-200px">
                    Stage
                  </th>
                  <th scope="col" className="w-min-200px">
                    By
                  </th>
                </tr>
              </thead>
              <tbody>
                {tobs?.pipeline_logs ? (
                  tobs?.pipeline_logs?.map((e, idx) => {
                    return (
                      <tr key={`tob-log-${idx}`}>
                        <td>{moment(e.time).format("DD MMM YYYY")}</td>
                        <td>{moment(e.time).format("LT")}</td>
                        <td>{e?.code}</td>
                        <td>{e?.name}</td>
                        <td>{e?.created_by}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                )}
              </tbody>
            </table>
          </Block>
        </Block>

        <Block>
          <BlockHead className="nk-block-head-line">
            <BlockTitle tag="h6" className="overline-title text-base">
              TOB \ Records
              <Button
                color="primary"
                outline
                size="xs"
                className="d-none d-sm-inline-flex ml-3"
                onClick={onAddTOBRecordBtnClicked}
              >
                <Icon name="plus"></Icon>
                <span>Add record</span>
              </Button>
            </BlockTitle>
          </BlockHead>
          {tobs?.tob_records?.length > 0 ? (
            <SimpleBar className="w-100" forceVisible="x" autoHide={false}>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col" className="w-min-80px">
                      S/N
                    </th>
                    <th scope="col" className="w-min-120px">
                      Name
                    </th>
                    <th scope="col" className="w-min-150px">
                      Account manager
                    </th>
                    <th scope="col" className="w-min-200px">
                      Rates
                    </th>
                    <th scope="col" className="w-min-100px">
                      TOB signed
                    </th>
                    <th scope="col" className="w-min-100px">
                      Client PLS
                    </th>
                    <th scope="col" className="w-min-250px">
                      Documents
                    </th>
                    <th scope="col" className="w-min-250px">
                      Date started
                    </th>
                    <th scope="col" className="w-min-250px">
                      Date confirmed
                    </th>
                    <th scope="col" className="w-min-150px"></th>
                  </tr>
                </thead>
                <tbody>
                  {tobs?.tob_records?.map((e, idx) => {
                    let key = `org-tob-records-${e.id}`;
                    return (
                      <tr key={key}>
                        <td>{idx + 1}</td>
                        <td>{e?.name}</td>
                        <td>{e?.account_manager}</td>
                        <td className="white-space-pre-line">{e?.rates}</td>
                        <td>{e?.is_tob_signed ? "Yes" : "No"}</td>
                        <td>{e?.is_client_pls ? "Yes" : "No"}</td>
                        <td>
                          {e?.documents?.map((doc, docIdx) => {
                            return (
                              <Row
                                key={`org-tob-file-${docIdx}`}
                                className="align-items-center my-2"
                              >
                                <Col className="mx-2">
                                  {nioIconData["file-pdf"]}
                                </Col>
                                <Col>
                                  <a
                                    href={`${process.env.PUBLIC_URL}/files/?key=${doc?.url}`}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                  >
                                    <span>{doc?.name}</span>
                                  </a>
                                </Col>
                              </Row>
                            );
                          })}
                        </td>
                        <td>
                          {e?.date_started &&
                            moment(e?.date_started).format(
                              "DD MMM YYYY, hh:mm a"
                            )}
                        </td>
                        <td>
                          {e?.date_end &&
                            moment(e?.date_end).format("DD MMM YYYY, hh:mm a")}
                        </td>
                        <td>
                          <React.Fragment>
                            <Button
                              className="btn-icon mr-2"
                              id={`upload-btn-${key}`}
                              color="light"
                              size="xs"
                              onClick={() => {
                                setUploadCategory(`tob_${e?.id}`);
                                setUploadModal(true);
                              }}
                            >
                              <Icon name="upload" />
                            </Button>
                            <UncontrolledTooltip
                              placement="top"
                              flip={false}
                              target={`upload-btn-${key}`}
                            >
                              Upload
                            </UncontrolledTooltip>
                            <Button
                              className="btn-icon mr-2"
                              id={`edit-btn-${key}`}
                              color="light"
                              size="xs"
                              onClick={() => {
                                onEditTOBBtnClicked(e);
                              }}
                            >
                              <Icon name="edit-alt-fill" />
                            </Button>
                            <UncontrolledTooltip
                              placement="top"
                              flip={false}
                              target={`edit-btn-${key}`}
                            >
                              Edit
                            </UncontrolledTooltip>
                            <Button
                              className="btn-icon"
                              id={`remove-btn-${key}`}
                              color="light"
                              size="xs"
                            >
                              <Icon name="trash" />
                            </Button>
                            <UncontrolledTooltip
                              placement="top"
                              flip={false}
                              target={`remove-btn-${key}`}
                            >
                              Remove
                            </UncontrolledTooltip>
                          </React.Fragment>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </SimpleBar>
          ) : (
            <div className="d-flex justify-content-center no-data-box">
              <div className="p-2">No data</div>
            </div>
          )}
        </Block>

        <Block>
          <BlockHead className="nk-block-head-line">
            <BlockTitle tag="h6" className="overline-title text-base">
              TOB \ Log
              <Button
                color="primary"
                outline
                size="xs"
                className="d-none d-sm-inline-flex ml-3"
                onClick={onAddTOBLogBtnClicked}
              >
                <Icon name="plus"></Icon>
                <span>Add log</span>
              </Button>
            </BlockTitle>
          </BlockHead>
          <SimpleBar className="w-100" forceVisible="x" autoHide={false}>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col" className="w-min-150px">
                    Date
                  </th>
                  <th scope="col" className="w-min-100px">
                    Time
                  </th>
                  <th scope="col" className="w-min-150px">
                    Duration
                  </th>
                  <th scope="col" className="w-min-150px">
                    Direction
                  </th>
                  <th scope="col" className="w-min-150px">
                    Interaction Type
                  </th>
                  <th scope="col" className="w-min-150px">
                    Purpose
                  </th>
                  <th scope="col" className="w-min-150px">
                    Purpose ID
                  </th>
                  <th scope="col" className="w-min-150px">
                    Pipeline
                  </th>
                  <th scope="col" className="w-min-150px">
                    By who
                  </th>
                  <th scope="col" className="w-min-150px">
                    With who
                  </th>
                  <th scope="col" className="w-min-150px">
                    Follow up required
                  </th>
                  <th scope="col" className="w-min-250px">
                    Notes
                  </th>
                  <th scope="col" className="w-min-100px"></th>
                </tr>
              </thead>
              <tbody>
                {logs?.tob_logs?.map((e, idx) => {
                  let key = `tob-log-${e.id}`;
                  return (
                    <tr key={key}>
                      <td>{moment(e.time).format("DD MMM YYYY")}</td>
                      <td>{moment(e.time).format("LT")}</td>
                      <td>{formatDuration(e?.duration)}</td>
                      <td>{e.direction}</td>
                      <td>{e.interaction_type_name}</td>
                      <td>{e.purpose}</td>
                      <td></td>
                      <td>{e.pipeline}</td>
                      <td>{e.created_by}</td>
                      <td>{e.with}</td>
                      <td>{e.follow_up_required ? "Yes" : "No"}</td>
                      <td className="white-space-pre-line">{e.notes}</td>
                      <td>
                        {e.is_editable ? (
                          <React.Fragment>
                            <Button
                              className="btn-icon"
                              id={`edit-btn-${key}`}
                              color="light"
                              size="xs"
                              onClick={() => {
                                onEditLogBtnClicked(e);
                              }}
                            >
                              <Icon name="edit" />
                            </Button>
                            <UncontrolledTooltip
                              placement="top"
                              target={`edit-btn-${key}`}
                            >
                              Edit
                            </UncontrolledTooltip>
                            <Button
                              className="btn-icon ml-2"
                              id={`remove-btn-${key}`}
                              color="light"
                              size="xs"
                              onClick={() => {
                                setSelectedItem(e);
                                setRemoveLogModal(true);
                              }}
                            >
                              <Icon name="trash" />
                            </Button>
                            <UncontrolledTooltip
                              placement="top"
                              target={`remove-btn-${key}`}
                            >
                              Remove
                            </UncontrolledTooltip>
                          </React.Fragment>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </SimpleBar>
        </Block>
      </Block>

      <ModalComponent
        isOpen={isAddTOBRecordModalOpen}
        backdrop={"static"}
        title={"Add TOB"}
        onModalCancel={() => {
          setAddTOBRecordModalOpen(false);
        }}
      >
        <form onSubmit={handleSubmit(onAddTOBRecordSubmit)}>
          <Row className="gy-3">
            <SelectField
              name={"code"}
              label={"Pipeline Code"}
              control={control}
              rules={{ required: "This field is required" }}
              isSearchable={false}
              options={tobs?.pipelines?.map((e, idx) => {
                return {
                  value: e.code,
                  label: e.code,
                };
              })}
              errors={errors}
              md="12"
            />
            <TextInput
              name={"name"}
              label={"Name"}
              required
              register={{
                ...register("name", {
                  required: "This field is required",
                }),
              }}
              errors={errors}
              md="12"
            />
            <TextInput
              name={"account_manager"}
              label={"Account Manager"}
              required
              register={{
                ...register("account_manager", {
                  required: "This field is required",
                }),
              }}
              errors={errors}
              md="12"
            />
            <TextareaInput
              name={"rates"}
              label={"Rates"}
              register={{
                ...register("rates", {}),
              }}
              errors={errors}
              md="12"
            />
            <CheckboxInput
              name={"is_tob_signed"}
              label={"TOB signed"}
              register={{
                ...register("is_tob_signed"),
              }}
              errors={errors}
              md="12"
            />
            <CheckboxInput
              name={"is_client_pls"}
              label={"Client PLS"}
              register={{
                ...register("is_client_pls"),
              }}
              errors={errors}
              md="12"
            />
            <DatePickerInput
              name={"date_started"}
              label={"Date started"}
              placeholder={"dd/mm/yyyy"}
              dateFormat={"dd/MM/yyyy"}
              control={control}
              errors={errors}
              md="12"
            />
            <DatePickerInput
              name={"date_end"}
              label={"Date end"}
              placeholder={"dd/mm/yyyy"}
              dateFormat={"dd/MM/yyyy"}
              control={control}
              errors={errors}
              md="12"
            />

            <Col size="12" className="d-flex justify-content-end">
              <Button
                className="mr-2"
                color="light"
                type="button"
                onClick={onAddTOBRecordModalCancel}
              >
                Cancel
              </Button>
              <Button color="primary" type="submit" disabled={isAddTOBLoading}>
                {isAddTOBLoading && <Spinner type="grow" size="sm" />}
                <span>Submit</span>
              </Button>
            </Col>
          </Row>
        </form>
      </ModalComponent>

      <ModalComponent
        isOpen={isEditPipelineModalOpen}
        backdrop={"static"}
        title={"Add/Edit Stage"}
        onModalCancel={() => {
          setEditPipelineModalOpen(false);
        }}
      >
        <form onSubmit={handleEditPipelineFormSubmit(onEditPipelineSubmit)}>
          <Row className="gy-3">
            <SelectField
              name={"code"}
              label={"Pipeline Code"}
              control={controlEditPipelineForm}
              rules={{ required: "This field is required" }}
              isSearchable={false}
              options={tobs?.pipelines?.map((e, idx) => {
                return {
                  value: e.code,
                  label: e.code,
                };
              })}
              errors={editPipelineFormErrors}
              md="12"
            />
            <SelectField
              name={"stage"}
              label={"Stage"}
              control={controlEditPipelineForm}
              rules={{ required: "This field is required" }}
              isSearchable={false}
              options={stageOptions}
              errors={editPipelineFormErrors}
              md="12"
            />
            <SelectField
              name={"status"}
              label={"Status"}
              control={controlEditPipelineForm}
              rules={{ required: "This field is required" }}
              isSearchable={false}
              options={pipelineStatusOptions}
              errors={editPipelineFormErrors}
              md="12"
            />
            <Col size="12">
              <Button
                color="primary"
                type="submit"
                disabled={isEditOrganisationPipelineLoading}
              >
                {isEditOrganisationPipelineLoading && (
                  <Spinner type="grow" size="sm" />
                )}
                <span>Submit</span>
              </Button>
            </Col>
          </Row>
        </form>
      </ModalComponent>

      <ModalComponent
        isOpen={isEditTOBModalOpen}
        backdrop={"static"}
        title={"Edit TOB Record"}
        onModalCancel={onEditTOBModalCancel}
      >
        <form onSubmit={handleSubmit(onEditTOBFormSubmit)}>
          <Row className="gy-3">
            <TextInput
              name={"name"}
              label={"Name"}
              required
              register={{
                ...register("name", {
                  required: "This field is required",
                }),
              }}
              errors={errors}
              md="12"
            />
            <TextInput
              name={"account_manager"}
              label={"Account Manager"}
              required
              register={{
                ...register("account_manager", {
                  required: "This field is required",
                }),
              }}
              errors={errors}
              md="12"
            />
            <TextareaInput
              name={"rates"}
              label={"Rates"}
              register={{
                ...register("rates", {}),
              }}
              errors={errors}
              md="12"
            />
            <CheckboxInput
              name={"is_tob_signed"}
              label={"TOB signed"}
              register={{
                ...register("is_tob_signed"),
              }}
              errors={errors}
              md="12"
            />
            <CheckboxInput
              name={"is_client_pls"}
              label={"Client PLS"}
              register={{
                ...register("is_client_pls"),
              }}
              errors={errors}
              md="12"
            />
            <DatePickerInput
              name={"date_started"}
              label={"Date started"}
              placeholder={"dd/mm/yyyy"}
              dateFormat={"dd/MM/yyyy"}
              control={control}
              errors={errors}
              md="12"
            />
            <DatePickerInput
              name={"date_end"}
              label={"Date end"}
              placeholder={"dd/mm/yyyy"}
              dateFormat={"dd/MM/yyyy"}
              control={control}
              errors={errors}
              md="12"
            />
            <Col size="12">
              <Button
                color="primary"
                type="submit"
                disabled={isEditTOBRecordLoading}
              >
                {isEditTOBRecordLoading && <Spinner type="grow" size="sm" />}
                <span>Submit</span>
              </Button>
            </Col>
          </Row>
        </form>
      </ModalComponent>

      <ModalComponent
        isOpen={isLogModalOpen}
        backdrop={"static"}
        title={"Add Log"}
        onModalCancel={() => {
          setLogModal(false);
        }}
      >
        <form onSubmit={handleLogFormSubmit(onLogFormSubmit)}>
          <Row className="gy-3">
            <SelectField
              name={"direction"}
              label={"Direction"}
              control={controlLogForm}
              // rules={{ required: "This field is required" }}
              isSearchable={false}
              options={logDirectionOptions}
              errors={errorsLogForm}
              md="12"
            />
            <SelectField
              name={"interaction_type"}
              label={"Interaction Type"}
              control={controlLogForm}
              // rules={{ required: "This field is required" }}
              options={interactionTypeOptions}
              isSearchable={false}
              isLoading={isGetInteractionTypesFetching}
              isDisabled={isGetInteractionTypesFetching}
              errors={errorsLogForm}
              md="12"
            />
            <TextInput
              name={"purpose"}
              label={"Purpose"}
              register={{
                ...registerLogForm("purpose", {}),
              }}
              errors={errorsLogForm}
              md="12"
            />
            <TextareaInput
              name={"notes"}
              label={"Notes"}
              required
              register={{
                ...registerLogForm("notes", {
                  required: "This field is required",
                }),
              }}
              errors={errorsLogForm}
              md="12"
            />
            <CheckboxInput
              name={"follow_up_required"}
              label={"Follow up required"}
              register={{
                ...registerLogForm("follow_up_required"),
              }}
              errors={errorsLogForm}
              md="12"
            />
            <CheckboxInput
              name={"is_start_pipeline"}
              label={"Start new pipeline"}
              register={{
                ...registerLogForm("is_start_pipeline"),
              }}
              errors={errorsLogForm}
              md="12"
            />
            <Col size="12">
              <Button
                color="primary"
                type="submit"
                disabled={isAddOrganisationLogLoading}
              >
                {isAddOrganisationLogLoading && (
                  <Spinner type="grow" size="sm" />
                )}
                <span>Submit</span>
              </Button>
            </Col>
          </Row>
        </form>
      </ModalComponent>

      <ModalComponent
        isOpen={isEditLogModalOpen}
        backdrop={"static"}
        title={"Edit Log"}
        onModalCancel={() => {
          setEditLogModal(false);
        }}
      >
        <form onSubmit={handleLogFormSubmit(onEditLogFormSubmit)}>
          <Row className="gy-3">
            <SelectField
              name={"direction"}
              label={"Direction"}
              control={controlLogForm}
              // rules={{ required: "This field is required" }}
              isSearchable={false}
              options={logDirectionOptions}
              errors={errorsLogForm}
              md="12"
            />
            <SelectField
              name={"interaction_type"}
              label={"Interaction Type"}
              control={controlLogForm}
              // rules={{ required: "This field is required" }}
              options={interactionTypeOptions}
              isSearchable={false}
              isLoading={isGetInteractionTypesFetching}
              isDisabled={isGetInteractionTypesFetching}
              errors={errorsLogForm}
              md="12"
            />
            <TextInput
              name={"purpose"}
              label={"Purpose"}
              register={{
                ...registerLogForm("purpose", {}),
              }}
              errors={errorsLogForm}
              md="12"
            />
            <TextareaInput
              name={"notes"}
              label={"Notes"}
              required
              register={{
                ...registerLogForm("notes", {
                  required: "This field is required",
                }),
              }}
              errors={errorsLogForm}
              md="12"
            />
            <CheckboxInput
              name={"follow_up_required"}
              label={"Follow up required"}
              register={{
                ...registerLogForm("follow_up_required"),
              }}
              errors={errorsLogForm}
              md="12"
            />
            <Col size="12">
              <Button
                color="primary"
                type="submit"
                disabled={isEditOrganisationLogLoading}
              >
                {isEditOrganisationLogLoading && (
                  <Spinner type="grow" size="sm" />
                )}
                <span>Submit</span>
              </Button>
            </Col>
          </Row>
        </form>
      </ModalComponent>

      <ModalComponent
        isOpen={isRemoveLogModalOpen}
        title={"Are you sure you want to remove this log?"}
        disableCloseBtn={true}
        toggle={() => {
          setRemoveLogModal(false);
        }}
        onModalCancel={() => {
          setRemoveLogModal(false);
        }}
      >
        <Row className="gy-3">
          <Col size="12">
            <Button
              color="primary"
              type="submit"
              disabled={isRemoveOrganisationLogLoading}
              onClick={() => {
                removeOrganisationLog(selectedItem);
              }}
            >
              {isRemoveOrganisationLogLoading && (
                <Spinner type="grow" size="sm" />
              )}
              <span>Yes</span>
            </Button>
            <Button
              className="ml-3"
              color="light"
              onClick={() => {
                setRemoveLogModal(false);
              }}
            >
              <span>No</span>
            </Button>
          </Col>
        </Row>
      </ModalComponent>
    </div>
  );
};

const OpportunitiesSection = ({
  sectionRef,
  setTab,
  setUploadCategory,
  setUploadModal,
}) => {
  const { organisation, opportunities } = useContext(DataContext);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isRemoveModalOpen, setRemoveModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const defaultValues = {};
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    control,
    reset,
  } = useForm({ defaultValues: defaultValues });
  const [
    getOpportunityTypes,
    {
      data: opportunityTypesData,
      isFetching: isGetOpportunityTypesFetching,
      isSuccess: isGetOpportunityTypesSuccess,
      isError: isGetOpportunityTypesError,
      error: getOpportunityTypesError,
    },
  ] = useLazyGetOpportunityTypesQuery();
  const [
    getOpportunityContractTypes,
    {
      data: opportunityContractTypesData,
      isFetching: isGetOpportunityContractTypesFetching,
      isSuccess: isGetOpportunityContractTypesSuccess,
      isError: isGetOpportunityContractTypesError,
      error: getOpportunityContractTypesError,
    },
  ] = useLazyGetOpportunityContractTypesQuery();
  const [
    getOpportunityCommitmentTypes,
    {
      data: opportunityCommitmentTypesData,
      isFetching: isGetOpportunityCommitmentTypesFetching,
      isSuccess: isGetOpportunityCommitmentTypesSuccess,
      isError: isGetOpportunityCommitmentTypesError,
      error: getOpportunityCommitmentTypesError,
    },
  ] = useLazyGetOpportunityCommitmentsQuery();
  const [opportunityTypesOptions, setOpportunityTypesOptions] = useState([]);
  const [opportunityContractTypesOptions, setOpportunityContractTypesOptions] =
    useState([]);
  const [
    opportunityCommitmentTypesOptions,
    setOpportunityCommitmentTypesOptions,
  ] = useState([]);
  const [
    addOpportunity,
    {
      isLoading: isAddOpportunityLoading,
      isSuccess: isAddOpportunitySuccess,
      isError: isAddOpportunityError,
      error: addOpportunityError,
    },
  ] = useAddOrganisationOpportunityMutation();

  const onAddBtnClicked = () => {
    getOpportunityTypes(undefined, true);
    getOpportunityContractTypes(undefined, true);
    getOpportunityCommitmentTypes(undefined, true);
    reset({ ...defaultValues });
    setAddModalOpen(true);
  };
  const onEditBtnClicked = () => {
    //TODO:
  };

  const processData = (data) => {
    data.organisation_id = organisation?.id;
    data.commitment = data?.commitment?.value;
    data.contract_type = data?.contract_type?.value;
    data.opportunity_type = data?.opportunity_type?.value;
    data.status = data?.status?.value;
    data.date_open = data.date_open
      ? new Date(data.date_open).toISOString()
      : null;
    data.date_close = data.date_close
      ? new Date(data.date_close).toISOString()
      : null;

    return data;
  };

  const onFormSubmit = (data) => {
    data = processData(data);
    console.log(data);

    addOpportunity(data);
  };

  const onAddModalCancel = () => {
    setAddModalOpen(false);
  };

  const onEditModalCancel = () => {
    setEditModalOpen(false);
  };

  useEffect(() => {
    if (isGetOpportunityTypesSuccess && opportunityTypesData?.types) {
      let data = opportunityTypesData.types.map((type) => {
        return { value: type.id, label: type.type };
      });
      setOpportunityTypesOptions(data);
    }
    if (isGetOpportunityTypesError && getOpportunityTypesError) {
      toast.error(getOpportunityTypesError?.data?.message);
    }
  }, [isGetOpportunityTypesSuccess, isGetOpportunityTypesError]);

  useEffect(() => {
    if (
      isGetOpportunityContractTypesSuccess &&
      opportunityContractTypesData?.types
    ) {
      let data = opportunityContractTypesData.types.map((type) => {
        return { value: type.id, label: type.type };
      });
      setOpportunityContractTypesOptions(data);
    }
    if (
      isGetOpportunityContractTypesError &&
      getOpportunityContractTypesError
    ) {
      toast.error(getOpportunityContractTypesError?.data?.message);
    }
  }, [
    isGetOpportunityContractTypesSuccess,
    isGetOpportunityContractTypesError,
  ]);

  useEffect(() => {
    if (
      isGetOpportunityCommitmentTypesSuccess &&
      opportunityCommitmentTypesData?.commitments
    ) {
      let data = opportunityCommitmentTypesData.commitments.map((type) => {
        return { value: type.id, label: type.type };
      });

      setOpportunityCommitmentTypesOptions(data);
    }
    if (
      isGetOpportunityCommitmentTypesError &&
      getOpportunityCommitmentTypesError
    ) {
      toast.error(getOpportunityCommitmentTypesError?.data?.message);
    }
  }, [
    isGetOpportunityCommitmentTypesSuccess,
    isGetOpportunityCommitmentTypesError,
  ]);

  useEffect(() => {
    if (isAddOpportunitySuccess) {
      toast.success("Add opportunity successfully!");
      setAddModalOpen(false);
    }
    if (isAddOpportunityError && addOpportunityError) {
      toast.error(addOpportunityError?.data?.message);
    }
  }, [isAddOpportunitySuccess, isAddOpportunityError]);

  return (
    <div ref={(ref) => (sectionRef.current[3] = ref)}>
      <Block>
        <BlockHead>
          <BlockTitle tag="h5">
            Oppotunities
            <Button
              color="primary"
              outline
              size="xs"
              className="d-none d-sm-inline-flex ml-3"
              onClick={onAddBtnClicked}
            >
              <Icon name="plus"></Icon>
              <span>Add Opportunity</span>
            </Button>
          </BlockTitle>
        </BlockHead>
        {opportunities?.length > 0 ? (
          <SimpleBar className="w-100" forceVisible="x" autoHide={false}>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col" className="w-min-60px">
                    S/N
                  </th>
                  <th scope="col" className="w-min-250px">
                    Opportunity type
                  </th>
                  <th scope="col" className="w-min-100px">
                    Contract type
                  </th>
                  <th scope="col" className="w-min-200px">
                    Commitment
                  </th>
                  <th scope="col" className="w-min-150px">
                    Employment Duration
                  </th>
                  <th scope="col" className="w-min-150px">
                    Arrangement
                  </th>
                  <th scope="col" className="w-min-200px">
                    Position
                  </th>
                  <th scope="col" className="w-min-400px">
                    Job description
                  </th>
                  <th scope="col" className="w-min-200px">
                    Number of openings
                  </th>
                  <th scope="col" className="w-min-200px">
                    Working address
                  </th>
                  <th scope="col" className="w-min-200px">
                    Salary flooring
                  </th>
                  <th scope="col" className="w-min-200px">
                    Salary ceiling
                  </th>
                  <th scope="col" className="w-min-200px">
                    Salary hourly
                  </th>
                  <th scope="col" className="w-min-200px">
                    Date open
                  </th>
                  <th scope="col" className="w-min-200px">
                    Date close
                  </th>
                  <th scope="col" className="w-min-200px">
                    Status
                  </th>
                  <th scope="col" className="w-min-200px">
                    Remarks
                  </th>
                  <th scope="col" className="w-min-200px">
                    Created by
                  </th>
                  <th scope="col" className="w-min-200px">
                    Created
                  </th>
                  <th scope="col" className="w-min-200px">
                    Updated
                  </th>
                  <th scope="col" className="w-min-150px"></th>
                </tr>
              </thead>
              <tbody>
                {opportunities?.map((e, idx) => {
                  let key = `organisation-opportunities-${e.id}`;
                  return (
                    <tr key={key}>
                      <td>{idx + 1}</td>
                      <td>{e?.opportunity_type_label}</td>
                      <td>{e?.contract_type_label}</td>
                      <td>{e?.commitment_label}</td>
                      <td>{e?.employment_duration}</td>
                      <td>{e?.arrangement}</td>
                      <td>{e?.position}</td>
                      <td className="white-space-pre-line">{e?.description}</td>
                      <td>{e?.number_openings}</td>
                      <td>{e?.working_address}</td>
                      <td>{e?.salary_flooring}</td>
                      <td>{e?.salary_ceiling}</td>
                      <td>{e?.salary_hourly}</td>
                      <td>{e?.date_open}</td>
                      <td>{e?.date_close}</td>
                      <td>{e?.status}</td>
                      <td>{e?.remarks}</td>
                      <td>{e?.created_by}</td>
                      <td>
                        {moment(e?.created_time).format("DD MMM YYYY, hh:mm a")}
                      </td>
                      <td>
                        {moment(e?.last_updated_time).format(
                          "DD MMM YYYY, hh:mm a"
                        )}
                      </td>
                      <td></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </SimpleBar>
        ) : (
          <div className="d-flex justify-content-center no-data-box">
            <div className="p-2">No data</div>
          </div>
        )}
      </Block>

      <ModalComponent
        isOpen={isAddModalOpen}
        backdrop={"static"}
        title={"Add Opportunity"}
        onModalCancel={() => {
          setAddModalOpen(false);
        }}
      >
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Row className="gy-3">
            <SelectField
              name={"opportunity_type"}
              label={"Opportunity Type"}
              control={control}
              rules={{ required: "This field is required" }}
              options={opportunityTypesOptions}
              isSearchable={true}
              isLoading={isGetOpportunityTypesFetching}
              isDisabled={isGetOpportunityTypesFetching}
              errors={errors}
              md="12"
            />
            <SelectField
              name={"contract_type"}
              label={"Contract Type"}
              control={control}
              rules={{ required: "This field is required" }}
              options={opportunityContractTypesOptions}
              isSearchable={true}
              isLoading={isGetOpportunityContractTypesFetching}
              isDisabled={isGetOpportunityContractTypesFetching}
              errors={errors}
              md="12"
            />
            <SelectField
              name={"commitment"}
              label={"Commitment"}
              control={control}
              rules={{ required: "This field is required" }}
              options={opportunityCommitmentTypesOptions}
              isSearchable={true}
              isLoading={isGetOpportunityCommitmentTypesFetching}
              isDisabled={isGetOpportunityCommitmentTypesFetching}
              errors={errors}
              md="12"
            />
            <TextInput
              name={"employment_duration"}
              label={"Employment Duration"}
              register={{
                ...register("employment_duration", {}),
              }}
              errors={errors}
              md="12"
            />
            <TextInput
              name={"arrangement"}
              label={"Arrangement"}
              register={{
                ...register("arrangement", {}),
              }}
              errors={errors}
              md="12"
            />
            <TextInput
              name={"position"}
              label={"Position"}
              required
              register={{
                ...register("position", {
                  required: "This field is required",
                }),
              }}
              errors={errors}
              md="12"
            />
            <TextareaInput
              name={"job_description"}
              label={"Jop Description"}
              register={{
                ...register("job_description", {}),
              }}
              errors={errors}
              md="12"
            />
            <TextInput
              name={"number_openings"}
              label={"Number of Openings"}
              type={"number"}
              register={{
                ...register("number_openings", {
                  valueAsNumber: true,
                }),
              }}
              errors={errors}
              md="12"
            />
            <TextInput
              name={"working_address"}
              label={"Working Address"}
              register={{
                ...register("working_address", {}),
              }}
              errors={errors}
              md="12"
            />
            <TextInput
              name={"salary_flooring"}
              label={"Salary Flooring"}
              register={{
                ...register("salary_flooring", {}),
              }}
              errors={errors}
              md="12"
            />
            <TextInput
              name={"salary_ceiling"}
              label={"Salary Ceiling"}
              register={{
                ...register("salary_ceiling", {}),
              }}
              errors={errors}
              md="12"
            />
            <TextInput
              name={"salary_hourly"}
              label={"Salary Hourly"}
              register={{
                ...register("salary_hourly", {}),
              }}
              errors={errors}
              md="12"
            />
            <DatePickerInput
              name={"date_open"}
              label={"Date open"}
              placeholder={"dd/mm/yyyy"}
              dateFormat={"dd/MM/yyyy"}
              control={control}
              errors={errors}
              md="12"
            />
            <DatePickerInput
              name={"date_close"}
              label={"Date close"}
              placeholder={"dd/mm/yyyy"}
              dateFormat={"dd/MM/yyyy"}
              control={control}
              errors={errors}
              md="12"
            />
            <SelectField
              name={"status"}
              label={"Status"}
              control={control}
              rules={{ required: "This field is required" }}
              isSearchable={false}
              options={opportunityStatusOptions}
              errors={errors}
              md="12"
            />
            <TextareaInput
              name={"remarks"}
              label={"Remarks"}
              register={{
                ...register("remarks", {}),
              }}
              errors={errors}
              md="12"
            />

            <Col size="12" className="d-flex justify-content-end">
              <Button
                className="mr-2"
                color="light"
                type="button"
                onClick={onAddModalCancel}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={isAddOpportunityLoading}
              >
                {isAddOpportunityLoading && <Spinner type="grow" size="sm" />}
                <span>Submit</span>
              </Button>
            </Col>
          </Row>
        </form>
      </ModalComponent>
    </div>
  );
};

const BillablesSection = ({
  sectionRef,
  setTab,
  setUploadCategory,
  setUploadModal,
}) => {
  const { organisation, tobs, billables } = useContext(DataContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();
  const {
    register: registerRecord,
    handleSubmit: handleSubmitRecord,
    formState: { errors: errorsRecord },
    control: controlRecord,
    reset: resetRecord,
  } = useForm();
  const [
    addBillable,
    {
      isLoading: isAddBillableLoading,
      isSuccess: isAddBillableSuccess,
      isError: isAddBillableError,
      error: addBillableError,
    },
  ] = useAddOrganisationBillableMutation();
  const [
    editBillable,
    {
      isLoading: isEditBillableLoading,
      isSuccess: isEditBillableSuccess,
      isError: isEditBillableError,
      error: editBillableError,
    },
  ] = useEditOrganisationBillableMutation();
  const [
    removeBillable,
    {
      isLoading: isRemoveBillableLoading,
      isSuccess: isRemoveBillableSuccess,
      isError: isRemoveBillableError,
      error: removeBillableError,
    },
  ] = useRemoveOrganisationBillableMutation();
  const [
    addBillableRecord,
    {
      isLoading: isAddBillableRecordLoading,
      isSuccess: isAddBillableRecordSuccess,
      isError: isAddBillableRecordError,
      error: addBillableRecordError,
    },
  ] = useAddOrganisationBillableRecordMutation();
  const [
    editBillablRecord,
    {
      isLoading: isEditBillableRecordLoading,
      isSuccess: isEditBillableRecordSuccess,
      isError: isEditBillableRecordError,
      error: editBillableRecordError,
    },
  ] = useEditOrganisationBillableRecordMutation();
  const [
    removeBillableRecord,
    {
      isLoading: isRemoveBillableRecordLoading,
      isSuccess: isRemoveBillableRecordSuccess,
      isError: isRemoveBillableRecordError,
      error: removeBillableRecordError,
    },
  ] = useRemoveOrganisationBillableRecordMutation();
  const [isAddBillableModalOpen, setAddBillableModalOpen] = useState(false);
  const [isEditBillableModalOpen, setEditBillableModalOpen] = useState(false);
  const [isRemoveBillableModalOpen, setRemoveBillableModalOpen] =
    useState(false);
  const [isAddRecordModalOpen, setAddRecordModalOpen] = useState(false);
  const [isEditRecordModalOpen, setEditRecordModalOpen] = useState(false);
  const [isRemoveRecordModalOpen, setRemoveRecordModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const onAddBillableBtnClicked = () => {
    reset();
    setAddBillableModalOpen(true);
  };
  const onAddBillableBtnCancel = () => {
    setAddBillableModalOpen(false);
  };
  const onEditBillableBtnClicked = (item) => {
    reset(
      {
        ...item,
        effective_start: Date.parse(item?.effective_start),
        effective_end: Date.parse(item?.effective_end),
      },
      {
        keepDefaultValues: true,
      }
    );
    setEditBillableModalOpen(true);
  };
  const onEditBillableBtnCancel = () => {
    setEditBillableModalOpen(false);
  };
  const onRemoveBillableBtnClicked = (item) => {
    setSelectedItem(item);
    setRemoveBillableModalOpen(true);
  };

  const onAddRecordBtnClicked = () => {
    resetRecord();
    setAddRecordModalOpen(true);
  };
  const onAddRecordBtnCancel = () => {
    setAddRecordModalOpen(false);
  };
  const onEditRecordBtnClicked = (item) => {
    resetRecord(
      {
        ...item,
        date_paid: Date.parse(item?.date_paid),
        direction: {
          value: item?.direction,
          label: item?.direction,
        },
      },
      {
        keepDefaultValues: true,
      }
    );
    setEditRecordModalOpen(true);
  };
  const onEditRecordBtnCancel = (item) => {
    setEditRecordModalOpen(false);
  };
  const onRemoveBillableRecordBtnClicked = (item) => {
    setSelectedItem(item);
    setRemoveRecordModalOpen(true);
  };

  const onAddBillableFormSubmit = (data) => {
    let pipelineID = tobs?.pipelines?.find(
      (pipeline) => pipeline.code === data.tob?.value
    )?.id;
    let tobID = tobs?.tob_records?.find(
      (tob) => tob.code === data.tob?.value
    )?.id;

    addBillable({
      ...data,
      code: data.tob?.value,
      pipeline_id: pipelineID,
      tob_id: tobID,
      organisation_id: organisation?.id,
      effective_start: data.effective_start
        ? new Date(data.effective_start).toISOString()
        : null,
      effective_end: data.effective_end
        ? new Date(data.effective_end).toISOString()
        : null,
    });
  };

  const onEditBillableFormSubmit = (data) => {
    editBillable({
      ...data,
      effective_start: data.effective_start
        ? new Date(data.effective_start).toISOString()
        : null,
      effective_end: data.effective_end
        ? new Date(data.effective_end).toISOString()
        : null,
    });
  };

  useEffect(() => {
    if (isAddBillableSuccess) {
      toast.success("Add billable successfully!");
      setAddBillableModalOpen(false);
    }
    if (isAddBillableError && addBillableError) {
      toast.error(addBillableError?.data?.message);
    }
  }, [isAddBillableSuccess, isAddBillableError]);

  useEffect(() => {
    if (isEditBillableSuccess) {
      toast.success("Edit billable successfully!");
      setEditBillableModalOpen(false);
    }
    if (isEditBillableError && editBillableError) {
      toast.error(editBillableError?.data?.message);
    }
  }, [isEditBillableSuccess, isEditBillableError]);

  useEffect(() => {
    if (isRemoveBillableSuccess) {
      toast.success("Remove billable successfully!");
      setRemoveBillableModalOpen(false);
    }
    if (isRemoveBillableError && removeBillableError) {
      toast.error(removeBillableError?.data?.message);
    }
  }, [isRemoveBillableSuccess, isRemoveBillableError]);

  const onAddRecordFormSubmit = (data) => {
    addBillableRecord({
      ...data,
      billable_id: data.billable_id?.value,
      organisation_id: organisation?.id,
      date_paid: data.date_paid ? new Date(data.date_paid).toISOString() : null,
      direction: data.direction?.value,
    });
  };

  const onEditRecordFormSubmit = (data) => {
    editBillablRecord({
      ...data,
      date_paid: data.date_paid ? new Date(data.date_paid).toISOString() : null,
      direction: data.direction?.value,
    });
  };

  useEffect(() => {
    if (isAddBillableRecordSuccess) {
      toast.success("Add record successfully!");
      setAddRecordModalOpen(false);
    }
    if (isAddBillableRecordError && addBillableRecordError) {
      toast.error(addBillableRecordError?.data?.message);
    }
  }, [isAddBillableRecordSuccess, isAddBillableRecordError]);

  useEffect(() => {
    if (isEditBillableRecordSuccess) {
      toast.success("Edit record successfully!");
      setEditRecordModalOpen(false);
    }
    if (isEditBillableRecordError && editBillableRecordError) {
      toast.error(editBillableRecordError?.data?.message);
    }
  }, [isEditBillableRecordSuccess, isEditBillableRecordError]);

  useEffect(() => {
    if (isRemoveBillableRecordSuccess) {
      toast.success("Remove record successfully!");
      setRemoveRecordModalOpen(false);
    }
    if (isRemoveBillableRecordError && removeBillableRecordError) {
      toast.error(removeBillableRecordError?.data?.message);
    }
  }, [isRemoveBillableRecordSuccess, isRemoveBillableRecordError]);

  return (
    <div ref={(ref) => (sectionRef.current[4] = ref)}>
      <Block>
        <BlockHead>
          <BlockTitle tag="h5">
            Billables
            <Button
              color="primary"
              outline
              size="xs"
              className="d-none d-sm-inline-flex ml-3"
              onClick={onAddBillableBtnClicked}
            >
              <Icon name="plus"></Icon>
              <span>Add billable</span>
            </Button>
          </BlockTitle>
        </BlockHead>
        <Block>
          <Block>
            <BlockHead className="nk-block-head-line">
              <BlockTitle tag="h6" className="overline-title text-base">
                Billables \ Overview
              </BlockTitle>
            </BlockHead>

            {billables?.billables?.length > 0 ? (
              <SimpleBar className="w-100" forceVisible="x" autoHide={false}>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" className="w-min-60px">
                        S/N
                      </th>
                      <th scope="col" className="w-min-100px">
                        TOB
                      </th>
                      <th scope="col" className="w-min-150px">
                        Arrangement
                      </th>
                      <th scope="col" className="w-min-150px">
                        Fee amount
                      </th>
                      <th scope="col" className="w-min-120px">
                        Percentage kept
                      </th>
                      <th scope="col" className="w-min-200px">
                        Payment frequency
                      </th>
                      <th scope="col" className="w-min-150px">
                        Effective start
                      </th>
                      <th scope="col" className="w-min-150px">
                        Effective end
                      </th>
                      <th scope="col" className="w-min-150px">
                        Candidate
                      </th>
                      <th scope="col" className="w-min-150px">
                        Recruiter
                      </th>
                      <th scope="col" className="w-min-200px">
                        Remarks
                      </th>
                      <th scope="col" className="w-min-150px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {billables?.billables?.map((e, idx) => {
                      let key = `billables-${e.id}`;
                      return (
                        <tr key={key}>
                          <td>{idx + 1}</td>
                          <td>{e?.tob_id}</td>
                          <td>{e?.arrangement}</td>
                          {/* <td>{moment(e?.year_diagnosed).format("YYYY")}</td> */}
                          <td>{e?.fee_amount}</td>
                          <td>{`${e?.percentage_kept}%`}</td>
                          <td>{e?.payment_frequency}</td>
                          <td>
                            {e?.effective_start &&
                              moment(e?.effective_start).format("DD-MM-YYYY")}
                          </td>
                          <td>
                            {e?.effective_end &&
                              moment(e?.effective_end).format("DD-MM-YYYY")}
                          </td>
                          <td>{e?.candidate_id}</td>
                          <td>{e?.recruiter_id}</td>
                          <td className="white-space-pre-line">{e?.remarks}</td>
                          <td>
                            <React.Fragment>
                              <Button
                                className="btn-icon mr-2"
                                id={`edit-btn-${key}`}
                                color="light"
                                size="xs"
                                onClick={() => {
                                  onEditBillableBtnClicked(e);
                                }}
                              >
                                <Icon name="edit-alt-fill" />
                              </Button>
                              <UncontrolledTooltip
                                placement="top"
                                flip={false}
                                target={`edit-btn-${key}`}
                              >
                                Edit
                              </UncontrolledTooltip>
                              <Button
                                className="btn-icon"
                                id={`remove-btn-${key}`}
                                color="light"
                                size="xs"
                                onClick={() => {
                                  onRemoveBillableBtnClicked(e);
                                }}
                              >
                                <Icon name="trash" />
                              </Button>
                              <UncontrolledTooltip
                                placement="top"
                                flip={false}
                                target={`remove-btn-${key}`}
                              >
                                Remove
                              </UncontrolledTooltip>
                            </React.Fragment>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </SimpleBar>
            ) : (
              <div className="d-flex justify-content-center no-data-box">
                <div className="p-2">No data</div>
              </div>
            )}
          </Block>

          <Block>
            <BlockHead className="nk-block-head-line">
              <BlockTitle tag="h6" className="overline-title text-base">
                Billables \ Records
                {billables?.billables?.length > 0 && (
                  <Button
                    color="primary"
                    outline
                    size="xs"
                    className="d-none d-sm-inline-flex ml-3"
                    onClick={onAddRecordBtnClicked}
                  >
                    <Icon name="plus"></Icon>
                    <span>Add records</span>
                  </Button>
                )}
              </BlockTitle>
            </BlockHead>
            {billables?.records?.length > 0 ? (
              <SimpleBar className="w-100" forceVisible="x" autoHide={false}>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" className="w-min-60px">
                        S/N
                      </th>
                      <th scope="col" className="w-min-100px">
                        Billable ID
                      </th>
                      <th scope="col" className="w-min-150px">
                        Date paid
                      </th>
                      <th scope="col" className="w-min-150px">
                        Amount
                      </th>
                      <th scope="col" className="w-min-250px">
                        Payment type
                      </th>
                      <th scope="col" className="w-min-200px">
                        Direction
                      </th>
                      <th scope="col" className="w-min-250px">
                        Remarks
                      </th>
                      <th scope="col" className="w-min-200px">
                        Created
                      </th>
                      <th scope="col" className="w-min-200px">
                        Updated
                      </th>
                      <th scope="col" className="w-min-150px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {billables?.records?.map((e, idx) => {
                      let key = `billables-records-${e.id}`;
                      return (
                        <tr key={key}>
                          <td>{idx + 1}</td>
                          <td>{e?.billable_id}</td>
                          <td>
                            {e?.date_paid &&
                              moment(e?.date_paid).format("DD-MM-YYYY")}
                          </td>
                          <td>{e?.amount}</td>
                          <td>{e?.payment_type}</td>
                          <td>{e?.direction}</td>
                          <td className="white-space-pre-line">{e?.remarks}</td>
                          <td>
                            {e?.created_time &&
                              moment(e?.created_time).format(
                                "DD MMM YYYY, hh:mm a"
                              )}
                          </td>
                          <td>
                            {e?.last_updated_time &&
                              moment(e?.last_updated_time).format(
                                "DD MMM YYYY, hh:mm a"
                              )}
                          </td>
                          <td>
                            <React.Fragment>
                              <Button
                                className="btn-icon mr-2"
                                id={`edit-btn-${key}`}
                                color="light"
                                size="xs"
                                onClick={() => {
                                  onEditRecordBtnClicked(e);
                                }}
                              >
                                <Icon name="edit-alt-fill" />
                              </Button>
                              <UncontrolledTooltip
                                placement="top"
                                flip={false}
                                target={`edit-btn-${key}`}
                              >
                                Edit
                              </UncontrolledTooltip>
                              <Button
                                className="btn-icon"
                                id={`remove-btn-${key}`}
                                color="light"
                                size="xs"
                                onClick={() => {
                                  onRemoveBillableRecordBtnClicked(e);
                                }}
                              >
                                <Icon name="trash" />
                              </Button>
                              <UncontrolledTooltip
                                placement="top"
                                flip={false}
                                target={`remove-btn-${key}`}
                              >
                                Remove
                              </UncontrolledTooltip>
                            </React.Fragment>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </SimpleBar>
            ) : (
              <div className="d-flex justify-content-center no-data-box">
                <div className="p-2">No data</div>
              </div>
            )}
          </Block>
        </Block>
      </Block>

      <ModalComponent
        isOpen={isAddBillableModalOpen}
        backdrop={"static"}
        title={"Add Billable"}
        onModalCancel={() => {
          setAddBillableModalOpen(false);
        }}
      >
        <form onSubmit={handleSubmit(onAddBillableFormSubmit)}>
          <Row className="gy-3">
            <SelectField
              name={"tob"}
              label={"TOB"}
              control={control}
              rules={{ required: "This field is required" }}
              isSearchable={false}
              options={tobs?.tob_records?.map((e, idx) => {
                return {
                  value: e.code,
                  label: e.code,
                };
              })}
              errors={errors}
              md="12"
            />
            <TextInput
              name={"arrangement"}
              label={"Arrangement"}
              // required
              register={{
                ...register("arrangement", {
                  // required: "This field is required",
                }),
              }}
              errors={errors}
              md="12"
            />
            <TextInput
              name={"fee_amount"}
              label={"Fee Amount"}
              // required
              register={{
                ...register("fee_amount", {
                  // required: "This field is required",
                }),
              }}
              errors={errors}
              md="12"
            />
            <TextInput
              name={"percentage_kept"}
              label={"Percentage kept"}
              type={"number"}
              register={{
                ...register("percentage_kept", {
                  valueAsNumber: true,
                }),
              }}
              errors={errors}
              md="12"
            />
            <TextInput
              name={"payment_frequency"}
              label={"Payment frequency"}
              // required
              register={{
                ...register("payment_frequency", {
                  // required: "This field is required",
                }),
              }}
              errors={errors}
              md="12"
            />
            <DatePickerInput
              name={"effective_start"}
              label={"Effective start"}
              placeholder={"dd/mm/yyyy"}
              dateFormat={"dd/MM/yyyy"}
              control={control}
              errors={errors}
              md="12"
            />
            <DatePickerInput
              name={"effective_end"}
              label={"Effective end"}
              placeholder={"dd/mm/yyyy"}
              dateFormat={"dd/MM/yyyy"}
              control={control}
              errors={errors}
              md="12"
            />
            <TextInput
              name={"candidate_id"}
              label={"Candidate ID"}
              required
              register={{
                ...register("candidate_id", {
                  // required: "This field is required",
                }),
              }}
              errors={errors}
              md="12"
            />
            <TextInput
              name={"recruiter_id"}
              label={"Recruiter ID"}
              required
              register={{
                ...register("recruiter_id", {
                  // required: "This field is required",
                }),
              }}
              errors={errors}
              md="12"
            />
            <TextareaInput
              name={"remarks"}
              label={"Remarks"}
              register={{
                ...register("remarks", {}),
              }}
              errors={errors}
              md="12"
            />

            <Col size="12" className="d-flex justify-content-end">
              <Button
                className="mr-2"
                color="light"
                type="button"
                onClick={onAddBillableBtnCancel}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={isAddBillableLoading}
              >
                {isAddBillableLoading && <Spinner type="grow" size="sm" />}
                <span>Submit</span>
              </Button>
            </Col>
          </Row>
        </form>
      </ModalComponent>

      <ModalComponent
        isOpen={isEditBillableModalOpen}
        backdrop={"static"}
        title={"Edit Billable"}
        onModalCancel={() => {
          setEditBillableModalOpen(false);
        }}
      >
        <form onSubmit={handleSubmit(onEditBillableFormSubmit)}>
          <Row className="gy-3">
            <TextInput
              name={"arrangement"}
              label={"Arrangement"}
              // required
              register={{
                ...register("arrangement", {
                  // required: "This field is required",
                }),
              }}
              errors={errors}
              md="12"
            />
            <TextInput
              name={"fee_amount"}
              label={"Fee Amount"}
              // required
              register={{
                ...register("fee_amount", {
                  // required: "This field is required",
                }),
              }}
              errors={errors}
              md="12"
            />
            <TextInput
              name={"percentage_kept"}
              label={"Percentage kept"}
              type={"number"}
              register={{
                ...register("percentage_kept", {
                  valueAsNumber: true,
                }),
              }}
              errors={errors}
              md="12"
            />
            <TextInput
              name={"payment_frequency"}
              label={"Payment frequency"}
              // required
              register={{
                ...register("payment_frequency", {
                  // required: "This field is required",
                }),
              }}
              errors={errors}
              md="12"
            />
            <DatePickerInput
              name={"effective_start"}
              label={"Effective start"}
              placeholder={"dd/mm/yyyy"}
              dateFormat={"dd/MM/yyyy"}
              control={control}
              errors={errors}
              md="12"
            />
            <DatePickerInput
              name={"effective_end"}
              label={"Effective end"}
              placeholder={"dd/mm/yyyy"}
              dateFormat={"dd/MM/yyyy"}
              control={control}
              errors={errors}
              md="12"
            />
            <TextInput
              name={"candidate_id"}
              label={"Candidate ID"}
              required
              register={{
                ...register("candidate_id", {
                  // required: "This field is required",
                }),
              }}
              errors={errors}
              md="12"
            />
            <TextInput
              name={"recruiter_id"}
              label={"Recruiter ID"}
              required
              register={{
                ...register("recruiter_id", {
                  // required: "This field is required",
                }),
              }}
              errors={errors}
              md="12"
            />
            <TextareaInput
              name={"remarks"}
              label={"Remarks"}
              register={{
                ...register("remarks", {}),
              }}
              errors={errors}
              md="12"
            />

            <Col size="12" className="d-flex justify-content-end">
              <Button
                className="mr-2"
                color="light"
                type="button"
                onClick={onEditBillableBtnCancel}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={isEditBillableLoading}
              >
                {isEditBillableLoading && <Spinner type="grow" size="sm" />}
                <span>Submit</span>
              </Button>
            </Col>
          </Row>
        </form>
      </ModalComponent>

      <ModalComponent
        isOpen={isRemoveBillableModalOpen}
        title={"Are you sure you want to remove this billable?"}
        disableCloseBtn={true}
        toggle={() => {
          setRemoveBillableModalOpen(false);
        }}
        onModalCancel={() => {
          setRemoveBillableModalOpen(false);
        }}
      >
        <Row className="gy-3">
          <Col size="12">
            <Button
              color="primary"
              type="submit"
              disabled={isRemoveBillableLoading}
              onClick={() => {
                removeBillable(selectedItem);
              }}
            >
              {isRemoveBillableLoading && <Spinner type="grow" size="sm" />}
              <span>Yes</span>
            </Button>
            <Button
              className="ml-3"
              color="light"
              onClick={() => {
                setRemoveBillableModalOpen(false);
              }}
            >
              <span>No</span>
            </Button>
          </Col>
        </Row>
      </ModalComponent>

      <ModalComponent
        isOpen={isAddRecordModalOpen}
        backdrop={"static"}
        title={"Add Record"}
        onModalCancel={() => {
          setAddRecordModalOpen(false);
        }}
      >
        <form onSubmit={handleSubmitRecord(onAddRecordFormSubmit)}>
          <Row className="gy-3">
            <SelectField
              name={"billable_id"}
              label={"Billable"}
              control={controlRecord}
              rules={{ required: "This field is required" }}
              isSearchable={false}
              options={billables?.billables?.map((e, idx) => {
                return {
                  value: e.id,
                  label: e.id,
                };
              })}
              errors={errorsRecord}
              md="12"
            />
            <DatePickerInput
              name={"date_paid"}
              label={"Date paid"}
              placeholder={"dd/mm/yyyy"}
              dateFormat={"dd/MM/yyyy"}
              control={controlRecord}
              errors={errorsRecord}
              md="12"
            />
            <TextInput
              name={"amount"}
              label={"Amount"}
              register={{
                ...registerRecord("amount", {}),
              }}
              errors={errorsRecord}
              md="12"
            />
            <TextInput
              name={"payment_type"}
              label={"Payment type"}
              register={{
                ...registerRecord("payment_type", {}),
              }}
              errors={errorsRecord}
              md="12"
            />
            <SelectField
              name={"direction"}
              label={"Direction"}
              control={controlRecord}
              isSearchable={false}
              options={billableDirectionOptions}
              errors={errorsRecord}
              md="12"
            />
            <TextareaInput
              name={"remarks"}
              label={"Remarks"}
              register={{
                ...registerRecord("remarks", {}),
              }}
              errors={errorsRecord}
              md="12"
            />

            <Col size="12" className="d-flex justify-content-end">
              <Button
                className="mr-2"
                color="light"
                type="button"
                onClick={onAddRecordBtnCancel}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={isAddBillableRecordLoading}
              >
                {isAddBillableRecordLoading && (
                  <Spinner type="grow" size="sm" />
                )}
                <span>Submit</span>
              </Button>
            </Col>
          </Row>
        </form>
      </ModalComponent>

      <ModalComponent
        isOpen={isEditRecordModalOpen}
        backdrop={"static"}
        title={"Edit Record"}
        onModalCancel={() => {
          setEditRecordModalOpen(false);
        }}
      >
        <form onSubmit={handleSubmitRecord(onEditRecordFormSubmit)}>
          <Row className="gy-3">
            <DatePickerInput
              name={"date_paid"}
              label={"Date paid"}
              placeholder={"dd/mm/yyyy"}
              dateFormat={"dd/MM/yyyy"}
              control={controlRecord}
              errors={errorsRecord}
              md="12"
            />
            <TextInput
              name={"amount"}
              label={"Amount"}
              register={{
                ...registerRecord("amount", {}),
              }}
              errors={errorsRecord}
              md="12"
            />
            <TextInput
              name={"payment_type"}
              label={"Payment type"}
              register={{
                ...registerRecord("payment_type", {}),
              }}
              errors={errorsRecord}
              md="12"
            />
            <SelectField
              name={"direction"}
              label={"Direction"}
              control={controlRecord}
              isSearchable={false}
              options={billableDirectionOptions}
              errors={errorsRecord}
              md="12"
            />
            <TextareaInput
              name={"remarks"}
              label={"Remarks"}
              register={{
                ...registerRecord("remarks", {}),
              }}
              errors={errorsRecord}
              md="12"
            />

            <Col size="12" className="d-flex justify-content-end">
              <Button
                className="mr-2"
                color="light"
                type="button"
                onClick={onEditRecordBtnCancel}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={isEditBillableRecordLoading}
              >
                {isEditBillableRecordLoading && (
                  <Spinner type="grow" size="sm" />
                )}
                <span>Submit</span>
              </Button>
            </Col>
          </Row>
        </form>
      </ModalComponent>

      <ModalComponent
        isOpen={isRemoveRecordModalOpen}
        title={"Are you sure you want to remove this record?"}
        disableCloseBtn={true}
        toggle={() => {
          setRemoveRecordModalOpen(false);
        }}
        onModalCancel={() => {
          setRemoveRecordModalOpen(false);
        }}
      >
        <Row className="gy-3">
          <Col size="12">
            <Button
              color="primary"
              type="submit"
              disabled={isRemoveBillableRecordLoading}
              onClick={() => {
                removeBillableRecord(selectedItem);
              }}
            >
              {isRemoveBillableRecordLoading && (
                <Spinner type="grow" size="sm" />
              )}
              <span>Yes</span>
            </Button>
            <Button
              className="ml-3"
              color="light"
              onClick={() => {
                setRemoveRecordModalOpen(false);
              }}
            >
              <span>No</span>
            </Button>
          </Col>
        </Row>
      </ModalComponent>
    </div>
  );
};

const SupportingDocsSection = ({
  sectionRef,
  setTab,
  setUploadCategory,
  setUploadModal,
}) => {
  const { organisation, supportingDocs } = useContext(DataContext);

  const [isAddSupportingDocModalOpen, setAddSupportingDocModalOpen] =
    useState(false);
  const [isEditSupportingDocModalOpen, setEditSupportingDocModal] =
    useState(false);
  const [isRemoveSupportingDocModalOpen, setRemoveSupportingDocModal] =
    useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const defaultValues = {};
  const [files, setFiles] = useState([]);
  const [percentage, setPercentage] = useState(null);
  const [uploader, setUploader] = useState(0);
  const [isUploading, setUploading] = useState(false);
  const [
    initializeMultipartUpload,
    {
      data: initializeMultipartUploadData,
      isLoading: isInitializeMultipartUploadLoading,
      isError: isInitializeMultipartUploadError,
      isSuccess: isInitializeMultipartUploadSuccess,
      error: initializeMultipartUploadError,
    },
  ] = useInitializeMultipartUploadMutation();
  const [
    finalizationMultipartUpload,
    {
      isLoading: isFinalizeMultipartUploadLoading,
      isSuccess: isFinalizeMultipartUploadSuccess,
      isError: isFinalizeMultipartUploadError,
      error: finalizeMultipartUploadError,
    },
  ] = useFinalizeMultipartUploadMutation();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          console.log(err);

          if (err.code === "file-too-large") {
            toast.error("File too large");
          }

          if (err.code === "file-invalid-type") {
          }
        });
      });
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    control,
    reset,
  } = useForm({ defaultValues: defaultValues });
  const [
    getOrgSupportingDocTypes,
    {
      data: orgSupportingDocTypesData,
      isFetching: isGetOrgSupportingDocTypesFetching,
      isSuccess: isGetOrgSupportingDocTypesSuccess,
      isError: isGetOrgSupportingDocTypesError,
      error: getOrgSupportingDocTypesError,
    },
  ] = useLazyGetOrgSupportingDocTypesQuery({
    limit: 1000,
  });
  const [orgSupportingDocTypesOptions, setOrgSupportingDocTypesOptions] =
    useState([]);
  const [
    updateSupportingDoc,
    {
      isLoading: isUpdateSupportingDocLoading,
      isSuccess: isUpdateSupportingDocSuccess,
      isError: isUpdateSupportingDocError,
      error: updateSupportingDocError,
    },
  ] = useEditSupportingDocMutation();
  const [
    removeSupportingDoc,
    {
      isLoading: isRemoveSupportingDocLoading,
      isSuccess: isRemoveSupportingDocSuccess,
      isError: isRemoveSupportingDocError,
      error: removeSupportingDocError,
    },
  ] = useRemoveSupportingDocMutation();

  const onAddBtnClicked = () => {
    getOrgSupportingDocTypes(undefined, true);
    reset({ ...defaultValues });
    setAddSupportingDocModalOpen(true);
  };

  const onEditBtnClicked = (doc) => {
    getOrgSupportingDocTypes(undefined, true);
    reset({
      ...doc,
      type: {
        value: doc?.type,
        label: doc?.type_name,
      },
      status: {
        value: doc?.status,
        label: doc?.status,
      },
      date_effective_from: Date.parse(doc?.date_effective_from),
      date_effective_to: Date.parse(doc?.date_effective_to),
    });
    setSelectedItem(doc);
    setEditSupportingDocModal(true);
  };

  const onRemoveBtnClicked = (doc) => {
    setSelectedItem(doc);
    setRemoveSupportingDocModal(true);
  };

  const processData = (data) => {
    data.organisation_id = organisation.id;
    data.date_effective_from = data.date_effective_from
      ? new Date(data.date_effective_from).toISOString()
      : null;
    data.date_effective_to = data.date_effective_to
      ? new Date(data.date_effective_to).toISOString()
      : null;
    data.status = data.status?.value;
    data.type = data.type?.value;
    return data;
  };

  const onFormSubmit = (data) => {
    data = processData(data);

    if (files) {
      const uploader = new Uploader({
        name: data.name,
        type: data.type,
        description: data.description,
        date_effective_from: data.date_effective_from,
        date_effective_to: data.date_effective_to,
        status: data.status,
        organisationId: organisation.id,
        fileName: files[0].name,
        file: files[0],
        fileType: files[0].type,
        uploadCategory: "organisation_supporting_document",
        initializeMultipartUpload: initializeMultipartUpload,
        finalizationMultipartUpload: finalizationMultipartUpload,
      });
      setUploader(uploader);

      uploader
        .onProgress(({ percentage: newPercentage }) => {
          if (newPercentage !== percentage) {
            setPercentage(newPercentage);
          }
        })
        .onError((error) => {
          setUploading(false);
          setFiles([]);
          setPercentage(0);
          toast.error(error);
        });

      uploader.start();
      setUploading(true);
    }
  };

  const onEditFormSubmit = (data) => {
    data = processData(data);
    // updateSupportingDoc(data);

    if (files) {
      const uploader = new Uploader({
        id: selectedItem.id,
        name: data.name,
        type: data.type,
        description: data.description,
        date_effective_from: data.date_effective_from,
        date_effective_to: data.date_effective_to,
        status: data.status,
        organisationId: organisation.id,
        fileName: files[0].name,
        file: files[0],
        fileType: files[0].type,
        uploadCategory: "organisation_supporting_document_edit",
        initializeMultipartUpload: initializeMultipartUpload,
        finalizationMultipartUpload: finalizationMultipartUpload,
      });
      setUploader(uploader);

      uploader
        .onProgress(({ percentage: newPercentage }) => {
          if (newPercentage !== percentage) {
            setPercentage(newPercentage);
          }
        })
        .onError((error) => {
          setUploading(false);
          setFiles([]);
          setPercentage(0);
          toast.error(error);
        });

      uploader.start();
      setUploading(true);
    }
  };

  const onAddSupportingDocModalCancel = () => {
    setAddSupportingDocModalOpen(false);
  };

  const onEditSupportingDocModalCancel = () => {
    setEditSupportingDocModal(false);
  };

  useEffect(() => {
    if (
      isFinalizeMultipartUploadSuccess ||
      isFinalizeMultipartUploadError ||
      isInitializeMultipartUploadError
    ) {
      setUploading(false);
      setFiles([]);
      setPercentage(0);

      if (isFinalizeMultipartUploadError) {
        toast.error(finalizeMultipartUploadError?.data?.message);
      }
      if (isInitializeMultipartUploadError) {
        toast.error(initializeMultipartUploadError?.data?.message);
      }

      if (isFinalizeMultipartUploadSuccess) {
        setAddSupportingDocModalOpen(false);
        setEditSupportingDocModal(false);
        toast.success("Add supporting doc successfully!");
      }
    }
  }, [
    isFinalizeMultipartUploadSuccess,
    isFinalizeMultipartUploadError,
    isInitializeMultipartUploadError,
  ]);

  useEffect(() => {
    if (isGetOrgSupportingDocTypesSuccess && orgSupportingDocTypesData?.types) {
      let data = orgSupportingDocTypesData.types.map((type) => {
        return { value: type.id, label: type.type };
      });
      setOrgSupportingDocTypesOptions(data);
    }
    if (isGetOrgSupportingDocTypesError && getOrgSupportingDocTypesError) {
      toast.error(getOrgSupportingDocTypesError?.data?.message);
    }
  }, [isGetOrgSupportingDocTypesSuccess, isGetOrgSupportingDocTypesError]);

  useEffect(() => {
    if (isUpdateSupportingDocSuccess) {
      toast.success("Edit supporting doc successfully!");
      setEditSupportingDocModal(false);
    }
    if (isUpdateSupportingDocError && updateSupportingDocError) {
      toast.error(updateSupportingDocError?.data?.message);
    }
  }, [isUpdateSupportingDocSuccess, isUpdateSupportingDocError]);

  useEffect(() => {
    if (isRemoveSupportingDocSuccess) {
      toast.success("Remove supporting doc successfully!");
      setRemoveSupportingDocModal(false);
    }
    if (isRemoveSupportingDocError && removeSupportingDocError) {
      toast.error(removeSupportingDocError?.data?.message);
    }
  }, [isRemoveSupportingDocSuccess, isRemoveSupportingDocError]);

  return (
    <div ref={(ref) => (sectionRef.current[5] = ref)}>
      <Block>
        <BlockHead>
          <BlockTitle tag="h5">
            Supporting Documents
            <Button
              color="primary"
              outline
              size="xs"
              className="d-none d-sm-inline-flex ml-3"
              onClick={onAddBtnClicked}
            >
              <Icon name="plus"></Icon>
              <span>Add Document</span>
            </Button>
          </BlockTitle>
        </BlockHead>

        <Block>
          {supportingDocs?.length > 0 ? (
            <SimpleBar className="w-100" forceVisible="x" autoHide={false}>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col" className="w-min-60px">
                      S/N
                    </th>
                    <th scope="col" className="w-min-100px">
                      Name
                    </th>
                    <th scope="col" className="w-min-150px">
                      Type
                    </th>
                    <th scope="col" className="w-min-150px">
                      Description
                    </th>
                    <th scope="col" className="w-min-250px">
                      Date effective from
                    </th>
                    <th scope="col" className="w-min-200px">
                      Date effective to
                    </th>
                    <th scope="col" className="w-min-250px">
                      Date uploaded
                    </th>
                    <th scope="col" className="w-min-200px">
                      Uploaded by
                    </th>
                    <th scope="col" className="w-min-200px">
                      Status
                    </th>
                    <th scope="col" className="w-min-400px">
                      Documents
                    </th>
                    <th scope="col" className="w-min-150px"></th>
                  </tr>
                </thead>
                <tbody>
                  {supportingDocs?.map((e, idx) => {
                    let key = `supporting-docs-${e?.id}-${idx}`;
                    return (
                      <tr key={key}>
                        <td>{idx + 1}</td>
                        <td>{e?.name}</td>
                        <td>{e?.type_name}</td>
                        <td className="white-space-pre-line">
                          {e?.description}
                        </td>
                        <td>
                          {e?.date_effective_from
                            ? moment(e?.date_effective_from).format(
                                "DD MMM YYYY"
                              )
                            : null}
                        </td>
                        <td>
                          {e?.date_effective_to
                            ? moment(e?.date_effective_to).format("DD MMM YYYY")
                            : null}
                        </td>
                        <td>
                          {e?.uploaded_time
                            ? moment(e?.uploaded_time).format("DD MMM YYYY")
                            : null}
                        </td>
                        <td>{e?.uploaded_by}</td>
                        <td>{e?.status}</td>
                        <td>
                          <Row className="align-items-center my-2">
                            <Col className="mx-2">
                              {nioIconData["file-pdf"]}
                            </Col>
                            <Col>
                              <a
                                href={`${process.env.PUBLIC_URL}/files/?key=${e?.url}`}
                                target="_blank"
                                rel="noreferrer noopener"
                              >
                                <span>{e?.file_name}</span>
                              </a>
                            </Col>
                          </Row>
                        </td>
                        <td>
                          <React.Fragment>
                            <Button
                              className="btn-icon"
                              id={`edit-btn-${key}`}
                              color="light"
                              size="xs"
                              onClick={() => {
                                onEditBtnClicked(e);
                              }}
                            >
                              <Icon name="edit-alt-fill" />
                            </Button>
                            <UncontrolledTooltip
                              placement="top"
                              target={`edit-btn-${key}`}
                            >
                              Edit
                            </UncontrolledTooltip>
                            <Button
                              className="btn-icon ml-2"
                              id={`remove-btn-${key}`}
                              color="light"
                              size="xs"
                              onClick={() => {
                                onRemoveBtnClicked(e);
                              }}
                            >
                              <Icon name="trash" />
                            </Button>
                            <UncontrolledTooltip
                              placement="top"
                              target={`remove-btn-${key}`}
                            >
                              Remove
                            </UncontrolledTooltip>
                          </React.Fragment>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </SimpleBar>
          ) : (
            <div className="d-flex justify-content-center no-data-box">
              <div className="p-2">No data</div>
            </div>
          )}
        </Block>
      </Block>

      <ModalComponent
        isOpen={isAddSupportingDocModalOpen}
        backdrop={"static"}
        title={"Add Supporting Document"}
        onModalCancel={() => {
          setAddSupportingDocModalOpen(false);
        }}
      >
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Row className="gy-3">
            <TextInput
              name={"name"}
              label={"Name"}
              required
              register={{
                ...register("name", {
                  required: "This field is required",
                }),
              }}
              errors={errors}
              md="12"
            />
            <SelectField
              name={"type"}
              label={"Type"}
              control={control}
              // rules={{ required: "This field is required" }}
              options={orgSupportingDocTypesOptions}
              isSearchable={true}
              isLoading={isGetOrgSupportingDocTypesFetching}
              isDisabled={isGetOrgSupportingDocTypesFetching}
              errors={errors}
              md="12"
            />
            <TextareaInput
              name={"description"}
              label={"Description"}
              register={{
                ...register("description", {}),
              }}
              errors={errors}
              md="12"
            />
            <DatePickerInput
              name={"date_effective_from"}
              label={"Date effective from"}
              placeholder={"dd/mm/yyyy"}
              dateFormat={"dd/MM/yyyy"}
              control={control}
              errors={errors}
              md="12"
            />
            <DatePickerInput
              name={"date_effective_to"}
              label={"Date effective to"}
              placeholder={"dd/mm/yyyy"}
              dateFormat={"dd/MM/yyyy"}
              control={control}
              errors={errors}
              md="12"
            />
            <SelectField
              name={"status"}
              label={"Status"}
              control={control}
              rules={{ required: "This field is required" }}
              isSearchable={false}
              options={orgSupportingDocStatusOptions}
              errors={errors}
              md="12"
            />
            <Col size="12">
              <section>
                <div
                  {...getRootProps()}
                  className="dropzone upload-zone dz-clickable h-min-100px"
                >
                  <input {...getInputProps()} />
                  {files.length === 0 && (
                    <div className="dz-message">
                      <span className="dz-message-text">
                        Drag and drop file
                      </span>
                      <span className="dz-message-or">or</span>
                      <Button color="primary">SELECT</Button>
                    </div>
                  )}
                  {files.map((file) => (
                    <React.Fragment key={`file-name-${file.name}`}>
                      <div>{file.name}</div>
                      <Progress
                        className="progress-lg mt-2"
                        animated
                        value={percentage}
                      >
                        {`${percentage}%`}
                      </Progress>
                    </React.Fragment>
                  ))}
                </div>
              </section>
            </Col>

            <Col size="12" className="d-flex justify-content-end">
              <Button
                className="mr-2"
                color="light"
                type="button"
                onClick={onAddSupportingDocModalCancel}
              >
                Cancel
              </Button>
              <Button color="primary" type="submit" disabled={isUploading}>
                {isUploading && <Spinner type="grow" size="sm" />}
                <span>Submit</span>
              </Button>
            </Col>
          </Row>
        </form>
      </ModalComponent>

      <ModalComponent
        isOpen={isEditSupportingDocModalOpen}
        backdrop={"static"}
        title={"Edit Supporting Document"}
        onModalCancel={() => {
          setEditSupportingDocModal(false);
        }}
      >
        <form onSubmit={handleSubmit(onEditFormSubmit)}>
          <Row className="gy-3">
            <TextInput
              name={"name"}
              label={"Name"}
              required
              register={{
                ...register("name", {
                  required: "This field is required",
                }),
              }}
              errors={errors}
              md="12"
            />
            <SelectField
              name={"type"}
              label={"Type"}
              control={control}
              rules={{ required: "This field is required" }}
              options={orgSupportingDocTypesOptions}
              isSearchable={true}
              isLoading={isGetOrgSupportingDocTypesFetching}
              isDisabled={isGetOrgSupportingDocTypesFetching}
              errors={errors}
              md="12"
            />
            <TextareaInput
              name={"description"}
              label={"Description"}
              register={{
                ...register("description", {}),
              }}
              errors={errors}
              md="12"
            />
            <DatePickerInput
              name={"date_effective_from"}
              label={"Date effective from"}
              placeholder={"dd/mm/yyyy"}
              dateFormat={"dd/MM/yyyy"}
              control={control}
              errors={errors}
              md="12"
            />
            <DatePickerInput
              name={"date_effective_to"}
              label={"Date effective to"}
              placeholder={"dd/mm/yyyy"}
              dateFormat={"dd/MM/yyyy"}
              control={control}
              errors={errors}
              md="12"
            />
            <SelectField
              name={"status"}
              label={"Status"}
              control={control}
              rules={{ required: "This field is required" }}
              isSearchable={false}
              options={orgSupportingDocStatusOptions}
              errors={errors}
              md="12"
            />
            <Col size="12">
              <section>
                <div
                  {...getRootProps()}
                  className="dropzone upload-zone dz-clickable h-min-100px"
                >
                  <input {...getInputProps()} />
                  {files.length === 0 && (
                    <div className="dz-message">
                      <span className="dz-message-text">
                        Drag and drop file
                      </span>
                      <span className="dz-message-or">or</span>
                      <Button color="primary">SELECT</Button>
                    </div>
                  )}
                  {files.map((file) => (
                    <React.Fragment key={`file-name-${file.name}`}>
                      <div>{file.name}</div>
                      <Progress
                        className="progress-lg mt-2"
                        animated
                        value={percentage}
                      >
                        {`${percentage}%`}
                      </Progress>
                    </React.Fragment>
                  ))}
                </div>
              </section>
            </Col>

            <Col size="12" className="d-flex justify-content-end">
              <Button
                className="mr-2"
                color="light"
                type="button"
                onClick={onEditSupportingDocModalCancel}
              >
                Cancel
              </Button>
              <Button color="primary" type="submit" disabled={isUploading}>
                {isUploading && <Spinner type="grow" size="sm" />}
                <span>Submit</span>
              </Button>
            </Col>
          </Row>
        </form>
      </ModalComponent>

      <ModalComponent
        isOpen={isRemoveSupportingDocModalOpen}
        title={"Are you sure you want to remove this log?"}
        disableCloseBtn={true}
        toggle={() => {
          setRemoveSupportingDocModal(false);
        }}
        onModalCancel={() => {
          setRemoveSupportingDocModal(false);
        }}
      >
        <Row className="gy-3">
          <Col size="12">
            <Button
              color="primary"
              type="submit"
              disabled={isRemoveSupportingDocLoading}
              onClick={() => {
                removeSupportingDoc(selectedItem);
              }}
            >
              {isRemoveSupportingDocLoading && (
                <Spinner type="grow" size="sm" />
              )}
              <span>Yes</span>
            </Button>
            <Button
              className="ml-3"
              color="light"
              onClick={() => {
                setRemoveSupportingDocModal(false);
              }}
            >
              <span>No</span>
            </Button>
          </Col>
        </Row>
      </ModalComponent>
    </div>
  );
};

const LogSection = ({ sectionRef, setTab }) => {
  const { organisation, logs } = useContext(DataContext);
  const defaultValues = {
    follow_up_required: false,
  };
  const {
    register: registerLogForm,
    handleSubmit: handleLogFormSubmit,
    formState: { errors: errorsLogForm },
    control: controlLogForm,
    reset: resetLogForm,
  } = useForm({ defaultValues: defaultValues });
  const {
    register: registerEditLogForm,
    handleSubmit: handleEditLogFormSubmit,
    formState: { errors: errorsEditLogForm },
    control: controlEditLogForm,
    reset: resetEditLogForm,
  } = useForm();

  const [
    addOrganisationLog,
    {
      isLoading: isAddOrganisationLogLoading,
      isError: isAddOrganisationLogError,
      isSuccess: isAddOrganisationLogSuccess,
      error: addOrganisationLogError,
    },
  ] = useAddOrganisationLogMutation();
  const [
    editOrganisationLog,
    {
      isLoading: isEditOrganisationLogLoading,
      isError: isEditOrganisationLogError,
      isSuccess: isEditOrganisationLogSuccess,
      error: editOrganisationLogError,
    },
  ] = useEditOrganisationLogMutation();
  const [
    removeOrganisationLog,
    {
      isLoading: isRemoveOrganisationLogLoading,
      isError: isRemoveOrganisationLogError,
      isSuccess: isRemoveOrganisationLogSuccess,
      error: removeOrganisationLogError,
    },
  ] = useRemoveOrganisationLogMutation();
  const [
    getInteractionTypesOptions,
    {
      data: interactionTypeData,
      isFetching: isGetInteractionTypesFetching,
      isSuccess: isGetInteractionTypesSuccess,
      isError: isGetInteractionTypesError,
      error: getInteractionTypesError,
    },
  ] = useLazyGetInteractionTypesQuery();
  const [interactionTypeOptions, setInteractionTypeOptions] = useState([]);
  const [isLogModalOpen, setLogModal] = useState(false);
  const [isEditLogModalOpen, setEditLogModal] = useState(false);
  const [isRemoveLogModalOpen, setRemoveLogModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  useEffect(() => {
    if (isAddOrganisationLogSuccess) {
      toast.success("Add log successfully!");
      setLogModal(false);
    }
    if (isAddOrganisationLogError && addOrganisationLogError) {
      toast.error(addOrganisationLogError?.data?.message);
    }
  }, [isAddOrganisationLogSuccess, isAddOrganisationLogError]);

  useEffect(() => {
    if (isEditOrganisationLogSuccess) {
      toast.success("Edit log successfully!");
      setEditLogModal(false);
    }
    if (isEditOrganisationLogError && editOrganisationLogError) {
      toast.error(editOrganisationLogError?.data?.message);
    }
  }, [isEditOrganisationLogSuccess, isEditOrganisationLogError]);

  useEffect(() => {
    if (isRemoveOrganisationLogSuccess) {
      toast.success("Remove log successfully!");
      setRemoveLogModal(false);
    }
    if (isRemoveOrganisationLogError && removeOrganisationLogError) {
      toast.error(removeOrganisationLogError?.data?.message);
    }
  }, [isRemoveOrganisationLogSuccess, isRemoveOrganisationLogError]);

  useEffect(() => {
    if (
      isGetInteractionTypesSuccess &&
      interactionTypeData?.interaction_types
    ) {
      let data = interactionTypeData.interaction_types.map((e) => {
        return { value: e.id, label: e.type };
      });
      setInteractionTypeOptions(data);
    }
    if (isGetInteractionTypesError && getInteractionTypesError) {
      toast.error(getInteractionTypesError?.data?.message);
    }
  }, [isGetInteractionTypesSuccess, isGetInteractionTypesError]);

  const onLogFormSubmit = (data) => {
    data.direction = data?.direction?.value;
    data.interaction_type = data?.interaction_type?.value;
    addOrganisationLog({
      ...data,
      organisation_id: organisation?.id,
    });
  };

  const onEditLogFormSubmit = (data) => {
    data.direction = data?.direction?.value;
    data.interaction_type = data?.interaction_type?.value;
    editOrganisationLog({
      ...data,
      organisation_id: organisation?.id,
    });
  };

  const onAddLogClicked = () => {
    getInteractionTypesOptions(undefined, true);
    resetLogForm();
    setLogModal(true);
  };

  const onEditLogClicked = (item) => {
    getInteractionTypesOptions(undefined, true);
    resetEditLogForm(
      {
        ...item,
        direction: {
          value: item.direction,
          label: capitalize(item.direction),
        },
        interaction_type: {
          value: item.interaction_type,
          label: item.interaction_type_name,
        },
      },
      {
        keepDefaultValues: true,
      }
    );
    setEditLogModal(true);
  };

  return (
    <div ref={(ref) => (sectionRef.current[10] = ref)}>
      <Block>
        <BlockHead>
          <BlockTitle tag="h5">
            Log
            <Button
              color="primary"
              outline
              size="xs"
              className="d-none d-sm-inline-flex ml-3"
              onClick={onAddLogClicked}
            >
              <Icon name="plus"></Icon>
              <span>Add log</span>
            </Button>
          </BlockTitle>
        </BlockHead>

        <Block>
          <BlockHead className="nk-block-head-line">
            <BlockTitle tag="h6" className="overline-title text-base">
              Log \ All
            </BlockTitle>
          </BlockHead>
          <SimpleBar className="w-100" forceVisible="x" autoHide={false}>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col" className="w-min-150px">
                    Date
                  </th>
                  <th scope="col" className="w-min-100px">
                    Time
                  </th>
                  <th scope="col" className="w-min-200px">
                    Duration
                  </th>
                  <th scope="col" className="w-min-200px">
                    Direction
                  </th>
                  <th scope="col" className="w-min-200px">
                    Interaction type
                  </th>
                  <th scope="col" className="w-min-200px">
                    Purpose
                  </th>
                  <th scope="col" className="w-min-150px">
                    Purpose ID
                  </th>
                  <th scope="col" className="w-min-250px">
                    Pipeline
                  </th>
                  <th scope="col" className="w-min-250px">
                    By who
                  </th>
                  <th scope="col" className="w-min-250px">
                    With who
                  </th>
                  <th scope="col" className="w-min-100px">
                    Follow up required
                  </th>
                  <th scope="col" className="w-min-250px">
                    Notes
                  </th>
                  <th scope="col" className="w-min-120px"></th>
                </tr>
              </thead>
              <tbody>
                {logs?.all?.map((e, idx) => {
                  return (
                    <tr key={`all-log-${e.id}`}>
                      <td>{moment(e.time).format("DD MMM YYYY")}</td>
                      <td>{moment(e.time).format("LT")}</td>
                      <td>{formatDuration(e?.duration)}</td>
                      <td>{e.direction}</td>
                      <td>{e.interaction_type_name}</td>
                      <td>{e.purpose}</td>
                      <td></td>
                      <td>{e.pipeline}</td>
                      <td>{e.created_by}</td>
                      <td>{e.with}</td>
                      <td>{e.follow_up_required ? "Yes" : "No"}</td>
                      <td className="white-space-pre-line">{e.notes}</td>
                      <td>
                        {e.is_editable ? (
                          <React.Fragment>
                            <Button
                              className="btn-icon"
                              id={`all-log-edit-btn-${e.id}`}
                              color="light"
                              size="xs"
                              onClick={() => {
                                onEditLogClicked(e);
                              }}
                            >
                              <Icon name="edit" />
                            </Button>
                            <UncontrolledTooltip
                              placement="top"
                              target={`all-log-edit-btn-${e.id}`}
                            >
                              Edit
                            </UncontrolledTooltip>
                            <Button
                              className="btn-icon ml-2"
                              id={`all-log-remove-btn-${e.id}`}
                              color="light"
                              size="xs"
                              onClick={() => {
                                setSelectedItem(e);
                                setRemoveLogModal(true);
                              }}
                            >
                              <Icon name="trash" />
                            </Button>
                            <UncontrolledTooltip
                              placement="top"
                              target={`all-log-remove-btn-${e.id}`}
                            >
                              Remove
                            </UncontrolledTooltip>
                          </React.Fragment>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </SimpleBar>
        </Block>
      </Block>

      <ModalComponent
        isOpen={isLogModalOpen}
        backdrop={"static"}
        title={"Add Log"}
        onModalCancel={() => {
          setLogModal(false);
        }}
      >
        <form onSubmit={handleLogFormSubmit(onLogFormSubmit)}>
          <Row className="gy-3">
            <SelectField
              name={"direction"}
              label={"Direction"}
              control={controlLogForm}
              // rules={{ required: "This field is required" }}
              isSearchable={false}
              options={logDirectionOptions}
              errors={errorsLogForm}
              md="12"
            />
            <SelectField
              name={"interaction_type"}
              label={"Interaction Type"}
              control={controlLogForm}
              // rules={{ required: "This field is required" }}
              options={interactionTypeOptions}
              isSearchable={false}
              isLoading={isGetInteractionTypesFetching}
              isDisabled={isGetInteractionTypesFetching}
              errors={errorsLogForm}
              md="12"
            />
            <TextInput
              name={"purpose"}
              label={"Purpose"}
              register={{
                ...registerLogForm("purpose", {}),
              }}
              errors={errorsLogForm}
              md="12"
            />
            <TextareaInput
              name={"notes"}
              label={"Notes"}
              required
              register={{
                ...registerLogForm("notes", {
                  required: "This field is required",
                }),
              }}
              errors={errorsLogForm}
              md="12"
            />
            <CheckboxInput
              name={"follow_up_required"}
              label={"Follow up required"}
              register={{
                ...registerLogForm("follow_up_required"),
              }}
              errors={errorsLogForm}
              md="12"
            />
            <Col size="12">
              <Button
                color="primary"
                type="submit"
                disabled={isAddOrganisationLogLoading}
              >
                {isAddOrganisationLogLoading && (
                  <Spinner type="grow" size="sm" />
                )}
                <span>Submit</span>
              </Button>
            </Col>
          </Row>
        </form>
      </ModalComponent>

      <ModalComponent
        isOpen={isEditLogModalOpen}
        backdrop={"static"}
        title={"Edit Log"}
        onModalCancel={() => {
          setEditLogModal(false);
        }}
      >
        <form onSubmit={handleEditLogFormSubmit(onEditLogFormSubmit)}>
          <Row className="gy-3">
            <SelectField
              name={"direction"}
              label={"Direction"}
              control={controlEditLogForm}
              // rules={{ required: "This field is required" }}
              isSearchable={false}
              options={logDirectionOptions}
              errors={errorsEditLogForm}
              md="12"
            />
            <SelectField
              name={"interaction_type"}
              label={"Interaction Type"}
              control={controlEditLogForm}
              // rules={{ required: "This field is required" }}
              options={interactionTypeOptions}
              isSearchable={false}
              isLoading={isGetInteractionTypesFetching}
              isDisabled={isGetInteractionTypesFetching}
              errors={errorsEditLogForm}
              md="12"
            />
            <TextInput
              name={"purpose"}
              label={"Purpose"}
              register={{
                ...registerEditLogForm("purpose", {}),
              }}
              errors={errorsEditLogForm}
              md="12"
            />
            <TextareaInput
              name={"notes"}
              label={"Notes"}
              required
              register={{
                ...registerEditLogForm("notes", {
                  required: "This field is required",
                }),
              }}
              errors={errorsEditLogForm}
              md="12"
            />
            <CheckboxInput
              name={"follow_up_required"}
              label={"Follow up required"}
              register={{
                ...registerEditLogForm("follow_up_required"),
              }}
              errors={errorsEditLogForm}
              md="12"
            />
            <Col size="12">
              <Button
                color="primary"
                type="submit"
                disabled={isEditOrganisationLogLoading}
              >
                {isEditOrganisationLogLoading && (
                  <Spinner type="grow" size="sm" />
                )}
                <span>Submit</span>
              </Button>
            </Col>
          </Row>
        </form>
      </ModalComponent>

      <ModalComponent
        isOpen={isRemoveLogModalOpen}
        title={"Are you sure you want to remove this log?"}
        disableCloseBtn={true}
        toggle={() => {
          setRemoveLogModal(false);
        }}
        onModalCancel={() => {
          setRemoveLogModal(false);
        }}
      >
        <Row className="gy-3">
          <Col size="12">
            <Button
              color="primary"
              type="submit"
              disabled={isRemoveOrganisationLogLoading}
              onClick={() => {
                removeOrganisationLog(selectedItem);
              }}
            >
              {isRemoveOrganisationLogLoading && (
                <Spinner type="grow" size="sm" />
              )}
              <span>Yes</span>
            </Button>
            <Button
              className="ml-3"
              color="light"
              onClick={() => {
                setRemoveLogModal(false);
              }}
            >
              <span>No</span>
            </Button>
          </Col>
        </Row>
      </ModalComponent>
    </div>
  );
};
