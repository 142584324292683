import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Icon,
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../../components/Components";
import { Breadcrumb, BreadcrumbItem, Card } from "reactstrap";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import moment from "moment";
import { useGetIntroductionFormQuery } from "../../redux/api/forms/formApi";

const IntroductionFormDetails = () => {
  let params = useParams();
  const navigate = useNavigate();
  const { data, isFetching, isSuccess, isError, error } =
    useGetIntroductionFormQuery({
      code: params.code,
    });

  return (
    <>
      <Head title="Introduction Form Detail"></Head>
      <Content>
        <BlockHead size="sm">
          <Breadcrumb
            tag="nav"
            listTag="div"
            className="breadcrumb-arrow fs-18px"
          >
            <BreadcrumbItem tag="a" href="../">
              Home
            </BreadcrumbItem>
            <BreadcrumbItem tag="a" href="./">
              Introduction Form
            </BreadcrumbItem>
            <BreadcrumbItem active>Detail</BreadcrumbItem>
          </Breadcrumb>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Introduction Form /{" "}
                <strong className="text-primary small">
                  {data?.reference_code}
                </strong>
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <Button
                color="light"
                outline
                className="bg-white d-none d-sm-inline-flex"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <Icon name="arrow-left"></Icon>
                <span>Back</span>
              </Button>
              <a
                href="#back"
                onClick={(ev) => {
                  ev.preventDefault();
                  navigate(-1);
                }}
                className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
              >
                <Icon name="arrow-left"></Icon>
              </a>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Card className="card-bordered">
            <div className="card-content">
              <div className="card-inner active">
                <Block>
                  <BlockHead>
                    <BlockTitle tag="h5">
                      Personal &amp; contact information
                    </BlockTitle>
                  </BlockHead>
                  <div className="profile-ud-list">
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">Email address</span>
                        <span className="profile-ud-value">
                          {data?.email ?? "N/A"}
                        </span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">First Name</span>
                        <span className="profile-ud-value">
                          {data?.first_name ?? "N/A"}
                        </span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">Last Name</span>
                        <span className="profile-ud-value">
                          {data?.last_name ?? "N/A"}
                        </span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">Mobile Number</span>
                        <span className="profile-ud-value">
                          {data?.phone_number
                            ? data?.country_calling_code + data?.phone_number
                            : "N/A"}
                        </span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">Date of Birth</span>
                        <span className="profile-ud-value">
                          {data?.date_of_birth
                            ? moment(data?.date_of_birth).format("MMMM D, YYYY")
                            : "N/A"}
                        </span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">
                          Able to travel independently
                        </span>
                        <span className="profile-ud-value">
                          {data?.is_travel_independently ?? "N/A"}
                        </span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">
                          Primary caregiver
                        </span>
                        <span className="profile-ud-value">
                          {data?.primary_caregiver ?? "N/A"}
                        </span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">Caregiver name</span>
                        <span className="profile-ud-value">
                          {data?.caregiver_first_name +
                            data?.caregiver_last_name ?? "N/A"}
                        </span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">
                          Caregiver mobile number
                        </span>
                        <span className="profile-ud-value">
                          {data?.caregiver_phone_number
                            ? data?.caregiver_country_calling_code +
                              data?.caregiver_phone_number
                            : "N/A"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="py-2">
                    <span className="profile-ud-label pb-2">
                      Why candidate want to work
                    </span>
                    <div className="bq-status">
                      <div className="bq-note-item">
                        <div className="bq-note-text">
                          <p>{data?.reason_to_work ?? "N/A"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="py-2">
                    <span className="profile-ud-label pb-2">
                      Hobbies &amp; interests
                    </span>
                    <div className="bq-status">
                      <div className="bq-note-item">
                        <div className="bq-note-text">
                          <p>{data?.interests ?? "N/A"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Block>
                <div className="nk-divider divider md" />
                <Block>
                  <BlockHead>
                    <BlockTitle tag="h5">Highest level of education</BlockTitle>
                  </BlockHead>
                  <div className="profile-ud-list">
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">
                          Highest level of education
                        </span>
                        <span className="profile-ud-value">
                          {data?.highest_education ?? "N/A"}
                        </span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">School</span>
                        <span className="profile-ud-value">
                          {data?.highest_education_school ?? "N/A"}
                        </span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">Course</span>
                        <span className="profile-ud-value">
                          {data?.highest_education_course ?? "N/A"}
                        </span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">From</span>
                        <span className="profile-ud-value">
                          {data?.time_start_highest_education
                            ? moment(data?.time_start_highest_education).format(
                                "MMMM YYYY"
                              )
                            : "N/A"}
                        </span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">To</span>
                        <span className="profile-ud-value">
                          {data?.time_end_highest_education
                            ? moment(data?.time_end_highest_education).format(
                                "MMMM YYYY"
                              )
                            : "N/A"}
                        </span>
                      </div>
                    </div>
                  </div>
                </Block>
                <div className="nk-divider divider md" />
                <Block>
                  <BlockHead>
                    <BlockTitle tag="h5">Recent employment</BlockTitle>
                  </BlockHead>
                  <div className="profile-ud-list">
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">Employer</span>
                        <span className="profile-ud-value">
                          {data?.recent_employer ?? "N/A"}
                        </span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">Job title/role</span>
                        <span className="profile-ud-value">
                          {data?.recent_job_role ?? "N/A"}
                        </span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">
                          First month of work
                        </span>
                        <span className="profile-ud-value">
                          {data?.recent_first_month_of_work
                            ? moment(data?.recent_first_month_of_work).format(
                                "MMMM YYYY"
                              )
                            : "N/A"}
                        </span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">
                          Last month of work
                        </span>
                        <span className="profile-ud-value">
                          {data?.recent_last_month_of_work
                            ? moment(data?.recent_last_month_of_work).format(
                                "MMMM YYYY"
                              )
                            : "N/A"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="py-2">
                    <span className="profile-ud-label pb-2">
                      Reason for leaving job
                    </span>
                    <div className="bq-status">
                      <div className="bq-note-item">
                        <div className="bq-note-text">
                          <p>{data?.reason_leaving_job ?? "N/A"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Block>
                <div className="nk-divider divider md" />
                <Block>
                  <BlockHead>
                    <BlockTitle tag="h5">Disability details</BlockTitle>
                  </BlockHead>
                  <div className="profile-ud-list">
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">Disability</span>
                        <span className="profile-ud-value">
                          {data?.disability_name ?? "N/A"}
                        </span>
                      </div>
                    </div>
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">
                          Year diagnosed disability
                        </span>
                        <span className="profile-ud-value">
                          {data?.disability_year_diagnosed
                            ? moment(data?.disability_year_diagnosed).format(
                                "YYYY"
                              )
                            : "N/A"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="py-2">
                    <span className="profile-ud-label pb-2">
                      More about candidate disability
                    </span>
                    <div className="bq-status">
                      <div className="bq-note-item">
                        <div className="bq-note-text">
                          <p>{data?.disability_description ?? "N/A"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="py-2">
                    <span className="profile-ud-label pb-2">
                      Ongoing therapy
                    </span>
                    <div className="bq-status">
                      <div className="bq-note-item">
                        <div className="bq-note-text">
                          <p>{data?.ongoing_therapy ?? "N/A"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="py-2">
                    <span className="profile-ud-label pb-2">
                      Medical history
                    </span>
                    <div className="bq-status">
                      <div className="bq-note-item">
                        <div className="bq-note-text">
                          <p>{data?.medical_history ?? "N/A"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Block>
                <div className="nk-divider divider md" />
                <Block>
                  <BlockHead>
                    <BlockTitle tag="h5">General</BlockTitle>
                  </BlockHead>
                  <div className="profile-ud-list">
                    <div className="profile-ud-item">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">
                          Who filled this form
                        </span>
                        <span className="profile-ud-value">
                          {data?.who_filled_form ?? "N/A"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="py-2">
                    <span className="profile-ud-label pb-2">
                      More things candidate want to share
                    </span>
                    <div className="bq-status">
                      <div className="bq-note-item">
                        <div className="bq-note-text">
                          <p>{data?.more_things_sharing ?? "N/A"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="py-2">
                    <span className="profile-ud-label pb-2">
                      How candidate know Inclus
                    </span>
                    <div className="bq-status">
                      <div className="bq-note-item">
                        <div className="bq-note-text">
                          <p>{data?.how_know_inclus ?? "N/A"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Block>
              </div>
            </div>
          </Card>
        </Block>
      </Content>
    </>
  );
};

export default IntroductionFormDetails;
