import { api } from "../api";

export const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/users",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          users: response.data,
          total: response.total,
        };
      },
      providesTags: (result, error, params) => [
        { type: "Users", id: params.user_type },
      ],
    }),
    getUser: builder.query({
      query: (data) => {
        return {
          url: "admin/v1/user",
          params: data,
        };
      },
      transformResponse: (response) => {
        return response.data[0];
      },
      providesTags: (result, error, data) => [{ type: "User", id: data.id }],
    }),
    createUser: builder.mutation({
      query: (data) => ({
        url: "admin/v1/user",
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => {
        return response.data[0];
      },
      invalidatesTags: (result, error, data) => [{ type: "Users" }],
    }),
    editUser: builder.mutation({
      query: (data) => ({
        url: "admin/v1/user",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, data) => [
        { type: "User", id: data.id },
        { type: "Logs", id: data.user_id },
      ],
    }),
    getUserLog: builder.query({
      query: (data) => {
        return {
          url: "admin/v1/user/log",
          params: data,
        };
      },
      transformResponse: (response) => {
        return response.data[0];
      },
      providesTags: (result, error, data) => [{ type: "Logs", id: data.id }],
    }),
    addUserLog: builder.mutation({
      query: (data) => ({
        url: "admin/v1/user/log",
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, error, data) => [
        { type: "Logs", id: data.user_id },
      ],
    }),
    editUserLog: builder.mutation({
      query: (data) => ({
        url: "admin/v1/user/log",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, data) => [
        { type: "Logs", id: data.user_id },
      ],
    }),
    removeUserLog: builder.mutation({
      query: (data) => ({
        url: "admin/v1/user/log",
        method: "DELETE",
        params: data,
      }),
      invalidatesTags: (result, error, data) => [
        { type: "Logs", id: data.user_id },
      ],
    }),
    getPipelineConfig: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/pipeline-config",
          params: params,
        };
      },
      transformResponse: (response) => {
        return response.data[0];
      },
    }),
    getUserAssessment: builder.query({
      query: (data) => {
        return {
          url: "admin/v1/user/assessment",
          params: data,
        };
      },
      transformResponse: (response) => {
        return response.data[0];
      },
      providesTags: (result, error, data) => [
        { type: "Assessment", id: data.id },
      ],
    }),
    getUserPipelines: builder.query({
      query: (data) => {
        return {
          url: "admin/v1/user/pipeline",
          params: data,
        };
      },
      transformResponse: (response) => {
        return response.data[0];
      },
      providesTags: (result, error, data) => [
        { type: "Pipelines", id: data.id },
      ],
    }),
    searchUsers: builder.query({
      query: (data) => {
        return {
          url: "admin/v1/users/search",
          params: data,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
    }),
    updatePipelineStage: builder.mutation({
      query: (data) => ({
        url: "admin/v1/user/pipeline",
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => {
        return response.data[0];
      },
      invalidatesTags: (result, error, data) => [
        { type: "Pipelines", id: data.user_id },
        { type: "Logs", id: data.user_id },
      ],
    }),
    createCandidate: builder.mutation({
      query: (data) => ({
        url: "admin/v1/candidate",
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => {
        return response.data[0];
      },
      invalidatesTags: (result, error, data) => [
        { type: "Users", id: "candidate" },
      ],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserQuery,
  useCreateUserMutation,
  useEditUserMutation,
  useGetUserAssessmentQuery,
  useGetUserLogQuery,
  useLazyGetUserLogQuery,
  useGetPipelineConfigQuery,
  useLazyGetPipelineConfigQuery,
  useUpdatePipelineStageMutation,
  useCreateCandidateMutation,
  useAddUserLogMutation,
  useEditUserLogMutation,
  useRemoveUserLogMutation,
  useGetUserPipelinesQuery,
  useSearchUsersQuery,
  useLazySearchUsersQuery,
} = usersApi;
