import { useForm } from "react-hook-form";
import {
  Block,
  BlockHead,
  BlockContent,
  BlockTitle,
  BlockDes,
  PreviewCard,
  Button,
  BlockHeadContent,
  Col,
  Row,
} from "../../components/Components";
import Head from "../../layout/head/Head";
import PageContainer from "../../layout/page-container/PageContainer";
import { Form, FormGroup, Spinner } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  useCreateIntroductionFormMutation,
  useLazyGetIntroductionFormQuery,
} from "../../redux/api/forms/formApi";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { stagingHostname } from "../../constants";
import Error404Modern from "../error/404-modern";

const IntroductionIndex = () => {
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [
    createForm,
    {
      data,
      isSuccess: isCreateSuccess,
      isError: isCreateError,
      isLoading: isCreateLoading,
      error: createError,
    },
  ] = useCreateIntroductionFormMutation();
  const [
    getForm,
    {
      data: formData,
      isFetching: isCheckFormFetching,
      isSuccess: isCheckFormSuccess,
      isError: isCheckFormError,
      error,
    },
  ] = useLazyGetIntroductionFormQuery();

  const onSubmit = (d) => {
    getForm({ code: d.reference_code }, true);
  };

  const onCreateClicked = () => {
    if (isCreateLoading) {
      return;
    }
    createForm();
  };

  useEffect(() => {
    if (isCreateSuccess && data) {
      if (data.reference_code) {
        navigate(`/introduction/${data.reference_code}`);
      }
    }
    if (isCreateError && createError) {
      toast.error(createError?.data?.message);
    }
  }, [isCreateSuccess, isCreateError]);

  useEffect(() => {
    if (isCheckFormSuccess && formData) {
      navigate(`/${formData.reference_code}`);
    }
    if (isCheckFormError && error) {
      toast.error(error.data.message);
    }
  }, [isCheckFormSuccess, isCheckFormError]);

  if (process.env.NODE_ENV === "production") {
    const hostname = window.location.hostname;
    if (hostname !== stagingHostname) {
      return <Error404Modern />;
    }
  }

  return (
    <>
      <Head title="Introduction Form" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body wide-xs">
          {/* <BlockTitle tag="h3" className="text-center mb-4">
            Inclus introduction form
          </BlockTitle> */}
          <PreviewCard
            className="card-bordered border-gray rounded-pill px-4 py-3"
            // bodyClass="card-inner-xl"
          >
            {/* <div className="fs-20px mb-4">
              <p className="form-note-s2 fw-medium">
                I want to start a new form
              </p>
            </div>
            <Button
              size="lg"
              className="btn-block"
              type="submit"
              color="primary"
              onClick={onCreateClicked}
            >
              START
            </Button>

            <div className="text-center pt-4 pb-3">
              <h5 className="overline-title overline-title-sap">
                <span>OR</span>
              </h5>
            </div> */}

            <Form className="is-alter" onSubmit={handleSubmit(onSubmit)}>
              <div className="fs-20px mb-3">
                <p className="form-note-s2 fw-medium mb-2">
                  Continue from your previous form
                </p>
                <p className="form-caption fs">
                  If you have previously saved and not submitted a form, you can
                  continue from your previous progress.
                </p>
              </div>
              <Row className="g-3">
                <Col md="7">
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      placeholder="your form reference code"
                      className="form-control-lg form-control"
                      {...register("reference_code", {
                        required: "This field is required",
                      })}
                    />
                    {errors && errors.reference_code && (
                      <span className="invalid">
                        {errors.reference_code.message}
                      </span>
                    )}
                  </div>
                </Col>
                <Col md="5">
                  <Button
                    size="lg"
                    className="btn-block btn-alt"
                    type="submit"
                    // color="primary"
                  >
                    CONTINUE
                  </Button>
                </Col>
              </Row>
            </Form>
          </PreviewCard>
        </Block>
      </PageContainer>
    </>
  );
};

export default IntroductionIndex;
