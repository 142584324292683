import { Icon, TooltipComponent } from "../../components/Components";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import { formatDate } from "../../utils/utils";

export const sexColumns = [
  {
    name: "Sex",
    selector: (row) => row.sex,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px" },
    cell: (row) => <span>{row.sex}</span>,
    sortable: true,
  },
  {
    name: "Created",
    selector: (row) => row.created_time,
    sortable: true,
    cell: (row) => <span>{formatDate(row.created_time)}</span>,
    hide: "md",
  },
  {
    name: "Updated",
    selector: (row) => row.last_updated_time,
    sortable: true,
    cell: (row) => <span>{formatDate(row.last_updated_time)}</span>,
    hide: "md",
  },
  {
    name: (
      <UncontrolledDropdown>
        <DropdownToggle
          tag="a"
          className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
        >
          <Icon name="more-h"></Icon>
        </DropdownToggle>
        <DropdownMenu right>
          <ul className="link-list-opt no-bdr">
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Selected</span>
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      data-tag="allowRowEvents"
                      data-action="edit"
                    >
                      <Icon
                        name="edit"
                        data-tag="allowRowEvents"
                        data-action="edit"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="edit">
                        Edit
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  },
];

export const religionColumns = [
  {
    name: "Religion",
    selector: (row) => row.religion,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px" },
    cell: (row) => <span>{row.religion}</span>,
    sortable: true,
  },
  {
    name: "Created",
    selector: (row) => row.created_time,
    sortable: true,
    cell: (row) => <span>{formatDate(row.created_time)}</span>,
    hide: "md",
  },
  {
    name: "Updated",
    selector: (row) => row.last_updated_time,
    sortable: true,
    cell: (row) => <span>{formatDate(row.last_updated_time)}</span>,
    hide: "md",
  },
  {
    name: (
      <UncontrolledDropdown>
        <DropdownToggle
          tag="a"
          className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
        >
          <Icon name="more-h"></Icon>
        </DropdownToggle>
        <DropdownMenu right>
          <ul className="link-list-opt no-bdr">
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Selected</span>
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      data-tag="allowRowEvents"
                      data-action="edit"
                    >
                      <Icon
                        name="edit"
                        data-tag="allowRowEvents"
                        data-action="edit"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="edit">
                        Edit
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  },
];

export const interactionTypeColumns = [
  {
    name: "Interaction Type",
    selector: (row) => row.type,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px" },
    cell: (row) => <span>{row.type}</span>,
    sortable: true,
  },
  {
    name: "Created",
    selector: (row) => row.created_time,
    sortable: true,
    cell: (row) => <span>{formatDate(row.created_time)}</span>,
    hide: "md",
  },
  {
    name: "Updated",
    selector: (row) => row.last_updated_time,
    sortable: true,
    cell: (row) => <span>{formatDate(row.last_updated_time)}</span>,
    hide: "md",
  },
  {
    name: (
      <UncontrolledDropdown>
        <DropdownToggle
          tag="a"
          className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
        >
          <Icon name="more-h"></Icon>
        </DropdownToggle>
        <DropdownMenu right>
          <ul className="link-list-opt no-bdr">
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Selected</span>
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      data-tag="allowRowEvents"
                      data-action="edit"
                    >
                      <Icon
                        name="edit"
                        data-tag="allowRowEvents"
                        data-action="edit"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="edit">
                        Edit
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  },
];

export const medicalTypeColumns = [
  {
    name: "Medical Type",
    selector: (row) => row.type,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px" },
    cell: (row) => <span>{row.type}</span>,
    sortable: true,
  },
  {
    name: "Created",
    selector: (row) => row.created_time,
    sortable: true,
    cell: (row) => <span>{formatDate(row.created_time)}</span>,
    hide: "md",
  },
  {
    name: "Updated",
    selector: (row) => row.last_updated_time,
    sortable: true,
    cell: (row) => <span>{formatDate(row.last_updated_time)}</span>,
    hide: "md",
  },
  {
    name: (
      <UncontrolledDropdown>
        <DropdownToggle
          tag="a"
          className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
        >
          <Icon name="more-h"></Icon>
        </DropdownToggle>
        <DropdownMenu right>
          <ul className="link-list-opt no-bdr">
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Selected</span>
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      data-tag="allowRowEvents"
                      data-action="edit"
                    >
                      <Icon
                        name="edit"
                        data-tag="allowRowEvents"
                        data-action="edit"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="edit">
                        Edit
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  },
];

export const raceColumns = [
  {
    name: "Race",
    selector: (row) => row.race,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px" },
    cell: (row) => <span>{row.race}</span>,
    sortable: true,
  },
  {
    name: "Created",
    selector: (row) => row.created_time,
    sortable: true,
    cell: (row) => <span>{formatDate(row.created_time)}</span>,
    hide: "md",
  },
  {
    name: "Updated",
    selector: (row) => row.last_updated_time,
    sortable: true,
    cell: (row) => <span>{formatDate(row.last_updated_time)}</span>,
    hide: "md",
  },
  {
    name: (
      <UncontrolledDropdown>
        <DropdownToggle
          tag="a"
          className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
        >
          <Icon name="more-h"></Icon>
        </DropdownToggle>
        <DropdownMenu right>
          <ul className="link-list-opt no-bdr">
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Selected</span>
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      data-tag="allowRowEvents"
                      data-action="edit"
                    >
                      <Icon
                        name="edit"
                        data-tag="allowRowEvents"
                        data-action="edit"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="edit">
                        Edit
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  },
];

export const educationLevelColumns = [
  {
    name: "Education Level",
    selector: (row) => row.level,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px" },
    cell: (row) => <span>{row.level}</span>,
    sortable: true,
  },
  {
    name: "Created",
    selector: (row) => row.created_time,
    sortable: true,
    cell: (row) => <span>{formatDate(row.created_time)}</span>,
    hide: "md",
  },
  {
    name: "Updated",
    selector: (row) => row.last_updated_time,
    sortable: true,
    cell: (row) => <span>{formatDate(row.last_updated_time)}</span>,
    hide: "md",
  },
  {
    name: (
      <UncontrolledDropdown>
        <DropdownToggle
          tag="a"
          className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
        >
          <Icon name="more-h"></Icon>
        </DropdownToggle>
        <DropdownMenu right>
          <ul className="link-list-opt no-bdr">
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Selected</span>
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      data-tag="allowRowEvents"
                      data-action="edit"
                    >
                      <Icon
                        name="edit"
                        data-tag="allowRowEvents"
                        data-action="edit"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="edit">
                        Edit
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  },
];

export const orgContactRoleColumns = [
  {
    name: "Role name",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px" },
    cell: (row) => <span>{row.name}</span>,
    sortable: true,
  },
  {
    name: "Created",
    selector: (row) => row.created_time,
    sortable: true,
    cell: (row) => <span>{formatDate(row.created_time)}</span>,
    hide: "md",
  },
  {
    name: "Updated",
    selector: (row) => row.last_updated_time,
    sortable: true,
    cell: (row) => <span>{formatDate(row.last_updated_time)}</span>,
    hide: "md",
  },
  {
    name: (
      <UncontrolledDropdown>
        <DropdownToggle
          tag="a"
          className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
        >
          <Icon name="more-h"></Icon>
        </DropdownToggle>
        <DropdownMenu right>
          <ul className="link-list-opt no-bdr">
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Selected</span>
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      data-tag="allowRowEvents"
                      data-action="edit"
                    >
                      <Icon
                        name="edit"
                        data-tag="allowRowEvents"
                        data-action="edit"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="edit">
                        Edit
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  },
];

export const orgSupportingDocTypeColumns = [
  {
    name: "Type",
    selector: (row) => row.type,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px" },
    cell: (row) => <span>{row.type}</span>,
    sortable: true,
  },
  {
    name: "Created",
    selector: (row) => row.created_time,
    sortable: true,
    cell: (row) => <span>{formatDate(row.created_time)}</span>,
    hide: "md",
  },
  {
    name: "Updated",
    selector: (row) => row.last_updated_time,
    sortable: true,
    cell: (row) => <span>{formatDate(row.last_updated_time)}</span>,
    hide: "md",
  },
  {
    name: (
      <UncontrolledDropdown>
        <DropdownToggle
          tag="a"
          className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
        >
          <Icon name="more-h"></Icon>
        </DropdownToggle>
        <DropdownMenu right>
          <ul className="link-list-opt no-bdr">
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Selected</span>
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      data-tag="allowRowEvents"
                      data-action="edit"
                    >
                      <Icon
                        name="edit"
                        data-tag="allowRowEvents"
                        data-action="edit"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="edit">
                        Edit
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  },
];

export const opportunityTypeColumns = [
  {
    name: "Type",
    selector: (row) => row.type,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px" },
    cell: (row) => <span>{row.type}</span>,
    sortable: true,
  },
  {
    name: "Created",
    selector: (row) => row.created_time,
    sortable: true,
    cell: (row) => <span>{formatDate(row.created_time)}</span>,
    hide: "md",
  },
  {
    name: "Updated",
    selector: (row) => row.last_updated_time,
    sortable: true,
    cell: (row) => <span>{formatDate(row.last_updated_time)}</span>,
    hide: "md",
  },
  {
    name: (
      <UncontrolledDropdown>
        <DropdownToggle
          tag="a"
          className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
        >
          <Icon name="more-h"></Icon>
        </DropdownToggle>
        <DropdownMenu right>
          <ul className="link-list-opt no-bdr">
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Selected</span>
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      data-tag="allowRowEvents"
                      data-action="edit"
                    >
                      <Icon
                        name="edit"
                        data-tag="allowRowEvents"
                        data-action="edit"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="edit">
                        Edit
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  },
];

export const opportunityContractTypeColumns = [
  {
    name: "Type",
    selector: (row) => row.type,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px" },
    cell: (row) => <span>{row.type}</span>,
    sortable: true,
  },
  {
    name: "Created",
    selector: (row) => row.created_time,
    sortable: true,
    cell: (row) => <span>{formatDate(row.created_time)}</span>,
    hide: "md",
  },
  {
    name: "Updated",
    selector: (row) => row.last_updated_time,
    sortable: true,
    cell: (row) => <span>{formatDate(row.last_updated_time)}</span>,
    hide: "md",
  },
  {
    name: (
      <UncontrolledDropdown>
        <DropdownToggle
          tag="a"
          className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
        >
          <Icon name="more-h"></Icon>
        </DropdownToggle>
        <DropdownMenu right>
          <ul className="link-list-opt no-bdr">
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Selected</span>
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      data-tag="allowRowEvents"
                      data-action="edit"
                    >
                      <Icon
                        name="edit"
                        data-tag="allowRowEvents"
                        data-action="edit"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="edit">
                        Edit
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  },
];

export const opportunityCommitmentTypeColumns = [
  {
    name: "Commitment",
    selector: (row) => row.type,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px" },
    cell: (row) => <span>{row.type}</span>,
    sortable: true,
  },
  {
    name: "Created",
    selector: (row) => row.created_time,
    sortable: true,
    cell: (row) => <span>{formatDate(row.created_time)}</span>,
    hide: "md",
  },
  {
    name: "Updated",
    selector: (row) => row.last_updated_time,
    sortable: true,
    cell: (row) => <span>{formatDate(row.last_updated_time)}</span>,
    hide: "md",
  },
  {
    name: (
      <UncontrolledDropdown>
        <DropdownToggle
          tag="a"
          className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
        >
          <Icon name="more-h"></Icon>
        </DropdownToggle>
        <DropdownMenu right>
          <ul className="link-list-opt no-bdr">
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Selected</span>
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      data-tag="allowRowEvents"
                      data-action="edit"
                    >
                      <Icon
                        name="edit"
                        data-tag="allowRowEvents"
                        data-action="edit"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="edit">
                        Edit
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  },
];

export const observationGradingColumns = [
  {
    name: "Observation Grading",
    selector: (row) => row.type,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px" },
    cell: (row) => <span>{row.observation_grading}</span>,
    sortable: true,
  },
  {
    name: "Created",
    selector: (row) => row.created_time,
    sortable: true,
    cell: (row) => <span>{formatDate(row.created_time)}</span>,
    hide: "md",
  },
  {
    name: "Updated",
    selector: (row) => row.last_updated_time,
    sortable: true,
    cell: (row) => <span>{formatDate(row.last_updated_time)}</span>,
    hide: "md",
  },
  {
    name: (
      <UncontrolledDropdown>
        <DropdownToggle
          tag="a"
          className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
        >
          <Icon name="more-h"></Icon>
        </DropdownToggle>
        <DropdownMenu right>
          <ul className="link-list-opt no-bdr">
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Selected</span>
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      data-tag="allowRowEvents"
                      data-action="edit"
                    >
                      <Icon
                        name="edit"
                        data-tag="allowRowEvents"
                        data-action="edit"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="edit">
                        Edit
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  },
];
