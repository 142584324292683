import LogoLight2x from "../../images/logo2x.png";
import LogoDark2x from "../../images/logo-dark2x.png";
import LogoSmall from "../../images/logo-small.png";
import TestLogo from "../../images/Vector.png";
import TestLogoSmall from "../../images/fav-icon.png";
import LogoLight from "../../images/logo-inclus.png";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <Link to="/" className="logo-link">
      <img
        className="logo-dark logo-img logo-img-lg"
        src={LogoLight}
        alt="logo"
      />
      {/* <img
        className="logo-small logo-img logo-img-small"
        src={TestLogoSmall}
        alt="logo"
      /> */}
      {/* <img className="logo-light logo-img" src={LogoLight2x} alt="logo" />
      <img className="logo-dark logo-img" src={LogoDark2x} alt="logo" />
      <img
        className="logo-small logo-img logo-img-small"
        src={LogoSmall}
        alt="logo"
      /> */}
    </Link>
  );
};

export default Logo;
