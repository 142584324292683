import { Icon, TooltipComponent } from "../../components/Components";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import moment from "moment";

export const workingDaysOptions = [
  {
    value: "0",
    label: "Sunday",
  },
  {
    value: "1",
    label: "Monday",
  },
  {
    value: "2",
    label: "Tuesday",
  },
  {
    value: "3",
    label: "Wednesday",
  },
  {
    value: "4",
    label: "Thursday",
  },
  {
    value: "5",
    label: "Friday",
  },
  {
    value: "6",
    label: "Saturday",
  },
];

export const employmentsColumns = [
  {
    name: "Email",
    selector: (row) => row.email,
    compact: true,
    // grow: 2,
    style: { paddingRight: "20px" },
    cell: (row) => <span>{row.email}</span>,
    sortable: true,
  },
  {
    name: "Workplace",
    selector: (row) => row.workplace_name,
    minWidth: "120px",
    cell: (row) => <span>{row.workplace_name}</span>,
    hide: 480,
  },
  {
    name: "Created",
    selector: (row) => row.created_time,
    minWidth: "120px",
    sortable: true,
    cell: (row) => (
      <span>{moment(row.created_time).format("DD-MM-YYYY, hh:mm:ss a")}</span>
    ),
    hide: "md",
  },
  {
    name: "Updated",
    selector: (row) => row.last_updated_time,
    minWidth: "120px",
    sortable: true,
    cell: (row) => (
      <span>
        {moment(row.last_updated_time).format("DD-MM-YYYY, hh:mm:ss a")}
      </span>
    ),
    hide: "md",
  },
  {
    name: (
      <UncontrolledDropdown>
        <DropdownToggle
          tag="a"
          className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
        >
          <Icon name="more-h"></Icon>
        </DropdownToggle>
        <DropdownMenu right>
          <ul className="link-list-opt no-bdr"></ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li className="nk-tb-action-hidden">
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-trigger btn-icon"
              id={"view" + row.id}
              icon="eye"
              direction="top"
              text="View Details"
              data-tag="allowRowEvents"
              data-action="details"
            />
          </li>
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#markasdone"
                      data-tag="allowRowEvents"
                      data-action="details"
                    >
                      <Icon
                        name="eye"
                        data-tag="allowRowEvents"
                        data-action="details"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="details">
                        View Details
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      data-tag="allowRowEvents"
                      data-action="edit"
                    >
                      <Icon
                        name="edit"
                        data-tag="allowRowEvents"
                        data-action="edit"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="edit">
                        Edit
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  },
];

export const workplacesColumns = [
  {
    name: "Name",
    selector: (row) => row.name,
    compact: true,
    // grow: 2,
    style: { paddingRight: "20px" },
    cell: (row) => <span>{row.name}</span>,
    sortable: true,
  },
  {
    name: "Address",
    selector: (row) => row.address,
    minWidth: "120px",
    cell: (row) => <span>{row.address}</span>,
    hide: 480,
  },
  {
    name: "Latitude",
    selector: (row) => row.latitude,
    minWidth: "120px",
    cell: (row) => <span>{row.latitude}</span>,
    hide: "md",
  },
  {
    name: "Longitude",
    selector: (row) => row.longitude,
    minWidth: "120px",
    cell: (row) => <span>{row.longitude}</span>,
    hide: "md",
  },
  {
    name: "Created",
    selector: (row) => row.created_time,
    minWidth: "120px",
    sortable: true,
    cell: (row) => (
      <span>{moment(row.created_time).format("DD-MM-YYYY, h:mm:ss a")}</span>
    ),
    hide: "md",
  },
  {
    name: "Updated",
    selector: (row) => row.last_updated_time,
    minWidth: "120px",
    sortable: true,
    cell: (row) => (
      <span>
        {moment(row.last_updated_time).format("D-MM-YYYY, h:mm:ss a")}
      </span>
    ),
    hide: "md",
  },
  {
    name: (
      <UncontrolledDropdown>
        <DropdownToggle
          tag="a"
          className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
        >
          <Icon name="more-h"></Icon>
        </DropdownToggle>
        <DropdownMenu right>
          <ul className="link-list-opt no-bdr">
            <li>
              <DropdownItem
                tag="a"
                href="#markasdone"
                onClick={(ev) => {
                  ev.preventDefault();
                  // selectorMarkAsDelivered();
                }}
              >
                <Icon name="truck"></Icon>
                <span>Mark As Delivered</span>
              </DropdownItem>
            </li>
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                  // selectorDeleteOrder();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Orders</span>
              </DropdownItem>
            </li>
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                  // selectorDeleteOrder();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Orders</span>
              </DropdownItem>
            </li>
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                  // selectorDeleteOrder();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Orders</span>
              </DropdownItem>
            </li>
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                  // selectorDeleteOrder();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Orders</span>
              </DropdownItem>
            </li>
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                  // selectorDeleteOrder();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Orders</span>
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li className="nk-tb-action-hidden">
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-trigger btn-icon"
              id={"view" + row.id}
              icon="eye"
              direction="top"
              text="View Details"
              data-tag="allowRowEvents"
              data-action="details"
            />
          </li>
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#markasdone"
                      data-tag="allowRowEvents"
                      data-action="details"
                    >
                      <Icon
                        name="eye"
                        data-tag="allowRowEvents"
                        data-action="details"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="details">
                        View Details
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  },
];

export const proSchedulesColumns = [
  {
    name: "Pro Email",
    selector: (row) => row.email,
    compact: true,
    // grow: 2,
    style: { paddingRight: "20px" },
    cell: (row) => <span>{row.email}</span>,
    sortable: true,
  },
  {
    name: "PWD Email",
    selector: (row) => row.employment_email,
    minWidth: "120px",
    cell: (row) => <span>{row.employment_email}</span>,
    hide: 480,
  },
  {
    name: "Created",
    selector: (row) => row.created_time,
    minWidth: "120px",
    sortable: true,
    cell: (row) => (
      <span>{moment(row.created_time).format("DD-MM-YYYY, h:mm:ss a")}</span>
    ),
    hide: "md",
  },
  {
    name: "Updated",
    selector: (row) => row.last_updated_time,
    minWidth: "120px",
    sortable: true,
    cell: (row) => (
      <span>
        {moment(row.last_updated_time).format("D-MM-YYYY, h:mm:ss a")}
      </span>
    ),
    hide: "md",
  },
  {
    name: (
      <UncontrolledDropdown>
        <DropdownToggle
          tag="a"
          className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
        >
          <Icon name="more-h"></Icon>
        </DropdownToggle>
        <DropdownMenu right>
          <ul className="link-list-opt no-bdr">
            <li>
              <DropdownItem
                tag="a"
                href="#markasdone"
                onClick={(ev) => {
                  ev.preventDefault();
                  // selectorMarkAsDelivered();
                }}
              >
                <Icon name="truck"></Icon>
                <span>Mark As Delivered</span>
              </DropdownItem>
            </li>
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                  // selectorDeleteOrder();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Orders</span>
              </DropdownItem>
            </li>
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                  // selectorDeleteOrder();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Orders</span>
              </DropdownItem>
            </li>
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                  // selectorDeleteOrder();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Orders</span>
              </DropdownItem>
            </li>
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                  // selectorDeleteOrder();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Orders</span>
              </DropdownItem>
            </li>
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                  // selectorDeleteOrder();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Orders</span>
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li className="nk-tb-action-hidden">
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-trigger btn-icon"
              id={"view" + row.id}
              icon="eye"
              direction="top"
              text="View Details"
              data-tag="allowRowEvents"
              data-action="details"
            />
          </li>
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#markasdone"
                      data-tag="allowRowEvents"
                      data-action="details"
                    >
                      <Icon
                        name="eye"
                        data-tag="allowRowEvents"
                        data-action="details"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="details">
                        View Details
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  },
];
