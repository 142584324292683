import { Icon, TooltipComponent } from "../../components/Components";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";

export const introductionFormsColumns = [
  {
    name: "Reference Code",
    selector: (row) => row.reference_code,
    compact: true,
    // grow: 2,
    style: { paddingRight: "20px" },
    cell: (row) => <span>{row.reference_code}</span>,
    sortable: true,
  },
  {
    name: "First name",
    selector: (row) => row.first_name,
    sortable: true,
    cell: (row) => <span>{row.first_name}</span>,
    hide: "md",
  },
  {
    name: "Last name",
    selector: (row) => row.last_name,
    sortable: true,
    cell: (row) => <span>{row.last_name}</span>,
    hide: "md",
  },
  {
    name: "Email",
    selector: (row) => row.email,
    sortable: true,
    cell: (row) => <span>{row.email}</span>,
    hide: "md",
  },
  {
    name: "Status",
    selector: (row) => row.is_completed,
    sortable: true,
    cell: (row) => (
      <span
        className={`fw-bold ${
          row.is_completed ? "text-success fs-bold" : "text-warning"
        }`}
      >
        {row.is_completed ? "Completed" : "In Progress"}
      </span>
    ),
    hide: "md",
  },
  {
    name: "Created",
    selector: (row) => row.created_time,
    sortable: true,
    cell: (row) => (
      <span>{moment(row.created_time).format("D-MM-YYYY, h:mm:ss a")}</span>
    ),
    hide: "md",
  },
  {
    name: "Updated",
    selector: (row) => row.last_updated_time,
    sortable: true,
    cell: (row) => (
      <span>
        {moment(row.last_updated_time).format("D-MM-YYYY, h:mm:ss a")}
      </span>
    ),
    hide: "md",
  },
  {
    name: (
      <UncontrolledDropdown>
        <DropdownToggle
          tag="a"
          className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
        >
          <Icon name="more-h"></Icon>
        </DropdownToggle>
        <DropdownMenu right>
          <ul className="link-list-opt no-bdr">
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                  // selectorDeleteOrder();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Forms</span>
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li className="nk-tb-action-hidden">
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-trigger btn-icon"
              id={"view" + row.id}
              icon="eye"
              direction="top"
              text="View Details"
              data-tag="allowRowEvents"
              data-action="details"
            />
          </li>
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#markasdone"
                      data-tag="allowRowEvents"
                      data-action="details"
                    >
                      <Icon
                        name="eye"
                        data-tag="allowRowEvents"
                        data-action="details"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="details">
                        View Details
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  },
];

export const assessmentFormsColumns = [
  {
    name: "Reference Code",
    selector: (row) => row.reference_code,
    compact: true,
    // grow: 2,
    style: { paddingRight: "20px" },
    cell: (row) => (
      <Link
        to={`/forms/assessment/${row.reference_code}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span>{row.reference_code}</span>
      </Link>
    ),
    sortable: true,
  },
  {
    name: "First name",
    selector: (row) => row.first_name,
    sortable: true,
    cell: (row) => <span>{row.first_name}</span>,
    hide: "md",
  },
  {
    name: "Last name",
    selector: (row) => row.last_name,
    sortable: true,
    cell: (row) => <span>{row.last_name}</span>,
    hide: "md",
  },
  {
    name: "Email",
    selector: (row) => row.email,
    sortable: true,
    cell: (row) => <span>{row.email}</span>,
    hide: "md",
  },
  {
    name: "Status",
    selector: (row) => row.is_completed,
    sortable: true,
    cell: (row) => (
      <span
        className={`fw-bold ${
          row.is_completed ? "text-success fs-bold" : "text-warning"
        }`}
      >
        {row.status}
      </span>
    ),
    hide: "md",
  },
  {
    name: "Created",
    selector: (row) => row.created_time,
    sortable: true,
    cell: (row) => (
      <span>{moment(row.created_time).format("D-MM-YYYY, h:mm:ss a")}</span>
    ),
    hide: "md",
  },
  {
    name: "Updated",
    selector: (row) => row.last_updated_time,
    sortable: true,
    cell: (row) => (
      <span>
        {moment(row.last_updated_time).format("D-MM-YYYY, h:mm:ss a")}
      </span>
    ),
    hide: "md",
  },
  {
    name: (
      <UncontrolledDropdown>
        <DropdownToggle
          tag="a"
          className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
        >
          <Icon name="more-h"></Icon>
        </DropdownToggle>
        <DropdownMenu right>
          <ul className="link-list-opt no-bdr">
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                  // selectorDeleteOrder();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Forms</span>
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li className="nk-tb-action-hidden">
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-trigger btn-icon"
              id={"view" + row.id}
              icon="eye"
              direction="top"
              text="View Details"
              data-tag="allowRowEvents"
              data-action="details"
            />
          </li>
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#markasdone"
                      data-tag="allowRowEvents"
                      data-action="details"
                    >
                      <Icon
                        name="eye"
                        data-tag="allowRowEvents"
                        data-action="details"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="details">
                        View Details
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  },
];

export const careersSubmissionsColumns = [
  {
    name: "Name",
    selector: (row) => row.name,
    compact: true,
    // grow: 2,
    style: { paddingRight: "20px" },
    cell: (row) => <span>{row.name}</span>,
    minWidth: "250px",
    sortable: true,
  },
  {
    name: "Email",
    selector: (row) => row.email,
    sortable: true,
    cell: (row) => <span>{row.email}</span>,
    minWidth: "250px",
    hide: "md",
  },
  {
    name: "Number",
    selector: (row) => row.number,
    sortable: true,
    cell: (row) => <span>{row.number}</span>,
    minWidth: "200px",
    hide: "md",
  },
  {
    name: "Resume/CV URL",
    selector: (row) => row.resume_url,
    sortable: true,
    cell: (row) => <span>{row.resume_url}</span>,
    minWidth: "200px",
    hide: "md",
  },
  {
    name: "Interested In",
    selector: (row) => row.interested_in,
    sortable: true,
    cell: (row) => <span>{row.interested_in}</span>,
    minWidth: "180px",
    hide: "md",
  },
  {
    name: "Message",
    selector: (row) => row.message,
    sortable: true,
    cell: (row) => <span className="text-cell">{row.message}</span>,
    minWidth: "200px",
    hide: "md",
  },
  {
    name: "Created",
    selector: (row) => row.created_time,
    sortable: true,
    cell: (row) => (
      <span>{moment(row.created_time).format("D-MM-YYYY, h:mm:ss a")}</span>
    ),
    hide: "md",
  },
  {
    name: (
      <UncontrolledDropdown>
        <DropdownToggle
          tag="a"
          className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
        >
          <Icon name="more-h"></Icon>
        </DropdownToggle>
        <DropdownMenu right>
          <ul className="link-list-opt no-bdr">
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                  // selectorDeleteOrder();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Submissions</span>
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li className="nk-tb-action-hidden">
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-trigger btn-icon"
              id={"view" + row.id}
              icon="eye"
              direction="top"
              text="View Details"
              data-tag="allowRowEvents"
              data-action="details"
            />
          </li>
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#markasdone"
                      data-tag="allowRowEvents"
                      data-action="details"
                    >
                      <Icon
                        name="eye"
                        data-tag="allowRowEvents"
                        data-action="details"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="details">
                        View Details
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  },
];

export const contactUsSubmissionsColumns = [
  {
    name: "Name",
    selector: (row) => row.name,
    compact: true,
    // grow: 2,
    style: { paddingRight: "20px" },
    cell: (row) => <span>{row.name}</span>,
    minWidth: "250px",
    sortable: true,
  },
  {
    name: "Email",
    selector: (row) => row.email,
    sortable: true,
    cell: (row) => <span>{row.email}</span>,
    minWidth: "250px",
    hide: "md",
  },
  {
    name: "Number",
    selector: (row) => row.number,
    sortable: true,
    cell: (row) => <span>{row.number}</span>,
    minWidth: "180px",
    hide: "md",
  },
  {
    name: "I am a",
    selector: (row) => row.iam,
    sortable: true,
    cell: (row) => <span>{row.iam}</span>,
    minWidth: "180px",
    hide: "md",
  },
  {
    name: "Type of enquiry",
    selector: (row) => row.enquiry_type,
    sortable: true,
    cell: (row) => <span>{row.enquiry_type}</span>,
    minWidth: "180px",
    hide: "md",
  },
  {
    name: "Message",
    selector: (row) => row.message,
    sortable: true,
    cell: (row) => <span className="text-cell">{row.message}</span>,
    minWidth: "180px",
    hide: "md",
  },
  {
    name: "Created",
    selector: (row) => row.created_time,
    sortable: true,
    cell: (row) => (
      <span>{moment(row.created_time).format("D-MM-YYYY, h:mm:ss a")}</span>
    ),
    minWidth: "120px",
    hide: "md",
  },
  {
    name: (
      <UncontrolledDropdown>
        <DropdownToggle
          tag="a"
          className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
        >
          <Icon name="more-h"></Icon>
        </DropdownToggle>
        <DropdownMenu right>
          <ul className="link-list-opt no-bdr">
            <li>
              <DropdownItem
                tag="a"
                href="#remove"
                onClick={(ev) => {
                  ev.preventDefault();
                  // selectorDeleteOrder();
                }}
              >
                <Icon name="trash"></Icon>
                <span>Remove Submissions</span>
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li className="nk-tb-action-hidden">
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-trigger btn-icon"
              id={"view" + row.id}
              icon="eye"
              direction="top"
              text="View Details"
              data-tag="allowRowEvents"
              data-action="details"
            />
          </li>
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#markasdone"
                      data-tag="allowRowEvents"
                      data-action="details"
                    >
                      <Icon
                        name="eye"
                        data-tag="allowRowEvents"
                        data-action="details"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="details">
                        View Details
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  },
];
