import { api } from "./api";
import { setCredentials } from "../features/authSlice";
import { updateChatState } from "../features/chat/chatSlice";

export const auth = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "admin/v1/login",
        method: "POST",
        body: credentials,
        credentials: "include",
      }),
      async onQueryStarted(token, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const response = await queryFulfilled;
          // onSuccess
          const { user, token } = response.data[0];
          dispatch(setCredentials({ user, token }));
          // dispatch(
          //   updateChatState({
          //     userID: user.agora_chat_user_id,
          //     chatToken: agora_user_token,
          //   })
          // );
        } catch (err) {}
      },
    }),
    logout: builder.mutation({
      query() {
        return {
          url: "admin/v1/logout",
          credentials: "include",
        };
      },
    }),
    getMe: builder.query({
      query: (token) => {
        return {
          url: "admin/v1/me",
          headers: {
            authorization: `Bearer ${token}`,
          },
        };
      },
      async onQueryStarted(token, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const response = await queryFulfilled;
          // onSuccess
          const data = response.data.data[0];
          dispatch(setCredentials({ user: data, token }));
          // dispatch(
          //   updateChatState({
          //     userID: data.agora_chat_user_id,
          //     chatToken: data.agora_user_token,
          //   })
          // );
        } catch (err) {}
      },
      providesTags: ["Account"],
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation, useGetMeQuery } = auth;
