import classNames from "classnames";
import { useEffect, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { Collapse } from "reactstrap";
import { TimePickerGroupInput } from "./TimePickerInput";
import { Col } from "../Components";

export const WorkingHoursField = ({
  className,
  variation,
  label,
  required,
  name,
  control,
  setValue,
  getValues,
  resetField,
  errors,
  item,
  md,
  ...props
}) => {
  const options = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const {
    fields: workinghoursFields,
    append: workinghoursAppend,
    remove: workinghoursRemove,
    update: workinghoursUpdate,
  } = useFieldArray({
    control,
    name: name,
  });
  const [isOpen, setIsOpen] = useState(null);
  const [sameEveryDay, setSameEveryDay] = useState(getValues("same_everyday"));
  const [optionStatesActive, setOptionStatesActive] = useState(() => {
    let arr = Array(options.length).fill(false);
    if (item) {
      for (var i = 0; i < item.working_days.length; i++) {
        if (item.working_days[i].day_of_week === 0) {
          arr[options.length - 1] = true;
          continue;
        }
        arr[item.working_days[i].day_of_week - 1] = true;
      }
    }

    return arr;
  });

  const toggleCollapse = (param) => {
    if (param === isOpen) {
      setIsOpen(null);
    } else {
      setIsOpen(param);
    }
  };

  useEffect(() => {
    if (item) {
      for (let i = 0; i < item.working_days.length; i++) {
        let workingTimeFromHour = Math.floor(
          item.working_days[i].working_time_from / 60
        );
        let workingTimeFromMinute = item.working_days[i].working_time_from % 60;
        let workingTimeToHour = Math.floor(
          item.working_days[i].working_time_to / 60
        );
        let workingTimeToMinute = item.working_days[i].working_time_to % 60;
        let lunchTimeFromHour = Math.floor(
          item.working_days[i].lunch_time_from / 60
        );
        let lunchTimeFromMinute = item.working_days[i].lunch_time_from % 60;
        let lunchTimeToHour = Math.floor(
          item.working_days[i].lunch_time_to / 60
        );
        let lunchTimeToMinute = item.working_days[i].lunch_time_to % 60;

        if (sameEveryDay) {
          workinghoursAppend({
            day_of_week: item.working_days[i].day_of_week,
            working_hour_start: new Date().setHours(
              workingTimeFromHour,
              workingTimeFromMinute
            ),
            working_hour_end: new Date().setHours(
              workingTimeToHour,
              workingTimeToMinute
            ),
            lunch_time_start: item.working_days[i].lunch_time_from,
            lunch_time_end: item.working_days[i].lunch_time_to,
          });
        } else {
          let idx = item.working_days[i].day_of_week - 1;
          if (idx === -1) {
            idx = options.length - 1;
          }
          setValue(
            `${name}.${idx}.working_hour_start`,
            new Date().setHours(workingTimeFromHour, workingTimeFromMinute)
          );
          setValue(
            `${name}.${idx}.working_hour_end`,
            new Date().setHours(workingTimeToHour, workingTimeToMinute)
          );
          setValue(
            `${name}.${idx}.lunch_time_start`,
            new Date().setHours(lunchTimeFromHour, lunchTimeFromMinute)
          );
          setValue(
            `${name}.${idx}.lunch_time_end`,
            new Date().setHours(lunchTimeToHour, lunchTimeToMinute)
          );

          // workinghoursUpdate(idx, {
          //   day_of_week: item.working_days[i].day_of_week,
          //   working_hour_start: new Date().setHours(
          //     workingTimeFromHour,
          //     workingTimeFromMinute
          //   ),
          //   working_hour_end: new Date().setHours(
          //     workingTimeToHour,
          //     workingTimeToMinute
          //   ),
          //   lunch_time_start: item.working_days[i].lunch_time_from,
          //   lunch_time_end: item.working_days[i].lunch_time_to,
          // });
        }
      }

      // if (sameEveryDay) {
      //   for (let i = 0; i < item.working_days.length; i++) {
      //     let workingTimeFromHour = Math.floor(
      //       item.working_days[i].working_time_from / 60
      //     );
      //     let workingTimeFromMinute =
      //       item.working_days[i].working_time_from % 60;
      //     let workingTimeToHour = Math.floor(
      //       item.working_days[i].working_time_to / 60
      //     );
      //     let workingTimeToMinute = item.working_days[i].working_time_to % 60;

      //     workinghoursAppend({
      //       day_of_week: item.working_days[i].day_of_week,
      //       working_hour_start: new Date().setHours(
      //         workingTimeFromHour,
      //         workingTimeFromMinute
      //       ),
      //       working_hour_end: new Date().setHours(
      //         workingTimeToHour,
      //         workingTimeToMinute
      //       ),
      //       lunch_time_start: item.working_days[i].lunch_time_from,
      //       lunch_time_end: item.working_days[i].lunch_time_to,
      //     });
      //   }
      // } else {
      //   for (let i = 0; i < item.working_days.length; i++) {
      //     workinghoursUpdate(item.working_days[i].day_of_week, {
      //       day_of_week: item.working_days[i].day_of_week,
      //       working_hour_start: item.working_days[i].working_time_from,
      //       working_hour_end: item.working_days[i].working_time_to,
      //       lunch_time_start: item.working_days[i].lunch_time_from,
      //       lunch_time_end: item.working_days[i].lunch_time_to,
      //     });
      //   }
      // }
    }
  }, [sameEveryDay]);

  return (
    <Col md={md ?? "6"}>
      <div className="form-group">
        <label className="form-label">Working Days</label>
        <div
          className="custom-control custom-checkbox custom-checkbox-left-label"
          style={{ display: "block", marginBottom: "1rem" }}
        >
          <input
            type="checkbox"
            className="custom-control-input form-control"
            id={`checkbox-field-${name}`}
            defaultChecked={getValues("same_everyday")}
            onChange={(ev) => {
              setValue(name, []);
              // resetField({
              //   [name]: [],
              // });
              setSameEveryDay(ev.target.checked);
              setValue("same_everyday", ev.target.checked);
              setValue("working_hour_start", "");
              setValue("working_hour_end", "");
            }}
          />
          <label
            className={"custom-control-label form-label custom-label-left"}
            style={{ fontSize: "0.9rem" }}
            htmlFor={`checkbox-field-${name}`}
          >
            Same every day
          </label>
        </div>

        {sameEveryDay ? (
          <div>
            <ul className="custom-control-group">
              {options.map((opt, idx) => {
                return (
                  <li key={`item-option-working-days-same-${idx}`}>
                    <div className="custom-control custom-checkbox custom-control-pro no-control checked">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        name={`btn-check-option-working-days-same-${idx}`}
                        id={`option-working-days-same-${idx}`}
                        defaultChecked={(() => {
                          if (item) {
                            var t = item.working_days.findIndex((item) => {
                              if (idx + 1 === 7) {
                                return item.day_of_week === 0;
                              } else {
                                return item.day_of_week === idx + 1;
                              }
                            });

                            if (t > -1) {
                              return true;
                            }

                            // for (var i = 0; i < item.working_days.length; i++) {
                            //   if (item.working_days[i].day_of_week === 0) {
                            //     return true;
                            //   }
                            //   if (
                            //     item.working_days[i].day_of_week ===
                            //     idx + 1
                            //   ) {
                            //     return true;
                            //   }
                            // }
                          }
                          return false;
                        })()}
                        onChange={(ev) => {
                          if (ev.target.checked) {
                            var w = {
                              day_of_week: idx + 1 === 7 ? 0 : idx + 1,
                            };
                            if (item) {
                              if (item.same_everyday && item.working_days) {
                                w = {
                                  ...w,
                                  working_hour_start: item.working_time_from,
                                  working_hour_end: item.working_time_to,
                                };
                              }
                            }

                            workinghoursAppend(w);
                          } else {
                            var i = workinghoursFields.findIndex((item) =>
                              idx + 1 === 7
                                ? item.day_of_week === 0
                                : item.day_of_week === idx + 1
                            );
                            workinghoursRemove(i);
                          }
                        }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={`option-working-days-same-${idx}`}
                      >
                        {opt}
                      </label>
                    </div>
                  </li>
                );
              })}
            </ul>
            <pre />
            <TimePickerGroupInput
              nameStart={"working_hour_start"}
              nameEnd={"working_hour_end"}
              label={"Working hours"}
              control={control}
              errors={errors}
              required={required}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Times"
              dateFormat="h:mm aa"
              md={"0"}
            />
            <pre />
            <TimePickerGroupInput
              nameStart={"lunch_time_start"}
              nameEnd={"lunch_time_end"}
              label={"Lunch Time"}
              control={control}
              errors={errors}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Times"
              dateFormat="h:mm aa"
              md={"0"}
            />
          </div>
        ) : (
          <div
            className={[
              `accordion accordion-s2${className ? " " + className : ""}`,
            ]}
          >
            {options.map((option, index) => {
              return (
                <div
                  className={classNames({
                    "accordion-item": true,
                    active: optionStatesActive[index],
                  })}
                  key={`accordion-${index}`}
                >
                  <div
                    className={[
                      `accordion-head${isOpen !== index ? " collapsed" : ""}`,
                    ]}
                  >
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={`customSwitch-${index}`}
                        defaultChecked={(() => {
                          if (item) {
                            for (var i = 0; i < item.working_days.length; i++) {
                              if (
                                item.working_days[i].day_of_week === 0 &&
                                index === options.length - 1
                              ) {
                                return true;
                              }
                              if (
                                item.working_days[i].day_of_week ===
                                index + 1
                              ) {
                                return true;
                              }
                            }
                          }
                          return false;
                        })()}
                        onChange={(ev) => {
                          setOptionStatesActive((prevState) => {
                            const newState = [...prevState];
                            if (ev.target.checked) {
                              newState[index] = true;
                            } else {
                              newState[index] = false;
                            }
                            return newState;
                          });

                          if (ev.target.checked) {
                            // workinghoursAppend({
                            //   working_hour_start: null,
                            //   working_hour_end: null,
                            // });
                            // workinghoursUpdate(idx - 1, {
                            //   ...workinghoursFields[idx - 1],
                            //   working_hour_start: null,
                            //   working_hour_end: null,
                            // });

                            toggleCollapse(index);
                          } else {
                            // let idx = workinghoursFields.findIndex((item) => {
                            //   if (index + 1 === 7) {
                            //     return item.day_of_week === 0;
                            //   } else {
                            //     return item.day_of_week === index + 1;
                            //   }
                            // });

                            // console.log(idx);
                            workinghoursUpdate(index, {
                              ...workinghoursFields[index],
                              working_hour_start: "",
                              working_hour_end: "",
                              lunch_time_start: "",
                              lunch_time_end: "",
                            });

                            // setValue(`${name}.${idx}.working_hour_start`, "");
                            // setValue(`${name}.${idx}.working_hour_end`, "");

                            // console.log(workinghoursFields);

                            if (isOpen === index) {
                              toggleCollapse(index);
                            }
                          }
                        }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={`customSwitch-${index}`}
                      ></label>
                    </div>
                    <h6
                      className="title"
                      onClick={() => {
                        if (optionStatesActive[index]) {
                          toggleCollapse(index);
                        }
                      }}
                    >
                      {option}
                    </h6>

                    {optionStatesActive[index] && (
                      <span
                        className="accordion-icon"
                        onClick={() => {
                          if (optionStatesActive[index]) {
                            toggleCollapse(index);
                          }
                        }}
                      />
                    )}
                  </div>
                  <Collapse
                    className="accordion-body"
                    isOpen={isOpen === index ? true : false}
                  >
                    <div className="accordion-inner">
                      <TimePickerGroupInput
                        nameStart={`${name}.${index}.working_hour_start`}
                        nameEnd={`${name}.${index}.working_hour_end`}
                        label={"Working Hours"}
                        control={control}
                        errors={errors}
                        required={required && optionStatesActive[index]}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Times"
                        dateFormat="h:mm aa"
                        md={"12"}
                      />
                      <br />
                      <TimePickerGroupInput
                        nameStart={`${name}.${index}.lunch_time_start`}
                        nameEnd={`${name}.${index}.lunch_time_end`}
                        label={"Lunch Time"}
                        control={control}
                        errors={errors}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Times"
                        dateFormat="h:mm aa"
                        md={"12"}
                      />
                    </div>
                  </Collapse>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </Col>
  );
};
