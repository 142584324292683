import axios from "axios";
// import uuid from "uuid/v4";

const RequestMethod = {
  Get: "GET",
  Post: "POST",
  Put: "PUT",
  Delete: "DELETE",
  Options: "OPTIONS",
  Head: "HEAD",
  Patch: "PATCH",
};

export async function get(endpoint, params, requestConfig) {
  const paramsConfig = params ? { params } : undefined;

  return _request(
    {
      url: endpoint,
      method: RequestMethod.Get,
    },
    {
      ...paramsConfig,
      ...requestConfig,
    }
  );
}

export async function post(endpoint, data) {
  const config = data ? { data } : undefined;

  return _request(
    {
      url: endpoint,
      method: RequestMethod.Post,
    },
    config
  );
}

export async function put(endpoint, data) {
  const config = data ? { data } : undefined;

  return _request(
    {
      url: endpoint,
      method: RequestMethod.Put,
    },
    config
  );
}

export async function del(endpoint, data) {
  const config = data ? { data } : undefined;

  return _request(
    {
      url: endpoint,
      method: RequestMethod.Delete,
    },
    config
  );
}

async function _request(restRequest, config) {
  if (!Boolean(restRequest.url)) {
    console.error(
      `Received ${restRequest.url} which is invalid for a endpoint url`
    );
  }

  try {
    const axiosRequestConfig = {
      ...config,
      method: restRequest.method,
      url: restRequest.url,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        ...config?.headers,
      },
    };

    const [axiosResponse] = await Promise.all([
      axios(axiosRequestConfig),
      _delay(),
    ]);
    const { status, data, request } = axiosResponse;

    if (data.success === false) {
      return _fillInErrorWithDefaults(
        {
          status,
          message: data.errors.join(" - "),
          errors: data.errors,
          url: request ? request.responseURL : restRequest.url,
          raw: axiosResponse,
        },
        restRequest
      );
    }

    return {
      ...axiosResponse,
    };
  } catch (error) {
    if (error.response) {
      const { status, statusText, data } = error.response;
      const errors = data.hasOwnProperty("errors")
        ? [statusText, ...data.errors]
        : [statusText];

      return _fillInErrorWithDefaults(
        {
          status,
          message: errors.filter(Boolean).join(" - "),
          errors,
          url: error.request.responseURL,
          raw: error.response,
        },
        restRequest
      );
    } else if (error.request) {
      const { status, statusText, responseURL } = error.request;

      return _fillInErrorWithDefaults(
        {
          status,
          message: statusText,
          errors: [statusText],
          url: responseURL,
          raw: error.request,
        },
        restRequest
      );
    }

    return _fillInErrorWithDefaults(
      {
        status: 0,
        message: error.message,
        errors: [error.message],
        url: restRequest.url,
        raw: error,
      },
      restRequest
    );
  }
}

function _fillInErrorWithDefaults(error, request) {
  const model = new HttpErrorResponseModel();

  model.status = error.status || 0;
  model.message = error.message || "Error requesting data";
  model.errors = error.errors.length ? error.errors : ["Error requesting data"];
  model.url = error.url || request.url;
  model.raw = error.raw;

  // Remove anything with undefined or empty strings.
  model.errors = model.errors.filter(Boolean);

  return model;
}

function _delay(duration = 2000) {
  return new Promise((resolve) => setTimeout(resolve, duration));
}

class HttpErrorResponseModel {
  //   id = uuid();
  status = 0;
  message = "";
  errors = [];
  url = "";
  raw = null;
}

export class Uploader {
  constructor(options) {
    this.chunkSize = options.chunkSize || 1024 * 1024 * 5;
    this.threadsQuantity = Math.min(options.threadsQuantity || 5, 15);
    this.userId = options.userId;
    this.organisationId = options.organisationId;
    this.id = options.id;
    this.name = options.name;
    this.type = options.type;
    this.description = options.description;
    this.date_effective_from = options.date_effective_from;
    this.date_effective_to = options.date_effective_to;
    this.status = options.status;
    this.file = options.file;
    this.fileName = options.fileName;
    this.aborted = false;
    this.uploadedSize = 0;
    this.progressCache = {};
    this.activeConnections = {};
    this.parts = [];
    this.uploadedParts = [];
    this.fileId = null;
    this.fileKey = null;
    this.fileType = options.fileType;
    this.uploadCategory = options.uploadCategory || null;
    this.objectUrl = null;
    this.onProgressFn = () => {};
    this.onErrorFn = () => {};
    // this.dispatch = () => {}
    this.initializeMultipartUpload =
      options.initializeMultipartUpload || (async () => {});
    this.finalizationMultipartUpload =
      options.finalizationMultipartUpload || (async () => {});
  }

  // starting the multipart upload request
  start() {
    this.initialize();
  }

  async initialize() {
    try {
      // adding the the file extension (if present) to fileName
      let fileName = this.fileName;
      // const ext = this.file.name.split(".").pop();
      // if (ext) {
      //   fileName += `.${ext}`;
      // }

      // initializing the multipart request
      const numberOfparts = Math.ceil(this.file.size / this.chunkSize);

      const initializationUploadInput = {
        file_type: this.fileType,
        file_name: fileName,
        parts: numberOfparts,
      };
      const initializeReponse = await this.initializeMultipartUpload(
        initializationUploadInput
      );

      // const initializeReponse = await
      // const t = upload.endpoints.initializeMultipartUpload.useMutation()

      // const AWSFileDataOutput = initializeReponse.data;

      this.fileId = initializeReponse.data.upload_id;
      this.fileKey = initializeReponse.data.key;
      this.objectUrl = initializeReponse.data.object_url;

      // retrieving the pre-signed URLs

      // const AWSMultipartFileDataInput = {
      //   fileId: this.fileId,
      //   fileKey: this.fileKey,
      //   parts: numberOfparts,
      // };

      // const urlsResponse = await api.request({
      //   url: "/uploads/getMultipartPreSignedUrls",
      //   method: "POST",
      //   data: AWSMultipartFileDataInput,
      // });

      const newParts = initializeReponse.data.parts;
      this.parts.push(...newParts);

      this.sendNext();
    } catch (error) {
      await this.complete(error);
    }
  }

  sendNext() {
    const activeConnections = Object.keys(this.activeConnections).length;

    if (activeConnections >= this.threadsQuantity) {
      return;
    }

    if (!this.parts.length) {
      if (!activeConnections) {
        this.complete();
      }

      return;
    }

    const part = this.parts.pop();

    if (this.file && part) {
      const sentSize = (part.part_number - 1) * this.chunkSize;
      const chunk = this.file.slice(sentSize, sentSize + this.chunkSize);

      const sendChunkStarted = () => {
        this.sendNext();
      };

      this.sendChunk(chunk, part, sendChunkStarted)
        .then(() => {
          this.sendNext();
        })
        .catch((error) => {
          this.parts.push(part);

          this.complete(error);
        });
    }
  }

  // terminating the multipart upload request on success or failure
  async complete(error) {
    if (error && !this.aborted) {
      this.onErrorFn(error);
      return;
    }

    if (error) {
      this.onErrorFn(error);
      return;
    }

    try {
      await this.sendCompleteRequest();
    } catch (error) {
      this.onErrorFn(error);
    }
  }

  // finalizing the multipart upload request on success by calling
  // the finalization API
  async sendCompleteRequest() {
    // const [finalizationMultipartUpload] = useFinalizeMultipartUploadMutation();
    // const finalizationMultipartUpload =
    //   upload.endpoints.finalizeMultipartUpload.useMutation();

    if (this.fileId && this.fileKey) {
      const finalizationMultiPartInput = {
        id: this.id,
        user_id: this.userId,
        organisation_id: this.organisationId,
        name: this.name,
        type: this.type,
        description: this.description,
        date_effective_from: this.date_effective_from,
        date_effective_to: this.date_effective_to,
        status: this.status,
        upload_id: this.fileId,
        key: this.fileKey,
        parts: this.uploadedParts,
        object_url: this.objectUrl,
        category: this.uploadCategory,
        file_name: this.fileName,
      };

      // await api.request({
      //   url: "/uploads/finalizeMultipartUpload",
      //   method: "POST",
      //   data: finalizationMultiPartInput,
      // });

      await this.finalizationMultipartUpload(finalizationMultiPartInput);
    }
  }

  sendChunk(chunk, part, sendChunkStarted) {
    return new Promise((resolve, reject) => {
      this.upload(chunk, part, sendChunkStarted)
        .then((status) => {
          if (status !== 200) {
            reject(new Error("Failed chunk upload"));
            return;
          }

          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // calculating the current progress of the multipart upload request
  handleProgress(part, event) {
    if (this.file) {
      if (
        event.type === "progress" ||
        event.type === "error" ||
        event.type === "abort"
      ) {
        this.progressCache[part] = event.loaded;
      }

      if (event.type === "uploaded") {
        this.uploadedSize += this.progressCache[part] || 0;
        delete this.progressCache[part];
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((memo, id) => (memo += this.progressCache[id]), 0);

      const sent = Math.min(this.uploadedSize + inProgress, this.file.size);

      const total = this.file.size;

      const percentage = Math.round((sent / total) * 100);

      this.onProgressFn({
        sent: sent,
        total: total,
        percentage: percentage,
      });
    }
  }

  // uploading a part through its pre-signed URL
  upload(file, part, sendChunkStarted) {
    // uploading each part with its pre-signed URL
    return new Promise((resolve, reject) => {
      if (this.fileId && this.fileKey) {
        // - 1 because PartNumber is an index starting from 1 and not 0
        const xhr = (this.activeConnections[part.part_number - 1] =
          new XMLHttpRequest());

        sendChunkStarted();

        const progressListener = this.handleProgress.bind(
          this,
          part.part_number - 1
        );

        xhr.upload.addEventListener("progress", progressListener);

        xhr.addEventListener("error", progressListener);
        xhr.addEventListener("abort", progressListener);
        xhr.addEventListener("loadend", progressListener);

        xhr.open("PUT", part.url);

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            // retrieving the ETag parameter from the HTTP headers
            const ETag = xhr.getResponseHeader("ETag");

            if (ETag) {
              const uploadedPart = {
                PartNumber: part.part_number,
                // removing the " enclosing carachters from
                // the raw ETag
                ETag: ETag.replaceAll('"', ""),
              };

              this.uploadedParts.push(uploadedPart);

              resolve(xhr.status);
              delete this.activeConnections[part.part_number - 1];
            }
          }
        };

        xhr.onerror = (error) => {
          reject(error);
          delete this.activeConnections[part.part_number - 1];
        };

        xhr.onabort = () => {
          reject(new Error("Upload canceled by user"));
          delete this.activeConnections[part.part_number - 1];
        };

        xhr.send(file);
      }
    });
  }

  onProgress(onProgress) {
    this.onProgressFn = onProgress;
    return this;
  }

  onError(onError) {
    this.onErrorFn = onError;
    return this;
  }

  abort() {
    Object.keys(this.activeConnections)
      .map(Number)
      .forEach((id) => {
        this.activeConnections[id].abort();
      });

    this.aborted = true;
  }
}
