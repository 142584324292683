import { Modal, ModalBody } from "reactstrap";
import { Icon } from "../Components";

const ModalComponent = ({
  isOpen,
  toggle,
  backdrop,
  disableCloseBtn,
  size,
  ...props
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      backdrop={backdrop}
      className="modal-dialog-centered"
      size={size ?? "lg"}
    >
      <ModalBody>
        {disableCloseBtn ? null : (
          <a href="#" className="close">
            {" "}
            <Icon
              name="cross-sm"
              onClick={(ev) => {
                ev.preventDefault();
                //   dispatch(props.onModalCancel());
                props.onModalCancel();
              }}
            ></Icon>
          </a>
        )}
        <div>
          <h5 className="title">{props.title}</h5>
          <div className="mt-4">{props.children}</div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalComponent;
