import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  offset: 0,
  itemsPerPage: 10,
  modal: {
    add: false,
    details: false,
    item: null,
  },
  selectedRows: null,
  clearSelectedRows: false,
};

export const quotesSlice = createSlice({
  name: "quotes",
  initialState: initialState,
  reducers: {
    onChangeClearSelectedRows(state, action) {
      const clearSelectedRows = action.payload;
      if (clearSelectedRows === true) {
        return {
          ...state,
          clearSelectedRows: clearSelectedRows,
          selectedRows: null,
        };
      }
      return { ...state, clearSelectedRows: clearSelectedRows };
    },
    onSelectedRowsChange(state, action) {
      const selectedRows = action.payload;
      return { ...state, selectedRows: selectedRows };
    },
    onChangePageState(state, action) {
      const { offset, itemsPerPage } = action.payload;
      return {
        ...state,
        offset,
        itemsPerPage,
      };
    },
    onFormCancel(state, action) {
      return {
        ...state,
        modal: initialState.modal,
      };
    },
    toggleModal(state, action) {
      const { type, item } = action.payload;
      state.modal[type] = !state.modal[type];
      state.modal.item = item;
    },
  },
});

export const {
  onChangePageState,
  onFormCancel,
  toggleModal,
  onSelectedRowsChange,
  onChangeClearSelectedRows,
} = quotesSlice.actions;

export default quotesSlice.reducer;
