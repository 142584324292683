import { api } from "../api";

export const goalApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getGoals: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/goals",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          goals: response.data,
          total: response.total,
        };
      },
      providesTags: ["Goals"],
    }),
    createGoal: builder.mutation({
      query: (data) => ({
        url: "admin/v1/goals",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Goals"],
    }),
    updateGoal: builder.mutation({
      query: ({ params, data }) => {
        return {
          url: "admin/v1/goals",
          method: "PUT",
          params: params,
          body: data,
        };
      },
      invalidatesTags: ["Goals"],
    }),
    deleteGoal: builder.mutation({
      query: (params) => ({
        url: "admin/v1/goals",
        method: "DELETE",
        params: params,
      }),
      invalidatesTags: ["Goals"],
    }),
    getGoalFrequencies: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/goal-frequency",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          goalFrequencies: response.data,
          total: response.total,
        };
      },
      providesTags: ["Goal-Frequency"],
    }),
  }),
});

export const {
  useGetGoalsQuery,
  useCreateGoalMutation,
  useUpdateGoalMutation,
  useDeleteGoalMutation,
  useGetGoalFrequenciesQuery,
  useLazyGetGoalFrequenciesQuery,
} = goalApi;
