import { useDispatch, useSelector } from "react-redux";
import { useState, useLayoutEffect } from "react";
import ReactDataTable from "../../components/table/ReactDataTable";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
} from "../../components/Components";
import { onChangePageState } from "../../redux/features/goalSlice";
import { useGetGoalFrequenciesQuery } from "../../redux/api/goal-management/goalApi";
import { goalFrequencyColumns } from "./DataColumns";

const GoalFrequency = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.goalFrequency);
  const { data, isFetching, isSuccess } = useGetGoalFrequenciesQuery({
    offset: state.offset,
    limit: state.itemsPerPage,
    total: true,
  });
  const [tableData, setTableData] = useState(data?.goalFrequencies ?? []);
  const [totalItems, setTotalItems] = useState(data?.total ?? 0);

  const changePageState = ({ offset = 0, itemsPerPage }) => {
    dispatch(onChangePageState({ offset, itemsPerPage: itemsPerPage }));
  };

  useLayoutEffect(() => {
    if (data) {
      setTableData(data.goalFrequencies);
      setTotalItems(data.total);
    }
  }, [data]);

  return (
    <>
      <Head title="Goal"></Head>
      <Content>
        <Block>
          <BlockHead>
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Goal</BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <Button
                    className="toggle btn-icon d-md-none"
                    color="primary"
                    onClick={() => {
                      //   toggle("add");
                    }}
                  >
                    <Icon name="plus"></Icon>
                  </Button>
                  <Button
                    className="toggle d-none d-md-inline-flex"
                    color="primary"
                    onClick={() => {
                      //   toggle("add");
                    }}
                  >
                    <Icon name="plus"></Icon>
                    <span>Add Goal</span>
                  </Button>
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <PreviewCard>
            <ReactDataTable
              data={tableData}
              columns={goalFrequencyColumns}
              offset={state.offset}
              itemsPerPage={state.itemsPerPage}
              pagination
              totalItems={totalItems}
              progressPending={isFetching}
              className="nk-tb-list"
              selectableRows
              changePageState={changePageState}
            />
          </PreviewCard>
        </Block>
      </Content>
    </>
  );
};

export default GoalFrequency;
