import { api } from "../api";

export const configDataApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getFormEmailSending: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/config/form-email-sending",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          emails: response.data,
          total: response.total,
        };
      },
      providesTags: ["Email-Sending"],
    }),
    createFormEmailSending: builder.mutation({
      query: (data) => ({
        url: "admin/v1/config/form-email-sending",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Email-Sending"],
    }),
    updateFormEmailSending: builder.mutation({
      query: ({ params, data }) => {
        return {
          url: "admin/v1/config/form-email-sending",
          method: "PUT",
          params: params,
          body: data,
        };
      },
      invalidatesTags: ["Email-Sending"],
    }),
    getMenuData: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/config/menu-data",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          data: response.data,
          total: response.total,
        };
      },
      providesTags: ["Menu-Data"],
    }),
    createMenuData: builder.mutation({
      query: (data) => ({
        url: "admin/v1/config/menu-data",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Menu-Data"],
    }),
    updateMenuData: builder.mutation({
      query: ({ params, data }) => {
        return {
          url: "admin/v1/config/menu-data",
          method: "PUT",
          params: params,
          body: data,
        };
      },
      invalidatesTags: ["Menu-Data"],
    }),
    deleteMenuData: builder.mutation({
      query: (params) => ({
        url: "admin/v1/config/menu-data",
        method: "DELETE",
        params: params,
      }),
      invalidatesTags: ["Menu-Data"],
    }),
  }),
});

export const {
  useGetFormEmailSendingQuery,
  useCreateFormEmailSendingMutation,
  useUpdateFormEmailSendingMutation,
  useGetMenuDataQuery,
  useLazyGetMenuDataQuery,
  useCreateMenuDataMutation,
  useUpdateMenuDataMutation,
  useDeleteMenuDataMutation,
} = configDataApi;
