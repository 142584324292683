import { useDispatch, useSelector } from "react-redux";
import { useLayoutEffect, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  onChangePageState,
  toggleModal,
  onFormCancel,
} from "../../redux/features/proSchedulesSlice";
import {
  useCreateProScheduleMutation,
  useGetProSchedulesQuery,
} from "../../redux/api/employment-management/employmentApi";
import ReactDataTable from "../../components/table/ReactDataTable";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { Spinner, Toast, ToastHeader, ToastBody, Alert } from "reactstrap";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
  Row,
  Col,
  ModalComponent,
  TextInput,
  SelectField,
} from "../../components/Components";
import { proSchedulesColumns, workingDaysOptions } from "./DataColumns";
import { toast } from "react-toastify";

const ProSchedules = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.proSchedules);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm();
  const { data, isFetching, isSuccess } = useGetProSchedulesQuery({
    offset: state.offset,
    limit: state.itemsPerPage,
    total: true,
    reverse: true,
  });
  const [tableData, setTableData] = useState(data?.proSchedules ?? []);
  const [totalItems, setTotalItems] = useState(data?.total ?? 0);
  const [
    createProSchedule,
    {
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess,
      isError: isCreateError,
      error: createError,
    },
  ] = useCreateProScheduleMutation();
  const defaultValue = {
    email: "",
    employment_email: "",
    working_days: [],
  };

  const changePageState = ({ offset = 0, itemsPerPage }) => {
    dispatch(onChangePageState({ offset, itemsPerPage: itemsPerPage }));
  };

  useLayoutEffect(() => {
    if (data) {
      setTableData(data.proSchedules);
      setTotalItems(data.total);
    }
  }, [data]);

  const onFormSubmit = (data) => {
    data.working_days = data.working_days.map((item, index) => {
      return { weekday: parseInt(item.value) };
    });
    createProSchedule(data);
  };

  useEffect(() => {
    if (isCreateSuccess) {
      dispatch(toggleModal({ type: "add" }));
      reset(defaultValue);
    }
    if (isCreateError && createError) {
      toast.error(createError?.data?.message);
    }
  }, [isCreateSuccess, isCreateError]);

  const onAddBtnClicked = (e) => {
    dispatch(toggleModal({ type: "add" }));
  };

  const onRowClicked = (row, e) => {
    e.stopPropagation();
    const action = e.target.getAttribute("data-action");
    switch (action) {
      case "remove":
        //dispatch delete
        break;
      case "details":
        dispatch(toggleModal({ type: "details", item: row }));
        break;
    }
  };
  return (
    <>
      <Head title="Pro Schedules" />
      <Content>
        <Block>
          <BlockHead>
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Pro Schedules</BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <Button
                    className="toggle btn-icon d-md-none"
                    color="primary"
                    onClick={onAddBtnClicked}
                  >
                    <Icon name="plus" />
                  </Button>
                  <Button
                    className="toggle d-none d-md-inline-flex"
                    color="primary"
                    onClick={onAddBtnClicked}
                  >
                    <Icon name="plus" />
                    <span>Add Pro Schedule</span>
                  </Button>
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <PreviewCard>
            <ReactDataTable
              data={tableData}
              columns={proSchedulesColumns}
              offset={state.offset}
              itemsPerPage={state.itemsPerPage}
              pagination
              totalItems={totalItems}
              progressPending={isFetching}
              className="nk-tb-list"
              selectableRows
              changePageState={changePageState}
              onRowClicked={onRowClicked}
            />
          </PreviewCard>
        </Block>

        <ModalComponent
          isOpen={state.modal.details}
          toggle={() => dispatch(toggleModal({ type: "details" }))}
          title={"Pro Schedule Details"}
          onModalCancel={() => {
            dispatch(onFormCancel());
          }}
        >
          {state.modal.item && (
            <Row className="gy-3">
              <Col lg={6}>
                <span className="sub-text">Pro Schedule Id</span>
                <span className="caption-text">{state.modal.item.id}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Pro Email</span>
                <span className="caption-text">{state.modal.item.email}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">PWD Email</span>
                <span className="caption-text">
                  {state.modal.item.employment_email}
                </span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Created Time</span>
                <span className="caption-text">
                  {state.modal.item.created_time}
                </span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Last Updated Time</span>
                <span className="caption-text">
                  {state.modal.item.last_updated_time}
                </span>
              </Col>
            </Row>
          )}
        </ModalComponent>

        <ModalComponent
          isOpen={state.modal.add}
          toggle={() => dispatch(onFormCancel())}
          backdrop={"static"}
          title={"Add Pro Schedule"}
          onModalCancel={() => {
            dispatch(onFormCancel());
          }}
        >
          {/* <Alert className="alert-icon" color="danger" isOpen={isCreateError}>
            <Icon name="cross-circle" />
            <strong>Create failed</strong>!{" "}
            {createError?.data.message ?? "There is some technical issues."}
          </Alert> */}
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Row className="g-3">
              <TextInput
                name={"email"}
                label={"Pro Email"}
                register={{
                  ...register("email", {
                    required: "This field is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  }),
                }}
                errors={errors}
              />
              <TextInput
                name={"employment_email"}
                label={"PWD Email"}
                register={{
                  ...register("employment_email", {
                    required: "This field is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  }),
                }}
                errors={errors}
              />
              <SelectField
                name={"working_days"}
                label={"Working Days"}
                control={control}
                rules={{ required: "This field is required" }}
                options={workingDaysOptions}
                isMulti
                closeMenuOnSelect={false}
                errors={errors}
              />

              <Col size="12">
                <Button
                  color="primary"
                  type="submit"
                  disabled={isCreateLoading}
                >
                  {isCreateLoading && <Spinner type="grow" size="sm" />}
                  <span>Add</span>
                </Button>
              </Col>
            </Row>
          </form>
        </ModalComponent>
      </Content>
    </>
  );
};
export default ProSchedules;
